var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('router-view'),_c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"dashboard"},[_c('v-toolbar',{staticClass:"secondary lighten-2 elevation-1",staticStyle:{"width":"100%"},attrs:{"flat":"","dark":""}},[_c('ATemplateListFilter',{staticClass:"flex-grow-1"}),_c('AIconTogglebar',{attrs:{"toggles":_vm.toggles,"active":_vm.currentToggle},on:{"input":_vm.setToggle}})],1),_c('v-data-table',{ref:"sortableTable",staticClass:"elevation-1",attrs:{"id":"paginationstart","headers":_vm.headers,"items":_vm.tableData,"sort-by":_vm.listConfigSortBy,"page":_vm.listConfigPage,"items-per-page":_vm.listConfigPageSize,"custom-sort":_vm.customSort,"hide-default-footer":""},on:{"update:page":function($event){_vm.listConfigPage=$event}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{key:props.index,staticClass:"data-row sortableRow"},[_c('td',[_c('v-container',{staticClass:"pa-0",staticStyle:{"text-align":"left"}},[_c('v-row',{staticClass:"ma-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"pa-0 sortHandle",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(props.item.no)+" ")])],1)],1)],1),_c('td',[(
                    props.item.categoryRef &&
                    props.item.categoryRef.length > 0
                  )?_c('div',[_c('ASnippetCategoryRef',{staticClass:"ma-1",attrs:{"filter":true,"category-ref":props.item.categoryRef,"category-set-id":_vm.template.categorySetId,"category-level":_vm.categoryLevel,"category-depth":1},on:{"filter-selected":_vm.filterCategory}}),(typeof props.item.vda_question_scope === 'string')?_c('v-chip',{attrs:{"small":"","color":"primary","title":_vm.$t(
                        'other.audit_item_list_manipulator.vda_question_scopes'
                      )},on:{"click":function($event){return _vm.filterVdaScope(props.item.vda_question_scope)}}},[_vm._v(" "+_vm._s(props.item.vda_question_scope)+" "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-filter-outline")])],1):_vm._e()],1):_vm._e()]),_c('td',[(props.item.chapters.length > 0)?_c('div',_vm._l((props.item.chapters),function(chapter,idx){return _c('div',{key:idx,staticClass:"ma-1"},[_c('AStandardChip',{attrs:{"chapter-ref":chapter,"filter":true},on:{"click":function($event){return _vm.filterChapter(chapter)}}})],1)}),0):_vm._e()]),_c('td',{staticClass:"text-xs-right"},[_c('v-chip',{attrs:{"small":"","color":props.item.typeColor}},[_vm._v(" "+_vm._s(props.item.typeShort)+" ")])],1),_c('td',{staticClass:"text-xs-right"},[_c('AQuestionContent',{attrs:{"text":props.item.text,"labels":props.item.labels,"audit-item-proofs":props.item.proofs,"links":props.item.links,"hint":props.item.hint,"toggle":_vm.currentToggle,"filtered-text":_vm.filteredText,"search-result":props.item.searchResult,"show-self-assessment":_vm.selfAssessmentFeatureEnabled,"self-assessment-text":_vm.selfAssessmentFeatureEnabled
                      ? props.item.selfAssessmentText
                      : undefined}})],1),_c('td',[(_vm.templatePermissions.write)?[_c('div',{staticStyle:{"display":"flex"}},[_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(props.item.id)}}},[_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("edit")])],1),_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(props.item.id, props.item.no)}}},[_c('v-icon',{attrs:{"color":"pink"}},[_vm._v("delete")])],1)],1)]:_vm._e()],2)])]}}])},[_c('template',{slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$t("views.template.matrix_nodata"))+" ")])],2),_c('PaginationControl',{attrs:{"page":_vm.listConfigPage,"page-sizes":_vm.listConfigPageSizes,"length":_vm.listConfigPageCount},on:{"change":_vm.listConfigOnPaginationChange}})],1)])],1),(_vm.templatePermissions.write)?_c('v-btn',{staticStyle:{"bottom":"30px"},attrs:{"fab":"","bottom":"","right":"","color":"accent","dark":"","fixed":""},on:{"click":_vm.createAuditItem}},[_c('v-icon',[_vm._v("add")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }