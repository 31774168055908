







































































import Vue from "vue";
import Component from "vue-class-component";
import { Action } from "vuex-class";
import { api } from "@/store/modules/app";
import { ROUTE_NAMES } from "@/routenames";
import { TodoActionSignature } from "@auditcloud/shared/lib/utils/type-guards";
import { SystemRoles } from "@auditcloud/shared/lib/constants/roles";

@Component({
  components: {},
})
export default class Settings extends Vue {
  @Action(api.actions.setBreadCrumb, { namespace: api.namespace })
  setBreadCrumb!: (currentBreadCrumb: any[]) => Promise<boolean>;

  @Action(api.actions.setMenuPanel, { namespace: api.namespace })
  setMenuPanel!: TodoActionSignature;

  get isSysAdmin() {
    return this.$user.roles().includes(SystemRoles.SYSTEM_ADMINISTRATOR);
  }

  get configRoute() {
    return {
      name: ROUTE_NAMES.CONFIGURATION,
      params: {
        segmentName: "app",
      },
    };
  }

  get userManagementRoute() {
    return { name: ROUTE_NAMES.USER_MANAGEMENT_USERS };
  }

  get sanityCheckRoute() {
    return {
      name: ROUTE_NAMES.SANITY_CHECK,
    };
  }

  get currentBreadCrumb(): any[] {
    return [
      {
        text: this.$t("views.audit.home"),
        to: { name: ROUTE_NAMES.DASHBOARDSCOPED },
        activeclass: "",
      },
      {
        text: this.$t("views.settings.breadcrumb"),
        to: { name: ROUTE_NAMES.SETTINGS },
        activeclass: "",
      },
    ];
  }

  mounted() {
    this.setMenuPanel("breadcrumb");
    this.setBreadCrumb(this.currentBreadCrumb);
  }
}
