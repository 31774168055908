import { MailTemplateDoc } from "@auditcloud/shared/lib/schemas";
import { unlistenerFunction, WatchedDocument } from "@/utils/firestore";
import { WatchedCollection } from "@/utils/firestore/types/WatchedCollection";

export class MailTemplateState implements WatchedCollection<MailTemplateDoc> {
  Unlistener: unlistenerFunction | null = null;
  Documents: WatchedDocument<MailTemplateDoc | null>[] = [];
  loadedNewQuery: boolean = true;
  currentTemplateId: string = "";
}
export type State = MailTemplateState;
