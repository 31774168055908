import { QuickActionConfig, WorkflowHistoryType } from "@/components/types";
import { api as auditClassesApi } from "@/store/modules/auditClasses";
import { api as confApi } from "@/store/modules/configuration";
import { api as measuresApi } from "@/store/modules/measures";
import { api as measureWorkflowsApi } from "@/store/modules/measureWorkflows";
import { api as templatesApi } from "@/store/modules/templates";
import { api as usersApi } from "@/store/modules/users";
import {
  api as schemasApi,
  FormSchemaDynamic,
  GetSchemaForType,
} from "@/store/modules/schemas";
import { AuditMetadataClient } from "@/types/Audit";
import { createError } from "@/utils/Errors";
import { getterNs } from "@/utils/VuexHelper";
import {
  Annotation,
  AuditDimensionsConfig,
  AuditImplementation,
  AuditItem,
  AuditMetadataDoc,
  AuditProgramConfig,
  AuditProgressDisplayType,
  AuditRemoteSettings,
  CategoryLevel,
  Finding,
  MultilingualText,
  SelfAssessment,
  StoredAttachmentEntry,
  AuditClassConfig,
  MeasurePolicyPerFindingType,
} from "@auditcloud/shared/lib/schemas";
import {
  AuditChecklistExportCriteria,
  AuditChecklistExportData,
} from "@auditcloud/shared/lib/types/AuditChecklistExport";
import {
  extractSelfAssessmentStatus,
  selfAssessmentStates,
  SelfAssessmentStatusId,
  statusIdByName,
} from "@auditcloud/shared/lib/utils/audit/auditStatus";
import {
  AuditItemCategory,
  AuditItemCategoryMap,
  AuditItemCategorySetMap,
  CategoryLevelOptions,
  extractCategories,
  getCategoryDepthOrDefault,
} from "@auditcloud/shared/lib/types/AuditItemCategory";
import { FlatExportFindingData } from "@auditcloud/shared/lib/types/AuditReportExport";
import {
  AuditScoreAggregatedTotal,
  AuditScoreTotal,
  PointsCalculationStrategy,
} from "@auditcloud/shared/lib/types/AuditScore/types";
import {
  FieldPartNames,
  idable,
  StrictDictionary,
  nullable,
  tt2str,
  typeIsMultilingualText,
} from "@auditcloud/shared/lib/types/common";
import { FindingTypeConfig } from "@auditcloud/shared/lib/types/Configuration/Configuration";
import {
  AuditItemTypeConfig,
  AuditItemTypeConfigMap,
  FindingTypeConfigMap,
  MeasureConfig,
} from "@auditcloud/shared/lib/types/Configuration/defaults";
import {
  FilterConfig,
  ListsConfig,
  ListsConfigMap,
} from "@auditcloud/shared/lib/types/Configuration/types";
import {
  AuditItemType,
  AuditItemTypeMap,
  FindingType,
  FindingTypeMap,
  MeasureTypeMap,
} from "@auditcloud/shared/lib/types/ItemTypes";
import {
  UNKNOWN_FINDING_TYPE,
  UNKNOWN_FINDING_TYPE_ID,
} from "@auditcloud/shared/lib/types/SaveFindingType";
import { IUserRef, typeIsIUserRef } from "@auditcloud/shared/lib/types/UserRef";
import {
  AuditPermissions,
  calcAllAuditRoles,
  calcAuditPermissions,
  isAdmin,
} from "@auditcloud/shared/lib/utils/aclHelpers";
import {
  array_cmp,
  text2sortArray,
} from "@auditcloud/shared/lib/utils/comperators";
import {
  AuditItemIdsFilteredWithSearchResults,
  AuditItemListManipulators,
  CreateAuditItemListManipulators,
  do_sort,
  SortManipulator,
} from "@auditcloud/shared/lib/utils/filter/AuditItemListManipulator";
import * as ListManipulatorIds from "@auditcloud/shared/lib/utils/filter/AuditItemListManipulatorIds";
import {
  buildAuditItemAggegators,
  AuditItemIdBasedLookupFunctions,
} from "@auditcloud/shared/lib/utils/filter/AuditItemAggegators";
import {
  buildFilterIndexForItems,
  calculateFilteredItemIds,
  buildAggregationsFromActiveFiltersAndIndex,
} from "@auditcloud/shared/lib/utils/filter/utils";
import { createDefaultSummary } from "@auditcloud/shared/lib/utils/audit/summary";

import {
  AuditClassClient,
  MappedAuditClasses,
} from "@auditcloud/shared/lib/types/AuditClass";
import {
  scoreToComplianceScore,
  buildPointsCalculationStrategy,
} from "@auditcloud/shared/lib/types/AuditScore";
import { calcAuditScore, scoreCalcSteps } from "@/utils/AuditScore";

import { WatchedDocumentStates } from "@/utils/firestore";
import { MeasureWorkflow } from "@auditcloud/shared/lib/workflow/modules/Measure";
import { StoredAttachmentEntryWithContext } from "@auditcloud/shared/lib/types/Attachments";
import {
  AuditImplementationE,
  BLOCKING_AUDIT_ITEM_STATES,
  MAGIC_VDA_STAR_AUDIT_ITEM_TYPE_ID,
  MAGIC_READONLY_AUDIT_STATUS_IDS,
  AuditStatusId,
  ReportSchemaE,
} from "@auditcloud/shared/lib/constants";
import {
  AuditRoles,
  SystemRoles,
} from "@auditcloud/shared/lib/constants/roles";
import { typeIsNamedRef } from "@auditcloud/shared/lib/utils/type-guards";
import {
  buildAuditItemListFilterSettings,
  fulltextSearchAuditItems,
  selectAvailableFiltersForList,
  initFilterConfigWithDefaults,
} from "@auditcloud/shared/lib/utils/filter/FilterUtils";
import { formatAuditName } from "@auditcloud/shared/lib/utils/formatting/audits";
import {
  AuditItemDimensionsMap,
  SortSetting,
  QuestionSearchResultMap,
} from "@auditcloud/shared/lib/types/Audit/types";

import { UserInfoResolver } from "@/types/User";
import { extractCurrentUserRoles, extractCurrentUserRef } from "../user/utils";
import { EntryAttachmentsVuexApi } from "@/utils/attachFileToEntity";
import {
  PreparationStateTransition,
  PreparationStates,
  AuditItemProperties,
  MinimalTemplate,
  AnswerStatus,
  DimensionMap,
  DimensionManager,
  AuditLoadingState,
  BREAD_CRUMB_LABEL_ICON_DICT,
  BreadCrumbLabelType,
  BREAD_CRUMB_LABEL_I18N_DICT,
  AuditItemSettings,
  AuditProgress,
  CurrentAuditState,
} from "./types";
import { formatDates } from "@auditcloud/shared/lib/utils/formatting/dates";
import moment from "moment";
import {
  mapValues,
  fromPairs,
  keys,
  keyBy,
  Dictionary,
  intersection,
  flatMap,
  flatten,
  reduce,
  difference,
  toPairs,
  uniq,
  map,
  isString,
  cloneDeep,
  size,
  uniqBy,
  entries,
  groupBy,
  upperFirst,
  camelCase,
  isArray,
  isNumber,
  isBoolean,
  pick,
  forEach,
  uniqWith,
  isEqual,
  isUndefined,
  first,
} from "lodash";
import {
  STANDARD_DIMENSION_MANAGER,
  VDA_PROCESS_DIMENSION_MANAGER,
  DEFAULT_DIMENSION_MANAGER,
  buildFindingLookup,
  allAuditItemsMapped,
  getQuestionNotesMapped,
  flattenCategoryRefForExport,
  FlatAuditScoreData,
  getSelfAssessmentsMapped,
  auditItemRefsToIdList,
  flattenAuditItems,
  FlatAuditItemDataFindingBased,
  checkConflicts,
  CheckConflictsPayload,
  auditMeasuresCountByType,
  auditFindingsCountByType,
  auditItemCountByType,
  normalizeMeasureForFlatExport,
  getFallback,
  default_flat_export_row,
  valueOfAuditItemDimensionRef,
  calculateTotalConsideredAuditItemIds,
  allPlainAuditItemsMapped,
  calcPreselectionFilterBasedOnTheAuditMetadata,
  deriveFilterValueFromStandardId,
  buildFilterTreeFromAuditItems,
  isVdaAuditClass,
} from "./utils";
import { typeIsNotEmpty } from "@auditcloud/shared/lib/utils/filter/typeIsNotEmpty";
import { lookupAndMakeIdable } from "@auditcloud/shared/lib/utils/transform/lookupAndMakeIdable";
import { makeIdable } from "@auditcloud/shared/lib/utils/transform/makeIdable";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import {
  Aggregation,
  Filter,
  Aggregator,
  AggegatorResult,
  FrontendFilterTree,
} from "@auditcloud/shared/lib/utils/filter/types";
import { AuditItemWithId } from "@auditcloud/shared/lib/utils/audit/types";
import { ct, ft, contentLanguage } from "@/plugins/ContentTranslation";
import { RootState } from "@/store/types";
import { GetterTree } from "vuex";
import { ROUTE_NAMES } from "@/routenames";
import { readFrontendLanguage } from "@/plugins/i18n";
import { calcUrgency } from "@auditcloud/shared/lib/types/UrgencyChart";
import {
  extractApplicableMeasurePolicyViolation,
  FindingMeasurePolicyAggregation,
  calculateMeasurePolicyViolations,
  MeasurePolicyViolation,
} from "@auditcloud/shared/lib/utils/audit/measurePolicies";
import naturalCompare from "natural-compare";

function isIsoDateString(val: string) {
  return /^\d{4}-\d{2}-\d{2}/.test(val);
}

type ExcelCellValueTypes = number | string | Date | boolean | null;
function intelligentTransform(
  data: unknown,
  language: string
): ExcelCellValueTypes {
  if (
    data === null ||
    isNumber(data) ||
    isBoolean(data) ||
    data instanceof Date
  ) {
    return data;
  } else if (isString(data)) {
    if (isIsoDateString(data)) {
      const date = new Date(data);
      return data.length > 10
        ? date.toLocaleString(language)
        : date.toLocaleDateString(language);
    } else {
      return data;
    }
  } else if (isArray(data)) {
    return data
      .map(v => intelligentTransform(v, language))
      .map(v => (v instanceof Date ? v.toLocaleDateString(language) : v))
      .join(", ");
  } else if (typeIsNamedRef(data)) {
    return tt2str(data.name, language);
  } else if (typeIsIUserRef(data)) {
    return data.displayName;
  } else {
    return null;
  }
}
function intelligentDataFlattener(
  prefix: string,
  data: unknown,
  language: string = "de"
): Dictionary<ExcelCellValueTypes> {
  if (data instanceof Object) {
    return fromPairs(
      toPairs(data).map(([key, val]): [string, ExcelCellValueTypes] => {
        const extendedKey = `${prefix}${upperFirst(camelCase(key))}`;
        return [extendedKey, intelligentTransform(val, language)];
      })
    );
  } else {
    return {};
  }
}

const getExcelMetadataCollection = (
  state: CurrentAuditState,
  getters: Dictionary<unknown>,
  rootState: RootState,
  rootGetters: Dictionary<unknown>
): Dictionary<ExcelCellValueTypes> => {
  const auditMetaData = state.Document.data;
  if (auditMetaData) {
    const language = contentLanguage();

    console.log("Metadata", auditMetaData);

    return {
      auditId: auditMetaData.id,
      auditName: formatAuditName(auditMetaData),
      exportDate: new Date(),

      auditingDate: intelligentTransform(auditMetaData.auditing_date, language),
      planningYear: intelligentTransform(auditMetaData.planning_year, language),

      auditStandards: intelligentTransform(
        auditMetaData.standardRefs,
        language
      ),
      auditType: intelligentTransform(auditMetaData.type, language),

      leadauditor: intelligentTransform(auditMetaData.leadauditor, language),
      coauditors: intelligentTransform(auditMetaData.coauditors, language),
      responsible: intelligentTransform(auditMetaData.responsible, language),
      auditParticipants: intelligentTransform(
        auditMetaData.audit_participants,
        language
      ),
      distributionList: intelligentTransform(
        auditMetaData.distributionlist,
        language
      ),
      measureCoordinators: intelligentTransform(
        auditMetaData.measureCoordinators,
        language
      ),

      objective: intelligentTransform(auditMetaData.objective, language),
      summary: intelligentTransform(auditMetaData.summary, language),
      auditDuration: intelligentTransform(
        auditMetaData.reportingAuditDuration,
        language
      ),
      auditShifts: intelligentTransform(
        auditMetaData.reportingAuditShifts,
        language
      ),

      ...intelligentDataFlattener("cd", auditMetaData.customData, language),
    };
  } else {
    return {};
  }
};
export type ExcelMetadataCollection = ReturnType<
  typeof getExcelMetadataCollection
>;

const getAuditReportSummary = (
  state: CurrentAuditState,
  getters: Dictionary<unknown>,
  rootState: RootState,
  rootGetters: Dictionary<unknown>
) => {
  if (!("users" in rootState) || !("loadedUsers" in rootState.users)) {
    return null;
  }

  const activeAudit = getters[
    n.getAuditDocumentData
  ] as nullable<AuditMetadataClient>;

  const score = getters[n.getAuditScore] as nullable<AuditScoreTotal>;

  if (activeAudit && score !== null) {
    const auditClass = getters[n.getAuditClass] as nullable<AuditClassClient>;

    const summaryItemConfig = auditClass?.summaryItemConfig;

    const defaultUserSkeleton = (userId: string) => {
      return {
        id: userId,
        displayName: "UserRef (" + userId + ") unresolved",
      };
    };

    const loadedUsers = rootState.users.loadedUsers;

    const resolveUserFromId = (userId: any): IUserRef | null => {
      if (userId) {
        if (
          userId instanceof Object &&
          typeof userId.id === "string" &&
          typeof userId.displayName === "string"
        ) {
          return userId;
        }
        console.assert(
          typeof userId === "string",
          "Expect type string found",
          typeof userId,
          "for",
          userId
        );
        const user = loadedUsers.find(
          (iterUser: { id: any }) => iterUser.id === userId
        );
        if (user) {
          return user;
        } else {
          return defaultUserSkeleton(userId);
        }
      } else {
        return null;
      }
    };

    const toDateOr = (IsoDateString: string | Date, Fallback: null) => {
      Fallback = Fallback || null;
      if (
        typeof IsoDateString === "string" &&
        /^\d{4}-\d{2}-\d{2}/.test(IsoDateString)
      ) {
        return new Date(IsoDateString.substr(0, 10));
      } else if (IsoDateString instanceof Date) {
        return IsoDateString;
      }
      return Fallback;
    };

    return {
      leadAuditor: resolveUserFromId(
        getFallback(activeAudit.leadauditor, null)
      ),
      responsibleUser: resolveUserFromId(
        getFallback(activeAudit.responsible, null)
      ),
      auditReportingType: activeAudit.reportSchema,
      auditId: getters[n.getAuditId] as string | null,
      auditName: formatAuditName(activeAudit),
      auditPlaningDate: toDateOr(activeAudit.planning_date ?? "", null),
      auditPlanningYear: activeAudit.planning_year,
      auditingDates: map(activeAudit.auditing_date, date => {
        return toDateOr(date, null);
      }),
      auditStandard: activeAudit.standards,
      auditType: activeAudit.type,
      auditWorkFlow: activeAudit.workflow,
      auditParticipants: map(activeAudit.audit_participants, resolveUserFromId),
      auditCoAuditors: map(activeAudit.coauditors, resolveUserFromId),
      auditedBy: activeAudit.audited_by,
      auditSummary:
        activeAudit.summary ?? createDefaultSummary(summaryItemConfig),
      statistics: getters[n.getAuditStatistics] as AuditStatistics,
      score,
      compliancePercent: scoreToComplianceScore(score.inTotal),
    };
  } else {
    return null;
  }
};

export type AuditReportSummary = ReturnType<typeof getAuditReportSummary>;

type FlatRowData = ReturnType<typeof default_flat_export_row>;

const getMeasureProcessCompletionPercentage = (
  state: CurrentAuditState,
  getters: Dictionary<unknown>,
  rootState: RootState,
  rootGetters: Dictionary<unknown>
) => {
  const measureIds = rootGetters[
    getterNs(measuresApi, measuresApi.getters.getMeasureIds)
  ] as string[];

  const completedMeasureCount = rootGetters[
    getterNs(measuresApi, measuresApi.getters.getCompletedMeasuresCount)
  ] as number;

  if (measureIds.length === 0) {
    return 100;
  }
  return (completedMeasureCount / measureIds.length) * 100;
};

// Flattet die Daten auf Findingebene
const getFlatExportData = (
  state: CurrentAuditState,
  getters: Dictionary<unknown>,
  rootState: RootState,
  rootGetters: Dictionary<unknown>
) => {
  return (
    auditItems: nullable<Array<AuditItemWithId>>,
    allowedFindingTypes: nullable<(string | null)[]>
  ) => {
    const lang = contentLanguage();
    const findings_order = getters[n.getFindingsReportOrder] as string[];
    const mappedFindingTypes = getters[
      n.getMappedFindingTypes
    ] as FindingTypeMap;

    const dimensionsMap = getters[n.getAuditDimensionsMap] as DimensionMap;
    const mappedAuditItemTypes = getters[
      n.getMappedAuditItemTypes
    ] as AuditItemTypeMap;

    const findingMeasuresMap = rootGetters[
      getterNs(measuresApi, measuresApi.getters.getMappedFindingId2MeasureIds)
    ] as {
      [findingId: string]: string[];
    };
    const mappedMeasures = rootGetters[
      getterNs(measuresApi, measuresApi.getters.getMappedMeasures)
    ] as Dictionary<MeasureProcessDocument>;
    const measureTypesMapping = rootGetters[
      getterNs(confApi, confApi.getters.getMeasureTypesMapping)
    ] as MeasureTypeMap;

    const importanceId2Order = (Id: string | null) => {
      return findings_order.findIndex((v: string) => v === Id);
    };

    const calcBase = getters[
      n.getAuditReportCalcSchema
    ] as nullable<PointsCalculationStrategy>;

    const auditItemsToConsider = auditItems
      ? auditItems
      : (getters[n.getAuditItems] as AuditItemWithId[]);

    const userGetter = rootGetters[
      getterNs(usersApi, usersApi.getters.getUser)
    ] as UserInfoResolver;
    const findingsLookup = buildFindingLookup(state, getters);

    if (typeof auditItemsToConsider === "undefined" || calcBase === null) {
      return [];
    } else {
      const categoryLevel = getters[n.getCategoryLevel] as CategoryLevel;
      const mappedCategories = getters[
        n.getAuditCategoryMapping
      ] as AuditItemCategoryMap;
      const exportRows = flatMap(auditItemsToConsider, auditItem => {
        const flatRow = default_flat_export_row(
          auditItem,
          importanceId2Order(null),
          mappedAuditItemTypes,
          categoryLevel,
          mappedCategories,
          calcBase
        );
        const findings = findingsLookup(auditItem.id);

        return flatMap(findings, finding => {
          const findingId = finding.id;
          const findingTypeId = finding.type;
          const findingOrder = importanceId2Order(findingTypeId);
          const finding_type_config = mappedFindingTypes[findingTypeId];
          if (typeof finding_type_config === "undefined") {
            throw createError(
              "invalid finding id",
              findingTypeId,
              mappedFindingTypes,
              finding
            );
          }

          const findingDimensionIds = uniq(
            flatMap(
              finding.auditItemRefs.filter(
                ref => ref.auditItemId === auditItem.id
              ),
              ref => ref.auditItemId
            )
          );

          const maturityEnabled = getters[n.getMaturityEnabled] as boolean;
          const findingMaturityValue = finding.maturity;
          if (maturityEnabled && isUndefined(findingMaturityValue)) {
            const findingType = mappedFindingTypes[finding.type];
            if (!findingType.is_not_applicable) {
              throw createError(
                `Maturity is enabled but finding "${finding.id}" has no maturity`,
                findingTypeId,
                mappedFindingTypes,
                finding
              );
            }
          }

          const findingRow: FlatExportFindingData = {
            findingDimensionIds,
            findingDimensionNames: findingDimensionIds.map(dimensionId =>
              tt2str(dimensionsMap[dimensionId]?.name ?? "", lang)
            ),
            findingId,
            findingNote: ct(finding.text),
            findingWeight: finding_type_config.value,
            findingTypeId,
            findingTypeName: ct(getFallback(finding_type_config.text, "")),
            findingTypeAbbr: ct(getFallback(finding_type_config.short, "")),
            findingTypeDesc: ct(
              getFallback(finding_type_config.description, "")
            ),
            findingIsApplicable: !finding_type_config.is_not_applicable,
            findingIsDeviation: !finding_type_config.is_no_deviation,
            findingMaturityValue,
            findingLikelihood: finding.risk?.likelihood,
            findingImpact: finding.risk?.impact,
            findingUrgency: calcUrgency(
              finding.risk?.impact ?? null,
              finding.risk?.likelihood ?? null
            ),
            findingRisk: finding.risk?.text ?? null,
          };

          const finding_measures = (findingMeasuresMap[findingId] ?? [])
            .map(measureId => mappedMeasures[measureId] ?? null)
            .filter(typeIsNotEmpty);

          const rows: Array<FlatRowData> = [];

          if (finding_measures.length > 0) {
            for (const measure of finding_measures) {
              const measureRow = normalizeMeasureForFlatExport(
                measure,
                userGetter,
                measureTypesMapping
              );
              rows.push({
                ...flatRow,
                findingOrder,
                ...findingRow,
                ...measureRow,
              });
            }
          } else {
            rows.push({
              ...flatRow,
              findingOrder,
              ...findingRow,
            });
          }
          return rows;
        });
      });

      if (allowedFindingTypes) {
        return exportRows.filter(row => {
          return allowedFindingTypes.includes(row.findingTypeId);
        });
      } else {
        return exportRows;
      }
    }
  };
};
export type FlatExportData = ReturnType<typeof getFlatExportData>;

// Generiert Daten für den Report nur mit Abweichungen.
const getFlatReportData = (
  state: CurrentAuditState,
  getters: Dictionary<unknown>,
  rootState: RootState,
  rootGetters: Dictionary<unknown>
) => {
  const settings = state.listSettings[state.activeList];
  let allowedFindingTypes: nullable<string[]> = null;
  const maturityEnabled = getters[n.getMaturityEnabled] as boolean;

  if (maturityEnabled) {
    const findingTypesMap = getters[n.getMappedFindingTypes] as FindingTypeMap;
    allowedFindingTypes = Object.entries(findingTypesMap)
      .filter(([_, findingType]) => !findingType.is_not_applicable)
      .map(([findingTypeId]) => findingTypeId);
  } else {
    if (settings) {
      const deviationFilter = settings.filters.find(
        filter =>
          filter.aggregationId ===
          ListManipulatorIds.FILTER_FINDING_IS_DEVIATION
      );
      const findingTypeFilterValues: string[] = [];
      if (deviationFilter) {
        const mappedFindingTypes = getters[
          n.getMappedFindingTypes
        ] as FindingTypeMap;
        Object.entries(mappedFindingTypes).forEach(([id, findingType]) => {
          if (findingType.is_no_deviation === !deviationFilter.value) {
            findingTypeFilterValues.push(id);
          }
        });
      } else {
        settings.filters.forEach(filter => {
          if (
            filter.aggregationId ===
              ListManipulatorIds.FILTER_FINDING_IMPORTANCE &&
            typeof filter.value === "string"
          ) {
            findingTypeFilterValues.push(filter.value);
          }
        });
      }
      if (findingTypeFilterValues.length) {
        allowedFindingTypes = findingTypeFilterValues;
      }
    }
  }

  const flatData = [
    ...(getters[n.getFlatExportData] as FlatExportData)(
      null,
      allowedFindingTypes
    ),
  ];

  // Todo: make sorting configurable
  flatData.sort((lhs: any, rhs: any) => {
    return naturalCompare(lhs.auditItemNo, rhs.auditItemNo);
  });
  return flatData;
};
export type FlatReportData = ReturnType<typeof getFlatReportData>;

const getAuditStatistics = (
  state: CurrentAuditState,
  getters: Dictionary<unknown>,
  rootState: RootState,
  rootGetters: Dictionary<unknown>
) => {
  const audit = getters[
    n.getAuditDocumentData
  ] as nullable<AuditMetadataClient>;
  if (audit === null) {
    return null;
  }

  const mappedAuditItemTypes = getters[
    n.getMappedAuditItemTypes
  ] as AuditItemTypeMap;

  const audit_findings = getters[
    n.getAuditFindingsFlat
  ] as FlatAuditItemDataFindingBased[];

  const item_count = size(groupBy(audit_findings, "auditItemId"));
  const grouped = map(
    groupBy(audit_findings, "findingTypeAbbr"),
    (grouped_findings, key) => {
      return {
        // base: grouped_findings,
        abbr: key,
        name: grouped_findings[0].findingTypeName,
        count: grouped_findings.length,
        worst: reduce(
          grouped_findings,
          (sum, finding) => {
            return (
              sum +
              finding.auditItemWeight *
                (finding.findingWeightWorstPossible || 0)
            );
          },
          0
        ),
        current: reduce(
          grouped_findings,
          (sum, finding) => {
            return sum + finding.auditItemWeight * (finding.findingWeight || 0);
          },
          0
        ),
        best: reduce(
          grouped_findings,
          (sum, finding) => {
            return (
              sum +
              finding.auditItemWeight * (finding.findingWeightBestPossible || 0)
            );
          },
          0
        ),
      };
    }
  );

  const findings_count = reduce(
    audit_findings,
    (sum, audit_finding) => {
      return sum + (audit_finding.findingId === null ? 0 : 1);
    },
    0
  );

  const measures_getter = rootGetters[
    getterNs(measuresApi, measuresApi.getters.getMappedMeasures)
  ] as { [measureId: string]: MeasureProcessDocument };

  const measures = toPairs(measures_getter).map(makeIdable);

  const mappedAuditItems = allAuditItemsMapped(rootGetters);

  const auditItemCount = {
    total: size(mappedAuditItems),
    byType: auditItemCountByType(mappedAuditItems, mappedAuditItemTypes),
  };
  const auditFindingsCount = {
    total: findings_count,
    byType: auditFindingsCountByType(
      state.AuditItemsDocument.data?.findings ?? {},
      getters[n.getMappedFindingTypes] as FindingTypeMap,
      getters[n.getFindingsReportOrder] as string[]
    ),
  };
  const auditMeasuresCount = {
    total: size(measures),
    byType: auditMeasuresCountByType(
      measures,
      (rootGetters[getterNs(confApi, confApi.getters.measure)] as MeasureConfig)
        .data.types
    ),
  };
  const statistics = {
    auditItemCount,
    auditFindingsCount,
    auditMeasuresCount,
  };
  const result = {
    auditItemCount: item_count,
    auditFindingsCount: findings_count,
    auditItemInvalidCount: null,
    auditMeasuresCount: size(measures),
    byMeasureType: null,
    byFindingType: grouped,
    statistics,
  };

  return result;
};
export type AuditStatistics = ReturnType<typeof getAuditStatistics>;

const getAuditItemsInConflictState = (
  state: CurrentAuditState,
  getters: Dictionary<unknown>,
  rootState: RootState,
  rootGetters: Dictionary<unknown>
): { [auditItemId: string]: string[] | null } => {
  const auditItemDimensionsMap = getters[
    n.getAuditItemDimensionsMap
  ] as AuditItemDimensionsMap;
  const mappedFindingTypes = getters[n.getMappedFindingTypes] as FindingTypeMap;

  const mappedAuditItems = allAuditItemsMapped(rootGetters);
  const findings = entries(state.AuditItemsDocument.data?.findings ?? {});

  const answeredAuditItems = findings.reduce(
    (p, [findingId, { auditItemRefs, type }]) => {
      uniqBy(auditItemRefs, valueOfAuditItemDimensionRef).forEach(
        ({ auditItemId, dimensions }) => {
          const auditItemInfo: CheckConflictsPayload = p[auditItemId] ?? {
            auditItemId,
            auditItemDimensions: auditItemDimensionsMap[auditItemId] ?? null,
            findings: [],
          };

          auditItemInfo.findings.push({
            findingDimensions: dimensions,
            findingType: mappedFindingTypes[type],
            findingId,
          });

          p[auditItemId] = auditItemInfo;
        }
      );

      return p;
    },
    {} as { [auditItemId: string]: CheckConflictsPayload }
  );

  return mapValues(answeredAuditItems, answerInfos => {
    return checkConflicts(answerInfos);
  });
};
export type AuditItemsInConflictState = ReturnType<
  typeof getAuditItemsInConflictState
>;

const getAttachment2AnnotationsMap = (
  state: CurrentAuditState,
  getters: Dictionary<unknown>,
  rootState: RootState,
  rootGetters: Dictionary<unknown>
): { [attachmentId: string]: idable<Annotation>[] | undefined } => {
  const annotations = map(
    state.AuditItemsDocument.data?.annotations ?? {},
    (val, id) => {
      return {
        ...val,
        id,
      };
    }
  );

  return groupBy(annotations, "attachmentId");
};
export type Attachment2AnnotationsMap = ReturnType<
  typeof getAttachment2AnnotationsMap
>;

// currentAuditId
const getAuditId = "getAuditId";
const getAuditName = "getAuditName";
const getAuditProgramId = "getAuditProgramId";
const getAuditProgramConfig = "getAuditProgramConfig";
// currentAudit
const getAuditDocumentData = "getAuditDocumentData";
const hasAuditDocument = "hasAuditDocument";
const getAuditFormSchema = "getAuditFormSchema";
const getAuditFormData = "getAuditFormData";

// currentStatus: Muss der getrennt sein? Ich brauche eh den sehr frühen Zugriff auf Metadaten.
const getAuditStatus = "getAuditStatus";
const getIsVdaAudit = "getIsVdaAudit"; // The result calculation is vda based
const getIsVdaMatrixRequired = "getIsVdaMatrixRequired"; // Is there VDA-Matrix that has to be served
const getAuditMetadata = "getAuditMetadata";
const getUnlinkedFindingsAllowed = "getUnlinkedFindingsAllowed";
const getAddRequirementsAllowed = "getAddRequirementsAllowed";
const getAddFreeFindingsAllowed = "getAddFreeFindingsAllowed";
const getAuditChecklistExportData = "getAuditChecklistExportData";

// TODO: Generischer Kram - Wie gehen wir damit um?
const getAuditCompanyMetadataDialogData = "getAuditCompanyMetadataDialogData";

// currentAuditItems
const getAuditItems = "getAuditItems";
const getAreMultipleStandardsReferenced = "getAreMultipleStandardsReferenced";
const getAuditItemsForPreparation = "getAuditItemsForPreparation";
const getAuditItemsForList = "getAuditItemsForList";
const getAuditItemsFilteredByFulltextSearch =
  "getAuditItemsFilteredByFulltextSearch";
const getAuditItemsFilteredSearchResults = "getAuditItemsFilteredSearchResults";
const getAuditItemIdsFiltered = "getAuditItemIdsFiltered";
const getFullTextSearch = "getFullTextSearch";
const getAuditItemIdsSorted = "getAuditItemIdsSorted";
const getAuditItemIdsPaged = "getAuditItemIdsPaged";
const getPageCount = "getPageCount";
const getCurrentPage = "getCurrentPage";
const getPageSizes = "getPageSizes";

const getMappedAuditItems = "getMappedAuditItems";
const getPlainAuditItemsMapped = "getPlainAuditItemsMapped";
const getTotalCountAuditItem = "getTotalCountAuditItem";

const getHasSelectedAuditItemIds = "getHasSelectedAuditItemIds";
const getSelectedAuditItemIds = "getSelectedAuditItemIds";

// currentFindings
const getAuditFindingsFlat = "getAuditFindingsFlat";
const getFindingIds = "getFindingIds";
const getFindingsMap = "getFindingsMap";
const getAuditItemId2FindingsMap = "getAuditItemId2FindingsMap";
const getSelfAssessments = "getSelfAssessments";
const getAuditItemId2SelfAssessmentMap = "getAuditItemId2SelfAssessmentMap";
const getAuditItemId2FindingIdsMap = "getAuditItemId2FindingIdsMap";
const getUnAnsweredAuditItems = "getUnAnsweredAuditItems";
const getResultAttachmentsMap = "getResultAttachmentsMap";
const getUnassignedFindingsCount = "getUnassignedFindingsCount";
const getFindingsAggregationForPolicyCheck =
  "getFindingsAggregationForPolicyCheck";
const getMeasurePolicyViolations = "getMeasurePolicyViolations";
const getMeasurePoliciesStatus = "getMeasurePoliciesStatus";
const getUnassignedFindingIds = "getUnassignedFindingIds";
const getUnassignedFindings = "getUnassignedFindings";
const getAssignedFindingIds = "getAssignedFindingIds";
const getAuditItemId2Setting = "getAuditItemId2Setting";
const getIsResultVisible = "getIsResultVisible";
const getIsAuditItemNoteVisible = "getIsAuditItemNoteVisible";

// Progress für Execution, WrapUp und Reporting?
const getAuditProgress = "getAuditProgress";

// Reporting -> Können wir da noch vereinfachne?
const getScoreCalcStepsDeprecated = "getScoreCalcStepsDeprecated";

const getAuditScore = "getAuditScore";
const getDimensionGroupedData = "getDimensionGroupedData";
const getAuditScores = "getAuditScores";

const getFlatAuditScoreData = "getFlatAuditScoreData";
const getAuditReportCalcSchema = "getAuditReportCalcSchema";
const getDefaultCalcSchemaName = "getDefaultCalcSchemaName";

const isExistingDocument = "isExistingDocument";
const hasPendingWrites = "hasPendingWrites";
const isCachedDocument = "isCachedDocument";
const getDocumentOnlineState = "getDocumentOnlineState";
const hideRequirementText = "hideRequirementText";

const getAuditCategoryMapping = "getAuditCategoryMapping";
const getAuditCategorySetId = "getAuditCategorySetId";
const getAuditCategory = "getAuditCategory";
const getWorkflowSteps = "getWorkflowSteps";
const getWorkflowHistory = "getWorkflowHistory";

const isAuditStateDirty = "isAuditStateDirty";
const isAuditTransitionDirty = "transitionDirty";

const getMappedFindingTypes = "getMappedFindingTypes";
const getFindingTypesForInput = "getFindingTypesForInput";
const getFindingTypeConfig = "getFindingTypeConfig";
const getFinalizeExecutionFindingTypeId = "getFinalizeExecutionFindingTypeId";
const getFindingsReportOrder = "getFindingsReportOrder";
const getFindingQuickActionConfig = "getFindingQuickActionConfig";
const getAuditClassId = "getAuditClassId";
const getAuditClass = "getAuditClass";
const getUnlinkedFindingsEnabled = "getUnlinkedFindingsEnabled";
const getCategoryLevel = "getCategoryLevel";
const getCategoryDepth = "getCategoryDepth";
const getAuditProgressDisplayType = "getAuditProgressDisplayType";
const getAuditItemTypeConfig = "getAuditItemTypeConfig";
const getMappedAuditItemTypes = "getMappedAuditItemTypes";
const getAuditItemTypes = "getAuditItemTypes";
const getAuditItemListManipulators = "getAuditItemListManipulators";
const getAuditItemListSortManipulators = "getAuditItemListSortManipulators";
const getAllSortManipulators = "getAllSortManipulators";
const getAvailableSortingForList = "getAvailableSortingForList";
const getAuditItemListSortSettings = "getAuditItemListSortSettings";
const getAuditorsForCurrentAudit = "getAuditorsForCurrentAudit";

const getAvailableFiltersForList = "getAvailableFiltersForList";
const getIsRestrictPreparationToCategories =
  "getIsRestrictPreparationToCategories";
const getAvailableFiltersForPreselection = "getAvailableFiltersForPreselection";
const getActiveFrontendFilter = "getActiveFrontendFilter";
const getAuditItemListFilterAggregations = "getAuditItemListFilterAggregations";
const getActiveFilters = "getActiveFilters";

const getManipulatorStatus = "getManipulatorStatus";

const getChecklistPages = "getChecklistPages";
const getReportPages = "getReportPages";
const getCalculatedAuditParticipants = "getCalculatedAuditParticipants";
const getMeasureResponsible = "getMeasureResponsible";
const getMeasureCoordinators = "getMeasureCoordinators";
const getActionsPerView = "getActionsPerView";
const getActionplanView = "getActionplanView";

const getAuditImplementation = "getAuditImplementation";
const getAuditBreadCrumbLabel = "getAuditBreadCrumbLabel";
const getAuditBreadCrumbLabelType = "getAuditBreadCrumbLabelType";
const getAuditBreadCrumbLabelIcon = "getAuditBreadCrumbLabelIcon";

const getContentLanguages = "getContentLanguages";
const getAuditPermissions = "getAuditPermissions";
const getAuditRoles = "getAuditRoles";

const getAuditLoadingState = "getAuditLoadingState";
const getAuditLoadingStateView = "getAuditLoadingStateView";
const getAuditLoadingError = "getAuditLoadingError";
const getAuditIsBulkUpdating = "getAuditIsBulkUpdating";
const getMeasureCreationAllowed = "getMeasureCreationAllowed";
const getMeasureWorkflowId = "getMeasureWorkflowId";
const getMeasureWorkflow = "getMeasureWorkflow";

const getEntityAttachmentsPublic = "getEntityAttachmentsPublic";
const getEntityAttachmentsPrivate = "getEntityAttachmentsPrivate";
const getEntityAttachments = "getEntityAttachments";

const getEntityAttachmentPermissions = "getEntityAttachmentPermissions";
const getDimensionsManager = "getDimensionsManager";
const getDimensionsConfig = "getDimensionsConfig";
const getAuditDimensionsMap = "getAuditDimensionsMap";
const getAuditItemDimensionsMap = "getAuditItemDimensionsMap";
const getAuditItemUnanswerdDimensionsMap = "getAuditItemUnanswerdDimensionsMap";
const getAuditItemDimensionAnswerStatusMap =
  "getAuditItemDimensionAnswerStatusMap";

const getAuditIsRemoteAudit = "getAuditIsRemoteAudit";
const getAuditRemoteSettings = "getAuditRemoteSettings";
const getSelfAssessmentIncluded = "getSelfAssessmentIncluded";
const getSelfAssessmentCompleted = "getSelfAssessmentCompleted";
const getIsReportSignatureRequired = "getIsReportSignatureRequired ";
const getSelfAssessmentOngoing = "getSelfAssessmentOngoing";
const getSelfAssessmentMarkedAsFinished = "getSelfAssessmentMarkedAsFinished";

const getPossibleAuditTemplatesForCurrentAudit =
  "getPossibleAuditTemplatesForCurrentAudit";
const getSelectedTemplateIds = "getSelectedTemplateIds";
const getPreselectionFilterAggregations = "getPreselectionFilterAggregations";
const getAuditItemAggregators = "getAuditItemAggregators";
const getAuditItemPreselectionAggregators =
  "getAuditItemPreselectionAggregators";
const getFilterTreeForFrontendFiltering = "getFilterTreeForFrontendFiltering";
const getFilterTreeForNotConsideredAuditItems =
  "getFilterTreeForNotConsideredAuditItems";
const getPreselectionFilterTree = "getPreselectionFilterTree";
const getAuditItemIdsBaseSetAccordingToAuditState =
  "getAuditItemIdsBaseSetAccordingToAuditState";

const getConsideredAuditItemIds = "getConsideredAuditItemIds";
const getTotalAuditItemCount = "getTotalAuditItemCount";
const getAuditItemProperties = "getAuditItemProperties";
const getPreparationState = "getPreparationState";
const getPossiblePreparationStates = "getPossiblePreparationStates";
const getPreparationStateTransitions = "getPreparationStateTransitions";
const getMappedTags = "getMappedTags";
const getMappedNotes = "getMappedNotes";
const totalConsideredAuditItemIds = "totalConsideredAuditItemIds";
const getNotConsideredAuditItemIds = "getNotConsideredAuditItemIds";
const getMaturity = "getMaturity";
const getMaturityEnabled = "getMaturityEnabled";
const getAuditItemsWithMissingMaturity = "getAuditItemsWithMissingMaturity";
const getForceFullFindingEditor = "getForceFullFindingEditor";
const getOpenProcessAfterCreation = "getOpenProcessAfterCreation";
const getIsDeleted = "getIsDeleted";

const n = {
  getAuditDocumentData,
  hasAuditDocument,
  getAuditFormData,
  getAuditFormSchema,
  getAuditId,
  getAuditName,
  getAuditProgramId,
  getAuditProgramConfig,
  getAuditStatus,
  getIsVdaAudit,
  getIsVdaMatrixRequired,
  getAuditItems,
  getAreMultipleStandardsReferenced,
  getAuditItemsForPreparation,
  getAuditItemsForList,
  getAuditItemsFilteredByFulltextSearch,
  getSearchResultsByAuditItemIds: getAuditItemsFilteredSearchResults,
  getAuditItemIdsFiltered,
  getFullTextSearch,
  getAuditItemIdsSorted,
  getAuditItemIdsPaged,
  getPageCount,
  getCurrentPage,
  getPageSizes,

  getMappedAuditItems,
  getPlainAuditItemsMapped,
  getTotalCountAuditItem,

  getHasSelectedAuditItemIds,
  getSelectedAuditItemIds,
  getAuditCompanyMetadataDialogData,
  getAuditMetadata,
  getUnlinkedFindingsAllowed,
  getAddRequirementsAllowed,
  getAddFreeFindingsAllowed,
  getAuditChecklistExportData,
  getExcelMetadataCollection: "getExcelMetadataCollection",
  getAuditReportSummary: "getAuditReportSummary",
  getScoreCalcStepsDeprecated,
  getMeasureProcessCompletionPercentage:
    "getMeasureProcessCompletionPercentage",
  getAuditProgress,
  getFlatExportData: "getFlatExportData",
  getFlatReportData: "getFlatReportData",
  getAuditStatistics: "getAuditStatistics",
  getAuditItemsInConflictState: "getAuditItemsInConflictState",
  getAttachment2AnnotationsMap: "getAttachment2AnnotationsMap",
  getAuditScore,
  getDimensionGroupedData,
  getAuditScores,
  getAuditFindingsFlat,
  getFindingIds,
  getFindingsMap,
  getAuditItemId2FindingsMap,
  getSelfAssessments,
  getAuditItemId2SelfAssessmentMap,
  getAuditItemId2FindingIdsMap,
  getUnAnsweredAuditItems,
  getResultAttachmentsMap,
  getFindingsAggregationForPolicyCheck,
  getUnassignedFindingsCount,
  getMeasurePolicyViolations,
  getMeasurePoliciesStatus,

  getUnassignedFindingIds,
  getUnassignedFindings,
  getAssignedFindingIds,
  getAuditItemId2Setting,
  getIsResultVisible,
  getIsAuditItemNoteVisible,
  getFlatAuditScoreData,

  getAuditReportCalcSchema,
  getDefaultCalcSchemaName,

  isExistingDocument,
  hasPendingWrites,
  isCachedDocument,
  getDocumentOnlineState,
  hideRequirementText,
  getAuditCategorySetId,
  getAuditCategory,
  getAuditCategoryMapping,
  getWorkflowSteps,
  getWorkflowHistory,
  getMeasureCreationAllowed,

  isAuditStateDirty,
  isAuditTransitionDirty,

  getMappedFindingTypes,

  getFindingTypesForInput,
  getFindingTypeConfig,
  getFinalizeExecutionFindingTypeId,
  getFindingsReportOrder,
  getFindingQuickActionConfig,
  getAuditClassId,
  getAuditClass,
  getUnlinkedFindingsEnabled,
  getCategoryLevel,
  getCategoryDepth,
  getAuditProgressDisplayType,

  getAuditItemTypeConfig,
  getMappedAuditItemTypes,
  getAuditItemTypes,
  getAuditItemListManipulators,
  getAuditItemListSortManipulators,
  getAllSortManipulators,
  getAvailableSortingForList,
  getAuditItemListSortSettings,
  getAuditorsForCurrentAudit,

  getAvailableFiltersForList,
  getIsRestrictPreparationToCategories,
  getAvailableFiltersForPreselection,
  getActiveFrontendFilter,
  getAuditItemListFilterAggregations,
  getActiveFilters,
  getManipulatorStatus,
  getChecklistPages,
  getReportPages,
  getCalculatedAuditParticipants,
  getMeasureCoordinators,
  getMeasureResponsible,
  getActionsPerView,
  getActionplanView,
  getAuditImplementation,
  getAuditBreadCrumbLabel,
  getAuditBreadCrumbLabelType,
  getAuditBreadCrumbLabelIcon,
  getContentLanguages,
  getAuditPermissions,
  getAuditRoles,
  getAuditLoadingState,
  getAuditLoadingStateView,
  getAuditLoadingError,
  getAuditIsBulkUpdating,
  getMeasureWorkflowId,
  getMeasureWorkflow,
  getEntityAttachmentsPrivate,
  getEntityAttachmentsPublic,
  getEntityAttachments,
  getEntityAttachmentPermissions,
  getDimensionsManager,
  getDimensionsConfig,
  getAuditDimensionsMap, // AuditDimensionsConfig
  getAuditItemDimensionsMap,
  getAuditItemUnanswerdDimensionsMap,
  getAuditItemDimensionAnswerStatusMap,

  getAuditIsRemoteAudit,
  getAuditRemoteSettings,
  getSelfAssessmentIncluded,
  getSelfAssessmentCompleted,
  getIsReportSignatureRequired,
  getSelfAssessmentOngoing,
  getSelfAssessmentMarkedAsFinished,

  getPossibleAuditTemplatesForCurrentAudit,
  getSelectedTemplateIds,
  getPreselectionFilterAggregations,
  getAuditItemAggregators,
  getAuditItemPreselectionAggregators,
  getFilterTreeForFrontendFiltering,
  getFilterTreeForNotConsideredAuditItems,
  getPreselectionFilterTree,
  getAuditItemIdsBaseSetAccordingToAuditState,

  getConsideredAuditItemIds,
  getTotalAuditItemCount,

  getAuditItemProperties,
  getPreparationState,
  getPossiblePreparationStates,
  getPreparationStateTransitions,
  getMappedTags,
  getMappedNotes,
  totalConsideredAuditItemIds,
  getNotConsideredAuditItemIds,
  getMaturity,
  getMaturityEnabled,
  getAuditItemsWithMissingMaturity,
  getForceFullFindingEditor,
  getOpenProcessAfterCreation,
  getIsDeleted,
};

function buildRefChecker(
  auditItemProperties: Map<AuditItemWithId["id"], AuditItemProperties>
): (auditItemId: string) => boolean {
  return auditItemId => {
    return auditItemProperties.get(auditItemId)?.isConsidered ?? true;
  };
}

const getterTree: GetterTree<CurrentAuditState, RootState> = {
  [n.getAuditDocumentData](
    state,
    getters,
    rootState,
    rootGetters
  ): nullable<AuditMetadataClient> {
    const Document = state.Document;
    if (Document && Document.data && Document.id !== "") {
      return Document.data;
    } else {
      return null;
    }
  },
  [n.hasAuditDocument](state): boolean {
    const Document = state.Document;
    return Document !== null && Document.id !== "";
  },
  [n.getAuditFormSchema](
    state,
    getters,
    rootState,
    rootGetters
  ): (formId: string) => FormSchemaDynamic {
    return (formId: string) => {
      const audit =
        state.Document && state.Document.data ? state.Document.data : null;
      const groupId = "audit";
      const auditClass =
        audit && audit.audit_class ? audit.audit_class : undefined;

      const formSchema = (
        rootGetters[
          getterNs(schemasApi, schemasApi.getters.getSchemaFor)
        ] as GetSchemaForType
      )(groupId, formId, auditClass);
      console.log(
        n.getAuditFormSchema,
        "audit",
        formId,
        auditClass,
        formSchema
      );

      if (formSchema === null) {
        throw createError(
          "Form Schema not found for",
          "audit",
          formId,
          auditClass
        );
      }

      return formSchema;
    };
  },
  [n.getAuditFormData](
    state,
    getters,
    rootState,
    rootGetters
  ): (formId: string) => any {
    return (formId: string) => {
      const audit =
        state.Document && state.Document.data ? state.Document.data : null;
      const result = audit ?? {};
      console.log(n.getAuditFormData, ", form=", formId, result);
      return result;
    };
  },

  [n.getAuditId](state, getters, rootState, rootGetters): nullable<string> {
    const Document = state.Document;
    if (Document && Document.id !== "") {
      return Document.id;
    } else {
      return null;
    }
  },
  [n.getAuditName](state, getters, rootState, rootGetters) {
    const Document = getters[
      n.getAuditDocumentData
    ] as nullable<AuditMetadataClient>;
    if (Document) {
      return formatAuditName(Document);
    } else {
      return null;
    }
  },
  [n.getAuditProgramId](state, getters, rootState, rootGetters) {
    const Document = getters[
      n.getAuditDocumentData
    ] as nullable<AuditMetadataClient>;
    if (Document && Document.auditProgramId) {
      return Document.auditProgramId;
    } else {
      return null;
    }
  },
  [n.getAuditProgramConfig](state, getters, rootState, rootGetters) {
    const auditProgramMap = rootGetters[
      getterNs(confApi, confApi.getters.getMappedAuditPrograms)
    ] as Dictionary<AuditProgramConfig>;

    const auditProgramId = getters[n.getAuditProgramId] as nullable<string>;

    if (auditProgramId !== null) {
      return auditProgramMap[auditProgramId];
    } else {
      return null;
    }
  },
  [n.getAuditStatus](state, getters): string | null {
    const Document = getters[
      n.getAuditDocumentData
    ] as nullable<AuditMetadataClient>;
    if (
      Document &&
      FieldPartNames.AUDIT_WORKFLOW in Document &&
      "status" in Document.workflow
    ) {
      return Document.workflow.status;
    } else if (Document) {
      return AuditStatusId.Planning;
    } else {
      return null;
    }
  },
  [n.getIsVdaAudit](state, getters): boolean {
    const auditClass = getters[n.getAuditClass] as nullable<AuditClassClient>;

    const mappedAuditItemTypes = getters[
      n.getMappedAuditItemTypes
    ] as AuditItemTypeMap;

    return !!auditClass && isVdaAuditClass(auditClass, mappedAuditItemTypes);
  },
  [n.getIsVdaMatrixRequired](state, getters): boolean {
    const isVdaAudit = getters[n.getIsVdaAudit] as boolean;
    const auditClass = getters[n.getAuditClass] as nullable<AuditClassClient>;

    const vdaMatrixRequired =
      !!auditClass && !!auditClass?.vdaConfig?.requiresVdaMatrix;

    return isVdaAudit && vdaMatrixRequired;
  },

  [n.getAuditItemsForList](
    state,
    getters,
    rootState,
    rootGetters
  ): Array<AuditItemWithId> {
    const mappedAuditItems = allAuditItemsMapped(rootGetters);
    const auditItemIdsPaged = getters[n.getAuditItemIdsPaged] as string[];

    const idableAuditItemsPaged = auditItemIdsPaged.map(
      lookupAndMakeIdable(mappedAuditItems)
    );
    return idableAuditItemsPaged.filter(typeIsNotEmpty);
  },
  [n.getAuditItems](
    state,
    getters,
    rootState,
    rootGetters
  ): Array<AuditItemWithId> {
    const mappedAuditItems = allAuditItemsMapped(rootGetters);
    const consideredAuditItemIds = getters[
      n.getConsideredAuditItemIds
    ] as string[];

    return consideredAuditItemIds
      .map(lookupAndMakeIdable(mappedAuditItems))
      .filter(typeIsNotEmpty);
  },
  [n.getAreMultipleStandardsReferenced](state): boolean {
    const knownStandardIds = new Set<string>();
    forEach(state.AuditItemsDocument.data?.auditItems ?? {}, auditItem => {
      auditItem.question.chapters.forEach(chapterRef => {
        knownStandardIds.add(chapterRef.standardId);
      });
    });

    return knownStandardIds.size > 1;
  },
  [n.getAuditItemsForPreparation](
    state,
    getters,
    rootState,
    rootGetters
  ): Array<AuditItemWithId> {
    const auditItemIds = getters[n.getAuditItemIdsFiltered] as Array<
      AuditItemWithId["id"]
    >;
    const mappedAuditItems = state.AuditItemsDocument.data?.auditItems ?? {};
    const preparedAuditItems = auditItemIds
      .map(lookupAndMakeIdable(mappedAuditItems))
      .filter(typeIsNotEmpty)
      .map((auditItem): {
        auditItem: AuditItemWithId;
        findings: Array<idable<Finding>>;
      } => {
        return {
          auditItem,
          findings: [],
        };
      });

    const allSortManipulators = getters[
      n.getAllSortManipulators
    ] as SortManipulator[];

    const baseOrderSortingSettings = [
      { desc: false, id: ListManipulatorIds.SORT_NO },
    ];

    const sortedAuditItems = do_sort(
      allSortManipulators,
      baseOrderSortingSettings,
      preparedAuditItems
    );

    return sortedAuditItems.map(entry => entry.auditItem);
  },
  [n.getAuditItemsFilteredByFulltextSearch](
    state,
    getters
  ): AuditItemIdsFilteredWithSearchResults {
    const auditItemIds = getters[
      n.getAuditItemIdsBaseSetAccordingToAuditState
    ] as Array<AuditItemWithId["id"]>;
    const mappedAuditItems = state.AuditItemsDocument.data?.auditItems ?? {};
    const auditItemLookUp = (auditItemId: AuditItemWithId["id"]) => {
      return mappedAuditItems[auditItemId] ?? null;
    };
    const questionNotesMapped = getters[n.getMappedNotes] as Map<
      AuditItemWithId["id"],
      string[]
    >;

    return fulltextSearchAuditItems(
      state.fulltextSearch,
      auditItemIds,
      questionNotesMapped,
      auditItemLookUp,
      ct
    );
  },

  [n.getSearchResultsByAuditItemIds](state, getters): QuestionSearchResultMap {
    console.log(n.getSearchResultsByAuditItemIds);
    const auditItemIdsFilteredwithSearchResults = getters[
      n.getAuditItemsFilteredByFulltextSearch
    ] as AuditItemIdsFilteredWithSearchResults;
    return auditItemIdsFilteredwithSearchResults.searchResultsByAuditItemIds;
  },
  [n.getAuditItemIdsFiltered](state, getters): Array<AuditItemWithId["id"]> {
    const auditItemIdsFilteredwithSearchResults = getters[
      n.getAuditItemsFilteredByFulltextSearch
    ] as AuditItemIdsFilteredWithSearchResults;

    const frontendFilterTree = getters[
      n.getFilterTreeForFrontendFiltering
    ] as FrontendFilterTree;

    const activeFilter = getters[n.getActiveFrontendFilter] as Filter[];

    const fulltextSearchFilteredAuditItemIds =
      auditItemIdsFilteredwithSearchResults.auditItemIds;

    return calculateFilteredItemIds(
      frontendFilterTree,
      activeFilter,
      fulltextSearchFilteredAuditItemIds
    );
  },
  [n.getFullTextSearch](state): string {
    return state.fulltextSearch;
  },
  [n.getAuditItemIdsSorted](state, getters, rootState, rootGetters) {
    console.log(n.getAuditItemIdsSorted);
    const findingsLookup = buildFindingLookup(state, getters);
    const mappedAuditItems = allAuditItemsMapped(rootGetters);

    const auditItemIdsFiltered = getters[n.getAuditItemIdsFiltered] as string[];

    const sortManipulator = getters[
      n.getAuditItemListSortManipulators
    ] as SortManipulator[];

    const sortSettings = getters[
      n.getAuditItemListSortSettings
    ] as SortSetting[];

    const auditItemIdsMapped = auditItemIdsFiltered.map(id => {
      const auditItem = { ...mappedAuditItems[id], id };
      return {
        auditItem,
        findings: findingsLookup(id),
      };
    });

    const allSortManipulators = getters[
      n.getAllSortManipulators
    ] as SortManipulator[];
    const isVdaAudit = getters[n.getIsVdaAudit] as boolean;

    // Basisreihenfolge festlegen
    // TODO: gibt es eine Audititem reihenfolge [Array der auditItemIds]

    const baseOrderSortingSettings = [
      { desc: false, id: ListManipulatorIds.SORT_NO },
    ];
    if (isVdaAudit) {
      baseOrderSortingSettings.push(
        { desc: false, id: ListManipulatorIds.SORT_VDA_PROCESSES },
        { desc: false, id: ListManipulatorIds.SORT_VDA_SCOPE }
      );
    }

    const auditItemsFiltered = do_sort(
      allSortManipulators,
      baseOrderSortingSettings,
      auditItemIdsMapped
    );

    console.time(n.getAuditItemIdsSorted);

    const sortedItems = do_sort(
      sortManipulator,
      sortSettings,
      auditItemsFiltered
    );

    console.timeEnd(n.getAuditItemIdsSorted);

    return sortedItems.map(ai => ai.auditItem.id);
  },
  [n.getAuditItemIdsPaged](state, getters) {
    const auditItemIdsSorted = getters[n.getAuditItemIdsSorted] as string[];
    if (state.pageConfig.pageSize) {
      const start = (state.pageConfig.page - 1) * state.pageConfig.pageSize;
      const end = state.pageConfig.page * state.pageConfig.pageSize;
      return auditItemIdsSorted.slice(start, end);
    } else {
      return auditItemIdsSorted;
    }
  },

  [n.getPageCount](state, getters) {
    const itemsCount = (getters[n.getAuditItemIdsSorted] as string[]).length;
    if (state.pageConfig.pageSize) {
      return Math.max(Math.ceil(itemsCount / state.pageConfig.pageSize), 1);
    } else {
      return 1;
    }
  },
  [n.getCurrentPage](state, getters) {
    return state.pageConfig.page;
  },
  [n.getPageSizes](state) {
    return [25, 50, 100, null];
  },
  [n.getMappedAuditItems](
    state,
    getters
  ): ReturnType<typeof allAuditItemsMapped> {
    const Document = state.AuditItemsDocument;
    if (Document && Document.data && Document.id !== "") {
      const result = {};

      Object.entries(Document.data.auditItems).forEach(([id, item]) => {
        result[id] = {
          id,
          ...item,
        };
      });
      return result;
    } else {
      return {};
    }
  },
  [n.getPlainAuditItemsMapped](
    state
  ): ReturnType<typeof allPlainAuditItemsMapped> {
    return state.AuditItemsDocument.data?.auditItems ?? {};
  },
  [n.getTotalCountAuditItem](state, getters): number {
    const mappedAuditItem = getters[n.getMappedAuditItems];
    return size(mappedAuditItem);
  },

  [n.getHasSelectedAuditItemIds](state): boolean {
    return state.selectedAuditItemIds.length > 0;
  },
  [n.getSelectedAuditItemIds](state): Array<AuditItemWithId["id"]> {
    return state.selectedAuditItemIds;
  },

  [n.getAuditCompanyMetadataDialogData](state) {
    const Document = state.Document;
    if (Document && Document.data) {
      return {
        id: Document.id,
        companyMetadata: Document.data.companyMetadata,
      };
    } else {
      return null;
    }
  },
  [n.getAuditMetadata](state, getters): AuditMetadataClient | null {
    const Document = state.Document;

    if (Document && Document.data) {
      return cloneDeep(Document.data);
    } else {
      return null;
    }
  },
  getExcelMetadataCollection,
  getAuditReportSummary,
  /**
   *
   * @deprecated use getter auditResult.getters.getScoreCalcSteps
   */
  [n.getScoreCalcStepsDeprecated](
    state,
    getters,
    rootState,
    rootGetters
  ): AuditScoreAggregatedTotal {
    const auditCategories = getters[
      n.getAuditCategoryMapping
    ] as AuditItemCategoryMap;

    const audit_score_data = getters[
      n.getFlatAuditScoreData
    ] as FlatAuditScoreData[];

    return scoreCalcSteps(auditCategories, audit_score_data, ct);
  },

  [n.getAuditProgress](state, getters): AuditProgress {
    const unansweredAuditItems = getters[
      n.getUnAnsweredAuditItems
    ] as AuditItemWithId[];

    const audit_items = getters[n.getAuditItems] as AuditItemWithId[];
    const count_audititems = audit_items.length;
    const count_answered_auditems =
      count_audititems - unansweredAuditItems.length;
    const progress = Math.round(
      (100 * count_answered_auditems) / count_audititems
    );

    return {
      audititem_count: count_audititems,
      audititem_answered_count: count_answered_auditems,
      progress,
    };
  },
  getMeasureProcessCompletionPercentage,
  getFlatExportData,
  getFlatReportData,
  getAuditStatistics,
  getAuditItemsInConflictState,
  getAttachment2AnnotationsMap,
  [n.getAuditScore](state, getters): nullable<AuditScoreTotal> {
    const audit_findings = getters[
      n.getAuditFindingsFlat
    ] as FlatAuditItemDataFindingBased[];

    const calcBase = getters[
      n.getAuditReportCalcSchema
    ] as nullable<PointsCalculationStrategy>;
    if (calcBase === null) {
      return null;
    }
    return calcAuditScore(audit_findings, calcBase);
  },
  [n.getDimensionGroupedData](
    state,
    getters
  ): {
    dimensionId: string | null;
    groupedData: FlatAuditItemDataFindingBased[];
  }[] {
    const audit_findings = getters[
      n.getAuditFindingsFlat
    ] as FlatAuditItemDataFindingBased[];

    const noDimensionRows: FlatAuditItemDataFindingBased[] = [];
    const groupedDimensions: Dictionary<FlatAuditItemDataFindingBased[]> = {};

    audit_findings.forEach(row => {
      if (isString(row.findingDimensionId)) {
        const dims = groupedDimensions[row.findingDimensionId];
        if (dims) {
          dims.push(row);
        } else {
          groupedDimensions[row.findingDimensionId] = [row];
        }
      } else {
        noDimensionRows.push(row);
      }
    });

    // Todo Sort dimensions ....
    return [
      ...map(groupedDimensions, (groupedData, dimensionId) => ({
        groupedData,
        dimensionId,
      })),
      { dimensionId: null, groupedData: noDimensionRows },
    ];
  },
  [n.getAuditScores](
    state,
    getters
  ): { dimensionId: string | null; score: nullable<AuditScoreTotal> }[] {
    const groupedData = getters[n.getDimensionGroupedData] as {
      dimensionId: string | null;
      groupedData: FlatAuditItemDataFindingBased[];
    }[];

    const calcBase = getters[
      n.getAuditReportCalcSchema
    ] as nullable<PointsCalculationStrategy>;

    return groupedData.map(dimRows => {
      return {
        dimensionId: dimRows.dimensionId,
        score: calcBase ? calcAuditScore(dimRows.groupedData, calcBase) : null,
      };
    });
  },
  [n.getAuditFindingsFlat](
    state,
    getters,
    rootState
  ): FlatAuditItemDataFindingBased[] {
    const audit_items = getters[n.getAuditItems] as AuditItemWithId[];
    const calcBase = getters[
      n.getAuditReportCalcSchema
    ] as nullable<PointsCalculationStrategy>;
    const mappedFindingTypes = getters[
      n.getMappedFindingTypes
    ] as FindingTypeMap;

    const mappedAuditItemTypes = getters[
      n.getMappedAuditItemTypes
    ] as AuditItemTypeMap;

    const mappedCategories = getters[
      n.getAuditCategoryMapping
    ] as AuditItemCategoryMap;
    const categoryLevel = getters[n.getCategoryLevel] as CategoryLevel;
    const auditItemFindingsmapping = getters[
      n.getAuditItemId2FindingIdsMap
    ] as Dictionary<string[]>;

    if (typeof audit_items === "undefined" || calcBase === null) {
      return [];
    } else {
      return flattenAuditItems(
        audit_items,
        state.AuditItemsDocument.data?.findings ?? {},
        auditItemFindingsmapping,
        mappedFindingTypes,
        mappedAuditItemTypes,
        mappedCategories,
        categoryLevel,
        calcBase
      );
    }
  },
  [n.getFindingIds](state, getters, rootState, rootGetters): string[] {
    // Filter out un assigned Findings

    const auditItemProperties = getters[n.getAuditItemProperties] as Map<
      AuditItemWithId["id"],
      AuditItemProperties
    >;
    const checkRef = buildRefChecker(auditItemProperties);

    return toPairs(state.AuditItemsDocument.data?.findings ?? {})
      .filter(([findingId, finding]) => {
        return finding.auditItemRefs.map(ref => ref.auditItemId).some(checkRef);
      })
      .map(([findingId, finding]) => {
        return findingId;
      });
  },
  [n.getFindingsMap](state): { [findingId: string]: Finding } {
    return state.AuditItemsDocument.data?.findings ?? {};
  },
  [n.getAuditItemId2FindingIdsMap](state, getters): Dictionary<string[]> {
    const findings = state.AuditItemsDocument.data?.findings ?? {};
    const uniqueConsideredAuditItemIds =
      state.Document.data?.totalConsideredAuditItemIds ?? [];

    const auditItemsMap = uniqueConsideredAuditItemIds.reduce(
      (p, auditItemId) => {
        return { ...p, [auditItemId]: [] };
      },
      {} as Dictionary<string[]>
    );

    const isResultVisible = getters[n.getIsResultVisible] as boolean;
    if (isResultVisible) {
      const mapping = reduce(
        findings,
        (prev, finding, findingId) => {
          const auditItemIds = auditItemRefsToIdList(finding.auditItemRefs);
          return auditItemIds.reduce((p, auditItemId) => {
            const additional = {
              [auditItemId]: [...(p[auditItemId] ?? []), findingId],
            };
            return { ...p, ...additional };
          }, prev);
        },
        auditItemsMap
      );

      return mapping;
    } else {
      return auditItemsMap;
    }
  },
  [n.getAuditItemId2FindingsMap](
    state,
    getters
  ): Dictionary<idable<Finding>[]> {
    const auditItemId2FindingIdsMap = getters[
      n.getAuditItemId2FindingIdsMap
    ] as Dictionary<string[]>;
    const findings = state.AuditItemsDocument.data?.findings ?? {};
    const transform = lookupAndMakeIdable(findings);

    const mapping = mapValues(auditItemId2FindingIdsMap, findingIds => {
      return findingIds.map(transform).filter(typeIsNotEmpty);
    });

    return mapping;
  },
  [n.getSelfAssessments](state, getters): Dictionary<SelfAssessment> {
    return getSelfAssessmentsMapped(state);
  },
  [n.getAuditItemId2SelfAssessmentMap](
    state,
    getters
  ): StrictDictionary<idable<SelfAssessment>[]> {
    const selfAssessments = getSelfAssessmentsMapped(state);
    const auditItems = getters[n.getAuditItems] as Array<AuditItemWithId>;

    const selfAssessmentsMap = auditItems.reduce((p, c) => {
      return { ...p, [c.id]: [] };
    }, {} as StrictDictionary<idable<SelfAssessment>[]>);

    const isResultVisible = getters[n.getIsResultVisible] as boolean;
    if (isResultVisible) {
      toPairs(selfAssessments).forEach(([id, assessment]) => {
        assessment.auditItemIds.forEach(aiId => {
          const item = selfAssessmentsMap[aiId];
          if (item) {
            item.push({ id, ...assessment });
          }
        });
      });
    }
    return selfAssessmentsMap;
  },
  [n.getUnAnsweredAuditItems](state, getters): AuditItemWithId[] {
    const auditItemFindingsmapping = getters[
      n.getAuditItemId2FindingIdsMap
    ] as Dictionary<string[]>;

    const auditItemStatus = getters[n.getAuditItemDimensionAnswerStatusMap] as {
      [auditItemId: string]: AnswerStatus[];
    };

    return (getters[n.getAuditItems] as AuditItemWithId[]).filter(
      ({ id: auditItemId }) => {
        const findingsCount =
          auditItemFindingsmapping[auditItemId]?.length ?? 0;
        const hasDimensions = (auditItemStatus[auditItemId]?.length ?? 0) > 0;
        const areAllDimensionsAnswered = auditItemStatus[auditItemId].every(
          ai => ai.answered
        );

        return !(
          (!hasDimensions && findingsCount > 0) ||
          (hasDimensions && areAllDimensionsAnswered)
        );
      }
    );
  },
  [n.getResultAttachmentsMap](
    state,
    getters
  ): Dictionary<StoredAttachmentEntry> {
    return state.AuditItemsDocument.data?.attachments ?? {};
  },
  [n.getUnassignedFindingsCount](state, getters): number {
    const unassignedFindingIds = getters[n.getUnassignedFindingIds] as string[];
    return unassignedFindingIds.length;
  },
  [n.getFindingsAggregationForPolicyCheck](
    state,
    getters,
    rootState: RootState,
    rootGetters: Dictionary<unknown>
  ): FindingMeasurePolicyAggregation[] {
    const findingMeasuresMap = rootGetters[
      getterNs(measuresApi, measuresApi.getters.getMappedFindingId2MeasureIds)
    ] as {
      [findingId: string]: string[];
    };

    const mappedMeasures = rootGetters[
      getterNs(measuresApi, measuresApi.getters.getMappedMeasures)
    ] as Dictionary<MeasureProcessDocument>;

    const findingsAggregations = map(
      state.AuditItemsDocument.data?.findings ?? {},
      (finding, findingId): FindingMeasurePolicyAggregation => {
        const measureIds = findingMeasuresMap[findingId] ?? [];
        const measureTypes = mapValues(
          groupBy(
            map(pick(mappedMeasures, measureIds), (measure): string => {
              return measure.additionalWorkflowMetadata.type;
            }),
            v => v
          ),
          v => v.length
        );

        return {
          findingId,
          auditItemId:
            first(uniq(finding.auditItemRefs.map(ai => ai.auditItemId))) ??
            null,
          findingTypeId: finding.type,
          measureTypes,
        };
      }
    );
    return findingsAggregations;
  },
  [n.getMeasurePolicyViolations](
    state,
    getters,
    rootState: RootState,
    rootGetters: Dictionary<unknown>
  ): MeasurePolicyViolation[] {
    const findingsAggregations = getters[
      n.getFindingsAggregationForPolicyCheck
    ] as FindingMeasurePolicyAggregation[];

    const auditClass = getters[n.getAuditClass] as nullable<AuditClassClient>;
    const type =
      auditClass?.measurePolicyPerFindingType.policyType ?? "non-blocking";

    return calculateMeasurePolicyViolations(
      auditClass?.measurePolicyPerFindingType.policies ?? {},
      findingsAggregations
    );
  },
  [n.getMeasurePoliciesStatus](
    state,
    getters,
    rootState: RootState,
    rootGetters: Dictionary<unknown>
  ): null | {
    type: MeasurePolicyPerFindingType["policyType"];
    level: MeasurePolicyPerFindingType["policies"][string][string];
  } {
    const measurePolicyViolations = getters[
      n.getMeasurePolicyViolations
    ] as MeasurePolicyViolation[];

    const auditClass = getters[n.getAuditClass] as nullable<AuditClassClient>;
    const type =
      auditClass?.measurePolicyPerFindingType.policyType ?? "non-blocking";

    const level = extractApplicableMeasurePolicyViolation(
      measurePolicyViolations
    );

    if (!level) {
      return null;
    } else {
      return { type, level };
    }
  },
  [n.getUnassignedFindingIds](state, getters): string[] {
    const allFindingIds = keys(state.AuditItemsDocument.data?.findings ?? {});
    const assignedFindingIds = getters[n.getAssignedFindingIds] as string[];

    return difference(allFindingIds, assignedFindingIds);
  },
  [n.getUnassignedFindings](state, getters): idable<Finding>[] {
    const unassignedFindingIds = getters[n.getUnassignedFindingIds] as string[];
    const findings = state.AuditItemsDocument.data?.findings ?? {};

    return unassignedFindingIds
      .map(lookupAndMakeIdable(findings))
      .filter(typeIsNotEmpty);
  },
  [n.getAssignedFindingIds](state, getters, rootState, rootGetters): string[] {
    const uniqueConsideredAuditItemIds = new Set(
      state.Document.data?.totalConsideredAuditItemIds ?? []
    );
    return reduce(
      state.AuditItemsDocument.data?.findings ?? {},
      (acc, finding, findingId) => {
        if (
          (finding.auditItemRefs ?? []).some(({ auditItemId }) => {
            return uniqueConsideredAuditItemIds.has(auditItemId);
          })
        ) {
          return [...acc, findingId];
        } else {
          return acc;
        }
      },
      [] as string[]
    );
  },
  [n.getAuditItemId2Setting](
    state,
    getters,
    rootState,
    rootGetters
  ): AuditItemSettings {
    const mappedAuditItems = allAuditItemsMapped(rootGetters);
    const auditItemId2FindingIdsMap = getters[
      n.getAuditItemId2FindingIdsMap
    ] as Dictionary<string[]>;

    const auditItemDimensionsMap = getters[
      n.getAuditItemDimensionsMap
    ] as AuditItemDimensionsMap;

    const mappedFindingTypes = getters[
      n.getMappedFindingTypes
    ] as FindingTypeMap;

    const findings = state.AuditItemsDocument.data?.findings ?? {};

    const hasDimensions = (auditItemId: string) => {
      return (auditItemDimensionsMap[auditItemId]?.length ?? 0) > 0;
    };

    const hasFindingThatBlocks = (auditItemId: string) => {
      const findingIds = auditItemId2FindingIdsMap[auditItemId] ?? [];
      const findingCount = findingIds.length;

      const findingTypeIds = findingIds
        .map(findingId => findings[findingId]?.type ?? null)
        .filter(typeIsNotEmpty);
      const findingTypes = findingTypeIds
        .map(findingTypeId => mappedFindingTypes[findingTypeId] ?? null)
        .filter(typeIsNotEmpty);

      return (
        findingCount === 0 ||
        findingTypes.every(
          findingType =>
            !BLOCKING_AUDIT_ITEM_STATES.includes(findingType.auditItemState)
        )
      );
    };

    const calcNotBlockedDimensionIds = (auditItemId: string) => {
      const findingIds = auditItemId2FindingIdsMap[auditItemId] ?? [];
      const findingCount = findingIds.length;
      const findingType2BlockingMap = mapValues(
        mappedFindingTypes,
        findingType => {
          return BLOCKING_AUDIT_ITEM_STATES.includes(
            findingType.auditItemState
          );
        }
      );

      const blockedDimensions = new Set(
        flatten(
          findingIds.map(findingId => {
            const finding = findings[findingId];
            const findingTypeId = finding?.type ?? null;
            const isBlockingFindingType =
              findingType2BlockingMap[findingTypeId] ?? false;
            if (!isBlockingFindingType) {
              return [];
            } else {
              const auditItemRefs = (finding.auditItemRefs ?? []).filter(
                ref =>
                  ref.auditItemId === auditItemId && ref.dimensions !== null
              );
              return flatMap(auditItemRefs, ref => {
                return ref.dimensions ?? [];
              });
            }
          })
        )
      );
      const possibleDimensions = auditItemDimensionsMap[auditItemId] ?? [];
      return possibleDimensions.filter(
        dimensionId => !blockedDimensions.has(dimensionId)
      );
    };

    const settings = mapValues(mappedAuditItems, (auditItem, auditItemId) => {
      if (hasDimensions(auditItemId)) {
        const openDimensions = calcNotBlockedDimensionIds(auditItemId);
        return {
          allowMoreFindings: openDimensions.length > 0,
          openDimensions,
        };
      } else {
        return {
          allowMoreFindings: hasFindingThatBlocks(auditItemId),
          openDimensions: null,
        };
      }
    });

    return settings;
  },
  [n.getIsResultVisible](state, getters): boolean {
    // Todo: Replace with generic Solution and not customer specific.
    const auditRoles = getters[n.getAuditRoles] as string[];
    const auditClass = getters[n.getAuditClassId] as nullable<string>;
    const auditStatus = getters[n.getAuditStatus] as nullable<string>;

    if (
      (auditClass ?? "").startsWith("targetProcess-") &&
      !(
        auditStatus === AuditStatusId.Reporting ||
        auditStatus === AuditStatusId.MeasureActions ||
        auditStatus === AuditStatusId.Completed
      )
    ) {
      return (
        intersection(auditRoles, [
          AuditRoles.CO_AUDITOR,
          AuditRoles.LEAD_AUDITOR,
          SystemRoles.ADMINISTRATOR,
          SystemRoles.SYSTEM_ADMINISTRATOR,
        ]).length > 0
      );
    }

    console.log("getIsResultVisible", auditRoles, auditClass);
    return true;
  },
  [n.getIsAuditItemNoteVisible](state, getters): boolean {
    const auditRoles = getters[n.getAuditRoles] as string[];

    return (
      intersection(auditRoles, [AuditRoles.CO_AUDITOR, AuditRoles.LEAD_AUDITOR])
        .length > 0
    );
  },

  [n.getFlatAuditScoreData](state, getters, rootState): FlatAuditScoreData[] {
    const calcBase = getters[
      n.getAuditReportCalcSchema
    ] as nullable<PointsCalculationStrategy>;
    if (calcBase === null) {
      return [];
    }
    const mappedFindingTypes = getters[
      n.getMappedFindingTypes
    ] as FindingTypeMap;
    const mappedAuditItemTypes = getters[
      n.getMappedAuditItemTypes
    ] as AuditItemTypeMap;
    const mappedCategories = getters[
      n.getAuditCategoryMapping
    ] as AuditItemCategoryMap;

    const filteredAuditItems = getters[n.getAuditItems] as AuditItemWithId[];
    const findingsLookup = buildFindingLookup(state, getters);

    const audit_items_flat = filteredAuditItems.map(auditItem => {
      const auditItemId = auditItem.id;
      const findings = findingsLookup(auditItemId).map(finding => {
        const findingId = finding.id;

        const findingTypeId = finding.type;
        const findingType: FindingType = mappedFindingTypes[findingTypeId];
        const is_no_deviation = findingType.is_no_deviation;
        const is_not_applicable = findingType.is_not_applicable;
        return {
          findingId,
          findingTypeId,
          findingWeight: findingType.value,
          is_no_deviation,
          is_not_applicable,
        };
      });

      const deviation_count = findings.reduce(
        (prev, finding) => prev + (finding.is_no_deviation ? 0 : 1),
        0
      );
      const auditItemWeight =
        mappedAuditItemTypes[auditItem.question.type].value;

      // Anwendbare AuditItems haben nur Antworten welche nicht NZ
      const isApplicable = findings.every(
        finding => !finding.is_not_applicable
      );

      const appreciable_finding_value = calcBase.get_appreciable_finding_value(
        findings.map(finding => finding.findingWeight)
      );

      const categoryLevel = getters[n.getCategoryLevel] as CategoryLevel;

      const res: FlatAuditScoreData = {
        auditItemId,
        auditItemTypeId: auditItem.question.type,
        auditItemWeight,
        auditItemText: ct(auditItem.question.text),

        ...flattenCategoryRefForExport(
          auditItem.question.categoryRef,
          categoryLevel,
          mappedCategories
        ),

        findingsCount: findings.length,
        deviationCount: deviation_count,
        isNotApplicable: !isApplicable,
        appreciableFindingValue: appreciable_finding_value,
        bestScore: auditItemWeight * calcBase.best_finding_value,
        worstScore: auditItemWeight * calcBase.worst_finding_value,
        currentScore: auditItemWeight * appreciable_finding_value,
      };
      return res;
    });

    // Todo: auditItemWeight == 0 -> AuditItem wird für den Ergebnis-Score ignoriert
    return audit_items_flat.filter(aiData => aiData.auditItemWeight !== 0);
  },

  [n.getAuditReportCalcSchema](
    state,
    getters
  ): nullable<PointsCalculationStrategy> {
    const active_audit = getters[
      n.getAuditDocumentData
    ] as nullable<AuditMetadataClient>;
    const findingTypesMap = getters[n.getMappedFindingTypes] as FindingTypeMap;
    if (active_audit === null || active_audit.reportSchema == null) {
      return null;
    }

    return buildPointsCalculationStrategy(
      active_audit.reportSchema,
      findingTypesMap
    );
  },

  [n.getDefaultCalcSchemaName](state, getters, rootState) {
    return (
      rootState.configuration.audit.reportSchema || ReportSchemaE.Compliance
    );
  },
  [n.isExistingDocument](state) {
    const Document = state.Document;
    return !Document || Document.exists;
  },
  [n.hasPendingWrites](state) {
    const Document = state.Document;
    if (Document && Document.metadata) {
      return Document.metadata.hasPendingWrites;
    } else {
      return false;
    }
  },
  [n.isCachedDocument](state) {
    const Document = state.Document;
    if (Document && Document.metadata) {
      return Document.metadata.fromCache;
    } else {
      return false;
    }
  },
  [n.getDocumentOnlineState](state) {
    const Document = state.Document;
    if (Document && Document.metadata) {
      const { fromCache, hasPendingWrites } = Document.metadata;
      if (fromCache && hasPendingWrites) {
        return "unsync";
      } else if (!fromCache && hasPendingWrites) {
        return "unsaved";
      } else if (fromCache && !hasPendingWrites) {
        return "cached";
      } else {
        return "sync";
      }
    } else {
      return null;
    }
  },

  [n.getAuditCategorySetId](state, getters): string {
    const audit = getters[
      n.getAuditDocumentData
    ] as nullable<AuditMetadataClient>;
    if (audit && audit.categorySetId) {
      return audit.categorySetId;
    } else {
      console.warn("not categorySetId found ...");
      return "";
    }
  },
  [n.getAuditCategory](
    state,
    getters,
    rootState,
    rootGetters
  ): AuditItemCategory[] {
    const categorySetMap = rootGetters[
      getterNs(confApi, confApi.getters.getCategoryMapping)
    ] as AuditItemCategorySetMap;
    const audit = getters[
      n.getAuditDocumentData
    ] as nullable<AuditMetadataClient>;
    if (audit && audit.categorySetId && categorySetMap[audit.categorySetId]) {
      return categorySetMap[audit.categorySetId].categories;
    } else {
      console.warn("not categorySet found ...");
      return [];
    }
  },
  [n.getAuditCategoryMapping](
    state,
    getters,
    rootState,
    rootGetters
  ): AuditItemCategoryMap {
    const mappedCategories = extractCategories(
      getters[n.getAuditCategory] as AuditItemCategory[]
    );
    return mappedCategories;
  },
  [n.getWorkflowSteps](state) {
    return state.workflowSteps;
  },
  [n.getWorkflowHistory](state, getters): null | WorkflowHistoryType[] {
    const doc = getters[
      n.getAuditDocumentData
    ] as nullable<AuditMetadataClient>;
    if (doc && doc.workflow.log && doc.workflow.log.length > 0) {
      return doc.workflow.log.map((log, idx) => {
        const from = log.from
          ? state.workflowSteps[parseInt(log.from, 10)].text
          : "common.workflow.created";
        console.log(
          "workflow",
          log,
          from,
          state.workflowSteps[parseInt(log.to, 10)].text
        );
        return {
          changedAt: log.timestamp.toDate(),
          user: log.user,
          status: {
            from,
            to: state.workflowSteps[parseInt(log.to, 10)].text,
          },
          comment: log.comment,
          no: idx,
        };
      });
    } else {
      return null;
    }
  },

  [n.isAuditStateDirty](state) {
    return state.stateDirty;
  },
  [n.isAuditTransitionDirty](state) {
    return state.transitionDirty;
  },

  [n.getFindingTypesForInput](state, getters): FindingType[] {
    const findingTypeConfig = getters[
      n.getFindingTypeConfig
    ] as nullable<FindingTypeConfig>;
    if (findingTypeConfig) {
      return findingTypeConfig.inputOrder.map(
        findingTypeId => findingTypeConfig.types[findingTypeId]
      );
    } else {
      return [];
    }
  },
  [n.getMappedFindingTypes](state, getters): FindingTypeMap {
    const findingTypeConfig = getters[
      n.getFindingTypeConfig
    ] as nullable<FindingTypeConfig>;

    if (findingTypeConfig) {
      return {
        ...findingTypeConfig.types,
        [UNKNOWN_FINDING_TYPE_ID]: UNKNOWN_FINDING_TYPE,
      };
    } else {
      return {
        [UNKNOWN_FINDING_TYPE_ID]: UNKNOWN_FINDING_TYPE,
      };
    }
  },

  [n.getFindingTypeConfig](
    state,
    getters,
    rootState,
    rootGetters
  ): nullable<FindingTypeConfig> {
    const auditClass = getters[n.getAuditClassId] as nullable<string>;
    if (auditClass) {
      const findingTypeConfigMap = rootGetters[
        getterNs(confApi, confApi.getters.getFindingTypesMappedByAuditClass)
      ] as FindingTypeConfigMap;

      const findingConfig = findingTypeConfigMap[auditClass];
      return findingConfig || null;
    } else {
      return null;
    }
  },
  [n.getFinalizeExecutionFindingTypeId](state, getters): nullable<string> {
    const findingTypeConfig = getters[
      n.getFindingTypeConfig
    ] as nullable<FindingTypeConfig>;

    if (findingTypeConfig) {
      return findingTypeConfig.finalizeExecutionFindingTypeId;
    } else {
      return null;
    }
  },
  [n.getFindingsReportOrder](state, getters): string[] {
    const findingTypeConfig = getters[
      n.getFindingTypeConfig
    ] as nullable<FindingTypeConfig>;

    if (findingTypeConfig) {
      return findingTypeConfig.reportOrder;
    } else {
      return [];
    }
  },

  [n.getFindingQuickActionConfig](state, getters): QuickActionConfig {
    const findingTypeConfig = getters[
      n.getFindingTypeConfig
    ] as nullable<FindingTypeConfig>;

    const findingActions: QuickActionConfig = {
      actions: [],
      directActions: [],
      disableSegmentedSelection: false,
    };

    if (findingTypeConfig) {
      findingActions.actions = findingTypeConfig.inputOrder.map(
        findingTypeId => findingTypeConfig.types[findingTypeId]
      );
      findingActions.directActions = findingTypeConfig.directInput;
      findingActions.disableSegmentedSelection =
        findingTypeConfig.disableSegmentedSelection ?? false;
    }

    return findingActions;
  },
  [n.getAuditClassId](state, getters): nullable<string> {
    const doc = getters[
      n.getAuditDocumentData
    ] as nullable<AuditMetadataClient>;
    if (doc && doc.audit_class) {
      return doc.audit_class;
    } else {
      return null;
    }
  },
  [n.getAuditClass](
    state,
    getters,
    rootState,
    rootGetters
  ): nullable<AuditClassClient> {
    const auditClassId = getters[n.getAuditClassId] as nullable<string>;
    if (auditClassId !== null) {
      const mappedAuditClasses = rootGetters[
        getterNs(auditClassesApi, auditClassesApi.getters.mappedAuditClasses)
      ] as MappedAuditClasses;
      const auditClass = mappedAuditClasses[auditClassId];
      if (auditClass) {
        return {
          ...auditClass,
          id: auditClassId,
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  },
  /** Checks whether the config generally allows for unlinked (free) findings */
  [n.getUnlinkedFindingsEnabled](
    state,
    getters,
    rootState,
    rootGetters
  ): boolean {
    const auditClass = getters[n.getAuditClass] as nullable<AuditClassClient>;
    return auditClass !== null &&
      typeof auditClass.allowFreeFindings === "boolean"
      ? auditClass.allowFreeFindings
      : true;
  },
  /** Checks whether the current status of the audit allows for
   * unlinked (free) findings to exist. */
  [n.getUnlinkedFindingsAllowed](state, getters): boolean {
    const auditStatusId =
      (getters[n.getAuditMetadata] as AuditMetadataClient | null)?.workflow
        ?.status ?? "";

    const ALLOWED_STATES_FOR_UNLINKED_FINDINGS: string[] = [
      AuditStatusId.Execution,
      AuditStatusId.Wrapup,
    ];
    return ALLOWED_STATES_FOR_UNLINKED_FINDINGS.includes(auditStatusId);
  },
  [n.getAddRequirementsAllowed](state, getters): boolean {
    const auditStatusId =
      (getters[n.getAuditMetadata] as AuditMetadataClient | null)?.workflow
        ?.status ?? "";

    const ALLOWED_STATES_FOR_ADD_REQUIREMENTS: string[] = [
      AuditStatusId.Execution,
      AuditStatusId.Wrapup,
      AuditStatusId.Reporting,
    ];
    return ALLOWED_STATES_FOR_ADD_REQUIREMENTS.includes(auditStatusId);
  },
  [n.getAddFreeFindingsAllowed](state, getters): boolean {
    const auditStatusId =
      (getters[n.getAuditMetadata] as AuditMetadataClient | null)?.workflow
        ?.status ?? "";

    const ALLOWED_STATES_FOR_ADD_REQUIREMENTS: string[] = [
      AuditStatusId.Execution,
      AuditStatusId.Wrapup,
    ];
    return ALLOWED_STATES_FOR_ADD_REQUIREMENTS.includes(auditStatusId);
  },
  [n.getCategoryLevel](state, getters): CategoryLevel {
    const auditClass = getters[n.getAuditClass] as nullable<AuditClassClient>;
    return auditClass !== null
      ? auditClass.useCategoryLevel
      : CategoryLevelOptions.Root;
  },
  [n.getCategoryDepth](state, getters): number {
    const categoryLevel = getters[n.getCategoryLevel] as CategoryLevel;
    return getCategoryDepthOrDefault(categoryLevel);
  },
  [n.getAuditProgressDisplayType](state, getters): AuditProgressDisplayType {
    const auditClass = getters[n.getAuditClass] as nullable<AuditClassClient>;
    return auditClass !== null &&
      typeof auditClass.displayAuditProgress !== "undefined"
      ? auditClass.displayAuditProgress
      : "proportion";
  },
  [n.getAuditItemTypeConfig](
    state,
    getters,
    rootState,
    rootGetters
  ): nullable<AuditItemTypeConfig> {
    const auditClass = getters[n.getAuditClassId] as nullable<string>;
    if (auditClass) {
      const auditItemTypeConfigMap = rootGetters[
        getterNs(confApi, confApi.getters.getAuditItemTypesMappedByAuditClass)
      ] as AuditItemTypeConfigMap;

      const auditItemConfig = auditItemTypeConfigMap[auditClass];
      return auditItemConfig || null;
    } else {
      return null;
    }
  },
  [n.getMappedAuditItemTypes](
    state,
    getters,
    rootState,
    rootGetters
  ): AuditItemTypeMap {
    const auditItemTypeConfig = getters[
      n.getAuditItemTypeConfig
    ] as nullable<AuditItemTypeConfig>;
    if (auditItemTypeConfig) {
      return auditItemTypeConfig.types;
    } else {
      return {};
    }
  },
  [n.getAuditItemTypes](
    state,
    getters,
    rootState,
    rootGetters
  ): AuditItemType[] {
    const auditItemTypeConfig = getters[
      n.getAuditItemTypeConfig
    ] as nullable<AuditItemTypeConfig>;
    if (auditItemTypeConfig) {
      return Object.values(auditItemTypeConfig.types);
    } else {
      return [];
    }
  },
  [n.getAuditItemListManipulators](
    state,
    getters,
    rootState,
    rootGetters
  ): AuditItemListManipulators {
    const mappedCategories = getters[
      n.getAuditCategoryMapping
    ] as AuditItemCategoryMap;
    const findings_order = getters[n.getFindingsReportOrder] as string[];
    const mappedFindingTypes = getters[
      n.getMappedFindingTypes
    ] as FindingTypeMap;
    const mappedAuditItemTypes = getters[
      n.getMappedAuditItemTypes
    ] as AuditItemTypeMap;
    const categoryLevel = getters[n.getCategoryLevel] as CategoryLevel;
    const auditIdNote = getters[getMappedNotes] as Map<
      AuditItemWithId["id"],
      string[]
    >;
    return CreateAuditItemListManipulators(
      { ft, ct },
      mappedCategories,
      findings_order,
      mappedFindingTypes,
      mappedAuditItemTypes,
      categoryLevel,
      auditIdNote
    );
  },
  [n.getAuditItemListSortManipulators](
    state,
    getters,
    rootState,
    rootGetters
  ): SortManipulator[] {
    const manipulator = getters[
      n.getAuditItemListManipulators
    ] as AuditItemListManipulators;
    const availableSortings = getters[n.getAvailableSortingForList] as string[];
    return manipulator.sort_by.filter(v => availableSortings.includes(v.id));
  },
  // get all sort manipulators, independent of the configuration
  [n.getAllSortManipulators](
    state,
    getters,
    rootState,
    rootGetters
  ): SortManipulator[] {
    const manipulator = getters[
      n.getAuditItemListManipulators
    ] as AuditItemListManipulators;
    return manipulator.sort_by;
  },
  [n.getAvailableSortingForList](
    state,
    getters,
    rootState,
    rootGetters
  ): string[] {
    const listsConfigs = rootGetters[
      getterNs(confApi, confApi.getters.lists)
    ] as ListsConfigMap;
    if (
      listsConfigs &&
      listsConfigs[state.activeList] &&
      listsConfigs[state.activeList].manipulators !== null
    ) {
      console.log("Sortmanipulator:1", listsConfigs[state.activeList]);
      const manipulators = listsConfigs[state.activeList].manipulators;
      return manipulators?.availableSortings ?? [];
    } else {
      console.log("Sortmanipulator:2", listsConfigs);
      return [];
    }
  },
  [n.getAuditItemListSortSettings](
    state,
    getters,
    rootState,
    rootGetters
  ): SortSetting[] {
    if (state.listSettings[state.activeList]) {
      console.log("getAuditItemListSortSettings3", state.listSettings);
      return state.listSettings[state.activeList].sortings;
    } else {
      const listsConfigs = rootGetters[
        getterNs(confApi, confApi.getters.lists)
      ] as ListsConfigMap;
      if (
        listsConfigs[state.activeList] &&
        listsConfigs[state.activeList].manipulators !== null
      ) {
        console.log("getAuditItemListSortSettings1", listsConfigs);
        const manipulators = listsConfigs[state.activeList].manipulators;
        return manipulators ? manipulators.defaultSorting : [];
      } else {
        console.log("getAuditItemListSortSettings2");
        return [];
      }
    }
  },
  [n.getAuditorsForCurrentAudit](
    state,
    getters,
    rootState,
    rootGetters
  ): IUserRef[] {
    const Document = getters[
      n.getAuditDocumentData
    ] as nullable<AuditMetadataClient>;
    if (Document && Document.leadauditor) {
      return [Document.leadauditor, ...(Document.coauditors ?? [])];
    } else {
      return [];
    }
  },
  [n.getAvailableFiltersForList](
    state,
    getters,
    rootState,
    rootGetters
  ): ReturnType<typeof selectAvailableFiltersForList> {
    const listsConfigs = rootGetters[
      getterNs(confApi, confApi.getters.lists)
    ] as ListsConfigMap;
    const activeAggregations = selectAvailableFiltersForList(
      listsConfigs,
      state.activeList
    ).filter(
      aggregation => aggregation.id !== ListManipulatorIds.FILTER_DIMENSION
    );

    const auditClass = getters[n.getAuditClass] as nullable<AuditClassClient>;
    const isMultidimensionAuditWithStandards = isString(
      auditClass?.dimensionSource
    ); //  === "standard";

    if (isMultidimensionAuditWithStandards) {
      activeAggregations.push(
        initFilterConfigWithDefaults(ListManipulatorIds.FILTER_DIMENSION)
      );
    }

    return activeAggregations;
  },
  [n.getIsRestrictPreparationToCategories](
    state,
    getters,
    rootState,
    rootGetters
  ): boolean {
    const auditClass = getters[n.getAuditClass] as nullable<AuditClassClient>;

    const restrictPreparationToCategories =
      auditClass?.restrictPreparationToCategories ?? false;
    return restrictPreparationToCategories;
  },
  [n.getAvailableFiltersForPreselection](
    state,
    getters,
    rootState,
    rootGetters
  ): ReturnType<typeof selectAvailableFiltersForList> {
    const auditClass = getters[n.getAuditClass] as nullable<AuditClassClient>;
    const restrictPreparationToCategories = getters[
      n.getIsRestrictPreparationToCategories
    ] as boolean;
    const isMultidimensionAuditWithStandards =
      auditClass?.dimensionSource === "standard";

    const preselectionAggregations: string[] = [
      ListManipulatorIds.FILTER_CATEGORY,
    ];
    if (!restrictPreparationToCategories) {
      preselectionAggregations.push(ListManipulatorIds.FILTER_LABELS);

      if (isMultidimensionAuditWithStandards) {
        preselectionAggregations.push(ListManipulatorIds.FILTER_DIMENSION);
      }
    }

    const initFilterConfigWithPreselectionDefaults = (
      id: string
    ): FilterConfig => {
      return {
        id,
        sort: "term",
        desc: false,
        showBucketsWithoutSearchResult: true,
        showAggregationWithSingleBucket: true,
      };
    };

    return preselectionAggregations.map(
      initFilterConfigWithPreselectionDefaults
    );
  },

  [n.getActiveFrontendFilter](
    state,
    getters,
    rootState,
    rootGetters
  ): Filter[] {
    const listsConfigs = rootGetters[
      getterNs(confApi, confApi.getters.lists)
    ] as ListsConfigMap;

    const activeFilters = buildAuditItemListFilterSettings(
      state.listSettings,
      state.activeList,
      listsConfigs
    );

    return activeFilters;
  },
  [n.getAuditItemListFilterAggregations](state, getters): Aggregation[] {
    const activeFilters = getters[n.getActiveFrontendFilter] as Filter[];

    const filterTree = getters[
      n.getFilterTreeForFrontendFiltering
    ] as FrontendFilterTree;

    const allItemIds: string[] = keys(
      state.AuditItemsDocument.data?.auditItems ?? {}
    );

    const aggregations = buildAggregationsFromActiveFiltersAndIndex(
      filterTree,
      activeFilters,
      allItemIds,
      readFrontendLanguage()
    );
    return aggregations;
  },
  [n.getActiveFilters](state): Filter[] {
    const activeSettings = state.listSettings[state.activeList];
    if (activeSettings) {
      return activeSettings.filters;
    } else {
      return [];
    }
  },
  [n.getManipulatorStatus](state, getters) {
    return {
      filters: (getters[n.getActiveFrontendFilter] as Filter[]).length,
      sortings: (getters[n.getAuditItemListSortSettings] as SortSetting[])
        .length,
      grouping: false, //settings.groupings !== null
    };
  },
  [n.getChecklistPages](state, getters) {
    const auditClass = getters[n.getAuditClass] as nullable<AuditClassClient>;

    if (auditClass) {
      return auditClass.checklistPages;
    } else {
      return [];
    }
  },
  [n.getReportPages](state, getters) {
    const auditClass = getters[n.getAuditClass] as nullable<AuditClassClient>;

    if (auditClass) {
      return auditClass.reportPages;
    } else {
      return [];
    }
  },
  [n.getChecklistPages](state, getters) {
    const auditClass = getters[n.getAuditClass] as nullable<AuditClassClient>;

    if (auditClass) {
      return auditClass.checklistPages;
    } else {
      return [];
    }
  },
  [n.getReportPages](state, getters) {
    const auditClass = getters[n.getAuditClass] as nullable<AuditClassClient>;

    if (auditClass) {
      return auditClass.reportPages;
    } else {
      return [];
    }
  },

  [n.getCalculatedAuditParticipants](state, getters): IUserRef[] {
    const audit = getters[
      n.getAuditDocumentData
    ] as nullable<AuditMetadataClient>;
    if (audit) {
      const participants: IUserRef[] = [];
      if (audit.leadauditor) {
        participants.push(audit.leadauditor);
      }
      if (audit.coauditors) {
        participants.push(...audit.coauditors);
      }
      if (audit.responsible) {
        participants.push(audit.responsible);
      }
      // Todo: Teilnehmer auch aus den Findings extraieren?

      return participants;
    } else {
      return [];
    }
  },
  [n.getMeasureCoordinators](state, getters): IUserRef[] {
    const audit = getters[
      n.getAuditDocumentData
    ] as nullable<AuditMetadataClient>;
    if (audit && audit.measureCoordinators) {
      return audit.measureCoordinators;
    } else {
      return [];
    }
  },
  [n.getMeasureResponsible](state, getters): IUserRef | null {
    const audit = getters[
      n.getAuditDocumentData
    ] as nullable<AuditMetadataClient>;
    if (audit && audit.responsible) {
      return audit.responsible;
    } else {
      return null;
    }
  },
  [n.getActionsPerView](
    state,
    getters,
    rootState,
    rootGetters
  ): (viewname: string) => string[] {
    return (viewname: string): string[] => {
      const auditClassName = getters[n.getAuditClassId] as string | null;
      const mappedAuditClass = rootGetters[
        getterNs(auditClassesApi, auditClassesApi.getters.mappedAuditClasses)
      ] as MappedAuditClasses;

      console.log(
        "getActionsPerView",
        viewname,
        auditClassName,
        mappedAuditClass
      );

      if (
        typeof viewname === "string" &&
        auditClassName !== null &&
        mappedAuditClass[auditClassName] &&
        viewname in mappedAuditClass[auditClassName].actionsPerState
      ) {
        console.log(
          "getActionsPerView:1",
          mappedAuditClass[auditClassName].actionsPerState
        );
        return (
          mappedAuditClass[auditClassName].actionsPerState as Dictionary<
            string[]
          >
        )[viewname];
      } else {
        console.log("getActionsPerView:2");
        return [];
      }
    };
  },
  [n.getActionplanView](
    state,
    getters,
    rootState,
    rootGetters
  ): "table" | "list" {
    const auditClassName = getters[n.getAuditClassId] as string | null;
    const mappedAuditClass = rootGetters[
      getterNs(auditClassesApi, auditClassesApi.getters.mappedAuditClasses)
    ] as MappedAuditClasses;

    return auditClassName
      ? mappedAuditClass[auditClassName]?.actionplanView ?? "list"
      : "list";
  },

  [n.getAuditImplementation](state, getters): null | AuditImplementation {
    const Document = getters[
      n.getAuditDocumentData
    ] as nullable<AuditMetadataClient>;
    if (Document && Document.implementation) {
      return Document.implementation;
    } else {
      return null;
    }
  },
  [n.getAuditBreadCrumbLabel](state, getters): null | string {
    const status = getters[
      n.getAuditBreadCrumbLabelType
    ] as BreadCrumbLabelType | null;

    if (!status) {
      return null;
    }

    return BREAD_CRUMB_LABEL_I18N_DICT[status];
  },
  [n.getAuditBreadCrumbLabelType](state, getters): BreadCrumbLabelType | null {
    const isRemoteAudit = getters[n.getAuditIsRemoteAudit] as boolean;
    const selfAssessmentOngoing = getters[
      n.getSelfAssessmentOngoing
    ] as boolean;

    const status = getters[n.getAuditStatus] as null | string;
    if (status && AuditStatusId.Completed === status) {
      return "completed";
    } else if (status && MAGIC_READONLY_AUDIT_STATUS_IDS.includes(status)) {
      return "readonly";
    } else if (status && AuditStatusId.Canceled === status) {
      return "canceled";
    } else if (isRemoteAudit && selfAssessmentOngoing) {
      return "remote-selfassessment";
    } else if (!isRemoteAudit && selfAssessmentOngoing) {
      return "selfassessment";
    } else if (isRemoteAudit) {
      return "remote";
    } else {
      return null;
    }
  },
  [n.getAuditBreadCrumbLabelIcon](state, getters): string | null {
    const status = getters[
      n.getAuditBreadCrumbLabelType
    ] as BreadCrumbLabelType | null;
    if (!status) {
      return null;
    }

    return BREAD_CRUMB_LABEL_ICON_DICT[status];
  },
  [n.getContentLanguages](state, getters): string[] {
    const auditItems = getters[n.getAuditItems] as AuditItemWithId[];

    const languages = new Set<string>();
    const extractLanguagesFromMultilingualText = (mt: MultilingualText) => {
      const keys = Object.keys(mt);
      keys.forEach(lang => {
        languages.add(lang);
      });
    };

    auditItems.forEach(item => {
      if (typeIsMultilingualText(item.question.hint)) {
        extractLanguagesFromMultilingualText(item.question.hint);
      }
      if (typeIsMultilingualText(item.question.text)) {
        extractLanguagesFromMultilingualText(item.question.text);
      }
    });

    return [...languages];
  },
  [n.getAuditPermissions](
    state,
    getters,
    rootState,
    rootGetters
  ): AuditPermissions {
    const audit = getters[n.getAuditDocumentData] as nullable<AuditMetadataDoc>;
    const userId = extractCurrentUserRef(rootGetters)?.id ?? null;
    const roles = extractCurrentUserRoles(rootGetters);
    return calcAuditPermissions(audit, userId, roles);
  },
  [n.hideRequirementText](state, getters, rootState, rootGetters): boolean {
    const permissions = getters[n.getAuditPermissions] as AuditPermissions;
    const auditClass = getters[n.getAuditClass] as nullable<AuditClassClient>;

    if (!auditClass) {
      return false;
    }

    if (auditClass.hideRequirementsFromNonAuditors) {
      return !permissions.write;
    }

    return false;
  },
  [n.getAuditRoles](state, getters, rootState, rootGetters): string[] {
    const userId = extractCurrentUserRef(rootGetters)?.id ?? null;
    const roles = extractCurrentUserRoles(rootGetters);

    const audit = getters[
      n.getAuditDocumentData
    ] as nullable<AuditMetadataClient>;
    return calcAllAuditRoles(audit, userId, roles);
  },
  [n.getAuditLoadingState](state): Omit<AuditLoadingState, "auditId"> {
    return state.loadingState;
  },
  [n.getAuditLoadingStateView](
    state
  ): "loading" | "loading-items" | "metadata" | "full" | "error" {
    const metadataState = state.loadingState.metadata;
    const auditItemState = state.loadingState.auditItems;
    if (
      metadataState === WatchedDocumentStates.Idle ||
      metadataState === WatchedDocumentStates.Queued ||
      metadataState === WatchedDocumentStates.Loading
    ) {
      return "loading";
    } else if (
      metadataState === WatchedDocumentStates.Watching &&
      (auditItemState === WatchedDocumentStates.Queued ||
        auditItemState === WatchedDocumentStates.Loading)
    ) {
      return "loading-items";
    } else if (
      metadataState === WatchedDocumentStates.Watching &&
      auditItemState === WatchedDocumentStates.Idle
    ) {
      return "metadata";
    } else if (
      metadataState === WatchedDocumentStates.Watching &&
      auditItemState === WatchedDocumentStates.Watching
    ) {
      return "full";
    } else {
      console.error(
        "auditLoadingState:error",
        JSON.stringify(state.loadingState)
      );
      return "error";
    }
  },
  [n.getAuditLoadingError](state): any {
    return state.loadingError;
  },

  [n.getMeasureWorkflowId](
    state,
    getter,
    rootState,
    rootGetters
  ): string | null {
    const auditClass = getter[n.getAuditClass] as nullable<AuditClassClient>;
    if (auditClass === null) {
      return null;
    }
    const workflowId = auditClass.measureWorkflow;

    return workflowId;
  },
  [n.getMeasureWorkflow](
    state,
    getter,
    rootState,
    rootGetters
  ): MeasureWorkflow | null {
    const workflowId = getter[n.getMeasureWorkflowId] as string | null;
    if (workflowId === null) {
      return null;
    }
    const mappedMeasureWorkflows = rootGetters[
      getterNs(
        measureWorkflowsApi,
        measureWorkflowsApi.getters.getMappedMeasureWorkflows
      )
    ] as Dictionary<MeasureWorkflow>;

    return mappedMeasureWorkflows[workflowId] ?? null;
  },
  [n.getMeasureCreationAllowed](
    state,
    getters,
    rootState,
    rootGetters
  ): boolean {
    const auditRoles = getters[n.getAuditRoles] as string[];
    if (isAdmin(auditRoles)) {
      return true;
    }

    const auditClass = getters[n.getAuditClass];
    const activeAudit = getters[
      n.getAuditDocumentData
    ] as nullable<AuditMetadataClient>;
    if (!activeAudit) {
      return false;
    }

    const statusCode = activeAudit.workflow.status;
    const forbiddenCodes =
      auditClass.measureCreationForbidden.inWorkflowStates.map(statusIdByName);
    return !forbiddenCodes.includes(statusCode);
  },

  [n.getEntityAttachmentsPrivate](
    state,
    getters
  ): EntryAttachmentsVuexApi["getEntityAttachments"] {
    const privateAttachments = state.AuditItemsDocument.data?.attachments ?? {};
    return mapValues(
      privateAttachments,
      (attachment): StoredAttachmentEntryWithContext => {
        return {
          ...attachment,
          context: "finding",
        };
      }
    );
  },
  [n.getEntityAttachmentsPublic](
    state,
    getters
  ): EntryAttachmentsVuexApi["getEntityAttachments"] {
    const publicAttachments = state.Document.data?.attachments ?? {};
    return mapValues(
      publicAttachments,
      (attachment): StoredAttachmentEntryWithContext => {
        return {
          ...attachment,
          context: "audit",
        };
      }
    );
  },
  [n.getEntityAttachments](
    state,
    getters
  ): EntryAttachmentsVuexApi["getEntityAttachments"] {
    const publicAttachments = getters[
      n.getEntityAttachmentsPublic
    ] as EntryAttachmentsVuexApi["getEntityAttachments"];
    const privateAttachments = getters[
      n.getEntityAttachmentsPrivate
    ] as EntryAttachmentsVuexApi["getEntityAttachments"];

    console.log("Public vs private", publicAttachments, privateAttachments);

    let sortedAttachments = {
      ...publicAttachments,
      ...privateAttachments,
    };

    return sortedAttachments;
  },
  [n.getEntityAttachmentPermissions](
    state,
    getters,
    rootState,
    rootGetters
  ): EntryAttachmentsVuexApi["getEntityAttachmentPermissions"] {
    const auditPermissions = getters[n.getAuditPermissions] as AuditPermissions;

    // auditors may *add* (but not update/delete) attachments in readonly state
    const auditRoles = getters[n.getAuditRoles] as string[];
    const isAuditor =
      intersection(auditRoles, [AuditRoles.CO_AUDITOR, AuditRoles.LEAD_AUDITOR])
        .length > 0;

    return {
      add: auditPermissions.write || isAuditor,
      delete: auditPermissions.write,
      update: auditPermissions.write,
      download: auditPermissions.read,
      deleteOwn: auditPermissions.write,
      updateOwn: auditPermissions.write,
      downloadOwn: auditPermissions.read,
    };
  },
  [n.getDimensionsManager](state, getters): DimensionManager {
    const auditClass = getters[n.getAuditClass] as nullable<AuditClassClient>;
    const dimensionSource = auditClass?.dimensionSource ?? null;
    switch (dimensionSource) {
      case "standard":
        return STANDARD_DIMENSION_MANAGER;
      case "vda-process":
        return VDA_PROCESS_DIMENSION_MANAGER;
      default:
        return DEFAULT_DIMENSION_MANAGER;
    }
  },

  [n.getDimensionsConfig]() {
    const filterBuilder = (standardId: string) => {
      return {
        auditItem: (auditItem: AuditItem) => {
          return (
            -1 !==
            auditItem.question.chapters.findIndex(
              chapter => chapter.standardId === standardId
            )
          );
        },
        audit: (audit: AuditMetadataDoc) => {
          return (
            -1 !==
              audit.standardRefs?.findIndex(
                standardRef => standardRef.id === standardId
              ) ?? -1
          );
        },
      };
    };
    const dimensions = {
      "92c3f74a-26f6-486b-a237-9af80eba3b4d": {
        name: "ISO 9001:2015",
        filter: filterBuilder("iso9001-2015"),
      },
      "56363b7e-c6a1-48ac-81b4-95183e77b315": {
        name: "ISO 14001:2015",
        filter: filterBuilder("iso14001-2015"),
      },
      "9a8362fd-2889-473d-b7ea-c502b9aa6297": {
        name: "ISO 45001:2018",
        filter: filterBuilder("iso45001-2018"),
      },
      "8ac44ca0-f1ae-450f-b723-5f4478e2a5e2": {
        name: "ISO 50001:2011",
        filter: filterBuilder("iso50001-2011"),
      },
      "0324a549-7367-4f37-aac5-04b982fd534b": {
        name: "ISO 50001:2018",
        filter: filterBuilder("iso50001-2018"),
      },
    };
    const config: AuditDimensionsConfig = {
      dimensions,
      order: keys(dimensions),
    };
    return config;
  },
  [n.getAuditDimensionsMap](
    state,
    getters,
    rootState,
    rootGetters
  ): DimensionMap {
    const dimensionManager = getters[
      n.getDimensionsManager
    ] as DimensionManager;

    const dimensions =
      dimensionManager.auditDimensionExtractor(rootGetters) ?? [];
    return keyBy(dimensions, "id");
  },
  [n.getAuditItemDimensionsMap](
    state,
    getters,
    rootState,
    rootGetters
  ): AuditItemDimensionsMap {
    const dimensionManager = getters[
      n.getDimensionsManager
    ] as DimensionManager;

    const auditDimensions =
      dimensionManager.auditDimensionExtractor(rootGetters);
    const auditItemDimensionResolver =
      dimensionManager.auditItemDimensionExtractorFactory(
        rootGetters,
        auditDimensions
      );
    const auditItemIds = keys(state.AuditItemsDocument?.data?.auditItems ?? {});

    return fromPairs(
      auditItemIds.map((auditItemId): [string, string[] | null] => {
        return [auditItemId, auditItemDimensionResolver(auditItemId)];
      })
    );
  },
  [n.getAuditItemUnanswerdDimensionsMap](
    state,
    getters,
    rootState,
    rootGetters
  ): AuditItemDimensionsMap {
    const dimensionManager = getters[
      n.getDimensionsManager
    ] as DimensionManager;
    const auditDimensions =
      dimensionManager.auditDimensionExtractor(rootGetters);
    const auditItemDimensionResolver =
      dimensionManager.auditItemDimensionExtractorFactory(
        rootGetters,
        auditDimensions
      );
    const unansweredResolver =
      dimensionManager.unansweredAuditItemDimensionsFactory(
        rootGetters,
        auditItemDimensionResolver
      );

    const auditItemIds = keys(state.AuditItemsDocument?.data?.auditItems ?? {});

    return fromPairs(
      auditItemIds.map((auditItemId): [string, string[] | null] => {
        return [auditItemId, unansweredResolver(auditItemId)];
      })
    );
  },

  [n.getAuditItemDimensionAnswerStatusMap](
    state,
    getters
  ): { [auditItemId: string]: AnswerStatus[] } {
    const auditItemDimensionMap = getters[
      n.getAuditItemDimensionsMap
    ] as AuditItemDimensionsMap;
    const auditItemUnansweredDimensionMap = getters[
      n.getAuditItemUnanswerdDimensionsMap
    ] as AuditItemDimensionsMap;

    const auditDimensionsMap = getters[n.getAuditDimensionsMap] as DimensionMap;
    return mapValues(
      auditItemDimensionMap,
      (dimensionIds, auditItemId): AnswerStatus[] => {
        if (dimensionIds) {
          return dimensionIds.map(dimensionId => {
            return {
              answered:
                !auditItemUnansweredDimensionMap[auditItemId]?.includes(
                  dimensionId
                ) ?? false,
              text:
                auditDimensionsMap[dimensionId]?.name ??
                `Unknown Dimension (${dimensionId})`,
            };
          });
        } else {
          return [];
        }
      }
    );
  },
  [n.getAuditIsRemoteAudit](state, getters): boolean {
    const implementation = getters[
      n.getAuditImplementation
    ] as null | AuditImplementation;

    const isRemote =
      implementation === AuditImplementationE.Remote ||
      implementation === AuditImplementationE.Mixed;
    return isRemote;
  },
  [n.getAuditRemoteSettings](state, getters): AuditRemoteSettings | null {
    const settings = state.Document.data?.remote ?? null;
    return settings;
  },
  [n.getSelfAssessmentIncluded](state, getters): boolean {
    const Document = getters[
      n.getAuditDocumentData
    ] as nullable<AuditMetadataClient>;
    if (Document && Document.includeSelfAssessment) {
      return Document.includeSelfAssessment;
    } else {
      return false;
    }
  },
  [n.getSelfAssessmentCompleted](state, getters): boolean {
    const isSelfAssessmentIncluded = getters[
      n.getSelfAssessmentIncluded
    ] as boolean;

    const auditMetaData = state.Document.data;
    return (
      !!auditMetaData && !!auditMetaData?.remote?.selfAssessment?.completed
    );
  },
  [n.getSelfAssessmentOngoing](state, getters): boolean {
    const auditMetaData = state.Document.data;
    return (
      !!auditMetaData &&
      extractSelfAssessmentStatus(auditMetaData) ===
        SelfAssessmentStatusId.ONGOING
    );
  },
  [n.getSelfAssessmentMarkedAsFinished](state, getters): boolean {
    const auditMetaData = state.Document.data;
    return (
      !!auditMetaData &&
      extractSelfAssessmentStatus(auditMetaData) ===
        SelfAssessmentStatusId.MARKED_FINISHED
    );
  },
  [n.getAuditIsBulkUpdating](state): boolean {
    return state.auditIsBulkUpdating;
  },
  [n.getAuditChecklistExportData](
    state,
    getters,
    rootState,
    rootGetters
  ): AuditChecklistExportData {
    const auditItems = getters[n.getMappedAuditItems] as ReturnType<
      typeof allAuditItemsMapped
    >;
    const itemTypes = getters[n.getMappedAuditItemTypes] as AuditItemTypeMap;
    const mappedCategories = getters[
      n.getAuditCategoryMapping
    ] as AuditItemCategoryMap;

    const consideredItemIds: string[] = getters[n.getConsideredAuditItemIds];
    console.log("getAuditChecklistExportData: audit items", consideredItemIds);
    console.log("getAuditChecklistExportData: item types", itemTypes);

    const mappedNotes = getters[n.getMappedNotes] as Map<
      AuditItemWithId["id"],
      string[]
    >;

    const criteria: AuditChecklistExportCriteria[] = consideredItemIds
      .map(id => auditItems[id])
      .map(auditItem => ({
        auditCriteriaId: auditItem.id,
        auditCriteriaNo: auditItem.question.no,
        auditCriteriaNoSort: text2sortArray(String(auditItem.question.no)),
        auditCriteriaText: ct(auditItem.question.text),
        auditCriteriaWeight:
          auditItem.question.type === ""
            ? 0
            : itemTypes[auditItem.question.type].value,
        auditCriteriaChapterIds: (auditItem.question.chapters || []).map(
          chapter => chapter.chapterId
        ),
        auditCriteriaTags: auditItem.tags?.join(", ") ?? "",
        auditCriteriaNotes: mappedNotes.get(auditItem.id)?.join("\n") ?? "",
        auditCriteriaNormchapter: auditItem.question.chapters
          .map(chapter => chapter.standardName + " " + chapter.chapterId)
          .join(", "),
        auditCriteriaCategory: auditItem.question.categoryRef
          .map(id => ct(mappedCategories[id]?.name ?? "Unknown"))
          .join(", "),
      }));

    criteria.sort((lhs, rhs) => {
      const lhsNo = lhs.auditCriteriaNoSort;
      const rhsNo = rhs.auditCriteriaNoSort;
      return array_cmp(lhsNo, rhsNo);
    });

    const auditMetadata = getters[
      n.getAuditMetadata
    ] as AuditMetadataClient | null;
    const customerLogo = rootGetters[
      getterNs(confApi, confApi.getters.customerLogo)
    ] as string | null;

    if (auditMetadata) {
      return {
        auditUrl: window.location.href,
        auditId: getters.getAuditId,
        auditName: formatAuditName(auditMetadata),
        customerLogo,
        exportDate: moment().format("l"),
        auditPlanningYear: auditMetadata.planning_year,
        auditingDates: formatDates(auditMetadata.auditing_date ?? "", null),
        auditStandards: (auditMetadata.standardRefs ?? []).map(v => v.name),
        auditType: auditMetadata.type ?? null,
        auditBy: auditMetadata.audited_by ?? null,
        leadAuditor: auditMetadata.leadauditor,
        responsibleForMeasures: auditMetadata.responsible,
        coAuditors: auditMetadata.coauditors ?? [],
        participants: auditMetadata.audit_participants || [],
        criteria,
      };
    } else {
      const FALLBACK: AuditChecklistExportData = {
        auditBy: null,
        auditId: "NO_ID_FOUND",
        auditName: "NO AUDIT FOUND",
        auditPlanningYear: 1970,
        auditStandards: [],
        auditType: null,
        auditUrl: "",
        auditingDates: [],
        coAuditors: [],
        criteria,
        customerLogo: "",
        exportDate: "",
        leadAuditor: null,
        participants: [],
        responsibleForMeasures: null,
      };

      return FALLBACK;
    }
  },

  [n.getPossibleAuditTemplatesForCurrentAudit](
    state,
    getters,
    rootState,
    rootGetters
  ): MinimalTemplate[] {
    const templates = rootGetters[
      getterNs(templatesApi, templatesApi.getters.getTemplateSelection)
    ] as Array<{
      id: string;
      name: string;
      audit_class: string;
      auditItemCount: number;
    }>;

    const auditClassId = state.Document.data?.audit_class ?? null;

    return templates.filter(template => {
      return template.audit_class === auditClassId;
    });
  },
  [n.getSelectedTemplateIds](state): Array<MinimalTemplate["id"]> {
    return state.Document.data?.auditPreparation?.templateIds ?? [];
  },
  [n.getAuditItemAggregators](
    state,
    getters,
    rootState,
    rootGetters
  ): ReturnType<typeof buildAuditItemAggegators> {
    const findingTypeConfig = getters[
      n.getFindingTypeConfig
    ] as nullable<FindingTypeConfig>;
    const auditItemTypeConfig = getters[
      n.getAuditItemTypeConfig
    ] as nullable<AuditItemTypeConfig>;
    const categoryLevel = getters[n.getCategoryLevel] as CategoryLevel;
    const mappedCategories = getters[
      n.getAuditCategoryMapping
    ] as AuditItemCategoryMap;

    const auditItemDimensionsMap = getters[
      n.getAuditItemDimensionsMap
    ] as AuditItemDimensionsMap;

    const dimensionsMap = getters[n.getAuditDimensionsMap] as DimensionMap;
    const auditClass = getters[n.getAuditClass] as nullable<AuditClassClient>;
    const isMultidimensionAuditWithStandards =
      auditClass?.dimensionSource === "standard";

    const dimensionExtrator: Aggregator<idable<AuditItem>>["aggregate"] =
      auditItem => {
        if (isMultidimensionAuditWithStandards) {
          if (auditItem.question.chapters.length === 0) {
            return [
              {
                name: "other.audit_item_list_manipulator.without_dimension",
                value: null,
              },
            ];
          } else {
            return auditItem.question.chapters.map(chapterRef => {
              return {
                name: chapterRef.standardName,
                value: deriveFilterValueFromStandardId(chapterRef.standardId),
              };
            });
          }
        } else {
          const dimensions = auditItemDimensionsMap[auditItem.id] ?? null;

          if (dimensions && dimensions.length > 0) {
            const result = dimensions
              .map((dimensionId): AggegatorResult | null => {
                const dimension = dimensionsMap[dimensionId];
                if (dimension) {
                  return {
                    name: dimension.name,
                    value: dimensionId,
                  };
                } else {
                  return null;
                }
              })
              .filter(typeIsNotEmpty);
            console.log(
              "dimensionExtrator",
              auditItem.id,
              dimensions,
              dimensionsMap,
              result
            );
            return result;
          } else {
            return [
              {
                name: "other.audit_item_list_manipulator.without_dimension",
                value: null,
              },
            ];
          }
        }
      };

    const notesMap = getters[n.getMappedNotes] as Map<
      AuditItemWithId["id"],
      string[]
    >;

    const consideredAuditItemIds = getters[
      n.getConsideredAuditItemIds
    ] as string[];

    const auditItem2Settings = getters[
      n.getAuditItemId2Setting
    ] as AuditItemSettings;
    const findingsLookup = buildFindingLookup(state, getters);

    const selfAssessmentsLookup = getters[
      n.getAuditItemId2SelfAssessmentMap
    ] as StrictDictionary<idable<SelfAssessment>[]>;

    const measureViolations = getters[
      n.getMeasurePolicyViolations
    ] as MeasurePolicyViolation[];
    const measureTypesMapping = rootGetters[
      getterNs(confApi, confApi.getters.getMeasureTypesMapping)
    ] as MeasureTypeMap;

    const auditItemIdBasedLookupFunctions: AuditItemIdBasedLookupFunctions = {
      conflictStateLookup: auditItemId => {
        const auditItemsInConflictState = getters[
          n.getAuditItemsInConflictState
        ] as AuditItemsInConflictState;
        return auditItemsInConflictState[auditItemId] ?? null;
      },
      findingsHaveAttachmentsLookup: auditItemId => {
        const findings = findingsLookup(auditItemId);
        return findings.some(finding => finding.attachmentIds.length > 0);
      },
      selfAssessmentsHaveAttachmentsLookup: auditItemId => {
        return (
          selfAssessmentsLookup[auditItemId]?.some(
            selfAssessment => selfAssessment.attachmentIds.length > 0
          ) ?? false
        );
      },
      findingsLookup,
      hasNoteLookup: auditItemId => {
        return (notesMap.get(auditItemId) ?? []).length > 0;
      },
      inSelfAssessmentLookup: auditItemId => {
        return (
          state.Document.data?.selfAssessmentEnabledAuditItemIds ?? []
        ).includes(auditItemId);
      },
      isConsideredLookup: auditItemId => {
        return consideredAuditItemIds.includes(auditItemId);
      },
      openDimensionsLookup: auditItemId => {
        return auditItem2Settings[auditItemId]?.openDimensions ?? null;
      },
      measureViolationLookup: auditItemId => {
        const findingIds = findingsLookup(auditItemId).map(
          finding => finding.id
        );
        const violations = measureViolations.filter(v => {
          return intersection(findingIds, v.findingIds).length > 0;
        });

        return violations
          .map(violation => {
            const measureType =
              measureTypesMapping[violation.measureTypeId] ?? null;
            if (measureType) {
              return {
                level: violation.violationLevel,
                measureType,
              };
            } else {
              return null;
            }
          })
          .filter(typeIsNotEmpty);
      },
    };

    return buildAuditItemAggegators(
      findingTypeConfig?.types ?? {},
      auditItemTypeConfig?.types ?? {},
      mappedCategories,
      categoryLevel,
      dimensionExtrator,
      auditItemIdBasedLookupFunctions
    );
  },
  [n.getAuditItemPreselectionAggregators](
    state,
    getters
  ): ReturnType<typeof buildAuditItemAggegators> {
    const auditItemTypeConfig = getters[
      n.getAuditItemTypeConfig
    ] as nullable<AuditItemTypeConfig>;
    const categoryLevel = getters[n.getCategoryLevel] as CategoryLevel;
    const mappedCategories = getters[
      n.getAuditCategoryMapping
    ] as AuditItemCategoryMap;

    const auditItemDimensionsMap = getters[
      n.getAuditItemDimensionsMap
    ] as AuditItemDimensionsMap;

    const dimensionsMap = getters[n.getAuditDimensionsMap] as DimensionMap;
    const auditClass = getters[n.getAuditClass] as nullable<AuditClassClient>;
    const isMultidimensionAuditWithStandards =
      auditClass?.dimensionSource === "standard";

    const dimensionExtrator: Aggregator<idable<AuditItem>>["aggregate"] =
      auditItem => {
        if (isMultidimensionAuditWithStandards) {
          if (auditItem.question.chapters.length === 0) {
            return [
              {
                name: { en: "without standard", de: "ohne Normzuordnung" },
                value: null,
              },
            ];
          } else {
            return auditItem.question.chapters.map(chapterRef => {
              return {
                name: chapterRef.standardName,
                value: deriveFilterValueFromStandardId(chapterRef.standardId),
              };
            });
          }
        } else {
          const dimensions = auditItemDimensionsMap[auditItem.id] ?? null;

          if (dimensions && dimensions.length > 0) {
            const result = dimensions
              .map((dimensionId): AggegatorResult | null => {
                const dimension = dimensionsMap[dimensionId];
                if (dimension) {
                  return {
                    name: dimension.name,
                    value: dimensionId,
                  };
                } else {
                  return null;
                }
              })
              .filter(typeIsNotEmpty);

            return result;
          } else {
            return [
              {
                name: "other.audit_item_list_manipulator.without_dimension",
                value: null,
              },
            ];
          }
        }
      };

    const auditItemIdBasedLookupFunctions: AuditItemIdBasedLookupFunctions = {
      conflictStateLookup: auditItemId => {
        return null;
      },
      findingsHaveAttachmentsLookup: auditItemId => {
        return false;
      },
      selfAssessmentsHaveAttachmentsLookup: auditItemId => {
        return false;
      },
      findingsLookup: auditItemId => {
        return [];
      },
      hasNoteLookup: auditItemId => {
        return false;
      },
      inSelfAssessmentLookup: auditItemId => {
        return false;
      },
      isConsideredLookup: auditItemId => {
        return false;
      },
      openDimensionsLookup: auditItemId => {
        return null;
      },
      measureViolationLookup: auditItemId => {
        return [];
      },
    };

    return buildAuditItemAggegators(
      {},
      auditItemTypeConfig?.types ?? {},
      mappedCategories,
      categoryLevel,
      dimensionExtrator,
      auditItemIdBasedLookupFunctions
    );
  },
  [n.getFilterTreeForNotConsideredAuditItems](
    state,
    getters
  ): FrontendFilterTree {
    const auditItemAggregators = getters[
      n.getAuditItemAggregators
    ] as ReturnType<typeof buildAuditItemAggegators>;

    const notConsideredAuditItemIds = getters[
      n.getNotConsideredAuditItemIds
    ] as string[];

    const auditItemMap = pick(
      state.AuditItemsDocument.data?.auditItems ?? {},
      notConsideredAuditItemIds
    );

    const availableFiltersForList = getters[
      n.getAvailableFiltersForList
    ] as ReturnType<typeof selectAvailableFiltersForList>;

    const availableFilterIds = availableFiltersForList.map(filter => {
      return filter.id;
    });

    const filterTree = buildFilterIndexForItems(
      pick(auditItemAggregators, availableFilterIds),
      auditItemMap,
      availableFiltersForList
    );

    return filterTree;
  },
  [n.getFilterTreeForFrontendFiltering](state, getters): FrontendFilterTree {
    const auditItemAggregators = getters[
      n.getAuditItemAggregators
    ] as ReturnType<typeof buildAuditItemAggegators>;

    const allAuditItems = state.AuditItemsDocument.data?.auditItems ?? {};
    const auditMetadata = state.Document.data;
    const isManualSelectionActive =
      auditMetadata?.workflow.status === AuditStatusId.Preparation &&
      getters[n.getPreparationState] === "manual-selection";

    const auditItemMap: Dictionary<AuditItem> = isManualSelectionActive
      ? allAuditItems
      : pick(allAuditItems, getters[n.getConsideredAuditItemIds] as string[]);

    const availableFiltersForList = getters[
      n.getAvailableFiltersForList
    ] as ReturnType<typeof selectAvailableFiltersForList>;

    const filterTree = buildFilterIndexForItems(
      pick(
        auditItemAggregators,
        availableFiltersForList.map(val => val.id)
      ),
      auditItemMap,
      availableFiltersForList
    );

    return filterTree;
  },
  [n.getPreselectionFilterTree](state, getters): FrontendFilterTree {
    const auditItemMap = state.AuditItemsDocument.data?.auditItems ?? {};
    return buildFilterTreeFromAuditItems(getters, auditItemMap);
  },
  [n.getPreselectionFilterAggregations](state, getters): Aggregation[] {
    const auditClass = getters[n.getAuditClass] as nullable<AuditClassClient>;

    const isMultidimensionAuditWithStandards =
      auditClass?.dimensionSource === "standard";

    const auditMetadata = state.Document.data;
    if (!auditMetadata) {
      return [];
    }

    const activeFilter = uniqWith(
      [
        ...(state.Document.data?.auditPreparation.activePreselectionFilters ??
          []),
        ...calcPreselectionFilterBasedOnTheAuditMetadata(
          auditMetadata,
          isMultidimensionAuditWithStandards
        ),
      ],
      isEqual
    );

    const filterTree = getters[
      n.getPreselectionFilterTree
    ] as FrontendFilterTree;

    const allAuditItemIds = keys(
      state.AuditItemsDocument.data?.auditItems ?? {}
    );

    const aggregations = buildAggregationsFromActiveFiltersAndIndex(
      filterTree,
      activeFilter,
      allAuditItemIds,
      readFrontendLanguage()
    );
    return aggregations;
  },
  [n.getAuditItemIdsBaseSetAccordingToAuditState](
    state
  ): Array<AuditItemWithId["id"]> {
    const auditMetadata = state.Document.data;
    const allAuditItemIds = keys(state.AuditItemsDocument.data?.auditItems);
    if (auditMetadata?.workflow.status === AuditStatusId.Preparation) {
      if (auditMetadata.auditPreparation.step === "self-assessment-selection") {
        return calculateTotalConsideredAuditItemIds(
          auditMetadata.totalConsideredAuditItemIds ?? [],
          auditMetadata.auditPreparation.manuallyConsideredAuditItemIds
        );
      } else {
        return allAuditItemIds;
      }
    } else {
      if (state.activeList === ROUTE_NAMES.AUDITPREPARATION) {
        return allAuditItemIds;
      } else {
        return auditMetadata?.totalConsideredAuditItemIds ?? [];
      }
    }
  },

  [n.getConsideredAuditItemIds](state, getters): Array<AuditItemWithId["id"]> {
    const auditMetadata = state.Document.data;
    if (
      !auditMetadata ||
      auditMetadata.workflow.status === AuditStatusId.Planning
    ) {
      return [];
    }

    if (auditMetadata.workflow.status === AuditStatusId.Preparation) {
      if (auditMetadata.auditPreparation.step === "preselection") {
        const filterTree = getters[
          n.getPreselectionFilterTree
        ] as FrontendFilterTree;
        const filterAggregationIds = [...filterTree.keys()];

        const auditClass = getters[
          n.getAuditClass
        ] as nullable<AuditClassClient>;
        const isMultidimensionAuditWithStandards =
          auditClass?.dimensionSource === "standard";

        const preselectionFilter = uniqWith(
          [
            ...auditMetadata.auditPreparation.activePreselectionFilters,
            ...calcPreselectionFilterBasedOnTheAuditMetadata(
              auditMetadata,
              isMultidimensionAuditWithStandards
            ),
          ],
          isEqual
        );

        console.log(
          "getConsideredAuditItemIds",
          filterAggregationIds,
          preselectionFilter
        );
        // EmptyFilter means no items selected.
        if (
          filterAggregationIds.every(aggregationId => {
            return !!preselectionFilter.find(
              filter => filter.aggregationId === aggregationId
            );
          })
        ) {
          return calculateFilteredItemIds(
            filterTree,
            preselectionFilter,
            keys(state.AuditItemsDocument.data?.auditItems)
          );
        } else {
          return [];
        }
      } else {
        // During the Preparation the array FieldPartNames.TOTAL_CONSIDERED_AUDIT_ITEM_IDS contains the Item from the preselection Filter
        return calculateTotalConsideredAuditItemIds(
          auditMetadata.totalConsideredAuditItemIds ?? [],
          auditMetadata.auditPreparation.manuallyConsideredAuditItemIds
        );
      }
    }

    return auditMetadata.totalConsideredAuditItemIds;
  },
  [n.getTotalAuditItemCount](state): number {
    return size(state.AuditItemsDocument.data?.auditItems ?? {});
  },
  [n.getAuditItemProperties](
    state,
    getters
  ): Map<AuditItemWithId["id"], AuditItemProperties> {
    const allAuditItemIds = keys(
      state.AuditItemsDocument.data?.auditItems ?? {}
    );
    const auditMetadata = state.Document.data;

    if (!auditMetadata) {
      throw new Error("no-audit-metadata");
    }

    const consideredAuditItemIds = new Set(
      getters[n.getConsideredAuditItemIds] as Array<AuditItemWithId["id"]>
    );

    const selfAssessmentEnabledAuditItemIds = new Set(
      auditMetadata.selfAssessmentEnabledAuditItemIds
    );

    const mapEntries = allAuditItemIds.map((auditItemId): [
      AuditItemWithId["id"],
      AuditItemProperties
    ] => {
      const isConsidered = consideredAuditItemIds.has(auditItemId);

      const isSelfAssessmentEnabled =
        isConsidered && selfAssessmentEnabledAuditItemIds.has(auditItemId);

      return [auditItemId, { isConsidered, isSelfAssessmentEnabled }];
    });
    return new Map(mapEntries);
  },
  [n.getPreparationState](state, getters): PreparationStates {
    const auditMetadata = getters[
      n.getAuditMetadata
    ] as AuditMetadataClient | null;

    const selfAssessmentIncluded = getters[
      n.getSelfAssessmentIncluded
    ] as boolean;

    const preparationStep =
      auditMetadata?.auditPreparation.step ?? "preselection";

    if (
      !selfAssessmentIncluded &&
      preparationStep === "self-assessment-selection"
    ) {
      return "manual-selection";
    }
    return preparationStep;
  },
  [n.getPossiblePreparationStates](state, getters): PreparationStates[] {
    const states: PreparationStates[] = ["preselection", "manual-selection"];

    const selfAssessmentIncluded = getters[
      n.getSelfAssessmentIncluded
    ] as boolean;
    if (selfAssessmentIncluded) {
      states.push("self-assessment-selection");
    }

    return states;
  },
  [n.getPreparationStateTransitions](
    state,
    getters
  ): {
    prev: PreparationStateTransition | null;
    next: PreparationStateTransition | null;
  } {
    const isSelfAssessmentIncluded = getters[
      n.getSelfAssessmentIncluded
    ] as boolean;

    const preparationState = getters[
      n.getPreparationState
    ] as PreparationStates;

    const auditStatus = getters[n.getAuditStatus] as string | null;
    const auditIsInPreparation = auditStatus === AuditStatusId.Preparation;
    const atLeastOneQuestionIsSelected =
      (getters[n.getConsideredAuditItemIds] as Array<AuditItemWithId["id"]>)
        .length > 0;

    if (auditIsInPreparation) {
      if (preparationState === "preselection") {
        return {
          prev: null,
          next: {
            name: "views.audit_preparation.preparation_transition_preselection_to_manual_selection",
            newState: "manual-selection",
            enabled: atLeastOneQuestionIsSelected,
          },
        };
      }
      if (preparationState === "manual-selection") {
        return {
          prev: {
            name: "views.audit_preparation.preparation_transition_manual_selection_to_preselection",
            newState: "preselection",
            enabled: true,
          },
          next: isSelfAssessmentIncluded
            ? {
                name: "views.audit_preparation.preparation_transition_manual_selection_to_self_assessment",
                newState: "self-assessment-selection",
                enabled: atLeastOneQuestionIsSelected,
              }
            : null,
        };
      }
      if (preparationState === "self-assessment-selection") {
        return {
          prev: {
            name: "views.audit_preparation.preparation_transition_self_assessment_to_manual_selection",
            newState: "manual-selection",
            enabled: atLeastOneQuestionIsSelected,
          },
          next: null,
        };
      }
    }

    return { prev: null, next: null };
  },
  [n.getMappedTags](state): Map<AuditItemWithId["id"], string[]> {
    const tags = new Map<AuditItemWithId["id"], string[]>();
    const auditItems = state.AuditItemsDocument.data?.auditItems ?? {};
    forEach(auditItems, (auditItem, auditItemId) => {
      tags.set(auditItemId, auditItem.tags ?? []);
    });

    return tags;
  },
  [n.getMappedNotes](state, getters): Map<AuditItemWithId["id"], string[]> {
    const notes = new Map<AuditItemWithId["id"], string[]>();

    const questionNotes = getQuestionNotesMapped(state, getters);
    const allAuditItemIds = keys(
      state.AuditItemsDocument.data?.auditItems ?? {}
    );

    allAuditItemIds.forEach(id => {
      notes.set(id, []);
    });

    Object.entries(questionNotes).forEach(([id, questionNote]) => {
      questionNote.auditItemIds.forEach(auditItemId => {
        notes.get(auditItemId)?.push(questionNote.text);
      });
    });

    return notes;
  },
  [n.totalConsideredAuditItemIds](state): Array<AuditItemWithId["id"]> {
    return state.Document.data?.totalConsideredAuditItemIds ?? [];
  },
  [n.getNotConsideredAuditItemIds](state): Array<AuditItemWithId["id"]> {
    const allAuditItemIds = keys(
      state.AuditItemsDocument.data?.auditItems ?? {}
    );
    const consideredAuditItemIds =
      state.Document.data?.totalConsideredAuditItemIds ?? [];
    return difference(allAuditItemIds, consideredAuditItemIds);
  },
  [n.getMaturity](state, getters) {
    const auditClass = getters[n.getAuditClass] as nullable<AuditClassClient>;
    return auditClass?.maturity ?? null;
  },
  [n.getMaturityEnabled](state, getters): boolean {
    const maturityConfig = getters[
      n.getMaturity
    ] as AuditClassConfig["maturity"];
    return !!maturityConfig;
  },
  [n.getAuditItemsWithMissingMaturity](state, getters) {
    const auditItems = getters[n.getAuditItems] as AuditItemWithId[];
    const findingsLookup = buildFindingLookup(state, getters);
    const findingTypesMap = getters[n.getMappedFindingTypes] as FindingTypeMap;

    return auditItems.filter(auditItem => {
      const findings = findingsLookup(auditItem.id);
      return (
        findings.length === 0 ||
        findings.some(
          finding =>
            !isNumber(finding.maturity) &&
            !findingTypesMap[finding.type].is_not_applicable
        )
      );
    });
  },
  [n.getForceFullFindingEditor](state, getters): boolean {
    const auditClass = getters[n.getAuditClass] as nullable<AuditClassClient>;
    return auditClass?.forceFullFindingEditor ?? false;
  },
  [n.getOpenProcessAfterCreation](state, getters): boolean {
    const measureWorkflow = getters[
      n.getMeasureWorkflow
    ] as MeasureWorkflow | null;
    return !!measureWorkflow && measureWorkflow.openProcessAfterCreation;
  },
  [n.getIsDeleted](state, getters): boolean {
    return state.Document.data?.docVersion?.deleted ?? false;
  },
};
export { n as getterNames, getterTree as getters };
