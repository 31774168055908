import { ActionTree } from "vuex";
import { RootState } from "../../types";
import { AutoCompletionsState } from "./types";
import { mutationNames as mn } from "./mutations";
import firebase from "firebase/compat/app";

import {
  CustomMetadataList,
  CustomMetadataNested,
} from "@auditcloud/shared/lib/types/CustomMetadata";
import { rdc, rdu } from "../user";
import { CollectionNames } from "@auditcloud/shared/lib/types/common";
import { idfy } from "@auditcloud/shared/lib/utils";

const loadAutoCompletesData = "loadAutoCompletesData";
const clearAutoCompletesData = "clearAutoCompletesData";
const updateAutoCompleteData = "updateAutoCompleteData";
const createAutoCompleteData = "createAutoCompleteData";

const n = {
  loadAutoCompletesData,
  clearAutoCompletesData,
  updateAutoCompleteData,
  createAutoCompleteData,
};

const actions: ActionTree<AutoCompletionsState, RootState> = {
  async [n.loadAutoCompletesData](context) {
    console.log("loadAutoCompletesData", context);
    const collection = firebase
      .firestore()
      .collection(CollectionNames.AUTOCOMPLETES);

    const snapshot = await collection.get();

    const docs = snapshot.docs;

    const data = {};
    docs.forEach(doc => {
      data[doc.id] = doc.data();
    });

    context.commit(mn.SET_AUTO_COMPLETES_DATA, data);
  },

  [n.clearAutoCompletesData](context) {
    console.log("clearAutoCompletesData", context);
    context.commit(mn.CLEAR_AUTO_COMPLETES_DATA);
  },
  async [n.updateAutoCompleteData](
    context,
    payload: {
      id: string;
      name: string;
      data: CustomMetadataList | CustomMetadataNested;
    }
  ) {
    console.log("updateAutoCompleteData", context, payload);
    const data = { name: payload.name, data: payload.data };

    const db = firebase.firestore();
    const collection = db.collection(CollectionNames.AUTOCOMPLETES);
    const docRef = collection.doc(payload.id);

    await db.runTransaction(async transaction => {
      transaction.update(docRef, { ...data, ...rdu(context.rootGetters) });
    });

    context.commit(mn.SET_AUTO_COMPLETE_DATA, { id: payload.id, data });
  },
  async [n.createAutoCompleteData](
    context,
    payload: {
      name: string;
      data: CustomMetadataList | CustomMetadataNested;
    }
  ) {
    const data = { ...payload, schema: null, ...rdc(context.rootGetters) };

    console.log("createAutoCompleteData", context, payload, data);

    const db = firebase.firestore();
    const collection = db.collection(CollectionNames.AUTOCOMPLETES);
    const docId = await db.runTransaction(async transaction => {
      const nameId = idfy(payload.name);

      const doc = await transaction.get(collection.doc(nameId));

      const docRef = doc.exists ? collection.doc() : doc.ref;
      transaction.set(docRef, data);

      const docId = docRef.id;

      return docId;
    });

    context.commit(mn.SET_AUTO_COMPLETE_DATA, { id: docId, data });

    return docId;
  },
};

export { n as actionNames, actions };
