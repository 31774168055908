

















import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import { isNull, isString } from "lodash";

import {
  AuditItemType,
  AuditItemTypeMap,
} from "@auditcloud/shared/lib/types/ItemTypes";

import { api as confApi } from "@/store/modules/configuration";
import { AuditItemTypeConfigMap } from "@auditcloud/shared/lib/types/Configuration/defaults";
import { typeIsArrayOf } from "@auditcloud/shared/lib/utils/type-guards";
import { TranslateableText } from "@auditcloud/shared/lib/schemas";
import {
  AuditItemCategorySetMap,
  AuditItemCategory,
  AuditItemCategoryMap,
} from "@auditcloud/shared/lib/types/AuditItemCategory";

/*      const auditItemTypeConfigMap = rootGetters[
        getterNs(confApi, confApi.getters.getAuditItemTypesMappedByAuditClass)
      ] as AuditItemTypeConfigMap;

      const auditItemConfig = auditItemTypeConfigMap[auditClass];
      return auditItemConfig || null;*/

function flatten(categories: AuditItemCategory[]): AuditItemCategoryMap {
  const flat: AuditItemCategoryMap = {};

  return categories.reduce((prev, category) => {
    // flat
    // flat[category.id] = category;

    return {
      ...prev,
      ...flatten(category.subcategories),
      [category.id]: category,
    };
  }, flat);
}

@Component({})
export default class ACategorySelect extends Vue {
  xValue: (string | null)[] = [];

  @Prop({
    type: String,
    validator: p => isString(p) || isNull(p),
    required: true,
  })
  categorySetId!: string | null;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Array,
    validator: (val: any): boolean => typeIsArrayOf(val, isString),
    default: [],
  })
  value!: string[];

  @Watch("value", {
    immediate: true,
    deep: true,
  })
  onValueChanged(newValue: string[]) {
    let isOpen = true;
    const enrichedVal: string[] = [];

    newValue.forEach((id, idx) => {
      const items =
        idx === 0
          ? this.categories
          : this.flatMappedCategories[enrichedVal[idx - 1]].subcategories;

      const category = items.find(c => c.id === id);

      if (category) {
        enrichedVal.push(id);
        isOpen = isOpen && category.subcategories.length > 0;
      } else {
        enrichedVal.splice(idx);
      }
    });
    const placeholder = isOpen ? [null] : [];
    this.xValue = [...enrichedVal, ...placeholder];
  }

  changed(idx: number, id: string) {
    const newVal = [...this.xValue].filter(isString);
    newVal.splice(idx);
    newVal.push(id);

    this.onValueChanged(newVal);
    this.$emit("input", newVal);
  }

  @Getter(confApi.getters.getCategoryMapping, {
    namespace: confApi.namespace,
  })
  mappedCategorySet!: AuditItemCategorySetMap;

  get categorySet() {
    if (this.categorySetId !== null) {
      return this.mappedCategorySet[this.categorySetId] || null;
    } else {
      return null;
    }
  }

  get categories(): AuditItemCategory[] {
    if (this.categorySet === null) {
      return [];
    } else {
      return this.categorySet.categories;
    }
  }

  get flatMappedCategories(): AuditItemCategoryMap {
    return flatten(this.categories);
  }

  itemText(item: AuditItemCategory): string {
    return this.$ct(item.name);
  }

  items(idx: number): AuditItemCategory[] {
    const id = this.xValue[idx - 1];

    return idx === 0
      ? this.categories
      : id === null
      ? []
      : this.flatMappedCategories[id].subcategories;
  }
}
