
















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { isArray, isFunction, isMap, isPlainObject, omit } from "lodash";
import {
  ConfigurableGetter,
  getterPath,
} from "@auditcloud/shared/lib/types/common";

import { typeIsArrayOf } from "@auditcloud/shared/lib/utils/type-guards";
import { CustomMetadataEntry } from "@auditcloud/shared/lib/schemas";
import { typeIsCustomMetadataEntry } from "@auditcloud/shared/lib/schemas/type-guards";

type AVuexAutocompleteValue =
  | CustomMetadataEntry
  | null
  | CustomMetadataEntry[];

@Component({
  name: "AVuexAutocomplete",
  components: {},
})
export default class AVuexAutocomplete extends Vue {
  @Prop({
    type: [Object, Array],
    default: null,
  })
  value!: AVuexAutocompleteValue;

  @Prop({
    type: Boolean,
    default: false,
  })
  multiple!: boolean;

  @Prop({
    type: Object,
    required: true,
  })
  itemsGetter!: ConfigurableGetter;

  get defaultedAttrs() {
    return { ...this.$attrs, "return-object": true };
  }

  get xValue() {
    const newVal = this.value;
    console.log("valueChanged", newVal);

    if (this.multiple) {
      return newVal === null ? [] : isArray(newVal) ? newVal : [newVal];
    } else {
      console.assert(
        !(isArray(newVal) && newVal.length > 1),
        "invalid single value",
        newVal
      );
      return isArray(newVal)
        ? newVal.length === 0
          ? null
          : newVal[0]
        : newVal;
    }
  }

  get getterResult() {
    const path = getterPath(this.itemsGetter);
    const getterResult: any = this.$store.getters[path];

    return getterResult;
  }

  resultItems(val: any): CustomMetadataEntry[] | null {
    if (
      isPlainObject(val) &&
      ["list", "nested"].includes(val.type) &&
      typeIsArrayOf(val.items, typeIsCustomMetadataEntry)
    ) {
      return val.items.map(v => omit(v, "children"));
    } else {
      return null;
    }
  }

  get items(): CustomMetadataEntry[] {
    const getterResult = this.getterResult;
    const id = this.itemsGetter.id;
    if (isArray(getterResult)) {
      return getterResult;
    } else if (typeof id === "string") {
      let result: CustomMetadataEntry[] | null = null;
      if (isFunction(getterResult)) {
        result = this.resultItems(getterResult(id));
      } else if (isMap(getterResult)) {
        result = this.resultItems(getterResult.get(id));
      } else if (isPlainObject(getterResult)) {
        result = this.resultItems(getterResult[id]);
      }
      console.assert(isArray(result), `expect getter Result to be an array`);
      return isArray(result) ? result : [];
    } else {
      console.error("invalid items getter", this.itemsGetter);
      return [];
    }
  }

  onSelectionChanged(newVal: AVuexAutocompleteValue) {
    this.$emit("input", newVal ?? null);
  }
}
