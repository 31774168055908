import { render, staticRenderFns } from "./TemplateItemDialog.vue?vue&type=template&id=7f25d22c&scoped=true&"
import script from "./TemplateItemDialog.vue?vue&type=script&lang=ts&"
export * from "./TemplateItemDialog.vue?vue&type=script&lang=ts&"
import style0 from "./TemplateItemDialog.vue?vue&type=style&index=0&id=7f25d22c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f25d22c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VAutocomplete,VChip,VCol,VContainer,VDialog,VIcon,VRow,VSelect,VTooltip})
