import { MutationTree, ActionTree, GetterTree, Module } from "vuex";
import firebase from "firebase/compat/app";
import { RootState, KnownDocumentNames } from "@/store/types";
import { Dictionary, pick } from "lodash";

import { AuditClassesState, AuditClassesListResult } from "./types";
import {
  MappedAuditClasses,
  initAuditClassesWithDefaults,
} from "@auditcloud/shared/lib/types/AuditClass";
import { typeIsMapOf } from "@auditcloud/shared/lib/utils/type-guards";
import { createError } from "@/utils/Errors";
import { CollectionNames } from "@auditcloud/shared/lib/types/common";
import { typeIsConfigurationAuditClassesDoc } from "@auditcloud/shared/lib/schemas/type-guards";

export const api = {
  namespace: "auditClasses",
  actions: {
    init: "init",
    updateAuditClass: "updateAuditClass",
  },
  mutations: {
    INIT: "INIT",
  },
  getters: {
    list: "list",
    mappedAuditClasses: "mappedAuditClasses",
  },
  state: {
    auditClasses: "auditClasses",
  },
};

const actions: ActionTree<AuditClassesState, RootState> = {
  [api.actions.init]({ commit }, payload: unknown) {
    console.log("auditClasses:init", payload);
    if (!typeIsConfigurationAuditClassesDoc(payload)) {
      throw createError("expect typeIsConfigurationAuditClassesDoc got", {
        data: payload,
        error: typeIsConfigurationAuditClassesDoc.errors(),
      });
    }

    const initialized = initAuditClassesWithDefaults(payload);
    commit(api.mutations.INIT, initialized);
  },
  async [api.actions.updateAuditClass](
    { commit, dispatch },
    payload: { auditClassId: string; auditClassData: Dictionary<any> }
  ) {
    const db = firebase.firestore();
    const docRef = db
      .collection(CollectionNames.CONFIGURATION)
      .doc(KnownDocumentNames.AUDIT_CLASSES);
    await docRef.update({
      [payload.auditClassId]: payload.auditClassData,
    });
    const doc = await docRef.get({ source: "server" });

    await dispatch(api.actions.init, doc.data());
  },
};

const mutations: MutationTree<AuditClassesState> = {
  [api.mutations.INIT](state, payload: MappedAuditClasses) {
    state.auditClasses = payload;
  },
};

const getters: GetterTree<AuditClassesState, RootState> = {
  [api.getters.list](state): AuditClassesListResult {
    return Object.entries(state.auditClasses).map(([id, auditClass]) => {
      return {
        id,
        ...pick(auditClass, ["name", "categorySetId", "reportSchema"]),
      };
    });
  },

  [api.getters.mappedAuditClasses](state): MappedAuditClasses {
    return state.auditClasses;
  },
};

const state = new AuditClassesState();

const namespaced: boolean = true;
const modul: Module<AuditClassesState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};

export default modul;
