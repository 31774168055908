import { NamedRef, TranslateableText } from "@auditcloud/shared/lib/schemas";
import { typeIsTranslateableText } from "@auditcloud/shared/lib/types/common";
import { isNumber } from "lodash";
import { StatedGetter } from "../reporting/types";
import { typeIsArrayOf } from "@auditcloud/shared/lib/utils/type-guards";
import {
  AuditDimensionResult,
  AuditResultSteps,
  CategeoryScoreResult,
  FlatAuditItemResultData,
  ScoreResult,
} from "@auditcloud/shared/lib/types/AuditResult";
import { ConstraintResult } from "@auditcloud/shared/lib/auditResult/utils";

interface TableDataRow {
  title: TranslateableText;
  values: number[];
}

export function typeIsTableDataRow(val: any): val is TableDataRow {
  if (!(val instanceof Object)) {
    return false;
  }
  if (!typeIsTranslateableText(val.title)) {
    return false;
  }
  if (!typeIsArrayOf(val.values, isNumber)) {
    return false;
  }

  return true;
}

export interface TableData {
  header: TranslateableText[];
  rows: TableDataRow[];
}

export function typeIsTableData(val: any): val is TableData {
  if (!(val instanceof Object)) {
    return false;
  }

  const header = val.header;
  if (!typeIsArrayOf(header, typeIsTranslateableText)) {
    return false;
  }

  const rows = val.rows;
  if (!typeIsArrayOf(rows, typeIsTableDataRow)) {
    return false;
  }
  const dataColCount = header.length - 1;
  if (!rows.every(({ values }) => values.length === dataColCount)) {
    return false;
  }

  return true;
}

export class AuditDimensionResultState implements AuditDimensionResult {
  constructor(
    public readonly dimension: NamedRef,
    public readonly flatData: FlatAuditItemResultData[],
    public readonly scoreResult: Readonly<ScoreResult>,
    public readonly categoryScoreResult: Readonly<CategeoryScoreResult>[],
    public readonly auditResultSteps: AuditResultSteps
  ) {}
}

export class AuditResultState {
  flatData: StatedGetter<FlatAuditItemResultData[]> = new StatedGetter();
  scoreResult: StatedGetter<Readonly<ScoreResult>> = new StatedGetter();
  categoryScoreResult: StatedGetter<Readonly<CategeoryScoreResult>[]> =
    new StatedGetter();
  auditResultSteps: StatedGetter<AuditResultSteps> = new StatedGetter();
  dimensionsResult: StatedGetter<AuditDimensionResultState[]> =
    new StatedGetter();
  answerConstraintResults: StatedGetter<ConstraintResult[]> =
    new StatedGetter();
}
