import { last, mapValues, pick } from "lodash";
import { RootState, RootGetters } from "@/store/types";
import { State } from "./types";
import { Getters } from ".";

type Getter<R> = (
  state: State,
  getters: Getters,
  rootState: RootState,
  rootGetters: RootGetters
) => R;

const getIsOperationRunning: Getter<(operationId: string) => boolean> =
  state => {
    return operationId => {
      const runningCount = state.operations[operationId] ?? 0;
      return runningCount > 0;
    };
  };

const getMessages: Getter<State["messages"] | null> = state => {
  return state.messages;
};

const getLastMessage: Getter<State["messages"][number] | null> = state => {
  return last(state.messages) ?? null;
};

const getters = {
  getIsOperationRunning,
  getLastMessage,
  getMessages,
};

const n = mapValues(getters, (_, key) => key);

export { n as getterNames, getters };
