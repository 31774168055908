import camelCase from "lodash/camelCase";
const requireModule = require.context(
  ".",
  true,
  /^\.\/[a-zA-Z][a-zA-Z0-9_-]+\/index\.[tj]s$/
);
const modules: any = {};
const modulesRegex = /^\.\/([a-zA-Z][a-zA-Z0-9_-]+)\/index\.[tj]s$/;

requireModule.keys().forEach(fileName => {
  const matches = modulesRegex.exec(fileName);

  if (matches === null) {
    return;
  }
  const moduleName = camelCase(matches[1]);
  modules[moduleName] = {
    // add namespace here
    namespaced: true,
    ...requireModule(fileName).default,
    // if you have exported the object with name in the module `js` file
    // e.g., export const name = {};
    // uncomment this line and comment the above
    // ...requireModule(fileName)[moduleName]
  };
});

export default modules;
