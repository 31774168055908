

























import Vue from "vue";
import { Component } from "vue-property-decorator";

import { VCard } from "vuetify/lib";

@Component({
  components: { VCard },
})
export default class AListCard extends Vue {}
