


































































import Vue, { PropType } from "vue";
import { MetaStatus } from "@auditcloud/shared/lib/workflow/types/State";
import { Component, Prop } from "vue-property-decorator";
import AUserSnippet from "@/components/snippets/AUserSnippet.vue";
import ADateSnippet from "@/components/snippets/ADateSnippet.vue";
import { idable } from "@auditcloud/shared/lib/types/common";
import { MeasureProcessStepDoc } from "@auditcloud/shared/lib/schemas";

const STEP_STATE = ["open", "doing", "done", "failed"] as const;
type StepState = typeof STEP_STATE[number];

@Component({ components: { AUserSnippet, ADateSnippet } })
export default class AMeasureProcessStepMetaStatusInfo extends Vue {
  @Prop({
    default: "open",
    type: String as PropType<StepState>,
    validator: (v: any) => STEP_STATE.includes(v),
  })
  stepState!: StepState;

  @Prop({
    required: true,
    type: Object as PropType<idable<MeasureProcessStepDoc>>,
  })
  step!: idable<MeasureProcessStepDoc>;

  @Prop({
    required: false,
    type: Object as PropType<idable<MeasureProcessStepDoc> | null>,
  })
  nextStep?: idable<MeasureProcessStepDoc> | null;

  @Prop({ type: String, required: true })
  metaStatus!: MetaStatus;

  @Prop({ type: Boolean, default: false })
  isResetStep!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  isCanceledStep!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isRevoked!: boolean;

  get dateTemplateString(): string {
    if (
      this.isResetStep ||
      (this.metaStatus === "complete" && this.stepState === "failed")
    ) {
      return "{enteredAt}";
    }
    return this.stepState === "doing" ? "{enteredAt}" : "{leftAt}";
  }

  get userIdTemplateString(): string {
    if (
      this.isResetStep ||
      (this.metaStatus === "complete" && this.stepState === "failed")
    ) {
      return "{enteredBy}";
    }
    return this.stepState === "doing" ? "{enteredBy}" : "{leftBy}";
  }

  get userId(): string | null {
    return this.stepState === "doing"
      ? this.step.workflowInfo.creatorRef.id
      : this.nextStep?.workflowInfo.creatorRef.id ?? null;
  }

  get date(): string | null {
    return this.stepState === "doing"
      ? this.step.workflowInfo.enteredAt
      : this.nextStep?.workflowInfo.enteredAt ?? null;
  }

  get splitI18nString() {
    const splitted = this.rawI18n
      .replace(this.dateTemplateString, this.userIdTemplateString)
      .split(this.userIdTemplateString);

    return {
      pre: splitted.shift(),
      end: splitted.pop(),
      mid: splitted.join(""),
    };
  }

  get isDateFirst(): boolean {
    return (
      this.rawI18n.search(this.dateTemplateString) <
      this.rawI18n.search(this.userIdTemplateString)
    );
  }

  get rawI18n() {
    const baseI18nKey =
      "components.controls.measure_process_steps.meta_status_info";

    if (this.metaStatus === "new" && this.isActive) {
      return this.$t(`${baseI18nKey}.measure_new_doing`).toString();
    } else if (this.metaStatus === "new") {
      return this.$t(`${baseI18nKey}.measure_new_done`).toString();
    } else if (this.metaStatus === "complete") {
      return this.$t(`${baseI18nKey}.measure_complete`).toString();
    }
    return this.$t(`TODO: ${baseI18nKey}.date`).toString();
  }

  get isActive() {
    return this.stepState === "doing" && this.metaStatus !== "complete";
  }

  get iconConfig() {
    if (this.isResetStep) {
      return { icon: "mdi-cancel", color: "grey lighten-2" };
    }
    if (this.isCanceledStep) {
      return { icon: "mdi-cancel", color: "grey lighten-2" };
    }
    if (this.metaStatus === "complete" && this.stepState !== "failed") {
      return {
        icon: "mdi-check",
        color:
          this.isResetStep || this.isRevoked ? "grey lighten-2" : "success",
      };
    }

    switch (this.stepState) {
      case "doing":
        return {
          icon: "mdi-chevron-right",
          color: "primary",
        };
      case "failed":
        return {
          icon: "mdi-close",
          color:
            this.isResetStep || this.isRevoked ? "grey lighten-2" : "error",
        };
      case "done":
        return {
          icon: "mdi-check",
          color:
            this.isResetStep || this.isRevoked ? "grey lighten-2" : "success",
        };
      default:
        return {
          icon: null,
          color: "#828282",
        };
    }
  }
}
