













































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { Attachment } from "../types";
import { getIconForMimeType } from "@auditcloud/shared/lib/utils/FileManagement";

@Component({
  components: {},
  name: "AAttachmentsSelectDialog",
})
export default class AAttachmentsSelectDialog extends Vue {
  uploadDragVisible = false;
  uploadCardDragVisible = false;

  newComment = "";
  files: File[] = [];

  @Prop({ type: Boolean, required: true })
  value!: boolean;

  @Prop({ type: Boolean, required: true })
  isUploading!: boolean;

  @Prop({ type: Boolean, required: true })
  multiple!: boolean;

  @Prop({ type: Array, required: true })
  preSelection!: File[];

  mounted() {
    this.files = this.preSelection;
  }

  selectFiles() {
    const input = document.createElement("input");
    input.setAttribute("type", "file");

    if (this.multiple) {
      input.setAttribute("multiple", "true");
    }

    input.style.visibility = "hidden";
    document.body.appendChild(input);
    input.addEventListener("change", async (e: Event) => {
      if ((e.target as any).files instanceof FileList) {
        const files: FileList = (e.target as any).files;
        this.files = Array.from(files);
      }
      document.body.removeChild(input);
    });
    input.click();
  }

  removeFile(i: number) {
    this.files = [...this.files.slice(0, i), ...this.files.slice(i + 1)];
  }

  addFiles(e: DragEvent) {
    if (e.dataTransfer) {
      console.log("ADD_ FILES: ", e);
      this.files = Array.from(e.dataTransfer.files).slice(
        0,
        this.multiple ? undefined : 1
      ) as File[];
    }
    this.uploadDragVisible = false;
  }

  iconForMimeType(type: string) {
    return getIconForMimeType(type);
  }

  uploadSelected() {
    const attachments = this.files.map((file): Attachment => {
      return {
        name: file.name,
        icon: getIconForMimeType(file.type),
        data: file,
        comment: this.newComment,
      };
    });
    this.$emit("upload", attachments);
  }
}
