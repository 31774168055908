import firebase from "firebase/compat/app";
import { MutationTree, ActionTree, GetterTree, Module } from "vuex";
import {
  AuditStandard,
  validatingCastAuditStandard,
  createStandardRef,
  createChapterRefs,
} from "@/types/Standard";
import {
  ChapterRefType,
  AuditStandardRef,
} from "@auditcloud/shared/lib/schemas";

import { RootState } from "@/store/types";
import { toPairs } from "lodash";
import { CollectionNames } from "@auditcloud/shared/lib/types/common";
import { rdc, rdu } from "../user";
import { idfy } from "@auditcloud/shared/lib/utils";
import { TodoAny } from "@auditcloud/shared/lib/utils/type-guards";
import { MutationsType, GettersType, ActionsType } from "@/utils/VuexHelper";
import { ft } from "@/plugins/ContentTranslation";

interface IMappedStandards {
  [id: string]: AuditStandard;
}

export class AuditStandardsState {
  loadedStandards: IMappedStandards = {};
}

export const api = {
  namespace: "standards",
  actions: {
    loadStandards: "loadStandards",
    importStandards: "importStandards",
  },
  mutations: {
    resetState: "resetState",
    setloadedStandards: "setloadedStandards",
    createStandard: "createStandard",
  },
  getters: {
    loadedStandards: "loadedStandards",
    getStandard: "getStandard",
    getChapterListForStandard: "getChapterListForStandard",
    getStandardList: "getStandardList",
  },
  state: {
    loadedStandards: "loadedStandards",
  },
};

const state = (() => new AuditStandardsState())();

const actions: ActionTree<AuditStandardsState, RootState> = {
  async [api.actions.loadStandards]({ commit, dispatch }) {
    dispatch("app/setLoading", false, { root: true });
    try {
      const snapshot = await firebase
        .firestore()
        .collection(CollectionNames.STANDARDS)
        .get();
      let standards: IMappedStandards = {};
      snapshot.forEach(doc => {
        const id = doc.id;
        standards[id] = validatingCastAuditStandard(doc.data(), id);
      });
      commit(api.mutations.setloadedStandards, standards);
      dispatch("app/setLoading", false, { root: true });
    } catch (err) {
      console.error(err);
      dispatch(
        "app/addStatus",
        {
          message: err.message,
          type: "error",
        },
        { root: true }
      );
      dispatch("app/setLoading", false, { root: true });
      throw err;
    }
  },
  async [api.actions.importStandards](
    { commit, rootGetters },
    { data }: TodoAny
  ) {
    const standard = validatingCastAuditStandard(data, data.id);
    const id = standard.id ?? idfy(ft(standard.name));
    standard.id = id;

    const doc = await firebase
      .firestore()
      .collection(CollectionNames.STANDARDS)
      .doc(id)
      .get();

    if (
      !doc.exists ||
      (doc.exists &&
        confirm(
          "Ein Standard mit der angegebenen ID existiert schon.\nSoll der bestehende Standard überschrieben werden?"
        ))
    ) {
      /*await doc.ref.update({
        ...standard,
        ...(doc.exists ? rdu(rootGetters) : rdc(rootGetters))
      }); */

      await firebase.firestore().runTransaction(async transaction => {
        if (doc.exists) {
          transaction.update(doc.ref, {
            ...standard,
            ...rdu(rootGetters),
          });
        } else {
          transaction.set(doc.ref, {
            ...standard,
            ...rdc(rootGetters),
          });
        }
      });

      const payload: CreateStandardMutationOptions = {
        item_id: id,
        data: standard,
      };
      commit(api.mutations.createStandard, payload);
      return true;
    } else {
      return false;
    }
  },
};

interface CreateStandardMutationOptions {
  data: AuditStandard;
  item_id: string;
}

const mutations: MutationTree<AuditStandardsState> = {
  [api.mutations.resetState](state) {
    Object.assign(state, new AuditStandardsState());
  },
  [api.mutations.setloadedStandards](state, standards: IMappedStandards) {
    state.loadedStandards = standards;
  },
  [api.mutations.createStandard](
    state,
    payload: CreateStandardMutationOptions
  ) {
    const standard_id = payload.item_id;
    state.loadedStandards[standard_id] = payload.data;
  },
};

const getters: GetterTree<AuditStandardsState, RootState> = {
  [api.getters.loadedStandards](state) {
    return state.loadedStandards;
  },
  [api.getters.getStandardList](state): AuditStandardRef[] {
    const standards = toPairs(state.loadedStandards).map(([id, value]) => {
      return createStandardRef(value, id);
    });
    console.log("StandardsList", standards);
    return standards;
  },
  [api.getters.getChapterListForStandard](
    state
  ): (id: string) => ChapterRefType[] {
    return id => {
      const standard = state.loadedStandards[id] ?? null;
      return standard ? createChapterRefs(standard) : [];
    };
  },
  [api.getters.getStandard](state): (id: string) => AuditStandard {
    return (id: string): AuditStandard => {
      if (state.loadedStandards[id]) {
        return state.loadedStandards[id];
      } else {
        throw new ReferenceError(`Standard with id ${id} not found`);
      }
    };
  },
};

const namespaced: boolean = true;
const modul: Module<AuditStandardsState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
export type Mutations = MutationsType<typeof mutations>;
export type Getters = GettersType<typeof getters>;

export default modul;
