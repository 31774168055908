


















































































































import { extractSignificantCategories } from "@/store/modules/audit/utils";
import { AuditItem } from "@auditcloud/shared/lib/schemas";
import ASnippetCategoryRef from "@/components/snippets/ASnippetCategoryRef.vue";
import { uniqBy } from "lodash";
import naturalCompare from "natural-compare";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { api as confApi } from "@/store/modules/configuration";
import { api as auditApi } from "@/store/modules/audit";
import { AuditItemCategorySetMap } from "@auditcloud/shared/lib/types/AuditItemCategory";

@Component({ components: { ASnippetCategoryRef } })
export default class ALinkFindingToRequirementWidget extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  items!: AuditItem[];
  @Getter(auditApi.getters.getAreMultipleStandardsReferenced, {
    namespace: auditApi.namespace,
  })
  hasMultipleStandardIds!: boolean;

  @Getter(confApi.getters.getCategoryMapping, {
    namespace: confApi.namespace,
  })
  mappedCategorySet!: AuditItemCategorySetMap;
  @Getter(auditApi.getters.getAuditCategorySetId, {
    namespace: auditApi.namespace,
  })
  categorySetId!: string;

  /** Checks multiple queries, which can be separated by whitespaces.
   * All queries need to match to show an item in the list */
  filter(item: any, queryText: string, itemText: string) {
    if (item.header) return false;

    const hasValue = val => (val != null ? val : "");
    const text: string = hasValue(itemText).toLowerCase();
    const queries: string[] = hasValue(queryText)
      .toLowerCase()
      .split(" ")
      .filter(hasValue);

    const hasMatch = queries.every(term => text.includes(term));

    return hasMatch;
  }
  auditItemSearchText(auditItem: AuditItem): string {
    const { text, no, chapters, categoryRef } = auditItem.question;

    const translation = this.$ft(text).toString();

    const categories = extractSignificantCategories(categoryRef, "root").map(
      categoryId =>
        this.$ft(
          this.mappedCategorySet[this.categorySetId].categories.find(
            category => category.id === categoryId
          )?.name ?? ""
        )
    );

    return `${no} ${chapters
      .map(chapter => chapter.chapterId)
      .join("-")} ${categories.join("-")} ${translation}`;
  }
  get auditItemsWithSearchText(): Array<AuditItem & { text: string }> {
    return this.items
      .map(auditItem => {
        return {
          ...auditItem,
          text: this.auditItemSearchText(auditItem),
        };
      })
      .sort((lhs, rhs) =>
        naturalCompare(lhs.question.no.toString(), rhs.question.no.toString())
      );
  }
  chapterGroups(auditItem: AuditItem) {
    const chapters = auditItem.question.chapters;
    const uniqChaptersById = uniqBy(chapters, "standardId");

    return uniqChaptersById.map(chapter => {
      return {
        standardName: chapter.standardName,
        categoryRef: auditItem.question.categoryRef,
        chapters: chapters.filter(c => c.standardId === chapter.standardId),
      };
    });
  }
}
