import { ActionTypeContext } from "@/utils/VuexHelper";
import { TrackEventOptions } from "@gtm-support/vue2-gtm";
import { mapValues } from "lodash";
import Vue from "vue";
import { Getters } from ".";
import { RootState } from "../../types";
import { GtmState } from "./types";

type Context = ActionTypeContext<GtmState, RootState, Getters>;

const actions = {
  async trackEvent(context: Context, payload: TrackEventOptions | undefined) {
    if (Vue.gtm) {
      Vue.gtm.trackEvent(payload);
    }
  },
  async trackView(
    context: Context,
    {
      screenName,
      path,
      additionalEventData,
    }: {
      screenName: string;
      path: string;
      additionalEventData?: Record<string, any> | undefined;
    }
  ) {
    if (Vue.gtm) {
      Vue.gtm.trackView(screenName, path, additionalEventData);
    }
  },
};

const n = mapValues(actions, (_, key) => key);

export { n as actionNames, actions };
