












































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import AAuditResultSummary from "@/components/widgets/AAuditResultSummary.vue";
import AAuditResultDataWrapper from "@/components/widgets/AAuditResultDataWrapper.vue";

@Component({
  components: {
    AAuditResultSummary,
    AAuditResultDataWrapper,
  },
})
export default class AuditIntermediateResultDialog extends Vue {
  dialog: boolean = true;

  close() {
    this.$router.go(-1);
    this.dialog = false;
  }
}
