



























































































































import { Component, Prop, Mixins } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  TodoAny,
  TodoActionSignature,
} from "@auditcloud/shared/lib/utils/type-guards";

// Mixins
import FileUploaderMixin from "@/components/mixins/FileUploaderMixin.vue";

import { api as userApi, Getters as UserGetters } from "@/store/modules/user";
import { api as appApi } from "@/store/modules/app";

@Component({ mixins: [FileUploaderMixin] })
export default class AUserInfoDialog extends Mixins(FileUploaderMixin) {
  @Prop({
    type: Boolean,
    required: true,
  })
  openDialog!: boolean;

  @Getter(userApi.getters.getCurrentUser, { namespace: userApi.namespace })
  currentUser!: UserGetters["getCurrentUser"];

  @Getter(userApi.getters.getCurrentUserRoles, { namespace: userApi.namespace })
  currentUserRoles!: UserGetters["getCurrentUserRoles"];

  @Getter(appApi.getters.getIsOnline, { namespace: appApi.namespace })
  getIsOnline!: TodoAny;

  get currentRoles() {
    return this.currentUserRoles.map(id => {
      return {
        id,
        description: this.$t(`views.user.roles_${id}`),
        member: true,
      };
    });
  }

  @Action(appApi.actions.setStatus, { namespace: appApi.namespace })
  setStatus!: TodoActionSignature;

  updateAvatar(user_id) {
    console.log("updateAvatar", user_id, this.getIsOnline);
    if (this.getIsOnline) {
      // TODO: Muss hier sinnvollerweise eine User-ID vergeben werden?

      (this.$refs.image as TodoAny).click();
    }
  }

  handleDialogStatus(event: boolean) {
    if (!event) {
      this.$emit("closeDialog");
    }
  }
}
