















































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { extractSignificantCategories } from "@/store/modules/audit/utils";

import {
  CategoryLevel,
  EmbeddedCategories,
  EmbeddedCategory,
} from "@auditcloud/shared/lib/schemas";

@Component({})
export default class ASnippetCategory extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  category!: EmbeddedCategories;

  @Prop({
    type: [Object, String],
    required: true,
  })
  categoryLevel!: CategoryLevel;

  @Prop({
    type: Array,
    default: () => [],
  })
  customClasses!: any;

  @Prop({
    default: false,
    type: Boolean,
  })
  filter!: boolean;

  @Prop({
    default: "primary",
    type: String,
  })
  color!: string;

  @Prop({
    default: false,
    type: Boolean,
  })
  showCategoriesInSingleRow!: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  label!: boolean;

  get categories() {
    const significant: EmbeddedCategory[] = extractSignificantCategories(
      this.category.categoryPath,
      this.categoryLevel
    );
    return significant;
  }

  get categoryNames() {
    return this.categories.map(v => this.$ct(v.name)).join(" / ");
  }

  selectFilter(category: EmbeddedCategory) {
    if (this.filter) {
      this.$emit("filter-selected", {
        id: category.id,
        name: this.$ct(category.name),
      });
    }
  }
}
