import _Vue from "vue";
import Auth from "../services/auth";
import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";

export interface UserPluginType {
  ref: () => IUserRef | null;
  avatar: () => string;
  displayName: () => string;
  email: () => string;
  id: () => string;
  roles: () => string[];
  isAdmin: () => boolean;
}

/*
  User plugin, provides short-circuits to user data inside vue components.-
  Eg.
    * if (this.$user.isAdmin() ) { ... }
    * {{ $user.displayName() }}
*/

export function UserPlugin(Vue: typeof _Vue, options?: any): void {
  Vue.prototype.$user = {
    ref: () => Auth.getUserRef(),
    avatar: () => Auth.getUserAvatar(),
    displayName: () => Auth.userDisplayName(),
    email: () => Auth.getUserEmail(),
    id: () => Auth.getUserId(),
    roles: () => Auth.getUserRoles(),
    isAdmin: () => Auth.isAdmin(),
  };
}
