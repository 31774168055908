











import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { api } from "@/store/modules/audit";
import { api as auditResultApi } from "@/store/modules/auditResult";

import ACalculationFullfilment from "@/components/widgets/ACalculationFullfilment.vue";
import ACalculationCompliance from "@/components/widgets/ACalculationCompliance.vue";
import ACalculationVda from "@/components/widgets/ACalculationVda.vue";
import { ScoreCalcRules } from "@/store/modules/auditResult/getters";

@Component({
  components: {
    ACalculationCompliance,
    ACalculationVda,
    ACalculationFullfilment,
  },
})
export default class AAuditReportCalculation extends Vue {
  @Getter(api.getters.getIsVdaAudit, {
    namespace: api.namespace,
  })
  isVdaAudit!: boolean;

  @Getter(auditResultApi.getters.getScoreCalcRules, {
    namespace: auditResultApi.namespace,
  })
  scoreCalcRules!: ScoreCalcRules;

  get showCalculationFullfilment() {
    return (
      (this.scoreCalcRules.scoringConfig.calculationMethod === "percentual" ||
        this.scoreCalcRules.scoringConfig.calculationMethod === "vda") &&
      !this.scoreCalcRules.hidePointsCalculation
    );
  }
}
