


































/* tslint:disable:no-console */
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

import { api } from "@/store/modules/measureProcess";
import {
  MeasureProcessMetadata,
  MeasureProcessStep,
  StepState,
} from "@/store/modules/measureProcess/types";

import { nullable } from "@auditcloud/shared/lib/types/common";
import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";
import { TodoArray } from "@auditcloud/shared/lib/utils/type-guards";
import { MeasureActivity } from "@/types/measure-activities";

import { Dictionary } from "lodash";

const ICONS: Dictionary<string | null> = {
  [StepState.Done]: "check",
  [StepState.WorkInProgress]: "chevron_right",
  [StepState.Verify]: "chevron_right",
  [StepState.Idle]: null,
  [StepState.New]: null,
};
const IconForState = (state: StepState) => {
  return state ? ICONS[state] ?? null : "check";
};

const COLORS: Dictionary<string> = {
  [StepState.Done]: "success",
  [StepState.WorkInProgress]: "primary",
  [StepState.Verify]: "primary",
  [StepState.Idle]: "#828282",
  [StepState.New]: "#828282",
};
const ColorForState = (state: StepState) => {
  return state ? COLORS[state] ?? "#828282" : "success";
};

@Component({
  components: {},
})
export default class AMeasureProsessStepsWidget extends Vue {
  loading: boolean = false;
  found: boolean = false;

  @Getter(api.getters.getMeasureProcessSteps, {
    namespace: api.namespace,
  })
  measureProcessSteps!: MeasureProcessStep[];

  get stepItems() {
    return this.measureProcessSteps.map(step => {
      return {
        icon: IconForState(step.state),
        color: ColorForState(step.state),
        doc: step,
      };
    });
  }

  @Action(api.actions.startStep, {
    namespace: api.namespace,
  })
  startStep!: (payload: any) => Promise<void>;

  created() {
    console.log("AMeasureProsessStepsWidget:created");
  }

  mounted() {
    console.log(this.measureProcessSteps);
    console.log("AMeasureProsessStepsWidget:mounted");
  }

  beforeDestroy() {
    console.log("AMeasureProsessStepsWidget:beforeDestroy");
  }
}
