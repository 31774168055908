import { ActionTree } from "vuex";
import firebase from "firebase/compat/app";
import { RootState } from "../../types";
import {
  I18nTranslationsState,
  TranslationPatch,
  TranslationMap,
  TranslationPatchMap,
} from "./types";
import { mutationNames as mn } from "./mutations";
import { createCollectionObserver } from "@/utils/firestore";
import { CollectionNames } from "@auditcloud/shared/lib/types/common";
import i18n from "@/plugins/i18n";
import { toPairs, Dictionary, set } from "lodash";

const listenOnTranslations = "listenOnTranslations";
const patchTranslations = "patchTranslations";
const storeTranslations = "storeTranslations";
const n = {
  listenOnTranslations,
  patchTranslations,
  storeTranslations,
};

const actions: ActionTree<I18nTranslationsState, RootState> = {
  async [n.listenOnTranslations](context) {
    const unlistener = createCollectionObserver(
      CollectionNames.TRANSLATIONS,
      {},
      updateData => {
        const patchData: TranslationPatch = updateData.modifiedDocs.map(doc => {
          return {
            locale: doc.id,
            values: doc.data ?? {},
          };
        });
        return context.dispatch(n.patchTranslations, {
          removed: updateData.removeDocs,
          modified: patchData,
        });
      },
      metadataUpdate => {},
      (error, collection) => {
        console.error("Error(listenOnTranslations): ", error, collection);
      },
      () => {}
    );
    context.commit(mn.SET_TRANSLATIONS_UNLISTENER, unlistener);
  },
  async [n.patchTranslations](
    context,
    { removed, modified }: { removed: string[]; modified: TranslationPatch }
  ) {
    modified.forEach(patch => {
      i18n.mergeLocaleMessage(patch.locale, patch.values);
    });

    context.commit(mn.SET_TRANSLATION_PATCH, { removed, modified });
  },
  async [n.storeTranslations](context, payload: TranslationPatchMap) {
    const documents: Dictionary<TranslationMap> = {};

    toPairs(payload).forEach(([key, translations]) => {
      toPairs(translations).forEach(([locale, translation]) => {
        set(documents, `${locale}.${key}`, translation);
      });
    });

    const db = firebase.firestore();

    const collectionRef = db.collection(CollectionNames.TRANSLATIONS);

    await db.runTransaction(async transaction => {
      toPairs(documents).forEach(([locale, translationMap]) => {
        transaction.set(collectionRef.doc(locale), translationMap);
      });
    });
  },
};

export { n as actionNames, actions };
