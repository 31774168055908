import firebase from "firebase/compat/app";
import { UserDoc } from "@auditcloud/shared/lib/schemas";
import { idable } from "@auditcloud/shared/lib/types/common";

export class UserState {
  user: idable<UserDoc> | null = null;
  firebaseUser: firebase.User | null = null;
  firebaseClaims: { roles?: string[]; [x: string]: unknown } = {};
}
export type State = UserState;
