import firebase from "firebase/compat/app";
import { CollectionObserverConfig } from "./types/CollectionObserverConfig";
import { WatchedDocument } from "./types/WatchedDocument";
import { DocumentsUpdateHandlerData } from "./types/DocumentsUpdateHandlerData";
import { createCollectionObserver } from "./CollectionObserver";
import { first, flatten, get, last, isArray } from "lodash";
import { OrderByData } from "./types/OrderByData";

type StateUpdateHandler = (state: PagingCollectionObserverState) => void;

type Page = Array<WatchedDocument<firebase.firestore.DocumentData>>;
type PageState = {
  page: Page;
  isLoading: boolean;
  error: null | Error;
};

export type PagingCollectionObserverState = {
  isLoading: boolean;
  hasMore: boolean;
  hasError: boolean;
  errors: Array<null | Error>;
  documents: Page;
  /** is `true` if a query has been made */
  isLive: boolean;
};

export const INITIAL_STATE: PagingCollectionObserverState = {
  isLoading: false,
  hasMore: false,
  hasError: false,
  errors: [],
  documents: [],
  isLive: false,
};

export type PagingCollectionObserver = {
  fetchMore: () => void;
  state: () => PagingCollectionObserverState;
  unsubscribe: () => void;
};

/**
 * Create a paging collection observer for infinite scroll with live updates.
 *
 * Under the hood, additional collection observers are created whenever more
 * results are fetched using the `fetchMore` method of the paging observer.
 *
 * All state, including the complete list of documents and the loading state,
 * is pushed through a single `stateUpdateHandler` callback.
 * Alternatively, the state can be pulled using the `state` method.
 *
 * Note that
 *  - the `limit` parameter of the config can be set to `Infinity` (no paging)
 *  - you should call `unsubscribe` when you no longer need the observer
 */
export function createPagingCollectionObserver(
  collection: string,
  config: CollectionObserverConfig,
  stateUpdateHandler?: StateUpdateHandler
): PagingCollectionObserver {
  const pageObservers: Array<() => void> = [];
  const pageStates: PageState[] = [];
  let pagesToRefresh = 0;
  let stateUpdateTask: null | number = null;

  // always fetch an additional item, to determine the next start ID (if any)
  const limit = (config.limit ?? Infinity) + 1;
  const result: PagingCollectionObserver = {
    fetchMore,
    state,
    unsubscribe,
  };
  fetchMore();
  return result;

  /** get a copy of the current observer state, including the live documents */
  function state(): PagingCollectionObserverState {
    const lastPage = last(pageStates)?.page ?? null;
    return {
      isLoading:
        pagesToRefresh > 0 || pageStates.some(state => state.isLoading),
      hasMore:
        pagesToRefresh === 0 && lastPage != null && lastPage.length === limit,
      hasError: pageStates.some(state => state.error != null),
      errors: pageStates.map(state => state.error),
      documents: merged(pageStates.map(state => state.page)),
      isLive: true,
    };
  }

  async function fetchMore() {
    const pageIndex = pageStates.length;
    const pageConfig = { ...config, limit };
    const lastPage = pageStates[pageIndex - 1]?.page;
    pagesToRefresh = Math.max(0, pagesToRefresh - 1);
    if (lastPage) {
      if (lastPage.length < limit) {
        console.log("fetchMore: nothing to do");
        pagesToRefresh = 0;
        return;
      }
      const lastDoc = await firebase
        .firestore()
        .collection(collection)
        .doc(lastPage[lastPage.length - 1].id)
        .get();
      pageConfig.startAtDoc = lastDoc;
    }

    pageStates.push({
      page: [],
      isLoading: true,
      error: null,
    });
    pageObservers.push(
      createCollectionObserver(
        collection,
        pageConfig,
        handlePageDocumentsUpdate,
        () => {},
        handlePageLoadingError,
        handlePageLoadingStateUpdate
      )
    );
    scheduleStateUpdate();

    function handlePageDocumentsUpdate(updateData: DocumentsUpdateHandlerData) {
      const { modifiedDocs, removeDocs } = updateData;
      const pageState = pageStates[pageIndex];
      pageState.page = pageState.page.filter(
        doc => !removeDocs.includes(doc.id)
      );
      modifiedDocs.forEach(doc => {
        const docIndex = pageState.page.findIndex(({ id }) => id === doc.id);
        if (docIndex === -1) {
          pageState.page.push(doc);
        } else {
          pageState.page[docIndex] = doc;
        }
      });
      sort(pageState.page);
      scheduleStateUpdate();
    }

    function handlePageLoadingError(error: Error, collection: string) {
      pageStates[pageIndex].error = error;
      scheduleStateUpdate();
    }

    function handlePageLoadingStateUpdate(pageIsLoading: boolean) {
      console.log("handlePageLoadingStateUpdate", collection, pageIsLoading);
      const wasLoading = pageStates[pageIndex].isLoading;
      pageStates[pageIndex].isLoading = pageIsLoading;
      if (wasLoading && !pageIsLoading && pagesToRefresh > 0) {
        fetchMore();
      }
      scheduleStateUpdate();
    }

    /** Update the state at most once per underlying update. */
    function scheduleStateUpdate() {
      if (stateUpdateTask === null) {
        stateUpdateTask = window.setTimeout(updateState, 0);
      }
    }

    /**
     * Run the stateUpdateHandler, and perform a consistency check:
     *
     * When page boundaries do not match anymore because documents were
     * added/removed, discard subsequent pages and fetch a fresh version.
     */
    function updateState() {
      // first, perform the consistency check to avoid e.g. Vue duplicate keys
      for (let i = 0; i < pageStates.length - 1; ++i) {
        const currentPage = pageStates[i].page;
        const nextIndex = i + 1;
        const nextPage = pageStates[nextIndex].page;
        if (pageStates[nextIndex].isLoading) {
          break;
        }
        if (last(currentPage)?.id !== first(nextPage)?.id) {
          console.log(`checkConsistency: refreshing pages, starting at ${i}`);
          pagesToRefresh = pageStates.length - nextIndex;
          pageObservers.slice(nextIndex).forEach(unsubscribe => {
            unsubscribe();
          });
          pageObservers.splice(nextIndex);
          pageStates.splice(nextIndex);
          fetchMore();
          break;
        }
      }
      // then, publish the update
      stateUpdateHandler?.(state());
      stateUpdateTask = null;
    }
  }

  function unsubscribe() {
    if (stateUpdateTask !== null) {
      window.clearTimeout(stateUpdateTask);
    }
    pageObservers.forEach(unsubscribe => {
      unsubscribe();
    });
  }

  function sort(page: Page): void {
    const fallbackOrderConfig: OrderByData[] = [{ fieldPath: "id" }];
    const fallbackComparator = docComparator(fallbackOrderConfig);

    const orderByConfig = config.orderBy
      ? isArray(config.orderBy)
        ? config.orderBy
        : [config.orderBy]
      : fallbackOrderConfig;

    const comparator = docComparator(orderByConfig);
    page.sort(comparator);

    type Order = -1 | 0 | 1;
    function docComparator(
      sortOptions: { fieldPath: string; direction?: "asc" | "desc" }[]
    ) {
      type Doc = WatchedDocument<firebase.firestore.DocumentData>;
      return (a: Doc, b: Doc): Order => {
        let order: Order = 0;
        for (const sortOption of sortOptions) {
          const aSortValue = get(a, sortOption.fieldPath);
          const bSortValue = get(b, sortOption.fieldPath);

          order =
            aSortValue < bSortValue ? -1 : aSortValue === bSortValue ? 0 : 1;

          if (order !== 0) {
            if (sortOption.direction === "desc") {
              order = (order * -1) as Order;
            }
            break;
          }
        }

        if (order === 0 && !sortOptions.find(v => v.fieldPath === "id")) {
          return fallbackComparator(a, b);
        }

        return order;
      };
    }
  }

  function merged(pages: Page[]): Page {
    const flattened: Page = flatten(
      pages.map(page => page.slice(0, limit - 1))
    );
    sort(flattened);
    return flattened;
  }
}
