import { TranslateableText } from "@auditcloud/shared/lib/schemas";
import { Dictionary } from "lodash";
import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";
import { typeIsMapOf } from "@auditcloud/shared/lib/utils/type-guards";
import {
  TypeGuard,
  TypeGuardWithErrors,
  typeIsTranslateableText,
} from "@auditcloud/shared/lib/types/common";
import { AxiosResponse, AxiosError } from "axios";

export function extractString(
  data: any,
  fieldName: string,
  fallback?: string
): string {
  if (data instanceof Object && typeof data[fieldName] === "string") {
    return data[fieldName];
  } else if (typeof fallback === "string") {
    return fallback;
  } else {
    throw new Error(`Expect ${fieldName} to be a string`);
  }
}

export function extractArray(
  data: any,
  fieldName: string,
  fallback?: any[]
): any[] {
  if (data instanceof Object && Array.isArray(data[fieldName])) {
    return data[fieldName];
  } else if (typeof fallback !== "undefined") {
    return fallback;
  } else {
    throw new Error(`Expect ${fieldName} to be a array`);
  }
}

export function extractMap(
  data: any,
  fieldName: string,
  fallback?: { [key: string]: any }
): { [key: string]: any } {
  if (
    data instanceof Object &&
    typeIsMapOf(data[fieldName], (val: any): val is any => true)
  ) {
    return data[fieldName];
  } else if (typeof fallback !== "undefined") {
    return fallback;
  } else {
    throw new Error(`Expect ${fieldName} to be a map`);
  }
}

export function extractBoolean(
  data: any,
  fieldName: string,
  fallback?: boolean
): boolean {
  if (data instanceof Object && typeof data[fieldName] === "boolean") {
    return data[fieldName];
  } else if (typeof fallback === "boolean") {
    return fallback;
  } else {
    throw new Error(`Expect ${fieldName} to be a boolean`);
  }
}

export function extractTranslateableText(
  data: any,
  fieldName: string,
  fallback?: string
): TranslateableText {
  if (typeIsTranslateableText(data?.[fieldName])) {
    return data[fieldName];
  } else if (typeof fallback === "string") {
    return fallback;
  } else {
    throw new Error(`Expect ${fieldName} name to be a string`);
  }
}

export function extractUserRef(
  data: any,
  fieldName: string,
  fallback?: IUserRef
): IUserRef {
  if (
    data instanceof Object &&
    data[fieldName] instanceof Object &&
    typeof data[fieldName].id === "string" &&
    typeof data[fieldName].displayName === "string"
  ) {
    return data[fieldName];
  } else if (typeof fallback !== "undefined") {
    return fallback;
  } else {
    throw new Error(`Expect ${fieldName} name to be a UserRef`);
  }
}

export function isoString2Date(data: string): Date {
  const date = new Date(data);
  if (isNaN(date.valueOf())) {
    throw new Error(`Convert "${data}" to Date failed`);
  } else {
    return date;
  }
}

export function typeIsToStringInterface(
  value: any
): value is { toString: () => string } {
  return (
    value instanceof Object &&
    "toString" in value &&
    typeof value.toString === "function"
  );
}

export function typeIsDate(value: any): value is Date {
  return Object.prototype.toString.call(value) === "[object Date]";
}

export function typeIsFlattenInterface(
  value: any
): value is { flatten: (prefix: string[]) => Dictionary<any> } {
  return (
    value instanceof Object &&
    "flatten" in value &&
    typeof value.flatten === "function"
  );
}

export function typeIsAxiosError(val: unknown): val is AxiosError<unknown> {
  return (
    val instanceof Error &&
    "isAxiosError" in val &&
    val["isAxiosError"] === true
  );
}

export function assertTypeIsAxiosError(
  val: unknown
): asserts val is AxiosError<unknown> {
  if (!typeIsAxiosError(val)) {
    console.error("Unknown error assertTypeIsAxiosError: ", val);
    throw val;
  }
}

export function assertTypeIsAxiosResponseOf<RS>(
  res: AxiosResponse<unknown>,
  tg: TypeGuard<RS> | TypeGuardWithErrors<RS>
): asserts res is AxiosResponse<RS> {
  if (!tg(res.data)) {
    if ("errors" in tg) {
      console.error(
        `invalid response for "${res.config.method}" "${res.config.url}": `,
        tg.errors()
      );
    }
    throw new Error(
      `invalid response for "${res.config.method}" "${res.config.url}"`
    );
  }
}
