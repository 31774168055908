import { measureStringToValue } from "../../../src/utils/Datefilter";
import i18n from "../../../src/plugins/i18n";
import moment from "moment";

import { auditStatusesInOrder } from "../sort";
import { selfAssessmentStates } from "@auditcloud/shared/lib/utils/audit/auditStatus";

// fill a dictionary with process step translations
const auditStatus: { [translation: string]: number } = {};
i18n.availableLocales.forEach(locale => {
  auditStatusesInOrder.forEach((translationIdentStr, index) => {
    // receive translation --> key; index --> value in auditStatus dictionary
    const translation = i18n.t(translationIdentStr, locale).toString();
    auditStatus[translation] = index;
  });
});

const selfAssessmentStatus: { [translation: string]: number } = {};
i18n.availableLocales.forEach(locale => {
  selfAssessmentStates.forEach((translationIdentStr, index) => {
    // receive translation --> key; index --> value in auditStatus dictionary
    const translation = i18n.t(translationIdentStr.text, locale).toString();
    selfAssessmentStatus[translation] = index;
  });
});

function compareByAuditStatus(a: { key: string }, b: { key: string }): number {
  const lhs = auditStatus[a.key];
  const rhs = auditStatus[b.key];
  return lhs - rhs;
}

function compareBySelfAssessmentStatus(
  a: { key: string },
  b: { key: string }
): number {
  const lhs = selfAssessmentStatus[a.key];
  const rhs = selfAssessmentStatus[b.key];
  return lhs - rhs;
}

function compareDateFilter(a: { key: string }, b: { key: string }): number {
  const lhs = measureStringToValue(a.key);
  const rhs = measureStringToValue(b.key);
  return lhs - rhs;
}

function compareByMonth(a: { key: string }, b: { key: string }): number {
  const lhs = moment(a.key, "MMMM");
  const rhs = moment(b.key, "MMMM");
  const lhsValid = lhs.isValid();
  const rhsValid = rhs.isValid();

  if (!lhsValid && !rhsValid) {
    // if neither is a valid month --> alphanumeric sort
    return a.key.localeCompare(b.key);
  } else if (!lhsValid) {
    return 1;
  } else if (!rhsValid) {
    return -1;
  }
  return lhs.valueOf() - rhs.valueOf();
}

export const itemsJsCompareFunctions: {
  [sortType: string]: (a: { key: string }, b: { key: string }) => number;
} = {
  audit_status: compareByAuditStatus,
  self_assessment_status: compareBySelfAssessmentStatus,
  datefilter: compareDateFilter,
  month: compareByMonth,
};

export function customSortItemsJsBucketItems(
  bucket: Array<{ key: string }>,
  sortOption: { sort: string; order: string | undefined }
) {
  // if custom sort exist, resolve correct function - else return bucket as is
  if (itemsJsCompareFunctions[sortOption.sort]) {
    // do supported sort
    bucket.sort(itemsJsCompareFunctions[sortOption.sort]);
    if (sortOption.order && sortOption.order === "desc") {
      bucket.reverse(); // reverse for desc option
    }
  }

  return bucket;
}
