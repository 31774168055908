
import Vue from "vue";
import { Component } from "vue-property-decorator";
// Register the router hooks with their names
Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate",
]);

import { cloneDeep, isEqual } from "lodash";

@Component({})
export default class FormsControlMixin extends Vue {
  startValues: any = {};
  editValues: any = {};
  isDirtyFlagActive: boolean = true;

  //dialog not necessary anymore
  //TODO dialog as computed property
  mixinDialog: boolean = true;

  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
  }

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNav);
  }

  preventNav(event) {
    if (!this.isDirty) return;
    event.preventDefault();
    // Chrome requires returnValue to be set.
    event.returnValue = this.$t("common.confirms.unsaved_changes").toString();
  }

  /** (re-)initialize form data and edits, e.g. on route update */
  mixinInitFormData(data: any) {
    this.startValues = data;
    this.editValues = cloneDeep(data);
  }

  /** keep local modifications, e.g. after only some fields were changed externally */
  mixinUpdateFormData(data: any) {
    if (this.editValues == null) {
      this.mixinInitFormData(data);
      return;
    }
    const newStartValues = data;
    const newEditValues = cloneDeep(data);
    Object.keys(newStartValues)
      .filter(f => !isEqual(this.editValues[f], this.startValues[f]))
      .forEach(f => {
        console.log(`mixinUpdateFormData: keep "${f}"`);
        newEditValues[f] = this.editValues[f];
      });
    this.startValues = newStartValues;
    this.editValues = newEditValues;
  }

  get mixinFormData(): any {
    return this.editValues;
  }
  set mixinFormData(data: any) {
    this.editValues = cloneDeep(data);
  }

  get isDirty() {
    if (!this.isDirtyFlagActive) {
      return false;
    }
    return !isEqual(this.mixinFormData, this.startValues);
  }

  confirmCancel(): boolean {
    if (this.isDirty) {
      const answer = window.confirm(
        this.$t("common.confirms.unsaved_changes").toString()
      );
      return answer;
    } else {
      return true;
    }
  }

  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave in Formscontrol", this.isDirty);
    if (this.isDirty) {
      const answer = window.confirm(
        this.$t("common.confirms.unsaved_changes").toString()
      );
      if (answer) {
        this.mixinDialog = false;
        this.mixinInitFormData(this.startValues);
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }

  beforeRouteUpdate(to, from, next) {
    if (this.isDirty) {
      const answer = window.confirm(
        this.$t("common.confirms.unsaved_changes").toString()
      );
      if (answer) {
        this.mixinDialog = false;
        this.mixinInitFormData(this.startValues);
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
}
