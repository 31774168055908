import { contentLanguage } from "@/plugins/ContentTranslation";
import { isFeatureEnabled } from "@/plugins/FeatureFlags";
import { AuditMetadataClient } from "@/types/Audit";
import { calcMeasureDueDate } from "@auditcloud/shared/lib/utils/measure/dueDate";
import { colorForIdx, TEXT_TOTAL } from "@auditcloud/shared/lib/constants";
import { AuditClassClient } from "@auditcloud/shared/lib/types/AuditClass";
import { AuditReportExportData } from "@auditcloud/shared/lib/types/AuditReportExport";
import {
  AuditResult,
  AuditResultSteps,
  FinalResult,
} from "@auditcloud/shared/lib/types/AuditResult";
import {
  AuditResultChartData,
  Series,
} from "@auditcloud/shared/lib/types/AuditResultChart";
import { scoreToComplianceScore } from "@auditcloud/shared/lib/types/AuditScore";
import {
  AuditScoreAggregatedTotal,
  AuditScoreAggregatedTotalDimension,
} from "@auditcloud/shared/lib/types/AuditScore/types";
import {
  FieldPartNames,
  nullable,
  tt2str,
} from "@auditcloud/shared/lib/types/common";
import { FindingType } from "@auditcloud/shared/lib/types/ItemTypes";
import { formatAuditName } from "@auditcloud/shared/lib/utils/formatting/audits";
import {
  Dictionary,
  difference,
  flatten,
  groupBy,
  intersection,
  isArray,
  isPlainObject,
  isString,
  keys,
  last,
  mapValues,
  mean,
  min,
  omit,
  round,
  toPairs,
  uniq,
} from "lodash";
import moment from "moment";
import { RootGetters, RootState } from "../../types";
import { getterNs } from "@/utils/VuexHelper";
import { formatDates } from "@auditcloud/shared/lib/utils/formatting/dates";
import { scoreCalcRuleConfig } from "@auditcloud/shared/lib/auditResult/scoreCalcRules";

import { api as auditApi } from "../audit";
import {
  AuditReportSummary,
  AuditStatistics,
  FlatReportData,
} from "../audit/getters";
import { api as confApi } from "../configuration";
import { StatedGetter } from "../reporting/types";
import { checkIsAuditResultStep } from "@auditcloud/shared/lib/auditResult/calcChainFactoryFunctions";
import { FindingTypeConfigMap } from "@auditcloud/shared/lib/types/Configuration/defaults";
import { allAuditItemsMapped, getFindingsMapped } from "../audit/utils";
import { extractCategories } from "@auditcloud/shared/lib/types/AuditItemCategory";
import { typeIsNotEmpty } from "@auditcloud/shared/lib/utils/filter/typeIsNotEmpty";
import { AuditResultState, TableData } from "./types";
import {
  buildAuditScoreAggregatedTotal,
  simplifyFindingsStatisticForReport,
} from "@auditcloud/shared/lib/auditResult/utils";
import { AuditResultCalcConfig } from "@auditcloud/shared/lib/auditResult/types";
import {
  calcUrgency,
  UrgencyChartData,
} from "@auditcloud/shared/lib/types/UrgencyChart";
import { MaturityChartData } from "@auditcloud/shared/lib/types/MaturityChart";
import { getAuditResultChartData } from "./utils";

function additionalReportingData(auditMetadata: AuditMetadataClient): {
  [fieldName: string]: any;
} {
  const pattern = /^reporting[A-Z].*/;

  return Object.keys(auditMetadata)
    .filter(key => pattern.test(key))
    .reduce((prev, key) => {
      prev[key] = auditMetadata[key];
      return prev;
    }, {} as { [fieldName: string]: any });
}

function extractFinalResult(
  scoreResults: AuditResultSteps | null
): { result: AuditResult; percent: number } | null {
  if (scoreResults) {
    const final = last(scoreResults.steps);
    if (final && checkIsAuditResultStep(final)) {
      return {
        result: final.auditResult,
        percent: scoreResults.compliancePercent,
      };
    } else {
      const dummyResult: AuditResult = {
        text: "",
        description: "Ergebnis noch nicht berechnet",
        color: "grey",
        short: "#",
      };
      return { result: dummyResult, percent: 1 };
    }
  } else {
    return null;
  }
}
/** Iterates over all findings. Returns an object for each finding with a maturity.. */
function extractMaturityData(rootGetters: RootGetters) {
  const mappedFindings = rootGetters[
    getterNs(auditApi, auditApi.getters.getFindingsMap)
  ] as ReturnType<typeof getFindingsMapped>;
  const mappedAuditItems = allAuditItemsMapped(rootGetters);
  const mappedCategories = rootGetters[
    getterNs(auditApi, auditApi.getters.getAuditCategoryMapping)
  ] as ReturnType<typeof extractCategories>;

  const data = flatten(
    toPairs(mappedFindings)
      .map(([findingId, finding]) => {
        const maturity = finding.maturity;
        if (maturity == null) {
          return null;
        }
        const findingText = finding.text;
        const auditItemIds = uniq(
          finding.auditItemRefs.map(v => v.auditItemId)
        );
        const result = auditItemIds
          .map(auditItemId => {
            const auditItem = mappedAuditItems[auditItemId];
            if (!auditItem) {
              console.warn(
                `audit item ${auditItemId} not found. Discard finding ${findingId} for audit item  ${auditItemId}`,
                mappedAuditItems
              );
              return null;
            }
            const categoryPath = auditItem.question.categoryRef.map(
              categoryId => {
                const category = mappedCategories[categoryId];
                if (!category) {
                  console.warn(
                    `category ${categoryId} not found. Discard finding ${findingId} for audit item  ${auditItemId}`,
                    mappedCategories
                  );
                  return null;
                }
                return category;
              }
            );
            if (categoryPath.some(v => v === null)) {
              return null;
            }

            return {
              auditItemNo: auditItem.question.no,
              categoryPath,
              auditItemText: auditItem.question.text,
              findingText,
              maturity,
            };
          })
          .filter(typeIsNotEmpty);

        return result;
      })
      .filter(typeIsNotEmpty)
  );
  return data;
}
const getters = {
  getConstraintResults: (state: AuditResultState) => {
    return state.answerConstraintResults;
  },
  getMissedConstraints: (state: AuditResultState) => {
    return (
      state.answerConstraintResults.data?.filter(result => !result.valid) ?? [
        { valid: false, title: "Loading ...", subtitle: "" },
      ]
    );
  },
  getFlatAuditResultData: (state: AuditResultState) => {
    return state.flatData;
  },
  getScoreCalcRuleId: (
    state: AuditResultState,
    getters: Dictionary<unknown>,
    rootState: RootState,
    rootGetters: Dictionary<unknown>
  ) => {
    const auditMetadata = rootGetters[
      getterNs(auditApi, auditApi.getters.getAuditMetadata)
    ] as AuditMetadataClient | null;

    if (!auditMetadata) {
      throw new Error(`Audit metadata not found`);
    }

    const auditClass = rootGetters[
      getterNs(auditApi, auditApi.getters.getAuditClass)
    ] as nullable<AuditClassClient>;

    if (!auditClass) {
      throw new Error(`Audit class not found`);
    }

    const auditYear = String(auditMetadata.planning_year);

    const scoreCalcRuleId =
      auditClass.scoreCalcRulePerYear?.[auditYear] ?? "default";

    return scoreCalcRuleId;
  },
  getScoreCalcRules: (
    state: AuditResultState,
    getters: Dictionary<unknown>
  ) => {
    const scoreCalcRuleId = getters[n.getScoreCalcRuleId] as ScoreCalcRuleId;
    const scoreCalcRules = scoreCalcRuleConfig[scoreCalcRuleId];
    return scoreCalcRules;
  },
  getAuditScoreResult: (state: AuditResultState) => {
    console.log("Calc", state.scoreResult);
    return state.scoreResult;
  },
  getAuditScoreResultForDimensions: (state: AuditResultState) => {
    console.log("Calc for dimension", state.dimensionsResult);
    return (
      state.dimensionsResult.data?.map(d => ({
        scoreResult: d.scoreResult,
        dimension: d.dimension,
      })) ?? []
    );
  },
  getAuditScoreResultByCategory: (state: AuditResultState) => {
    return state.categoryScoreResult;
  },
  getFinalResult: (state: AuditResultState) => {
    const scoreResults = extractFinalResult(state.auditResultSteps.data);
    if (scoreResults) {
      return new StatedGetter(scoreResults);
    } else {
      return new StatedGetter<FinalResult>(null, state.auditResultSteps.error);
    }
  },
  getResultSteps: (state: AuditResultState) => {
    return state.auditResultSteps;
  },
  getResultStepsForDimensions: (state: AuditResultState) => {
    return (
      state.dimensionsResult.data?.map(entry => ({
        dimension: entry.dimension,
        resultSteps: entry.auditResultSteps,
      })) ?? []
    );
  },
  getResultCalculationNote(
    state: AuditResultState,
    getters: Dictionary<unknown>
  ) {
    const scoreCalcRules = getters[n.getScoreCalcRules] as ScoreCalcRules;
    return scoreCalcRules.calculationNote;
  },
  getFinalResultForDimensions: (state: AuditResultState) => {
    return (
      state.dimensionsResult.data?.map(entry => ({
        dimension: entry.dimension,
        finalResult: extractFinalResult(entry.auditResultSteps),
      })) ?? []
    );
  },
  getAuditResultChartData: (
    state: AuditResultState,
    getters: Dictionary<unknown>
  ): AuditResultChartData | null => {
    const scoreCalcRules = getters[n.getScoreCalcRules] as ScoreCalcRules;
    return getAuditResultChartData(state, scoreCalcRules.scoringConfig);
  },
  getMaturityChartData: (state, getters, rootState, rootGetters) => {
    const auditClass = rootGetters[
      getterNs(auditApi, auditApi.getters.getAuditClass)
    ] as nullable<AuditClassClient>;

    const maturityCalculationMethod = auditClass?.maturity;

    if (
      maturityCalculationMethod === null ||
      typeof maturityCalculationMethod === "undefined"
    ) {
      return null;
    }

    const maturityData = extractMaturityData(rootGetters);

    const maturityPerFinding = maturityData.map(val => {
      const categoryPath = val.categoryPath[0];
      const maturity = val.maturity;
      return {
        seriesId: categoryPath?.id ?? "00000000-0000-0000-0000-000000000000",
        seriesLabels: {
          short: categoryPath?.short ?? categoryPath?.name ?? "Unknown",
          long: categoryPath?.name ?? "Unknown",
        },
        maturity,
      };
    });

    const mappedById = groupBy(maturityPerFinding, v => v.seriesId);
    const labels = {
      short: {
        de: maturityCalculationMethod === "min" ? "Min" : "Durchschnitt",
        en: maturityCalculationMethod === "min" ? "Min" : "Average",
      },
      long: {
        de: maturityCalculationMethod === "min" ? "Minimum" : "Durchschnitt",
        en: maturityCalculationMethod === "min" ? "Minimum" : "Average",
      },
    };

    const seriesIds = Object.keys(mappedById);
    const series: Series = {
      ...labels,
      color: colorForIdx(0),
      values: seriesIds.map(seriesId => {
        const maturitiesPerCategorty = mappedById[seriesId].map(
          v => v.maturity
        );
        return round(mean(maturitiesPerCategorty), 2);
      }),
    };

    const categoryLabels = seriesIds.map(
      seriesId => mappedById[seriesId][0].seriesLabels
    );

    const calculateTotalScoreByAverage = () =>
      mean(
        seriesIds.map(seriesId => {
          const maturitiesPerCategorty = mappedById[seriesId].map(
            v => v.maturity
          );
          const cutback = (v: number) => min([3, v]);
          const cutbackMaturities = maturitiesPerCategorty.map(cutback);
          if (cutbackMaturities.some(v => v == null)) {
            throw new Error("Cutback value could not be calculated");
          }
          const meanMaturityInCategory = mean(cutbackMaturities);
          return round(meanMaturityInCategory, 2);
        })
      );

    const calculateTotalScoreByMin = () => {
      const minTotalScore = min(
        seriesIds.map(seriesId => {
          const maturitiesPerCategorty = mappedById[seriesId].map(
            v => v.maturity
          );
          const minMaturityInCategory = min(maturitiesPerCategorty);

          if (minMaturityInCategory == null) {
            throw new Error("Min maturity in category could not be calculated");
          }

          return minMaturityInCategory;
        })
      );

      if (minTotalScore == null) {
        throw new Error("Min maturity in category could not be calculated");
      }

      return minTotalScore;
    };

    const calculateTotalScore =
      maturityCalculationMethod === "avg"
        ? calculateTotalScoreByAverage
        : calculateTotalScoreByMin;

    const totalScore = calculateTotalScore();

    const result: MaturityChartData = {
      totalScore: round(totalScore, 2),
      maturityCalculationMethod,
      categoryLabels,
      limits: [
        {
          short: { de: "Ziel", en: "Target" },
          long: { de: "Ziel", en: "Target" },
          color: "green",
          value: 3,
        },
      ],
      seriesArray: [series],
    };
    return result;
  },
  getAuditFindingResult: (state: AuditResultState) => {
    const countPerFindingType = state.scoreResult.data;
    const dimensionsResult = state.dimensionsResult.data;
    const countPerFindingTypeCategory = state.categoryScoreResult.data;
    if (
      countPerFindingType &&
      countPerFindingTypeCategory &&
      dimensionsResult
    ) {
      const result: TableData = {
        header: [
          { de: "Feststellungen", en: "Findings" },
          ...dimensionsResult.map(dr => {
            return dr.dimension.name;
          }),
          TEXT_TOTAL,
        ],
        rows: [],
      };

      const sumup = (
        prev: number,
        item: FindingType & {
          count: number;
        }
      ): number => {
        return prev + item.count;
      };

      const findingTypeCount = countPerFindingType.countPerFindingType.length;
      for (let i = 0; i < findingTypeCount; i++) {
        const rowValues: number[] = dimensionsResult.map(
          val => val.scoreResult.countPerFindingType[i].count
        );
        rowValues.push(countPerFindingType.countPerFindingType[i].count);

        result.rows.push({
          title: countPerFindingType.countPerFindingType[i].text,
          values: rowValues,
        });
      }
      result.rows.push({
        title: {
          de: "Anzahl der Feststellungen",
          en: "Findings count",
        },
        values: [
          ...dimensionsResult.map(val =>
            val.scoreResult.countPerFindingType.reduce(sumup, 0)
          ),
          countPerFindingType.countPerFindingType.reduce(sumup, 0),
        ],
      });
      result.rows.push({
        title: {
          de: "Anzahl der Anforderungen",
          en: "Questions count",
        },
        values: [
          ...dimensionsResult.map(val => val.scoreResult.auditItemCount),
          countPerFindingType.auditItemCount,
        ],
      });

      return new StatedGetter(result);
    } else {
      return new StatedGetter<TableData>(undefined, {
        message: "Failed to load Finding Results",
        statusCode: 1,
      });
    }
  },
  getAuditMeasureResult: (
    state: AuditResultState,
    getters: Dictionary<unknown>,
    rootState: RootState,
    rootGetters: Dictionary<unknown>
  ) => {
    // TODO: Return Error object, saying no data can be displayed
    const result: TableData = {
      header: [
        "Maßnahmen Dummy Data",
        "ISO 9001",
        "ISO 14001",
        "ISO 45001",
        "Gesamt",
      ],
      rows: [
        { title: "Korrekturmaßnahmen", values: [0, 2, 3, 15] },
        {
          title: "Sofortmaßnahmen",
          values: [20, 10, 5, 35],
        },
      ],
    };
    return new StatedGetter(result);
  },
  getScoreCalcSteps: (
    state: AuditResultState,
    getters: Dictionary<unknown>
  ): null | AuditScoreAggregatedTotal => {
    const lang = contentLanguage();
    const scoreResult = state.scoreResult.data ?? null;
    const categoryScoreResult = state.categoryScoreResult.data ?? null;
    const flatAuditItemResult = state.flatData.data ?? null;

    const finalResult = (getters[n.getFinalResult] as gFinalResult).data;

    if (
      scoreResult &&
      categoryScoreResult &&
      flatAuditItemResult &&
      finalResult
    ) {
      return buildAuditScoreAggregatedTotal(
        flatAuditItemResult,
        scoreResult,
        categoryScoreResult,
        finalResult,
        lang
      );
    } else {
      return null;
    }
  },

  getAuditReportExportData: (
    state: AuditResultState,
    getters: Dictionary<unknown>,
    rootState: RootState,
    rootGetters: Dictionary<unknown>
  ): null | AuditReportExportData => {
    const lang = contentLanguage();
    const scoreResult = state.scoreResult.data ?? null;

    const auditMetadata = rootGetters[
      getterNs(auditApi, auditApi.getters.getAuditMetadata)
    ] as AuditMetadataClient | null;

    const auditClass = rootGetters[
      getterNs(auditApi, auditApi.getters.getAuditClass)
    ] as nullable<AuditClassClient>;

    const auditStatistics = rootGetters[
      getterNs(auditApi, auditApi.getters.getAuditStatistics)
    ] as AuditStatistics;
    const currentAuditReportSummary = rootGetters[
      getterNs(auditApi, auditApi.getters.getAuditReportSummary)
    ] as AuditReportSummary;

    const currentCalcScoreLegacy = rootGetters[
      getterNs(auditApi, auditApi.getters.getScoreCalcStepsDeprecated)
    ] as AuditScoreAggregatedTotal;

    const currentCalcScore = getters[
      n.getScoreCalcSteps
    ] as AuditScoreAggregatedTotal | null;

    const flatFindingsList = rootGetters[
      getterNs(auditApi, auditApi.getters.getFlatReportData)
    ] as FlatReportData;

    const customerLogo = rootGetters[
      getterNs(confApi, confApi.getters.customerLogo)
    ] as string | null;

    const findingTypesMappedByAuditClass = rootGetters[
      getterNs(confApi, confApi.getters.getFindingTypesMappedByAuditClass)
    ] as FindingTypeConfigMap;

    const scoreCalcRules = getters[
      n.getScoreCalcRules
    ] as AuditResultCalcConfig;

    const finalResult = (getters[n.getFinalResult] as gFinalResult).data;
    const auditResultSteps = state.auditResultSteps.data;

    const dimensionResults = state.dimensionsResult.data ?? null;

    if (
      auditClass &&
      auditMetadata &&
      currentCalcScore &&
      scoreResult &&
      currentAuditReportSummary &&
      auditStatistics &&
      finalResult &&
      auditResultSteps
    ) {
      const findingStatistic = scoreResult.countPerFindingType;
      const metadata = omit({ ...auditMetadata }, FieldPartNames.AUDIT_ITEMS);

      const complianceScore = scoreToComplianceScore(
        currentAuditReportSummary.score.inTotal
      );

      const findingTypesConfig = findingTypesMappedByAuditClass[auditClass.id];

      // un-comment this block to debug the score calculation
      //
      // if (!isEqual(currentCalcScore, currentCalcScoreLegacy)) {
      //   console.log(
      //     "[!] currentCalcScoreLegacy != currentCalcScor ",
      //     currentCalcScoreLegacy,
      //     currentCalcScore
      //   );
      //   diff("/", currentCalcScore, currentCalcScoreLegacy);
      // } else {
      //   console.log(
      //     "OK: currentCalcScoreLegacy == currentCalcScore",
      //     currentCalcScoreLegacy,
      //     currentCalcScore
      //   );
      // }

      const dimensionScores: AuditScoreAggregatedTotalDimension[] | null =
        dimensionResults
          ? dimensionResults.map((res): AuditScoreAggregatedTotalDimension => {
              const dimScore = buildAuditScoreAggregatedTotal(
                res.flatData,
                res.scoreResult,
                res.categoryScoreResult,
                finalResult,
                lang
              );

              return {
                ...dimScore,
                dimensionId: res.dimension.id,
                dimensionName: tt2str(res.dimension.name, lang),
              };
            })
          : null;

      // Score suppressed for customer-specific  QM case:
      const isScoreSuppressed =
        isFeatureEnabled("featureAllowToSuppressScoreInReport") &&
        metadata.audit_class === "qualified-supplier-visit";
      const score = isScoreSuppressed ? null : { ...currentCalcScore };

      return {
        auditMetadata,
        reportingMetadata: additionalReportingData(auditMetadata),
        customerLogo,
        auditName: formatAuditName(auditMetadata),
        exportDate: moment().format("l"),
        measureFinalizeDate: calcMeasureDueDate(
          auditMetadata,
          auditClass,
          "final"
        ).format("l"),
        measureReviewDate: calcMeasureDueDate(
          auditMetadata,
          auditClass,
          "review"
        ).format("l"),
        auditPlanningYear: auditMetadata.planning_year,
        auditingDates: formatDates(auditMetadata.auditing_date ?? [], null),
        auditStandards: auditMetadata.standardRefs?.map(v => v.name) ?? [],
        auditType: auditMetadata.type ?? null,
        auditBy: auditMetadata.audited_by ?? null,
        leadAuditor: currentAuditReportSummary.leadAuditor,
        responsibleForMeasures: currentAuditReportSummary.responsibleUser,
        coAuditors: currentAuditReportSummary.auditCoAuditors, // ["Markus Meier", "Erna Etna"],
        participants: currentAuditReportSummary.auditParticipants,
        summaryItemConfig: auditClass.summaryItemConfig,
        distributionlist: Array.isArray(auditMetadata.distributionlist)
          ? auditMetadata.distributionlist
          : [],
        objective: isString(auditMetadata.objective)
          ? auditMetadata.objective
          : null,
        score,
        dimensionScores,
        auditResultChartData: getters[
          n.getAuditResultChartData
        ] as AuditResultChartData,
        urgencyChartData: getters[n.getUrgencyChartData] as UrgencyChartData,
        maturityChartData: getters[n.getMaturityChartData] as MaturityChartData,
        compliancePercent: complianceScore,

        statistics: {
          ...auditStatistics.statistics,
          auditFindingsCount: simplifyFindingsStatisticForReport(
            findingStatistic,
            lang
          ),
        },
        result: {
          finalResult,
          auditResultSteps,
          dimensionResults,
        },
        findings: flatFindingsList,
        findingTypesConfig: findingTypesConfig,
        auditResults: scoreCalcRules.auditResults,
        auditResultOrder: scoreCalcRules.auditResultOrder,
        auditResultCalculationNote: scoreCalcRules.calculationNote,
      };
    } else {
      return null;
    }
  },

  getUrgencyChartData(
    state: AuditResultState,
    getters: Dictionary<unknown>,
    rootState: RootState,
    rootGetters: Dictionary<unknown>
  ): UrgencyChartData | null {
    const auditClass = rootGetters[
      getterNs(auditApi, auditApi.getters.getAuditClass)
    ] as nullable<AuditClassClient>;
    if (!auditClass?.useRiskBasedFindings) {
      return null;
    }

    const data = extractRiskData(rootGetters).filter(item => item.urgency > 0);

    data.sort((lhs, rhs) => {
      return rhs.urgency - lhs.urgency;
    });

    const worstTenFindings = data.slice(0, 10);

    const result: UrgencyChartData = {
      values: worstTenFindings.map(
        (flatFinding): UrgencyChartData["values"][number] => {
          return {
            impact: flatFinding.risk.impact ?? 0,
            likelihood: flatFinding.risk.likelihood ?? 0,
            urgency: flatFinding.urgency,
            label: {
              short: String(flatFinding.auditItemNo),
              long:
                flatFinding.findingText ||
                `Finding: ${flatFinding.auditItemNo}`,
            },
          };
        }
      ),
    };

    return result;
  },
};

function extractRiskData(rootGetters: RootGetters) {
  const mappedFindings = rootGetters[
    getterNs(auditApi, auditApi.getters.getFindingsMap)
  ] as ReturnType<typeof getFindingsMapped>;
  const mappedAuditItems = allAuditItemsMapped(rootGetters);
  const mappedCategories = rootGetters[
    getterNs(auditApi, auditApi.getters.getAuditCategoryMapping)
  ] as ReturnType<typeof extractCategories>;

  const data = flatten(
    toPairs(mappedFindings)
      .map(([findingId, finding]) => {
        const findingText = finding.text;
        const risk = finding.risk;
        if (!risk) {
          return null;
        }

        const auditItemIds = uniq(
          finding.auditItemRefs.map(v => v.auditItemId)
        );

        const result = auditItemIds
          .map(auditItemId => {
            const auditItem = mappedAuditItems[auditItemId];
            if (!auditItem) {
              console.warn(
                `audit item ${auditItemId} not found. Discard finding ${findingId} for audit item  ${auditItemId}`,
                mappedAuditItems
              );
              return null;
            }
            const categoryPath = auditItem.question.categoryRef.map(
              categoryId => {
                const category = mappedCategories[categoryId];
                if (!category) {
                  console.warn(
                    `category ${categoryId} not found. Discard finding ${findingId} for audit item  ${auditItemId}`,
                    mappedCategories
                  );
                  return null;
                }
                return category;
              }
            );
            if (categoryPath.some(v => v === null)) {
              return null;
            }

            return {
              auditItemNo: auditItem.question.no,
              categoryPath,
              auditItemText: auditItem.question.text,
              findingText,
              risk,
              urgency: calcUrgency(risk.impact, risk.likelihood) ?? 0,
            };
          })
          .filter(typeIsNotEmpty);

        return result;
      })
      .filter(typeIsNotEmpty)
  );
  return data;
}

export type FlatAuditResultData = ReturnType<
  typeof getters.getFlatAuditResultData
>;

export type ConstraintResultData = ReturnType<
  typeof getters.getConstraintResults
>;

export type MissedConstraintResultData = ReturnType<
  typeof getters.getMissedConstraints
>;
export type ScoreCalcRuleId = ReturnType<typeof getters.getScoreCalcRuleId>;
export type ScoreCalcRules = ReturnType<typeof getters.getScoreCalcRules>;
export type AuditScoreResult = ReturnType<typeof getters.getAuditScoreResult>;
export type AuditScoreResultForDimensions = ReturnType<
  typeof getters.getAuditScoreResultForDimensions
>;
export type AuditScoreResultByCategory = ReturnType<
  typeof getters.getAuditScoreResultByCategory
>;
export type gFinalResult = ReturnType<typeof getters.getFinalResult>;
export type gResultSteps = ReturnType<typeof getters.getResultSteps>;
export type ResultStepsForDimensions = ReturnType<
  typeof getters.getResultStepsForDimensions
>;
export type FinalResultForDimensions = ReturnType<
  typeof getters.getFinalResultForDimensions
>;
export type AuditFindingResult = ReturnType<
  typeof getters.getAuditFindingResult
>;
export type AuditMeasureResult = ReturnType<
  typeof getters.getAuditMeasureResult
>;
export type ScoreCalcSteps = ReturnType<typeof getters.getScoreCalcSteps>;

const n = mapValues(getters, (_, key) => key);

function diff(path: string, l: any, r: any) {
  if (typeof l === typeof r) {
    if (isArray(l) && isArray(r)) {
      if (l.length === r.length) {
        for (let i = 0; i < l.length; i++) {
          diff(`${path}[${i}]`, l[i], r[i]);
        }
      } else {
        console.warn(`${path}\n${l}\nvs:\n${r}\n\n`);
      }
    } else if (isPlainObject(l) && isPlainObject(r)) {
      const lk = keys(l);
      const rk = keys(l);
      const both = intersection(lk, rk);
      const onlyL = difference(lk, rk);
      const onlyR = difference(rk, lk);

      both.forEach(key => {
        diff(`${path}.${key}`, l[key], r[key]);
      });

      onlyL.forEach(key => {
        diff(`${path}.${key}`, l[key], r[key]);
      });
      onlyR.forEach(key => {
        diff(`${path}.${key}`, l[key], r[key]);
      });
    } else {
      if (l !== r) {
        console.warn(`${path}\n${l}\nvs:\n${r}\n\n`);
      }
    }
  } else {
    console.warn(`${path}\n${l}\nvs:\n${r}\n\n`);
  }
}

export { n as getterNames, getters };
