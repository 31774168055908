import { render, staticRenderFns } from "./MeasureListWidget.vue?vue&type=template&id=8bd5b63c&scoped=true&"
import script from "./MeasureListWidget.vue?vue&type=script&lang=ts&"
export * from "./MeasureListWidget.vue?vue&type=script&lang=ts&"
import style0 from "./MeasureListWidget.vue?vue&type=style&index=0&id=8bd5b63c&scoped=true&lang=scss&"
import style1 from "./MeasureListWidget.vue?vue&type=style&index=1&id=8bd5b63c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8bd5b63c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VBtn,VCard,VCheckbox,VChip,VDataTable,VDivider,VExpandTransition,VIcon,VList,VListItem,VListItemTitle,VMenu,VSimpleCheckbox,VSpacer,VTooltip})
