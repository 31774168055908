










































































import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { pick } from "lodash";
import AUserAutocompleteMixin from "@/components/mixins/AUserAutocompleteMixin.vue";
import AUserAutocompleteCreateDialog from "./AUserAutocompleteCreateDialog.vue";

import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";

@Component({
  components: { AUserAutocompleteCreateDialog },
})
export default class AUserAutocompleteSingle extends AUserAutocompleteMixin {
  xValue: IUserRef | null = null;
  @Prop({
    type: Object,
    default: null,
  })
  value!: IUserRef | null;

  @Watch("search", {
    immediate: true,
  })
  onSearch(newVal: string, oldVal: string) {
    newVal && this.queryUsers(newVal);
  }

  @Watch("value", {
    immediate: true,
    deep: true,
  })
  onValueChanged(newVal: IUserRef | null, oldVal: IUserRef | null) {
    if (newVal) {
      this.xItems = [{ ...newVal }];
    }

    this.xValue = newVal;
    this.$emit("change", newVal);
  }

  openAddUserDialog(evt: any) {
    this.userDialogVisible = true;
    (this.$refs.autocomplete as HTMLInputElement).blur();
  }
  userDialogVisible = false;

  onSelectionChanged(newVal?: IUserRef) {
    this.search = "";
    this.$emit("input", newVal ? newVal : null);
  }

  selectNewUser(user: IUserRef | null): void {
    this.search = "";
    const cleanupUserRef = (val: any) => {
      return pick(val, ["displayName", "id"]);
    };

    this.$emit("input", user ? cleanupUserRef(user) : null);
  }
}
