






























































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { api as auditApi } from "@/store/modules/audit";
import { AuditPermissions } from "@auditcloud/shared/lib/utils/aclHelpers";
import { AuditMetadataClient } from "@/types/Audit";
import { nullable } from "@auditcloud/shared/lib/types/common";
import { AuditClassClient } from "@auditcloud/shared/lib/types/AuditClass";
import { AuditSummary } from "@auditcloud/shared/lib/schemas";
import AAuditSummary from "@/components/controls/AAuditSummary.reg.vue";
import { createDefaultSummary } from "@auditcloud/shared/lib/utils/audit/summary";

@Component({
  components: { AAuditSummary },
})
export default class AAuditReportSummary extends Vue {
  @Getter(auditApi.getters.getAuditMetadata, { namespace: auditApi.namespace })
  auditMetadata!: AuditMetadataClient | null;

  @Getter(auditApi.getters.getAuditClass, {
    namespace: auditApi.namespace,
  })
  auditClass!: nullable<AuditClassClient>;

  @Getter(auditApi.getters.getAuditId, { namespace: auditApi.namespace })
  auditId!: string;

  @Getter(auditApi.getters.getAuditPermissions, {
    namespace: auditApi.namespace,
  })
  auditPermissions!: AuditPermissions;

  @Action(auditApi.actions.updateAuditMetadata, {
    namespace: auditApi.namespace,
  })
  setSummary!: (payload: {
    id: string;
    data: { summary: AuditMetadataClient["summary"] };
  }) => Promise<void>;

  get hasPermission() {
    return this.auditPermissions.writeMetadata;
  }

  editSummary: boolean = false;
  loadingEdit: boolean = false;
  updateError: boolean = false;
  lazySummary: AuditSummary | null = null;

  get summary(): AuditSummary {
    const summaryItemConfig = this.auditClass?.summaryItemConfig;

    return (
      this.lazySummary ??
      this.auditMetadata?.summary ??
      createDefaultSummary(summaryItemConfig)
    );
  }

  set summary(value: AuditSummary) {
    this.lazySummary = value;
  }

  edit() {
    if (!this.hasPermission) {
      return;
    }

    this.editSummary = true;
  }

  async save() {
    this.loadingEdit = true;
    this.updateError = false;

    try {
      await this.setSummary({
        id: this.auditId,
        data: { summary: this.summary },
      });
      this.editSummary = false;
      this.lazySummary = null;
    } catch (error) {
      this.updateError = true;
    } finally {
      this.loadingEdit = false;
    }
  }

  cancel() {
    this.lazySummary = null;
    this.editSummary = false;
    this.updateError = false;
  }
}
