import MeasureWorkflows from "@auditcloud/shared/lib/workflow/configs/Measure";
import { TranslateableText } from "@auditcloud/shared/lib/schemas";
import { StateConfig } from "@auditcloud/shared/lib/workflow/types/State";
import { Dictionary, isUndefined } from "lodash";
import { AuditWorkflowStepInfo } from "@/store/modules/audit/types";
import {
  SelfAssessmentStatusId,
  selfAssessmentStates,
} from "@auditcloud/shared/lib/utils/audit/auditStatus";

export const measureWorkflowResolver = (workflowId: string) => {
  const workflow = MeasureWorkflows[workflowId];
  if (workflow) {
    return workflow;
  } else {
    return null;
  }
};

export const measureWorkflowStatusResolver = (
  workflowId: string
): ((statusId: string) => null | TranslateableText) => {
  const workflow = MeasureWorkflows[workflowId];
  if (workflow) {
    return (statusId: string) => {
      const state = workflow.states[statusId];
      return state?.name || null;
    };
  } else {
    return () => null;
  }
};

export const measureWorkflowStatusGetter = (
  workflowId: string
): ((statusId: string) => null | StateConfig) => {
  const workflow = MeasureWorkflows[workflowId];
  if (workflow) {
    return (statusId: string) => {
      const state = workflow.states[statusId];
      return state ?? null;
    };
  } else {
    return () => null;
  }
};

export const auditWorkflowStatusDescriptionResolver = (
  workflow: AuditWorkflowStepInfo[]
) => {
  return (statusId: string) => {
    const statusItem = workflow.find(item => {
      return item.statusId === statusId;
    });

    if (isUndefined(statusItem)) {
      return statusId;
    } else {
      return statusItem.description;
    }
  };
};

export function resolveSelfAssessmentStatusText(
  statusId: SelfAssessmentStatusId
): string {
  const statusItem = selfAssessmentStates.find(v => v.statusId === statusId);
  if (isUndefined(statusItem)) {
    return statusId;
  } else {
    return statusItem.text;
  }
}
export function resolveSelfAssessmentStatusDescription(
  statusId: SelfAssessmentStatusId
): string {
  const statusItem = selfAssessmentStates.find(v => v.statusId === statusId);
  if (isUndefined(statusItem)) {
    return statusId;
  } else {
    return statusItem.description;
  }
}

export function resolveSelfAssessmentProgress(
  selfAssessmentStatus: SelfAssessmentStatusId,
  saAnsweredCount: number | undefined,
  saEnabledCount: number | undefined
) {
  return saEnabledCount &&
    selfAssessmentStatus !== SelfAssessmentStatusId.NONE &&
    selfAssessmentStatus !== SelfAssessmentStatusId.PENDING
    ? `${saAnsweredCount ?? 0} / ${saEnabledCount}`
    : "";
}
