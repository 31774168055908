

























































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { api as confApi } from "@/store/modules/configuration";
import { clone } from "lodash";
const confModule = namespace(confApi.namespace);

@Component({})
export default class DialogLayout extends Vue {
  // iOS/macOS: help Safari remember the  background scroll state (ACS-1820)
  windowScrollPosition: { top: number; left: number } | null = null;

  @Prop({
    type: String,
    default: "",
  })
  title!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  loading!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  editable!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  hasBadge!: boolean;

  @confModule.Getter(confApi.getters.getDialogButtonPosition)
  buttonPosition!: string;

  get isDisabled() {
    return this.disabled;
  }

  get isEditable() {
    return this.editable;
  }

  closeIcon() {
    this.$emit("closeIcon:dialog", false);
  }
  closeButton() {
    this.$emit("closeButton:dialog", false);
  }
  save() {
    this.$emit("save:dialog", false);
  }
  mounted() {
    this.windowScrollPosition = { top: window.scrollY, left: window.scrollX };
    document.documentElement.style.overflow = "hidden";
  }
  updated() {
    document.documentElement.style.overflow = "hidden";
  }

  beforeDestroy() {
    setTimeout(() => {
      console.log(
        "DialogLayout: restoring scroll position: ",
        clone(this.windowScrollPosition)
      );
      if (this.windowScrollPosition !== null) {
        window.scrollTo(this.windowScrollPosition);
      }
    });

    document.documentElement.style.overflow = "visible";
  }
}
