






































































































































import { Component, Prop, Vue } from "vue-property-decorator";

import AUserSnippet from "@/components/snippets/AUserSnippet.vue";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import { uniqBy } from "lodash";
import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";
import { typeIsNotEmpty } from "@auditcloud/shared/lib/utils/filter/typeIsNotEmpty";
import { TranslateableText } from "@auditcloud/shared/lib/schemas";

@Component({
  components: { AUserSnippet },
})
export default class AMeasureParticipatingUsers extends Vue {
  noUserPlaceholder: TranslateableText = {
    de: "Nicht zugewiesen",
    en: "Not assigned",
  };

  @Prop({
    type: Object,
    default: () => {},
  })
  measure!: MeasureProcessDocument;

  get uniqUserRefs(): IUserRef[] {
    const userRefs = this.measure.userRefs;
    const participants: Array<null | IUserRef> = [
      userRefs.assignedTo,
      userRefs.leadAuditor,
      ...userRefs.coAuditors,
      userRefs.responsible,
      ...userRefs.measureCoordinators,
      ...userRefs.measureParticipants,
    ];

    return uniqBy(participants.filter(typeIsNotEmpty), "id");
  }

  get uniqUserNameAbbreviations(): string[] {
    return this.uniqUserRefs.map(
      userRef => userRef.displayName.slice(0, 1).toUpperCase() ?? ""
    );
  }

  get participantAvatars() {
    // TODO: Replace color set by a non-random one
    const colors = [
      "#2e63b0",
      "#f8d4b1",
      "#43b694",
      "#515575",
      "#4cf952",
      "#17895b",
      "#ee1b82",
      "#637b24",
      "#33d61f",
      "#6ffa2c",
    ];
    // TODO: Read number of users from config
    const noOfUsers = 3;

    const firstParticipants = this.uniqUserNameAbbreviations.slice(
      0,
      noOfUsers
    );

    const result: Array<{
      short: string;
      color: string;
    }> = firstParticipants.map((short, idx) => {
      return {
        short,
        color: colors[idx],
      };
    });
    const remainingUserAmount =
      this.uniqUserNameAbbreviations.length - firstParticipants.length;

    if (remainingUserAmount > 0) {
      result.push({
        short: `+${remainingUserAmount}`,
        color: "transparent",
      });
    }

    return result;
  }
}
