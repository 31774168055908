var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-subtitle',[_vm._v(" Maßnahmen-Richtlinien bestimmen zu welchen Feststellungstypen welche Maßnahmentypen erwünscht oder erforderlich sind um ein Audit abzuschließen. ")]),(_vm.hasValidInput)?_c('v-card-text',[_c('v-alert',{attrs:{"border":"left","elevation":"2","colored-border":"","dense":"","type":"error","value":_vm.unknownFindingTypeIds.length > 0},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":""},on:{"click":_vm.cleanupFindings}},'v-btn',attrs,false),on),[_vm._v(" Feststellungstypen bereinigen ")])]}}],null,false,1130336461)},[_c('span',[_vm._v(" Löscht die unbekannten Festestellungstypen aus der Richtlinien-Konfiguration ")])])]},proxy:true}],null,false,1181132321)},[_vm._v(" Die Konfiguration ist nicht gültig es wurden unbekannte Feststellungstypen "),_c('code',[_vm._v(_vm._s(_vm.unknownFindingTypeIds.join(", ")))]),_vm._v(" referenziert. ")]),_c('v-alert',{attrs:{"border":"left","elevation":"2","colored-border":"","dense":"","type":"error","value":_vm.unknownMeasuresTypeIds.length > 0},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":""},on:{"click":_vm.cleanupMeasures}},'v-btn',attrs,false),on),[_vm._v(" Maßnahmentypen bereinigen ")])]}}],null,false,189979307)},[_c('span',[_vm._v(" Löscht die unbekannten Maßnahmentypen aus der Richtlinien-Konfiguration ")])])]},proxy:true}],null,false,1284404172)},[_vm._v(" Die Konfiguration ist nicht gültig es wurden unbekannte Maßnahmentypen "),_c('code',[_vm._v(_vm._s(_vm.unknownMeasuresTypeIds.join(", ")))]),_vm._v(" referenziert. ")]),(!_vm.hasErrors)?[_c('v-select',{attrs:{"dense":"","outlined":"","label":"Richtliniendurchsetzung","items":_vm.policyTypes,"disabled":_vm.xDisabled,"value":_vm.actualPolicyConfig.policyType},on:{"change":_vm.policyTypeChanged},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,1859502481)},[_c('span',[_vm._v(" Legt fest ob die Policies das Abgeschließen des Audit blockiert "),_c('br'),_vm._v(" oder nur ein Hinweis angezeigt werden soll. ")])])]},proxy:true}],null,false,3626633051)}),_vm._l((_vm.findingTypes),function(findingType){return _c('v-card',{key:findingType.id,staticClass:"mb-1",style:({
          opacity: _vm.xDisabled ? 0.3 : 1,
        })},[_c('v-card-title',[_c('AChip',{staticClass:"mr-4",attrs:{"color":findingType.color,"small":""}},[(_vm.findingChipLabelSource === 'VALUE')?_c('div',[_vm._v(" "+_vm._s(findingType.value)+" ")]):(_vm.findingChipLabelSource === 'SHORT')?_c('div',[_vm._v(" "+_vm._s(_vm.$ft(findingType.short))+" ")]):_vm._e()]),_c('span',{staticClass:"d-inline-block text-truncate"},[_vm._v(" "+_vm._s(_vm.$ft(findingType.text))+" ")])],1),_c('v-card-text',_vm._l((_vm.measureTypes),function(measureType){return _c('div',{key:measureType.id},[_c('v-select',{attrs:{"dense":"","outlined":"","label":"Richtlinie","disabled":_vm.xDisabled,"items":_vm.findingPolicyTypes,"value":_vm.policyValue(findingType.id, measureType.id)},on:{"change":function($event){return _vm.policyChanged({
                  findingTypeId: findingType.id,
                  measureTypeId: measureType.id,
                  policy: $event,
                })}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"mr-4",attrs:{"color":measureType.color}},[_vm._v(" "+_vm._s(measureType.icon)+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$ft(measureType.text))+" ")])]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(" Legt fest ob für \""+_vm._s(_vm.$ft(findingType.text))+"\" eine \""+_vm._s(_vm.$ft(measureType.text))+"\" erforderlich oder nur empfohlen ist. ")])])]},proxy:true}],null,true)})],1)}),0)],1)})]:_vm._e()],2):_c('v-card-text',[_c('v-alert',{attrs:{"type":"error","value":true}},[_vm._v(" Die Maßnahmen- oder Feststellungstypen für die Auditklasse sind nicht richtig konfiguriert. ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }