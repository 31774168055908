






import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class AAuditTransitionWarning extends Vue {
  @Prop({ type: String, default: "" })
  warningText!: string;
}
