


























































































































































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Action, Getter, State } from "vuex-class";
import { compare, Operation } from "fast-json-patch";
import { cloneDeep, isEqual, isString, pick } from "lodash";
import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";
import { typeIsArrayOf } from "@auditcloud/shared/lib/utils/type-guards";
import {
  TransitionConfig,
  TransitionContext,
} from "@auditcloud/shared/lib/workflow/types/Transition";
import { MeasureTransitionData } from "@auditcloud/shared/lib/schemas";
import { MeasureWorkflow } from "@auditcloud/shared/lib/workflow/modules/Measure";
import { api as usersApi } from "@/store/modules/users";
import { api as auditApi } from "@/store/modules/audit";
import {
  api as measureAPI,
  Actions as MeasureActions,
  Getters as MeasureGetters,
} from "@/store/modules/measure";
import {
  api as measuresAPI,
  Getters as MeasuresGetters,
  Actions as MeasuresActions,
} from "@/store/modules/measures";
import { api as auditClassesApi } from "@/store/modules/auditClasses";
import FormsControlMixin from "../mixins/FormsControlMixin.vue";
import AAuditTransitionWarning from "@/components/widgets/AAuditTransitionWarning.vue";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import { idable } from "@auditcloud/shared/lib/types/common";
import { MappedAuditClasses } from "@auditcloud/shared/lib/types/AuditClass";

interface BulkEditState {
  userRefs: {
    assignedTo: MeasureProcessDocument["userRefs"]["assignedTo"];
  };
  dueDate: MeasureProcessDocument["dueDate"];
}

@Component({ components: { AAuditTransitionWarning } })
export default class AAuditMeasureBulkEditDialog extends Mixins(
  FormsControlMixin
) {
  readonly TRANSITION_CONTEXT: TransitionContext = "bulk";
  transitionComment: string = "";

  @Prop({
    type: Boolean,
    default: false,
  })
  value!: boolean; // dialog's visibility

  @Prop({
    type: Array,
    validator: (val: any): boolean => typeIsArrayOf(val, isString),
    default: () => [],
  })
  selectedMeasureIds!: string[];

  @State("apiErrors", { namespace: measureAPI.namespace })
  apiErrors!: string[];

  @Action(measuresAPI.actions.updateMeasures, {
    namespace: measuresAPI.namespace,
  })
  updateMeasures!: MeasuresActions["updateMeasures"];

  @Getter(measuresAPI.getters.getMeasureAmount, {
    namespace: measuresAPI.namespace,
  })
  readonly measureAmount!: MeasuresGetters["getMeasureAmount"];

  @Getter(measuresAPI.getters.getTransitionsForSelectedMeasures, {
    namespace: measuresAPI.namespace,
  })
  readonly allTransitions!: MeasuresGetters["getTransitionsForSelectedMeasures"];

  @Getter(measuresAPI.getters.getAllowedBulkActions, {
    namespace: measuresAPI.namespace,
  })
  readonly allowedBulkAktions!: MeasuresGetters["getAllowedBulkActions"];

  @Getter(auditApi.getters.getMeasureWorkflow, {
    namespace: auditApi.namespace,
  })
  measureWorkflow!: MeasureWorkflow | null;

  @Getter(measuresAPI.getters.getMappedMeasures, {
    namespace: measuresAPI.namespace,
  })
  readonly mappedMeasures!: MeasuresGetters["getMappedMeasures"];

  @Getter(auditClassesApi.getters.mappedAuditClasses, {
    namespace: auditClassesApi.namespace,
  })
  mappedAuditClasses!: MappedAuditClasses;

  @Action(measureAPI.actions.runBulkTransition, {
    namespace: measureAPI.namespace,
  })
  runBulkTransition!: MeasureActions["runBulkTransition"];

  loading: boolean = false;
  runningTransition: null | idable<TransitionConfig> = null;
  errors: string[] = [];

  initialState: BulkEditState = {
    userRefs: { assignedTo: null },
    dueDate: null,
  };
  currentState: BulkEditState = cloneDeep(this.initialState);

  data: any = {
    tags: ["blödsinn", "frohsinn"],
  };

  get dueDate(): Date | null {
    return this.currentState.dueDate
      ? new Date(this.currentState.dueDate)
      : null;
  }

  get auditClassConfigs() {
    const auditClassIds = Object.values(
      pick(this.mappedMeasures, this.selectedMeasureIds)
    ).map(v => v.auditMetadata.audit_class);

    return pick(this.mappedAuditClasses, auditClassIds);
  }

  /** Measures that prevent a ref user as assignee */
  get measuresPreventingRefUserAssignees() {
    return this.selectedMeasureIds.filter(measureId => {
      const measure = this.mappedMeasures[measureId];
      if (!measure) {
        return false;
      }

      const auditClass =
        this.mappedAuditClasses[measure.auditMetadata.audit_class];

      if (!auditClass) {
        return false;
      }

      return !!auditClass.preventRefUserAssignees;
    });
  }

  set dueDate(val: Date | null) {
    this.currentState.dueDate = val ? val.toISOString() : null;
  }

  savePatch(patch: Operation[]) {
    console.log("SAVE", patch);
    if (patch.length > 0) {
      this.loading = true;
      this.updateMeasures({
        patch,
        measureIds: this.selectedMeasureIds,
      })
        .then(res => {
          console.log("save done", res);
        })
        .catch(err => {
          console.error("save failed", err);
          return this.handleApiError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  saveDueDate() {
    const patch = this.generatePatch(
      pick(this.initialState, "dueDate"),
      pick(this.currentState, "dueDate")
    );
    this.savePatch(patch);
    this.initialState.dueDate = this.currentState.dueDate;
  }

  saveAssignee() {
    const patch = this.generatePatch(
      pick(this.initialState, "userRefs"),
      pick(this.currentState, "userRefs")
    );
    this.savePatch(patch);
    this.initialState.userRefs.assignedTo =
      this.currentState.userRefs.assignedTo;
  }

  async executeTransition(transition: idable<TransitionConfig>) {
    if (
      confirm(
        this.$t(
          "components.dialogs.measure_bulk_edit_dialog.confirm_bulk_transition",
          { amount: this.selectedMeasureIds.length }
        ).toString()
      )
    ) {
      try {
        this.loading = true;
        this.runningTransition = transition;
        await this.runBulkTransition({
          measureIds: this.selectedMeasureIds,
          transitionId: transition.id,
          transitionData: {
            transitionComment: this.transitionComment,
          },
        });

        if (this.selectedMeasureIds.length === 0) {
          this.setVisibility(false);
        }
      } catch (err) {
        console.error(err);
        this.errors = [String(err)];
      } finally {
        // wait a moment for state changes to propagate into the frontend
        await delayMs(1000);
        this.loading = false;
        this.runningTransition = null;
      }
    }
  }

  generatePatch(a: Object, b: Object): Operation[] {
    let operations: Operation[] = [];

    if (this.selectedMeasureIds.length > 0) {
      operations = compare(a, b);
    }

    return operations;
  }

  handleApiError(reason: any) {
    if (this.apiErrors.length > 0) {
      this.errors = this.apiErrors;
    } else {
      this.errors = [String(reason)];
    }

    console.log("ERROR", this.apiErrors);

    this.loading = false;
  }

  get isDirty() {
    return !isEqual(this.initialState, this.currentState);
  }

  get assigneeChanged() {
    return (
      !isEqual(
        this.initialState.userRefs.assignedTo,
        this.currentState.userRefs.assignedTo
      ) && this.currentState.userRefs.assignedTo !== null
    );
  }

  get dueDateChanged() {
    return (
      !isEqual(this.initialState.dueDate, this.currentState.dueDate) &&
      this.currentState.dueDate !== null
    );
  }

  get transitions() {
    return this.allTransitions.filter(t => {
      return (
        t.showInContexts.includes(this.TRANSITION_CONTEXT) &&
        this.measureWorkflow?.transitions.get(t.id)?.from !== null
      );
    });
  }

  get assignedToQueryAction() {
    return this.measuresPreventingRefUserAssignees.length > 0
      ? {
          name: usersApi.actions.queryLoginUser,
          namespace: usersApi.namespace,
        }
      : {
          name: usersApi.actions.queryUser,
          namespace: usersApi.namespace,
        };
  }

  resetState() {
    this.currentState = cloneDeep(this.initialState);
  }

  setVisibility(visibility: boolean) {
    this.resetState();
    this.$emit("input", visibility);
  }
}

function delayMs(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
