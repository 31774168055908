


























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import AWorkflowLog from "@/components/controls/AWorkflowLog.vue";

import { getterNs } from "@/utils/VuexHelper";
import { typeIsArrayOf } from "@auditcloud/shared/lib/utils/type-guards";
import { WorkflowHistoryType, typeIsWorkflowHistory } from "@/components/types";

@Component({
  name: "AWorkflowHistorySidebar",
  components: { AWorkflowLog },
})
export default class AAuditLog extends Vue {
  @Prop({ type: String, required: true })
  attachmentModulName!: string;

  @Prop({ type: String, required: true })
  sidebarTitle!: string;

  get api() {
    return {
      namespace: this.attachmentModulName,
      getters: {
        getWorkflowHistory: "getWorkflowHistory",
      },
    };
  }

  get workflowHistory(): WorkflowHistoryType[] {
    const api = this.api;
    const workflow = this.$store.getters[
      getterNs(api, api.getters.getWorkflowHistory)
    ] as unknown;

    if (typeIsArrayOf(workflow, typeIsWorkflowHistory)) {
      return workflow;
    } else {
      if (process.env.NODE_ENV !== "production") {
        console.warn(
          `AAttachmentSidebar for ${api.namespace}, getter ${getterNs(
            api,
            api.getters.getWorkflowHistory
          )} must return WorkflowHistoryType`
        );
      }
      return [];
    }
  }
}
