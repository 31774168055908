import {
  typeIsArrayOf,
  typeIsObjectWithProperty,
} from "@auditcloud/shared/lib/utils/type-guards";
import { auditWorkflowStatusDescriptionResolver } from "@/utils/status_resolver";
import { isString } from "lodash";
import { AuditMetadataDoc } from "@auditcloud/shared/lib/schemas";
import { getDateFilterMonth } from "../Datefilter";
import { AuditWorkflowStepInfo } from "@/store/modules/audit/types";
import { UserRefToItemsJsNormalizer } from "@/types/User";

const resolveStandard = (value: unknown): null | string[] => {
  if (
    typeIsArrayOf(
      value,
      (val: unknown): val is { name: string } =>
        val != null &&
        typeIsObjectWithProperty(val, "name") &&
        isString(val.name)
    )
  ) {
    return value.map(val => val.name);
  }
  return null;
};

const resolveDate = (value: any): null | string => {
  if (isString(value)) {
    return value;
  }
  if (typeIsArrayOf(value, isString)) {
    const dates = [...value];
    dates.sort();
    return dates[0] ?? null;
  }
  return null;
};

const extractAuditStatus = (
  workflow: null | AuditMetadataDoc["workflow"]
): string => {
  if (
    workflow != null &&
    typeIsObjectWithProperty(workflow, "status") &&
    isString(workflow.status)
  ) {
    return workflow.status;
  }
  return "";
};

export interface AuditMetadataForItemJs {
  auditor: string;
  coauditors: string[];
  leadauditor: string;
  audited_by: string;
  createdAt: string;
  date_filter: string;
  auditing_date: string;
  planning_date: string;
  planning_year: string;
  process: AuditMetadataDoc["process"];
  scope: AuditMetadataDoc["scope"];
  standard: string[];
  statusId: string;
  status: string;
  type: string;
}

/** Flatten metadata fields so that they can be used with ItemJS */
export function resolveMetadataForItemJs(
  auditMetadata: Partial<AuditMetadataDoc>,
  workflowSteps: AuditWorkflowStepInfo[],
  fallbackText: string,
  normalizeUserRefToItemsJs: UserRefToItemsJsNormalizer
): AuditMetadataForItemJs {
  const leadauditor =
    normalizeUserRefToItemsJs(auditMetadata.leadauditor) ?? fallbackText;
  const coauditors = Array.isArray(auditMetadata.coauditors)
    ? auditMetadata.coauditors.map(
        coauditor => normalizeUserRefToItemsJs(coauditor) ?? fallbackText
      )
    : [];

  const createdAt =
    auditMetadata?.docVersion?.createdAt.toDate().toISOString() ??
    "Invalid Date";

  let standard = resolveStandard(auditMetadata.standardRefs) ??
    resolveStandard(auditMetadata.standards) ?? ["Invalid Ref"];
  if (standard?.length === 0) {
    standard = [fallbackText];
  }

  const statusDescriptionResolver =
    auditWorkflowStatusDescriptionResolver(workflowSteps);
  const statusId = extractAuditStatus(auditMetadata.workflow ?? null);

  const result: AuditMetadataForItemJs = {
    auditor: [leadauditor, ...coauditors].join(", "),
    coauditors,
    leadauditor,
    audited_by: auditMetadata.audited_by || "",
    createdAt,
    date_filter: getDateFilterMonth(
      resolveDate(auditMetadata.auditing_date) ?? ""
    ),
    auditing_date: resolveDate(auditMetadata.auditing_date) ?? "",
    planning_date: resolveDate(auditMetadata.planning_date) ?? "",
    planning_year: `${auditMetadata.planning_year || 2019}`,
    process: auditMetadata.process || "",
    scope: auditMetadata.scope || "",
    standard,
    statusId,
    status: statusDescriptionResolver(statusId),
    type: auditMetadata.type || "",
  };
  return result;
}
