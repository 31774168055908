var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-timeline-item',{class:{ 'opacity-6': _vm.isPreview },attrs:{"id":_vm.stepId,"fill-dot":"","small":"","icon":_vm.iconConfig.icon,"icon-color":"white","color":_vm.iconConfig.color}},[_c('v-card',{staticClass:"a-step",class:{
      'is-active': _vm.isActive,
      'elevation-10': _vm.isActive,
      'elevation-0': !_vm.isActive,
      'opacity-4': _vm.isRevoked || _vm.isResetStep || _vm.isCanceledStep,
    }},[_c('v-card-title',{staticClass:"header align-start pa-3",attrs:{"primary-title":""}},[_c('div',{staticClass:"titles"},[(_vm.isResetStep || _vm.isCanceledStep)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("components.controls.measure_process_steps.canceled"))+" ")]):_vm._e(),(_vm.isRevoked)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("components.controls.measure_process_steps.revoked"))+" ")]):_vm._e(),_c('span',{class:{
            'font-weight-bold': !_vm.isResetStep && !_vm.isRevoked,
          }},[_vm._v(" "+_vm._s(_vm.$ft(_vm.title))+" ")]),(_vm.hasSubTitle)?_c('div',{staticClass:"text-sm-left sub-title"},[_vm._t("sub-title")],2):_vm._e()]),_c('v-spacer'),_c('div',{staticClass:"text-right text-subtitle-2 text--secondary"},[_vm._t("version-info")],2),_c('div',[_vm._t("title-right")],2)],1),(_vm.hasSlotDefault)?_c('v-divider'):_vm._e(),(_vm.hasSlotDefault)?_c('v-card-text',{staticClass:"pa-0"},[_vm._t("default")],2):_vm._e(),_vm._t("step-footer")],2),_vm._t("step-transition")],2)}
var staticRenderFns = []

export { render, staticRenderFns }