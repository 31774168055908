import { createError } from "@/utils/Errors";
import { measuresEndpointUrl } from "@/utils/HttpApi";
import { Operation } from "fast-json-patch";
import { isString } from "lodash";
import {
  MeasureProcessDocument,
  MeasureProcessDocumentSource,
} from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import axios from "axios";

export function enrichMeasure(id: string, data: any): MeasureProcessDocument {
  return new MeasureProcessDocument(id, data as MeasureProcessDocumentSource);
}
/**
 *  Applies a specified patch to all specified measures
 */
export async function updateMeasuresInDb(
  patch: Operation[],
  measureIds: string[]
) {
  if (
    !(measureIds.every(v => isString(v)) && measureIds.every(v => v.length > 3))
  ) {
    throw createError("invalid measureIds", measureIds);
  }
  if (patch.length === 0) {
    return null;
  }

  try {
    const url = measuresEndpointUrl();

    const patches = {};
    measureIds.forEach(id => {
      patches[id] = patch;
    });

    const response = await axios({
      method: "patch",
      url,
      responseType: "json",
      data: { patches },
    });
    return response;
  } catch (err) {
    if (err.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error("Response other than 2xx");
      console.error(err.response.data);
      console.error(err.response.status);
      console.error(err.response.headers);
    } else if (err.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error("Request");
      console.error(err.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error", err.message);
    }
    console.log(err.config);
    console.error("measure/save-failed", err);
    throw new Error("measure/save-failed");
  }
}
