





























import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";

import { VCard } from "vuetify/lib";

@Component({
  components: { VCard },
})
export default class ACardWithHeader extends Vue {
  //missingDefault
  @Prop({
    type: String,
  })
  title!: string;
}
