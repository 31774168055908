



















































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { Finding, GeoLocation } from "@auditcloud/shared/lib/schemas";
import AGeoLocation from "@/components/snippets/AGeoLocation.vue";
import firebase from "firebase/compat/app";

@Component({
  components: {
    AGeoLocation,
  },
})
export default class AGeoLocationInput extends Vue {
  locationValues: GeoLocation[] = [];
  isCreate: boolean = false;
  dialogIsOpen: boolean = false;
  valid: boolean = true;
  index: number = -1;
  latitude: number = 0;
  longitude: number = 0;
  locationDescription: string = "";

  @Prop({
    default: () => [],
    type: Array,
  })
  readonly value!: GeoLocation[];

  @Prop({
    default: false,
    type: Boolean,
  })
  isReadOnly!: Boolean;

  @Prop({
    type: String,
  })
  readonly geoInputLabel!: String;

  get locationPosition() {
    return this.isValidPosition
      ? this.$t(
          "components.controls.audit_location.common.latitude"
        ).toString() +
          Math.round(this.latitude * 100000) / 100000 +
          "° , " +
          this.$t(
            "components.controls.audit_location.common.longitude"
          ).toString() +
          Math.round(this.longitude * 100000) / 100000 +
          "° "
      : "";
  }

  get locationURL() {
    return `https://maps.google.com/?q=${this.latitude},${this.longitude}`;
  }

  get isValidPosition() {
    return this.latitude !== 0 && this.longitude !== 0;
  }

  get isFormValid() {
    return this.valid && this.locationDescription.trim() !== "";
  }

  @Watch("value", { deep: true, immediate: true })
  onValueChanged(newVal: GeoLocation[]) {
    this.locationValues = newVal;
  }

  onSave() {
    const newLocation: GeoLocation = {
      description: this.locationDescription,
      position: new firebase.firestore.GeoPoint(this.latitude, this.longitude),
    };
    if (this.isCreate) {
      this.locationValues.push(newLocation);
      this.toggleDialog();
      this.$emit("input", this.locationValues);
    } else {
      if (this.index >= 0) {
        Vue.set(this.locationValues, this.index, newLocation);
      }
      this.index = -1;
      this.toggleDialog();
    }
  }

  onEditItem(geoIndex: number) {
    this.isCreate = false;
    this.locationDescription = this.locationValues[geoIndex].description;
    this.latitude = this.locationValues[geoIndex].position?.latitude ?? 0;
    this.longitude = this.locationValues[geoIndex].position?.longitude ?? 0;
    this.index = geoIndex;
    this.dialogIsOpen = !this.dialogIsOpen;
  }

  removeLocationItem(item2delete: number) {
    this.locationValues.splice(item2delete, 1);
    this.$emit("input", this.locationValues);
  }

  toggleDialog() {
    this.isCreate = true;
    this.dialogIsOpen = !this.dialogIsOpen;
    this.locationDescription = "";
    this.latitude = 0;
    this.longitude = 0;
  }

  geoFindMe() {
    if (!navigator.geolocation) {
      console.log("Geolocation is not supported by your current Application ");
      return;
    }
    navigator.geolocation.getCurrentPosition(this.success, this.error);
  }
  // PositionError will be deprecated in Typescript Version 4.1.x.
  // Starting then, it has to be GeolocationPositionError (Current Version is 3.9.7).v
  error(error) {
    console.log("Position Error", error);
  }
  // Position will be deprecated in Typescript Version 4.1.x.
  // Starting then, it has to be GeolocationPosition (Current Version is 3.9.7).
  success(position) {
    this.latitude = position.coords.latitude;
    this.longitude = position.coords.longitude;
  }
}
