import Ajv from "ajv";
import { ft } from "@/plugins/ContentTranslation";

import {
  StepConfigPropertyValidationRule,
  StepConfigPropertyMeta,
} from "@auditcloud/shared/lib/workflow/types/State";

export type ValidationRule = (value: unknown) => string | true;

/**
 * Fill in fallback label/placeholder for a step context property.
 */
export function providePropertyMeta(
  meta: StepConfigPropertyMeta | undefined,
  fallbackLabel: string
): StepConfigPropertyMeta {
  const label =
    meta?.label !== undefined ? `${ft(meta?.label)}` : fallbackLabel;
  const hint = meta?.hint !== undefined ? `${ft(meta?.hint)}` : "";
  const rules = meta?.rules ?? [];

  return { label, hint, rules };
}

/** Generate validation functions for any configured validation rules. */
export function compilePropertyValidationRules(
  ajv: Ajv.Ajv,
  rules?: StepConfigPropertyValidationRule[]
): ValidationRule[] {
  return (rules ?? []).map(rule => {
    const isValidInstance = ajv.compile(rule.schema as object);
    return (value: unknown) =>
      isValidInstance(value) ? true : `${ft(rule.validationMessage)}`;
  });
}
