import { GetterTree } from "vuex";
import { RootState } from "../../types";
import { CurrentTemplatesState } from "./types";
import {
  keyBy,
  Dictionary,
  isEqual,
  uniqWith,
  flatten,
  size,
  isString,
  isBoolean,
} from "lodash";
import {
  extractString,
  extractArray,
  extractMap,
  extractTranslateableText,
} from "@/types/TypeGuards";
import { TodoMap } from "@auditcloud/shared/lib/utils/type-guards";
import { typeIsArrayOf } from "@auditcloud/shared/lib/utils/type-guards";
import { typeIsNotEmpty } from "@auditcloud/shared/lib/utils/filter/typeIsNotEmpty";

import { AuditItem, AuditStandardRef } from "@auditcloud/shared/lib/schemas";
import { createError } from "@/utils/Errors";
import { typeIsWebLink } from "@auditcloud/shared/lib/types/Weblink";
import { typeIsAuditProof } from "@auditcloud/shared/lib/types/AuditProof";
import {
  AuditTemplate,
  forceUniqueQuestionNumbersDefaultValue,
} from "../template/types";
import {
  TemplatePermissions,
  calcTemplatePermissions,
  calcAllTemplateRoles,
} from "@auditcloud/shared/lib/utils/aclHelpers";
import {
  typeIsChapterRefType,
  validateAuditStandardRef,
} from "@auditcloud/shared/lib/types/Standards";
import { idable, FieldPartNames } from "@auditcloud/shared/lib/types/common";
import { extractCurrentUserRef, extractCurrentUserRoles } from "../user/utils";

const getTemplateIds = "getTemplateIds";
const getMappedTemplates = "getMappedTemplates";
const getFlatTemplateMatrix = "getFlatTemplateMatrix";
const getTemplateSelection = "getTemplateSelection";
const getStandardsListForAuditClass = "getStandardsListForAuditClass";

const n = {
  getTemplateIds,
  getMappedTemplates,
  getFlatTemplateMatrix,
  getTemplateSelection,
  getStandardsListForAuditClass,
};

function createAuditTemplate(
  id: string,
  data: TodoMap,
  getters: Dictionary<any>,
  rootGetters: Dictionary<any>
): AuditTemplate {
  const name = extractString(data, "name");
  const audit_class = extractString(data, "audit_class");
  const standardRefs = extractArray(data, "standardRefs").map(v => {
    if (validateAuditStandardRef(v)) {
      return v;
    } else {
      throw createError(`expect valid standardRef in template ${id}`, data, v);
    }
  });
  const audit_items = keyBy(
    Object.entries(extractMap(data, FieldPartNames.TEMPLATE_ITEMS)).map(
      ([auditItemId, auditItemVal], idx) => {
        const labels = auditItemVal?.labels ?? [];
        if (!typeIsArrayOf(labels, isString)) {
          throw createError(
            "invalid value/labels[]",
            `Expect array of labels in template ${id} audit_item.${auditItemId}`,
            labels,
            auditItemVal,
            data
          );
        }

        const question = extractMap(auditItemVal, "question");

        const no =
          typeof question.no === "number" || typeof question.no === "string"
            ? question.no
            : idx;
        if (
          typeof question.links !== "undefined" &&
          !typeIsArrayOf(question.links, typeIsWebLink)
        ) {
          throw createError(
            "invalid value/WebLink[]",
            `Expect array of Weblink in template ${id} audit_item.${auditItemId}`,
            question.links,
            question,
            data
          );
        }
        const links = question.links || [];

        if (
          typeof question.requiredProofs !== "undefined" &&
          !typeIsArrayOf(question.requiredProofs, typeIsAuditProof)
        ) {
          throw createError(
            "invalid value/AuditProof[]",
            `Expect array of AuditProof in template ${id} audit_item.${auditItemId}`,
            question.links,
            question,
            data
          );
        }
        const requiredProofs = question.requiredProofs || [];

        if (
          typeof question.chapters !== "undefined" &&
          !typeIsArrayOf(question.chapters, typeIsChapterRefType)
        ) {
          throw createError(
            "invalid value/ChapterRefType[]",
            `Expect array of ChapterRefType in template ${id} audit_item.${auditItemId}`,
            question.chapters,
            question,
            data
          );
        }

        const chapters = question.chapters || [];

        const ai: idable<AuditItem> = {
          id: auditItemId,
          labels,
          question: {
            description: extractTranslateableText(question, "description", ""),
            text: extractTranslateableText(question, "text"),
            // don't set a "" value for Self Assessment if not required
            // -> otherwise, questions would always have selfAssessmentText="" in db after saving
            selfAssessmentText: question.selfAssessmentText
              ? extractTranslateableText(question, "selfAssessmentText", "")
              : undefined,
            hint: extractTranslateableText(question, "hint", ""),
            type: extractString(question, "type"),
            no,
            links,
            categoryRef: extractArray(question, "categoryRef").map(v => {
              if (typeof v === "string") {
                return v;
              } else {
                throw createError(`invalid value for categoryRef`, question, v);
              }
            }),
            chapters,
            requiredProofs,
          },
        };

        const vda_question_scope = question.vda_question_scope;
        if (
          vda_question_scope === "Prozess" ||
          vda_question_scope === "Produkt"
        ) {
          ai.question.vda_question_scope = vda_question_scope;
        }
        return ai;
      }
    ),
    "id"
  );
  const categorySetId = extractString(data, "categorySetId");
  const reportSchema = extractString(data, "reportSchema");

  const templateLanguages = Array.isArray(data.templateLanguages)
    ? data.templateLanguages
    : ["de"];

  const uniqueQuestionNumbers = isBoolean(data.forceUniqueQuestionNumbers)
    ? data.forceUniqueQuestionNumbers
    : forceUniqueQuestionNumbersDefaultValue;

  /*
  const text = extractString(data, "text");
  const description = extractString(data, "description");
  const audititem_id = extractString(data, "audititem_id");
  const deviation_id = extractString(data, "deviation_id");
  const cause_analysis = extractString(data, "cause_analysis");
  const measuretypeId = extractString(data, "measuretype");
  const measuretype = measureTypesMapping[measuretypeId];
  const status = extractString(data, "status");
  const priority = extractBoolean(data, "priority");

  const createdDate = isoString2Date(extractString(data, "createdDate"));
  const dueDate = isoString2Date(extractString(data, "dueDate"));
  const assignedTo = extractUserRef(data, "assignedTo");
  const autor = extractUserRef(data, "autor"); */

  return new AuditTemplate(
    id,
    name,
    audit_class,
    categorySetId,
    reportSchema,
    templateLanguages,
    standardRefs,
    audit_items,
    uniqueQuestionNumbers
  );
  /*{
    id,
    auditRef,
    audit_id,
    text,
    description,
    audititem_id,
    deviation_id,
    cause_analysis,
    measuretype,
    status,
    priority,
    createdDate,
    dueDate,
    assignedTo,
    autor,
    attachments: {}
  }; */
}

const getters: GetterTree<CurrentTemplatesState, RootState> = {
  [n.getTemplateIds](state): string[] {
    return state.Documents.map(doc => doc.id);
  },
  [n.getMappedTemplates](
    state,
    getters,
    rootState,
    rootGetters
  ): Dictionary<AuditTemplate> {
    console.log("mappedTemplates", state.Documents);
    return keyBy(
      state.Documents.map(watched => {
        if (watched.data !== null) {
          try {
            return createAuditTemplate(
              watched.id,
              watched.data,
              getters,
              rootGetters
            );
          } catch (e) {
            console.error("create audit template failed", watched, e);
          }
        } else {
          return null;
        }
      }).filter(typeIsNotEmpty),
      "id"
    );
  },
  [n.getFlatTemplateMatrix](
    state,
    getters,
    rootState,
    rootGetters
  ): {
    data: { [k: string]: any };
    id: string;
    permissions: TemplatePermissions;
  }[] {
    const userId = extractCurrentUserRef(rootGetters)?.id ?? null;
    const roles = extractCurrentUserRoles(rootGetters);

    const ids = getters[n.getTemplateIds] as string[];
    const templates = getters[
      n.getMappedTemplates
    ] as Dictionary<AuditTemplate>;
    console.log("getFlatTemplateMatrix", templates);
    return ids.map(id => {
      const data = templates[id] || null;
      const templateRoles = calcAllTemplateRoles(data, userId, roles);
      return {
        id,
        data,
        permissions: calcTemplatePermissions(templateRoles),
      };
    });
  },
  [n.getTemplateSelection](state, getters, rootState, rootGetters) {
    const ids = getters[n.getTemplateIds] as string[];
    const templates = getters[
      n.getMappedTemplates
    ] as Dictionary<AuditTemplate>;

    const templateSelection = ids
      .map(id => {
        const data = templates[id] || null;
        if (data !== null) {
          return {
            id,
            name: data.name,
            audit_class: data.audit_class,
            auditItemCount: size(data.audit_items),
          };
        } else {
          return null;
        }
      })
      .filter(typeIsNotEmpty);

    return templateSelection;
  },
  [n.getStandardsListForAuditClass](
    state,
    getters,
    rootState,
    rootGetters
  ): (auditClassId: string) => AuditStandardRef[] {
    return (auditClassId: string) => {
      const templates = Object.values(
        getters[n.getMappedTemplates] as Dictionary<AuditTemplate>
      );
      const standards = uniqWith(
        flatten(
          templates
            .filter(template => {
              return template.audit_class === auditClassId;
            })
            .map(templates => templates.standardRefs)
        ),
        isEqual
      );
      return standards;
    };
  },
};

export { n as getterNames, getters };
