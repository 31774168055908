import _ from "lodash";
import { Module } from "vuex";
import { MeasureWorkflowsState } from "./types";
import { RootState } from "../../types";

import { getters, getterNames } from "./getters";
import { actions, actionNames } from "./actions";
import { mutations, mutationNames } from "./mutations";
import { MutationsType, GettersType, ActionsType } from "@/utils/VuexHelper";

const state = new MeasureWorkflowsState();

const namespaced: boolean = true;
const modul: Module<MeasureWorkflowsState, RootState> = {
  namespaced,
  getters,
  actions,
  mutations,
  state,
};

export const api = {
  namespace: "measureWorkflows",
  mutations: mutationNames,
  getters: getterNames,
  actions: actionNames,
};
export type Mutations = MutationsType<typeof mutations>;
export type Getters = GettersType<typeof getters>;
export type Actions = ActionsType<typeof actions>;

export default modul;
