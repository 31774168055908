


































































import { NotedProof } from "@auditcloud/shared/lib/schemas";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ANotedProofList extends Vue {
  activeProof: number = -1;
  proofName: string = "";
  proofNameValid: boolean = false;

  @Prop({
    required: true,
    type: Array,
  })
  readonly notedProofs!: NotedProof[];

  @Prop({
    type: Boolean,
    default: true,
  })
  readonly!: boolean;

  get dialog() {
    return (
      this.notedProofs.length >= 0 &&
      0 <= this.activeProof &&
      this.activeProof < this.notedProofs.length
    );
  }

  set dialog(value: boolean) {
    if (!value) {
      this.activeProof = -1;
    }
  }

  removeProof(proofIndex: number) {
    this.$emit("remove", proofIndex);
  }

  updateProof(proofName: string) {
    this.$emit("proof-rename", { index: this.activeProof, name: proofName });
    this.dialog = false;
  }

  get rules() {
    return [
      (v: any) =>
        (typeof v === "string" && v.length > 0) ||
        this.$t("components.dialogs.noted_proof_dialog.min_chars").toString(),
    ];
  }

  get dialogFullscreen() {
    return this.$vuetify.breakpoint.smAndDown;
  }
}
