


























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { renderTemplate } from "@auditcloud/shared/lib/utils/templateHelper";
import { Dictionary } from "lodash";

@Component({})
export default class APrefilledTextField extends Vue {
  /** the model to use for prefilling, and to get the overridden value from */
  @Prop({
    type: Object,
    default: () => ({}),
  })
  context!: Dictionary<unknown>;

  /** context field to read the (overridden) value from, e.g. "name" */
  @Prop({
    type: String,
    required: true,
  })
  valueKey!: string;

  /** context field to read the template from, e.g. "nameTemplate" */
  @Prop({
    type: String,
    default: null,
  })
  templateKey!: string | null;

  /** directly set the template to use for generating a prefill value */
  @Prop({
    type: String,
    default: null,
  })
  template!: string | null;

  // track state to re-render if there an empty (=null) value on blur
  lastInput: string | null = null;
  refreshMarker: boolean = false;

  get value(): string {
    const value = this.context[this.valueKey];
    return value ? `${value}` : "";
  }

  get activeTemplate(): string | null {
    let { context, template, templateKey } = this;

    if (templateKey) {
      const contextTemplate = context[templateKey];
      if (
        typeof contextTemplate == "string" &&
        contextTemplate.trim().length > 0
      ) {
        template = contextTemplate;
      }
    }
    return template;
  }

  get prefilledValue() {
    let { context, activeTemplate, templateKey } = this;

    if (typeof activeTemplate === "string") {
      return renderTemplate(activeTemplate, context);
    } else {
      console.log(`APrefilledTextField: No template (key=${templateKey})`);
      return "";
    }
  }

  get labelSuffix() {
    if (this.prefilledValue && !this.value) {
      return this.$t(
        "components.controls.prefilled_text_field.prefilled_label_suffix"
      );
    }
    return "";
  }

  onInput(value?: string | null) {
    const text = value?.trim() || null; // || to transform "" to null

    // emit empty string if not in template Mode otherwise return null if no data is set
    this.lastInput = this.activeTemplate === null && text === null ? "" : text;
    console.log("ON_INPUT", text, this.activeTemplate);
    this.$emit("input", this.lastInput);
  }

  onBlur() {
    if (this.lastInput === null) {
      // force full re-render, clearing the input
      this.refreshMarker = !this.refreshMarker;
    }
  }
}
