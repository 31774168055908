

















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class AAnniversaryEventDialog extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  })
  isOpen!: boolean;

  @Prop({
    type: Number,
    required: true,
  })
  age!: number;

  get year(): number {
    return new Date().getFullYear();
  }

  handleDialogStatus(event: boolean) {
    if (!event) {
      this.$emit("close");
    }
  }
}
