import store from "@/store";
import { api as userApi } from "@/store/modules/user";
import { api as confApi } from "@/store/modules/configuration";
import { getterNs } from "@/utils/VuexHelper";
import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";
import { SystemRoles } from "@auditcloud/shared/lib/constants/roles";
import {
  extractCurrentUserRef,
  extractCurrentUserRoles,
} from "@/store/modules/user/utils";

export default class Auth {
  static getUserRoles() {
    return extractCurrentUserRoles(store.getters);
  }
  static isAdmin() {
    return Auth.getUserRoles().includes(SystemRoles.ADMINISTRATOR);
  }
  static isSignedIn() {
    if (store.getters[getterNs(userApi, userApi.getters.isAuthenticated)]) {
      return true;
    } else {
      return false;
    }
  }

  // TODO: Brauch man das tatsächlich ?
  static stateLoaded() {
    if (store.getters[getterNs(confApi, confApi.getters.app)]) {
      return true;
    } else {
      return false;
    }
  }
  static getUserRef(): null | IUserRef {
    return extractCurrentUserRef(store.getters);
  }

  // TODO: Warum hier nicht async?
  static userDisplayName() {
    const userRef = Auth.getUserRef();

    return userRef !== null ? userRef.displayName : null;
  }

  static getUserAvatar() {
    if (store.getters[getterNs(userApi, userApi.getters.getCurrentUser)]) {
      return (
        store.getters[getterNs(userApi, userApi.getters.getCurrentUser)]
          .avatar || ""
      );
    }
  }

  static getUserId() {
    const userRef = Auth.getUserRef();

    return userRef !== null ? userRef.id : null;
  }

  static getUserEmail() {
    if (store.getters[getterNs(userApi, userApi.getters.getCurrentUser)]) {
      return (
        store.getters[getterNs(userApi, userApi.getters.getCurrentUser)]
          .email || ""
      );
    }
  }
}
