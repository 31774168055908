import firebase from "firebase/compat/app";
import { ActionTree } from "vuex";
import { RootState } from "../../types";
import {
  CurrentTemplateState,
  AuditTemplate,
  TemplateItemStoragePayload,
  TemplateItemRemovalPayload,
} from "./types";
import { mutationNames as mn } from "./mutations";
import { getterNames as gn } from "./getters";

import { AuditItem } from "@auditcloud/shared/lib/schemas";
import {
  createTemplateItemPath,
  createTemplateItemsPath,
} from "@auditcloud/shared/lib/utils/firestorePathHelper";
import { v4 as uuidv4 } from "uuid";
import { rdu } from "../user";
import { createError } from "@/utils/Errors";
import { logXStored } from "@/utils/VuexHelper";
import { Dictionary, isString, omit, pick, isEqual } from "lodash";
import { CollectionNames } from "@auditcloud/shared/lib/types/common";
import { createUpdateDataForItemsNumberModification } from "./utils";
import { Result, ok, err } from "neverthrow";
import { ct } from "@/plugins/ContentTranslation";

const setCurrentTemplateId = "setCurrentTemplateId";
const setCurrentItemTemplateId = "setCurrentItemTemplateId";
const storeTemplateItem = "storeTemplateItem";
const updateTemplateMetadata = "updateTemplateMetadata";
const deleteTemplateItem = "deleteTemplateItem";

const n = {
  setCurrentTemplateId,
  setCurrentItemTemplateId,
  storeTemplateItem,
  updateTemplateMetadata,
  deleteTemplateItem,
};

const actions: ActionTree<CurrentTemplateState, RootState> = {
  async [n.setCurrentTemplateId](context, payload) {
    context.commit(mn.SET_TEMPLATE_ID, payload);
  },
  async [n.setCurrentItemTemplateId](context, payload) {
    context.commit(mn.SET_TEMPLATE_ITEM_ID, payload);
  },
  async [n.storeTemplateItem](
    { state, rootGetters, getters },
    payload: TemplateItemStoragePayload
  ): Promise<Result<string, string>> {
    console.log(n.storeTemplateItem, state, payload);
    const templateId = state.currentTemplateId;
    if (isString(templateId)) {
      const templateItemId =
        state.currentTemplateItemId === null
          ? uuidv4()
          : state.currentTemplateItemId;

      const db = firebase.firestore();
      const numbersOnly = getters[gn.getTemplateItemsNoTypeIsNumber] as boolean;
      const forceUniqueNumbers = getters[gn.getForceUniqueNumbers] as boolean;

      try {
        await db.runTransaction(async transaction => {
          let updateData: firebase.firestore.UpdateData = {};
          const { doc, ref, child } = createTemplateItemsPath(templateId);
          const docRef = db.collection(ref).doc(doc);

          const document = await transaction.get(docRef);
          const data = document.data();
          if (!data) {
            throw createError(
              "Could not fetch data for template",
              state.currentTemplateId
            );
          }
          const templateItems = data[child] as Dictionary<AuditItem>;
          const templateItemsEntries = Object.entries(templateItems);

          // check if specified number is unique
          if (!numbersOnly && forceUniqueNumbers) {
            if (
              templateItemsEntries.some(
                ([id, templateItem]) =>
                  id !== templateItemId &&
                  templateItem.question.no === payload.item.question.no
              )
            ) {
              console.error(
                "item number is not unique",
                payload.item.question.no,
                "template",
                templateId
              );
              throw createError(`${ct(
                "components.dialogs.audit_item_dialog_json.error_questionno_exists"
              ).toString()}:
                ${payload.item.question.no ?? ""}`);
            }
          }

          // no id is set -> new item is created
          if (state.currentTemplateItemId === null) {
            if (numbersOnly) {
              payload.item.question.no = templateItemsEntries.length + 1;
            } else {
              if (payload.item.question.no === "") {
                payload.item.question.no = uuidv4().split("-")[0];
              }
            }
          }

          if (payload.itemNumberModification && numbersOnly) {
            updateData = createUpdateDataForItemsNumberModification(
              payload.itemNumberModification,
              templateItems
            );
          }

          const {
            child: templateChild,
            ref: templateRef,
            doc: templateDoc,
          } = createTemplateItemPath(templateId, templateItemId);
          const templateDocRef = db.collection(templateRef).doc(templateDoc);

          updateData[templateChild] = pick(payload.item, "question", "labels");
          transaction.update(templateDocRef, {
            ...updateData,
            ...rdu(rootGetters),
          });
          logXStored(`TemplateItem: ${templateId}, ${templateItemId}`);
        });
      } catch (e) {
        console.log("modifyTemplateItemsNumbers", "transaction failed", e);
        return err(e.message);
      }
      return ok(templateItemId);
    } else {
      throw createError(
        "expect current templateId",
        state.currentTemplateId,
        state.currentTemplateItemId
      );
    }
  },

  async [n.updateTemplateMetadata](
    { state, rootGetters, getters },
    payload: AuditTemplate
  ) {
    const templateId = state.currentTemplateId;
    if (isString(templateId)) {
      const docRef = firebase
        .firestore()
        .collection(CollectionNames.TEMPLATES)
        .doc(templateId);
      const updateData = {
        ...omit(payload, ["audit_items", "auditItemCount"]),

        ...rdu(rootGetters),
      };

      console.log(n.updateTemplateMetadata, updateData, docRef);

      await docRef.update(updateData);
      logXStored(`Template update: ${templateId}`)();
    } else {
      throw createError("expect valid templateId", templateId);
    }
  },
  async [n.deleteTemplateItem](
    { getters, state, rootGetters },
    { templateItemId, itemNumberModification }: TemplateItemRemovalPayload
  ) {
    const mappedTemplateItem = getters[
      gn.getMappedTemplateItems
    ] as Dictionary<AuditItem>;
    const templateItem = mappedTemplateItem[templateItemId];
    const templateId = state.currentTemplateId;
    if (templateItem && templateId !== null) {
      const db = firebase.firestore();
      const numbersOnly = getters[gn.getTemplateItemsNoTypeIsNumber] as boolean;

      try {
        await db.runTransaction(async transaction => {
          let updateData: firebase.firestore.UpdateData = {};
          if (itemNumberModification && numbersOnly) {
            const { doc, ref, child } = createTemplateItemsPath(templateId);
            const docRef = db.collection(ref).doc(doc);

            const document = await transaction.get(docRef);
            const data = document.data();
            if (!data) {
              throw createError(
                "Could not fetch data for template",
                state.currentTemplateId
              );
            }
            const templateItems = data[child] as Dictionary<AuditItem>;
            updateData = await createUpdateDataForItemsNumberModification(
              itemNumberModification,
              templateItems
            );
          }

          const {
            child: templateChild,
            ref: templateRef,
            doc: templateDoc,
          } = createTemplateItemPath(templateId, templateItemId);
          const templateDocRef = db.collection(templateRef).doc(templateDoc);

          updateData[templateChild] = firebase.firestore.FieldValue.delete();
          transaction.update(templateDocRef, {
            ...updateData,
            ...rdu(rootGetters),
          });
          logXStored(`TemplateItem delete: ${templateId}, ${templateItemId}`);
        });
      } catch (e) {
        console.log("modifyTemplateItemsNumbers", "transaction failed", e);
      }
    } else {
      throw createError(
        "expect existing templateItemId",
        templateItemId,
        templateItem,
        mappedTemplateItem
      );
    }
  },
};

export { n as actionNames, actions };
