import { ActionContext } from "vuex";
import { RootState } from "../../types";
import { State } from "./types";
import { mutationNames as mn } from "./mutations";
import { mapValues } from "lodash";
import {
  QueryData,
  OrderByData,
  createCollectionObserver,
  CollectionObserverConfig,
} from "@/utils/firestore";
import {
  VERSIONED_DOCUMENT_FIELDNAME,
  REV_KEY,
} from "@auditcloud/shared/lib/types/VersionedDocument";
import { createError } from "@/utils/Errors";
import { enrichMeasure, updateMeasuresInDb } from "./utils";
import { CollectionNames } from "@auditcloud/shared/lib/types/common";
import { Operation } from "fast-json-patch";
import * as measuresService from "@/utils/services/measuresService";
import { isGenericMeasureReader } from "@auditcloud/shared/lib/utils/aclHelpers";
import { fieldPath } from "@auditcloud/shared/lib/utils/firestorePathHelper";
import { extractCurrentUserRef, extractCurrentUserRoles } from "../user/utils";
import { AxiosError } from "axios";
import { createAccessFilter } from "@/utils/firestore/queryFilters";
import { MeasureProcessMetaStatus } from "@auditcloud/shared/lib/schemas";

import { disableEmptyFilters } from "../audit/utils";

type Context = ActionContext<State, RootState>;

interface MeasureQueryPayload {
  auditId?: string;
  whereUserIs: "creator" | "assignee" | "member" | "generic-measure-reader";
  showCompletedMeasures: boolean;
  status?: string[];
  limit?: number;
}

function buildObserverConfig(
  queryPayLoad: MeasureQueryPayload,
  currentUserId: string,
  currentUserRoles: string[]
): CollectionObserverConfig {
  const whereUserIs = queryPayLoad.whereUserIs;
  console.assert(
    whereUserIs !== "generic-measure-reader" ||
      (whereUserIs === "generic-measure-reader" &&
        isGenericMeasureReader(currentUserRoles)),
    "expect current user to have a generic-measure-reader role admin if filter is 'generic-measure-reader'"
  );
  const filter = [
    new QueryData(
      fieldPath(REV_KEY, VERSIONED_DOCUMENT_FIELDNAME.DELETED),
      "==",
      false
    ),
  ];

  switch (whereUserIs) {
    case "creator":
      filter.push(
        createAccessFilter({
          userId: currentUserId,
          roles: currentUserRoles,
          accessType: "Read",
        })
      );
      filter.push(new QueryData("users.creator", "==", currentUserId));
      break;
    case "assignee":
      filter.push(
        createAccessFilter({
          userId: currentUserId,
          roles: currentUserRoles,
          accessType: "Read",
        })
      );
      filter.push(new QueryData("users.assignedTo", "==", currentUserId));
      break;
    case "member":
      filter.push(
        createAccessFilter({
          userId: currentUserId,
          accessType: "Read",
        })
      );
      break;
    default:
      // make sure that measures can be accessed based on the system roles:
      filter.push(
        createAccessFilter({
          userId: currentUserId,
          roles: currentUserRoles,
          accessType: "Read",
        })
      );
      break;
  }

  if (queryPayLoad.auditId) {
    filter.push(new QueryData("auditRef.id", "==", queryPayLoad.auditId));
  }

  if (!queryPayLoad.showCompletedMeasures) {
    const completedMetaState: MeasureProcessMetaStatus = "complete";
    filter.push(new QueryData("workflow.metaState", "!=", completedMetaState));
  }

  if (queryPayLoad.status) {
    filter.push(
      new QueryData(
        "workflow.statusId",
        "in",
        Array.isArray(queryPayLoad.status)
          ? queryPayLoad.status
          : [queryPayLoad.status]
      )
    );
  }

  const limit =
    typeof queryPayLoad.limit === "number" ? queryPayLoad.limit : undefined;
  const orderByMetaState = new OrderByData("workflow.metaState");
  const orderByDueDate = new OrderByData("dueDate");
  return {
    filter,
    limit,
    orderBy: [orderByMetaState, orderByDueDate],
  };
}

const actions = {
  async loadMeasures(context: Context, payload: MeasureQueryPayload) {
    console.assert(!!payload, "loadMeasures require a payload");

    const currentUserId =
      extractCurrentUserRef(context.rootGetters)?.id ?? null;

    const currentUserRoles = extractCurrentUserRoles(context.rootGetters);

    if (currentUserId) {
      const config = buildObserverConfig(
        payload,
        currentUserId,
        currentUserRoles
      );

      const unlistener = createCollectionObserver(
        CollectionNames.MEASURE_PROCESSES,
        config,
        updateData => {
          console.log("MEASURE_UPDATE ... ", updateData.modifiedDocs);
          const modifiedDocs = updateData.modifiedDocs.map(watched => {
            if (watched.data !== null) {
              try {
                return {
                  ...watched,
                  data: enrichMeasure(watched.id, watched.data),
                };
              } catch (e) {
                console.error("enrichMeasure failed", watched, e);
                return {
                  ...watched,
                  data: null,
                };
              }
            } else {
              return watched;
            }
          });

          context.commit(mn.SET_MEASURES, {
            removeDocs: updateData.removeDocs,
            modifiedDocs,
          });

          if (payload.auditId) {
            disableEmptyFilters(context);
          }
        },
        updateData => {
          context.commit(mn.SET_MEASURES_METADATA, updateData);
        },
        () => {}
      );
      context.commit(mn.SET_MEASURES_UNLISTENER, unlistener);
      return unlistener;
    }
    return null;
  },
  async updateMeasures(
    context: Context,
    payload: {
      patch: Operation[];
      measureIds: string[];
    }
  ) {
    return updateMeasuresInDb(payload.patch, payload.measureIds);
  },
  async clearMeasures({ commit }: Context, payload) {
    commit(mn.CLEAR_MEASURES, payload);
  },
  async deleteMeasure({ rootGetters }: Context, { measureId }: any) {
    if (!(typeof measureId === "string" && measureId.length > 3)) {
      throw createError("invalid measureId", measureId);
    }

    try {
      const response = await measuresService.deleteMeasure(measureId);
      return response;
    } catch (error) {
      const err = error as AxiosError;
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Response other than 2xx");
        console.error(err.response.data);
        console.error(err.response.status);
        console.error(err.response.headers);
      } else if (err.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error("Request");
        console.error(err.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error", err.message);
      }
      console.log(err.config);
      console.error("measure/delete-failed", err);
      throw new Error("measure/delete-failed");
    }
  },
  async deleteMeasures(context: Context, measureIds: string[]) {
    try {
      const response = await measuresService.deleteMeasures(measureIds);
      return response;
    } catch (error) {
      const err = error as AxiosError;
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Response other than 2xx");
        console.error(err.response.data);
        console.error(err.response.status);
        console.error(err.response.headers);
      } else if (err.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error("Request");
        console.error(err.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error", err.message);
      }
      console.log(err.config);
      console.error("measures/delete-failed", err);
      throw new Error("measures/delete-failed");
    }
  },
};

const n = mapValues(actions, (_, key) => key);
export { n as actionNames, actions };
