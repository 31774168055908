import {
  CollectionNames,
  DocumentNames,
  FieldPartNames,
} from "@auditcloud/shared/lib/types/common";
import { EXPECTED_DB_VERSION } from "@auditcloud/shared/lib/constants";
import { createDocumentObserver } from "./firestore";
import { get } from "lodash";

export function watchDbVersion(
  systemDocChanged: (
    dbVersions: { expected: number; actual: number },
    doc: any
  ) => void,
  onError: (errMsg: string) => void
) {
  return createDocumentObserver(
    CollectionNames.CONFIGURATION,
    DocumentNames.CONFIG_SYSTEM,
    updateData => {
      const dbVersion = get(
        updateData.data ?? {},
        FieldPartNames.SYSTEM_DB_VERSION,
        null
      );
      systemDocChanged(
        { expected: EXPECTED_DB_VERSION, actual: dbVersion },
        updateData.data
      );
    },
    error => {
      console.error("Observer configuration/system failed", error);
      onError(String(error));
    }
  );
}
