






































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter } from "vuex-class";
import { pick } from "lodash";

import { WebLink } from "@auditcloud/shared/lib/schemas";
import { VForm } from "../types";

@Component({})
export default class AWebLinkInput extends Vue {
  linkValues: WebLink[] = [];
  dialogIsOpen: boolean = false;
  valid: boolean = true;
  isCreate: boolean = true;
  index: number = -1;
  newLabel: string = "";
  newURL: string = "";
  @Prop({
    default: () => [],
    type: Array,
  })
  readonly value!: WebLink[];

  get filterAttrs() {
    this.$refs.linkForm;
    return pick(this.$attrs, [
      "error",
      "error-messages",
      "error-count",
      "hint",
      "label",
    ]);
  }

  get isFormValid() {
    return this.valid && this.newURL.trim() !== "";
  }

  get isReadonly() {
    return this.$attrs.readonly ?? false;
  }

  get labelRules() {
    return [
      v =>
        !this.isCreate ||
        this.linkValues.findIndex(
          link => link.label === v.trim() || link.url === v.trim()
        ) === -1 ||
        this.$t(
          "components.controls.audit_web_link.rules.label_exists_already"
        ).toString(),
    ];
  }

  get urlRules() {
    return [
      v => !!v || this.$t("common.rules.url_required").toString(),
      v =>
        /^https?:\/\//.test(v) ||
        this.$t(
          "components.controls.audit_web_link.rules.http_required"
        ).toString(),
      v =>
        /^https?:\/\/[a-z0-9\-.]+\.[a-z0-9-]{2,}(\/\S*)?/.test(v) ||
        this.$t(
          "components.controls.audit_web_link.rules.domain_invalid"
        ).toString(),
      v => {
        const link = this.linkValues.find(link => link.url === v.trim());
        if (link && this.isCreate) {
          return this.$t(
            "components.controls.audit_web_link.rules.url_exists_already",
            {
              url: link.label ?? link.url,
            }
          ).toString();
        } else {
          return true;
        }
      },
    ];
  }

  @Watch("value", {
    deep: true,
    immediate: true,
  })
  onValueChanged(newVal: WebLink[]) {
    this.linkValues = newVal;
  }

  onEditItem(item2Edit: WebLink) {
    if (!this.isReadonly) {
      this.isCreate = false;
      this.newLabel = item2Edit.label ?? "";
      this.newURL = item2Edit.url;
      this.index = this.linkValues.findIndex(x => x.url === this.newURL);
      this.dialogIsOpen = !this.dialogIsOpen;
    }
  }

  onSave() {
    const newLink: WebLink = {
      url: this.newURL,
    };
    const label = this.newLabel.trim();
    if (label !== "") {
      newLink.label = label;
    }
    if (this.isCreate) {
      this.linkValues.push(newLink);
      this.toggleDialog();
    } else {
      if (this.index >= 0) {
        this.linkValues[this.index] = newLink;
      }
      this.index = -1;
      this.toggleDialog();
    }
  }

  removeLinkItem(item2delete: WebLink) {
    this.linkValues = this.linkValues.filter(links => {
      return !(links.url === item2delete.url);
    });
    this.$emit("input", this.linkValues);
  }

  toggleDialog() {
    this.newLabel = "";
    this.newURL = "";
    this.isCreate = true;
    this.dialogIsOpen = !this.dialogIsOpen;
  }
}
