














import { AuditStatusId } from "@auditcloud/shared/lib/constants";
import {
  AuditMetadataDoc,
  AuditStandardRef,
} from "@auditcloud/shared/lib/schemas";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AAuditedStandardsSelect extends Vue {
  @Prop({
    type: Object,
    default: () => ({}),
  })
  auditMeta!: Partial<AuditMetadataDoc>;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: () => ({}),
  })
  readonly!: boolean;

  get xValue() {
    return this.auditMeta.standardRefs ?? [];
  }

  set xValue(val: AuditStandardRef[]) {
    this.$emit("input", val);
  }

  get xReadonly() {
    if (this.readonly) {
      return true;
    }
    const auditStatusId = this.auditMeta.workflow?.status ?? "";
    const auditPreparationState = this.auditMeta.auditPreparation?.step ?? "";

    const editable =
      auditStatusId === AuditStatusId.Planning ||
      (auditStatusId === AuditStatusId.Preparation &&
        auditPreparationState === "preselection");

    return !editable;
  }
}
