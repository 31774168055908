import { Finding } from "@auditcloud/shared/lib/schemas";
import { emptyFinding } from "./utils";
import { Attachment } from "@/components/types";

export class CurrentFindingState {
  findingId: string | null = null;
  finding: Finding = emptyFinding();
  attachments: Attachment[] = [];
  evaluationForSelfAssessmentWithId: string | null = null;
}
export type State = CurrentFindingState;
