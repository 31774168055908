import { GetterTree } from "vuex";
import { RootState } from "../../types";
import {
  CurrentTemplateState,
  AuditTemplate,
  forceUniqueQuestionNumbersDefaultValue,
} from "./types";
import { api as templatesApi } from "../templates";
import { api as confApi } from "@/store/modules/configuration";
import { api as auditClassesApi } from "@/store/modules/auditClasses";

import { Dictionary, isNumber, toPairs, keys } from "lodash";

import { getterNs } from "@/utils/VuexHelper";
import { idable, nullable } from "@auditcloud/shared/lib/types/common";
import {
  AuditItemTypeMap,
  AuditItemType,
} from "@auditcloud/shared/lib/types/ItemTypes";
import {
  AuditItemTypeConfigMap,
  AuditItemTypeConfig,
} from "@auditcloud/shared/lib/types/Configuration/defaults";
import { AuditItem, CategoryLevel } from "@auditcloud/shared/lib/schemas";
import {
  TemplatePermissions,
  calcAllTemplateRoles,
  calcTemplatePermissions,
} from "@auditcloud/shared/lib/utils/aclHelpers";
import {
  AuditItemCategory,
  AuditItemCategoryMap,
  AuditItemCategorySetMap,
  extractCategories,
  CategoryLevelOptions,
} from "@auditcloud/shared/lib/types/AuditItemCategory";
import {
  CreateAuditItemListManipulators,
  AuditItemIdsFilteredWithSearchResults,
  AuditItemListManipulators,
} from "@auditcloud/shared/lib/utils/filter/AuditItemListManipulator";
import {
  buildAuditItemListFilterSettings,
  fulltextSearchAuditItems,
  selectAvailableFiltersForList,
} from "@auditcloud/shared/lib/utils/filter/FilterUtils";
import { makeIdable } from "@auditcloud/shared/lib/utils/transform/makeIdable";
import {
  QuestionSearchResult,
  QuestionSearchResultMap,
} from "@auditcloud/shared/lib/types/Audit/types";
import { ct, ft } from "@/plugins/ContentTranslation";
import { ListsConfigMap } from "@auditcloud/shared/lib/types/Configuration/types";
import { extractCurrentUserRef, extractCurrentUserRoles } from "../user/utils";
import {
  Aggregation,
  Filter,
  FilterTree,
  Aggregator,
  FrontendFilterTree,
} from "@auditcloud/shared/lib/utils/filter/types";
import {
  buildAggregationsFromActiveFiltersAndIndex,
  buildFilterIndexForItems,
  calculateFilteredItemIds,
} from "@auditcloud/shared/lib/utils/filter/utils";
import {
  buildAuditItemAggegators,
  AuditItemIdBasedLookupFunctions,
} from "@auditcloud/shared/lib/utils/filter/AuditItemAggegators";
import { FindingTypeConfig } from "@auditcloud/shared/lib/types/Configuration/Configuration";
import { AuditItemWithId } from "@auditcloud/shared/lib/utils/audit/types";
import { readFrontendLanguage } from "@/plugins/i18n";
import {
  AuditClassClient,
  MappedAuditClasses,
} from "@auditcloud/shared/lib/types/AuditClass";

const getTemplateExists = "getTemplateExists";
const getHasTemplate = "getHasTemplate";
const getTemplate = "getTemplate";
const getCurrentTemplateId = "getCurrentTemplateId";
const getAuditClassId = "getAuditClassId";
const getAuditClass = "getAuditClass";
const getCategoryLevel = "getCategoryLevel";
const getCategorySetId = "getCategorySetId";
const getAuditItemTypeConfig = "getAuditItemTypeConfig";
const getMappedAuditItemTypes = "getMappedAuditItemTypes";
const getAuditItemTypes = "getAuditItemTypes";
const getMappedTemplateItems = "getMappedTemplateItems";
const getTemplateItems = "getTemplateItems";
const getCurrentTemplateItem = "getCurrentTemplateItem";
const getAuditItemsNumbers = "getAuditItemsNumbers";
const getStandardIds = "getStandardIds";
const getForceUniqueNumbers = "getForceUniqueNumbers";
const getTemplateCategoryMapping = "getTemplateCategoryMapping";
const getTemplatePermissions = "getTemplatePermissions";
const getTemplateCategory = "getTemplateCategory";
const getFullTextSearch = "getFullTextSearch";
const getTemplateItemsNoTypeIsNumber = "getTemplateItemsNoTypeIsNumber";
const getUsedLabels = "getUsedLabels";

const getTemplateItemIdsFilteredWithSearchResult =
  "getTemplateItemIdsFilteredWithSearchResult";
const getTemplateItemIdsFiltered = "getTemplateItemIdsFiltered";
const getSearchResultsByAuditItemIds = "getSearchResultsByAuditItemIds";
const getManipulatorStatus = "getManipulatorStatus";
const getAuditItemAggregators = "getAuditItemAggregators";
const getFilterTreeForFrontendFiltering = "getFilterTreeForFrontendFiltering";
const getTemplateItemListFilterAggregations =
  "getTemplateItemListFilterAggregations";
const getVdaScopeSelectable = "getVdaScopeSelectable";

const n = {
  getTemplateExists,
  getHasTemplate,
  getTemplate,
  getCurrentTemplateId,
  getAuditClassId,
  getAuditClass,
  getCategoryLevel,
  getCategorySetId,
  getAuditItemTypeConfig,
  getMappedAuditItemTypes,
  getAuditItemTypes,
  getMappedTemplateItems,
  getTemplateItems,
  getCurrentTemplateItem,
  getAuditItemsNumbers,
  getStandardIds,
  getForceUniqueNumbers,
  getTemplateCategoryMapping,
  getTemplatePermissions,
  getTemplateCategory,
  getFullTextSearch,
  getTemplateItemsNoTypeIsNumber,
  getUsedLabels,
  getTemplateItemIdsFilteredWithSearchResult,
  getTemplateItemIdsFiltered,
  getSearchResultsByAuditItemIds,
  getManipulatorStatus,

  getAuditItemAggregators,
  getFilterTreeForFrontendFiltering,
  getTemplateItemListFilterAggregations,
  getVdaScopeSelectable,
};

const getters: GetterTree<CurrentTemplateState, RootState> = {
  [n.getTemplateExists](state, getters, rootState, rootGetters): boolean {
    const templateIds = rootGetters[
      getterNs(templatesApi, templatesApi.getters.getTemplateIds)
    ] as string[];
    if (state.currentTemplateId !== null) {
      return templateIds.includes(state.currentTemplateId);
    } else {
      return false;
    }
  },
  [n.getHasTemplate](state, getters, rootState, rootGetters): boolean {
    const mappedTemplates = rootGetters[
      getterNs(templatesApi, templatesApi.getters.getMappedTemplates)
    ] as Dictionary<AuditTemplate>;
    return (
      state.currentTemplateId !== null &&
      typeof mappedTemplates[state.currentTemplateId] !== "undefined"
    );
  },
  [n.getTemplate](
    state,
    getters,
    rootState,
    rootGetters
  ): nullable<AuditTemplate> {
    const mappedTemplates = rootGetters[
      getterNs(templatesApi, templatesApi.getters.getMappedTemplates)
    ] as Dictionary<AuditTemplate>;
    if (state.currentTemplateId !== null) {
      return mappedTemplates[state.currentTemplateId] || null;
    } else {
      return null;
    }
  },
  [n.getCurrentTemplateId](state) {
    return state.currentTemplateId;
  },
  [n.getAuditClassId](state, getters): nullable<string> {
    const doc = getters[n.getTemplate] as nullable<AuditTemplate>;
    if (doc && doc.audit_class) {
      return doc.audit_class;
    } else {
      return null;
    }
  },
  [n.getAuditClass](
    state,
    getters,
    rootState,
    rootGetters
  ): nullable<AuditClassClient> {
    const auditClassId = getters[n.getAuditClassId] as nullable<string>;
    if (auditClassId !== null) {
      const mappedAuditClasses = rootGetters[
        getterNs(auditClassesApi, auditClassesApi.getters.mappedAuditClasses)
      ] as MappedAuditClasses;
      const auditClass = mappedAuditClasses[auditClassId];
      if (auditClass) {
        return {
          ...auditClass,
          id: auditClassId,
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  },
  [n.getCategoryLevel](state): CategoryLevel {
    return CategoryLevelOptions.Root;
  },
  [n.getCategorySetId](state, getters): nullable<string> {
    const doc = getters[n.getTemplate] as nullable<AuditTemplate>;
    if (doc && doc.categorySetId) {
      return doc.categorySetId;
    } else {
      return null;
    }
  },
  [n.getAuditItemTypeConfig](
    state,
    getters,
    rootState,
    rootGetters
  ): nullable<AuditItemTypeConfig> {
    const auditClass = getters[n.getAuditClassId] as nullable<string>;
    if (auditClass) {
      const auditItemTypeConfigMap = rootGetters[
        getterNs(confApi, confApi.getters.getAuditItemTypesMappedByAuditClass)
      ] as AuditItemTypeConfigMap;

      const auditItemConfig = auditItemTypeConfigMap[auditClass];
      return auditItemConfig || null;
    } else {
      return null;
    }
  },
  [n.getUsedLabels](state, getters): string[] {
    const mappedTemplateItem = getters[
      n.getMappedTemplateItems
    ] as Dictionary<AuditItem>;

    let labels: string[] = [];

    Object.values(mappedTemplateItem).forEach(auditItem => {
      labels.push(...(auditItem.labels ?? []));
    });

    return Array.from(new Set(labels));
  },
  [n.getMappedAuditItemTypes](
    state,
    getters,
    rootState,
    rootGetters
  ): AuditItemTypeMap {
    const auditItemTypeConfig = getters[
      n.getAuditItemTypeConfig
    ] as nullable<AuditItemTypeConfig>;
    if (auditItemTypeConfig) {
      return auditItemTypeConfig.types;
    } else {
      return {};
    }
  },
  [n.getAuditItemTypes](
    state,
    getters,
    rootState,
    rootGetters
  ): AuditItemType[] {
    const auditItemTypeConfig = getters[
      n.getAuditItemTypeConfig
    ] as nullable<AuditItemTypeConfig>;
    if (auditItemTypeConfig) {
      return Object.values(auditItemTypeConfig.types);
    } else {
      return [];
    }
  },
  [n.getMappedTemplateItems](state, getters): Dictionary<AuditItem> {
    const template = getters[n.getTemplate] as nullable<AuditTemplate>;
    if (template === null) {
      return {};
    } else {
      return template.audit_items;
    }
  },
  [n.getTemplateItems](state, getters): Array<idable<AuditItem>> {
    const mappedTemplateItems = getters[
      n.getMappedTemplateItems
    ] as Dictionary<AuditItem>;
    const auditItems = toPairs(mappedTemplateItems).map(makeIdable);
    return auditItems;
  },
  [n.getCurrentTemplateItem](state, getters): null | idable<AuditItem> {
    if (state.currentTemplateItemId === null) {
      return null;
    } else {
      const mappedTemplateItem = getters[
        n.getMappedTemplateItems
      ] as Dictionary<AuditItem>;
      const templateItem = mappedTemplateItem[state.currentTemplateItemId];
      if (templateItem) {
        return { ...templateItem, id: state.currentTemplateItemId };
      } else {
        console.error(
          `Unmapped template item id ${state.currentTemplateItemId} in template ${state.currentTemplateId}`
        );
        return null;
      }
    }
  },
  [n.getAuditItemsNumbers](state, getters): (string | number)[] {
    const doc = getters[n.getTemplate] as nullable<AuditTemplate>;
    if (doc === null) {
      return [];
    } else {
      return Object.entries(doc.audit_items).map(
        ([key, value]) => value.question.no
      );
    }
  },
  [n.getStandardIds](state, getters): string[] {
    const currentTemplate = getters[n.getTemplate] as nullable<AuditTemplate>;
    const standardsIds = currentTemplate?.standardRefs?.map(s => s.id) ?? [];

    return standardsIds;
  },
  [n.getForceUniqueNumbers](state, getters): boolean {
    const currentTemplate = getters[n.getTemplate] as nullable<AuditTemplate>;
    return (
      currentTemplate?.forceUniqueQuestionNumbers ??
      forceUniqueQuestionNumbersDefaultValue
    );
  },
  [n.getTemplateCategoryMapping](
    state,
    getters,
    rootState,
    rootGetters
  ): AuditItemCategoryMap {
    const mappedCategories: AuditItemCategoryMap = extractCategories(
      getters[n.getTemplateCategory] as AuditItemCategory[]
    );
    return mappedCategories;
  },
  [n.getTemplatePermissions](
    state,
    getters,
    rootState,
    rootGetters
  ): TemplatePermissions {
    const userId = extractCurrentUserRef(rootGetters)?.id ?? null;
    const roles = extractCurrentUserRoles(rootGetters);
    const currentTemplate = getters[n.getTemplate] as nullable<AuditTemplate>;

    const templateRoles = calcAllTemplateRoles(currentTemplate, userId, roles);
    return calcTemplatePermissions(templateRoles);
  },
  [n.getTemplateItemsNoTypeIsNumber](state, getters): boolean {
    const template = getters[n.getTemplate] as nullable<AuditTemplate>;
    if (template === null) {
      return false;
    }
    const isNoNumber = Object.entries(template?.audit_items ?? {}).some(
      ([key, value]) => {
        return !isNumber(value.question.no);
      }
    );
    return !isNoNumber;
  },
  [n.getTemplateCategoryMapping](
    state,
    getters,
    rootState,
    rootGetters
  ): AuditItemCategoryMap {
    const mappedCategories = extractCategories(
      getters[n.getTemplateCategory] as AuditItemCategory[]
    );
    return mappedCategories;
  },
  [n.getTemplateCategory](
    state,
    getters,
    rootState,
    rootGetters
  ): AuditItemCategory[] {
    const categorySetMap = rootGetters[
      getterNs(confApi, confApi.getters.getCategoryMapping)
    ] as AuditItemCategorySetMap;
    const template = getters[n.getTemplate] as nullable<AuditTemplate>;
    if (template && categorySetMap[template.categorySetId]) {
      return categorySetMap[template.categorySetId].categories;
    } else {
      return [];
    }
  },
  [n.getFullTextSearch](state): string {
    return state.fulltextSearch;
  },
  [n.getTemplateItemIdsFilteredWithSearchResult](
    state,
    getters
  ): AuditItemIdsFilteredWithSearchResults {
    const mappedTemplateItems = getters[
      n.getMappedTemplateItems
    ] as Dictionary<AuditItem>;
    const templateItemIds = keys(mappedTemplateItems);

    const templateItemLookup = (
      templateItemId: AuditItemWithId["id"]
    ): null | AuditItem => {
      return mappedTemplateItems[templateItemId] ?? null;
    };

    return fulltextSearchAuditItems(
      state.fulltextSearch,
      templateItemIds,
      new Map(),
      templateItemLookup,
      ct
    );
  },
  [n.getTemplateItemIdsFiltered](state, getters) {
    const auditItemIdsFilteredwithSearchResults = getters[
      n.getTemplateItemIdsFilteredWithSearchResult
    ] as AuditItemIdsFilteredWithSearchResults;

    const frontendFilterTree = getters[
      n.getFilterTreeForFrontendFiltering
    ] as FrontendFilterTree;

    const activeFilter = state.activeFilter;

    const fulltextSearchFilteredAuditItemIds =
      auditItemIdsFilteredwithSearchResults.auditItemIds;

    return calculateFilteredItemIds(
      frontendFilterTree,
      activeFilter,
      fulltextSearchFilteredAuditItemIds
    );
  },
  [n.getSearchResultsByAuditItemIds](state, getters): QuestionSearchResultMap {
    console.log(n.getSearchResultsByAuditItemIds);
    const auditItemIdsFilteredwithSearchResults = getters[
      n.getTemplateItemIdsFilteredWithSearchResult
    ] as AuditItemIdsFilteredWithSearchResults;
    return auditItemIdsFilteredwithSearchResults.searchResultsByAuditItemIds;
  },

  [n.getManipulatorStatus](state, getters) {
    return {
      filters: state.activeFilter.length,
    };
  },
  [n.getAuditItemAggregators](
    state,
    getters
  ): ReturnType<typeof buildAuditItemAggegators> {
    const findingTypeConfig: FindingTypeConfig = {
      directInput: [],
      finalizeExecutionFindingTypeId: "",
      inputOrder: [],
      reportOrder: [],
      types: {},
    };

    const auditItemTypeMap = getters[
      n.getMappedAuditItemTypes
    ] as AuditItemTypeMap;

    const categoryLevel = getters[n.getCategoryLevel] as CategoryLevel;
    const mappedCategories = getters[
      n.getTemplateCategoryMapping
    ] as AuditItemCategoryMap;

    const emptyDimensionsExtratror: Aggregator<idable<AuditItem>>["aggregate"] =
      auditItem => {
        return [];
      };

    const auditItemIdBasedLookupFunctions: AuditItemIdBasedLookupFunctions = {
      conflictStateLookup: auditItemId => {
        return null;
      },
      findingsHaveAttachmentsLookup: auditItemId => {
        return false;
      },
      selfAssessmentsHaveAttachmentsLookup: auditItemId => {
        return false;
      },
      findingsLookup: auditItemId => {
        return [];
      },
      hasNoteLookup: auditItemId => {
        return false;
      },
      inSelfAssessmentLookup: auditItemId => {
        return false;
      },
      isConsideredLookup: auditItemId => {
        return true;
      },
      openDimensionsLookup: auditItemId => {
        return null;
      },
      measureViolationLookup: auditItemId => {
        return [];
      },
    };

    return buildAuditItemAggegators(
      findingTypeConfig.types,
      auditItemTypeMap,
      mappedCategories,
      categoryLevel,
      emptyDimensionsExtratror,
      auditItemIdBasedLookupFunctions
    );
  },
  [n.getFilterTreeForFrontendFiltering](
    state,
    getters,
    rootState,
    rootGetters
  ): FilterTree {
    const mappedTemplateItems = getters[
      n.getMappedTemplateItems
    ] as Dictionary<AuditItem>;
    const listsConfigs = rootGetters[
      getterNs(confApi, confApi.getters.lists)
    ] as ListsConfigMap;

    const auditItemAggregators = getters[
      n.getAuditItemAggregators
    ] as ReturnType<typeof buildAuditItemAggegators>;

    return buildFilterIndexForItems(
      auditItemAggregators,
      mappedTemplateItems,
      selectAvailableFiltersForList(listsConfigs, "templatePreperation")
    );
  },
  [n.getTemplateItemListFilterAggregations](state, getters): Aggregation[] {
    const activeFilters = state.activeFilter;
    const filterTree = getters[
      n.getFilterTreeForFrontendFiltering
    ] as FrontendFilterTree;
    const mappedTemplateItems = getters[
      n.getMappedTemplateItems
    ] as Dictionary<AuditItem>;

    const allItemIds: string[] = keys(mappedTemplateItems);

    const aggregations = buildAggregationsFromActiveFiltersAndIndex(
      filterTree,
      activeFilters,
      allItemIds,
      readFrontendLanguage()
    );
    return aggregations;
  },
  [n.getVdaScopeSelectable](state, getters): boolean {
    const auditClass = getters[n.getAuditClass] as nullable<AuditClassClient>;

    return (auditClass && auditClass.vdaConfig?.vdaScopeSelectable) ?? false;
  },
};

export { n as getterNames, getters };
