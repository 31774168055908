import { nullable } from "@auditcloud/shared/lib/types/common";
import { WatchedDocument, unlistenerFunction } from "@/utils/firestore";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import {
  defaultPageConfig,
  PaginationSetting,
} from "@auditcloud/shared/lib/types/Audit/types";

export class MeasuresListState {
  MeasureDocuments: WatchedDocument<MeasureProcessDocument | null>[] = [];
  MeasureUnlistener: nullable<unlistenerFunction> = null;
  measures: MeasureProcessDocument[] = [];
  loadedNewQuery: boolean = true;
  pageConfig: PaginationSetting = defaultPageConfig();
  SelectedMeasureIds: string[] = [];
}
export type State = MeasuresListState;
