var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.userDialogVisible)?_c('AUserAutocompleteCreateDialog',{on:{"save":_vm.selectNewUser},model:{value:(_vm.userDialogVisible),callback:function ($$v) {_vm.userDialogVisible=$$v},expression:"userDialogVisible"}}):_vm._e(),_c('v-autocomplete',_vm._b({ref:"autocomplete",attrs:{"label":_vm.$ft(_vm.label),"placeholder":_vm.defaultedPlaceholder,"items":_vm.xItems,"hide-no-data":_vm.itemsGetter === null && !_vm.allowUserCreation,"loading":_vm.loading,"search-input":_vm.search,"item-value":"id","item-text":"data.displayName","value":_vm.selected,"append-icon":_vm.appendIcon,"return-object":true,"multiple":true,"menu-props":{ closeOnContentClick: _vm.closeOnContentClick },"cache-items":true},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":_vm.onSelectionChanged,"click:append":_vm.onClearClicked},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"size":"32px","color":"purple"}},[_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(item.data.displayName.charAt(0).toUpperCase())+" ")])])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.data.displayName)}}),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.resolveUser(item.id).e)+" ")])],1)]}},(_vm.allowUserCreation)?{key:"no-data",fn:function(){return [_c('span')]},proxy:true}:null,{key:"selection",fn:function(ref){
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._b({staticClass:"white--text ma-1",attrs:{"pill":"","small":"","input-value":selected,"color":_vm.greyedOutUserIds.includes(item.id) ? 'grey lighten-1' : 'primary',"close":!_vm.$attrs.disabled},on:{"click:close":function($event){return _vm.unselectItem(item.id)}}},'v-chip',_vm.$attrs,false),[_c('v-avatar',{staticClass:"font-weight-light white--text",attrs:{"left":"","color":_vm.greyedOutUserIds.includes(item.id) ? 'grey' : 'secondary'}},[_vm._v(" "+_vm._s(item.data.displayName.charAt(0))+" ")]),_c('span',{style:({
            'text-decoration': _vm.greyedOutUserIds.includes(item.id)
              ? 'line-through'
              : 'none',
          }),domProps:{"textContent":_vm._s(item.data.displayName)}})],1)]}},(_vm.allowUserCreation)?{key:"append-item",fn:function(){return [_c('v-list-item',{attrs:{"link":""},nativeOn:{"click":function($event){return _vm.openAddUserDialog($event)}}},[_vm._v(" "+_vm._s(_vm.$t("components.controls.user_autocomplete.create_external_user"))+" ")])]},proxy:true}:null],null,true)},'v-autocomplete',_vm.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }