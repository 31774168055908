import firebase from "firebase/compat/app";
import { DocumentUpdateHandlerData } from "./types/DocumentUpdateHandlerData";

type DocumentUpdateHandler = (updateData: DocumentUpdateHandlerData) => void;

type LoadingStateSetter = (loading: boolean) => void;
type ErrorHandler = (
  error: Error,
  collection: string,
  documentId: string
) => void;

export function createDocumentObserver(
  collection: string,
  documentId: string,
  documentUpdateHandler: DocumentUpdateHandler,
  errorHandler: ErrorHandler,
  loadingStateSetter: LoadingStateSetter = () => {}
) {
  const observer = {
    error(err: Error) {
      console.error(`Observer[${collection}/${documentId}].error`, err);
      errorHandler(err, collection, documentId);
      loadingStateSetter(false);
    },
    next(snapshot: firebase.firestore.DocumentSnapshot) {
      console.log(
        `Observer[${collection}/${documentId}].next`,
        snapshot.metadata
      );
      const data = snapshot.data() ?? null;
      try {
        documentUpdateHandler({
          data,
          exists: snapshot.exists,
          id: snapshot.id,
          metadata: { ...snapshot.metadata },
        });
      } catch (err) {
        errorHandler(
          err instanceof Error ? err : new Error(`${err}`),
          collection,
          documentId
        );
      }
    },
  };
  return firebase
    .firestore()
    .collection(collection)
    .doc(documentId)
    .onSnapshot({ includeMetadataChanges: true }, observer);
}
