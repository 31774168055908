import { Dictionary } from "lodash";
import { DocumentCustomMetadata } from "@auditcloud/shared/lib/types/Documents/CustomMetadata";
import firebase from "firebase/compat/app";

export type CustomMetadataType =
  DocumentCustomMetadata<firebase.firestore.Timestamp>;

export class AutoCompletionsState {
  autoCompletionData: Dictionary<CustomMetadataType> = {};
}
