import { v4 as uuidv4 } from "uuid";
import { createAuditStorageBasePath } from "@auditcloud/shared/lib/utils/storage/storagePathHelper";
import { uploadFile, uploadBlob, UploaderMetadata } from "@/utils/uploader";

import {
  Finding,
  AuditResultDoc,
  UserRef,
} from "@auditcloud/shared/lib/schemas";
import { Attachment } from "@/components/types";
import { Dictionary } from "vue-router/types/router";
import { getterNs } from "@/utils/VuexHelper";
import { api as auditApi } from "../audit";
import { createError } from "@/utils/Errors";
import { isString, isArray } from "lodash";
import { FindingTypeMap } from "@auditcloud/shared/lib/types/ItemTypes";
import { ct } from "@/plugins/ContentTranslation";

export type EmptyFindingPayLoad = {
  auditItemId: string | null;
  dimensions: null | string[];
};

export function emptyFinding(
  { auditItemId, dimensions }: EmptyFindingPayLoad = {
    auditItemId: null,
    dimensions: null,
  }
): Finding {
  return {
    auditItemRefs: auditItemId ? [{ auditItemId, dimensions }] : [],
    type: "",
    text: "",
    attachmentIds: [],
    customData: {},
    locations: [],
  };
}

export type UploadFindingAttachmentPayload = {
  auditId: string;
  findingId: string;
  userRef: UserRef;
  attachment: Attachment;
};

export async function uploadFindingAttachment({
  auditId,
  findingId,
  userRef,
  attachment,
}: UploadFindingAttachmentPayload) {
  const uploadBasePath = createAuditStorageBasePath(auditId);

  const metadata: UploaderMetadata = {
    target: {
      type: "finding",
      auditId,
      findingId,
    },
  };
  const uploadTaskSnapshot = await (attachment.data instanceof File
    ? uploadFile(attachment.data, metadata)
    : uploadBlob(attachment.data, attachment.name, metadata));

  if (!uploadTaskSnapshot.isOk()) {
    throw uploadTaskSnapshot.error;
  }
  const url = uploadTaskSnapshot.value.data.attachmentEntry.url;
  const added = uploadTaskSnapshot.value.data.attachmentEntry.added;
  const mediaId = uploadTaskSnapshot.value.data.id;

  const firestoreInfo: {
    id: string;
    attachment: AuditResultDoc["attachments"][string];
  } = {
    id: mediaId,
    attachment: {
      url,
      icon: attachment.icon,
      type: attachment.data.type,
      size: attachment.data.size,
      name: attachment.name,
      added,
      owner: userRef,
      comment: attachment.comment,
    },
  };
  return firestoreInfo;
}

export function normalizeFinding(
  finding: Finding,
  rootGetters: Dictionary<unknown>
): Finding {
  const typeId = finding.type;
  const findingType = (
    rootGetters[
      getterNs(auditApi, auditApi.getters.getMappedFindingTypes)
    ] as FindingTypeMap
  )[typeId];

  if (!findingType) {
    throw createError("Unknown finding type", typeId, finding);
  }

  const text =
    isString(finding.text) && finding.text.trim().length > 0
      ? finding.text.trim()
      : ct(
          findingType.placeholder_text || {
            de: "Das ist ein Platzhalter",
            en: "This is a placeholder",
          }
        );

  const locations = isArray(finding.locations) ? finding.locations : [];

  return { ...finding, locations, text };
}
