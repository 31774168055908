import ui from "./ui.json";
import data from "./data.json";

import { SchemaMap, FormSchema } from "../../index";
const dialogSchema: FormSchema = {
  ui,
  data,
};

const schemaMap: SchemaMap = {
  default: dialogSchema,
};
export default schemaMap;
