















































import Vue, { PropType } from "vue";
import { Component, Prop } from "vue-property-decorator";

import {
  MeasureProcessStepDoc,
  TranslateableText,
} from "@auditcloud/shared/lib/schemas";
import AAuditOverviewQuestion from "@/components/widgets/AAuditOverviewQuestion.vue";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import { MeasureWorkflow } from "@auditcloud/shared/lib/workflow/modules/Measure";
import { stepImplementation } from "@auditcloud/shared/lib/workflow/types/MeasureProcessStep";
import { idable } from "@auditcloud/shared/lib/types/common";
import { State, StepType } from "@auditcloud/shared/lib/workflow/types/State";

interface FreeProperty {
  processProperty: string;
  title: TranslateableText;
}

const PROCESS_PROPERTIES_TO_CHECK: FreeProperty[] = [
  { processProperty: "causeAnalysis", title: "label_cause_analysis" },
  { processProperty: "description", title: "label_description" },
  { processProperty: "measureImplementation", title: "label_implementation" },
  { processProperty: "approval", title: "label_approval" },
];

@Component({
  components: {
    AAuditOverviewQuestion,
  },
})
export default class AMeasureProcessFreeInputs extends Vue {
  @Prop({ type: Array as PropType<MeasureProcessStepDoc[]>, required: true })
  processSteps!: MeasureProcessStepDoc[];

  @Prop({ type: Object as PropType<MeasureWorkflow>, required: true })
  workflow!: MeasureWorkflow;

  @Prop({ type: Object as PropType<MeasureProcessDocument>, required: true })
  measureProcess!: MeasureProcessDocument;

  @Prop({
    type: Array as PropType<idable<State<MeasureProcessDocument>>[]>,
    required: true,
  })
  remainingPreviewStates!: idable<State<MeasureProcessDocument>>[];

  get freeProperties(): FreeProperty[] {
    return PROCESS_PROPERTIES_TO_CHECK.filter(({ processProperty }) => {
      if (!this.measureProcess[processProperty]) {
        return false;
      }

      const propertyInProcessSteps = this.processSteps.some(stepDoc => {
        const stepImpl = stepImplementation(this.workflow, stepDoc);
        const stepProperties = stepImpl.toMeasureProcessUpdate();
        return processProperty in stepProperties;
      });

      const propertyInPreviewStates = this.remainingPreviewStates.some(
        previewState => {
          const stepConfig = previewState.step;
          if (stepConfig?.type === StepType.SIMPLE_MESSAGE) {
            return stepConfig.description?.processProperty === processProperty;
          }
          if (stepConfig?.type === StepType.IMPLEMENTATION_MESSAGE) {
            return (
              stepConfig.summary?.processProperty === processProperty ||
              stepConfig.description?.processProperty === processProperty
            );
          }
          return false;
        }
      );

      return !propertyInProcessSteps && !propertyInPreviewStates;
    });
  }
}
