






















import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import _ from "lodash";

import { mapState, mapActions, mapGetters } from "vuex";
import { api, SetTransitionPanelPayload } from "@/store/modules/app";
import { ROUTE_NAMES, DIALOG_NAMES, dialogRoute } from "@/routenames";
import { AUDIT_ATTACHMENT_SIDEBAR_CONFIG } from "@/store/modules/audit/types";

const auditSteps: string[] = [
  ROUTE_NAMES.AUDITPLANNING,
  ROUTE_NAMES.AUDITPREPARATION,
  ROUTE_NAMES.AUDITEXECUTION,
  ROUTE_NAMES.AUDITWRAPUP,
  ROUTE_NAMES.AUDITREPORTING,
  ROUTE_NAMES.AUDITACTIONLIST,
  ROUTE_NAMES.AUDITFINALIZED,
];

const templateItemListViews: string[] = [ROUTE_NAMES.TEMPLATE_DIALOG];

const auditItemListViews: string[] = [
  ROUTE_NAMES.AUDITPREPARATION,
  ROUTE_NAMES.AUDITEXECUTION,
  ROUTE_NAMES.AUDITWRAPUP,
  ROUTE_NAMES.AUDITREPORTING,
  ROUTE_NAMES.AUDITACTIONLIST,
];

const allAuditSteps: string[] = [
  ROUTE_NAMES.AUDITPLANNING,
  ROUTE_NAMES.AUDITPREPARATION,
  ROUTE_NAMES.AUDITEXECUTION,
  ROUTE_NAMES.AUDITWRAPUP,
  ROUTE_NAMES.AUDITREPORTING,
  ROUTE_NAMES.AUDITACTIONLIST,
  ROUTE_NAMES.AUDITFINALIZED,
];

@Component({})
export default class NavigationPanelRight extends Vue {
  xTitle: any = null;
  stateless = false;

  @Getter(api.getters.hasTransitionPanel, { namespace: api.namespace })
  hasTransitionPanel!: boolean;

  @Getter(api.getters.getTransitionPanel, { namespace: api.namespace })
  getTransitionPanel!: SetTransitionPanelPayload;

  get panelComponent() {
    const panel = this.getTransitionPanel;

    return panel ? panel.component : null;
  }

  get propsComponent(): { [name: string]: any } {
    const props = this.getTransitionPanel?.props ?? {};
    console.log("propsComponent", props);
    return props;
  }
  get panelWidth() {
    return this.$vuetify.breakpoint.mdAndUp ? "40%" : "100%";
  }

  set displayDrawer(val) {
    console.log("displayDrawer", val, this.$route);
    const currentRouteName = this.$route.name;
    if (val === false && currentRouteName) {
      this.xTitle = null;
      if (
        (auditSteps.includes(currentRouteName) &&
          (this.$route.params.dialogName === DIALOG_NAMES.NEXT ||
            this.$route.params.dialogName ===
              DIALOG_NAMES.AUDIT_ATTACHMENTS)) ||
        (allAuditSteps.includes(currentRouteName) &&
          this.$route.params.dialogName === DIALOG_NAMES.AUDIT_DATA) ||
        (auditItemListViews.includes(currentRouteName) &&
          (this.$route.params.dialogName === DIALOG_NAMES.FILTER ||
            this.$route.params.dialogName === DIALOG_NAMES.SORT ||
            this.$route.params.dialogName ===
              DIALOG_NAMES.PRINT_FILTER_DIALOG)) ||
        (currentRouteName === ROUTE_NAMES.AUDITEXECUTION &&
          this.$route.params.dialogName ===
            DIALOG_NAMES.ACTIVATE_SELF_ASSESSMENT) ||
        (currentRouteName === ROUTE_NAMES.AUDITACTIONLIST &&
          this.$route.params.dialogName ===
            DIALOG_NAMES.ACTIVATE_EXTERNAL_MEASURE_PROCESSING)
      ) {
        console.log("displayDrawer:nav", val, this.$route);
        this.$router.push({
          name: currentRouteName,
          params: _.omit(this.$route.params, "dialogName"),
        });
      } else if (
        templateItemListViews.includes(currentRouteName) &&
        this.$route.params.dialogName === DIALOG_NAMES.FILTER
      ) {
        console.log("displayDrawer:nav", val, this.$route);
        this.$router.push({
          name: ROUTE_NAMES.TEMPLATE,
          params: _.omit(this.$route.params, "dialogName"),
        });
      } else if (this.panelComponent === "AuditActivityStream") {
        this.setTransitionPanel(null);
      }
    }
  }
  get displayDrawer() {
    return this.hasTransitionPanel;
  }

  get title() {
    const panel = this.getTransitionPanel;
    if (this.xTitle) {
      return this.xTitle;
    } else if (panel && panel.title) {
      return panel.title;
    } else {
      return "Rightbar";
    }
  }

  @Action(api.actions.setTransitionPanel, { namespace: api.namespace })
  setTransitionPanel!: (payload: SetTransitionPanelPayload) => Promise<void>;

  setTitle(title) {
    this.xTitle = title;
  }

  @Watch("$route", {
    immediate: true,
    deep: true,
  })
  onWatchRoute(newVal) {
    console.log("onWatchRoute", newVal);
    this.stateless = false;
    if (
      templateItemListViews.includes(newVal.name) &&
      newVal.params.dialogName === DIALOG_NAMES.FILTER
    ) {
      this.setTransitionPanel({
        component: "ATemplateItemFilterAction",
        title: "ExternTitle",
      });
    } else if (
      auditSteps.includes(newVal.name) &&
      newVal.params.dialogName === DIALOG_NAMES.NEXT
    ) {
      this.stateless = true;
      console.log("Open Transition", newVal);
      this.setTransitionPanel({
        component: "AAuditTransition",
        title: "ExternTitle",
      });
    } else if (
      auditSteps.includes(newVal.name) &&
      newVal.params.dialogName === DIALOG_NAMES.AUDIT_ATTACHMENTS
    ) {
      console.log("Open AuditAttachments", newVal);
      this.setTransitionPanel(AUDIT_ATTACHMENT_SIDEBAR_CONFIG);
    } else if (
      allAuditSteps.includes(newVal.name) &&
      newVal.params.dialogName === DIALOG_NAMES.AUDIT_DATA
    ) {
      console.log("Open AuditData", newVal);
      this.setTransitionPanel({
        component: "AAuditDataSidebar",
        title: "Audit",
      });
    } else if (
      auditItemListViews.includes(newVal.name) &&
      newVal.params.dialogName === DIALOG_NAMES.SORT
    ) {
      console.log("Open SortAction", newVal);
      this.setTransitionPanel({
        component: "AAuditItemSortAction",
        title: "ExternTitle",
      });
    } else if (
      auditItemListViews.includes(newVal.name) &&
      newVal.params.dialogName === DIALOG_NAMES.FILTER
    ) {
      console.log("Open FilterAction", newVal);
      this.setTransitionPanel({
        component: "AAuditItemFilterAction",
        title: "ExternTitle",
      });
    } else if (
      auditItemListViews.includes(newVal.name) &&
      newVal.params.dialogName === DIALOG_NAMES.PRINT_FILTER_DIALOG
    ) {
      console.log("Open FilterAction", newVal);
      this.setTransitionPanel({
        component: "APrintFilterSidebar",
        title: "ExternTitle",
      });
    } else if (
      newVal.name === ROUTE_NAMES.AUDITEXECUTION &&
      newVal.params.dialogName === DIALOG_NAMES.ACTIVATE_SELF_ASSESSMENT
    ) {
      console.log("Open Self Assessment", newVal);
      this.setTransitionPanel({
        component: "ASelfAssessmentSidebar",
        title: "ExternTitle",
      });
    } else if (
      newVal.name === ROUTE_NAMES.AUDITACTIONLIST &&
      newVal.params.dialogName ===
        DIALOG_NAMES.ACTIVATE_EXTERNAL_MEASURE_PROCESSING
    ) {
      console.log("Open external measure processing", newVal);
      this.setTransitionPanel({
        component: "AExternalMeasureProcessingSidebar",
        title: "ExternTitle",
      });
    } else {
      this.setTransitionPanel(null);
    }
  }
}
