import Vue from "vue";
import { MutationTree } from "vuex";
import { AutoCompletionsState } from "./types";

const SET_AUTO_COMPLETES_DATA = "SET_AUTO_COMPLETES_DATA";
const SET_AUTO_COMPLETE_DATA = "SET_AUTO_COMPLETE_DATA";
const CLEAR_AUTO_COMPLETES_DATA = "CLEAR_AUTO_COMPLETES_DATA";

const n = {
  SET_AUTO_COMPLETES_DATA,
  SET_AUTO_COMPLETE_DATA,
  CLEAR_AUTO_COMPLETES_DATA,
};

const mutations: MutationTree<AutoCompletionsState> = {
  [n.SET_AUTO_COMPLETES_DATA](state, payload) {
    Vue.set(state, "autoCompletionData", payload);
  },

  [n.CLEAR_AUTO_COMPLETES_DATA](state) {
    Vue.set(state, "autoCompletionData", {});
  },
  [n.SET_AUTO_COMPLETE_DATA](state, { id, data }) {
    console.assert(
      typeof id === "string" && data instanceof Object,
      "Invalid parameter",
      id,
      data
    );

    Vue.set(state.autoCompletionData, id, data);
  },
};

export { n as mutationNames, mutations };
