import ui from "./ui.json";
import data from "./data.json";

import { FormSchema, SchemaMap } from "../../../index";

const dialogSchema: FormSchema = {
  ui,
  data,
};

export default dialogSchema;
