import { GetterTree } from "vuex";
import { ApiError, ApiUser, ApiUserParams, UserManagementState } from "./types";
import { RootState } from "@/store/types";

const getError = "getError";
const getParams = "getParams";
const getUser = "getUser";
const getUserList = "getUserList";
const hasMore = "hasMore";
const isLoading = "isLoading";

const n = {
  getError,
  getParams,
  getUser,
  getUserList,
  hasMore,
  isLoading,
};

const getterTree: GetterTree<UserManagementState, RootState> = {
  [n.getError](state): ApiError | null {
    return state.error;
  },
  [n.getUser](state): ApiUser | null {
    return state.user;
  },
  [n.getUserList](state): ApiUser[] {
    return state.users;
  },
  [n.getParams](state): ApiUserParams {
    return state.params;
  },
  [n.hasMore](state, getters, rootState, rootGetters): boolean {
    return state.nextFrom != null;
  },
  [n.isLoading](state, getters, rootState, rootGetters): boolean {
    return state.loading;
  },
};

export { n as getterNames, getterTree as getters };
