


















import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import {
  isString,
  isFunction,
  isMap,
  take,
  get,
  flatten,
  pick,
  merge,
  isObject,
  cloneDeep,
  isEqual,
} from "lodash";

import { typeIsNotEmpty } from "@auditcloud/shared/lib/utils/filter/typeIsNotEmpty";
import {
  ConfigurableGetter,
  getterPath,
  typeIsConfigurableGetter,
} from "@auditcloud/shared/lib/types/common";
import {
  TranslateableText,
  CompositionWizardSettings,
  CompositionWizardSettingsNode,
} from "@auditcloud/shared/lib/schemas";

import {
  typeIsCompositionWizardSettings,
  typeIsCompositionWizardSettingsNode,
  CompositionWizardResultType,
} from "../types";

@Component({
  name: "ACompositionWizard",
})
export default class ACompositionWizard extends Vue {
  selectionPath: (string | null)[] = [null];

  @Prop({
    type: Object,
    required: true,
  })
  itemsGetter!:
    | ConfigurableGetter
    | CompositionWizardSettingsNode
    | CompositionWizardSettings;

  @Watch("itemsGetter", { immediate: true, deep: true })
  onItemsGetterChanged(newVal: any, oldVal: any) {
    if (!isEqual(newVal, oldVal)) {
      this.selectionPath = [null];
      this.$emit("change", this.result);
    }
  }

  get getterResult() {
    const ig = this.itemsGetter;

    if (typeIsConfigurableGetter(ig)) {
      const path = getterPath(ig);
      const getterResult: unknown = this.$store.getters[path];

      return getterResult;
    } else {
      return this.itemsGetter;
    }
  }

  get getterData():
    | CompositionWizardSettingsNode
    | CompositionWizardSettings
    | null {
    const getterResult = this.getterResult;
    const isExpectedType = (
      v: unknown
    ): v is CompositionWizardSettingsNode | CompositionWizardSettings =>
      typeIsCompositionWizardSettings(getterResult) ||
      typeIsCompositionWizardSettingsNode(getterResult);

    const ig = this.itemsGetter;
    const id = typeIsConfigurableGetter(ig) ? ig.id : undefined;
    if (isExpectedType(getterResult)) {
      return getterResult;
    } else if (isString(id)) {
      let result: unknown;
      if (isFunction(getterResult)) {
        result = getterResult(id);
      } else if (isMap(getterResult)) {
        result = getterResult.get(id);
      } else if (isObject(getterResult)) {
        result = getterResult[id];
      }
      console.assert(
        isExpectedType(result),
        `expect getter Result to be composition wizard settings`
      );
      return isExpectedType(result) ? cloneDeep(result) : null;
    } else {
      console.error("invalid items getter", ig, this.getterResult);
      return null;
    }
  }

  get baseItems() {
    const base = this.getterData;
    return typeIsCompositionWizardSettingsNode(base)
      ? cloneDeep(base.children)
      : [];
  }

  get levelNames(): TranslateableText[] {
    return this.selectionPath.map((entryId, level) => {
      const value =
        (this.readNestedField(
          this.selectionPath,
          level,
          "title"
        ) as TranslateableText) ?? "Unknown";
      return value;
    });
  }

  get baseResult(): CompositionWizardResultType {
    return cloneDeep(pick(this.getterData, ["preset"]).preset ?? {});
  }

  get resultList(): CompositionWizardResultType[] {
    return [
      ...this.selectionPath.map((entryId, level) => {
        const levelPath = [
          ...flatten(
            take(this.selectionPath, level + 1)
              .filter(typeIsNotEmpty)
              .map(entryId => ["children", parseInt(entryId.substr(3), 10)])
          ),
        ];

        const value =
          (get(this.getterData, levelPath) as CompositionWizardSettings) ??
          null;

        return value?.preset ?? {};
      }),
    ];
  }

  get items(): Array<Array<{ id: string; name: TranslateableText }>> {
    return this.selectionPath.map((pathPart, level) => {
      return this.itemsForLevel(this.selectionPath, level);
    });
  }

  get result(): CompositionWizardResultType {
    if (this.selectionPath.every(isString)) {
      const result = merge(
        cloneDeep(this.baseResult),
        ...cloneDeep(this.resultList)
      );
      return result;
    } else {
      return null;
    }
  }

  readNestedField(
    path: Array<string | null>,
    level: number,
    fieldName: string
  ): any {
    const levelPath = [
      ...flatten(
        take(path, level)
          .filter(typeIsNotEmpty)
          .map(entryId => ["children", parseInt(entryId.substr(3), 10)])
      ),
      fieldName,
    ];
    return get(this.getterData, levelPath);
  }

  itemsForLevel(
    path: Array<string | null>,
    level: number
  ): Array<{ id: string; name: TranslateableText }> {
    const items =
      (this.readNestedField(this.selectionPath, level, "children") as Array<
        CompositionWizardSettingsNode | CompositionWizardSettings
      >) ?? [];

    return items.map((v, idx) => {
      return {
        id: `id-${idx}`,
        name: v.name,
      };
    });
  }

  changed(level: number, id: string) {
    const newVal: Array<string | null> = take(
      this.selectionPath.filter(isString),
      level
    );

    newVal.push(id);
    this.selectionPath = newVal;
    if (this.itemsForLevel(newVal, level + 1).length > 0) {
      newVal.push(null);
    }

    this.selectionPath = newVal;
    this.$emit("change", this.result);
  }
}
