import _ from "lodash";
import { Module } from "vuex";
import { MeasuresListState } from "./types";
import { RootState } from "../../types";
import { MutationsType, GettersType, ActionsType } from "@/utils/VuexHelper";

import { getters, getterNames } from "./getters";
import { actions, actionNames } from "./actions";
import { mutations, mutationNames } from "./mutations";

const state = new MeasuresListState();

const namespaced: boolean = true;
const modul: Module<MeasuresListState, RootState> = {
  namespaced,
  getters,
  actions,
  mutations,
  state,
};

export const api = {
  namespace: "measures",
  mutations: mutationNames,
  getters: getterNames,
  actions: actionNames,
};

export type Mutations = MutationsType<typeof mutations>;
export type Getters = GettersType<typeof getters>;
export type Actions = ActionsType<typeof actions>;
export default modul;
