

























import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import _ from "lodash";

@Component({})
export default class ANumberAggregationInput extends Vue {
  xValue: { [key: string]: number } = {};
  @Prop({
    type: Object,
    default: () => ({}),
  })
  value!: { [key: string]: number };

  @Prop({
    type: Array,
    default: () => [],
  })
  fields!: string[];

  @Watch("value", { deep: true, immediate: true })
  onValueChanged(newVal: { [key: string]: number }) {
    this.xValue = _.pick(newVal, this.fields);
  }

  onInput(field: string, value: string) {
    const newVal = { ...this.xValue, [field]: parseFloat(value) };
    this.xValue = newVal;

    this.$emit("input", newVal);
  }

  get employeeCount() {
    return Object.values(this.xValue).reduce((prev, current) => {
      return prev + current;
    }, 0);
  }

  created() {
    console.log("ANumberAggregationInput", this.$attrs);
  }
}
