import { TranslateableText } from "@auditcloud/shared/lib/schemas";
import i18n from "../../src/plugins/i18n";
import moment from "moment";

interface DateRangeFilter<
  L = number | "-",
  U = number | "+",
  UT = "days" | "weeks" | "months" | "years"
> {
  range: { lower: L; upper: U };
  text: TranslateableText;
  unit: UT;
}

const NORMALIZED_BASE_UNIT = "days";
type NormalizedDateRangeFilter = DateRangeFilter<
  number,
  number,
  typeof NORMALIZED_BASE_UNIT
>;

const MeasureDateFilter: DateRangeFilter[] = [
  {
    range: { lower: "-", upper: 0 },
    text: { de: "1 - Planmäßig", en: "1 - On schedule" },
    unit: "days",
  },
  {
    range: { lower: -7, upper: -1 },
    text: { de: "2 - In den nächsten 7 Tagen fällig", en: "2 - Next 7 days" },
    unit: "days",
  },
  {
    range: { lower: 1, upper: "+" },
    text: { de: "3 - Seit 1 Woche überfällig", en: "3 - Past due for 1 week" },
    unit: "weeks",
  },
  {
    range: { lower: 1, upper: "+" },
    text: { de: "4 - Seit 1 Monat überfällig", en: "4 - Past due for 1 month" },
    unit: "months",
  },
  {
    range: { lower: 3, upper: "+" },
    text: {
      de: "5 - Seit 3 Monaten überfällig",
      en: "5 - Past due for 3 months",
    },
    unit: "months",
  },
  {
    range: { lower: 1, upper: "+" },
    text: { de: "6 - Überfällig", en: "6 - Past due" },
    unit: "days",
  },
];

const normalizeRangeDaysMapper = (
  dateRangeFilter: DateRangeFilter[]
): NormalizedDateRangeFilter[] => {
  const plusInfinity = moment()
    .add(100, "years")
    .diff(moment(), NORMALIZED_BASE_UNIT);
  const minusInfinity = moment()
    .subtract(100, "years")
    .diff(moment(), NORMALIZED_BASE_UNIT);

  const normalizedDiff = (val: number, unit: DateRangeFilter["unit"]) => {
    return moment().add(val, unit).diff(moment(), NORMALIZED_BASE_UNIT);
  };

  const normalized: NormalizedDateRangeFilter[] = dateRangeFilter.map(
    object => {
      const leftBoundDays =
        object.range.lower === "-"
          ? minusInfinity
          : normalizedDiff(object.range.lower, object.unit);
      const rightBoundDays =
        object.range.upper === "+"
          ? plusInfinity
          : normalizedDiff(object.range.upper, object.unit);

      return {
        text: object.text,
        range: { lower: leftBoundDays, upper: rightBoundDays },
        unit: NORMALIZED_BASE_UNIT,
      };
    }
  );
  return normalized;
};

const measureDateFilterDays = normalizeRangeDaysMapper(MeasureDateFilter);

const getRangeValues = (days: number) => {
  return measureDateFilterDays
    .filter(({ range }) => {
      return range.lower <= days && days <= range.upper;
    })
    .map(v => v.text);
};

export const getDateRange = (dueDate: string, date = Date.now()) => {
  const days = moment(date)
    // "ceil" the values -> e.g. 0.5 days are treated as 1 day
    .startOf(NORMALIZED_BASE_UNIT)
    .diff(moment(dueDate).startOf("day"), NORMALIZED_BASE_UNIT);

  return getRangeValues(days);
};

export const getDateFilterMonth = (startDate: string) =>
  moment(startDate, "YYYY-MM-DD").format("MMMM");

export const measureStringToValue = (dateState: string) => {
  return measureDateFilterDays.findIndex(v =>
    v.text.toString().includes(dateState)
  );
};
