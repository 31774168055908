

















































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AAxiosAlert from "../../snippets/AAxiosAlert.vue";
import AMeasureProcessTitleSuggestion from "../AMeasureProcessTitleSuggestion.vue";
import Ajv from "ajv";
import {
  MeasureProcessStep,
  MeasureProcessStepDoc,
  MeasureProcessStepContextImplementationMessage,
} from "@auditcloud/shared/lib/schemas";
import { idable } from "@auditcloud/shared/lib/types/common";
import {
  compilePropertyValidationRules,
  providePropertyMeta,
} from "./utils/stepContext";
import {
  State,
  StepConfigImplementationMessage,
  StepConfigPropertyMeta,
  StepType,
} from "@auditcloud/shared/lib/workflow/types/State";

import {
  api as measureApi,
  Getters as MeasureGetters,
  Mutations as MeasureMutations,
  Actions as MeasureActions,
} from "@/store/modules/measure";
import { Operation } from "fast-json-patch";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";

type VForm = Vue & { validate: () => void };

const measureModule = namespace(measureApi.namespace);
@Component({
  components: { AAxiosAlert, AMeasureProcessTitleSuggestion },
})
export default class AMeasureProcessStepControlImplementationMessage extends Vue {
  @Prop({
    type: String,
  })
  readonly stepId!: string;

  @Prop({
    type: Boolean,
    default: true,
  })
  readonly readonly!: boolean;

  @measureModule.Getter(measureApi.getters.getWorkflow, {
    namespace: measureApi.namespace,
  })
  workflow!: MeasureGetters["getWorkflow"];

  @measureModule.Getter(measureApi.getters.getCurrentMeasure, {
    namespace: measureApi.namespace,
  })
  currentMeasure!: MeasureGetters["getCurrentMeasure"];

  @measureModule.Getter(measureApi.getters.getProcessSteps, {
    namespace: measureApi.namespace,
  })
  processSteps!: MeasureGetters["getProcessSteps"];

  @measureModule.Mutation(measureApi.mutations.SET_CURRENT_STEP_DIRTY)
  setCurrentStepDirty!: MeasureMutations["SET_CURRENT_STEP_DIRTY"];

  @measureModule.Action(measureApi.actions.patchMeasureStep)
  patchMeasureStep!: MeasureActions["patchMeasureStep"];

  isValid = true;
  loading = false;
  requestPromise: Promise<unknown> = Promise.resolve();

  lazySummary: string | null = null;
  lazyDescription: string | null = null;

  @Watch("stepContext.summary")
  onStepContextSummaryChange(newValue: string) {
    if (newValue === this.lazySummary) {
      this.lazySummary = null;
    }
  }

  @Watch("stepContext.description")
  onStepContextDescriptionChange(newValue: string) {
    if (newValue === this.lazyDescription) {
      this.lazyDescription = null;
    }
  }

  get step(): null | idable<MeasureProcessStepDoc> {
    return this.processSteps.find(step => step.id === this.stepId) ?? null;
  }

  get stateConfig(): State<MeasureProcessDocument> | null {
    const stateId = this.step?.workflowInfo.state.id;
    return stateId == null ? null : this.workflow?.states.get(stateId) ?? null;
  }

  get stepConfig(): StepConfigImplementationMessage {
    const stepConfig = this.stateConfig?.step;
    if (stepConfig?.type === StepType.IMPLEMENTATION_MESSAGE) {
      return stepConfig;
    }
    return { type: StepType.IMPLEMENTATION_MESSAGE };
  }

  get stepContext(): null | MeasureProcessStepContextImplementationMessage {
    const stepContext: null | MeasureProcessStep["stepContext"] =
      this.step?.stepContext ?? null;

    if (
      stepContext == null ||
      stepContext?.type !== StepType.IMPLEMENTATION_MESSAGE
    ) {
      console.warn("No valid stepContext: ", this.stepId, stepContext?.type);
      return null;
    }
    return stepContext;
  }

  get isDirty() {
    const description = this.lazyDescription?.trim() ?? null;
    const summary = this.lazySummary?.trim() ?? null;
    return (
      (description !== null && description !== this.stepContext?.description) ||
      (summary !== null && summary !== this.stepContext?.summary)
    );
  }

  @Watch("isDirty")
  updateIsDirtyState(newState: boolean) {
    this.setCurrentStepDirty(newState);
  }

  get isActiveStep() {
    return this.step?.workflowInfo.leftAt === null;
  }

  get isEditable() {
    return !this.readonly && this.step?.workflowInfo.leftAt === null;
  }

  get summary(): string {
    return this.lazySummary ?? this.stepContext?.summary ?? "";
  }

  set summary(val) {
    this.lazySummary = val;
  }

  get summaryMeta(): StepConfigPropertyMeta {
    const stateConfig = this.stateConfig;
    return providePropertyMeta(
      this.stepConfig.summary,
      this.$ft(stateConfig?.name ?? "")
    );
  }

  summaryMaxLength: number = 140;
  get summaryValidationRules() {
    const { rules } = this.summaryMeta;
    return [
      ...compilePropertyValidationRules(this.ajv, rules),
      (summary: unknown) =>
        (typeof summary === "string" &&
          summary.length <= this.summaryMaxLength) ||
        this.$t(
          "components.dialogs.audit_measure_dialog.step_summary_too_long",
          {
            maxLength: this.summaryMaxLength,
          }
        ),
    ];
  }

  get summarySuggestion() {
    return this.currentMeasure?.measureSuggestion || null;
  }

  get description(): string {
    return this.lazyDescription ?? this.stepContext?.description ?? "";
  }

  set description(val) {
    this.lazyDescription = val;
  }

  get descriptionMeta(): StepConfigPropertyMeta {
    return providePropertyMeta(
      this.stepConfig.description,
      this.$t(
        "components.dialogs.audit_measure_dialog.step_description_default_label"
      ).toString()
    );
  }

  get descriptionValidationRules() {
    const { rules } = this.descriptionMeta;
    return compilePropertyValidationRules(this.ajv, rules);
  }

  get ajv(): Ajv.Ajv {
    return Ajv();
  }

  cancelEdit() {
    this.lazySummary = null;
    this.lazyDescription = null;
    this.requestPromise = Promise.resolve();
    (this.$refs.form as VForm).validate();
  }

  updateStep() {
    (this.$refs.form as VForm).validate();
    if (!this.isValid) {
      return;
    }
    const patch: Operation[] = [];
    const summary = this.lazySummary?.trim();
    if (summary != null) {
      patch.push(
        {
          op: "test",
          path: "/summary",
          value: this.stepContext?.summary ?? "",
        },
        {
          op: "replace",
          path: "/summary",
          value: summary,
        }
      );
    }
    const description = this.lazyDescription?.trim();
    if (description != null) {
      patch.push(
        {
          op: "test",
          path: "/description",
          value: this.stepContext?.description ?? "",
        },
        {
          op: "replace",
          path: "/description",
          value: description,
        }
      );
    }

    if (patch.length > 0) {
      this.loading = true;
      this.requestPromise = this.patchMeasureStep({
        measureStepId: this.stepId,
        patch,
      });

      this.requestPromise
        .then(res => {
          console.log("updateStep done", res);
          this.lazySummary = summary ?? this.summary;
          this.lazyDescription = description ?? this.description;
        })
        .catch(err => {
          console.error("updateStep failed", err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
