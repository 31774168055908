import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { Bubble, mixins } from "vue-chartjs";
import { cloneDeep, debounce, isEqual } from "lodash";
import { buildDefaultChartData } from "./utils";
import { ChartData, ChartOptions } from "chart.js";
const { reactiveProp } = mixins;

@Component({
  extends: Bubble,
  mixins: [reactiveProp],
})
export default class BubbleChart extends Vue<Bubble> {
  constructor(...props: any[]) {
    super(...props);
  }

  @Prop({
    type: Object,
    default: buildDefaultChartData,
  })
  chartData!: ChartData;

  @Prop({
    type: Object,
    default: () => {
      return {
        scale: {
          ticks: {
            beginAtZero: true,
            max: 100,
          },
        },
      };
    },
  })
  options!: ChartOptions;

  @Prop({
    type: Array,
    default: [],
  })
  plugins!: any[];

  @Watch("options")
  onWatchChartOptions(newValue: ChartOptions, oldValue: ChartOptions) {
    if (!isEqual(oldValue, newValue)) {
      this.rerender();
    }
  }

  @Watch("chartData")
  onWatchChartData(newValue: ChartData, oldValue: ChartData) {
    try {
      const equal = isEqual(oldValue, newValue);
      console.log("onWatchChartData equal=", equal, newValue);
      if (!equal) {
        this.rerender();
      }
    } catch (e) {
      console.error("onWatchChartData", e);
    }
  }

  mounted() {
    // Overwriting base render method with actual data.
    this.rerender();
    this.plugins.forEach(plugin => this.addPlugin(plugin));
  }

  latestChartData: ChartData | null = null;
  latestOptions: ChartOptions | null = null;
  refreshChart = debounce((vm: BubbleChart) => {
    if (vm.latestChartData !== null && vm.latestOptions !== null) {
      this.renderChart(
        // Clone is required cause Chart JS changes Structure "_meta" is written in the datasets
        cloneDeep(vm.latestChartData),
        cloneDeep(vm.latestOptions)
      );
    }
  }, 100);

  rerender() {
    this.latestChartData = this.chartData;
    this.latestOptions = this.options;
    if (this.latestChartData !== null && this.latestOptions !== null) {
      this.refreshChart(this);
    }
  }
}
