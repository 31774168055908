var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$attrs.readonly)?_vm._l((_vm.normalizedConfig),function(config){return _c('div',{key:config.model,staticClass:"text-body-2 mb-4",on:{"dblclick":function($event){return _vm.$emit('edit')}}},[(config.name)?_c('div',{staticClass:"text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$ft(config.name))+" ")]):_vm._e(),_c('div',{staticClass:"text-body-2 text-pre-wrap",class:{
          'grey--text': !config.value,
        },domProps:{"textContent":_vm._s(
          config.value
            ? config.value
            : _vm.$t('components.widgets.audit_reporting.conclusion_placeholder')
        )}})])}):_vm._l((_vm.normalizedConfig),function(config){return _c('v-textarea',_vm._b({key:config.model,ref:"textInput",refInFor:true,class:{
        'mb-4': !!config.name,
      },attrs:{"value":config.value,"label":_vm.$ft(config.name),"hide-details":"auto","placeholder":_vm.$t('components.widgets.audit_reporting.conclusion_edit_placeholder')},on:{"change":function($event){return _vm.onChange(config.model, $event)}}},'v-textarea',_vm.$attrs,false))})],2)}
var staticRenderFns = []

export { render, staticRenderFns }