











import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import {
  SelfAssessmentState,
  selfAssessmentStates,
  SelfAssessmentStatusId,
} from "@auditcloud/shared/lib/utils/audit/auditStatus";

@Component({})
export default class ASnippetSelfAssessmentState extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  status!: SelfAssessmentStatusId;

  get selfAssessmentState(): SelfAssessmentState | undefined {
    return selfAssessmentStates.find(v => v.statusId === this.status);
  }

  get text() {
    return this.selfAssessmentState?.text ?? "";
  }

  get description() {
    return this.selfAssessmentState?.description ?? "";
  }
}
