import ui from "./ui.json";
import data from "./data.json";

import { SchemaMap, FormSchema } from "../../index";
import babor from "./babor";
import baborInspection from "./babor-inspection";
import dgq from "./dgq";
import energy from "./energy";
import hse from "./hse";
import qm from "./qm";
import iso9001_2015 from "./iso-9001-2015";
import qlkgJoined from "./qlkg-joined";
import softwareApproval from "./software-approval";
import supplier from "./supplier";
import vda_6_3 from "./vda-6-3";

const dialogSchema: FormSchema = {
  ui,
  data,
};

const schemaMap: SchemaMap = {
  default: dialogSchema,
  babor,
  "babor-inspection": baborInspection,
  dgq,
  qm,
  energy,
  hse,
  "iso-9001-2015": iso9001_2015,
  "qlkg-joined": qlkgJoined,
  "software-approval": softwareApproval,
  supplier,
  "vda-6-3": vda_6_3,
};
export default schemaMap;
