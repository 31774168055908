













import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  TodoAny,
  TodoActionSignature,
} from "@auditcloud/shared/lib/utils/type-guards";

// Snippets/Components
import MeasureListWidget from "@/components/widgets/MeasureListWidget.vue";
import { ROUTE_NAMES } from "@/routenames";

import { api as appApi } from "@/store/modules/app";

@Component({ components: { MeasureListWidget } })
export default class Measures extends Vue {
  dialogRouteName = ROUTE_NAMES.MEASUREDIALOG;
  numberOfMeasures: number = 0;
  @Action(appApi.actions.setBreadCrumb, { namespace: appApi.namespace })
  setBreadCrumb!: TodoActionSignature;

  @Action(appApi.actions.setMenuPanel, { namespace: appApi.namespace })
  setMenuPanel!: TodoActionSignature;

  @Getter(appApi.getters.getCurrentLanguage, { namespace: appApi.namespace })
  currentLanguage!: TodoAny;

  @Watch("currentLanguage", { immediate: true, deep: true })
  onLaguageChange() {
    this.updateBreadCrumbs();
  }

  created() {
    this.updateBreadCrumbs();
  }

  updateBreadCrumbs() {
    const currentBreadCrumbs = [
      {
        text: this.$t("views.audit.home"),
        to: {
          name: ROUTE_NAMES.HOME,
        },
        activeclass: "",
      },
      {
        text: `${this.$t("components.widgets.audit_overview.measures")}: ${
          this.numberOfMeasures
        }`,
        to: {
          name: ROUTE_NAMES.MEASURES,
        },
        activeclass: "",
      },
    ];
    this.setMenuPanel("breadcrumb");
    this.setBreadCrumb(currentBreadCrumbs);
  }
}
