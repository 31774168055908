import firebase from "firebase/compat/app";
import Vue from "vue";
import { MutationTree, ActionTree, GetterTree, Module } from "vuex";
import { RootState } from "@/store/types";
import { AUTOCOMPLETE_DUMP_FILENAME } from "@auditcloud/shared/lib/constants";

import axios from "axios";

import {
  ApiV0UserAddLoginRequest,
  ApiV0UserAddLoginResponse,
  AutocompleteUser,
} from "@auditcloud/shared/lib/schemas";
import { Dictionary, pick } from "lodash";
import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";
import { userEndpointUrl } from "@/utils/HttpApi";
import {
  UserInfo,
  UserInfoResolver,
  UserRefToItemsJsNormalizer,
} from "@/types/User";
import { SystemRoles } from "@auditcloud/shared/lib/constants/roles";

export class UsersState {
  loadedUsers: Dictionary<AutocompleteUser> = {};
}

function createDisplayNameFilter(needle: string) {
  const preparedNeedle = needle.toLocaleLowerCase();
  return (user: IUserRef) => {
    return user.displayName.toLocaleLowerCase().includes(preparedNeedle);
  };
}

export function filterUsersByName(stack: IUserRef[], needle: string) {
  if (needle.length < 3) {
    return [];
  }
  const displayNameFilter = createDisplayNameFilter(needle);
  return stack.filter(displayNameFilter);
}

export const api = {
  namespace: "users",
  getters: {
    getLoadedUsers: "getLoadedUsers",
    getLoadedUserRefs: "getLoadedUserRefs",
    getUser: "getUser",
    getUserRefToItemJsNormalizer: "getUserRefToItemJsNormalizer",
    getLoginUsers: "getLoginUsers",
    getAuditors: "getAuditors",
  },
  mutations: {
    resetState: "resetState",
    addPerson: "addPerson",
    setLoadedUsers: "setLoadedUsers",
    setLoadedRoles: "setLoadedRoles",
    deleteUser: "deleteUser",
  },
  actions: {
    queryUser: "queryUser",
    queryLoginUser: "queryLoginUser",
    queryAuditor: "queryAuditor",
    loadUsersByApi: "loadUsersByApi",
    loadUsers: "loadUsers",
    createUserInactive: "createUserInactive",
    createLoginUser: "createLoginUser",
    userConnectWithGoogle: "userConnectWithGoogle",
  },
};

const state = (() => new UsersState())();

const mutations: MutationTree<UsersState> = {
  [api.mutations.resetState](state) {
    Object.assign(state, new UsersState());
  },
  [api.mutations.setLoadedUsers](state, payload: Dictionary<AutocompleteUser>) {
    state.loadedUsers = payload;
  },

  [api.mutations.addPerson](
    state,
    person: { id: string; data: AutocompleteUser }
  ) {
    console.log("addPerson", person, state.loadedUsers[person.id]);
    if (typeof state.loadedUsers[person.id] === "undefined") {
      Vue.set(state.loadedUsers, person.id, person.data);
    }
  },
};

const actions: ActionTree<UsersState, RootState> = {
  async [api.actions.queryUser](
    { getters },
    searchInput: string
  ): Promise<IUserRef[]> {
    // Todo: use lunr fulltext search on email and displayName
    const allUsers = getters[api.getters.getLoadedUserRefs] as IUserRef[];

    return filterUsersByName(allUsers, searchInput);
  },
  async [api.actions.queryLoginUser](
    { getters },
    searchInput: string
  ): Promise<IUserRef[]> {
    const users = getters[api.getters.getLoginUsers] as IUserRef[];

    return filterUsersByName(users, searchInput);
  },
  async [api.actions.queryAuditor](
    { getters },
    searchInput: string
  ): Promise<IUserRef[]> {
    const auditors = getters[api.getters.getAuditors] as IUserRef[];

    return filterUsersByName(auditors, searchInput);
  },
  async [api.actions.loadUsersByApi]({ commit, dispatch }) {
    const useRestApi = process.env.VUE_APP_EMULATOR === "true";
    const getUrl = async () => {
      if (useRestApi) {
        return userEndpointUrl("autocomplete-dump");
      } else {
        const storage = firebase.storage();
        const dumpRef = storage.ref(AUTOCOMPLETE_DUMP_FILENAME);
        return (await dumpRef.getDownloadURL()) as string;
      }
    };

    await dispatch("app/setLoading", false, { root: true });

    const res = await axios({
      method: "get",
      url: await getUrl(),
      responseType: "json",
    });

    await dispatch("app/setLoading", false, { root: true });
    const resData = res.data;
    if (!(resData instanceof Object)) {
      console.error("loadUsersByApi failed with ", res.status, res.data);
      return;
    }
    const users = useRestApi ? resData.users : resData;
    if (!(users instanceof Object)) {
      console.error("loadUsersByApi: bad content: ", users);
      return;
    }
    const usersState = users as Dictionary<AutocompleteUser>;
    commit(api.mutations.setLoadedUsers, usersState);
  },
  async [api.actions.loadUsers]({ commit, dispatch }) {
    console.error("Currently not implemented ...");
    throw new Error("Currently not implemented ...");
    /*
    return new Promise((resolve, reject) => {
      dispatch("app/setLoading", false, { root: true });
      firebase
        .firestore()
        .collection("users")
        .get()
        .then(snapshot => {
          const users: TodoAny = [];
          snapshot.forEach(doc => {
            const user = doc.data();
            const key = doc.id;
            users.push({ ...user, id: key });
          });
          console.log("Fetch user data from firebase", users);
          commit(api.mutations.setLoadedUsers, users);
          dispatch("app/setLoading", false, { root: true });
          resolve(true);
        })
        .catch(error => {
          console.log(error);
          dispatch("app/setLoading", false, { root: true });
          reject(false);
        });
    }); */
  },
  async [api.actions.createUserInactive](
    { dispatch, commit },
    payload: { data: { email: string; displayName: string } }
  ): Promise<{ created: boolean; ref: IUserRef }> {
    await dispatch("app/setLoading", false, { root: true });

    const url = userEndpointUrl("add-external");

    const res = await axios.post(url, payload.data);
    console.log("createUserInactive", res);

    const respData = res.data;

    commit(api.mutations.addPerson, pick(respData, ["id", "data"]));

    await dispatch("app/setLoading", false, { root: true });
    return {
      created: respData.created,

      ref: {
        id: respData.id,
        displayName: respData.data.n,
      },
    };
  },
  async [api.actions.createLoginUser](
    { commit, dispatch },
    payloadData: ApiV0UserAddLoginRequest
  ): Promise<ApiV0UserAddLoginResponse> {
    // build endpoint url and post with axios
    const url = userEndpointUrl("add-login");
    // post and return response data
    const res = await axios.post(url, payloadData);
    return res.data;
  },
};

const getters: GetterTree<UsersState, RootState> = {
  [api.getters.getLoadedUsers](state): Dictionary<AutocompleteUser> {
    //TODO: ACHTUNG - Selects benötigen Arrays
    //console.log(state.loadedUsers);
    return state.loadedUsers;
  },
  [api.getters.getLoadedUserRefs](state) {
    return Object.entries(state.loadedUsers).map(([id, user]) => {
      if (typeof id === "string") {
        return {
          id,
          displayName: user.n,
        };
      } else {
        return null;
      }
    });
  },
  [api.getters.getUser](state): UserInfoResolver {
    return (userId: string): null | UserInfo => {
      const user = state.loadedUsers[userId];
      if (user) {
        return {
          displayName: user.n,
          email: user.e,
          roles: user.r,
          id: userId,
        };
      } else {
        return null;
      }
    };
  },
  [api.getters.getUserRefToItemJsNormalizer](
    state
  ): UserRefToItemsJsNormalizer {
    return userRef => {
      if (userRef == null) {
        return null;
      }
      const userId = userRef.id;

      const knownUser = state.loadedUsers[userId];

      const displayName =
        knownUser?.n.trim() || userRef.displayName.trim() || null;
      return displayName;
    };
  },
  [api.getters.getLoginUsers](state): IUserRef[] {
    const flatUsers = Object.entries(state.loadedUsers).filter(([id, user]) => {
      return user.r.includes(SystemRoles.USER);
    });

    return flatUsers.map(([id, user]) => {
      return {
        displayName: user.n,
        id,
      };
    });
  },
  [api.getters.getAuditors](state): IUserRef[] {
    const flat_auditors = Object.entries(state.loadedUsers).filter(
      ([id, user]) => {
        return user.r.includes(SystemRoles.AUDITOR);
      }
    );

    return flat_auditors.map(([id, user]) => {
      return {
        displayName: user.n,
        id,
      };
    });
  },
};

const namespaced: boolean = true;
const modul: Module<UsersState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};

export default modul;

// TODO: Ist das eine clevere Möglichkeit Dummy-User für die Auto-Completes zu importieren?
/*
function usertemplate() {
  return [
    {
      firebase_id: "nQHrH0TunQQqFCzsYYGcVvfgsYx1",
      displayName: "Alexander Stoffers",
      avatar:
        "https://firebasestorage.googleapis.com/v0/b/audit-cloud-5771e.appspot.com/o/static%2Favatars%2F5.jpg?alt=media&token=4fbacfb1-084d-44ff-bab0-44122e39cfef",
      email: "stoffers@productify.eu"
    }
  ];
}
*/
