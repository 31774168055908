import { MutationTree, ActionTree } from "vuex";
import Vue from "vue";
import { WatchedCollection } from "./WatchedCollection";
import { WatchedDocument } from "./WatchedDocument";
import { unlistenerFunction } from "./unlistenerFunction";
import { createCollectionObserver } from "../CollectionObserver";
import { CollectionObserverConfig } from "./CollectionObserverConfig";

const SET_COLLECTION_UNLISTENER = "SET_COLLECTION_UNLISTENER";
const SET_COLLECTION_DOCUMENTS = "SET_COLLECTION_DOCUMENTS";
const SET_COLLECTION_DOCUMENT_METADATA = "SET_COLLECTION_DOCUMENT_METADATA";
const CLEAR_DOCUMENTS = "CLEAR_DOCUMENTS";

const mn = {
  SET_COLLECTION_UNLISTENER,
  SET_COLLECTION_DOCUMENTS,
  SET_COLLECTION_DOCUMENT_METADATA,
  CLEAR_DOCUMENTS,
};

const listenOnCollection = "listenOnCollection";
const clearDocuments = "clearDocuments";

const an = {
  listenOnCollection,
  clearDocuments,
};

export class WatchableCollection<DT, RS> implements WatchedCollection<DT> {
  constructor(
    public readonly collection: string,
    public readonly prefix: string = ""
  ) {}
  Documents: WatchedDocument<DT | null>[] = [];
  Unlistener: unlistenerFunction | null = null;
  loadedNewQuery: boolean = true;
  mutationNames() {
    return mn;
  }
  mutations() {
    const mutations: MutationTree<WatchedCollection<DT>> = {
      [mn.SET_COLLECTION_UNLISTENER](
        state,
        unlistener: null | unlistenerFunction
      ) {
        state.loadedNewQuery = true;
        const prevUnlistener = state.Unlistener;
        state.Unlistener = unlistener;
        if (prevUnlistener) {
          prevUnlistener();
        }
      },
      [mn.SET_COLLECTION_DOCUMENTS](
        state,
        {
          removeDocs,
          modifiedDocs,
        }: {
          removeDocs: string[];
          modifiedDocs: WatchedDocument<DT | null>[];
        }
      ) {
        if (state.loadedNewQuery === true) {
          state.Documents = modifiedDocs;
        } else {
          removeDocs.forEach(docId => {
            const idx = state.Documents.findIndex(v => v.id === docId);
            if (idx > -1) {
              Vue.delete(state.Documents, idx);
            }
          });

          modifiedDocs.forEach(doc => {
            const idx = state.Documents.findIndex(v => v.id === doc.id);
            if (idx > -1) {
              Vue.set(state.Documents, idx, doc);
            } else {
              state.Documents.push(doc);
            }
          });
        }
        state.loadedNewQuery = false;
      },
      [mn.SET_COLLECTION_DOCUMENT_METADATA](
        state,
        updateMetadata: WatchedDocument<null>[]
      ) {
        if (state.loadedNewQuery === true) {
          state.Documents = [];
        } else {
          updateMetadata.forEach(doc => {
            const idx = state.Documents.findIndex(v => v.id === doc.id);
            if (idx) {
              state.Documents[idx].exists = doc.exists;
              state.Documents[idx].metadata = doc.metadata;
            }
          });
        }
      },
      [mn.CLEAR_DOCUMENTS](state, payload) {
        const prev_unlistener = state.Unlistener;
        // To prevent that problems with the execution order of the components lifecycle hook will messup the audits watcher
        if ((payload && payload === prev_unlistener) || payload === null) {
          state.Unlistener = null;
          state.Documents = [];
          if (prev_unlistener) {
            prev_unlistener();
          }
        }
      },
    };
    return mutations;
  }
  actionNames() {
    return an;
  }
  actions() {
    const COLLECTION_NAME = this.collection;
    const actions: ActionTree<WatchedCollection<DT>, RS> = {
      async [an.listenOnCollection](context, config: CollectionObserverConfig) {
        const unlistener = createCollectionObserver(
          COLLECTION_NAME,
          config,
          updateData => {
            context.commit(mn.SET_COLLECTION_DOCUMENTS, updateData);
          },
          updateData => {
            context.commit(mn.SET_COLLECTION_DOCUMENT_METADATA, updateData);
          },
          () => {}
        );
        context.commit(mn.SET_COLLECTION_UNLISTENER, unlistener);
        return unlistener;
      },
      [an.clearDocuments]({ commit }, payload) {
        commit(mn.CLEAR_DOCUMENTS, payload);
      },
    };
    return actions;
  }
}
