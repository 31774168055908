import { ActionTree } from "vuex";
import { Dictionary, isString, omit, partition, toPairs } from "lodash";
import { AuditDimensionResultState, AuditResultState } from "./types";
import { mutationNames as mn } from "./mutations";
import { RootState } from "../../types";
import { api as auditApi } from "../audit";
import { getterNs } from "@/utils/VuexHelper";
import {
  AuditItem,
  Finding,
  TranslateableText,
} from "@auditcloud/shared/lib/schemas";
import { makeIdable } from "@auditcloud/shared/lib/utils/transform/makeIdable";
import { AuditItemTypeConfig } from "@auditcloud/shared/lib/types/Configuration/defaults";
import { idable, nullable } from "@auditcloud/shared/lib/types/common";
import {
  buildFlatAuditItemResultData,
  calcAuditScore,
  calcAuditScoreByCategory,
  CategoryResolver,
  checkAuditAnswerConstraints,
  ConstraintResult,
  createAuditItemTypeResolver,
  createFindingTypeResolver,
  FindingsResolver,
} from "@auditcloud/shared/lib/auditResult/utils";
import { getterNames as gn, ScoreCalcRuleId, ScoreCalcRules } from "./getters";
import { FindingTypeConfig } from "@auditcloud/shared/lib/types/Configuration/Configuration";
import { PointsCalculationStrategy } from "@auditcloud/shared/lib/types/AuditScore/types";
import { AuditItemCategoryMapStrict } from "@auditcloud/shared/lib/types/AuditItemCategory";
import { AuditClassClient } from "@auditcloud/shared/lib/types/AuditClass";
import { calcResultSteps } from "@auditcloud/shared/lib/auditResult/calcChainFactoryFunctions";
import { CategoryKoDowngradeHandler } from "@auditcloud/shared/lib/auditResult/classes/CategoryKoDowngradeHandler";
import { AuditItemDimensionsMap } from "@auditcloud/shared/lib/types/Audit/types";
import { StateGetterMutationPayload } from "../reporting/types";
import {
  AuditResultSteps,
  FlatAuditItemResultData,
} from "@auditcloud/shared/lib/types/AuditResult";
import { AuditItemWithId } from "@auditcloud/shared/lib/utils/audit/types";
import {
  CategeoryScoreResultRO,
  ScoreResultRO,
} from "@auditcloud/shared/lib/auditResult/types";
import { AuditItemProperties, DimensionMap } from "../audit/types";

const loadAuditResult = "loadAuditResult";

const n = { loadAuditResult };

const buildFindingsResolver = (
  rootGetters: Dictionary<any>,
  findingDimensionsFilter: (finding: idable<Finding>) => boolean
): FindingsResolver => {
  const currentAuditId = rootGetters[
    getterNs(auditApi, auditApi.getters.getAuditId)
  ] as string | null;

  const findingsMap = rootGetters[
    getterNs(auditApi, auditApi.getters.getFindingsMap)
  ] as Dictionary<Finding | undefined>;

  const auditItemId2FindingIds = rootGetters[
    getterNs(auditApi, auditApi.getters.getAuditItemId2FindingIdsMap)
  ] as Dictionary<string[] | undefined>;

  return async (
    auditId: string,
    auditItemId: string,
    dimensionId: string | null,
    useUndimensiondFindingsInGenericDimension: boolean
  ) => {
    if (auditId !== currentAuditId) {
      return null;
    }

    const findingIds = auditItemId2FindingIds[auditItemId] ?? null;
    if (!findingIds) {
      return null;
    }

    const [unknownFindingIds, findings] = partition(
      findingIds.map(findingId => {
        const finding = findingsMap[findingId];
        if (finding) {
          return makeIdable([findingId, finding]);
        } else {
          return findingId;
        }
      }),
      isString
    );

    if (unknownFindingIds.length > 0) {
      throw new Error(
        `failed to resolve findingIds [${unknownFindingIds.join(
          ", "
        )}] for audit item ${auditItemId} in audit ${auditId}`
      );
    }

    return findings.filter(findingDimensionsFilter);
  };
};

const buildCategoryResolver = (
  rootGetters: Dictionary<any>
): CategoryResolver => {
  const auditItemCategoryMap = rootGetters[
    getterNs(auditApi, auditApi.getters.getAuditCategoryMapping)
  ] as AuditItemCategoryMapStrict;

  return async (categoryId: string) => {
    const category = auditItemCategoryMap[categoryId] ?? null;
    if (category) {
      return omit(category, "subcategories");
    } else {
      return null;
    }
  };
};

type AuditItemDimensionsResolver = (auditItemId: string) => null | string[];

const buildAuditItemDimensionResolver = (
  rootGetters: Dictionary<any>
): AuditItemDimensionsResolver => {
  const auditItemDimensionsMap = rootGetters[
    getterNs(auditApi, auditApi.getters.getAuditItemDimensionsMap)
  ] as AuditItemDimensionsMap;

  return (auditItemId: string) => {
    const dimensions = auditItemDimensionsMap[auditItemId] ?? null;
    return dimensions;
  };
};

function buildFilterForAuditItemsByDimension(
  dimensionsResolver: AuditItemDimensionsResolver,
  dimensionId: null | string,
  useUndimensiondFindingsInGenericDimension: boolean
): (auditItem: idable<AuditItem>) => boolean {
  return auditItem => {
    const auditItemId = auditItem.id;
    const dimension = dimensionsResolver(auditItemId);
    return (
      (useUndimensiondFindingsInGenericDimension === false &&
        dimension === null) ||
      (dimensionId === null && dimension === null) ||
      (dimension !== null &&
        dimensionId !== null &&
        dimension.includes(dimensionId))
    );
  };
}

function loadDimensionsInfo(rootGetters: Dictionary<any>): {
  useUndimensiondFindingsInGenericDimension: boolean;
  dimensionsMap: DimensionMap;
} {
  const auditClass = rootGetters[
    getterNs(auditApi, auditApi.getters.getAuditClass)
  ] as nullable<AuditClassClient>;
  return {
    dimensionsMap:
      rootGetters[getterNs(auditApi, auditApi.getters.getAuditDimensionsMap)],
    useUndimensiondFindingsInGenericDimension:
      auditClass?.useUndimensiondFindingsInGenericDimension ?? false,
  };
}

const actions: ActionTree<AuditResultState, RootState> = {
  async [n.loadAuditResult](
    { rootGetters, commit, getters },
    { lang, auditId }: { lang: string; auditId: string }
  ) {
    commit(mn.CLEAR_RESULT);

    try {
      const auditItemTypeConfig = rootGetters[
        getterNs(auditApi, auditApi.getters.getAuditItemTypeConfig)
      ] as nullable<AuditItemTypeConfig>;
      if (!auditItemTypeConfig) {
        throw new Error(`Missing AuditItemTypeConfig for audit ${auditId}`);
      }
      const auditItemTypeResolver =
        createAuditItemTypeResolver(auditItemTypeConfig);

      const findingTypeConfig = rootGetters[
        getterNs(auditApi, auditApi.getters.getFindingTypeConfig)
      ] as nullable<FindingTypeConfig>;
      if (!findingTypeConfig) {
        throw new Error(`Missing FindingTypeConfig for audit ${auditId}`);
      }

      const auditItemDimensionResolver =
        buildAuditItemDimensionResolver(rootGetters);
      const dimensionsInfo = loadDimensionsInfo(rootGetters);
      const findingTypeResolver = createFindingTypeResolver(findingTypeConfig);
      const findingsResolver = buildFindingsResolver(rootGetters, () => true);
      const categoryResolver = buildCategoryResolver(rootGetters);

      const pointsCalculationStrategy = rootGetters[
        getterNs(auditApi, auditApi.getters.getAuditReportCalcSchema)
      ] as nullable<PointsCalculationStrategy>;

      if (!pointsCalculationStrategy) {
        throw new Error(
          `Missing PointsCalculationStrategy for audit ${auditId}`
        );
      }

      const auditItems = rootGetters[
        getterNs(auditApi, auditApi.getters.getMappedAuditItems)
      ] as Dictionary<AuditItem>;
      const auditItemProperties = rootGetters[
        getterNs(auditApi, auditApi.getters.getAuditItemProperties)
      ] as Map<AuditItemWithId["id"], AuditItemProperties>;

      const activeAuditItems = toPairs(auditItems)
        .map(makeIdable)
        .filter(ai => auditItemProperties.get(ai.id)?.isConsidered);

      const scoreCalcRuleId = getters[gn.getScoreCalcRuleId] as ScoreCalcRuleId;
      const scoreCalcRules = getters[gn.getScoreCalcRules] as ScoreCalcRules;

      const categoryKoDowngradeHandler = new CategoryKoDowngradeHandler(
        scoreCalcRuleId
      );

      const flatData: FlatAuditItemResultData[] = await Promise.all(
        activeAuditItems.map(ai => {
          return buildFlatAuditItemResultData(
            auditId,
            ai,
            pointsCalculationStrategy,
            findingsResolver,
            auditItemTypeResolver,
            findingTypeResolver,
            categoryResolver,
            categoryKoDowngradeHandler,
            null,
            false
          );
        })
      );

      const constraintResults: StateGetterMutationPayload<ConstraintResult[]> =
        {
          type: "data",
          val: checkAuditAnswerConstraints(scoreCalcRules, flatData),
        };
      commit(mn.SET_ANSWER_CONSTRAINT_RESULT_DATA, constraintResults);

      const flatResult: StateGetterMutationPayload<FlatAuditItemResultData[]> =
        {
          type: "data",
          val: flatData,
        };

      commit(mn.SET_FLAT_RESULT_DATA, flatResult);

      const categoryScoreResult: StateGetterMutationPayload<
        CategeoryScoreResultRO[]
      > = {
        type: "data",
        val: await calcAuditScoreByCategory(
          flatData,
          findingTypeConfig,
          auditItemTypeConfig,
          pointsCalculationStrategy.name,
          scoreCalcRules.scoringConfig,
          lang
        ),
      };
      commit(mn.SET_CATEGORY_SCORE_DATA, categoryScoreResult);

      const scoreResult: StateGetterMutationPayload<ScoreResultRO> = {
        type: "data",
        val: await calcAuditScore(
          flatData,
          findingTypeConfig,
          auditItemTypeConfig,
          pointsCalculationStrategy.name,
          scoreCalcRules.scoringConfig
        ),
      };
      commit(mn.SET_SCORE_RESULT_DATA, scoreResult);

      const resultSteps: StateGetterMutationPayload<AuditResultSteps> = {
        type: "data",
        val: calcResultSteps(
          scoreCalcRules,
          scoreResult.val,
          categoryScoreResult.val,
          flatData,
          categoryKoDowngradeHandler,
          lang
        ),
      };
      commit(mn.SET_RESULT_STEP_DATA, resultSteps);

      const generic: Array<[null, { name: TranslateableText; id: null }]> =
        dimensionsInfo.useUndimensiondFindingsInGenericDimension
          ? [
              [
                null,
                {
                  id: null,
                  name: "Generic",
                },
              ],
            ]
          : [];

      const dimensionsResult = await Promise.all(
        [...toPairs(dimensionsInfo.dimensionsMap), ...generic].map(
          async ([dimensionId, dimension]) => {
            const name = dimension?.name ?? "";

            const filterForAuditItemsByDimension =
              buildFilterForAuditItemsByDimension(
                auditItemDimensionResolver,
                dimensionId,
                dimensionsInfo.useUndimensiondFindingsInGenericDimension
              );

            const categoryKoDowngradeHandlerForDimension =
              new CategoryKoDowngradeHandler(scoreCalcRuleId);

            const flatDataByDimension: FlatAuditItemResultData[] =
              await Promise.all(
                activeAuditItems
                  .filter(filterForAuditItemsByDimension)
                  .map(ai => {
                    const filterFindingsByDimension = (
                      finding: idable<Finding>
                    ): boolean => {
                      const filteredAuditItemRefs =
                        finding.auditItemRefs.filter(ref => {
                          if (ref.auditItemId === ai.id) {
                            const findingDimension = ref.dimensions;

                            return (
                              (dimensionsInfo.useUndimensiondFindingsInGenericDimension ===
                                false &&
                                findingDimension === null) ||
                              (findingDimension === null &&
                                dimensionId === null) ||
                              (dimensionId !== null &&
                                findingDimension !== null &&
                                findingDimension.includes(dimensionId))
                            );
                          } else {
                            return false;
                          }
                        });

                      return filteredAuditItemRefs.length > 0;
                    };

                    const dimensionsFindingResolver = buildFindingsResolver(
                      rootGetters,
                      filterFindingsByDimension
                    );

                    return buildFlatAuditItemResultData(
                      auditId,
                      ai,
                      pointsCalculationStrategy,
                      dimensionsFindingResolver,
                      auditItemTypeResolver,
                      findingTypeResolver,
                      categoryResolver,
                      categoryKoDowngradeHandlerForDimension,
                      dimensionId,
                      dimensionsInfo.useUndimensiondFindingsInGenericDimension
                    );
                  })
              );

            const categoryScoreResultByDimension =
              await calcAuditScoreByCategory(
                flatDataByDimension,
                findingTypeConfig,
                auditItemTypeConfig,
                pointsCalculationStrategy.name,
                scoreCalcRules.scoringConfig,
                lang
              );

            const scoreResultByDimension = await calcAuditScore(
              flatDataByDimension,
              findingTypeConfig,
              auditItemTypeConfig,
              pointsCalculationStrategy.name,
              scoreCalcRules.scoringConfig
            );

            const resultStepsByDimension = calcResultSteps(
              scoreCalcRules,
              scoreResultByDimension,
              categoryScoreResultByDimension,
              flatDataByDimension,
              categoryKoDowngradeHandler,
              lang
            );
            return new AuditDimensionResultState(
              {
                name,
                id: dimensionId ?? "",
              },
              flatDataByDimension,
              scoreResultByDimension,
              categoryScoreResultByDimension,
              resultStepsByDimension
            );
          }
        )
      );
      const dimensionData: StateGetterMutationPayload<typeof dimensionsResult> =
        {
          type: "data",
          val: dimensionsResult,
        };
      console.log("RESULT SET_DIMENSIONS_RESULT", dimensionData);

      commit(mn.SET_DIMENSIONS_RESULT, dimensionData);
    } catch (err) {
      const result: StateGetterMutationPayload<any> = {
        type: "error",
        val: {
          statusCode: 1,
          message: String(err),
        },
      };
      commit(mn.SET_RESULT_STEP_DATA, result);
      throw err;
    }
  },
};

export { n as actionNames, actions };
