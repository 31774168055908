



























































import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";

import {
  typeIsTranslateableText,
  typeIsMultilingualText,
} from "@auditcloud/shared/lib/types/common";
import { TranslateableText } from "@auditcloud/shared/lib/schemas";
import { isString } from "lodash";

function emptyHandler() {}
@Component({})
export default class ATranslateableTextInput extends Vue {
  xValue: TranslateableText = "";
  activeLocale: string | null = null;
  menuList: { id: string; title: string }[] = [];
  menuHandler: (locale: string) => void = emptyHandler;

  @Prop({
    type: Boolean,
    default: false,
  })
  multiline!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  label!: string;

  @Prop({
    type: [String, Object],
    default: "",
    validator: typeIsTranslateableText,
  })
  value!: TranslateableText;

  @Watch("value", {
    immediate: true,
  })
  onValueChanged(newValue: TranslateableText) {
    console.log("onValueChanged", newValue, this.activeLocale);

    if (typeIsMultilingualText(newValue) && this.activeLocale === null) {
      const activeLocales = Object.keys(newValue).sort();
      this.activeLocale = activeLocales[0];
    } else if (isString(newValue)) {
      this.activeLocale = null;
    }
    this.xValue = newValue;
  }

  changed(eventData: string) {
    console.log("changed", eventData, this.activeLocale);
    if (this.isSimple) {
      this.xValue = eventData;
      this.$emit("input", eventData);
    } else if (
      typeof this.activeLocale === "string" &&
      this.xValue instanceof Object
    ) {
      const newVal = { ...this.xValue, [this.activeLocale]: eventData };
      this.xValue = newVal;
      this.$emit("input", newVal);
    }
  }

  get inputLabel() {
    if (this.activeLocale === null) {
      return this.label;
    } else {
      return (
        this.label + " [" + this.activeLocale + "/" + this.locales.length + "]"
      );
    }
  }

  get isSimple() {
    return typeof this.xValue === "string";
  }

  get toggleTranslationTooltip() {
    return this.$t(
      this.isSimple
        ? "components.controls.translatable_text_input.tooltip_enable_translations"
        : "components.controls.translatable_text_input.tooltip_disable_translations"
    );
  }

  get locales() {
    return ["de", "en"]; // this.isSimple ? [] : Object.keys(this.xValue).sort();
  }

  get editableText() {
    if (this.isSimple) {
      return this.xValue;
    } else if (this.xValue instanceof Object) {
      return (
        this.xValue[this.activeLocale !== null ? this.activeLocale : "en"] || ""
      );
    } else {
      return "";
    }
  }

  get activeComponent() {
    return this.multiline ? "v-textarea" : "v-text-field";
  }

  get title() {
    return this.xValue instanceof Object && "en" in this.xValue
      ? this.xValue.en
      : "";
  }

  get translationIcon() {
    return this.isSimple ? "mdi-translate" : "mdi-translate-off";
  }

  get localeIcon() {
    return this.isSimple ? undefined : "mdi-web-box";
  }

  toggleTranslation(activator: any, event: MouseEvent) {
    console.log("toggleTranslation", activator, event);
    this.menuList = this.locales.map(id => {
      return {
        id,
        title: this.$t(
          this.isSimple
            ? "components.controls.translatable_text_input.setlect_translate"
            : "components.controls.translatable_text_input.untranslate",
          {
            locale: id,
          }
        ).toString(),
      };
    });
    this.menuHandler = (locale: string) => {
      console.log("selectLocale:toggleTranslation", locale);

      if (typeof this.xValue === "string") {
        const text = this.xValue;

        const newVal = {
          en: "",
          [locale]: text,
        };
        this.xValue = newVal;
        this.changed(text);

        this.activeLocale = locale;
      } else if (typeIsMultilingualText(this.xValue)) {
        const text = this.xValue[locale] || "";
        this.xValue = text;
        this.changed(text);
        this.activeLocale = null;
      }

      this.menuList = [];
      this.menuHandler = emptyHandler;
    };
    activator.click(event);

    /*        "untranslate": "Discarge all translation, exept {locale}",
    "setlect_translate": "Set language of text to {locale}" */
  }

  selectLocale(activator: any, event: MouseEvent) {
    console.log("selectLocale", activator, event);
    this.menuList = this.locales.map(id => {
      return {
        id,
        title: this.$t(
          "components.controls.translatable_text_input.select_locale",
          { locale: id }
        ).toString(),
      };
    });
    this.menuHandler = (locale: string) => {
      console.log("selectLocale:menuHandler", locale);
      this.activeLocale = locale;
      this.menuList = [];
      this.menuHandler = emptyHandler;
    };
    activator.click(event);
  }
}
