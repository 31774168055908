





































































import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";

@Component({})
export default class AWidget extends Vue {
  //missingDefault
  @Prop({
    type: String,
  })
  title!: string;
}
