





















import { StatedGetter, GetterState } from "@/store/modules/reporting/types";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class StatGetter extends Vue {
  @Prop({
    default: [new StatedGetter<any>()],
    type: Array,
  })
  statedGetters!: StatedGetter<any>[];

  get count() {
    return this.statedGetters.length;
  }

  get allGettersReady() {
    return this.statedGetters.every(cur => {
      return cur.status === GetterState.FINISHED;
    });
  }

  get hasError() {
    return this.statedGetters.some(cur => {
      return cur.status === GetterState.ERROR;
    });
  }

  get errorMessages() {
    return this.statedGetters
      .filter(cur => cur.status === GetterState.ERROR)
      .map(cur => cur.error?.message || "An unknown error occured.");
  }
}
