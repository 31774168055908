import {
  PagingCollectionObserver,
  PagingCollectionObserverState,
} from "@/utils/firestore/PagingCollectionObserver";
import { mapValues } from "lodash";
import {
  AuditsQueryConfig,
  DateRange,
  MeasuresQueryConfig,
  State,
} from "./types";

const mutations = {
  UPDATE_AUDITS_QUERY_CONFIG(
    state: State,
    auditsQueryConfigChanges: AuditsQueryConfig
  ) {
    state.auditsQueryConfigChanges = {
      ...state.auditsQueryConfigChanges,
      ...auditsQueryConfigChanges,
    };
  },
  UPDATE_AUDITS_OBSERVER(
    state: State,
    observer: null | PagingCollectionObserver
  ) {
    state.auditsObserver?.unsubscribe();
    state.auditsObserver = observer;
  },
  UPDATE_AUDITS_STATE(state: State, audits: PagingCollectionObserverState) {
    state.audits = audits;
  },
  UPDATE_AUDITS_LIMIT(state: State, newLimit: number) {
    state.auditsLimit = newLimit;
  },

  UPDATE_CALENDAR_AUDITS_DATE_RANGE(state: State, dateRange: DateRange) {
    state.calendarAuditsDateRange = dateRange;
  },
  UPDATE_CALENDAR_AUDITS_OBSERVER(
    state: State,
    observer: null | PagingCollectionObserver
  ) {
    state.calendarAuditsObserver?.unsubscribe();
    state.calendarAuditsObserver = observer;
  },
  UPDATE_CALENDAR_AUDITS_STATE(
    state: State,
    calendarAudits: PagingCollectionObserverState
  ) {
    state.calendarAudits = calendarAudits;
  },

  UPDATE_MEASURES_QUERY_CONFIG(
    state: State,
    measuresQueryConfigChanges: Partial<MeasuresQueryConfig>
  ) {
    state.measuresQueryConfigChanges = {
      ...state.measuresQueryConfigChanges,
      ...measuresQueryConfigChanges,
    };
  },
  UPDATE_MEASURES_OBSERVER(
    state: State,
    observer: null | PagingCollectionObserver
  ) {
    state.measuresObserver?.unsubscribe();
    state.measuresObserver = observer;
  },
  UPDATE_MEASURES_STATE(state: State, measures: PagingCollectionObserverState) {
    state.measures = measures;
  },
  UPDATE_MEASURES_LIMIT(state: State, newLimit: number) {
    state.measuresLimit = newLimit;
  },
};

const n = mapValues(mutations, (_, key) => key);

export { n as mutationNames, mutations };
