






import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { State, Action, Getter, Mutation } from "vuex-class";
import {
  TodoMap,
  TodoArray,
  TodoAny,
  TodoActionSignature,
  TodoMutationSignature,
} from "@auditcloud/shared/lib/utils/type-guards";

import { mapGetters } from "vuex";
import { api } from "@/store/modules/audit";
import { AuditWorkflowStepInfo } from "@/store/modules/audit/types";
import { typeIsTranslateableText } from "@auditcloud/shared/lib/types/common";

@Component({})
export default class SnippetWorkflow extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  status!: string;

  @Getter(api.getters.getWorkflowSteps, { namespace: api.namespace })
  workflowSteps!: AuditWorkflowStepInfo[];

  get description() {
    const status_item = this.workflowSteps.find(item => {
      return item.statusId === this.status;
    });

    if (typeof status_item === "undefined") {
      console.error(
        "status description unmappable",
        this.status,
        this.workflowSteps
      );
      return this.status;
    } else {
      return this.$t(status_item.description).toString();
    }
  }
}
