





















































































































import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import ASignInCard from "@auditcloud/components/layouts/ASignInCard.vue";
import { State, Action, Getter, Mutation } from "vuex-class";

import { mapActions } from "vuex";
import { api } from "@/store/modules/users";

import firebase from "firebase/compat/app";
import { ROUTE_NAMES, SIGNIN_PATH } from "@/routenames";
import { AUDIT_CLOUD_SUBDIR } from "@auditcloud/shared/lib/configuration/apps";
import { isString } from "lodash";
import { api as confApi } from "@/store/modules/configuration";
import { ConfigurationSystemDoc } from "@auditcloud/shared/lib/schemas";
import { NEXTAUDIT_SUPPORT_MAIL } from "@auditcloud/shared/lib/constants";

@Component({ components: { ASignInCard } })
export default class ResetPassword extends Vue {
  email: String = "";
  password: String = "";
  alert: boolean = false;
  passwordResetInitiated = false;

  @Getter(confApi.getters.system, { namespace: confApi.namespace })
  systemConfig!: ConfigurationSystemDoc;

  resetPassword(emailAddress) {
    const isLocalEmulator = process.env.VUE_APP_EMULATOR === "true";
    const appSubDirSuffix = isLocalEmulator ? "" : `/${AUDIT_CLOUD_SUBDIR}`;
    const redirectBaseUrl = `${window.location.origin}${appSubDirSuffix}`;
    const redirectQuery = this.$route.query.redirect;
    const redirectUrlSuffix =
      isString(redirectQuery) && !redirectQuery.startsWith(SIGNIN_PATH)
        ? redirectQuery
        : "/";
    const redirectUrl = `${redirectBaseUrl}${redirectUrlSuffix}`;

    firebase
      .auth()
      .sendPasswordResetEmail(emailAddress, { url: redirectUrl })
      .then(() => {
        console.log("Password Reset Successfull");
        this.passwordResetInitiated = true;
      })
      .catch(error => {
        console.log("Password Reset failed", error);
        this.navigateToSignin();
      });
  }
  get error() {
    return {};
    //return this.$store.state.error;
  }
  get loading() {
    return {};
    //return this.$store.state.loading;
  }

  get welcomeMessage() {
    return this.$t("views.reset_password.welcome_text");
  }

  get supportMail() {
    return this.systemConfig.supportMail ?? NEXTAUDIT_SUPPORT_MAIL;
  }

  get logos() {
    const logos: Array<{ url: string; src: string }> = [
      {
        url: "https://www.next-audit.de",
        src: require("../assets/logo_white.png"),
      },
    ];
    return logos;
  }

  navigateToSignin() {
    this.$router.push({
      name: ROUTE_NAMES.SIGNIN,
      query: {
        redirect: this.$route.query.redirect,
      },
    });
  }

  resendMail() {
    this.passwordResetInitiated = false;
  }

  @Watch("user")
  onWatchUser(value) {
    //console.log(value)
    if (value !== null && value !== undefined) {
      this.$router.push("/");
    }
  }
  @Watch("error")
  onWatchError(value) {
    if (value) {
      this.alert = true;
    }
  }
  @Watch("alert")
  onWatchAlert(value) {
    if (!value) {
      //this.$store.commit("setError", null);
    }
  }
}
