import firebase from "firebase/compat/app";
import { ActionTree } from "vuex";
import { RootState } from "../../types";
import { ct } from "@/plugins/ContentTranslation";
import {
  CurrentAuditState,
  DocumentType,
  DocumentAuditItemsType,
  AuditLoadingState,
  AuditWorkflowStepInfo,
  AuditListsSettings,
  ActivateExternalMeasuresParams,
  typeIsActivateExternalMeasuresParams,
  MinimalTemplate,
  PreparationStates,
} from "./types";
import { mutationNames as mn } from "./mutations";
import { getterNames as gn } from "./getters";
import {
  Dictionary,
  cloneDeep,
  omit,
  toPairs,
  chain,
  isString,
  forIn,
  uniq,
  isPlainObject,
  keys,
  difference,
  flatten,
  fromPairs,
  pick,
  isEqual,
  forEach,
  uniqWith,
  values,
  pickBy,
  map,
  size,
} from "lodash";
import axios, { AxiosError } from "axios";
import Ajv from "ajv";

import { enrichAuditClient, AuditMetadataClient } from "@/types/Audit";
import {
  expectType,
  updateAuditItemsTags,
  updateManuallyConsideredAuditItem,
  updateQuestionNote,
  updateSelfAssessmentEnabledAuditItems,
  buildTransactionalAuditMetadataLoader,
  buildTransactionalAuditResultLoader,
  calculateTotalConsideredAuditItemIds,
  calcPreselectionFilterBasedOnTheAuditMetadata,
  extractSignificantCategories,
  buildFilterTreeFromAuditItems,
  disableEmptyFilters,
} from "./utils";
import { vdu, rdu, rdc } from "../user";

import {
  api as measuresApi,
  Actions as MeasuresActions,
} from "@/store/modules/measures";
import { api as auditsApi } from "@/store/modules/audits";

import { getterNs, logXStored, actionNs } from "@/utils/VuexHelper";
import {
  createFindingPath,
  createAuditMediaPath,
  createAuditItemPath,
  createFindingMediaPath,
  fieldPath,
  createAuditPath,
  createAuditItemsPath,
  createFindingsPath,
  createAnnotationPath,
} from "@auditcloud/shared/lib/utils/firestorePathHelper";
import { v4 as uuidv4, v5 as uuidv5, version as uuidVersion } from "uuid";

import { api as confApi } from "@/store/modules/configuration";
import { FormSchemaDynamic, api as schemasApi } from "@/store/modules/schemas";

import { FindingTypeMap } from "@auditcloud/shared/lib/types/ItemTypes";
import { createError } from "@/utils/Errors";
import {
  nullable,
  CollectionNames,
  idable,
  FieldPartNames,
  DocumentNames,
} from "@auditcloud/shared/lib/types/common";

import {
  attachFilesToEntity,
  EntryAttachmentsVuexApi,
} from "@/utils/attachFileToEntity";

import { AuditItemCategory } from "@auditcloud/shared/lib/types/AuditItemCategory";

import {
  createDocumentObserver,
  DocumentUpdateHandlerData,
  WatchedDocumentStates,
} from "@/utils/firestore";
import { AuditPermissions } from "@auditcloud/shared/lib/utils/aclHelpers";
import { isGenericMeasureReader } from "@auditcloud/shared/lib/utils/aclHelpers";
import { toStoragePath, getDownloadLinkForAttachment } from "@/utils/storage";
import {
  AuditItem,
  AuditResultDoc,
  AuditStandardRef,
  Finding,
  ReportSchema,
  Annotation,
  ApiV0FileManagementDownloadRequest,
  AttachmentMetadataAudit,
  AttachmentMetadataDownloadFinding,
  AuditMetadataDoc,
  TemplateDoc,
  CategoryLevel,
  ApiV0AuditSharesPostShareRequest,
  ApiV0AuditSharesPutShareRequest,
  ApiV0CreateMeasureProcessesForAuditRequest,
} from "@auditcloud/shared/lib/schemas";
import {
  typeIsAuditResultDoc,
  typeIsApiV0AuditSharesPostShareRequest,
  typeIsApiV0AuditSharesPutShareRequest,
} from "@auditcloud/shared/lib/schemas/type-guards";
import { REV_KEY } from "@auditcloud/shared/lib/types/VersionedDocument";
import { AuditStatusId } from "@auditcloud/shared/lib/constants";
import { ActivityVerbs } from "@/utils/activityCreator";
import { ListsConfigMap } from "@auditcloud/shared/lib/types/Configuration/types";
import { AuditItemDimensionsMap } from "@auditcloud/shared/lib/types/Audit/types";
import { extractCurrentUserRef } from "../user/utils";
import { typeIsArrayOf } from "@auditcloud/shared/lib/utils/type-guards";
import { Filter, Aggregation } from "@auditcloud/shared/lib/utils/filter/types";
import {
  AuditItemWithId,
  QuestionNoteWithId,
} from "@auditcloud/shared/lib/utils/audit/types";
import { calculateFilteredItemIds } from "@auditcloud/shared/lib/utils/filter/utils";
import { AuditClassClient } from "@auditcloud/shared/lib/types/AuditClass";
import {
  FILTER_CATEGORY,
  FILTER_LABELS,
} from "@auditcloud/shared/lib/utils/filter/AuditItemListManipulatorIds";
import { Result, err, ok } from "neverthrow";
import { MeasurePolicyViolation } from "@auditcloud/shared/lib/utils/audit/measurePolicies";
import { createMeasures } from "@/utils/services/measuresService";
import { auditsEndpointUrl } from "@/utils/HttpApi";
import { AsyncHandlerFunctionResponse } from "../asyncHandler";

const setAudit = "setAudit";
const clearAudit = "clearAudit";
const changeAuditName = "changeAuditName";
const updateCompanyMetadata = "updateCompanyMetadata";
const updatePreselection = "updatePreselection";
const createAudit = "createAudit";
const updateAuditMetadata = "updateAuditMetadata";
const clearTemplate = "clearTemplate";
const useTemplate = "useTemplate";
const storeFinding = "storeFinding";
const storeMeasure = "storeMeasure";
const deleteFinding = "deleteFinding";
const deleteAllUnassignedFindings = "deleteAllUnassignedFindings";
const updateAttachmentComment = "updateAttachmentComment";
const downloadFileFromEntry = "downloadFileFromEntry";
const attachFilesToEntry = "attachFilesToEntry";
const attachFileToFinding = "attachFileToFinding";
const updateFindingAttachment = "updateFindingAttachment";
const deleteFileFromEntry = "deleteFileFromEntry";

const activateConflictFilter = "activateConflictFilter";
const activateUnansweredMaturitiesFilter = "activateUnansweredMaturitiesFilter";

const setAuditFormData = "setAuditFormData";
const updateSingleMetadataField = "updateSingleMetadataField";
const changeAuditStatusUnrestricted = "changeAuditStatusUnrestricted";
const changeAuditProgram = "changeAuditProgram";
const changeToNextAuditStatus = "changeToNextAuditStatus";
const cloneVdaProcessQuestions = "cloneVdaProcessQuestions";
const moveFinding = "moveFinding";

const setDefaultAnswerForUnanswerdAuditItems =
  "setDefaultAnswerForUnanswerdAuditItems";

const addAuditShare = "addAuditShare";
const updateAuditShare = "updateAuditShare";
const activateExternalMeasures = "activateExternalMeasures";
const completeSelfAssessment = "completeSelfAssessment";

const storeAnnotation = "storeAnnotation";
const deleteAnnotation = "deleteAnnotation";

const setSelectedTemplateIds = "setSelectedTemplateIds";
const togglePreselectionFilter = "togglePreselectionFilter";
const clearPreselectionFilter = "clearPreselectionFilter";
const setAllPreselectionFilter = "setAllPreselectionFilter";
const manuallyAddToConsideredAuditItems = "manuallyAddToConsideredAuditItems";
const manuallyRemoveFromConsideredAuditItems =
  "manuallyRemoveFromConsideredAuditItems";
const manuallyAddAuditItemsToSelfAssessment =
  "manuallyAddAuditItemsToSelfAssessment";
const manuallyRemoveAuditItemsFromSelfAssessment =
  "manuallyRemoveAuditItemsFromSelfAssessment";
const updateTags = "updateTags";
const clearTags = "clearTags";
const setNote = "setNote";
const deleteNote = "deleteNote";
const switchToPreparationState = "switchToPreparationState";
const createRequiredMeasuresForPolicies = "createRequiredMeasuresForPolicies";

const n = {
  setAudit,
  clearAudit,
  changeAuditName,
  updateCompanyMetadata,
  updatePreselection,
  createAudit,
  updateAuditMetadata,
  clearTemplate,
  useTemplate,

  storeFinding,
  storeMeasure,

  deleteFinding,
  deleteAllUnassignedFindings,
  updateAttachmentComment,
  downloadFileFromEntry,
  attachFilesToEntry,
  attachFileToFinding,
  updateFindingAttachment,
  deleteFileFromEntry,
  activateConflictFilter,
  activateUnansweredMaturitiesFilter,
  setAuditFormData,
  updateSingleMetadataField,
  changeAuditStatusUnrestricted,
  changeAuditProgram,
  changeToNextAuditStatus,
  cloneVdaProcessQuestions,
  setDefaultAnswerForUnanswerdAuditItems,

  moveFinding,
  addAuditShare,
  updateAuditShare,
  activateExternalMeasures,
  completeSelfAssessment,

  storeAnnotation,
  deleteAnnotation,

  setSelectedTemplateIds,
  togglePreselectionFilter,
  clearPreselectionFilter,
  setAllPreselectionFilter,
  manuallyAddToConsideredAuditItems,
  manuallyRemoveFromConsideredAuditItems,
  manuallyAddAuditItemsToSelfAssessment,
  manuallyRemoveAuditItemsFromSelfAssessment,
  updateTags,
  clearTags,
  setNote,
  deleteNote,
  switchToPreparationState,
  createRequiredMeasuresForPolicies,
};

const measureTypeResolverFactory =
  (rootState: any) => (measureTypeId: string) => {
    let measureType = rootState.configuration.measure.data.types.find(
      (v: any) => {
        return v.id === measureTypeId;
      }
    );
    if (!measureType) {
      measureType = rootState.configuration.measure.data.types.find(
        (v: any) => {
          return v.default;
        }
      );
    }
    if (measureType) {
      return {
        id: measureType.id,
        short: measureType.short || "XX",
        text: measureType.text || "Text",
        description: measureType.description || "Beschreibung",
        color: measureType.color || "blue",
        default: measureType.default || false,
        icon: measureType.icon || "info",
      };
    } else {
      throw new Error(
        `Undefined measureTypeId (importance) "${measureTypeId}"`
      );
    }
  };

const actions: ActionTree<CurrentAuditState, RootState> = {
  /**
   * Startet das Lauschen auf das aktuelle Audit
   * und lädt ggf. private Unterdokumente (AuditItems, Maßnahmen)
   *
   * @param auditId Id des Audits
   * @param loadSubDocuments Sollen Unterdokumente geladen werden
   */
  async [n.setAudit](
    context,
    {
      auditId,
      loadSubDocuments,
    }: { auditId: string; loadSubDocuments: boolean }
  ) {
    const { rootState, commit, getters, dispatch, rootGetters } = context;
    await dispatch(n.clearAudit);

    const documentsLoadingState: AuditLoadingState = {
      auditId,
      metadata: WatchedDocumentStates.Loading,
      auditItems: loadSubDocuments
        ? WatchedDocumentStates.Queued
        : WatchedDocumentStates.Idle,
      auditMeasures: loadSubDocuments
        ? WatchedDocumentStates.Queued
        : WatchedDocumentStates.Idle,
    };

    const updateLoadingState = () => {
      commit(mn.UPDATE_LOADING_STATE, documentsLoadingState);
    };

    const auditItemDocumentObserverErrorHandler = (error: any) => {
      documentsLoadingState.auditItems = WatchedDocumentStates.Error;
      updateLoadingState();
      if (
        error instanceof Object &&
        error.name === "FirebaseError" &&
        error.code === "permission-denied"
      ) {
        console.error("Audit Item-Listener failed", error);
        commit(mn.SET_ERROR_AUDIT_DATA_PERMISSION_DENIED);
      }
      commit(mn.SET_AUDIT_LOADING_ERROR, error);
    };

    const auditItemDocumentObserverUpdateHandler = (
      updateData: DocumentUpdateHandlerData
    ) => {
      const source = updateData.data;

      if (source === null || typeIsAuditResultDoc(source)) {
        if (
          documentsLoadingState.auditItems === WatchedDocumentStates.Loading
        ) {
          documentsLoadingState.auditItems = WatchedDocumentStates.Watching;
          updateLoadingState();
        }

        commit(mn.SET_AUDITITEMS_DOCUMENT, {
          doc: expectType<DocumentAuditItemsType>({
            ...updateData,
            data: source,
          }),
          auditId,
        });

        disableEmptyFilters(context);
      } else {
        console.error(
          "Unexpected Document format for AuditItems",
          source,
          typeIsAuditResultDoc.errors()
        );
      }
    };

    const auditDocumentObserverErrorHandler = (
      error: any,
      collection: string,
      documentId: string
    ) => {
      documentsLoadingState.metadata = WatchedDocumentStates.Error;
      updateLoadingState();
      console.error(
        `Error while watching for ${collection}/${documentId}`,
        error
      );
      commit(mn.SET_AUDIT_LOADING_ERROR, error);
    };
    const auditDocumentObserverUpdateHandler = (
      updateData: DocumentUpdateHandlerData
    ) => {
      console.log(`florentin update doc`);
      const source = updateData.data;
      let data: AuditMetadataClient | null = null;
      if (source !== null) {
        if (typeof source.reportSchema !== "string") {
          source.reportSchema = getters[gn.getDefaultCalcSchemaName] as string;
        }
        source.id = updateData.id;
        data = enrichAuditClient(updateData.id, source);
      }
      const auditMetadata = expectType<DocumentType>({
        ...updateData,
        data,
      });

      commit(mn.SET_CURRENTAUDITMETADATA, auditMetadata);

      const auditPermissions = getters[
        gn.getAuditPermissions
      ] as AuditPermissions;
      const auditRoles = getters[gn.getAuditRoles] as string[];

      // Wenn der Loading State Loading ist dann handelt es sich um das erste Empfangen von daten
      if (documentsLoadingState.metadata === WatchedDocumentStates.Loading) {
        // Dokumenten status auf in Beobachtung setzen
        documentsLoadingState.metadata = WatchedDocumentStates.Watching;
        updateLoadingState();
      }

      // Sicherstellen das nur bei existierenden Audits Unterdokumente geladen werden.
      if (loadSubDocuments && auditMetadata.exists && auditMetadata.data) {
        if (documentsLoadingState.auditItems === WatchedDocumentStates.Queued) {
          if (auditPermissions.read) {
            documentsLoadingState.auditItems = WatchedDocumentStates.Loading;
            updateLoadingState();

            const { ref: auditItemsCollectionPath, doc: auditItemsDocId } =
              createAuditItemsPath(auditId);
            const auditItemUnlistener = createDocumentObserver(
              auditItemsCollectionPath,
              auditItemsDocId,
              auditItemDocumentObserverUpdateHandler,
              auditItemDocumentObserverErrorHandler
            );
            commit(mn.SET_AUDITITEMS_UNLISTENER, auditItemUnlistener);
          } else {
            commit(mn.SET_ERROR_AUDIT_DATA_PERMISSION_DENIED);
            documentsLoadingState.auditItems = WatchedDocumentStates.Idle;
            updateLoadingState();
          }
        }

        if (
          documentsLoadingState.auditMeasures === WatchedDocumentStates.Queued
        ) {
          documentsLoadingState.auditMeasures = WatchedDocumentStates.Loading;
          updateLoadingState();

          const loadMeasuresPayload: Parameters<
            MeasuresActions["loadMeasures"]
          >[0] = {
            auditId,
            whereUserIs: isGenericMeasureReader(auditRoles)
              ? "generic-measure-reader"
              : "member",
            showCompletedMeasures: true,
          };

          dispatch(
            actionNs(measuresApi, measuresApi.actions.loadMeasures),
            loadMeasuresPayload,
            { root: true }
          )
            .then(measuresUnlistener => {
              commit(mn.SET_MEASURE_UNLISTENER, measuresUnlistener);
            })
            .catch(err => {
              console.error(`Listen on measures for audit ${auditId} failed`);
              documentsLoadingState.auditMeasures = WatchedDocumentStates.Error;
              updateLoadingState();
              commit(mn.SET_AUDIT_LOADING_ERROR, err);
            });
        }

        //
        // documentsLoadingState.auditMeasures = WatchedDocumentStates.Loading;
      }
    };

    const { ref: auditCollectoinPath, doc: auditDocId } =
      createAuditPath(auditId);

    commit(mn.SET_UNLISTENER, {
      auditId,
      auditUnlistener: createDocumentObserver(
        auditCollectoinPath,
        auditDocId,
        auditDocumentObserverUpdateHandler,
        auditDocumentObserverErrorHandler
      ),
    });
  },

  async [n.clearAudit]({ rootGetters, commit, state, dispatch }) {
    // Set Defaults
    const listsSettings: AuditListsSettings = {};
    const listsConfigs = rootGetters[
      getterNs(confApi, confApi.getters.lists)
    ] as ListsConfigMap;
    Object.entries(listsConfigs).forEach(([id, listsConfig]) => {
      if (listsConfig.manipulators !== null) {
        listsSettings[id] = {
          filters: listsConfig.manipulators.defaultFilter.map(
            (item): Filter => {
              return {
                aggregationId: item.id,
                value: item.value,
              };
            }
          ),
          groupings: listsConfig.manipulators.defaultGrouping,
          sortings: listsConfig.manipulators.defaultSorting,
          paging: {
            page: 1,
            pageSize: listsConfig.pageSizes[0],
          },
        };
      }
    });
    if (state.MeasureUnlistener !== null) {
      await dispatch(
        actionNs(measuresApi, measuresApi.actions.clearMeasures),
        state.MeasureUnlistener,
        { root: true }
      );
    }

    commit(mn.CLEAR_AUDIT, listsSettings);
  },

  [n.changeAuditName]({ getters, rootGetters }, AuditName: any) {
    const auditId = getters[gn.getAuditId] as string | null;
    if (auditId) {
      const { ref, doc } = createAuditPath(auditId);
      return firebase
        .firestore()
        .collection(ref)
        .doc(doc)
        .update({
          name: AuditName,
          ...rdu(rootGetters),
        });
    } else {
      throw new Error("no-audit-active");
    }
  },
  [n.updateCompanyMetadata](
    { rootGetters },
    { AuditId, CompanyMetadataUpdateData }: any
  ) {
    if (AuditId) {
      const { ref, doc } = createAuditPath(AuditId);
      return firebase
        .firestore()
        .collection(ref)
        .doc(doc)
        .update({
          companyMetadata: CompanyMetadataUpdateData,
          ...rdu(rootGetters),
        });
    } else {
      throw new Error("no-audit-active");
    }
  },
  [n.updatePreselection]({ rootGetters }, { auditId, preselection }: any) {
    if (auditId) {
      const { ref, doc } = createAuditPath(auditId);
      return firebase
        .firestore()
        .collection(ref)
        .doc(doc)
        .update({
          preselection,
          ...rdu(rootGetters),
        });
    } else {
      throw new Error("no-audit-active");
    }
  },
  async [n.createAudit]({ dispatch, rootGetters }, { data, activity }) {
    const db = firebase.firestore();
    const collection = db.collection(CollectionNames.AUDITS);
    const docRef = collection.doc();
    const firstStatus = AuditStatusId.Planning;

    const user = extractCurrentUserRef(rootGetters);
    if (user === null) {
      throw createError("invalid data/missed current user or audit id");
    }
    const workflow = {
      status: firstStatus,
      log: [
        {
          comment: "",
          timestamp: firebase.firestore.Timestamp.now(),
          user,
          from: null,
          to: firstStatus,
        },
      ],
    };
    await docRef.set({
      ...data,
      ...rdc(rootGetters),
      workflow,
    });
    logXStored(`Audit: ${docRef.id}`);

    const auditResultDoc: Omit<AuditResultDoc, typeof REV_KEY> = {
      auditItems: {},
      selfAssessments: {},
      findings: {},
      attachments: {},
      auditSummary: "",
      customData: {},
      questionNotes: {},
    };

    const { ref, doc } = createAuditItemsPath(docRef.id);
    await db
      .collection(ref)
      .doc(doc)
      .set({
        ...auditResultDoc,
        ...rdc(rootGetters),
      });
    logXStored(`AuditItems: ${docRef.id}`);

    if (activity) {
      dispatch(
        "activities/createActivity",
        activity(docRef.id, ActivityVerbs.CREATE),
        {
          root: true,
        }
      );
    }
    return docRef;
  },
  [n.updateAuditMetadata]({ dispatch, rootGetters }, { id, data, activity }) {
    data = cloneDeep(data);
    data = omit(data, [
      "hasPendingWrites",
      "fromCache",
      FieldPartNames.AUDIT_WORKFLOW,
      "companyMetadata",
      FieldPartNames.AUDIT_ITEMS,
      "additionalReportData",
      "exists",
      "id",
    ]);

    if (id) {
      if (activity) {
        dispatch("activities/createActivity", activity, { root: true });
      }
      const { ref, doc } = createAuditPath(id);
      return firebase
        .firestore()
        .collection(ref)
        .doc(doc)
        .update({ ...data, ...rdu(rootGetters) });
    } else {
      throw new Error("no-audit-active");
    }
  },
  [n.clearTemplate]({ state, getters, rootGetters }) {
    const auditId = state.Document.id;
    const status = getters[gn.getAuditStatus] as string | null;
    /*
     * TODO:
     * compare status index with the default
     */
    if (status !== AuditStatusId.Preparation) {
      return;
    }
    if (auditId !== "") {
      const metadata: firebase.firestore.UpdateData = {};
      metadata["categories"] = [];
      metadata["categorySetId"] = "";

      const auditItems: firebase.firestore.UpdateData = {};
      auditItems[FieldPartNames.AUDIT_ITEMS] = {};

      const { ref, doc } = createAuditPath(auditId);
      const { ref: auditItemsRef, doc: auditItemsDoc } =
        createAuditItemsPath(auditId);

      const auditData = { ...metadata, ...rdu(rootGetters) };

      const auditItemData = { ...auditItems, ...rdu(rootGetters) };
      console.log("SELECT TEMPLATE:AUDIT", auditData, ref, doc);
      console.log(
        "SELECT TEMPLATE:AUDIT_ITEMS",

        auditItemData,
        auditItemsRef,
        auditItemsDoc
      );

      return Promise.all([
        firebase.firestore().collection(ref).doc(doc).update(auditData),
        firebase
          .firestore()
          .collection(auditItemsRef)
          .doc(auditItemsDoc)
          .update(auditItemData),
      ]);
    } else {
      console.warn("No Document id set");
    }
  },
  async [n.useTemplate](
    { state, rootGetters },
    payload: {
      audit_items: { [s: string]: idable<AuditItem> };
      categories: AuditItemCategory[];
      audit_class?: string;
      standardRefs?: AuditStandardRef[];
      reportSchema?: ReportSchema;
      categorySetId?: string;
    }
  ) {
    const auditId = state.Document.id;
    console.log("Usetemplate", payload);
    if (auditId !== "") {
      const metadata: firebase.firestore.UpdateData = {};
      metadata["categories"] = payload.categories;

      if (payload.audit_class) {
        metadata["audit_class"] = payload.audit_class;
      }

      if (payload.categorySetId) {
        metadata["categorySetId"] = payload.categorySetId;
      }

      if (payload.reportSchema) {
        metadata["reportSchema"] = payload.reportSchema;
      }
      const auditItems = chain(payload.audit_items)
        .toPairs()
        .map(([auditItemId, auditItem]): [string, AuditItem] => {
          const { child } = createAuditItemPath(auditId, auditItemId);
          return [child, omit(auditItem, "id")];
        })
        .fromPairs()
        .value();

      const { ref, doc } = createAuditPath(auditId);
      const { ref: auditItemsRef, doc: auditItemsDoc } =
        createAuditItemsPath(auditId);

      const auditData = { ...metadata, ...rdu(rootGetters) };
      const auditItemData = { ...auditItems, ...rdu(rootGetters) };
      console.log("SELECT TEMPLATE:AUDIT", auditData, ref, doc);
      console.log(
        "SELECT TEMPLATE:AUDIT_ITEMS",

        auditItemData,
        auditItemsRef,
        auditItemsDoc
      );

      return Promise.all([
        firebase.firestore().collection(ref).doc(doc).update(auditData),
        firebase
          .firestore()
          .collection(auditItemsRef)
          .doc(auditItemsDoc)
          .update(auditItemData),
      ]);
    } else {
      console.warn("No Document id set");
    }
  },
  async [n.deleteFinding]({ getters, rootGetters }, { ref: { findingId } }) {
    const auditId = getters[gn.getAuditId] as string | null;
    if (!auditId) {
      throw new Error("Expect create is called on current audit");
    }

    const { ref, doc, child } = createFindingPath(auditId, findingId);

    const firebaseUpdatedata: firebase.firestore.UpdateData = {
      [child]: firebase.firestore.FieldValue.delete(),
    };

    firebase
      .firestore()
      .collection(ref)
      .doc(doc)
      .update({ ...firebaseUpdatedata, ...rdu(rootGetters) })
      .then(logXStored("deleteFinding"));
  },
  async [n.deleteAllUnassignedFindings]({ getters, rootGetters }) {
    const auditId = getters[gn.getAuditId] as string | null;
    if (!auditId) {
      throw new Error("Expect create is called on current audit");
    }

    const unassignedFindingIds = getters[
      gn.getUnassignedFindingIds
    ] as string[];

    const firebaseUpdatedata: firebase.firestore.UpdateData = {};
    unassignedFindingIds.forEach(findingId => {
      const { ref, doc, child } = createFindingPath(auditId, findingId);

      firebaseUpdatedata[child] = firebase.firestore.FieldValue.delete();
    });

    const { ref: findingsRef, doc: findingsDoc } = createFindingsPath(auditId);
    firebase
      .firestore()
      .collection(findingsRef)
      .doc(findingsDoc)
      .update({ ...firebaseUpdatedata, ...rdu(rootGetters) })
      .then(logXStored("deleteUnassignedFindings"));
  },
  async [n.attachFilesToEntry](
    { state },
    ...[payload]: Parameters<EntryAttachmentsVuexApi["attachFilesToEntry"]>
  ): ReturnType<EntryAttachmentsVuexApi["attachFilesToEntry"]> {
    const auditId = state.auditId;
    if (auditId === null) {
      throw createError("Invalid auditId");
    }

    const attachmentMap = await attachFilesToEntity(payload, {
      target: { type: "audit", auditId },
    });
    return keys(attachmentMap);
  },
  async [n.updateAttachmentComment](
    { rootGetters, state },
    ...[{ id: attachmentId, comment, context }]: Parameters<
      EntryAttachmentsVuexApi["updateAttachmentComment"]
    >
  ): ReturnType<EntryAttachmentsVuexApi["updateAttachmentComment"]> {
    console.log(
      "DISPATCH: updateAttachmentComment",
      attachmentId,
      comment,
      context
    );

    const expectedContexts = ["finding", "audit"];

    if (!expectedContexts.includes(context)) {
      throw createError(
        `Unexpected context - Error: 5c9db7e8-2ea8-42a0-9f0c-dd950062a05b`,
        context,
        expectedContexts,
        attachmentId
      );
    }
    const auditId = state.auditId;
    if (!auditId || !attachmentId) {
      throw createError(
        "auditId or attachmentId not defined",
        auditId,
        attachmentId
      );
    }

    const db = firebase.firestore();
    const { ref, doc, child } =
      context === "audit"
        ? createAuditMediaPath(auditId, attachmentId)
        : createFindingMediaPath(auditId, attachmentId);

    const docRef = db.collection(ref).doc(doc);
    await db.runTransaction(async transaction => {
      transaction.update(docRef, {
        [`${child}.comment`]: comment,
        ...rdu(rootGetters),
      });
    });
  },
  async [n.downloadFileFromEntry](
    { state },
    ...[{ id: attachmentId, context }]: Parameters<
      EntryAttachmentsVuexApi["downloadFileFromEntry"]
    >
  ): ReturnType<EntryAttachmentsVuexApi["downloadFileFromEntry"]> {
    const expectedContexts = ["finding", "audit"];

    if (!expectedContexts.includes(context)) {
      throw createError(
        `Unexpected context - Error: 3e4d3dec-ca65-4c3b-8487-e3e01d32dcf2`
      );
    }
    const auditId = state.auditId;
    if (!auditId || !attachmentId) {
      throw createError(
        "auditId or attachmentId not defined",
        auditId,
        attachmentId
      );
    }

    const createAuditTarget = (): AttachmentMetadataAudit => {
      return {
        auditId,
        type: "audit",
      };
    };

    const createFindingTarget = (): AttachmentMetadataDownloadFinding => {
      return {
        auditId,
        type: "finding",
      };
    };

    const targetBuilder =
      context === "audit" ? createAuditTarget : createFindingTarget;

    const params: ApiV0FileManagementDownloadRequest = {
      attachmentId,
      target: targetBuilder(),
    };

    const attachmentLink = await getDownloadLinkForAttachment(params);
    if (attachmentLink.isOk()) {
      return attachmentLink.value.downloadUrl;
    } else {
      throw createError(attachmentLink.error);
    }
  },
  async [n.deleteFileFromEntry](
    { state, rootGetters },
    ...[{ id: attachmentId, context }]: Parameters<
      EntryAttachmentsVuexApi["deleteFileFromEntry"]
    >
  ): ReturnType<EntryAttachmentsVuexApi["deleteFileFromEntry"]> {
    const expectedContexts = ["finding", "audit"];

    if (!expectedContexts.includes(context)) {
      throw createError(
        `Unexpected context - Error: a264585b-0ae9-4dcd-8efd-31c3383979e9`
      );
    }
    const auditId = state.auditId;
    if (!auditId || !attachmentId) {
      throw createError(
        "auditId or attachmentId not defined",
        auditId,
        attachmentId
      );
    }

    const FieldValue = firebase.firestore.FieldValue;
    const db = firebase.firestore();
    const { ref, doc, child } =
      context === "audit"
        ? createAuditMediaPath(auditId, attachmentId)
        : createFindingMediaPath(auditId, attachmentId);

    const auditUpdateDataBuilder = async (
      _: firebase.firestore.Transaction
    ): Promise<firebase.firestore.UpdateData> => {
      return {};
    };

    const findingUpdateDataBuilder = async (
      transaction: firebase.firestore.Transaction
    ): Promise<firebase.firestore.UpdateData> => {
      const doc = await transaction.get(docRef);
      const findings = doc.get(FieldPartNames.FINDINGS);

      if (findings) {
        const updateData: firebase.firestore.UpdateData = {};
        const attachmentIdsFieldName: keyof Finding = "attachmentIds";

        forIn(findings, (value, findingId) => {
          const attachmentIds = value?.[attachmentIdsFieldName] ?? [];
          if (typeIsArrayOf(attachmentIds, isString)) {
            if (attachmentIds.includes(attachmentId)) {
              const path = fieldPath(
                FieldPartNames.FINDINGS,
                findingId,
                attachmentIdsFieldName
              );

              updateData[path] = FieldValue.arrayRemove(attachmentId);
            }
          } else {
            throw createError(
              `invalid finding format auditId=${auditId}, findingId=${findingId}: invalid field ${attachmentIdsFieldName}`,
              value,
              attachmentIds
            );
          }
        });
        return updateData;
      } else {
        throw createError(
          `invalid audit result format auditId=${auditId}: `,
          findings
        );
      }
    };

    const additionalUpdateDataBulder =
      context === "audit" ? auditUpdateDataBuilder : findingUpdateDataBuilder;

    const docRef = db.collection(ref).doc(doc);
    await db.runTransaction(async transaction => {
      const additionalUpdateData = await additionalUpdateDataBulder(
        transaction
      );

      transaction.update(docRef, {
        ...additionalUpdateData,
        [child]: FieldValue.delete(),
        ...rdu(rootGetters),
      });
    });
  },
  async [n.attachFileToFinding](
    { dispatch, rootGetters },
    { audit_id, audititem_id, deviation_id, media_id, data: { url } }
  ) {
    dispatch("app/setLoading", true, { root: true });
    media_id = media_id || uuidv4();

    const { ref, doc, child } = createFindingMediaPath(audit_id, media_id);

    const firebaseUpdatedata = {
      [child]: { url },
    };

    firebase
      .firestore()
      .collection(ref)
      .doc(doc)
      .update({ ...firebaseUpdatedata, ...rdu(rootGetters) })
      .then(logXStored(attachFileToFinding));
    dispatch("app/setLoading", false, { root: true });
  },
  async [n.updateFindingAttachment](
    { rootGetters },
    {
      ref: { auditId, auditItemId, findingId, mediaId },
      data: { url, dataUrl },
    }
  ) {
    const storageRef = firebase.storage().refFromURL(url);
    const oldMetadata = await storageRef.getMetadata();
    const customMetadata = oldMetadata.customMetadata;

    const uploadTask = await storageRef.putString(dataUrl, "data_url", {
      customMetadata,
    });
    const newDownloadUrl = toStoragePath(uploadTask.ref);

    const { ref, doc, child } = createFindingMediaPath(
      customMetadata?.auditId || auditId,
      mediaId
    );

    const firebaseUpdatedata = {
      [child]: { url: newDownloadUrl },
    };

    firebase
      .firestore()
      .collection(ref)
      .doc(doc)
      .update({ ...firebaseUpdatedata, ...rdu(rootGetters) })
      .then(logXStored("attachFileToFinding"));
    return newDownloadUrl;
  },

  [n.activateConflictFilter]({ state, getters, rootGetters, commit }) {
    commit(mn.SET_CONFLICT_FILTER);
  },
  [n.activateUnansweredMaturitiesFilter]({
    state,
    getters,
    rootGetters,
    commit,
  }) {
    commit(mn.SET_UNANSWERED_MATURITIES_FILTER);
  },
  [n.setAuditFormData](
    { state, getters, rootGetters },
    { formSchemaId, data }: { formSchemaId: string; data: any }
  ) {
    const vfjsCommonSchemas = rootGetters[
      getterNs(schemasApi, schemasApi.getters.getVfjsCommonSchemas)
    ] as Dictionary<any>;
    const audit =
      state.Document && state.Document.data ? state.Document.data : null;

    const dialogSchema = (
      getters[gn.getAuditFormSchema] as (formId: string) => FormSchemaDynamic
    )(formSchemaId);

    const schemaValidator = new Ajv();
    Object.entries(vfjsCommonSchemas).forEach(([key, schema]) => {
      schemaValidator.addSchema(schema, key);
    });

    const valid = schemaValidator.validate(dialogSchema.data, data);
    if (!valid) {
      throw createError("Dynamic formdata invalid.", schemaValidator.errors);
    } else {
      console.log("Data is valid");
    }

    const updateData = omit({ ...audit, ...data }, FieldPartNames.AUDIT_ITEMS);

    console.log(n.setAuditFormData, updateData, audit, data);

    let auditId: nullable<string> = getters[gn.getAuditId];
    let auditDocRef: firebase.firestore.DocumentReference | null = null;
    if (auditId) {
      auditDocRef = firebase
        .firestore()
        .collection(CollectionNames.AUDITS)
        .doc(auditId);
    } else {
      auditDocRef = firebase
        .firestore()
        .collection(CollectionNames.AUDITS)
        .doc();
      auditId = auditDocRef.id;
    }
    if (auditDocRef && auditId) {
      auditDocRef
        .set(
          { ...updateData, [REV_KEY]: vdu(rootGetters) },
          {
            merge: true,
          }
        )
        .then(logXStored("Dynamic audit dialog data"));
      return auditId;
    } else {
      throw createError("Failed to create or update audit");
    }
  },
  async [n.updateSingleMetadataField](
    { getters, rootGetters, commit },
    payload: { fieldPath: string; value: any }
  ) {
    const VALID_FIELD_PATHS = new Set([
      "due_date_review",
      "due_date_final",
      "auditing_date",
    ]);
    if (!VALID_FIELD_PATHS.has(payload.fieldPath)) {
      throw new Error(`update field ${payload.fieldPath} forbidden`);
    }
    const auditId = getters[gn.getAuditId] as null | string;
    if (!auditId) {
      throw new Error("expect current audit id");
    }
    const auditDocRef = firebase
      .firestore()
      .collection(CollectionNames.AUDITS)
      .doc(auditId);

    try {
      await auditDocRef.update({
        [payload.fieldPath]: payload.value,
        ...rdu(rootGetters),
      });
    } catch (err) {
      console.error("Error updateSingleMetadataField", err);
    }
  },
  async [n.changeAuditStatusUnrestricted](
    { getters },
    payload: { from: string; to: string; comment: string }
  ): Promise<string> {
    const { to, from, comment } = payload;

    const auditId: nullable<string> = getters[gn.getAuditId];
    if (auditId) {
      const url = auditsEndpointUrl(auditId, "status");
      try {
        const res = await axios({
          method: "POST",
          url,
          data: {
            comment,
            status: {
              from,
              to,
            },
          },
        });
        console.log("AXIOS:done", res);
        return to;
      } catch (error) {
        const err = error as AxiosError;
        console.error("AXIOS:failed", err);
        if (err.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error("Response other than 2xx");
          console.error(err.response.data);
          console.error(err.response.status);
          console.error(err.response.headers);
        } else if (err.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.error("Request");
          console.error(err.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error", err.message);
        }

        throw err;
      }
    } else {
      throw new Error("invalid data/missed current user or audit id");
    }
  },
  async [n.changeAuditProgram](
    { getters, dispatch },
    auditProgramId: string
  ): Promise<void> {
    const auditId: nullable<string> = getters[gn.getAuditId];
    if (auditId) {
      return dispatch(
        actionNs(auditsApi, auditsApi.actions.changeAuditProgram),
        { auditIds: [auditId], auditProgramId },
        { root: true }
      );
    } else {
      throw new Error("invalid data/missed current user or audit id");
    }
  },
  async [n.changeToNextAuditStatus](
    { getters, rootGetters, state },
    payload: { from: string; comment: string }
  ): Promise<string> {
    const { from, comment } = payload;
    // ToDo: Transition nutzen
    const to = String(parseInt(from, 10) + 1);

    const workflowSteps: AuditWorkflowStepInfo[] = getters[gn.getWorkflowSteps];
    const stepVal = parseInt(to, 10);
    if (!workflowSteps[stepVal]) {
      throw createError("Invalid State", to, workflowSteps);
    }

    const auditId: nullable<string> = getters[gn.getAuditId];
    const user = extractCurrentUserRef(rootGetters);

    if (auditId && user !== null) {
      const auditDocRef = firebase
        .firestore()
        .collection(CollectionNames.AUDITS)
        .doc(auditId);

      await firebase.firestore().runTransaction(async transaction => {
        const updates: firebase.firestore.UpdateData = {
          [fieldPath(FieldPartNames.AUDIT_WORKFLOW, "status")]: to,
          [fieldPath(FieldPartNames.AUDIT_WORKFLOW, "log")]:
            firebase.firestore.FieldValue.arrayUnion({
              comment,
              timestamp: firebase.firestore.Timestamp.now(),
              user,
              from,
              to,
            }),
          ...rdu(rootGetters),
        };

        if (from === AuditStatusId.Preparation) {
          const latestAuditDoc = await transaction.get(auditDocRef);
          const auditMetadata = latestAuditDoc.data() as
            | AuditMetadataDoc
            | undefined;
          if (!auditMetadata) {
            throw new Error(`Audit id=${auditId} not found`);
          }

          updates[FieldPartNames.TOTAL_CONSIDERED_AUDIT_ITEM_IDS] =
            calculateTotalConsideredAuditItemIds(
              auditMetadata.totalConsideredAuditItemIds,
              auditMetadata.auditPreparation.manuallyConsideredAuditItemIds
            );
        }

        transaction.update(auditDocRef, updates);
      });
      logXStored("Audit Status")();
    } else {
      throw new Error("invalid data/missed current user or audit id");
    }
    return to;
  },
  async [n.cloneVdaProcessQuestions]({ getters, rootGetters }) {
    const auditId = getters[gn.getAuditId] as nullable<string>;

    if (auditId) {
      console.log("Start Clone");

      const { ref: auditResultRef, doc: auditResultDocId } =
        createAuditItemsPath(auditId);
      const { ref: auditRef, doc: auditDocId } = createAuditPath(auditId);

      const db = firebase.firestore();

      const auditResultDocRef = db
        .collection(auditResultRef)
        .doc(auditResultDocId);
      const auditDocRef = db.collection(auditRef).doc(auditDocId);

      await db.runTransaction(async transaction => {
        const auditDoc = await transaction.get(auditDocRef);
        const audit = auditDoc.data() as AuditMetadataDoc | undefined;
        if (!audit) {
          throw Error("audit doc not found.");
        }

        const vdaProcesses = (audit.vda?.processes ?? []).filter(
          process =>
            audit.vda?.matrix.some(coords => coords.r === process) ?? false
        );

        if (
          vdaProcesses.length < 1 ||
          (audit.vda?.products ?? [])?.length < 1 ||
          (audit.vda?.matrix ?? [])?.length < 1
        ) {
          throw Error("api_errors.audit.missing_vda_processes");
        }

        const isVdaAudit = getters[gn.getIsVdaAudit] as boolean;

        const cloneRequired = isVdaAudit;
        if (!cloneRequired) {
          return;
        }

        if (
          !(
            audit.workflow.status === AuditStatusId.Preparation &&
            audit.auditPreparation.step === "preselection"
          )
        ) {
          return;
        }

        const auditResultDoc = await transaction.get(auditResultDocRef);
        const auditResult = auditResultDoc.data() as AuditResultDoc | undefined;
        if (!auditResult) {
          throw Error("audit result doc not found.");
        }

        const originalAuditItemsOfCategory6 = pickBy(
          auditResult.auditItems,
          (auditItem, auditItemId) => {
            // Todo:  Besseren kenner für die VDA Prozess Fragen verwenden
            return (
              uuidVersion(auditItemId) === 4 &&
              /^6\D?/.test(String(auditItem.question.no))
            );
          }
        );

        console.assert(
          size(originalAuditItemsOfCategory6) > 0 &&
            "expect at least one audit item in category 6 of vda audits"
        );
        console.log("Found ", size(originalAuditItemsOfCategory6), " to clone");

        const clonedVdaAuditItems = fromPairs(
          flatten(
            map(
              originalAuditItemsOfCategory6,
              (auditItem, baseAuditItemId): Array<[string, AuditItem]> => {
                const auditItemIds = [
                  baseAuditItemId,
                  ...Array.from(new Array(vdaProcesses.length - 1)).map(
                    (_, idx) => {
                      return uuidv5(String(idx + 1), baseAuditItemId);
                    }
                  ),
                ];
                console.log("AuditItemIds for ", baseAuditItemId, auditItemIds);

                return auditItemIds.map((auditItemId, idx): [
                  string,
                  AuditItem
                ] => {
                  return [
                    auditItemId,
                    {
                      ...auditItem,
                      question: {
                        ...auditItem.question,
                        vda_process: { name: vdaProcesses[idx], step: idx + 1 },
                      },
                    },
                  ];
                });
              }
            )
          )
        );

        transaction.update(auditResultDocRef, {
          [FieldPartNames.AUDIT_ITEMS]: {
            ...pickBy(auditResult.auditItems, (auditItem, auditItemId) => {
              return uuidVersion(auditItemId) === 4;
            }),
            ...clonedVdaAuditItems,
          },
          ...rdu(rootGetters),
        });
      });
    } else {
      throw new Error("No audit active");
    }
  },
  // TODO ACS-1957 replace with a REST API
  async [n.setDefaultAnswerForUnanswerdAuditItems](
    { getters, rootGetters },
    { text }: { text?: string }
  ) {
    const auditItemUnansweredDimensionMap = getters[
      gn.getAuditItemUnanswerdDimensionsMap
    ] as AuditItemDimensionsMap;

    const auditId: nullable<string> = getters[gn.getAuditId];
    const finalizeExecutionFindingTypeId = getters[
      gn.getFinalizeExecutionFindingTypeId
    ] as nullable<string>;

    if (!finalizeExecutionFindingTypeId) {
      throw createError("no finalize execution finding type defined");
    }

    const findingType = (getters[gn.getMappedFindingTypes] as FindingTypeMap)[
      finalizeExecutionFindingTypeId
    ];

    if (!findingType) {
      throw createError("Unknown finding type", finalizeExecutionFindingTypeId);
    }

    const unansweredAuditItems = getters[
      gn.getUnAnsweredAuditItems
    ] as idable<AuditItem>[];

    if (typeof text !== "string" || text.trim() === "") {
      text = ct(findingType.placeholder_text || "Das ist ein Platzhalter");
    }
    const msg = text;

    const buildDefaultFindingFor = (
      auditItemId: string,
      unansweredDimensions: string[] | null
    ) => {
      const finding: Finding = {
        attachmentIds: [],
        auditItemRefs: [
          {
            auditItemId,
            dimensions: unansweredDimensions,
          },
        ],
        text: msg,
        type: finalizeExecutionFindingTypeId,
      };
      return finding;
    };

    const firebaseUpdateData: Dictionary<Finding> = {};
    const auditItemIds = unansweredAuditItems.map(auditItem => auditItem.id);
    if (auditItemIds.length > 0 && auditId !== null) {
      auditItemIds.forEach(auditItemId => {
        const findingId = uuidv4();
        const { child } = createFindingPath(auditId, findingId);
        const unansweredDimensions =
          auditItemUnansweredDimensionMap[auditItemId] ?? null;
        firebaseUpdateData[child] = buildDefaultFindingFor(
          auditItemId,
          unansweredDimensions
        );
      });

      await firebase.firestore().runTransaction(async transaction => {
        const { ref, doc } = createFindingPath(auditId, "");
        const docRef = firebase.firestore().collection(ref).doc(doc);

        // within the transaction, check that all new findings are still unset
        const auditResultDoc = await transaction.get(docRef);
        const { findings } = auditResultDoc.data() as AuditResultDoc;
        const answeredDimensionsByAuditItemId: Dictionary<string[]> = {};
        for (const finding of Object.values(findings)) {
          finding.auditItemRefs.forEach(({ auditItemId, dimensions }) => {
            answeredDimensionsByAuditItemId[auditItemId] = uniq([
              ...(answeredDimensionsByAuditItemId[auditItemId] ?? []),
              ...(dimensions ?? []),
            ]);
          });
        }

        for (const findingToAdd of Object.values(firebaseUpdateData)) {
          findingToAdd.auditItemRefs.forEach(auditItemRef => {
            const { auditItemId, dimensions = null } = auditItemRef;
            const answeredDimensions =
              answeredDimensionsByAuditItemId[auditItemId] ?? null;
            if (answeredDimensions === null) {
              return;
            }
            if (
              dimensions === null ||
              dimensions?.some(d => answeredDimensions.includes(d))
            ) {
              throw new Error("Findings were modified concurrently.");
            }
          });
        }

        transaction.update(docRef, {
          ...firebaseUpdateData,
          ...rdu(rootGetters),
        });
      });
    } else {
      throw createError("Expect one or more auditItemIds", auditItemIds);
    }
  },

  async [n.moveFinding](
    { getters, rootGetters, state },
    {
      findingId,
      formAuditItemId,
      toAuditItemId,
    }: {
      findingId: string;
      formAuditItemId: string | null;
      toAuditItemId: string | null;
    }
  ) {
    console.log("Move Finding", findingId, formAuditItemId, toAuditItemId);

    const auditId = getters[gn.getAuditId] as null | string;

    if (!auditId) {
      throw new Error("expect current audit id");
    }
    const auditItemDimensionsMap = getters[
      gn.getAuditItemDimensionsMap
    ] as AuditItemDimensionsMap;

    const findings = state.AuditItemsDocument.data?.findings ?? {};

    const findingToMove = findings[findingId];

    const toDimensions = auditItemDimensionsMap[toAuditItemId ?? ""] ?? [];

    const untouchedRefs = findingToMove.auditItemRefs.filter(
      ref =>
        ref.auditItemId !== toAuditItemId && ref.auditItemId !== formAuditItemId
    );

    const fromAuditItemRef = findingToMove.auditItemRefs.find(
      ref => ref.auditItemId === formAuditItemId
    ) ?? { auditItemId: "", dimensions: null };
    const toAuditItemRef = findingToMove.auditItemRefs.find(
      ref => ref.auditItemId === toAuditItemId
    ) ?? { auditItemId: "", dimensions: null };

    const activatedDimensions = uniq(
      [
        ...(toAuditItemRef.dimensions ?? []),
        ...(fromAuditItemRef.dimensions ?? []),
      ].filter(d => toDimensions.includes(d))
    );

    const auditItemRefs = untouchedRefs;

    if (toAuditItemId) {
      auditItemRefs.push({
        auditItemId: toAuditItemId,
        dimensions: activatedDimensions.length > 0 ? activatedDimensions : null,
      });
    }

    const patchData = {
      [fieldPath(FieldPartNames.FINDINGS, findingId, "auditItemRefs")]:
        auditItemRefs,
    };

    const db = firebase.firestore();

    const { ref, doc } = createFindingPath(auditId, findingId);
    const docRef = db.collection(ref).doc(doc);

    await docRef.update({
      ...patchData,
      ...rdu(rootGetters),
    });

    return true;
  },
  async [n.addAuditShare](
    { getters },
    payload: ApiV0AuditSharesPostShareRequest
  ): Promise<Result<true, "NOT_ALLOWED_FOR_USER" | "UNKNOWN">> {
    const auditId = getters[gn.getAuditId] as string | null;
    if (auditId === null) {
      throw createError("no active audit");
    }

    const url = auditsEndpointUrl(auditId, "shares");
    console.log(n.addAuditShare, "RQ", payload, url);

    if (typeIsApiV0AuditSharesPostShareRequest(payload)) {
      const response = await axios({
        method: "POST",
        url,
        responseType: "json",
        data: payload,
      }).catch(error => error.response);

      console.log(n.addAuditShare, "RS", payload, response);
      if (response.status < 400) {
        return ok(response.data);
      } else if (
        response.data.code === "UNRPOCESSABLE_ENTITY/not-allowed-for-user"
      ) {
        return err("NOT_ALLOWED_FOR_USER");
      }
      return err("UNKNOWN");
    } else {
      console.log("expect payload to be ApiV0AuditSharesPostShareRequest");
      throw new TypeError("expect ApiV0AuditSharesPostShareRequest");
    }
  },
  async [n.updateAuditShare](
    { getters },
    payload: { shareId: string } & ApiV0AuditSharesPutShareRequest
  ) {
    const auditId = getters[gn.getAuditId] as string | null;
    if (auditId === null) {
      throw createError("no active audit");
    }
    const { shareId, ...apiPayload } = payload;

    const url = auditsEndpointUrl(auditId, "shares", shareId);
    console.log(n.updateAuditShare, "RQ", apiPayload, url);

    if (typeIsApiV0AuditSharesPutShareRequest(apiPayload)) {
      const response = await axios({
        method: "PUT",
        url,
        responseType: "json",
        data: apiPayload,
      });

      console.log(n.updateAuditShare, "RS", apiPayload, response);
      return response.data;
    } else {
      console.log("expect payload to be ApiV0AuditSharesPutShareRequest");
      throw new TypeError("expect ApiV0AuditSharesPutShareRequest");
    }
  },
  async [n.activateExternalMeasures](
    { getters },
    payload: ActivateExternalMeasuresParams
  ) {
    const auditId = getters[gn.getAuditId] as string | null;
    if (auditId === null) {
      throw createError("no active audit");
    }

    const url = auditsEndpointUrl(auditId, "activate-external-measures");
    if (typeIsActivateExternalMeasuresParams(payload)) {
      const response = await axios({
        method: "post",
        url,
        responseType: "json",
        data: payload,
      });

      return response.data;
    } else {
      console.log("expect payload to be ActivateExternalMeasuresParams");
      throw new TypeError("expect ActivateExternalMeasuresParams");
    }
  },
  async [n.completeSelfAssessment]({ getters }) {
    const auditId = getters[gn.getAuditId] as string | null;
    if (auditId === null) {
      throw createError("no active audit");
    }

    const url = auditsEndpointUrl(auditId, "complete-self-assessment");
    const response = await axios({ method: "post", url, responseType: "json" });

    return response.data;
  },

  async [n.storeAnnotation](
    { getters, rootGetters },
    payload: { annotation: Annotation; annotationId?: string }
  ) {
    console.log("storeAnnotation", payload);
    const permissions = getters[gn.getAuditPermissions] as AuditPermissions;
    const auditId = getters[gn.getAuditId] as nullable<string>;

    if (!auditId) {
      throw new Error("no active audit found");
    }

    if (!permissions.write) {
      throw new Error("no permission to store annotations");
    }
    if (!isString(payload.annotation.attachmentId)) {
      throw new Error("invalid call");
    }
    if (!isPlainObject(payload.annotation.markerAreaState)) {
      throw new Error("invalid call");
    }
    if (payload.annotation.attachmentId.trim().length === 0) {
      throw new Error("expect attachment id");
    }

    const versionDocData = rdu(rootGetters);

    const annotationId = payload.annotationId ?? uuidv4();
    const annotation: Annotation = {
      attachmentId: payload.annotation.attachmentId,
      userRef: payload.annotation.userRef,
      markerAreaState: payload.annotation.markerAreaState,
      timestamp: payload.annotation.timestamp,
    };

    if (isString(payload.annotation.comment)) {
      annotation.comment = payload.annotation.comment?.trim();
    }

    const db = firebase.firestore();
    const { doc, ref, child } = createAnnotationPath(auditId, annotationId);

    const docRef = db.collection(ref).doc(doc);
    docRef
      .update({
        [child]: annotation,
        ...versionDocData,
      })
      .then(logXStored(`Annotation: ${docRef.path} ${child}`))
      .catch(err => console.error("Store  annotation failed: ", err));

    return annotationId;
  },

  async [n.deleteAnnotation](
    { getters, rootGetters },
    payload: { annotation: Omit<Annotation, "creator">; annotationId?: string }
  ) {
    console.log("deleteAnnotationMarkerArea", payload);
    const permissions = getters[gn.getAuditPermissions] as AuditPermissions;
    const auditId = getters[gn.getAuditId] as nullable<string>;

    if (!auditId) {
      throw new Error("no active audit found");
    }

    if (!permissions.write) {
      throw new Error("no permission to delete markers from annotations");
    }
    if (!isString(payload.annotation.attachmentId)) {
      throw new Error("invalid call");
    }
    if (!isPlainObject(payload.annotation.markerAreaState)) {
      throw new Error("invalid call");
    }

    const versionDocData = rdu(rootGetters);

    if (!payload.annotationId) {
      throw new Error("no active annotation found");
    }

    const { doc, ref, child } = createAnnotationPath(
      auditId,
      payload.annotationId
    );
    const db = firebase.firestore();

    const docRef = db.collection(ref).doc(doc);
    docRef.delete();
  },

  async [n.setSelectedTemplateIds](
    { getters, rootGetters, commit, state },
    templateIds: Array<MinimalTemplate["id"]>
  ): Promise<void> {
    if (templateIds.length > 10) {
      throw new Error(`Only 10 templates a allowed, got ${templateIds.length}`);
    }
    const auditId = state.auditId;
    if (!auditId) {
      throw new Error(`No auditId set`);
    }

    const categoryLevel = getters[gn.getCategoryLevel] as CategoryLevel;

    const db = firebase.firestore();
    const auditMetadataDocRef = db
      .collection(CollectionNames.AUDITS)
      .doc(auditId);
    const auditResultDocRef = db
      .collection(CollectionNames.AUDITS)
      .doc(auditId)
      .collection(CollectionNames.PRIVATE)
      .doc(DocumentNames.AUDIT_ITEMS);

    type UpdateFunction<T> = (
      transaction: firebase.firestore.Transaction
    ) => Promise<T>;
    const auditMetaDataLoader =
      buildTransactionalAuditMetadataLoader(auditMetadataDocRef);
    const auditResultLoader =
      buildTransactionalAuditResultLoader(auditResultDocRef);

    const docVersionUpdate = rdu(rootGetters);
    const loadAuditDocs: UpdateFunction<{
      auditMetadata: AuditMetadataDoc;
      auditResult: AuditResultDoc;
    }> = async transaction => {
      return {
        auditMetadata: await auditMetaDataLoader(transaction),
        auditResult: await auditResultLoader(transaction),
      };
    };

    const clearTemplatesHandler: UpdateFunction<void> = async transaction => {
      const { auditResult, auditMetadata } = await loadAuditDocs(transaction); // Run to ensure that the audit is valid

      const auditMetadataUpdate: Dictionary<any> = {
        "auditPreparation.step": "preselection",
        "auditPreparation.activePreselectionFilters": [],
        "auditPreparation.manuallyConsideredAuditItemIds": {},
        "auditPreparation.templateIds": [],
        ...docVersionUpdate,
      };

      const auditResultUpdate: Dictionary<any> = {
        auditItems: {},
        questionNotes: {},
        ...docVersionUpdate,
      };

      transaction
        .update(auditMetadataDocRef, auditMetadataUpdate)
        .update(auditResultDocRef, auditResultUpdate);
    };

    const loadTemplateDocs: (
      transaction: firebase.firestore.Transaction,
      templateIds: string[],
      allowDeleted: boolean
    ) => Promise<Array<idable<TemplateDoc>>> = async (
      transaction,
      templateIds,
      allowDeleted
    ) => {
      const templateDocs = await Promise.all(
        templateIds.map(templateId =>
          transaction.get(
            db.collection(CollectionNames.TEMPLATES).doc(templateId)
          )
        )
      );

      return templateDocs.map(templateDoc => {
        const template = templateDoc.data() as undefined | TemplateDoc;
        if (!template) {
          throw new Error(`${templateDoc.ref.path} not found`);
        }

        const isTemplateDeleted = template.docVersion?.deleted ?? true;
        if (!allowDeleted && isTemplateDeleted) {
          throw new Error(`Expect ${templateDoc.ref.path} is not deleted.`);
        }

        return {
          id: templateDoc.id,
          ...template,
        };
      });
    };

    const updateTemplatesHandler: UpdateFunction<void> = async transaction => {
      const { auditResult, auditMetadata } = await loadAuditDocs(transaction);
      const storedTemplateIds =
        auditMetadata.auditPreparation?.templateIds ?? [];

      const existingCategorySetId = auditMetadata.categorySetId ?? "";

      const add = difference(templateIds, storedTemplateIds);
      const remove = difference(storedTemplateIds, templateIds);

      const addTemplates = await loadTemplateDocs(transaction, add, false);
      const removeTemplates = await loadTemplateDocs(transaction, remove, true);

      const expectedCategorySetIds = [
        ...addTemplates.map(template => template.categorySetId),
        existingCategorySetId,
      ];
      const cleanCategorySetIds = uniq(
        expectedCategorySetIds.filter(
          categorySetId => categorySetId.trim().length !== 0
        )
      );
      console.log(
        "expectedCategorySetIds",
        expectedCategorySetIds,
        cleanCategorySetIds
      );
      if (cleanCategorySetIds.length !== 1) {
        throw new Error(`Expect a valid Categorie Set Id`);
      }

      const categorySetId = cleanCategorySetIds[0];

      const auditItemsPairsToAdd = flatten(
        addTemplates.map(template => {
          return toPairs(template.audit_items).map(([auditItemId, auditItem]): [
            string,
            AuditItem
          ] => {
            return [
              fieldPath(FieldPartNames.AUDIT_ITEMS, auditItemId),
              pick(auditItem, ["question", "labels"]),
            ];
          });
        })
      );

      const auditItemsPairsToRemove = flatten(
        removeTemplates.map(template => {
          return flatten(
            toPairs(template.audit_items).map(([auditItemId, auditItem]): [
              string,
              firebase.firestore.FieldValue
            ][] => {
              return [
                [
                  fieldPath(FieldPartNames.AUDIT_ITEMS, auditItemId),
                  firebase.firestore.FieldValue.delete(),
                ],
                [
                  fieldPath(FieldPartNames.QUESTION_NOTES, auditItemId),
                  firebase.firestore.FieldValue.delete(),
                ],
              ];
            })
          );
        })
      );

      // Activate all Categories
      const additionalAuditItems = flatten(
        addTemplates.map(template => values(template.audit_items))
      );

      const auditItems = [
        ...values(
          omit(
            auditResult.auditItems,
            flatten(removeTemplates.map(template => keys(template.audit_items)))
          )
        ),
        ...additionalAuditItems,
      ];
      const relevantCategoryIds = uniq(
        flatten(
          auditItems.map(auditItem => {
            return extractSignificantCategories(
              auditItem.question.categoryRef,
              categoryLevel
            );
          })
        )
      );
      const relevantLables = uniq(
        flatten(
          auditItems.map(auditItem => {
            const labels = uniq(auditItem.labels ?? []);
            if (labels.length) {
              return labels;
            } else {
              return null;
            }
          })
        )
      );

      const activePreselectionFilters = [
        ...relevantCategoryIds.map((categoryId): Filter => {
          return {
            aggregationId: FILTER_CATEGORY,
            value: categoryId,
          };
        }),
        ...relevantLables.map((label): Filter => {
          return {
            aggregationId: FILTER_LABELS,
            value: label,
          };
        }),
      ];
      const auditMetadataUpdate: Dictionary<any> = {
        categorySetId,
        "auditPreparation.step": "preselection",
        "auditPreparation.activePreselectionFilters": activePreselectionFilters,
        "auditPreparation.manuallyConsideredAuditItemIds": {},
        "auditPreparation.templateIds": templateIds,
        ...docVersionUpdate,
      };

      const auditResultUpdate: Dictionary<any> = {
        ...fromPairs(auditItemsPairsToRemove),
        ...fromPairs(auditItemsPairsToAdd),
        ...docVersionUpdate,
      };

      transaction
        .update(auditMetadataDocRef, auditMetadataUpdate)
        .update(auditResultDocRef, auditResultUpdate);
    };

    await firebase
      .firestore()
      .runTransaction(
        templateIds.length === 0
          ? clearTemplatesHandler
          : updateTemplatesHandler
      );
  },
  async [n.togglePreselectionFilter](
    { rootGetters, state },
    filter: Filter
  ): Promise<void> {
    const auditId = state.auditId;
    if (!auditId) {
      throw new Error(`No auditId set`);
    }

    const db = firebase.firestore();
    const auditMetadataDocRef = db
      .collection(CollectionNames.AUDITS)
      .doc(auditId);

    const auditMetadataLoader =
      buildTransactionalAuditMetadataLoader(auditMetadataDocRef);

    const docVersionUpdate = rdu(rootGetters);
    await db.runTransaction(async transaction => {
      const auditMetadata = await auditMetadataLoader(transaction);
      const auditPreparation = auditMetadata.auditPreparation;
      if (auditPreparation) {
        const activePreselectionFilters = [
          ...auditPreparation.activePreselectionFilters,
        ];
        const withOutFilter = activePreselectionFilters.filter(activeFilter => {
          return !isEqual(filter, activeFilter);
        });

        const toggledPreselectionFilters =
          activePreselectionFilters.length > withOutFilter.length
            ? withOutFilter
            : [...activePreselectionFilters, filter];

        const updateData: firebase.firestore.UpdateData = {
          "auditPreparation.activePreselectionFilters":
            toggledPreselectionFilters,
          ...docVersionUpdate,
        };

        transaction.update(auditMetadataDocRef, updateData);
      } else {
        throw new Error(
          "Found invalid audit format. Expect auditPreparation property"
        );
      }
    });
  },
  async [n.setAllPreselectionFilter](
    { getters, rootGetters, commit, state },
    aggregationId: Filter["aggregationId"]
  ): Promise<void> {
    const aggregations = getters[
      gn.getPreselectionFilterAggregations
    ] as Aggregation[];
    const aggregation = aggregations.find(
      aggregation => aggregation.id === aggregationId
    );

    if (!aggregation) {
      throw new Error(`Got unexpected aggregations id ${aggregationId}`);
    }

    const auditId = state.auditId;
    if (!auditId) {
      throw new Error(`No auditId set`);
    }

    const db = firebase.firestore();
    const auditMetadataDocRef = db
      .collection(CollectionNames.AUDITS)
      .doc(auditId);

    const auditMetadataLoader =
      buildTransactionalAuditMetadataLoader(auditMetadataDocRef);

    const filters = aggregation.buckets.map(bucket => {
      return {
        aggregationId,
        value: bucket.value,
      };
    });

    const docVersionUpdate = rdu(rootGetters);
    await db.runTransaction(async transaction => {
      const auditMetadata = await auditMetadataLoader(transaction);
      const auditPreparation = auditMetadata.auditPreparation;
      if (auditPreparation) {
        const cleanedFilter = auditPreparation.activePreselectionFilters.filter(
          filter => filter.aggregationId !== aggregationId
        );

        const updateData: firebase.firestore.UpdateData = {
          "auditPreparation.activePreselectionFilters": [
            ...cleanedFilter,
            ...filters,
          ],
          ...docVersionUpdate,
        };

        transaction.update(auditMetadataDocRef, updateData);
      }
    });
  },
  async [n.clearPreselectionFilter](
    { getters, rootGetters, commit, state },
    aggregationId: Filter["aggregationId"]
  ): Promise<void> {
    const auditId = state.auditId;
    if (!auditId) {
      throw new Error(`No auditId set`);
    }

    const db = firebase.firestore();
    const auditMetadataDocRef = db
      .collection(CollectionNames.AUDITS)
      .doc(auditId);

    const auditMetadataLoader =
      buildTransactionalAuditMetadataLoader(auditMetadataDocRef);

    const docVersionUpdate = rdu(rootGetters);
    await db.runTransaction(async transaction => {
      const auditMetadata = await auditMetadataLoader(transaction);
      const auditPreparation = auditMetadata.auditPreparation;
      if (auditPreparation) {
        const cleanedFilter = auditPreparation.activePreselectionFilters.filter(
          filter => filter.aggregationId !== aggregationId
        );

        const updateData: firebase.firestore.UpdateData = {
          "auditPreparation.activePreselectionFilters": [...cleanedFilter],
          ...docVersionUpdate,
        };

        transaction.update(auditMetadataDocRef, updateData);
      }
    });
  },
  async [n.manuallyAddToConsideredAuditItems](
    { getters, rootGetters, commit },
    auditItemIds: Array<AuditItemWithId["id"]>
  ): Promise<void> {
    const auditId = getters[gn.getAuditId] as string | null;

    if (auditId) {
      commit(mn.SET_BULK_UPDATING_STATE, true);
      const db = firebase.firestore();
      const updateResult = await updateManuallyConsideredAuditItem(
        auditId,
        auditItemIds,
        "include",
        rootGetters,
        { db },
        auditMetadata => {
          return (
            auditMetadata.workflow.status === AuditStatusId.Preparation &&
            auditMetadata.auditPreparation.step === "manual-selection"
          );
        }
      );
      commit(mn.SET_BULK_UPDATING_STATE, false);
      return updateResult;
    } else {
      throw new Error("no-audit-active");
    }
  },
  async [n.manuallyRemoveFromConsideredAuditItems](
    { getters, rootGetters, commit },
    auditItemIds: Array<AuditItemWithId["id"]>
  ): Promise<void> {
    const auditId = getters[gn.getAuditId] as string | null;

    if (auditId) {
      commit(mn.SET_BULK_UPDATING_STATE, true);

      const db = firebase.firestore();
      const updateResult = await updateManuallyConsideredAuditItem(
        auditId,
        auditItemIds,
        "exclude",
        rootGetters,
        { db },
        auditMetadata => {
          return (
            auditMetadata.workflow.status === AuditStatusId.Preparation &&
            auditMetadata.auditPreparation.step === "manual-selection"
          );
        }
      );
      commit(mn.SET_BULK_UPDATING_STATE, false);
      return updateResult;
    } else {
      throw new Error("no-audit-active");
    }
  },
  async [n.manuallyAddAuditItemsToSelfAssessment](
    { getters, rootGetters, commit },
    auditItemIds: Array<AuditItemWithId["id"]>
  ): Promise<void> {
    const auditId = getters[gn.getAuditId] as string | null;
    if (auditId) {
      commit(mn.SET_BULK_UPDATING_STATE, true);

      const db = firebase.firestore();
      await updateSelfAssessmentEnabledAuditItems(
        auditId,
        auditItemIds,
        "enable",
        rootGetters,
        { db }
      );
      commit(mn.SET_BULK_UPDATING_STATE, false);
    } else {
      throw new Error("no-audit-active");
    }
  },
  async [n.manuallyRemoveAuditItemsFromSelfAssessment](
    { getters, rootGetters, commit },
    auditItemIds: Array<AuditItemWithId["id"]>
  ): Promise<void> {
    const auditId = getters[gn.getAuditId] as string | null;
    if (auditId) {
      commit(mn.SET_BULK_UPDATING_STATE, true);

      const db = firebase.firestore();
      await updateSelfAssessmentEnabledAuditItems(
        auditId,
        auditItemIds,
        "disable",
        rootGetters,
        { db }
      );
      commit(mn.SET_BULK_UPDATING_STATE, false);
    } else {
      throw new Error("no-audit-active");
    }
  },
  async [n.updateTags](
    { getters, rootGetters },
    payload: {
      auditItemIds: Array<AuditItemWithId["id"]>;
      tagsToAdd: string[];
      tagsToRemove: string[];
    }
  ): Promise<void> {
    const auditId = getters[gn.getAuditId] as string | null;
    if (auditId) {
      const db = firebase.firestore();
      await updateAuditItemsTags(
        auditId,
        payload.auditItemIds,
        {
          tagsToAdd: payload.tagsToAdd,
          tagsToRemove: payload.tagsToRemove,
          action: "update",
        },
        rootGetters,
        { db }
      );
    } else {
      throw new Error("no-audit-active");
    }
  },
  async [n.clearTags](
    { getters, rootGetters },
    payload: { auditItemIds: Array<AuditItemWithId["id"]> }
  ): Promise<void> {
    const auditId = getters[gn.getAuditId] as string | null;
    if (auditId) {
      const db = firebase.firestore();
      await updateAuditItemsTags(
        auditId,
        payload.auditItemIds,
        { action: "clear" },
        rootGetters,
        { db }
      );
    } else {
      throw new Error("no-audit-active");
    }
  },
  async [n.setNote](
    { getters, rootGetters },
    payload: { auditItemId: AuditItemWithId["id"]; note: string }
  ): Promise<void> {
    const auditId = getters[gn.getAuditId] as string | null;
    const mappedNotes = getters[gn.getMappedNotes] as Map<
      AuditItemWithId["id"],
      QuestionNoteWithId | null
    >;

    if (auditId) {
      const db = firebase.firestore();
      await updateQuestionNote(
        auditId,
        payload.auditItemId,
        { type: "set", text: payload.note },
        rootGetters,
        {
          db,
        }
      );
    } else {
      throw new Error("no-audit-active");
    }
  },
  async [n.deleteNote](
    { getters, rootGetters },
    payload: { auditItemId: AuditItemWithId["id"] }
  ): Promise<void> {
    const auditId = getters[gn.getAuditId] as string | null;
    const mappedNotes = getters[gn.getMappedNotes] as Map<
      AuditItemWithId["id"],
      QuestionNoteWithId | null
    >;
    const noteId = mappedNotes.get(payload.auditItemId)?.id;

    if (auditId) {
      const db = firebase.firestore();
      await updateQuestionNote(
        auditId,
        payload.auditItemId,
        { type: "delete" },
        rootGetters,
        {
          db,
        }
      );
    } else {
      throw new Error("no-audit-active");
    }
  },
  async [n.switchToPreparationState](
    { getters, rootGetters, dispatch },
    { newState }: { newState: PreparationStates }
  ): Promise<void> {
    const auditId = getters[gn.getAuditId] as string | null;
    if (!auditId) {
      throw new Error("Expect valid auditId");
    }
    const auditClass = getters[gn.getAuditClass] as nullable<AuditClassClient>;
    const isMultidimensionAuditWithStandards =
      auditClass?.dimensionSource === "standard";

    const db = firebase.firestore();
    const auditMetadataDocRef = db
      .collection(CollectionNames.AUDITS)
      .doc(auditId);

    const auditResultDocRef = db
      .collection(CollectionNames.AUDITS)
      .doc(auditId)
      .collection(CollectionNames.PRIVATE)
      .doc(DocumentNames.AUDIT_ITEMS);

    const manuallyConsideredAuditItemIdsPath = fieldPath(
      FieldPartNames.AUDIT_METADATA_PREPARATION,
      FieldPartNames.MANUALLY_CONSIDERED_AUDIT_ITEM_IDS
    );

    if (getters[gn.getIsVdaMatrixRequired] && newState === "manual-selection") {
      await dispatch(n.cloneVdaProcessQuestions);
    }

    await db.runTransaction(async transaction => {
      const auditMetadata = (
        await transaction.get(auditMetadataDocRef)
      ).data() as AuditMetadataDoc | undefined;
      if (!auditMetadata) {
        throw Error("audit doc not found.");
      }

      const auditResult = (await transaction.get(auditResultDocRef)).data() as
        | AuditResultDoc
        | undefined;
      if (!auditResult) {
        throw Error("audit result doc not found.");
      }
      const storedAuditItems = auditResult.auditItems;

      if (auditMetadata.workflow.status !== AuditStatusId.Preparation) {
        throw new Error(
          "Preparation State changes are only allow during the audit preparation"
        );
      }
      const currentState = auditMetadata.auditPreparation.step;
      if (currentState === newState) {
        return;
      }

      const isForwardTransitionToManualSelection =
        currentState === "preselection" && newState === "manual-selection";
      const isForwardTransitionToSelfAssessmentSelection =
        currentState === "manual-selection" &&
        newState === "self-assessment-selection" &&
        auditMetadata.includeSelfAssessment === true;
      const isBackwardTransition =
        (newState === "manual-selection" &&
          currentState === "self-assessment-selection") ||
        (newState === "preselection" &&
          currentState === "self-assessment-selection") ||
        (newState === "preselection" && currentState === "manual-selection");

      const updateData: firebase.firestore.UpdateData = {
        ...rdu(rootGetters),
        [fieldPath(
          FieldPartNames.AUDIT_METADATA_PREPARATION,
          FieldPartNames.STEP
        )]: newState,
      };

      if (isForwardTransitionToManualSelection) {
        const auditItemFilterTree = buildFilterTreeFromAuditItems(
          getters,
          storedAuditItems
        );
        const preselectionFilter = uniqWith(
          [
            ...auditMetadata.auditPreparation.activePreselectionFilters,
            ...calcPreselectionFilterBasedOnTheAuditMetadata(
              auditMetadata,
              isMultidimensionAuditWithStandards
            ),
          ],
          isEqual
        );

        const preselectedAuditItemIds = new Set(
          calculateFilteredItemIds(
            auditItemFilterTree,
            preselectionFilter,
            keys(storedAuditItems)
          )
        );
        updateData[fieldPath(FieldPartNames.TOTAL_CONSIDERED_AUDIT_ITEM_IDS)] =
          [...preselectedAuditItemIds];

        // cleanup manual includes/excludes
        // (remove includes for preselected items, remove excludes for non-preselected items)
        forEach(
          auditMetadata.auditPreparation.manuallyConsideredAuditItemIds,
          (state, auditItemId) => {
            if (
              (state === "include" &&
                preselectedAuditItemIds.has(auditItemId)) ||
              (state === "exclude" && !preselectedAuditItemIds.has(auditItemId))
            ) {
              updateData[
                fieldPath(manuallyConsideredAuditItemIdsPath, auditItemId)
              ] = firebase.firestore.FieldValue.delete();
            }
          }
        );
      } else if (isForwardTransitionToSelfAssessmentSelection) {
        // cleanup selfAssessmentEnabledAuditItemIds (remove items not considered)

        const consideredAuditItemIds = new Set(
          calculateTotalConsideredAuditItemIds(
            auditMetadata.totalConsideredAuditItemIds ?? [],
            auditMetadata.auditPreparation.manuallyConsideredAuditItemIds
          )
        );

        updateData[
          fieldPath(FieldPartNames.SELF_ASSESSMENT_ENABLED_AUDIT_ITEM_IDS)
        ] = auditMetadata.selfAssessmentEnabledAuditItemIds.filter(
          auditItemId => consideredAuditItemIds.has(auditItemId)
        );
      } else if (!isBackwardTransition) {
        throw new Error(
          `No Transition found for state changes form=${currentState} to=${newState}`
        );
      }

      transaction.update(auditMetadataDocRef, updateData);
    });
  },
  async [n.createRequiredMeasuresForPolicies](
    { getters, rootGetters, dispatch },
    {
      dueDate,
      assignedTo,
      defaultText,
      measureTypeId,
    }: {
      dueDate: string;
      assignedTo: string;
      defaultText: string;
      measureTypeId: string;
    }
  ): Promise<AsyncHandlerFunctionResponse> {
    const auditId = getters[gn.getAuditId] as string | null;

    const policyViolations = getters[
      gn.getMeasurePolicyViolations
    ] as MeasurePolicyViolation[];

    if (!auditId || policyViolations.length === 0) {
      return null;
    }

    const findings = getters[gn.getFindingsMap] as {
      [findingId: string]: Finding;
    };

    const strictViolations = policyViolations.filter(
      v => v.violationLevel === "strict" && v.measureTypeId === measureTypeId
    );

    const measuresCreationData: ApiV0CreateMeasureProcessesForAuditRequest = [];

    strictViolations.forEach(strictViolation => {
      strictViolation.findingIds.forEach(findingId => {
        const finding = findings[findingId];
        if (!finding) {
          return;
        }

        finding.auditItemRefs.forEach(auditItemRef => {
          measuresCreationData.push({
            auditItemId: auditItemRef.auditItemId,
            findingId,
            content: {
              assignedTo,
              dueDate,
              measureTypeId: strictViolation.measureTypeId,
              text: defaultText,
            },
          });
        });
      });
    });

    const res = await createMeasures(auditId, measuresCreationData);

    const errors = res.filter(v => v.result.type === "error");

    if (errors.length > 0) {
      return {
        type: "warning",
        payload: {
          errorCount: errors.length,
          count: res.length,
        },
        message: {
          en: `${res.length} measure(s) created, ${errors.length} failed`,
          de: `${res.length} Maßnahme(n) erstellt, ${errors.length} fehlgeschlagen`,
        },
      };
    } else {
      return {
        type: "success",
        payload: { count: res.length },
        message: {
          en: `${res.length} measure(s) created`,
          de: `${res.length} Maßnahme(n) erstellt`,
        },
      };
    }
  },
};

export { n as actionNames, actions };
