


































import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import FormsControlMixin from "@/components/mixins/FormsControlMixin.vue";

@Component({
  mixins: [FormsControlMixin],
})
export default class ATextareaForm extends Mixins(FormsControlMixin) {
  @Prop({ type: String, required: true })
  value!: string;

  @Prop({ type: String, required: true })
  title!: string;

  @Prop({ type: Boolean, required: true })
  loading!: boolean;

  created() {
    this.mixinInitFormData(this.value);
  }

  save() {
    this.$emit("save", this.editValues);
  }

  close() {
    if (this.isDirty) {
      if (window.confirm(this.$ft("common.confirms.unsaved_changes"))) {
        this.$emit("close");
      }
    } else {
      this.$emit("close");
    }
  }
}
