








import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import {
  api,
  Mutations,
  Getters,
  AsyncHandlerFunction,
} from "@/store/modules/asyncHandler";
export type { AsyncHandlerFunction } from "@/store/modules/asyncHandler";
import { isArray } from "lodash";

@Component
export default class AAsyncBtn extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  operationId!: string;

  @Prop({
    type: Function,
    required: true,
  })
  handler!: AsyncHandlerFunction;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  loading!: boolean;

  get props() {
    return {
      ...this.$attrs,
      ...this.$props,
      loading: this.loadingStatus,
      disabled: this.disabledStatus,
    };
  }

  get actions() {
    const clickHandler: Function | Function[] | null =
      this.$listeners["click"] ?? null;
    const click = clickHandler
      ? isArray(clickHandler)
        ? [...clickHandler, this.click]
        : [clickHandler, this.click]
      : this.click;

    return {
      ...this.$listeners,
      click,
    };
  }

  @Getter(api.getters.getIsOperationRunning, {
    namespace: api.namespace,
  })
  isOperationRunning!: Getters["getIsOperationRunning"];

  @Mutation(api.mutations.START_OPERATION, {
    namespace: api.namespace,
  })
  startOperation!: Mutations["START_OPERATION"];

  @Mutation(api.mutations.STOP_OPERATION, {
    namespace: api.namespace,
  })
  stopOperation!: Mutations["STOP_OPERATION"];

  @Mutation(api.mutations.ADD_ERROR, {
    namespace: api.namespace,
  })
  addError!: Mutations["ADD_ERROR"];

  @Mutation(api.mutations.ADD_MSG, {
    namespace: api.namespace,
  })
  addMsg!: Mutations["ADD_MSG"];

  get isBlockingActionRunning(): boolean {
    return false;
  }

  get disabledStatus(): boolean {
    return this.disabled || this.isBlockingActionRunning;
  }

  get loadingStatus() {
    return this.loading || this.isOperationRunning(this.operationId);
  }

  click(evt: PointerEvent) {
    const operationId = this.operationId;
    this.startOperation(operationId);

    this.handler()
      .then(resp => {
        if (resp) {
          this.addMsg(resp);
        }
      })
      .catch(err => {
        this.addError({
          operationId,
          error: err,
        });
      })
      .finally(() => {
        this.stopOperation(operationId);
      });
  }
}
