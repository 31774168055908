import { WatchableCollection } from "@/utils/firestore";
import { RootState } from "@/store/types";
import { CollectionNames } from "@auditcloud/shared/lib/types/common";
import { GroupDoc } from "@auditcloud/shared/lib/schemas";

export class UserGroupsState extends WatchableCollection<GroupDoc, RootState> {
  constructor() {
    super(CollectionNames.GROUPS, "GROUPS");
  }
}
