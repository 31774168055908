




























import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter } from "vuex-class";

import { VChip, VAvatar, VIcon } from "vuetify/lib";
import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";
import { api } from "@/store/modules/users";
import { UserInfoResolver } from "@/types/User";

@Component({
  components: { VChip, VAvatar, VIcon },
})
export default class AUserChip extends Vue {
  @Prop({
    default: null,
    type: [String, Object],
  })
  readonly user!: IUserRef | string | null;

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly disabled!: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly dark!: boolean;

  @Getter(api.getters.getUser, {
    namespace: api.namespace,
  })
  getUser!: UserInfoResolver;

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly close!: boolean;

  get userId() {
    if (this.user instanceof Object) {
      return this.user.id;
    } else {
      return this.user;
    }
  }

  get isUser(): boolean {
    return (
      this.user instanceof Object &&
      typeof this.user.id === "string" &&
      typeof this.user.displayName === "string"
    );
  }

  get storedUser(): {
    id: string;
    displayName: string;
  } | null {
    if (this.isUser) {
      return this.user as {
        id: string;
        displayName: string;
      };
    } else if (this.userId) {
      return this.getUser(this.userId) || null;
    } else {
      return null;
    }
  }

  get userIdTitle() {
    if (typeof this.user === "string") {
      return this.user;
    } else {
      return undefined;
    }
  }

  get displayName(): string {
    const DEFAULT_DISPLAY_NAME = "Unbekannt";
    if (this.storedUser) {
      return this.storedUser.displayName;
    } else {
      return DEFAULT_DISPLAY_NAME;
    }
  }

  get avatar(): string | null {
    return null;
  }

  emitActivate(evt: Event) {
    const user = this.userId;
    if (user) {
      this.$emit("activate", user);
    }
    this.$emit("click");
  }

  emitClose() {
    this.$emit("click:close", this.userId);
  }
}
