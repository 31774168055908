import { mapValues } from "lodash";
import { State } from "./types";
import { RootState } from "../../types";
// import { mutationNames as mn } from "./mutations";
import { ActionTypeContext } from "@/utils/VuexHelper";
import { Getters } from ".";

type Context = ActionTypeContext<State, RootState, Getters>;

const actions = {};

const n = mapValues(actions, (_, key) => key);

export { n as actionNames, actions };
