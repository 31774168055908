
















































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  TodoAny,
  TodoActionSignature,
} from "@auditcloud/shared/lib/utils/type-guards";

import { api } from "@/store/modules/app";
import {
  api as userApi,
  Getters as UserGetters,
  Actions as UserActions,
} from "@/store/modules/user";
import { ROUTE_NAMES } from "../../routenames";
import AUserInfoDialog from "@/components/dialogs/AUserInfoDialog.vue";
@Component({ components: { AUserInfoDialog } })
export default class AProfileWidget extends Vue {
  openDialog: boolean = false;
  @Prop({
    type: Boolean,
    default: false,
  })
  icon!: boolean;

  @Getter(api.getters.getCurrentLanguage, { namespace: api.namespace })
  getCurrentLanguage!: TodoAny;

  @Getter(api.getters.getKnownLanguage, { namespace: api.namespace })
  getKnownLanguage!: TodoAny;

  get currentAvatar() {
    const displayName = this.$user.displayName();
    if (displayName) {
      return displayName.substring(0, 1) || "-";
    } else {
      return "-";
    }
  }

  get userActions() {
    if (this.isAuthenticated) {
      return [
        {
          icon: "exit_to_app",
          text: this.$t("common.sign_out"),
          route: "/signout",
        },
      ];
    } else {
      return [
        {
          icon: "lock_open",
          text: this.$t("views.signin.sign_in"),
          route: "/signin",
        },
      ];
    }
  }

  @Action(api.actions.setLanguage, { namespace: api.namespace })
  setLanguage!: TodoActionSignature;

  @Getter(userApi.getters.isAuthenticated, { namespace: userApi.namespace })
  isAuthenticated!: UserGetters["isAuthenticated"];

  @Action(userApi.actions.userSignOut, { namespace: userApi.namespace })
  userSignOut!: UserActions["userSignOut"];

  isActiveLanguage(language: string): boolean {
    return this.getCurrentLanguage === language;
  }

  logout() {
    console.log("Loging Out");
    this.userSignOut();
    this.$router.push({
      name: ROUTE_NAMES.SIGNIN,
    });
  }

  editUser() {
    this.openDialog = true;
  }

  changeLanguage(language: string) {
    const lang = this.getCurrentLanguage;
    console.log("changeLanguage", lang);
    if (lang !== language) {
      this.setLanguage(language);
      this.$i18n.locale = language;
    }
  }
}
