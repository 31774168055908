













import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";

import { api as confApi } from "@/store/modules/configuration";

import {
  AuditItemCategorySetMap,
  AuditItemCategory,
} from "@auditcloud/shared/lib/types/AuditItemCategory";
import {
  CategoryLevel,
  EmbeddedCategories,
} from "@auditcloud/shared/lib/schemas";

import ASnippetCategory from "./ASnippetCategory.vue";

@Component({
  components: { ASnippetCategory },
})
export default class ASnippetCategoryRef extends Vue {
  @Prop({
    required: true,
    type: Array,
  })
  categoryRef!: string[];

  @Prop({
    type: String,
    required: true,
  })
  categorySetId!: string;

  @Prop({
    type: [Object, String],
    required: true,
  })
  categoryLevel!: CategoryLevel;

  @Prop({
    default: false,
    type: Boolean,
  })
  filter!: boolean;

  @Prop({
    default: "primary",
    type: String,
  })
  color!: string;

  @Prop({
    type: Array,
    default: () => [],
  })
  customClasses!: any;

  @Prop({
    default: false,
    type: Boolean,
  })
  label!: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  showCategoriesInSingleRow!: boolean;

  @Getter(confApi.getters.getCategoryMapping, {
    namespace: confApi.namespace,
  })
  mappedCategorySet!: AuditItemCategorySetMap;

  get category(): EmbeddedCategories {
    return {
      categorySetId: this.categorySetId,
      categoryPath: this.categories,
    };
  }

  get categorySet() {
    if (this.categorySetId !== null) {
      return this.mappedCategorySet[this.categorySetId] || null;
    } else {
      return null;
    }
  }

  get allCategories(): AuditItemCategory[] {
    if (this.categorySet === null) {
      return [];
    } else {
      return this.categorySet.categories;
    }
  }

  get categories(): AuditItemCategory[] {
    const enrichedVal: AuditItemCategory[] = [];
    this.categoryRef.forEach((id, idx) => {
      const category =
        idx === 0
          ? this.allCategories.find(c => c.id === id)
          : enrichedVal[idx - 1].subcategories.find(c => c.id === id);

      if (category) {
        enrichedVal.push(category);
      } else {
        enrichedVal.splice(idx);
      }
    });
    return enrichedVal;
  }

  passthrougth(category: any) {
    if (this.filter) {
      this.$emit("filter-selected", category);
    }
  }
}
