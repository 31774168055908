import {
  AUDITPLANNING,
  AUDITPREPARATION,
  AUDITEXECUTION,
  AUDITWRAPUP,
  AUDITREPORTING,
  AUDITACTIONLIST,
  AUDITFINALIZED,
} from "@auditcloud/shared/lib/constants";
export function dialogRoute(baseRoute: string, dialog: string) {
  return [baseRoute, dialog].join(".");
}

export function stripDialog(dialogRoute: string) {
  const parts = dialogRoute.split(".");
  if (parts.length >= 1) {
    return parts[0];
  } else {
    return "error";
  }
}

export function isDialogRoute(routeName?: string | null): boolean {
  return routeName?.includes(".") ?? false;
}

export const ROUTE_NAMES = {
  HOME: "home",
  HOME_CREATE_AUDIT: "homeCreateAudit",
  HOME_VIEW_AUDIT: "homeViewAudit",
  DASHBOARDSCOPED: "dashboardScoped",
  DASHBOARDSCOPED_CREATE_AUDIT: "dashboardScopedCreateAudit",
  DASHBOARDSCOPED_VIEW_AUDIT: "dashboardScopedViewAudit",
  DASHBOARDSCOPED_EDIT_MEASURE: "dashboardScopedEditMeasure",

  AUDITMATRIX: "auditmatrix",
  AUDITMATRIX_UNIT_YEAR_FILTERED: "auditmatrixByUnitAndYear",
  AUDITMATRIX_UNIT_PROGRAM_FILTERED: "auditmatrixByUnitAndProgram",
  AUDITMATRIX_YEAR_FILTERED: "auditmatrixByYear",
  AUDITMATRIX_PROGRAM_FILTERED: "auditmatrixByProgram",
  AUDIT_PROGRAMS: "auditPrograms",

  AUDIT: "audit",
  AUDITPLANNING,
  // AUDITPLANNING_METADATA: "auditPlanning",
  AUDITPREPARATION: AUDITPREPARATION,
  AUDITEXECUTION: AUDITEXECUTION,
  AUDITWRAPUP: AUDITWRAPUP,
  AUDITREPORTING: AUDITREPORTING,
  AUDITACTIONLIST: AUDITACTIONLIST,
  AUDITFINALIZED: AUDITFINALIZED,
  REPORTINGDASHBOARD: "reporting",
  MEASUREPROCESSMATRIX: "measureProcessMatrix",
  MEASUREPROCESSDIALOG: "measureProcessDialog",

  CAMERA: "camera",
  TEMPLATES: "templates",
  TEMPLATE: "template",
  TEMPLATE_METADATA: "templateMetadata",
  TEMPLATE_METADATA_OVERVIEW: "templateMetadataOverview",
  TEMPLATE_ITEM_DIALOG: "templateItemDialog",
  TEMPLATE_CREATE: "templateCreate",
  TEMPLATE_IMPORT: "templateImportDialog",
  TEMPLATE_DIALOG: "templateDialog",

  STANDARDS: "standards",
  MEASURES: "measures",
  MEASUREDIALOG: "measuredialog",
  STANDARD: "standard",
  SIGNIN: "signin",
  SIGNUP: "signup",
  RESETPASSWORD: "resetpassword",
  MANAGEMENT_SUMMARY: "managementSummary",
  SETTINGS: "settings",
  USERS: "users",
  USER_MANAGEMENT: "userManagement",
  USER_MANAGEMENT_USERS: "userManagementUsers",
  USER_MANAGEMENT_PERSONS: "userManagementPersons",
  USER_MANAGEMENT_ROLES: "userManagementRoles",
  USER_MANAGEMENT_GROUPS: "userManagementGroups",
  USER_MANAGEMENT_CREATE_USERS: "userManagementCreateUsers",
  USER_MANAGEMENT_USER_DETAILS: "userManagementUserDetails",
  USER: "user",
  CONFIGURATION: "configuration",
  CONFIGURATION_SEGMENT: "configurationSegment",
  CONFIGURATION_FORMSCHEMA: "configurationFormschema",
  CONFIGURATION_EMAIL_TEMPLATE: "configurationEmailTemplate",
  CONFIGURATION_METADATA: "configurationMetadata",
  CONFIGURATION_AUDITCLASS: "configurationAuditclass",
  CONFIGURATION_I18N: "configurationI18n",
  CONFIGURATION_AUDIT: "configurationAudit",
  CONFIGURATION_PRIVATES: "configurationInternals",
  CONFIGURATION_LISTS: "configurationLists",
  SANITY_CHECK: "sanityCheck",
  CUSTOMERDATA: "customerdata",
  SCHEMA_FORM_EDIT: "schemaFormEdit",
} as const;

export enum DIALOG_NAMES {
  METADATA_DIALOG = "metadata",
  AUDIT_INFO_DIALOG = "auditInfoDialog",
  AUDITMATRIX_CREATE = "auditmatrixCreate",
  AUDITMATRIX_EDIT = "auditmatrixEdit",
  FINDING = "finding",
  MEASURE = "measure",
  NEXT = "next",
  FILTER = "filter",
  SORT = "sort",
  REPORTING_DIALOG = "reportingDialog",
  AUDIT_ATTACHMENTS = "attachments",
  AUDIT_DATA = "audit-data",
  ACTIVATE_SELF_ASSESSMENT = "self-assessment",
  ACTIVATE_EXTERNAL_MEASURE_PROCESSING = "external-measures",
  PRINT_FILTER_DIALOG = "printPreparation",
  INTERMEDIATE_RESULT_DIALOG = "intermediateResultDialog",
  MEASURES_CREATE_DIALOG = "measuresCreateDialog",
}

export enum DIALOG_ACTIONS {
  CREATE = "create",
  EDIT = "edit",
  VIEW = "view",
}

export const SIGNIN_PATH = "/signin";
