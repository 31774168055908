import {
  ChapterRefType,
  AuditStandardRef,
  TranslateableText,
} from "@auditcloud/shared/lib/schemas";
import { typeIsTranslateableText } from "@auditcloud/shared/lib/types/common";

export type AuditStandardId = string;
export type AuditChapterId = string;

export interface AuditStandardChapter {
  id: AuditStandardId;
  description: TranslateableText;
}

export interface AuditStandard {
  id?: AuditStandardId;
  chapters: AuditStandardChapter[];
  name: TranslateableText;
  description: TranslateableText;
}

export type AuditStandardResolver = (
  standardId: AuditStandardId
) => AuditStandardRef;

export function validatingCastAuditStandardChapter(
  value: any
): AuditStandardChapter {
  if (
    typeof value.id === "string" &&
    typeIsTranslateableText(value.description)
  ) {
    return {
      id: value.id,
      description: value.description,
    };
  } else {
    console.error("expect AuditStandardChapter got ", value);
    throw new TypeError(`Invalid AuditStandardChapter`);
  }
}

export function validatingCastAuditStandard(
  value: any,
  id?: string
): AuditStandard {
  if (
    typeIsTranslateableText(value.description) &&
    typeIsTranslateableText(value.name) &&
    value.chapters instanceof Array
  ) {
    let chapters: AuditStandardChapter[] = [];
    try {
      chapters = value.chapters.map((chapter: any) =>
        validatingCastAuditStandardChapter(chapter)
      );
    } catch (err) {
      console.error(
        "expect AuditStandardChapter[] got ",
        value.chapters,
        " in ",
        value
      );
      throw err;
    }

    const response: AuditStandard = {
      name: value.name,
      description: value.description,
      chapters,
    };

    if (id) {
      response.id = id;
    }
    return response;
  } else {
    console.error("expect AuditStandard got ", value);
    throw new TypeError(`Invalid AuditStandard`);
  }
}

export function createStandardRef(
  standard: AuditStandard,
  id?: string
): AuditStandardRef {
  if (typeof standard.id === "string" && typeof id === "undefined") {
    return {
      id: standard.id,
      name: standard.name,
    };
  } else if (
    (typeof standard.id === "undefined" && typeof id === "string") ||
    (typeof standard.id === "string" && standard.id === id)
  ) {
    return {
      id,
      name: standard.name,
    };
  } else {
    throw new Error(
      `createStandardRef failed ${typeof standard.id} ${
        standard.id
      } vs. ${typeof id} ${id}`
    );
  }
}

export function createChapterRefs(
  standard: AuditStandard,
  id?: string
): ChapterRefType[] {
  const standardRef = createStandardRef(standard, id);
  return standard.chapters.map(chapter => {
    return {
      standardName: standardRef.name,
      standardId: standardRef.id,
      chapterId: chapter.id,
      chapterDescription: chapter.description,
    };
  });
}
