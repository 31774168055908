










import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

import {
  MeasureProcessStep,
  MeasureProcessStepDoc,
  MeasureProcessStepContextForceTransition,
} from "@auditcloud/shared/lib/schemas";

import { idable } from "@auditcloud/shared/lib/types/common";
import {
  api as measureApi,
  Getters as MeasureGetters,
} from "@/store/modules/measure";

import { StepType } from "@auditcloud/shared/lib/workflow/types/State";
import { isString } from "lodash";

const measureModule = namespace(measureApi.namespace);

@Component({})
export default class AMeasureProcessStepControlForceTransition extends Vue {
  @Prop({
    type: String,
  })
  readonly stepId!: string;

  @measureModule.Getter(measureApi.getters.getProcessSteps, {
    namespace: measureApi.namespace,
  })
  processSteps!: MeasureGetters["getProcessSteps"];

  get step(): null | idable<MeasureProcessStepDoc> {
    return this.processSteps.find(step => step.id === this.stepId) ?? null;
  }

  get stepContext(): null | MeasureProcessStepContextForceTransition {
    const stepContext: null | MeasureProcessStep["stepContext"] =
      this.step?.stepContext ?? null;

    if (
      stepContext == null ||
      stepContext?.type !== StepType.FORCE_TRANSITION
    ) {
      console.warn("No valid stepContext: ", this.stepId, stepContext?.type);
      return null;
    }
    return stepContext;
  }
  get stepDone() {
    return isString(this.step?.workflowInfo.leftAt);
  }
}
