






import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AAuditQuestionAdditionalContentBox extends Vue {
  @Prop({
    default: false,
    type: Boolean,
  })
  readonly greyedBackground!: boolean;

  get backgroundClass(): string {
    return this.greyedBackground ? "greyBackground" : "";
  }
}
