









































































import Vue from "vue";
import Ajv from "ajv";
import { Component, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

import AAxiosAlert from "../../snippets/AAxiosAlert.vue";
import {
  MeasureProcessStep,
  MeasureProcessStepDoc,
  MeasureProcessStepContextSimpleMessage,
} from "@auditcloud/shared/lib/schemas";

import { idable } from "@auditcloud/shared/lib/types/common";
import {
  api as measureApi,
  Getters as MeasureGetters,
  Mutations as MeasureMutations,
  Actions as MeasureActions,
} from "@/store/modules/measure";

import {
  compilePropertyValidationRules,
  providePropertyMeta,
} from "./utils/stepContext";
import {
  State,
  StepConfigPropertyMeta,
  StepConfigSimpleMessage,
  StepType,
} from "@auditcloud/shared/lib/workflow/types/State";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
const measureModule = namespace(measureApi.namespace);

type VForm = Vue & { validate: () => void };

@Component({
  components: { AAxiosAlert },
})
export default class AMeasureProcessStepControlSimpleMessage extends Vue {
  @Prop({
    type: String,
  })
  readonly stepId!: string;

  @Prop({
    type: Boolean,
    default: true,
  })
  readonly readonly!: boolean;

  @measureModule.Getter(measureApi.getters.getWorkflow, {
    namespace: measureApi.namespace,
  })
  workflow!: MeasureGetters["getWorkflow"];

  @measureModule.Getter(measureApi.getters.getCurrentMeasure, {
    namespace: measureApi.namespace,
  })
  currentMeasure!: MeasureGetters["getCurrentMeasure"];

  @measureModule.Getter(measureApi.getters.getProcessSteps, {
    namespace: measureApi.namespace,
  })
  processSteps!: MeasureGetters["getProcessSteps"];

  @measureModule.Mutation(measureApi.mutations.SET_CURRENT_STEP_DIRTY)
  setCurrentStepDirty!: MeasureMutations["SET_CURRENT_STEP_DIRTY"];

  @measureModule.Action(measureApi.actions.patchMeasureStep)
  patchMeasureStep!: MeasureActions["patchMeasureStep"];

  isValid = true;
  loading = false;
  requestPromise: Promise<unknown> = Promise.resolve();

  lazyDescription: string | null = null;

  @Watch("stepContext.description")
  onStepContextDescriptionChange(newValue) {
    if (newValue === this.lazyDescription) {
      this.lazyDescription = null;
    }
  }

  get step(): null | idable<MeasureProcessStepDoc> {
    return this.processSteps.find(step => step.id === this.stepId) ?? null;
  }

  get stateConfig(): State<MeasureProcessDocument> | null {
    const stateId = this.step?.workflowInfo.state.id;
    return stateId == null ? null : this.workflow?.states.get(stateId) ?? null;
  }

  get stepConfig(): StepConfigSimpleMessage {
    const stepConfig = this.stateConfig?.step;
    if (stepConfig?.type === StepType.SIMPLE_MESSAGE) {
      return stepConfig;
    }
    return { type: StepType.SIMPLE_MESSAGE };
  }

  get stepContext(): null | MeasureProcessStepContextSimpleMessage {
    const stepContext: null | MeasureProcessStep["stepContext"] =
      this.step?.stepContext ?? null;

    if (stepContext == null || stepContext?.type !== StepType.SIMPLE_MESSAGE) {
      console.warn("No valid stepContext: ", this.stepId, stepContext?.type);
      return null;
    }
    return stepContext;
  }

  get isDirty() {
    const data = this.lazyDescription?.trim() ?? null;
    return data !== null && data !== this.stepContext?.description;
  }

  @Watch("isDirty")
  updateIsDirtyState(newState: boolean) {
    this.setCurrentStepDirty(newState);
  }

  get isActiveStep() {
    return this.step?.workflowInfo.leftAt === null;
  }

  get isEditable() {
    return !this.readonly && this.step?.workflowInfo.leftAt === null;
  }

  get description(): string {
    return this.lazyDescription ?? this.stepContext?.description ?? "";
  }

  set description(val) {
    this.lazyDescription = val;
  }

  get descriptionMeta(): StepConfigPropertyMeta {
    const stateConfig = this.stateConfig;
    return providePropertyMeta(
      this.stepConfig.description,
      this.$ft(stateConfig?.name ?? "")
    );
  }

  get descriptionValidationRules() {
    const { rules } = this.descriptionMeta;
    return compilePropertyValidationRules(this.ajv, rules);
  }

  get ajv(): Ajv.Ajv {
    return Ajv();
  }

  cancelEdit() {
    this.lazyDescription = null;
    this.requestPromise = Promise.resolve();
    (this.$refs.form as VForm).validate();
  }

  updateStep() {
    (this.$refs.form as VForm).validate();
    if (!this.isValid) {
      return;
    }
    const description = this.lazyDescription?.trim();
    if (description != null) {
      this.loading = true;
      this.requestPromise = this.patchMeasureStep({
        measureStepId: this.stepId,
        patch: [
          {
            op: "test",
            path: "/description",
            value: this.stepContext?.description ?? "",
          },
          {
            op: "replace",
            path: "/description",
            value: description,
          },
        ],
      });
      this.requestPromise
        .then(res => {
          console.log("updateStep done", res);
          this.lazyDescription = description ?? this.description;
        })
        .catch(err => {
          console.error("updateStep failed", err.response);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
