var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.rows.length > 0 &&
    _vm.columns.length > 0 &&
    (_vm.selectedItems.length > 0 || !_vm.disabled)
  )?_c('v-input',_vm._b({},'v-input',_vm.filterAttrs,false),[_c('v-container',{staticClass:"py-0 mb-3"},[_c('v-row',{class:{
        'label__selectable-matrix': true,
        'disabled-items__selectable-matrix': _vm.disabled,
      }},[_vm._v(" "+_vm._s(_vm.$ft(_vm.label))+" ")]),_c('v-row',[_c('table',[_c('tr',[_c('th'),_vm._l((_vm.columns),function(c,cidx){return _c('td',{key:cidx,class:{
              tableheadercolumn: true,
              'disabled-items__sortable-control': _vm.disabled,
            },attrs:{"scope":"col"}},[_c('span',{staticClass:"colheader"},[_vm._v(" "+_vm._s(_vm.columnFormatter(c, cidx + 1))+" ")])])})],2),_vm._l((_vm.rows),function(r,ridx){return _c('tr',{key:ridx},[_c('td',{staticStyle:{"text-align":"right"},attrs:{"scope":"row"}},[_c('div',{class:{
                tableheaderrow: true,
                'disabled-items__sortable-control': _vm.disabled,
              }},[_vm._v(" "+_vm._s(_vm.rowFormatter(r, ridx + 1))+" ")])]),_vm._l((_vm.columns),function(rc,cidx){return _c('td',{key:cidx,attrs:{"title":(r + ", " + rc)}},[_c('v-checkbox',{staticClass:"ma-0 pt-0",attrs:{"value":_vm.allItems[cidx + ridx * _vm.columns.length],"disabled":_vm.disabled,"hide-details":"","multiple":true,"value-comparator":_vm.comparator},model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)})],2)})],2)]),_c('v-row',{staticClass:"mt-4"},[(_vm.rows.length > 0 && _vm.columns.length > 0 && !_vm.disabled)?_c('v-btn',{attrs:{"disabled":_vm.areAllSelected || _vm.disabled,"small":"","color":"primary","outlined":"","depressed":""},on:{"click":_vm.chooseAll}},[_vm._v(" "+_vm._s(_vm.$t("schema_forms.common.select_all"))+" ")]):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }