<template>
  <span>
    <span v-for="(s, sid) in currentStandard" :key="sid">
      <span>{{ s.name }}</span>
      <span v-if="sid < currentStandard.length - 1">&nbsp; / &nbsp;</span>
    </span>
  </span>
</template>

<script>
import { mapGetters } from "vuex";
import { api } from "@/store/modules/standards";

export default {
  props: {
    standard: {
      type: [Array, String],
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(api.namespace, [api.getters.getStandard]),
    standardList() {
      if (this.standard instanceof Array) {
        return this.standard;
      } else {
        return [this.standard];
      }
    },
    currentStandard() {
      const invalidStandard = {
        id: "",
        name: "UNKNOWN",
      };
      return this.standardList.map(s => {
        if (
          s instanceof Object &&
          typeof s.id === "string" &&
          typeof s.name === "string"
        ) {
          return s;
        } else if (typeof s === "string") {
          const standard = this.getStandard(s);
          if (standard) {
            return {
              id: s,
              name: standard.name,
            };
          } else {
            return invalidStandard;
          }
        } else {
          return invalidStandard;
        }
      });
    },
  },
  methods: {
    getStandardName(standard_id) {
      const standard = this.getStandard(standard_id);
      return standard ? standard.name : "";
    },
  },
};
</script>
