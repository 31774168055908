import { render, staticRenderFns } from "./AMeasureTransitionSelectionTabs.vue?vue&type=template&id=5d6cf104&scoped=true&"
import script from "./AMeasureTransitionSelectionTabs.vue?vue&type=script&lang=ts&"
export * from "./AMeasureTransitionSelectionTabs.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d6cf104",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCardActions,VIcon,VTab,VTabs})
