import axios, { AxiosRequestConfig } from "axios";
import { ActionTree } from "vuex";
import { Result, ok, err } from "neverthrow";

import {
  ApiError,
  ApiFilterItem,
  ApiUserParams,
  ApiOrderByItem,
  ApiListResponse,
  UpdateUserResult,
  UpdateUserPayload,
  UserManagementState,
} from "./types";
import { mutationNames as mn } from "./mutations";
import { RootState } from "@/store/types";
import { userEndpointUrl } from "@/utils/HttpApi";
import { ApiV0UserDisableSelfAssessmentSharesRequest } from "@auditcloud/shared/lib/schemas";

const findUser = "findUser";
const reload = "reload";
const loadMore = "loadMore";
const updateFilters = "updateFilters";
const updateOrderBy = "updateOrderBy";
const updateUser = "updateUser";
const disableSelfAssessmentShares = "disableSelfAssessmentShares";
const n = {
  findUser,
  reload,
  loadMore,
  updateFilters,
  updateOrderBy,
  updateUser,
  disableSelfAssessmentShares,
};

async function fetchUserList({
  where,
  orderBy,
  pagination,
}: ApiUserParams): Promise<Result<ApiListResponse, ApiError>> {
  const url = userEndpointUrl("list");

  let params: AxiosRequestConfig["params"] = {};
  if (pagination.limit) {
    params.limit = pagination.limit;
  }
  if (pagination.from) {
    params.from = pagination.from;
  }
  if (orderBy.length > 0) {
    params.orderBy = orderBy
      .map(({ field, direction }) => `${field}:${direction}`)
      .join(",");
  }
  where.forEach(({ field, condition, value }) => {
    params[`where:${field}:${condition}`] = value;
  });

  console.log("fetchUsers: params=", params);
  const response = await axios
    .get(url, { params })
    .catch(error => error.response);
  if (response.status < 400) {
    const { users, nextFrom } = response.data;
    console.log("fetchUsers: length=", users.length, "nextFrom=", nextFrom);
    return ok({ users, nextFrom });
  } else {
    console.error("fetchUsers: error fetching users", response.data);
    return err(response.data);
  }
}

const actions: ActionTree<UserManagementState, RootState> = {
  async [n.findUser](
    { rootState, commit, getters, dispatch },
    payload: string
  ) {
    commit(mn.SET_LOADING_STATE, true);
    const url = userEndpointUrl("list", payload);
    const response = await axios.get(url).catch(error => error.response);
    const result =
      response.status < 400 ? ok(response.data) : err(response.data);
    commit(mn.SET_FIND_RESPONSE, result);
  },

  async [n.reload]({ rootState, commit, state, getters, dispatch }, payload) {
    commit(mn.PREPARE_RELOAD);
    const result = await fetchUserList(state.params);
    commit(mn.SET_LIST_RESPONSE, result);
  },

  async [n.loadMore]({ rootGetters, commit, state, dispatch }, payload) {
    commit(mn.PREPARE_LOAD_MORE);
    const result = await fetchUserList(state.params);
    commit(mn.APPEND_LIST_RESPONSE, result);
  },

  async [n.updateFilters](
    { rootGetters, commit, state, dispatch },
    payload: ApiFilterItem[]
  ) {
    commit(mn.UPDATE_WHERE, { where: payload });
  },

  async [n.updateOrderBy](
    { rootGetters, commit, state, dispatch },
    payload: ApiOrderByItem[]
  ) {
    commit(mn.UPDATE_ORDER_BY, { orderBy: payload });
  },

  async [n.updateUser](
    { rootState, commit, getters, dispatch },
    payload: UpdateUserPayload
  ): Promise<UpdateUserResult> {
    commit(mn.SET_LOADING_STATE, true);

    if (payload.type === "ref") {
      delete payload.applicationGroups;
      delete payload.roles;
    }
    if (payload.type === "sync") {
      delete payload.displayName;
    }

    const url = userEndpointUrl("list", payload.id);
    const response = await axios
      .put(url, payload)
      .catch(error => error.response);
    commit(mn.SET_LOADING_STATE, false);
    return response.status < 400
      ? ok({ created: response.data.created })
      : err(response.data);
  },

  async [n.disableSelfAssessmentShares](
    { rootState, commit, getters, dispatch },
    payload: { userId: string }
  ) {
    commit(mn.SET_LOADING_STATE, true);

    const url = userEndpointUrl(
      "list",
      payload.userId,
      "self-assessment-shares"
    );
    const reqPayload: ApiV0UserDisableSelfAssessmentSharesRequest = {
      state: "disabled",
    };

    const response = await axios
      .put(url, reqPayload)
      .catch(error => error.response);

    commit(mn.SET_LOADING_STATE, false);

    return response.status < 400
      ? ok({ response: response.data })
      : err(response.data);
  },
};

export { n as actionNames, actions };
