













import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { TodoArray } from "@auditcloud/shared/lib/utils/type-guards";

@Component({})
export default class Chips extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  })
  currentItems!: TodoArray;
}
