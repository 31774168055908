



























import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { TodoAny } from "@auditcloud/shared/lib/utils/type-guards";

import { ROUTE_NAMES } from "../routenames";
import { api as appApi } from "@/store/modules/app";
import { SystemRoles } from "@auditcloud/shared/lib/constants/roles";
import { DocumentNames } from "@auditcloud/shared/lib/types/common";

function configSegment(title: string, id: string, name?: string) {
  return {
    title,
    route: {
      name: name || ROUTE_NAMES.CONFIGURATION_SEGMENT,
      params: {
        segmentName: id,
      },
    },
    id,
  };
}

@Component({})
export default class Configuration extends Vue {
  @Prop({
    type: String,
    default: null,
  })
  segmentName!: string;

  get currentBreadCrumb() {
    const title =
      this.segments.find(v => v.id === this.segmentName)?.title ?? " - ";
    return [
      {
        text: this.$t("views.audit.home"),
        to: { name: ROUTE_NAMES.DASHBOARDSCOPED },
        activeclass: "",
      },
      {
        text: this.$t("views.settings.breadcrumb"),
        to: { name: ROUTE_NAMES.SETTINGS },
        activeclass: "",
      },
      {
        text: this.$t("sitemap.configuration"),
        to: {
          name: ROUTE_NAMES.CONFIGURATION,
          params: {
            segmentName: "app",
          },
        },
        activeclass: "",
      },
      {
        text: title,
      },
    ];
  }

  @Watch("currentBreadCrumb", { immediate: true })
  onBreadCrumbChanged() {
    this.updateBreadCrumbs();
    this.setMenuPanel();
  }

  @Action(appApi.actions.setBreadCrumb, { namespace: appApi.namespace })
  setBreadCrumb!: (currentBreadCrumb: any[]) => Promise<boolean>;

  @Action(appApi.actions.setMenuPanel, { namespace: appApi.namespace })
  setMenuPanel!: () => Promise<boolean>;

  @Getter(appApi.getters.getCurrentLanguage, { namespace: appApi.namespace })
  currentLanguage!: TodoAny;

  @Watch("currentLanguage", { immediate: true, deep: true })
  onLaguageChange() {
    this.updateBreadCrumbs();
  }

  get segments() {
    return [
      configSegment("App Settings", "app"),
      configSegment("Audit Settings", "audit", ROUTE_NAMES.CONFIGURATION_AUDIT),
      configSegment(
        "Audit Classes",
        "auditClasses",
        ROUTE_NAMES.CONFIGURATION_AUDITCLASS
      ),
      configSegment(
        "Formular Schema Settings",
        "schema",
        ROUTE_NAMES.CONFIGURATION_FORMSCHEMA
      ),
      configSegment("Audititem Settings", "audititem"),
      configSegment("Category Settings", "categories"),

      configSegment("Finding Settings", "finding"),
      configSegment("Measure Settings", "measure"),
      configSegment(
        "Metadata (Autocompletions)",
        "metadata",
        ROUTE_NAMES.CONFIGURATION_METADATA
      ),
      configSegment("User Settings", "user"),

      configSegment(
        this.$t("views.configuration.lists_settings").toString(),
        "lists",
        ROUTE_NAMES.CONFIGURATION_LISTS
      ),
      configSegment(
        this.$t("views.configuration.i18n").toString(),
        "i18n",
        ROUTE_NAMES.CONFIGURATION_I18N
      ),

      configSegment(
        "Email-Templates",
        "email-template",
        ROUTE_NAMES.CONFIGURATION_EMAIL_TEMPLATE
      ),
      configSegment(
        this.$t("views.configuration.internals").toString(),
        DocumentNames.CONFIG_INTERNALS,
        ROUTE_NAMES.CONFIGURATION_PRIVATES
      ),
    ];
  }

  updateBreadCrumbs() {
    this.setBreadCrumb(this.currentBreadCrumb);
  }

  onSegmentChanged(newSegment) {
    console.log("onSegmentChanged", newSegment);
    this.$router.push(newSegment.route);
  }
}
