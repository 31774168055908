import firebase from "firebase/compat/app";
import { CollectionObserverConfig } from "./types/CollectionObserverConfig";
import { WatchedDocument } from "./types/WatchedDocument";
import { DocumentsUpdateHandlerData } from "./types/DocumentsUpdateHandlerData";
import { MetadataUpdateHandlerData } from "./types/MetadataUpdateHandlerData";
import { isArray } from "lodash";

type DocumentsUpdateHandler = (updateData: DocumentsUpdateHandlerData) => void;
type MetadataUpdateHandler = (updateData: MetadataUpdateHandlerData) => void;

type LoadingStateSetter = (loading: boolean) => void;
type ErrorHandler = (error: Error, collection: string) => void;

/** Provides live/reactive results for a given query */
export function createCollectionObserver(
  collection: string,
  config: CollectionObserverConfig,
  documentsUpdateHandler: DocumentsUpdateHandler,
  metadataUpdateHandler: MetadataUpdateHandler,
  errorHandler: ErrorHandler,
  loadingStateSetter: LoadingStateSetter = () => {}
) {
  const observer = {
    error(error: Error) {
      console.error(`Observer[${collection}].error`, error, config);
      errorHandler(error, collection);
      loadingStateSetter(false);
    },
    next(querySnapshot: firebase.firestore.QuerySnapshot) {
      console.log(`Observer[${collection}].next`, querySnapshot.size, config);
      const docChanges = querySnapshot.docChanges({
        // includeMetadataChanges: true
      });

      if (docChanges.length > 0) {
        const removeDocs = docChanges
          .filter(docChange => docChange.type === "removed")
          .map(docChange => docChange.doc.id);

        const modifiedDocs = docChanges
          .filter(docChange => docChange.type !== "removed")
          .map(docChange => {
            const doc = docChange.doc;
            const data = doc.data();
            const watched: WatchedDocument<firebase.firestore.DocumentData> = {
              data,
              metadata: {
                ...doc.metadata,
              },
              exists: doc.exists,
              id: doc.id,
            };

            return watched;
          });
        documentsUpdateHandler({ removeDocs, modifiedDocs });
      } else {
        const updateMetadata = querySnapshot.docs.map(doc => {
          const metadata: WatchedDocument<null> = {
            id: doc.id,
            exists: doc.exists,
            metadata: doc.metadata,
            data: null,
          };
          return metadata;
        });

        metadataUpdateHandler(updateMetadata);
      }
      loadingStateSetter(false);
    },
  };

  let query: firebase.firestore.Query = firebase
    .firestore()
    .collection(collection);

  if (config.filter) {
    config.filter.forEach(filter => {
      query = query.where(filter.fieldPath, filter.operator, filter.value);
    });
  }
  if (config.limit) {
    query = query.limit(config.limit);
  }
  if (config.orderBy) {
    if (isArray(config.orderBy)) {
      config.orderBy.forEach(currentOrderBy => {
        query = query.orderBy(
          currentOrderBy.fieldPath,
          currentOrderBy.direction
        );
      });
    } else {
      query = query.orderBy(config.orderBy.fieldPath, config.orderBy.direction);
    }
  }
  if (config.startAtDoc) {
    query = query.startAt(config.startAtDoc);
  }

  console.log(`Observer[${collection}].init`, config, observer);

  return query.onSnapshot({ includeMetadataChanges: true }, observer);
}
