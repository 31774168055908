


































































































































































import Vue from "vue";

import { Component, Prop } from "vue-property-decorator";
import { TranslateableText } from "@auditcloud/shared/lib/schemas";
@Component({})
export default class AUserSnippetBase extends Vue {
  @Prop({ type: String, default: null })
  readonly userName!: string | null;

  @Prop({ type: String, default: null })
  readonly userEmail!: string | null;

  @Prop({ type: Boolean, default: false })
  readonly noUserCard!: boolean;

  @Prop({ type: String })
  readonly prependIcon!: string;

  @Prop({ type: String })
  readonly prependIconColor!: string;

  @Prop({ type: String, default: "" })
  readonly appendIcon!: string;

  @Prop({ type: Boolean, default: false })
  readonly trailingComma!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly chip!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly noAvatar!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly editable!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly dark!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly avatarOnly!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly small!: boolean;

  @Prop({
    type: [String, Object],
    default: () => ({ de: "Unbekannt", en: "Unknown" }),
  })
  readonly noUserPlaceholder!: TranslateableText;

  get noUserPlaceholderText() {
    return this.$ft(this.noUserPlaceholder);
  }

  get userPreviewCharacter() {
    return this.userName && this.userName.length > 0
      ? this.userName.charAt(0)
      : "-";
  }

  get iconRadiusPx() {
    const radiusPx = this.userEmail === null ? 20 : 25;
    return radiusPx - (this.small ? 5 : 0);
  }
}
