import firebase from "firebase/compat/app";

import { mapValues, pick } from "lodash";
import { UserState } from "./types";
import { RootState } from "../../types";
import { mutationNames as mn } from "./mutations";
import { actionNs, ActionTypeContext, getterNs } from "@/utils/VuexHelper";
import { Getters } from ".";
import axios from "axios";
import { endpointUrlRegisterUser } from "@/utils/HttpApi";
import { CollectionNames } from "@auditcloud/shared/lib/types/common";
import { createSamlProvider } from "../users/providerWrapper";
import { contentTranslation } from "@/plugins/ContentTranslation";
import {
  UserDoc,
  ConfigurationSystemDoc,
} from "@auditcloud/shared/lib/schemas";
import { getSamlProviderName } from "@auditcloud/shared/lib/utils/auth";
import { api as confApi } from "../configuration";
import { api as appApi } from "../app";

type Context = ActionTypeContext<UserState, RootState, Getters>;

const actions = {
  async userSignUp({ commit, dispatch }: Context, payload) {
    // dispatch(actionNs(appApi, appApi.actions.setLoading), true, { root: true });
    try {
      const response = await axios({
        method: "post",
        url: endpointUrlRegisterUser(),
        responseType: "json",
        data: payload,
      });

      console.log("userSignUp", payload, response);

      if (
        response.headers["content-type"] &&
        response.headers["content-type"].startsWith("application/json") &&
        response.data &&
        typeof response.data.token === "string"
      ) {
        const userCredential = await firebase
          .auth()
          .signInWithCustomToken(response.data.token);
        return userCredential;
      } else {
        throw Error("Register failed");
      }
    } catch (err) {
      if (err.response && err.response.data instanceof Object) {
        console.error("res", err.response);
        throw err.response.data;
      } else if (err.request) {
        console.error("req", err.request);
      } else {
        console.error("all", err);
      }
      throw err;
    }

    /*
    const userCredential = await firebase
      .auth()
      .createUserWithEmailAndPassword(payload.email, payload.password)
      .then(firebaseUser => {
        console.log("Firebase User");

        const local_payload = {
          data: {
            firebase_id: firebaseUser.user.uid,
            email: firebaseUser.user.email,
            displayName: firebaseUser.user.displayName,
            avatar: firebaseUser.user.photoURL
          }
        };
        console.log("User Sign Up", local_payload);

        // User in Firebase DB anlegen
        dispatch("createUser", local_payload);

        // User Object anlegen
        commit(api.mutations.setUser, {
          id: firebaseUser.user.uid,
          email: firebaseUser.user.email
        });
        //router.push("/");
        dispatch(actionNs(appApi, appApi.actions.setLoading), false, { root: true });
      })
      .catch(error => {
        //commit("setError", error.message);
        dispatch(actionNs(appApi, appApi.actions.setLoading), false, { root: true });
      });
      */
  },
  async handleSignIn({ commit, dispatch }: Context, firebaseUserId: string) {
    const db = firebase.firestore();
    const usersCollectionRef = db.collection(CollectionNames.USERS);
    let userDoc = await usersCollectionRef.doc(firebaseUserId).get();
    if (!userDoc.exists) {
      const userssnapshot = await usersCollectionRef
        .where("firebase_id", "==", firebaseUserId)
        .get();
      if (userssnapshot.empty) {
        throw new Error("User not found");
      } else if (userssnapshot.size > 1) {
        console.warn(
          `Expect to find only one user with firebase_id ${firebaseUserId} found ${userssnapshot.size}`
        );
      }
      userDoc = userssnapshot.docs[0];
    }
    const userDocData = userDoc.data() as UserDoc | undefined;
    if (!userDocData) {
      throw new Error("User Data Invalid");
    }
    const userData = {
      ...userDocData,
      id: firebaseUserId,
    };
    commit(mn.setUser, userData);
  },
  async userSignIn({ commit, dispatch }: Context, payload) {
    const firebaseUser = await firebase
      .auth()
      .signInWithEmailAndPassword(payload.email, payload.password);

    if (firebaseUser.user) {
      const firebaseUserId = firebaseUser.user.uid;
      await dispatch(n.handleSignIn, firebaseUserId);
    }
    return firebaseUser;
  },
  async userSignInWithSAML(
    { commit, dispatch, rootGetters }: Context,
    email: string
  ) {
    try {
      const systemConfig = rootGetters[
        getterNs(confApi, confApi.getters.system)
      ] as ConfigurationSystemDoc;

      const providername = getSamlProviderName(email, systemConfig);
      console.log("SAML DOMAIN", providername);
      // TODO: Get provider by configuration
      const provider = createSamlProvider(providername);

      console.log("SAML Login");

      const firebaseUser = await firebase.auth().signInWithPopup(provider);
      console.log("Firebase User", firebaseUser);

      if (firebaseUser.user) {
        const firebaseUserId = firebaseUser.user.uid;
        await dispatch(n.handleSignIn, firebaseUserId);
      }
      return firebaseUser;
    } catch (err) {
      console.log("SAML ERR", err);
      throw err;
    }
  },

  async autoSignIn(
    { commit, dispatch, state }: Context,
    firebaseUser: firebase.User | null
  ) {
    await dispatch(actionNs(appApi, appApi.actions.setLoading), true, {
      root: true,
    });
    try {
      if (firebaseUser) {
        const firebaseUserId = firebaseUser.uid;
        await dispatch(n.handleSignIn, firebaseUserId);
      }
      return true;
    } catch (err) {
      console.error("autoSignIn failed", err);
      throw err;
    } finally {
      await dispatch(actionNs(appApi, appApi.actions.setLoading), false, {
        root: true,
      });
    }
  },
  async userSignOut({ commit, dispatch }: Context) {
    await dispatch(actionNs(appApi, appApi.actions.setLoading), true, {
      root: true,
    });

    await dispatch("unloadState", "", { root: true });
    console.log("unload state accomplished");

    commit(mn.setUser, null);
    await firebase.auth().signOut();
    console.log("logout accomplished");

    await dispatch(actionNs(appApi, appApi.actions.setLoading), false, {
      root: true,
    });

    // TODO: Wir bräuchten nach dem Signout die Umleitung auf die konfigurierte Single Sign Out URL
    /*const res = await axios.get(
      "https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0"
    );
    console.log("Sign Out den Lümmel", res);*/
  },
  async listenToTokenChanges({ commit }: Context) {
    firebase.auth().onIdTokenChanged(async user => {
      commit(mn.SET_FIREBASE_USER, user ? user.toJSON() : null);

      if (user) {
        const result = await user.getIdTokenResult();

        commit(mn.SET_FIREBASE_CLAIMS, result.claims);
      } else {
        commit(mn.SET_FIREBASE_CLAIMS, null);
      }
    });
  },

  async storeActivitiesReadTime(
    { commit, dispatch, getters, rootGetters }: Context,
    date: string
  ) {
    const user = getters.getCurrentUser;
    if (user?.id) {
      await firebase
        .firestore()
        .collection(CollectionNames.USERS)
        .doc(user.id)
        .update({
          activitiesReadTime: date,
        });
      commit(mn.storeActivitiesReadTime, date);
      return date;
    } else {
      throw new Error("no-user-active");
    }
  },
  async storeSystemLanguage(
    { commit, getters, rootGetters }: Context,
    language: string
  ) {
    const user = getters.getCurrentUser;
    if (user?.id) {
      commit(mn.storeSystemLanguage, language);
      await firebase
        .firestore()
        .collection(CollectionNames.USERS)
        .doc(user.id)
        .update({
          ["language.system"]: language,
        });
    } else {
      throw new Error("no-user-active");
    }
  },
  async storeContentLanguage(
    { commit, dispatch, getters, rootGetters }: Context,
    language: string
  ) {
    const user = getters.getCurrentUser;
    if (user?.id) {
      commit(mn.storeContentLanguage, language);
      contentTranslation.setLanguage(language);
      await firebase
        .firestore()
        .collection(CollectionNames.USERS)
        .doc(user.id)
        .update({
          ["language.content"]: language,
        });
    } else {
      throw new Error("no-user-active");
    }
  },
  async storeCustomData(
    { commit, dispatch, getters, rootGetters }: Context,
    customData: UserDoc["customData"]
  ) {
    const user = getters.getCurrentUser;
    if (customData && user?.id) {
      commit(mn.storeCustomData, customData);
      const updateData = Object.keys(customData)
        .map(k => [`customData.${k}`, customData[k]])
        .reduce((result, [k, v]) => ({ ...result, [k as string]: v }), {});
      await firebase
        .firestore()
        .collection(CollectionNames.USERS)
        .doc(user.id)
        .update(updateData);
    } else {
      throw new Error("no-user-active");
    }
  },
};

const n = mapValues(actions, (_, key) => key);

export { n as actionNames, actions };
