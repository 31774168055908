import { MappedAuditClasses } from "@auditcloud/shared/lib/types/AuditClass";
import {
  ReportSchema,
  TranslateableText,
} from "@auditcloud/shared/lib/schemas";

export class AuditClassesState {
  auditClasses: MappedAuditClasses = {};
}

export type AuditClassesListEntry = {
  id: string;
  name: TranslateableText;
  categorySetId: string;
  reportSchema: ReportSchema;
};
export type AuditClassesListResult = AuditClassesListEntry[];
