



























































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { api as usersApi } from "@/store/modules/users";
import { UserRef } from "@auditcloud/shared/lib/schemas";
import {
  ConfigType,
  DataType,
} from "@auditcloud/shared/lib/workflow/modules/Measure/transitionControls/duedateAssignee";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import { TransitionConfig } from "@auditcloud/shared/lib/workflow/types/Transition";
import { idable } from "@auditcloud/shared/lib/types/common";

@Component({
  name: "AMeasureTransitionDueDateAndAssigneeControl",
})
export default class AMeasureTransitionDueDateAndAssigneeControl extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  measure!: MeasureProcessDocument;

  @Prop({
    type: Object,
    default: null,
  })
  transition!: idable<TransitionConfig> | null;

  @Prop({
    type: Object,
    required: true,
  })
  componentConfig!: ConfigType;

  @Prop({
    type: Object,
  })
  transitionData!: DataType | null;

  @Prop({
    type: Boolean,
    required: true,
  })
  preventRefUserAssignees!: boolean;

  get isAuditor() {
    const userRefs = this.measure?.userRefs;
    if (!userRefs) return null;

    return [
      userRefs.leadAuditor?.id,
      ...userRefs.coAuditors?.map(userRef => userRef.id),
    ].some(id => id === this.$user.id());
  }

  get isDueDateControlEnabled() {
    return this.componentConfig.dueDate === "enabled";
  }

  get isDueDateControlReadonly() {
    return this.componentConfig.dueDate === "readonly";
  }

  get isAssigneeControlEnabled() {
    return this.componentConfig.assignee === "enabled";
  }

  get isAssigneeControlReadonly() {
    return this.componentConfig.assignee === "readonly";
  }

  get assigneeError(): string | null {
    return this.assignedTo === null && this.componentConfig.valueRequired
      ? this.$t("schema_forms.common.err_field_required").toString()
      : null;
  }

  get dueDateError(): string | null {
    return !(this.dueDate instanceof Date) && this.componentConfig.valueRequired
      ? this.$t("schema_forms.common.err_field_required").toString()
      : null;
  }

  get dueDate(): Date | null {
    return this.transitionData?.dueDate
      ? new Date(this.transitionData.dueDate)
      : null;
  }
  set dueDate(val: Date | null) {
    this.emitChangedDueDate(val ? val.toISOString() : null);
  }

  get assignedTo() {
    return this.transitionData?.assignedTo ?? null;
  }

  set assignedTo(assignee: UserRef | null) {
    this.emitChangedAssignedTo(assignee);
  }

  emitChangedDueDate(dueDate: string | null) {
    this.$emit("transition-data-updated", { dueDate });
  }
  emitChangedAssignedTo(assignee: UserRef | null) {
    this.$emit("transition-data-updated", { assignedTo: assignee });
  }

  get assignedToQueryAction() {
    return this.preventRefUserAssignees
      ? {
          name: usersApi.actions.queryLoginUser,
          namespace: usersApi.namespace,
        }
      : {
          name: usersApi.actions.queryUser,
          namespace: usersApi.namespace,
        };
  }
}
