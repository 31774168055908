import { ROUTE_NAMES } from "@/routenames";
import { TodoAny } from "@auditcloud/shared/lib/utils/type-guards";
import { api as userApi, Getters as UserGetters } from "@/store/modules/user";
import { api as confApi } from "@/store/modules/configuration";
import { getterNs, actionNs } from "@/utils/VuexHelper";
import { SystemRoles } from "@auditcloud/shared/lib/constants/roles";
import { AuditProgramConfig } from "@auditcloud/shared/lib/schemas";
import { Dictionary, size } from "lodash";
import { extractCurrentUserRoles } from "../user/utils";

export type SetTransitionPanelPayload = null | {
  component: string;
  title: string;
  props?: {
    [name: string]: any | undefined;
  };
};
const FOOTER_COMPONENTS = ["AAppFooter", "AAuditFooter"];

const knownLanguages: string[] = ["de", "en"];

export const api = {
  namespace: "app",
  getters: {
    sitemap: "sitemap",
    getStatus: "getStatus",
    getMessageline: "getMessageline",
    getCurrentLanguage: "getCurrentLanguage",
    getKnownLanguage: "getKnownLanguage",
    isLoading: "isLoading",
    getIsOnline: "getIsOnline",
    getMenuPanel: "getMenuPanel",
    getBreadCrumb: "getBreadCrumb",
    getMenuTabs: "getMenuTabs",
    getFooterComponent: "getFooterComponent",
    hasTransitionPanel: "hasTransitionPanel",
    getTransitionPanel: "getTransitionPanel",
    getFeatureDisabledDialog: "getFeatureDisabledDialog",
  },
  mutations: {
    resetState: "resetState",
    setLoading: "setLoading",
    setStatus: "setStatus",
    SET_MENU_PANEL: "SET_MENU_PANEL",
    SET_BREAD_CRUMB: "SET_BREAD_CRUMB",
    SET_TRANSITION_PANEL: "SET_TRANSITION_PANEL",
    setMenuTabs: "setMenuTabs",
    toggleDrawer: "toggleDrawer",
    toggleDrawerRight: "toggleDrawerRight",
    setDrawerRight: "setDrawerRight",
    addStatus: "addStatus",
    shiftStatus: "shiftStatus",
    setLanguage: "setLanguage",
    SET_IS_ONLINE: "SET_IS_ONLINE",
    SET_FOOTER_COMPONENT: "SET_FOOTER_COMPONENT",
    SET_FEATURE_DISABLED_DIALOG: "SET_FEATURE_DISABLED_DIALOG",
  },
  actions: {
    setLoading: "setLoading",
    setStatus: "setStatus",
    setMenuPanel: "setMenuPanel",
    setBreadCrumb: "setBreadCrumb",
    setTransitionPanel: "setTransitionPanel",
    setMenuTabs: "setMenuTabs",

    toggleDrawer: "toggleDrawer",
    toggleDrawerRight: "toggleDrawerRight",
    setDrawerRight: "setDrawerRight",
    addStatus: "addStatus",
    shiftStatus: "shiftStatus",
    setLanguage: "setLanguage",
    setIsOnline: "setIsOnline",
  },
  state: {
    appTitle: "appTitle",
    transitionPanel: "transitionPanel",
    drawerVisible: "drawerVisible",
    drawerRightVisible: "drawerRightVisible",
    drawerRight: "drawerRight",
    isLoading: "isLoading",
    status: "status",
    messageline: "messageline",
    language: "language",
    isOnline: "isOnline",
    menupanel: "menupanel",
    breadcrumb: "breadcrumb",
    menutabs: "menutabs",

    footerComponent: "footerComponent",
    featureDisabledDialog: "featureDisabledDialog",
  },
};

const defaultState = () => {
  return {
    [api.state.appTitle]: "Audit Cloud",
    [api.state.isLoading]: false,
    [api.state.status]: false,
    [api.state.menupanel]: "dashboard",
    [api.state.breadcrumb]: [],
    [api.state.menutabs]: [],

    [api.state.messageline]: [],
    [api.state.drawerVisible]: false,
    [api.state.drawerRightVisible]: false,
    [api.state.drawerRight]: {},
    [api.state.language]: (() => {
      let lang = (window.navigator.language || "de").substring(0, 2);
      if (!/^(de|en)$/.test(lang)) {
        lang = "en";
      }
      return lang;
    })(),
    [api.state.isOnline]: window.navigator.onLine,

    [api.state.footerComponent]: FOOTER_COMPONENTS[0],
    [api.state.transitionPanel]: null, // | {component: string; title?: string;}
    [api.state.featureDisabledDialog]: false,
  };
};

const state = defaultState();

const mutations = {
  [api.mutations.resetState](state: TodoAny) {
    Object.assign(state, defaultState());
  },
  [api.mutations.toggleDrawer](state: TodoAny, drawerVisible: boolean) {
    if (drawerVisible == null) {
      drawerVisible = !state.drawerVisible;
    }
    state.drawerVisible = drawerVisible;
  },
  [api.mutations.toggleDrawerRight](
    state: TodoAny,
    drawerRightVisible: boolean
  ) {
    if (drawerRightVisible == null) {
      drawerRightVisible = !state.drawerRightVisible;
    }
    state.drawerRightVisible = drawerRightVisible;
  },
  [api.mutations.setLoading](state: TodoAny, payload: boolean) {
    state.isLoading = payload;
  },
  [api.mutations.setStatus](state: TodoAny, payload: string) {
    state.status = payload;
  },
  [api.mutations.SET_MENU_PANEL](state: TodoAny, payload: TodoAny) {
    state.menupanel = payload;
  },
  [api.mutations.SET_BREAD_CRUMB](state: TodoAny, payload: TodoAny) {
    state.breadcrumb = payload;
  },
  [api.mutations.SET_TRANSITION_PANEL](
    state: { transitionPanel: SetTransitionPanelPayload },
    payload: SetTransitionPanelPayload
  ) {
    if (state.transitionPanel !== null || payload !== null) {
      state.transitionPanel = payload;
    }
  },
  [api.mutations.setMenuTabs](state: TodoAny, payload: TodoAny) {
    state.menutabs = payload;
  },
  [api.mutations.setDrawerRight](state: TodoAny, payload: TodoAny) {
    state.drawerRight = payload;
  },
  [api.mutations.addStatus](state: TodoAny, payload: TodoAny) {
    return state.messageline.push(payload);
  },
  [api.mutations.shiftStatus](state: TodoAny) {
    return state.messageline.shift();
  },
  [api.mutations.setLanguage](state: TodoAny, payload: string) {
    state.language = payload;
  },
  [api.mutations.SET_IS_ONLINE](state: TodoAny, payload: boolean) {
    state.isOnline = payload;
  },
  [api.mutations.SET_FOOTER_COMPONENT](state: TodoAny, payload: TodoAny) {
    console.log(api.mutations.SET_FOOTER_COMPONENT, payload);
    if (FOOTER_COMPONENTS.includes(payload)) {
      state.footerComponent = payload;
    } else {
      state.footerComponent = FOOTER_COMPONENTS[0];
    }
  },
  [api.mutations.SET_FEATURE_DISABLED_DIALOG](
    state: TodoAny,
    payload: TodoAny
  ) {
    if (payload) {
      state.featureDisabledDialog = true;
    } else {
      state.featureDisabledDialog = false;
    }
  },
};

const actions = {
  [api.actions.setLoading]({ commit }: any, payload: any) {
    commit(api.mutations.setLoading, payload);
  },
  [api.actions.setStatus]({ commit }: any, payload: any) {
    commit(api.mutations.setStatus, payload);
  },
  [api.actions.setMenuPanel]({ commit }: any, payload: any) {
    commit(api.mutations.SET_MENU_PANEL, payload);
  },
  [api.actions.setBreadCrumb]({ commit }: any, payload: any) {
    console.log("actions:setBreadCrumb", payload);
    commit(api.mutations.SET_BREAD_CRUMB, payload);
  },
  [api.actions.setTransitionPanel](
    { commit }: any,
    payload: SetTransitionPanelPayload
  ) {
    commit(api.mutations.SET_TRANSITION_PANEL, payload);
  },
  [api.actions.setMenuTabs]({ commit }: any, payload: any) {
    console.log("actions:setMenuTabs", payload);
    commit(api.mutations.setMenuTabs, payload);
  },

  [api.actions.toggleDrawer]({ commit }: any, payload: any) {
    commit(api.mutations.toggleDrawer, payload);
  },
  [api.actions.toggleDrawerRight]({ commit }: any, payload: any) {
    commit(api.mutations.toggleDrawerRight, payload);
  },
  [api.actions.setDrawerRight]({ commit }: any, payload: any) {
    commit(api.mutations.setDrawerRight, payload);
  },
  [api.actions.addStatus]({ commit }: any, payload: any) {
    commit(api.mutations.addStatus, payload);
  },
  [api.actions.shiftStatus]({ commit }: any) {
    commit(api.mutations.shiftStatus);
  },
  [api.actions.setLanguage]({ commit, dispatch }: any, lang: string) {
    commit(api.mutations.setLanguage, lang);
    dispatch(actionNs(userApi, userApi.actions.storeSystemLanguage), lang, {
      root: true,
    });
  },

  [api.actions.setIsOnline]({ commit }: any, payload: any) {
    console.log("ONLINE_STATE:ACTION", state.isOnline, payload);
    commit(api.mutations.SET_IS_ONLINE, payload);
  },
};

function getUserLanguage(rootGetters: any) {
  return rootGetters[
    getterNs(userApi, userApi.getters.getUserLanguage)
  ] as UserGetters["getUserLanguage"];
}
const getters = {
  [api.getters.getStatus](state: { status: any }) {
    return state.status;
  },
  [api.getters.getMessageline](state: { messageline: any }) {
    return state.messageline;
  },
  [api.getters.getMenuPanel](state: { menupanel: any }) {
    return state.menupanel;
  },

  [api.getters.getFooterComponent](state: { footerComponent: any }) {
    console.log("getFooterComponent Getter", state.footerComponent);
    return state.footerComponent;
  },
  [api.getters.hasTransitionPanel](state: {
    transitionPanel: SetTransitionPanelPayload;
  }) {
    console.log("hasTransitionPanel Getter", state.transitionPanel);
    return state.transitionPanel !== null;
  },
  [api.getters.getTransitionPanel](state: {
    transitionPanel: SetTransitionPanelPayload;
  }): SetTransitionPanelPayload {
    console.log("getTransitionPanel Getter", state.transitionPanel);
    return state.transitionPanel;
  },
  [api.getters.getFeatureDisabledDialog](state: {
    featureDisabledDialog: any;
  }) {
    return state.featureDisabledDialog;
  },
  [api.getters.getBreadCrumb](state: { breadcrumb: any }) {
    console.log("getters:getBreadCrumb", state.breadcrumb);
    return state.breadcrumb ?? [];
  },
  [api.getters.getMenuTabs](state: { menutabs: any }) {
    console.log("getters:MenuTabs", state.menutabs);
    return state.menutabs;
  },
  [api.getters.isLoading](state: any) {
    return state.isLoading;
  },
  [api.getters.sitemap](state, getters, rootState, rootGetters) {
    const roles = extractCurrentUserRoles(rootGetters);
    const auditProgramMap = rootGetters[
      getterNs(confApi, confApi.getters.getMappedAuditPrograms)
    ] as Dictionary<AuditProgramConfig>;

    const hasAuditdProgramGrid = rootGetters[
      getterNs(confApi, confApi.getters.hasAuditdProgramGrid)
    ] as boolean;

    const managementSummaryAllowedRoles = rootGetters[
      getterNs(confApi, confApi.getters.getManagementSummaryAllowedRoles)
    ] as string[];

    const sitemap: Array<{
      icon: string;
      text: string;
      route: { name: string; params?: Record<string, string> };
    }> = [];

    sitemap.push({
      icon: "mdi-home",
      text: "sitemap.dashboard",
      route: {
        name: ROUTE_NAMES.DASHBOARDSCOPED,
      },
    });
    sitemap.push({
      icon: "mdi-clock",
      text: "sitemap.standards_norms",
      route: {
        name: ROUTE_NAMES.STANDARDS,
      },
    });
    if (
      roles.includes(SystemRoles.QUALITYMANAGER) ||
      roles.includes(SystemRoles.ADMINISTRATOR) ||
      roles.includes(SystemRoles.SYSTEM_ADMINISTRATOR)
    ) {
      sitemap.push({
        icon: "featured_play_list",
        text: "sitemap.questionpool",
        route: {
          name: ROUTE_NAMES.TEMPLATES,
        },
      });
    }
    //{ icon: "subscriptions", text: "Audit Serien", route: "/auditseries" },

    if (size(auditProgramMap) > 1 && hasAuditdProgramGrid) {
      sitemap.push({
        icon: "mdi-youtube-subscription",
        text: "sitemap.audit_programm",
        route: {
          name: ROUTE_NAMES.AUDIT_PROGRAMS,
        },
      });
    } else {
      sitemap.push({
        icon: "mdi-youtube-subscription",
        text: "sitemap.audit_programm",
        route: {
          name: ROUTE_NAMES.AUDITMATRIX_YEAR_FILTERED,
          params: {
            year: String(new Date().getFullYear()),
          },
        },
      });
    }

    sitemap.push({
      icon: "mdi-history",
      text: "sitemap.measures",
      route: {
        name: ROUTE_NAMES.MEASURES,
      },
    });
    if (
      roles.includes(SystemRoles.ADMINISTRATOR) ||
      roles.includes(SystemRoles.SYSTEM_ADMINISTRATOR)
    ) {
      sitemap.push({
        icon: "mdi-cog",
        text: "sitemap.settings",
        route: {
          name: ROUTE_NAMES.SETTINGS,
        },
      });
    }
    if (roles.some(role => managementSummaryAllowedRoles.includes(role))) {
      sitemap.push({
        icon: "mdi-chart-line",
        text: "sitemap.management_summary",
        route: {
          name: ROUTE_NAMES.MANAGEMENT_SUMMARY,
        },
      });
    }

    return sitemap;
  },
  [api.getters.getCurrentLanguage](state, getters, rootState, rootGetters) {
    const userLanguage = getUserLanguage(rootGetters);
    if (userLanguage && userLanguage.system) {
      return userLanguage.system;
    }
    console.log("getCurrentLanguage", userLanguage);
    return state.language;
  },
  [api.getters.getKnownLanguage]() {
    return knownLanguages;
  },
  [api.getters.getIsOnline](state: any) {
    console.log("ONLINE_STATE:GETTER", state.isOnline);
    return state.isOnline;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
