var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","grid-list-sm":""}},[_c('div',{staticClass:"process-measure-matrix"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10,"show-select":"","disable-sort":"","fixed-header":""},on:{"click:row":_vm.onRowClicked},scopedSlots:_vm._u([{key:"item.audit",fn:function(ref){
var item = ref.item;
return [(item.audit)?_c('router-link',{attrs:{"to":'/audits/' + item.audit.id}},[_vm._v(" "+_vm._s(_vm.$ft(_vm.audit.name))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v(" "+_vm._s(_vm.$t("views.measure_process_matrix.no_audit"))+" ")])]}},{key:"item.finding",fn:function(ref){
var item = ref.item;
return [(item.finding)?_c('div',{staticClass:"component-wrapper"},[_c('div',{staticClass:"finding"},[_c('div',{staticClass:"item-border",style:({ backgroundColor: item.finding.type.color })}),_c('div',{staticClass:"content"},[_c('AChip',{attrs:{"color":item.finding.type.color,"small":""}},[_vm._v(" "+_vm._s(item.finding.type.value)+" ")]),_vm._v(" "+_vm._s(item.finding.text)+" ")],1),(item.finding.hasDirectMeasure)?_c('div',{staticClass:"direct-measure"},[_c('v-btn',{attrs:{"icon":"","small":"","color":"red"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("warning")])],1)],1):_vm._e()])]):_c('span',{staticClass:"text--disabled"},[_vm._v(" "+_vm._s(_vm.$t("views.measure_process_matrix.no_finding"))+" ")])]}},{key:"item.reason",fn:function(ref){
var item = ref.item;
return [(item.reason)?_c('span',[_vm._v(_vm._s(item.reason))]):_c('span',{staticClass:"text--disabled"},[_vm._v(" "+_vm._s(_vm.$t("views.measure_process_matrix.no_cause_analysis"))+" ")])]}},{key:"item.measure",fn:function(ref){
var item = ref.item;
return [(item.measure)?_c('span',[_vm._v(_vm._s(item.measure))]):_c('span',{staticClass:"text--disabled"},[_vm._v(" "+_vm._s(_vm.$t("views.measure_process_matrix.no_corrective_measure"))+" ")])]}},{key:"item.asignee",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"component-wrapper"},[(item.asignee)?_c('a-user-chip',{attrs:{"user":item.asignee}}):_vm._e(),(item.dueDate)?_c('a-date-chip',{attrs:{"date":item.dueDate}}):_vm._e()],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"component-wrapper"},[_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}}])}),_c('router-view')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }