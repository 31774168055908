import { camelCase, upperFirst } from "lodash";
import Vue from "vue";

const requireModule = require.context(
  ".",
  true,
  /[a-zA-Z][a-zA-Z0-9_-]+\.reg\.vue$/
);

const modulesRegex = /([a-zA-Z][a-zA-Z0-9_-]+)\.reg\.vue$/;

requireModule.keys().forEach(fileName => {
  const matches = modulesRegex.exec(fileName);

  if (matches === null) {
    return;
  }
  const componentName = upperFirst(camelCase(matches[1]));
  const component = requireModule(fileName).default;
  Vue.component(componentName, component);
});
