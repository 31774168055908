import { TransitionControlUIFactory } from "./types";
import {
  DataType,
  ConfigType,
  builder,
  typeIsConfigType,
} from "@auditcloud/shared/lib/workflow/modules/Measure/transitionControls/duedateAssignee";
import component from "../AMeasureTransitionDueDateAndAssigneeControl.reg.vue";

const factory: TransitionControlUIFactory<DataType, ConfigType> = config => {
  return {
    component,
    ...builder(config),
  };
};

export default {
  factory,
  typeIsConfigType,
};
