import {
  INITIAL_STATE,
  PagingCollectionObserver,
  PagingCollectionObserverState,
} from "@/utils/firestore/PagingCollectionObserver";
import { WatchedDocument } from "@/utils/firestore";
import { AuditMetadataClient } from "@/types/Audit";
import { AuditPermissions } from "@auditcloud/shared/lib/utils/aclHelpers";
import moment from "moment";
import { ISO_DATE_FORMAT } from "@auditcloud/shared/lib/utils/formatting/dates";

export const DASHBOARD_PAGE_SIZE = 10;

export interface AuditPermissionInfo {
  auditDoc: WatchedDocument<AuditMetadataClient>;
  permissions: AuditPermissions;
  auditRoles: string[];
}

export type DateRange = { start: string; end: string };
function currentMonth() {
  const now = moment();
  return {
    start: now.clone().startOf("month").format(ISO_DATE_FORMAT),
    end: now.clone().endOf("month").format(ISO_DATE_FORMAT),
  };
}

export class DashboardState {
  auditsQueryConfigChanges: Partial<AuditsQueryConfig> = {};
  audits: PagingCollectionObserverState = INITIAL_STATE;
  auditsLimit: number = DASHBOARD_PAGE_SIZE;
  auditsObserver: null | PagingCollectionObserver = null;

  calendarAuditsDateRange: DateRange = currentMonth();
  calendarAudits: PagingCollectionObserverState = INITIAL_STATE;
  calendarAuditsObserver: null | PagingCollectionObserver = null;

  measuresQueryConfigChanges: Partial<MeasuresQueryConfig> = {};
  measures: PagingCollectionObserverState = INITIAL_STATE;
  measuresLimit: number = DASHBOARD_PAGE_SIZE;
  measuresObserver: null | PagingCollectionObserver = null;
}

export type State = DashboardState;

export type MeasuresQueryConfig = {
  roleFilter: "assignee" | "creator" | "member";
  statusFilter: string[] | null;
  showCompletedMeasures: boolean;
  limit: number;
};

export type AuditsQueryConfig = {
  roleFilter: "leadauditor" | "member";
  statusFilter: string[] | null;
  limit: number;
};
