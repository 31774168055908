import { GetterTree } from "vuex";
import { RootState } from "../../types";
import { MeasureWorkflowsState } from "./types";
import { Dictionary } from "vue-router/types/router";
import { MeasureWorkflow } from "@auditcloud/shared/lib/workflow/modules/Measure";

const getMappedMeasureWorkflows = "getMappedMeasureWorkflows";

const n = { getMappedMeasureWorkflows };
const getters: GetterTree<MeasureWorkflowsState, RootState> = {
  [n.getMappedMeasureWorkflows](
    state,
    getters,
    rootState,
    rootGetters
  ): Dictionary<MeasureWorkflow> {
    return state.Workflows;
  },
};
export { n as getterNames, getters };
