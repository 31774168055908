import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";
import { idable, nullable } from "@auditcloud/shared/lib/types/common";
import { MeasureActivity } from "@/types/measure-activities";
import {
  AuditItem,
  Finding,
  TranslateableText,
} from "@auditcloud/shared/lib/schemas";
import { WatchedDocument } from "@/utils/firestore";
import { defaultDocType } from "@auditcloud/shared/lib/types/Audit/types";

export const enum StepState {
  Idle = "idle",
  New = "new",
  WorkInProgress = "wip",
  Verify = "verify",
  Done = "done",
}

export const TITLE_QUESTION: TranslateableText = {
  de: "Frage",
  en: "question",
};

export const TITLE_FINDING: TranslateableText = {
  de: "Abweichung",
  en: "deviation",
};
export const TITLE_DIRECTMEASURE: TranslateableText = {
  de: "Sofortmaßnahme",
  en: "direct measure",
};

export const TITLE_CAUSE_ANALYSIS: TranslateableText = {
  de: "Ursachenanalyse",
  en: "cause analysis",
};

export const TITLE_MEASURE: TranslateableText = {
  de: "Abstellmaßnahme",
  en: "corrective measure",
};

export const TITLE_EFFICACY_CHECK: TranslateableText = {
  de: "Wirksamkeitsprüfung",
  en: "efficacy check",
};

export class MeasureProcessMetadata {
  constructor(
    public responsible: IUserRef,
    public status: string,
    public dueDate: nullable<string>,
    public assignedTo: nullable<IUserRef>,
    public participants: IUserRef[]
  ) {}
}

type DocumentType = WatchedDocument<MeasureProcessMetadata | null>;

export class CurrentMeasureProcessState {
  id: string | null = null;
  metadata: DocumentType = defaultDocType();
  activities: MeasureActivity[] = [];
  steps: MeasureProcessStep[] = [];
  loading: boolean = false;
}

export interface MeasureProcessStep {
  type: string;
  title: TranslateableText;
  id: string | null;
  state: StepState;
}

export class MeasureProcessStepEmpty implements MeasureProcessStep {
  public readonly state = StepState.Idle;
  public readonly type = "MeasureProcessStepEmpty";
  public readonly id = null;
  constructor(public readonly title: TranslateableText) {}
}

export class MeasureProcessStepQuestion implements MeasureProcessStep {
  public readonly state = StepState.Done;
  public readonly type = "MeasureProcessStepQuestion";
  public readonly title = TITLE_QUESTION;
  constructor(
    public readonly question: idable<AuditItem>,
    public readonly id: string
  ) {}
}

export class MeasureProcessStepFinding implements MeasureProcessStep {
  public readonly state = StepState.Done;
  public readonly type = "MeasureProcessStepFinding";
  public readonly title = TITLE_FINDING;
  constructor(
    public readonly finding: idable<Finding>,
    public readonly id: string
  ) {}
}

export interface MeasureProcessStepEditable extends MeasureProcessStep {
  state: StepState;
  id: string;
  dueDate: Date | null;
  finalizedDate: Date | null;
  responsible: IUserRef | null;
  commentsCount: number;
  attachmentsCount: number;
}

export class MeasureProcessStepDirectMeasure
  implements MeasureProcessStepEditable
{
  public readonly type = "MeasureProcessStepDirectMeasure";
  public readonly title = TITLE_DIRECTMEASURE;
  constructor(
    public state: StepState,
    public id: string,
    public description: string | null,
    public dueDate: Date | null,
    public responsible: IUserRef | null,
    public attachmentsCount: number,
    public commentsCount: number,
    public finalizedDate: Date | null = null,
    public readonly signature: Signature | null = null
  ) {}
}

export const enum SignatureType {
  Verified = "verified",
  Rejected = "rejected",
}

export class Signature {
  constructor(
    public readonly type: SignatureType,
    public readonly date: Date,
    public readonly by: IUserRef
  ) {}
}

export class MeasureProcessStepCauseAnalysis
  implements MeasureProcessStepEditable
{
  public readonly type = "MeasureProcessStepCauseAnalysis";
  public readonly title = TITLE_CAUSE_ANALYSIS;

  constructor(
    public readonly state: StepState,
    public readonly id: string,
    public readonly dueDate: Date | null,
    public readonly responsible: IUserRef | null,
    public readonly attachmentsCount: number,
    public readonly commentsCount: number,
    public readonly text: TranslateableText,
    public readonly causeAnalysisMethod: string,
    public readonly finalizedDate: Date | null = null,
    public readonly signature: Signature | null = null
  ) {}
}

export class CorrectiveMeasureTask {
  constructor(
    public readonly done: boolean,

    public readonly responsible: IUserRef,
    public readonly text: TranslateableText
  ) {}
}

export class MeasureProcessStepCorrectiveMeasure
  implements MeasureProcessStepEditable
{
  public readonly type = "MeasureProcessStepCorrectiveMeasure";
  public readonly title = TITLE_MEASURE;

  constructor(
    public readonly state: StepState,
    public readonly id: string,
    public readonly dueDate: Date | null,
    public readonly responsible: IUserRef | null,
    public readonly attachmentsCount: number,
    public readonly commentsCount: number,
    public readonly text: TranslateableText,
    public readonly tasks: CorrectiveMeasureTask[],
    public readonly finalizedDate: Date | null = null,
    public readonly signature: Signature | null = null
  ) {}

  get openTaskCount() {
    return this.tasks.filter(task => task.done).length;
  }

  get taskCount() {
    return this.tasks.length;
  }
}

export class NamedRef {
  constructor(public readonly id: string, public readonly name: string) {}
}

export class MeasureProcessStepEfficacyCheck
  implements MeasureProcessStepEditable
{
  public readonly type = "MeasureProcessStepEfficacyCheck";
  public readonly title = TITLE_EFFICACY_CHECK;

  /**
   * Erstellt ein Wirksamkeitsprüfschritt
   *
   * @param state Status des Schritts
   * @param id Id des Dokuments
   * @param dueDate Fällingkeitsdatum
   * @param responsible Verantwortlicher des Maßnahmenprozessschrits
   * @param attachmentsCount Anzahl der Anhänge welche dem Schritt zugeordnet sind
   * @param commentsCount Anzahl der Kommentare welche dem Schritt zugeordnet sind
   * @param auditRef Eine Referenz auf ein zukünfitiges Audit. (id: AuditId, name: AuditName)
   * @param finalizedDate Abschlussdatum des Schritts
   * @param signature Signatur des Benutzers welcher den Schritt abgeschlossen hat
   */
  constructor(
    public readonly state: StepState,
    public readonly id: string,
    public readonly dueDate: Date | null,
    public readonly responsible: IUserRef | null,
    public readonly attachmentsCount: number,
    public readonly commentsCount: number,
    public readonly auditRef: NamedRef | null = null,
    // public readonly scopeRef: NamedRef | null = null,
    public readonly finalizedDate: Date | null = null,
    public readonly signature: Signature | null = null
  ) {}
}
