

































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import {
  FindingType,
  MeasurePolicyPerFindingType,
} from "@auditcloud/shared/lib/schemas";
import { MeasureType } from "@auditcloud/shared/lib/types/ItemTypes";

import { FindingTypeConfig } from "@auditcloud/shared/lib/types/Configuration/Configuration";
import { lookupAndMakeIdable } from "@auditcloud/shared/lib/utils/transform/lookupAndMakeIdable";
import { typeIsNotEmpty } from "@auditcloud/shared/lib/utils/filter/typeIsNotEmpty";
import AChip from "@/components/snippets/AChip.vue";
import { cloneDeep, isEmpty, keys, mapValues, omit } from "lodash";
import {
  normalizeMeasurePolicyPerFindingType,
  analyzeMeasurePolicyPerFindingType,
  ViolationLevel,
} from "@auditcloud/shared/lib/utils/audit/measurePolicies";

@Component({
  components: { AChip },
})
export default class AMeasurePolicyEditor extends Vue {
  @Prop({
    type: Object,
    required: true,
    default: (): MeasurePolicyPerFindingType => {
      return {
        policyType: "non-blocking",
        policies: {},
      };
    },
  })
  readonly value!: MeasurePolicyPerFindingType;

  @Prop({
    type: Array,
    required: true,
    default: [],
  })
  readonly measureTypes!: MeasureType[];

  @Prop({
    type: Object,
    required: true,
    default: null,
  })
  readonly findingTypeConfig!: FindingTypeConfig | null;

  /* = {
    policyType: "blocking",
    policies: {
      "12e7bd54-e8f1-4c73-8ea8-f150ce1558ef": {
        "0": "strict",
        "1": "strict",
      },
      "587d77e7-d9a6-4c14-bb20-a72784cece81": {
        "0": "strict",
      },
      "2d67aa2f-2e58-4ca6-9be9-734c1aa03ac7": {
        "0": "warning",
      },

      "unknown-finding-type-id": {
        "0": "warning",
      },
      "5a94997a-6e96-45b7-b36b-857c6425f4e0": {
        "unknown-measure-type-id": "warning",
      },
    },
  }; */
  private xPolicyConfig: MeasurePolicyPerFindingType | null = null;
  get actualPolicyConfig(): MeasurePolicyPerFindingType {
    return this.xPolicyConfig ?? this.value;
  }

  readonly policyTypes: Array<{
    value: MeasurePolicyPerFindingType["policyType"];
    text: string;
  }> = [
    {
      value: "non-blocking",
      text: "Nicht Blockierend",
    },
    {
      value: "blocking",
      text: "Blockierend wenn eine erforderliche Richtlinie verletzt wird",
    },
  ];

  readonly findingPolicyTypes: Array<{
    value: ViolationLevel;
    text: string;
  }> = [
    {
      value: null,
      text: "Nicht erforderlich",
    },
    {
      value: "warning",
      text: "Empfohlen",
    },
    {
      value: "strict",
      text: "Erforderlich",
    },
  ];

  get hasValidInput() {
    return this.measureTypes.length > 0 && !!this.findingTypeConfig;
  }

  get findingTypes(): FindingType[] {
    return (this.findingTypeConfig?.reportOrder ?? [])
      .map(lookupAndMakeIdable(this.findingTypeConfig?.types ?? {}))
      .filter(typeIsNotEmpty)
      .filter(type => !type.is_no_deviation);
  }

  get findingChipLabelSource(): FindingTypeConfig["findingChipLabelSource"] {
    return this.findingTypeConfig?.findingChipLabelSource;
  }

  get knownMeasureTypeIds() {
    return this.measureTypes.map(type => type.id);
  }

  get knownFindingTypeIds() {
    return keys(this.findingTypeConfig?.types ?? {});
  }

  get analyzedConfigResult() {
    return analyzeMeasurePolicyPerFindingType(
      this.actualPolicyConfig,
      this.knownFindingTypeIds,
      this.knownMeasureTypeIds
    );
  }

  get unknownMeasuresTypeIds(): string[] {
    return this.analyzedConfigResult.unknownMeasureTypeIds;
  }

  get unknownFindingTypeIds(): string[] {
    return this.analyzedConfigResult.unknownFindingTypeIds;
  }

  get hasErrors() {
    return (
      !isEmpty(this.unknownFindingTypeIds) ||
      !isEmpty(this.unknownMeasuresTypeIds)
    );
  }

  get xDisabled(): boolean {
    return this.hasErrors;
  }

  policyTypeChanged(policyType: MeasurePolicyPerFindingType["policyType"]) {
    console.log("policyTypeChanged", policyType);
    this.updatePolicyConfig({
      ...this.actualPolicyConfig,
      policyType,
    });
  }

  policyValue(findingTypeId: string, measureTypeId: string): ViolationLevel {
    return (
      this.actualPolicyConfig.policies[findingTypeId]?.[measureTypeId] ?? null
    );
  }

  policyChanged({
    findingTypeId,
    measureTypeId,
    policy,
  }: {
    findingTypeId: string;
    measureTypeId: string;
    policy: ViolationLevel;
  }) {
    const policyConfig = cloneDeep(this.actualPolicyConfig);
    if (findingTypeId in policyConfig.policies && policy === null) {
      delete policyConfig.policies[findingTypeId][measureTypeId];
    } else if (policy !== null) {
      policyConfig.policies[findingTypeId] = {
        ...(policyConfig.policies[findingTypeId] ?? {}),
        [measureTypeId]: policy,
      };
    }

    this.updatePolicyConfig(policyConfig);
  }

  cleanupFindings() {
    this.updatePolicyConfig({
      ...this.actualPolicyConfig,
      policies: omit(
        this.actualPolicyConfig.policies,
        this.unknownFindingTypeIds
      ),
    });
  }

  cleanupMeasures() {
    const unknownMeasuresTypeIds = this.unknownMeasuresTypeIds;
    this.updatePolicyConfig({
      ...this.actualPolicyConfig,
      policies: mapValues(this.actualPolicyConfig.policies, findingPolicy => {
        return omit(findingPolicy, unknownMeasuresTypeIds);
      }),
    });
  }

  updatePolicyConfig(policyConfig: MeasurePolicyPerFindingType) {
    const result = analyzeMeasurePolicyPerFindingType(
      policyConfig,
      this.knownFindingTypeIds,
      this.knownMeasureTypeIds
    );
    if (
      isEmpty(result.unknownFindingTypeIds) &&
      isEmpty(result.unknownMeasureTypeIds)
    ) {
      this.$emit("input", normalizeMeasurePolicyPerFindingType(policyConfig));
      this.xPolicyConfig = null;
    } else {
      //
      this.xPolicyConfig = normalizeMeasurePolicyPerFindingType(policyConfig);
    }
  }
}
