





























import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { VAvatar, VIcon, VBtn } from "vuetify/lib";
import { AnyMeasureActivity } from "@/types/measure-activities";
import ADateChip from "@auditcloud/components/snippets/ADateChip.vue";

@Component({
  components: { VAvatar, VIcon, VBtn, ADateChip },
})
export default class AMeasureActivitySecondary extends Vue {
  //missingDefault
  @Prop({
    type: Object,
    required: true,
  })
  readonly activity!: AnyMeasureActivity;
}
