





































































































































import { flatMap, head, last } from "lodash";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { VBtn, VIcon, VSelect, VRow, VCol } from "vuetify/lib";
import { Action, Getter } from "vuex-class";

import AWidget from "@/components/widgets/AWidget.vue";
import ASquareIconButton from "@/components/controls/ASquareIconBtn.vue";
import AListCard from "@/components/widgets/AListCard.vue";
import AAuditItem from "@/components/widgets/AAuditItem.vue";
import ACalendar, { CalendarRange } from "@/components/widgets/ACalendar.vue";

import { api as apiConfig } from "@/store/modules/configuration";
import {
  api as dashboardApi,
  Getters as dashboardGetters,
  Actions as dashboardActions,
} from "@/store/modules/dashboard";

import { ROUTE_NAMES } from "@/routenames";
import { formatAuditName } from "@auditcloud/shared/lib/utils/formatting/audits";
import {
  formatDates,
  ISO_DATE_FORMAT,
} from "@auditcloud/shared/lib/utils/formatting/dates";
import { AuditPermissionInfo } from "@/store/modules/dashboard/types";

@Component({
  components: {
    AWidget,
    ASquareIconButton,
    AListCard,
    AAuditItem,
    ACalendar,
    VBtn,
    VRow,
    VCol,
    VIcon,
    VSelect,
  },
})
export default class AAuditsWidget extends Vue {
  view: "list" | "calendar" = "list";

  get calendarItems() {
    const res = flatMap(
      this.calendarAudits
        .map((auditEntry: AuditPermissionInfo) => {
          const audit = auditEntry.auditDoc.data;
          const start = head(audit.auditing_date);
          const end = last(audit.auditing_date);
          const datesIso = formatDates(
            audit.auditing_date ?? [],
            null,
            ISO_DATE_FORMAT
          );
          return { start, end, datesIso, auditEntry };
        })
        .filter(({ start, end, datesIso }) => {
          return start && end && datesIso.length > 0;
        })
        .map(({ datesIso, auditEntry }) => {
          const audit = auditEntry.auditDoc.data;
          const dates = formatDates(audit.auditing_date ?? [], null);
          return datesIso.map(date => {
            const start = date.split(" ")[0];
            const end = date.split(" ")[2] || start;
            return {
              name: formatAuditName(audit),
              start,
              end,
              color: "primary",
              id: audit.id,
              leadauditor: audit.leadauditor?.displayName ?? "Unknown",
              dates,
              status: audit.workflow.status,
              auditEntry,
            };
          });
        })
    );
    return res;
  }

  get today() {
    return new Date().toISOString().substr(0, 10);
  }

  get availableAuditRoleFilters(): any[] {
    return [
      {
        text: this.$t(
          "components.widgets.audits_widget.filter_by_role_leadauditor"
        ),
        value: "leadauditor",
      },
      {
        text: this.$t("components.widgets.audits_widget.filter_by_role_member"),
        value: "member",
      },
    ];
  }

  @Getter(apiConfig.getters.hasAuditdProgramGrid, {
    namespace: apiConfig.namespace,
  })
  hasAuditProgramGrid!: boolean;

  @Getter(apiConfig.getters.getIsAuditCreationAllowed, {
    namespace: apiConfig.namespace,
  })
  isAuditCreationAllowed!: boolean;

  @Getter(apiConfig.getters.auditingYears, { namespace: apiConfig.namespace })
  auditingYears!: number[];

  @Getter(apiConfig.getters.getIsSelfAssessmentStatusVisibleOnDashboard, {
    namespace: apiConfig.namespace,
  })
  isSelfAssessmentStatusVisibleOnDashboard!: boolean;

  @Getter(dashboardApi.getters.getAuditsQueryConfig, {
    namespace: dashboardApi.namespace,
  })
  auditsQueryConfig!: dashboardGetters["getAuditsQueryConfig"];

  @Getter(dashboardApi.getters.getAuditsWithPermissions, {
    namespace: dashboardApi.namespace,
  })
  audits!: dashboardGetters["getAuditsWithPermissions"];

  @Getter(dashboardApi.getters.getIsLoadingAudits, {
    namespace: dashboardApi.namespace,
  })
  isLoadingAudits!: dashboardGetters["getIsLoadingAudits"];

  @Getter(dashboardApi.getters.getHasMoreAudits, {
    namespace: dashboardApi.namespace,
  })
  hasMoreAudits!: dashboardGetters["getHasMoreAudits"];

  @Getter(dashboardApi.getters.getCalendarAuditsWithPermissions, {
    namespace: dashboardApi.namespace,
  })
  calendarAudits!: dashboardGetters["getCalendarAuditsWithPermissions"];

  @Getter(dashboardApi.getters.getIsLoadingAudits, {
    namespace: dashboardApi.namespace,
  })
  isLoadingCalendarAudits!: dashboardGetters["getIsLoadingCalendarAudits"];

  @Action(dashboardApi.actions.fetchAudits, {
    namespace: dashboardApi.namespace,
  })
  fetchAudits!: dashboardActions["fetchAudits"];

  @Action(dashboardApi.actions.fetchCalendarAudits, {
    namespace: dashboardApi.namespace,
  })
  fetchCalendarAudits!: dashboardActions["fetchCalendarAudits"];

  @Action(dashboardApi.actions.updateAuditsQueryConfig, {
    namespace: dashboardApi.namespace,
  })
  updateAuditsQueryConfig!: dashboardActions["updateAuditsQueryConfig"];

  @Action(dashboardApi.actions.updateCalendarAuditsRange, {
    namespace: dashboardApi.namespace,
  })
  updateCalendarAuditsRange!: dashboardActions["updateCalendarAuditsRange"];

  @Action(dashboardApi.actions.fetchMoreAudits, {
    namespace: dashboardApi.namespace,
  })
  fetchMoreAudits!: dashboardActions["fetchMoreAudits"];

  @Action(dashboardApi.actions.clearAudits, {
    namespace: dashboardApi.namespace,
  })
  clearAudits!: dashboardActions["clearAudits"];

  @Action(dashboardApi.actions.clearCalendarAudits, {
    namespace: dashboardApi.namespace,
  })
  clearCalendarAudits!: dashboardActions["clearCalendarAudits"];

  async updateView(view: "calendar" | "list") {
    this.view = view;
    if (view === "calendar") {
      this.fetchCalendarAudits();
    } else {
      this.clearCalendarAudits();
    }
  }

  async updateCalendarRange({ start, end }: CalendarRange) {
    const dateRange = { start, end };
    this.updateCalendarAuditsRange(dateRange);
  }

  createAudit() {
    this.$router.push({
      name:
        this.$route.name === ROUTE_NAMES.DASHBOARDSCOPED
          ? ROUTE_NAMES.DASHBOARDSCOPED_CREATE_AUDIT
          : ROUTE_NAMES.HOME_CREATE_AUDIT,
      params: this.$route.params,
    });
  }

  openAudit(auditEntry: AuditPermissionInfo) {
    const useHomeView = !auditEntry.permissions.read;
    const routeName = useHomeView
      ? ROUTE_NAMES.HOME_VIEW_AUDIT
      : ROUTE_NAMES.AUDIT;
    console.log("openAudit", auditEntry, routeName);
    this.$router.push({
      name: routeName,
      params: { auditId: auditEntry.auditDoc.id },
    });
  }

  openAudits() {
    if (this.hasAuditProgramGrid) {
      this.$router.push({
        name: ROUTE_NAMES.AUDIT_PROGRAMS,
      });
      return;
    }

    const currentYear = new Date().getFullYear();
    const maxYear = (years: number[]) =>
      years.length > 0 ? years.reduce((a, b) => Math.max(a, b), 0) : null;

    const year = this.auditingYears.includes(currentYear)
      ? currentYear
      : maxYear(this.auditingYears);

    this.$router.push({
      name: ROUTE_NAMES.AUDITMATRIX_YEAR_FILTERED,
      params: {
        year: `${year ?? ""}`,
      },
    });
  }

  mounted() {
    this.fetchAudits();
  }

  beforeDestroy() {
    this.clearAudits();
    this.clearCalendarAudits();
  }
}
