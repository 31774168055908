

















































import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  TodoAny,
  TodoActionSignature,
} from "@auditcloud/shared/lib/utils/type-guards";

import { api } from "@/store/modules/audit";
import { api as schemasApi, FormSchemaDynamic } from "@/store/modules/schemas";
import { api as activityApi } from "@/store/modules/activities";
import { stripDialog } from "@/routenames";

import VueFormJsonSchema from "vue-form-json-schema";
import FormsControlMixin from "@/components/mixins/FormsControlMixin.vue";

import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { AuditPermissions } from "@auditcloud/shared/lib/utils/aclHelpers";
import DialogLayout from "../layouts/BaseLayouts/DialogLayout.vue";
import { formatAuditName } from "@auditcloud/shared/lib/utils/formatting/audits";
import { AuditClassConfig } from "@auditcloud/shared/lib/schemas";
import { ActivityVerbs, getActivityCreator } from "@/utils/activityCreator";

@Component({
  components: {
    VueFormJsonSchema,
    DialogLayout,
  },
  mixins: [FormsControlMixin],
})
export default class AuditInfoDialog extends Mixins(FormsControlMixin) {
  // TODO: Anhand der Permissions bestimmen ...
  @Prop({
    type: String,
    required: true,
  })
  readonly auditId!: string;

  editable: boolean = false;
  loading: boolean = false;
  formValid: boolean = false;
  vfjsOptions: Object = {};

  @Getter(api.getters.getAuditFormData, { namespace: api.namespace })
  getAuditFormData!: TodoAny;
  @Getter(api.getters.getAuditFormSchema, { namespace: api.namespace })
  getAuditFormSchema!: (schemaId: string) => FormSchemaDynamic;

  @Getter(api.getters.getAuditPermissions, { namespace: api.namespace })
  auditPermissions!: AuditPermissions;

  @Getter(api.getters.getAuditClass, { namespace: api.namespace })
  getAuditClass!: AuditClassConfig | null;

  @Action(activityApi.actions.createActivity, {
    namespace: activityApi.namespace,
  })
  createActivity!: TodoActionSignature;

  @Getter(schemasApi.getters.getVfjsOptions, {
    namespace: schemasApi.namespace,
  })
  getVfjsOptions!: TodoAny;
  @Getter(schemasApi.getters.getVfjsCommonSchemas, {
    namespace: schemasApi.namespace,
  })
  getVfjsCommonSchemas!: TodoAny;

  get formSchemaId() {
    return "metadata-form";
  }
  get formSchema() {
    const res = this.getAuditFormSchema(this.formSchemaId);

    return res;
  }
  get uiSchema() {
    return this.formSchema.ui(!this.editable || this.readonly, this.readonly);
  }
  get schema() {
    const res = this.formSchema.data;

    return res;
  }
  get readonly() {
    return !this.auditPermissions.writeMetadata;
  }
  get dialog(): boolean {
    return this.xDialog;
  }

  set dialog(val: boolean) {
    const auditId = this.auditId;
    const currentRouteName = this.$route.name ?? "error";
    const route = {
      name: stripDialog(currentRouteName),
      params: { auditId },
    };

    this.$router.push(route);
  }
  xDialog = true;

  close() {
    this.dialog = false;
  }

  @Action(api.actions.setAuditFormData, { namespace: api.namespace })
  setAuditFormData!: TodoActionSignature;

  saveChanges(e) {
    if (!this.formValid) {
      return;
    }
    console.log("save changes", e);
    this.loading = true;

    const activityCreator = getActivityCreator(this.$user, this.getAuditClass, {
      ...this.mixinFormData,
    });
    const activity = activityCreator?.(this.auditId, ActivityVerbs.UPDATE);
    if (activity) {
      this.createActivity(activity);
    }

    this.setAuditFormData({
      formSchemaId: this.formSchemaId,
      data: this.mixinFormData,
    })
      .then(res => {
        console.log("setAuditFormData", res);
        this.editable = false;
        this.mixinInitFormData(this.auditFormData);
      })
      .catch(err => {
        console.error("setAuditFormData", err);
      })
      .finally(() => {
        this.loading = false;
        this.dialog = false;
      });
  }
  change(e) {
    console.log('"change" event', e);

    this.mixinFormData = e;
  }
  onValidated(isValid) {
    console.log('"validated" event', isValid);
    this.formValid = isValid;
  }
  onChangeState(e) {
    console.log('"change-state" event', e);
  }

  editControl() {
    // TODO: Macht es sinn hier zu klonen und nicht schon beim created hook?
    this.editable = true;
    this.mixinInitFormData(this.auditFormData);
  }
  disableEditControl() {
    this.editable = false;
    this.mixinInitFormData(this.auditFormData);
  }

  cancelEdit() {
    this.editable = false;
    this.mixinInitFormData(this.auditFormData);
    this.vfjsOptions = this.getVfjsOptions;
  }

  get auditFormData() {
    return this.getAuditFormData(this.formSchemaId);
  }
  @Watch("auditFormData", { immediate: true, deep: true })
  onAuditFormDataChanged(newVal: any, oldVal: any) {
    this.mixinInitFormData(newVal);
    this.vfjsOptions = this.getVfjsOptions;
  }
}
