


















































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class AMaintenanceSoftwareUpdateDialog extends Vue {
  @Prop({ default: true })
  value!: boolean;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: true })
  allowDismiss!: boolean;
}
