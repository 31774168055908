













































































































































































import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { State, Action, Getter } from "vuex-class";
import { api } from "@/store/modules/users";

import Tribute from "tributejs";
import Turndown from "turndown";
import { UserInfo } from "../../types/User";
import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";

import { Dictionary } from "lodash";
import { AutocompleteUser } from "@auditcloud/shared/lib/schemas";

@Component({})
export default class AMentionInput extends Vue {
  @Prop({
    default: false,
    type: String,
  })
  readonly value!: boolean;

  @Prop({
    type: String,
  })
  readonly placeholder!: string;

  @Getter(api.getters.getLoadedUsers, {
    namespace: api.namespace,
  })
  readonly loadedUsers!: Dictionary<AutocompleteUser>;

  @Action(api.actions.createUserInactive, { namespace: api.namespace })
  createUserInactive!: (payload: {
    data: { email: string; displayName: string };
  }) => Promise<{ created: boolean; ref: IUserRef }>;

  userDialogVisible = false;
  newUserName = "";
  newUserMail = "";

  text = "";
  turndown = new Turndown();
  tribute?: Tribute<{ key: string; meta: UserInfo }>;

  constructor() {
    super();

    this.turndown.addRule("mention", {
      filter: ["a"],
      replacement: function (content, node, options) {
        return `[${
          (node as HTMLElement).getAttribute("data-mention") || content
        }](${(node as HTMLElement).getAttribute("href")})`;
      },
    });
  }

  created() {
    this.tribute = new Tribute<{
      key: string;
      meta?: UserInfo;
      addNewUser?: true;
    }>({
      menuContainer: this.$el,
      containerClass: "tribute-container-mentions",
      allowSpaces: true,
      selectTemplate: function (item) {
        if (typeof item === "undefined" || item.original.addNewUser) {
          return "";
        }

        return `<a href="user://${item.original.meta?.id}" data-mention="${item.original.key}">@${item.original.key}</a>`;
      },
      lookup: ({ meta }) => [meta?.displayName, meta?.email].join(" "),
      menuItemTemplate: ({ original }) => original.key,
      values: (text, cb) => {
        cb(
          [
            { key: "Externen Benutzer erwähnen", addNewUser: true } as any,
          ].concat(
            Object.entries(this.loadedUsers).map(([id, u]) => ({
              key: u.n,
              meta: {
                id,
                displayName: u.n,
                email: u.n,
              },
            })) ?? []
          )
        );
      },
    });
  }

  mounted() {
    this.tribute?.attach(this.$el.getElementsByClassName("input").item(0)!);

    this.$el
      .querySelector(".input")!
      .addEventListener("tribute-replaced", (e: any) => {
        console.log("Replace:", e);
        if (
          typeof e.detail.item === "undefined" ||
          e.detail.item.original.addNewUser
        ) {
          this.userDialogVisible = true;
          const mentionText: string = e.detail.context.mentionText || "";
          if (mentionText.includes("@")) {
            this.newUserMail = e.detail.context.mentionText;
          } else {
            this.newUserName = e.detail.context.mentionText;
          }
        }
      });
  }

  async saveUser() {
    console.log("Saving User:", this.newUserName, "/", this.newUserMail);
    const res = await this.createUserInactive({
      data: {
        displayName: this.newUserName,
        email: this.newUserMail,
      },
    });

    if (
      res.created ||
      confirm(
        this.$t("common.confirms.user_with_email_address_exist_use_existing", {
          displayName: res.ref.displayName,
        }).toString()
      )
    ) {
      const inputField = this.$el.querySelector(".input")!;
      const mention = `<a href="user://${res.ref.id}" data-mention="${res.ref.displayName}">@${res.ref.displayName}</a>`;
      inputField.normalize();
      inputField.innerHTML =
        inputField.innerHTML.replace(/(\s|&nbsp;)+/g, " ").trim() +
        " " +
        mention +
        " ";
      this.userDialogVisible = false;
      this.newUserName = "";
      this.newUserMail = "";
      this.onEdit({ target: inputField });
    }
  }

  onEdit(e) {
    const newText = this.turndown.turndown(e.target.innerHTML);
    this.text = newText;
    this.$emit("input", newText);
    console.log("Text:", newText);
  }

  @Watch("value")
  onValue(value) {
    if (value.trim() === "") {
      this.text = "";
      this.$el.querySelector(".input")!.innerHTML = "";
    }
  }

  onEnter(e: KeyboardEvent) {
    if (!e.shiftKey && !this.tribute?.isActive) {
      this.$emit("submit");
      e.preventDefault();
    }
  }
}
