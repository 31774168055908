import { AuditMetadataClient } from "@/types/Audit";
import { nullable, idable } from "@auditcloud/shared/lib/types/common";
import { AuditStatusId } from "@auditcloud/shared/lib/constants";
import {
  AuditResultDoc,
  TranslateableText,
  AuditPreparationState,
} from "@auditcloud/shared/lib/schemas";
import {
  WatchedDocument,
  unlistenerFunction,
  WatchedDocumentStates,
} from "@/utils/firestore";
import { ROUTE_NAMES } from "@/routenames";
import { IUserRef, typeIsIUserRef } from "@auditcloud/shared/lib/types/UserRef";
import { getQuestionNotesMapped } from "@/store/modules/audit/utils";
import {
  defaultDocType,
  defaultPageConfig,
  GroupSetting,
  PaginationSetting,
  SortSetting,
} from "@auditcloud/shared/lib/types/Audit/types";

import { TodoAny } from "@auditcloud/shared/lib/utils/type-guards";
import { ListSetting } from "@auditcloud/shared/lib/utils/filter/AuditItemListManipulator";
import { Filter } from "@auditcloud/shared/lib/utils/filter/types";
import { AuditItemWithId } from "@auditcloud/shared/lib/utils/audit/types";

export interface AuditProgress {
  audititem_count: number;
  audititem_answered_count: number;
  progress: number;
}

export type AuditQuestionNoteMap = ReturnType<typeof getQuestionNotesMapped>;

export type DocumentType = WatchedDocument<AuditMetadataClient | null>;
export type DocumentMeasureType = WatchedDocument<any>;
export type DocumentAuditItemsType = WatchedDocument<AuditResultDoc | null>;
export type DocumentFindingType = WatchedDocument<any>;

export interface AuditWorkflowStepInfo {
  statusId: string;
  text: string;
  description: string;
  transitionText: string;
  route: string;
}

export function defaultWorkflowSteps(): AuditWorkflowStepInfo[] {
  return [
    {
      statusId: AuditStatusId.Planning,
      text: "common.workflow.planning",
      description: "common.workflow_description.planning",
      transitionText: "common.transition.planning",
      route: ROUTE_NAMES.AUDITPLANNING,
    },
    {
      statusId: AuditStatusId.Preparation,
      text: "common.workflow.preparation",
      description: "common.workflow_description.preparation",
      transitionText: "common.transition.preparation",
      route: ROUTE_NAMES.AUDITPREPARATION,
    },
    {
      statusId: AuditStatusId.Execution,
      text: "common.workflow.execution",
      description: "common.workflow_description.execution",
      transitionText: "common.transition.execution",
      route: ROUTE_NAMES.AUDITEXECUTION,
    },
    {
      statusId: AuditStatusId.Wrapup,
      text: "common.workflow.postprocessing",
      description: "common.workflow_description.postprocessing",
      transitionText: "common.transition.postprocessing",
      route: ROUTE_NAMES.AUDITWRAPUP,
    },
    {
      statusId: AuditStatusId.Reporting,
      text: "common.workflow.reporting",
      description: "common.workflow_description.reporting",
      transitionText: "common.transition.reporting",
      route: ROUTE_NAMES.AUDITREPORTING,
    },
    {
      statusId: AuditStatusId.MeasureActions,
      text: "common.workflow.measures",
      description: "common.workflow_description.measures",
      transitionText: "common.transition.measures",
      route: ROUTE_NAMES.AUDITACTIONLIST,
    },
    {
      statusId: AuditStatusId.Completed,
      text: "common.workflow.finalized",
      description: "common.workflow_description.finalized",
      transitionText: "common.transition.finalized",
      route: ROUTE_NAMES.AUDITFINALIZED,
    },
    {
      statusId: AuditStatusId.Canceled,
      text: "common.workflow.canceled",
      description: "common.workflow_description.canceled",
      transitionText: "common.transition.canceled",
      route: ROUTE_NAMES.AUDITPLANNING,
    },
  ];
}

export interface AuditLoadingState {
  auditId: string;
  metadata: WatchedDocumentStates;
  auditItems: WatchedDocumentStates;
  auditMeasures: WatchedDocumentStates;
}

export function initialLoadingState(): Omit<AuditLoadingState, "auditId"> {
  return {
    metadata: WatchedDocumentStates.Idle,
    auditItems: WatchedDocumentStates.Idle,
    auditMeasures: WatchedDocumentStates.Idle,
  };
}

export class CurrentAuditState {
  auditId: string | null = null;
  loadingError: null | any = null;
  loadingState = initialLoadingState();
  auditIsBulkUpdating = false;

  Document: DocumentType = defaultDocType();
  Unlistener: nullable<unlistenerFunction> = null;

  MeasureUnlistener: nullable<unlistenerFunction> = null;
  findingMeasuresMap: { [findingId: string]: string[] } = {};

  AuditItemsDocument: DocumentAuditItemsType = defaultDocType();
  AuditItemsUnlistener: nullable<unlistenerFunction> = null;

  fulltextSearch: string = "";
  listSort: string[] = ["vda", "no"];

  pageConfig: PaginationSetting = defaultPageConfig();
  readonly workflowSteps: {
    text: string;
    route: string;
  }[] = defaultWorkflowSteps();
  stateDirty: boolean = false; // Speichert ob eine Transition möglich ist.
  transitionDirty: boolean = false;
  listSettings: AuditListsSettings = {};
  activeList: string = "";
  missingAuditDataReadPermission: boolean = false;
  selectedAuditItemIds: Array<AuditItemWithId["id"]> = [];
}

export interface AuditListSetting extends ListSetting {
  paging: PaginationSetting;
  sortings: SortSetting[];
  groupings: GroupSetting | null;
}

export interface AuditListsSettings {
  [id: string]: AuditListSetting;
}

export function getEmptyListSetting(): AuditListSetting {
  return {
    filters: [],
    groupings: null,
    paging: defaultPageConfig(),
    sortings: [],
  };
}

export interface ActivateSelfAssessmentParams {
  assessQuestions: boolean;
  assignedTo: IUserRef;
  dueDate: string;
  message: string;
  additionalQuestionListId: null | string;
}

export function typeIsActivateSelfAssessmentParams(
  val: any
): val is ActivateSelfAssessmentParams {
  return (
    val &&
    val instanceof Object &&
    typeof val.assessQuestions === "boolean" &&
    typeof val.dueDate === "string" &&
    typeof val.message === "string" &&
    (typeof val.additionalQuestionListId === "string" ||
      val.additionalQuestionListId === null) &&
    typeIsIUserRef(val.assignedTo)
  );
}

export interface ActivateExternalMeasuresParams {
  dueDate: string;
}

export function typeIsActivateExternalMeasuresParams(
  val: any
): val is ActivateExternalMeasuresParams {
  return val && val instanceof Object && typeof val.dueDate === "string";
}

export const AUDIT_ATTACHMENT_SIDEBAR_CONFIG = {
  component: "AAttachmentSidebar",
  title: "ExternTitle",
  props: {
    attachmentModulName: "audit",
    sidebarTitle:
      "components.widgets.sidebar.audit_attachment_list.audit_attachments",
  },
};

export const AUDIT_ADVANCED_SETTINGS_CONFIG = {
  component: "AAuditAdvancedSettings",
  title: "ExternTitle",
};

export const AUDIT_WORKFLOW_LOG_CONFIG = {
  component: "AWorkflowHistorySidebar",
  title: "ExternTitle",
  props: {
    attachmentModulName: "audit",
    sidebarTitle: "components.app.audit_panel_right.audit_log",
  },
};

export interface DimensionMap {
  [dimensionId: string]:
    | {
        name: TranslateableText;
        id?: string;
      }
    | undefined;
}

export interface AuditItemSettings {
  [auditItemId: string]: {
    allowMoreFindings: boolean;
    openDimensions: null | string[];
  };
}

export interface AnswerStatus {
  answered: boolean;
  text: TranslateableText;
}

/**
 * Liefert die Dimensionen des Audit zurück
 */
export type AuditDimensionExtractor = (
  rootGetters: TodoAny
) => null | { id: string; name: TranslateableText }[];

/**
 * Liefert Informationen welche Dimensionen ein AuditItem hat
 */
export type AuditItemDimensionExtractorFactory = (
  rootGetters: TodoAny,
  auditDimensions: ReturnType<AuditDimensionExtractor>
) => (auditItemId: string) => null | string[];

/**
 * Liefert Informationen welche Dimensionen ein Finding hat
 */
export type FindingDimensionExtractorFactory = (
  rootGetters: TodoAny,
  auditDimensions: ReturnType<AuditDimensionExtractor>
) => (auditItemId: string, findingId: string) => null | string[];

/**
 * Liefert Informationen welche Dimensionen noch nicht beantwortet wurden
 *
 *
 */
export type UnansweredAuditItemDimensionsFactory = (
  rootGetters: TodoAny,
  // auditDimensions: ReturnType<AuditDimensionExtractor>,
  auditItemDimensionResolver: ReturnType<AuditItemDimensionExtractorFactory>
) => (auditItemId: string) => null | string[];

export interface DimensionManager {
  auditDimensionExtractor: AuditDimensionExtractor;
  auditItemDimensionExtractorFactory: AuditItemDimensionExtractorFactory;
  findingDimensionExtractorFactory: FindingDimensionExtractorFactory;
  unansweredAuditItemDimensionsFactory: UnansweredAuditItemDimensionsFactory;
}

const BREAD_CRUMB_TYPES = [
  "completed",
  "readonly",
  "canceled",
  "remote",
  "remote-selfassessment",
  "selfassessment",
] as const;
export type BreadCrumbLabelType = typeof BREAD_CRUMB_TYPES[number];

export const BREAD_CRUMB_LABEL_I18N_DICT: {
  [element in BreadCrumbLabelType]: string;
} = {
  completed: "store.modules.audit.bread_crumb_finalized",
  canceled: "store.modules.audit.bread_crumb_canceled",
  readonly: "store.modules.audit.bread_crumb_readonly",
  remote: "store.modules.audit.bread_crumb_remote",
  "remote-selfassessment":
    "store.modules.audit.bread_crumb_remote_selfassessment",
  selfassessment: "store.modules.audit.bread_crumb_selfassessment",
};

export const BREAD_CRUMB_LABEL_ICON_DICT: {
  [element in BreadCrumbLabelType]: string;
} = {
  completed: "mdi-check-all",
  canceled: "mdi-progress-close",
  readonly: "mdi-pencil-off-outline",
  remote: "mdi-remote-desktop",
  "remote-selfassessment": "mdi-remote-desktop",
  selfassessment: "mdi-clipboard-text",
};

export type PreselectionFilter = Filter;

export interface MinimalTemplate {
  id: string;
  name: string;
  auditItemCount: number;
}
export interface AuditItemProperties {
  isSelfAssessmentEnabled: boolean;
  isConsidered: boolean;
}

export type PreparationStates = AuditPreparationState["step"];

export interface PreparationStateTransition {
  name: TranslateableText;
  newState: PreparationStates;
  enabled: boolean;
}
