













import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter } from "vuex-class";

import { VTabs, VTab } from "vuetify/lib";

import { api as appApi } from "@/store/modules/app";

@Component({
  components: {
    VTabs,
    VTab,
  },
})
export default class AMenuTabs extends Vue {
  @Getter(appApi.getters.getMenuTabs, {
    namespace: appApi.namespace,
  })
  tabItems!: any[];

  transformText(text: string) {
    return this.$vuetify.breakpoint.width >= 400 ? text : text.charAt(0);
  }
}
