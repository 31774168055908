import ui from "./ui.json";
import data from "./data.json";

import { FormSchema } from "../../../index";

const dialogSchema: FormSchema = {
  ui,
  data,
};
export default dialogSchema;
