








































































































































































































import Vue from "vue";
import { UserRef } from "@auditcloud/shared/lib/schemas";
import { Component, Prop, Watch } from "vue-property-decorator";
import AUserChip from "@/components/snippets/AUserChip.vue";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import { getUsersByRoleFromMeasure } from "@/components/utils";
import { idable } from "@auditcloud/shared/lib/types/common";
import {
  ConfigType,
  DataType,
} from "@auditcloud/shared/lib/workflow/modules/Measure/transitionControls/notifications";
import { TransitionConfig } from "@auditcloud/shared/lib/workflow/types/Transition";
import { Dictionary, uniqBy } from "lodash";
import { UsersByRole } from "@/components/types";
import { MeasureRoles } from "@auditcloud/shared/lib/constants/roles";
import { api as usersApi } from "@/store/modules/users";

interface UiState {
  advancedMode: boolean;
  includeMyself: boolean;
  /** if != -1 and `includeMyself` is false, insert current user here (grey) */
  indexOfMyself: number;
  comment: string;
}

function isSameUserAs(user: UserRef | null) {
  return (otherUser: UserRef | null) => user?.id === otherUser?.id;
}

function isDifferentUserFrom(user: UserRef | null) {
  return (otherUser: UserRef | null) => user?.id !== otherUser?.id;
}

@Component({ components: { AUserChip } })
export default class AMeasureTransitionNotificationControl extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  measure!: MeasureProcessDocument;

  @Prop({
    type: Object,
    default: null,
  })
  transition!: idable<TransitionConfig> | null;

  @Prop({
    type: Object,
    required: true,
  })
  componentConfig!: ConfigType;

  @Prop({
    type: Object,
  })
  transitionData!: DataType | null;

  @Prop({
    type: Object,
    default(): UiState {
      return {
        advancedMode: false,
        includeMyself: true,
        indexOfMyself: -1,
        comment: "",
      };
    },
  })
  transitionUiState!: UiState;

  get advancedMode(): boolean {
    return this.transitionUiState.advancedMode ?? false;
  }

  get includeMyself(): boolean {
    return this.transitionUiState.includeMyself ?? false;
  }

  set includeMyself(includeMyself: boolean) {
    this.updateRecipientList(this.recipientList, includeMyself);
  }

  get assignedToQueryAction() {
    return {
      name: usersApi.actions.queryUser,
      namespace: usersApi.namespace,
    };
  }

  changeToAdvancedMode() {
    const includeMyself = this.transitionData?.transitionNotify.some(
      isSameUserAs(this.currentUser)
    );
    this.$emit("transition-ui-updated", {
      ...this.transitionUiState,
      advancedMode: true,
      includeMyself,
    });
  }

  /** When the transition changes -> preselect the assigned user for notification */
  @Watch("transitionData", { immediate: true })
  onAssignedToChange(newData?: DataType | null, oldData?: DataType | null) {
    const newAssignee = newData?.assignedTo;
    const oldAssignee = oldData?.assignedTo;

    const newAssigneeIsCurrentUser = newAssignee?.id === this.currentUserId;

    // if the new assignee is not yet selected and not the current user, select it
    if (
      newAssignee &&
      !newAssigneeIsCurrentUser &&
      !this.recipientList.some(isSameUserAs(newAssignee))
    ) {
      this.recipientList = [...this.recipientList, newAssignee];
    }

    // remove the old assignee from the selection
    const assigneeChanged = newAssignee?.id !== oldAssignee?.id;
    if (assigneeChanged) {
      this.recipientList = this.recipientList.filter(
        isDifferentUserFrom(oldAssignee ?? null)
      );
    }
  }

  get recipientList(): UserRef[] {
    const recipientsToNotify = this.transitionData?.transitionNotify ?? [];
    const indexOfMyself = this.transitionUiState.indexOfMyself;
    if (
      this.currentUser &&
      indexOfMyself > -1 &&
      recipientsToNotify.every(isDifferentUserFrom(this.currentUser))
    ) {
      return [
        ...recipientsToNotify.slice(0, indexOfMyself),
        this.currentUser,
        ...recipientsToNotify.slice(indexOfMyself),
      ];
    }
    return recipientsToNotify;
  }

  set recipientList(users: UserRef[]) {
    this.updateRecipientList(users, this.includeMyself);
  }

  updateRecipientList(users: UserRef[], includeMyself: boolean) {
    const indexOfMyself = users.findIndex(isSameUserAs(this.currentUser));
    this.$emit("transition-ui-updated", {
      ...this.transitionUiState,
      includeMyself,
      indexOfMyself,
    });

    this.$emit("transition-data-updated", {
      transitionNotify: !includeMyself
        ? users.filter(user => user.id !== this.currentUserId)
        : users,
    });
  }

  get isCurrentUserInRecipientList() {
    return this.recipientList.some(isSameUserAs(this.currentUser));
  }

  get numberOfRecipientsToNotify() {
    return this.transitionData?.transitionNotify.length ?? 0;
  }

  get notifyAssignee() {
    const nextAssignee = this.transitionData?.assignedTo;

    if (!nextAssignee) {
      return false;
    }

    return this.recipientList.some(isSameUserAs(nextAssignee));
  }

  onNotifyAssigneeChange(checked: boolean) {
    const nextAssignee = this.transitionData?.assignedTo;

    if (!nextAssignee) {
      return;
    }

    this.includeMyself =
      checked && !this.advancedMode && nextAssignee.id === this.currentUserId;

    if (checked) {
      this.recipientList = uniqBy([...this.recipientList, nextAssignee], "id");
    } else {
      this.recipientList = this.recipientList.filter(
        isDifferentUserFrom(nextAssignee)
      );
    }
  }

  get usersByRole(): UsersByRole<MeasureRoles> {
    console.log(
      "componentConfig:getUsersByRoleFromMeasure",
      this.componentConfig
    );
    return getUsersByRoleFromMeasure(
      this.measure,
      this.componentConfig.defaultSelectedGroupsForNotification ?? []
    );
  }

  get comment(): string | undefined {
    return this.transitionUiState.comment;
  }

  set comment(comment: string | undefined) {
    this.$emit("transition-ui-updated", {
      ...this.transitionUiState,
      comment: comment ?? "",
    });
    this.emitNewComment(comment ?? "");
  }

  emitNewComment(comment: string) {
    this.$emit("transition-data-updated", { transitionComment: comment });
  }

  get notifyAssigneeLabel() {
    const nextAssignee = this.transitionData?.assignedTo;

    if (this.advancedMode) {
      return this.$t(
        "components.transition.measure_transition_notification.nextAssignee"
      );
    }

    if (nextAssignee) {
      return this.$t(
        "components.transition.measure_transition_notification.notify",
        { name: nextAssignee.displayName }
      );
    }

    return null;
  }

  get allUsersSelected(): Dictionary<boolean> {
    const allUsersSelected = {};
    this.usersByRole.forEach(userByRole => {
      const everyUserForRole = userByRole.users.every(roleUser =>
        this.recipientList.some(isSameUserAs(roleUser))
      );
      allUsersSelected[userByRole.roleId] = everyUserForRole;
    });
    return allUsersSelected;
  }

  get someUsersSelected() {
    const someUsersSelected = {};
    this.usersByRole.forEach(userByRole => {
      const someUserForRole = userByRole.users.some(roleUser =>
        this.recipientList.some(isSameUserAs(roleUser))
      );
      someUsersSelected[userByRole.roleId] = someUserForRole;
    });
    return someUsersSelected;
  }

  get selection(): Dictionary<Dictionary<boolean>> {
    const usersByRole: UsersByRole<MeasureRoles> = this.usersByRole;
    return Object.values(usersByRole).reduce((result, role) => {
      return {
        ...result,
        [role.roleId]: role.users.map(roleUser =>
          this.recipientList.some(isSameUserAs(roleUser))
        ),
      };
    }, {});
  }

  get currentUser() {
    return this.$user.ref();
  }

  get currentUserId() {
    return this.currentUser?.id;
  }

  /**
   * A role was selected. This enables/disables all users of this role
   */
  toggleRole(checked: boolean, roleId: string) {
    const roleUsers =
      this.usersByRole.find(userByRole => userByRole.roleId === roleId)
        ?.users ?? [];

    if (checked) {
      this.recipientList = uniqBy([...this.recipientList, ...roleUsers], "id");
    } else {
      this.recipientList = this.recipientList.filter(
        recipient => !roleUsers.some(isSameUserAs(recipient))
      );
    }
  }
}
