





















































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Action } from "vuex-class";

import VJsoneditor from "v-jsoneditor";
import { cloneDeep, isEqual, Dictionary } from "lodash";
import ConfigurationAuditDocSchema from "@auditcloud/shared/lib/schemas/documents/ConfigurationAuditDoc.schema.json";
import { ConfigurationAuditDoc } from "@auditcloud/shared/lib/schemas";
import ajv from "@auditcloud/shared/lib/schemas/validator";

import { api } from "@/store/modules/configuration";
import { api as appApi } from "@/store/modules/app";
import { getterNs } from "../../utils/VuexHelper";
import { TodoAny } from "@auditcloud/shared/lib/utils/type-guards";
@Component({
  components: { VJsoneditor },
})
export default class AConfigEditorAudit extends Vue {
  editedData: null | ConfigurationAuditDoc = null;
  auditPreset: Dictionary<any> = {};

  valid: boolean = true;
  options = {
    ajv,
    onValidationError: this.onValidationError,
    schema: ConfigurationAuditDocSchema,
    templates: [],
    mode: "form",
    modes: ["tree", "form", "code", "text"], // // v-if="$user.isAdmin()"
  };
  //missingDefault

  segmentName = "audit";

  get storedData(): ConfigurationAuditDoc | null {
    const getterName = getterNs(api, this.segmentName);
    if (this.segmentName in api.getters) {
      return cloneDeep(this.$store.getters[getterName]);
    } else {
      return null;
    }
  }
  @Watch("storedData", {
    immediate: true,
  })
  onSegmentNameChange(
    val: ConfigurationAuditDoc | null,
    oldVal?: ConfigurationAuditDoc | null
  ) {
    this.valid = true;
    this.editedData = cloneDeep(val);
  }

  get isDirty() {
    return !isEqual(this.storedData, this.editedData);
  }

  get auditPresetJson() {
    return JSON.stringify(this.auditPreset, undefined, 2);
  }

  get auditCreateSettings() {
    return cloneDeep(this.editedData?.auditCreateSettings ?? {});
  }

  @Action(api.actions.updateConfiguration, { namespace: api.namespace })
  updateConfiguration!: (payload: {
    segment: string;
    data: Dictionary<any>;
  }) => Promise<any>;

  @Action(appApi.actions.setStatus, { namespace: appApi.namespace })
  setStatus!: (payload: string) => Promise<any>;

  importJsonConf() {
    console.log(
      "importJsonConf",
      this.segmentName,
      this.editedData,
      this.valid,
      this.isDirty
    );
    if (this.valid && this.isDirty && this.editedData !== null) {
      this.setStatus("loading");
      try {
        console.log("Start Save");
        const payload = { segment: this.segmentName, data: this.editedData };

        console.log("Importing", payload);

        this.updateConfiguration(payload);
        this.setStatus("success");
      } catch (error) {
        console.log("Json Parse failed:", error);
        this.setStatus("error");
      }
    }
  }
  onError(err: TodoAny) {
    console.error("AConfigEditorAudit:onError", err);
    this.valid = false;
  }
  onValidationError(errors: any[]) {
    console.error("AConfigEditorAudit:ValidationError.1", errors);
    this.valid = errors.length === 0;
    errors.forEach(error => {
      console.error("AConfigEditorAudit:ValidationError", error);
    });
  }
  onInput(data: null | ConfigurationAuditDoc) {
    this.valid = true;
    this.editedData = data;
  }
}
