



























import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { typeIsArrayOf } from "@auditcloud/shared/lib/utils/type-guards";
import { isString } from "util";
import { isObject } from "lodash";

export interface ToggleConfig {
  id: string;
  icon: string;
  iconOutlined?: string;
  title: string;
  iconColor?: string;
}

@Component({})
export default class AIconTogglebar extends Vue {
  @Prop({
    type: Array,
    default: () => [],
    validator: (v: unknown) =>
      typeIsArrayOf(v, (vi: any): vi is ToggleConfig => {
        return isString(vi?.id) && isString(vi?.icon) && isString(vi?.title);
      }),
  })
  readonly toggles!: ToggleConfig[];

  @Prop({
    type: String,
    default: "",
    validator: isString,
  })
  readonly active!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly outlined!: boolean;

  setToggle(toggle: string) {
    this.$emit("input", toggle);
  }

  get isTouchDevice() {
    return matchMedia("(hover: none)").matches;
  }
}
