












import ATextareaDialog from "@/components/dialogs/ATextareaDialog.vue";
import { AuditItemWithId } from "@auditcloud/shared/lib/utils/audit/types";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { api as auditApi } from "../../store/modules/audit";

@Component({
  components: {
    ATextareaDialog,
  },
})
export default class AAuditItemNoteDialog extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  auditItemId!: AuditItemWithId["id"];

  @Prop({
    type: Boolean,
    required: true,
  })
  isOpen!: boolean;

  isSaving: boolean = false;

  @Getter(auditApi.getters.getMappedNotes, {
    namespace: auditApi.namespace,
  })
  mappedNotes!: Map<AuditItemWithId["id"], string[]>;

  get editedNote(): string {
    const questionNote = this.mappedNotes.get(this.auditItemId)?.join(",");
    return questionNote ?? "";
  }

  @Action(auditApi.actions.setNote, {
    namespace: auditApi.namespace,
  })
  setNote!: (payload: {
    auditItemId: AuditItemWithId["id"];
    note: string;
  }) => Promise<void>;

  @Action(auditApi.actions.deleteNote, {
    namespace: auditApi.namespace,
  })
  deleteNote!: (payload: {
    auditItemId: AuditItemWithId["id"];
  }) => Promise<void>;

  async editNote(editedNote: string) {
    const note = editedNote.trim();
    const isNoteTextEmpty = note === "";
    const auditItemId = this.auditItemId;

    this.isSaving = true;
    if (isNoteTextEmpty) {
      await this.deleteNote({ auditItemId });
    } else {
      await this.setNote({ auditItemId, note });
    }
    this.isSaving = false;

    this.$emit("close");
  }
}
