import { AuditStatusId } from "@auditcloud/shared/lib/constants";
import { AuditMetaStatusChartBuilderConfig } from "./classes/AuditMetaStatusChartBuilder";
import {
  AuditProgramConfig,
  OrganizationUnitConfig,
} from "@auditcloud/shared/lib/schemas";
import { idable } from "@auditcloud/shared/lib/types/common";

export { AuditMetaStatusChartBuilder } from "./classes/AuditMetaStatusChartBuilder";
export { AuditProgramCardData } from "./classes/AuditProgramCardData";

export type AuditProgram = Required<idable<AuditProgramConfig>>;
export type OrganizationUnit = Required<idable<OrganizationUnitConfig>>;

export interface OrganizationUnitCardData extends OrganizationUnit {
  auditProgramCount: number;
}

// Todo: ist die Zuordnung zu den Metastatus sinnvoll?
export const AUDIT_WORKFLOW_META_STATUS: AuditMetaStatusChartBuilderConfig[] = [
  {
    statusText: "components.widgets.audit_program_card.planning",
    color: "#FDCE39",
    workflowStatusIds: new Set([
      AuditStatusId.Planning,
      AuditStatusId.Preparation,
    ]),
  },
  {
    statusText: "components.widgets.audit_program_card.execution",
    color: "#0597A7",
    workflowStatusIds: new Set([
      AuditStatusId.Execution,
      AuditStatusId.Wrapup,
      AuditStatusId.Reporting,
      AuditStatusId.MeasureActions,
    ]),
  },
  {
    statusText: "components.widgets.audit_program_card.completed",
    color: "#90BF7A",
    workflowStatusIds: new Set([
      AuditStatusId.Completed,
      AuditStatusId.Canceled,
    ]),
  },
];

export class AuditProgramStatistic {}

export type State = AuditProgramStatistic;
