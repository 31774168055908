











import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class AFeatureNotAvailableDialog extends Vue {}
