import { MutationTree } from "vuex";
import { MeasureWorkflowsState } from "./types";
import { unlistenerFunction, WatchedDocument } from "@/utils/firestore";
import { WorkflowConfig } from "@auditcloud/shared/lib/workflow/types/Workflow";
import { Dictionary } from "vue-router/types/router";
import { MeasureWorkflow } from "@auditcloud/shared/lib/workflow/modules/Measure";

const SET_MEASURE_UNLISTENER = "SET_MEASURE_UNLISTENER";
const SET_MEASURE_METADATA = "SET_MEASURE_METADATA";
const SET_WORKFLOWS = "SET_WORKFLOWS";

const n = {
  SET_MEASURE_UNLISTENER,
  SET_WORKFLOWS,
};

const mutations: MutationTree<MeasureWorkflowsState> = {
  [n.SET_MEASURE_UNLISTENER](state, measuresUnlistener: unlistenerFunction) {
    const prevUnlistener = state.Unlistener;
    state.Unlistener = measuresUnlistener;
    if (prevUnlistener) {
      prevUnlistener();
    }
  },
  [n.SET_WORKFLOWS](state, data: Dictionary<MeasureWorkflow>) {
    console.log("SET_WORKFLOWS", state, data);
    // import { WorkflowConfig } from "@auditcloud/shared/lib/workflow/types/Workflow";
    state.Workflows = data;
  },
};

export { n as mutationNames, mutations };
