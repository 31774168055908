var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"70%","height":"70%","persistent":!_vm.markingDisabled,"close-delay":1000,"fullscreen":_vm.dialogFullscreen},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2",attrs:{"primary-title":""}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.markingDisabled),expression:"markingDisabled"}],staticClass:"mr-5",attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('div',[_c('span',[_vm._v(_vm._s(_vm.name))])]),_c('v-spacer'),(
          _vm.hasEditPermission &&
          _vm.isMarkingAllowed &&
          _vm.displayMode === 'editable' &&
          !_vm.isBlobPreview
        )?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.showMarkerArea(true)}}},[_vm._v(" "+_vm._s(_vm.$t("components.dialogs.attachment_dialog.edit_annotation"))+" ")]):_vm._e(),(_vm.markingDisabled && !_vm.loading)?[(_vm.numberOfMarkers() > 0)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('v-btn',{staticClass:"mr-3",attrs:{"icon":"","loading":_vm.downloading},on:{"click":function($event){_vm.showMenu = true}}},[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,false,3549613104),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.downloadMediaPlain}},[_vm._v(" "+_vm._s(_vm.$t( "components.dialogs.attachment_dialog.download_picture_plain" ))+" ")]),_c('v-list-item',{on:{"click":_vm.downloadMediaWithMarkers}},[_vm._v(" "+_vm._s(_vm.$t( "components.dialogs.attachment_dialog.download_picture_with_markers" ))+" ")])],1)],1):_c('v-btn',{staticClass:"mr-3",attrs:{"icon":""},on:{"click":_vm.downloadMediaPlain}},[_c('v-icon',[_vm._v("mdi-download")])],1)]:_vm._e()],2),(_vm.externalSource)?_c('div',{staticClass:"external-warning py-2"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-alert")]),_vm._v(" "+_vm._s(_vm.$t("common.tooltips.tooltip_external_attachment"))+" ")],1):_vm._e(),(_vm.loading)?_c('v-card-text',{staticStyle:{"text-align":"center"}},[_c('v-progress-circular',{staticClass:"mt-3",attrs:{"indeterminate":"","size":70,"width":7}}),_vm._v(" "+_vm._s(_vm.$t("components.dialogs.attachment_dialog.loading", { err: _vm.$ft(_vm.error), }))+" ")],1):_c('v-card-text',{staticClass:"text-center"},[(_vm.isImage)?[_c('div',{class:_vm.markingDisabled ? 'img-container' : 'img-container-editable'},[_c('div',{attrs:{"id":"pictureWithMarkers"}},[_c('img',{ref:"imgRef",class:_vm.markingDisabled
                  ? 'attachment-dialog-marker-img'
                  : 'attachment-dialog-marker-img-invisible',attrs:{"src":_vm.previewUrl},on:{"load":function($event){return _vm.onImgLoaded($event)}}})])]),(_vm.imageToSmallToEdit)?_c('v-alert',{staticClass:"mt-5",attrs:{"type":"warning","dense":"","text":""}},[_vm._v(" "+_vm._s(_vm.$t( "components.dialogs.attachment_dialog.resolution_image_warning" ))+" ")]):(_vm.screenToSmallToEdit)?_c('v-alert',{staticClass:"mt-5",attrs:{"type":"warning","dense":"","text":""}},[_vm._v(" "+_vm._s(_vm.$t( "components.dialogs.attachment_dialog.resolution_screen_warning" ))+" ")]):_vm._e()]:[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":_vm.previewUrl,"download":_vm.name,"target":"_blank","title":_vm.$t('components.dialogs.attachment_dialog.download')}},[_c('v-icon',{attrs:{"size":"128px"}},[_vm._v(_vm._s(_vm.icon))])],1)]],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }