

















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import {
  ConfigType,
  DataType,
} from "@auditcloud/shared/lib/workflow/modules/Measure/transitionControls/comment";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import { idable } from "@auditcloud/shared/lib/types/common";
import { TransitionConfig } from "@auditcloud/shared/lib/workflow/types/Transition";

@Component({ name: "AMeasureTransitionCommentControl" })
export default class AMeasureTransitionCommentControl extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  measure!: MeasureProcessDocument;

  @Prop({
    type: Object,
    default: null,
  })
  transition!: idable<TransitionConfig> | null;

  @Prop({
    type: Object,
    required: true,
  })
  componentConfig!: ConfigType;

  @Prop({
    type: Object,
    required: true,
  })
  transitionData!: DataType;

  get comment(): string | undefined {
    return this.transitionData?.transitionComment;
  }

  set comment(comment: string | undefined) {
    this.emitNewComment(comment ?? "");
  }

  emitNewComment(comment: string) {
    this.$emit("transition-data-updated", { transitionComment: comment });
  }
}
