import firebase from "firebase/compat/app";

import { mapValues } from "lodash";
import { ActionContext } from "vuex";
import { MailTemplateState } from "./types";
import { RootState } from "../../types";
import { MailTemplateDoc } from "@auditcloud/shared/lib/schemas";
import { createCollectionObserver, QueryData } from "@/utils/firestore";
import { CollectionNames } from "@auditcloud/shared/lib/types/common";
import { mutationNames as mn } from "./mutations";
import { vdc, rdu } from "../user";
import { cleanupMailTemplate } from "./utils";
import { fieldPath } from "@auditcloud/shared/lib/utils/firestorePathHelper";
import {
  REV_KEY,
  VERSIONED_DOCUMENT_FIELDNAME,
} from "@auditcloud/shared/lib/types/VersionedDocument";

type Context = ActionContext<MailTemplateState, RootState>;

const actions = {
  async startWatch(context: Context) {
    const unlistener = createCollectionObserver(
      CollectionNames.MAIL_TEMPLATES,
      {
        filter: [
          new QueryData(
            fieldPath(REV_KEY, VERSIONED_DOCUMENT_FIELDNAME.DELETED),
            "==",
            false
          ),
        ],
      },
      updateData => {
        context.commit(mn.SET_DOCUMENTS, updateData);
      },
      updateMetadata => {},
      (error, collection) => {
        console.error("Error(listenOnEmailTemplates): ", error, collection);
      }
    );
    context.commit(mn.WATCH, unlistener);
  },
  async create(
    context: Context,
    payload: { id: string; data: MailTemplateDoc }
  ) {
    const db = firebase.firestore();
    const docRef = db
      .collection(CollectionNames.MAIL_TEMPLATES)
      .doc(payload.id);
    await db.runTransaction(async transaction => {
      const doc = await transaction.get(docRef);
      if (doc.exists) {
        throw new Error("invalid/email-template-id/duplicates");
      }
      transaction.set(docRef, {
        docVersion: vdc(context.rootGetters),
        ...cleanupMailTemplate(payload.data),
      });
    });
  },
  async update(context: Context, templateData: MailTemplateDoc) {
    const docId = context.state.currentTemplateId;

    const db = firebase.firestore();
    const docRef = db.collection(CollectionNames.MAIL_TEMPLATES).doc(docId);
    await db.runTransaction(async transaction => {
      const doc = await transaction.get(docRef);
      if (!doc.exists) {
        throw new Error("invalid/email-template-id/not-found");
      }
      transaction.update(docRef, {
        ...rdu(context.rootGetters),
        ...cleanupMailTemplate(templateData),
      });
    });
  },
};

const n = mapValues(actions, (_, key) => key);

export { n as actionNames, actions };
