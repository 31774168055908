
































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

import VJsoneditor from "v-jsoneditor";
import { isEqual, cloneDeep } from "lodash";

import { api } from "@/store/modules/i18n";
import { TodoAny, TodoArray } from "@auditcloud/shared/lib/utils/type-guards";
import { TranslationPatchMap } from "@/store/modules/i18n/types";
import Ajv from "ajv";

function initAjv() {
  return Ajv({
    allErrors: true,
    verbose: true,
  });
}

@Component({
  name: "ATranslationTable",
  components: { VJsoneditor },
})
export default class ATranslationTable extends Vue {
  editedData: TranslationPatchMap = {};
  loading: boolean = false;
  valid: boolean = true;
  options = {
    ajv: initAjv(),
    onValidationError: this.onValidationError,
    schema: {
      $schema: "http://json-schema.org/draft-07/schema#",
      $id: "https://schemas.next-audit.de/TranslationPatch.schema.json",
      title: "The TranslationPatch Schema",
      type: "object",
      patternProperties: {
        "^[a-zA-Z0-9_$]+(\\.[$a-zA-Z0-9_$]+)*$": {
          type: "object",
          patternProperties: {
            "^[a-z]{2}$": { type: "string" },
          },
          additionalProperties: false,
        },
      },
      additionalProperties: false,
    },
    templates: [
      /*
          {
            text: "Person",
            title: "Insert a Person Node",
            className: "jsoneditor-type-object",
            field: "PersonTemplate",
            value: {
              firstName: "John",
              lastName: "Do",
              age: 28
            }
          },
          {
            text: "Address",
            title: "Insert a Address Node",
            field: "AddressTemplate",
            value: {
              street: "",
              city: "",
              state: "",
              "ZIP code": ""
            }
          } */
    ],
    mode: "tree",
    modes: ["tree", "form", "code", "text"], // // v-if="$user.isAdmin()"
  };

  selectedLocales: string[] = [];

  @Getter(api.getters.getTranslationPatchMap, { namespace: api.namespace })
  translationPatchMap!: TranslationPatchMap;

  @Action(api.actions.storeTranslations, { namespace: api.namespace })
  storeTranslations!: (payload: TranslationPatchMap) => Promise<void>;

  @Watch("translationPatchMap", {
    immediate: true,
  })
  onTranslationPatchMapChange(
    val: TranslationPatchMap,
    oldVal?: TranslationPatchMap
  ) {
    console.log("onTranslationPatchMapChange", val, oldVal);

    this.loading = false;
    this.valid = true;
    this.editedData = cloneDeep(this.translationPatchMap);
  }

  get knownLocales() {
    return this.$i18n.availableLocales;
  }

  get isDirty() {
    return !isEqual(this.translationPatchMap, this.editedData);
  }

  onError(err: TodoAny) {
    console.error("ATranslationTable:onError", err);
    this.valid = false;
  }
  onInput(data: TranslationPatchMap) {
    this.valid = true;
    this.editedData = data;
  }
  onValidationError(errors: TodoArray) {
    this.valid = errors.length === 0;
    errors.forEach(error => {
      console.error("ValidationError", error);
    });
  }
  save() {
    this.loading = true;
    this.storeTranslations(this.editedData)
      .catch(err => {
        console.error("storeTranslations", err);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
