





















































































































































































































































































import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { State, Action, Getter, Mutation } from "vuex-class";
import ASignInCard from "@auditcloud/components/layouts/ASignInCard.vue";

import {
  api as userApi,
  Actions as UserActions,
  Getters as UserGetters,
} from "@/store/modules/user";
import { api as confApi } from "@/store/modules/configuration";

import { VForm } from "../components/types";
import { testCall } from "./SigninT";
import { isString } from "lodash";
import { ROUTE_NAMES, SIGNIN_PATH } from "@/routenames";
import { ConfigurationSystemDoc } from "@auditcloud/shared/lib/schemas";
import { NEXTAUDIT_SUPPORT_MAIL } from "@auditcloud/shared/lib/constants";

@Component({ components: { ASignInCard } })
export default class Signin extends Vue {
  tab: String = "password";
  showOtherLoginMethods: boolean = false;
  waitForActivation: boolean = false;
  validRegistration: boolean = false;
  validLogin: boolean = true;
  validSAMLLogin: boolean = true;
  errorMessage: string | null = null;
  errorCode: string | null = null;
  email: string = "";
  password: String = "";
  passwordConfirm: String = "";
  displayName: String = "";
  alert: boolean = false;
  acceptAgb: boolean = false;
  acceptEmail: boolean = false;
  loading: boolean = false;
  isProd: any = process.env.NODE_ENV === "production";

  get logos() {
    const logos: Array<{ url: string; src: string }> = [
      {
        url: "https://www.next-audit.de",
        src: require("../assets/logo_white.png"),
      },
    ];
    return logos;
  }

  get supportMail() {
    return this.systemConfig.supportMail ?? NEXTAUDIT_SUPPORT_MAIL;
  }

  get placeholder(): string {
    const ph = this.systemConfig?.samlValidation?.placeholder ?? "";
    console.log("PLACE_HOLDER", ph);
    return this.$ft(ph);
  }

  get welcomeMessage() {
    return this.$t("views.signin.welcome_text");
  }

  get samlWelcomeMessage(): string | null {
    const msg = this.systemConfig?.samlValidation?.welcomeMessage ?? "";
    return this.$ft(msg) || null; // convert empty string to null
  }

  get error(): string {
    const errorMessage = this.errorMessage ? `: ${this.errorMessage}` : "";
    return `${this.$t("views.signin.error")}${errorMessage} - Code: ${
      this.errorCode
    }`;
  }

  get errorHint(): string {
    return this.$ft(
      this.systemConfig.samlValidation?.errorHint ??
        "common.rules.email_invalid"
    );
  }

  get agb(): string {
    return this.$t("views.signin.agb").toString();
  }

  get marketing(): string {
    return this.$t("views.signin.marketing").toString();
  }

  get rules(): Record<
    "required" | "min6chars" | "customEmail" | "email",
    (v?: string) => string | boolean
  > {
    return {
      required: v =>
        !!v || this.$t("views.signin.rules_field_required").toString(),
      min6chars: v =>
        (v?.length ?? 0) >= 6 ||
        this.$t("views.signin.rules_min6chars").toString(),
      email: v =>
        /.+@.+\..+/.test(v ?? "") ||
        this.$t("common.rules.email_invalid").toString(),
      customEmail: v => this.customEmailRegEx.test(v ?? "") || this.errorHint,
    };
  }

  get customEmailRegEx(): RegExp {
    const regexpString: string =
      //eslint-disable-next-line no-useless-escape
      this.systemConfig.samlValidation?.validationPattern ?? ".+@.+\..+";
    return new RegExp(regexpString);
  }

  @Getter(userApi.getters.getCurrentUser, { namespace: userApi.namespace })
  getCurrentUser!: UserGetters["getCurrentUser"];

  @Getter(confApi.getters.system, { namespace: confApi.namespace })
  systemConfig!: ConfigurationSystemDoc;

  get allowRegister() {
    return this.systemConfig.allowRegistration;
  }
  get hasSamlLogin() {
    return (
      this.systemConfig.loginMethods.includes("saml") ||
      typeof this.$route.query.saml !== "undefined"
    );
  }
  get hasPasswordLogin() {
    return this.systemConfig.loginMethods.includes("password") || false;
  }

  get showRegister() {
    return this.allowRegister && this.showOtherLoginMethods;
  }

  get showSamlLogin() {
    return (
      this.hasSamlLogin &&
      (this.defaultLoginMethod === "saml" || this.showOtherLoginMethods)
    );
  }

  get showPasswordLogin() {
    return (
      this.hasPasswordLogin &&
      (this.defaultLoginMethod === "password" || this.showOtherLoginMethods)
    );
  }

  get loginMethodCount(): number {
    return [
      this.allowRegister,
      this.hasSamlLogin,
      this.hasPasswordLogin,
    ].filter(v => v).length;
  }

  get showTabs() {
    return this.loginMethodCount > 1 && this.showOtherLoginMethods;
  }

  get defaultLoginMethod() {
    return (
      this.systemConfig.defaultLoginMethod ??
      this.systemConfig.loginMethods[0] ??
      "password"
    );
  }

  @Action(userApi.actions.userSignIn, { namespace: userApi.namespace })
  userSignIn!: UserActions["userSignIn"];

  @Action(userApi.actions.userSignInWithSAML, { namespace: userApi.namespace })
  userSignInWithSAML!: UserActions["userSignInWithSAML"];

  @Action(userApi.actions.userSignUp, { namespace: userApi.namespace })
  userSignUp!: UserActions["userSignUp"];

  navigateToPasswordReset() {
    this.$router.push({
      name: ROUTE_NAMES.RESETPASSWORD,
      query: {
        redirect: this.$route.query.redirect,
      },
    });
  }

  validateRegistration() {
    // TODO: Smell - Das funktioniert so erst nach dem 2. Klicken
    console.log("Validate the form");
    (this.$refs.formRegistration as VForm).validate();
  }
  userSignMeIn(evt) {
    console.log("userSignMeIn", evt);
    this.loading = true;
    this.alert = false;
    this.userSignIn({
      email: this.email,
      password: this.password,
    })
      .then(userCredential => {
        console.log("userCredential", userCredential);
      })
      .catch(err => {
        console.error("userSignMeIn failed", err);
        this.alert = true;
        if (
          err instanceof Object &&
          typeof err.code === "string" &&
          err.code.startsWith("auth/")
        ) {
          this.errorCode = "177df2d7-e3ef-4134-80f1-c4866ad6bdd3";
          this.errorMessage = this.$t(
            "views.signin.invalid_credentials"
          ).toString();
        } else {
          this.errorCode = "32306288-f4fa-40c8-8b18-d63b07728f4c";
        }
        this.loading = false;
      });
  }
  userRegister(evt) {
    console.log("userRegister", evt);
    this.loading = true;
    this.alert = false;
    this.userSignUp({
      password: this.password,
      email: this.email,
      displayName: this.displayName,
      acceptEmail: this.acceptEmail,
      acceptAgb: this.acceptAgb,
    })
      .then(userCredential => {
        console.log("userCredential", userCredential);
      })
      .catch(err => {
        this.alert = true;
        console.error("userRegister failed", err);
        if (
          (err instanceof Object &&
            typeof err.code === "string" &&
            err.code.startsWith("auth/")) ||
          err.code.startsWith("registerUser/")
        ) {
          if (err.code === "auth/user-disabled") {
            this.waitForActivation = true;
          } else {
            this.errorCode = "afbdd07e-041d-40ba-8c82-5540b8f5b76f";
          }
        } else {
          this.errorCode = "c43903ce-522c-456e-a12c-f2d3f96eff2e";
        }
        this.loading = false;
      });
  }
  userSignMeInWithSAML() {
    this.userSignInWithSAML(this.email);
  }

  @Watch("getCurrentUser")
  onWatchGetCurrentUser(value: UserGetters["getCurrentUser"]) {
    if (value !== null && value !== undefined) {
      const redirect = this.$route.query.redirect;
      this.$router.push(
        isString(redirect) && !redirect.startsWith(SIGNIN_PATH) ? redirect : "/"
      );
    }
  }

  @Watch("error")
  onWatchError(value: string) {
    if (value) {
      this.alert = true;
    }
  }
  @Watch("alert")
  onWatchAlert(value: boolean) {
    if (!value) {
      //this.$store.commit("setError", null);
    }
  }

  get buildTime() {
    return process.env.VUE_APP_BUILD_TIMESTAMP || "no build time info";
  }

  runTest() {
    console.log("runTest:call", testCall);

    testCall()
      .then(val => {
        console.log("runTest:done", val);
      })
      .catch(err => {
        console.error("runTest:failed", err);
      });
  }
}
