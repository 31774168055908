import { mapValues } from "lodash";
import { CollectionNames } from "@auditcloud/shared/lib/types/common";
import { ActionTypeContext } from "@/utils/VuexHelper";
import {
  CollectionObserverConfig,
  OrderByData,
  QueryData,
} from "@/utils/firestore";
import {
  createPagingCollectionObserver,
  INITIAL_STATE,
} from "@/utils/firestore/PagingCollectionObserver";
import { RootState } from "../../types";
import {
  AuditsQueryConfig,
  DashboardState,
  DASHBOARD_PAGE_SIZE,
  DateRange,
  MeasuresQueryConfig,
} from "./types";
import { mutationNames as mn } from "./mutations";
import { Getters } from ".";
import { fieldPath } from "@auditcloud/shared/lib/utils/firestorePathHelper";
import {
  VERSIONED_DOCUMENT_FIELDNAME,
  REV_KEY,
} from "@auditcloud/shared/lib/types/VersionedDocument";
import { extractCurrentUserRef, extractCurrentUserRoles } from "../user/utils";
import { createAccessFilter } from "@/utils/firestore/queryFilters";
import { AuditStatusId } from "@auditcloud/shared/lib/constants";
import { MAGIC_MEASURE_COMPLETED_STATUS_ID } from "@auditcloud/shared/lib/workflow/configs/constants";

type Context = ActionTypeContext<DashboardState, RootState, Getters>;

function baseAuditsObserverConfig(
  queryConfig: AuditsQueryConfig,
  userId: string,
  roles: string[]
): CollectionObserverConfig {
  const filter = [
    new QueryData(
      fieldPath(REV_KEY, VERSIONED_DOCUMENT_FIELDNAME.DELETED),
      "==",
      false
    ),
  ];

  if (queryConfig.roleFilter === "leadauditor") {
    filter.push(new QueryData("leadauditorId", "==", userId));
    filter.push(createAccessFilter({ userId, roles }));
  } else if (queryConfig.roleFilter === "member") {
    filter.push(createAccessFilter({ userId }));
  }

  return {
    filter,
  };
}

function listAuditsObserverConfig(
  queryConfig: AuditsQueryConfig,
  userId: string,
  roles: string[]
): CollectionObserverConfig {
  const baseConfig = baseAuditsObserverConfig(queryConfig, userId, roles);
  const filter = [...(baseConfig.filter ?? [])];

  // Cannot exclude/include multiple status values due to firestore limitations.
  // At least exclude completed audits (if desired). Rest must be done locally.
  if (
    queryConfig.statusFilter &&
    !queryConfig.statusFilter.includes(AuditStatusId.Completed)
  ) {
    filter.push(
      new QueryData("workflow.status", "!=", AuditStatusId.Completed)
    );
  }
  return {
    ...baseConfig,
    filter,
  };
}

function calendarAuditsObserverConfig(
  queryConfig: AuditsQueryConfig,
  dateRange: DateRange,
  userId: string,
  roles: string[]
): CollectionObserverConfig {
  const baseConfig = baseAuditsObserverConfig(queryConfig, userId, roles);
  return {
    ...baseConfig,
    filter: [
      ...(baseConfig.filter ?? []),
      new QueryData("auditing_date_start", ">=", dateRange.start),
      new QueryData("auditing_date_start", "<=", dateRange.end),
    ],
  };
}

function measuresObserverConfig(
  queryConfig: MeasuresQueryConfig,
  userId: string,
  roles: string[]
): CollectionObserverConfig {
  const filter: QueryData[] = [
    new QueryData(
      fieldPath(REV_KEY, VERSIONED_DOCUMENT_FIELDNAME.DELETED),
      "==",
      false
    ),
  ];

  // Cannot exclude/include multiple status values due to firestore limitations.
  // At least exclude completed audits (if desired). Rest must be done locally.
  if (
    queryConfig.statusFilter &&
    !queryConfig.statusFilter.includes(MAGIC_MEASURE_COMPLETED_STATUS_ID)
  ) {
    filter.push(
      new QueryData(
        "workflow.statusId",
        "!=",
        MAGIC_MEASURE_COMPLETED_STATUS_ID
      )
    );
  }

  if (queryConfig.roleFilter === "creator") {
    filter.push(createAccessFilter({ userId, roles, accessType: "Read" }));
    filter.push(
      new QueryData(
        `docVersion.${VERSIONED_DOCUMENT_FIELDNAME.CREATED_BY}`,
        "==",
        userId
      )
    );
  } else if (queryConfig.roleFilter === "assignee") {
    filter.push(createAccessFilter({ userId, roles, accessType: "Read" }));
    filter.push(new QueryData("users.assignedTo", "==", userId));
  } else if (queryConfig.roleFilter === "member") {
    filter.push(createAccessFilter({ userId, accessType: "Read" }));
  }

  return {
    filter,
  };
}

const actions = {
  async updateAuditsQueryConfig(
    context: Context,
    payload: Partial<AuditsQueryConfig>
  ) {
    context.commit(mn.UPDATE_AUDITS_QUERY_CONFIG, payload);
    if (context.state.audits.isLive) {
      context.dispatch(n.clearAudits);
      context.dispatch(n.fetchAudits);
    }
    if (context.state.calendarAudits.isLive) {
      context.dispatch(n.clearCalendarAudits);
      context.dispatch(n.fetchCalendarAudits);
    }
  },
  async fetchAudits(context: Context) {
    const userId = extractCurrentUserRef(context.rootGetters)?.id;
    const roles = extractCurrentUserRoles(context.rootGetters);
    if (userId) {
      const observer = createPagingCollectionObserver(
        CollectionNames.AUDITS,
        listAuditsObserverConfig(
          context.getters.getAuditsQueryConfig,
          userId,
          roles
        ),
        auditsState => {
          context.commit(mn.UPDATE_AUDITS_STATE, auditsState);
        }
      );
      context.commit(mn.UPDATE_AUDITS_OBSERVER, observer);
    } else {
      console.warn("Expect valid user id");
      context.commit(mn.UPDATE_AUDITS_OBSERVER, null);
    }
  },
  async fetchMoreAudits(context: Context) {
    context.commit(
      mn.UPDATE_AUDITS_LIMIT,
      context.state.auditsLimit + DASHBOARD_PAGE_SIZE
    );
  },
  async clearAudits(context: Context) {
    context.commit(mn.UPDATE_AUDITS_OBSERVER, null);
    context.commit(mn.UPDATE_AUDITS_LIMIT, DASHBOARD_PAGE_SIZE);
    context.commit(mn.UPDATE_AUDITS_STATE, INITIAL_STATE);
  },

  async updateCalendarAuditsRange(context: Context, payload: DateRange) {
    context.commit(mn.UPDATE_CALENDAR_AUDITS_DATE_RANGE, payload);
    context.dispatch(n.clearCalendarAudits);
    context.dispatch(n.fetchCalendarAudits);
  },
  async fetchCalendarAudits(context: Context) {
    const userId = extractCurrentUserRef(context.rootGetters)?.id;
    const roles = extractCurrentUserRoles(context.rootGetters);
    if (userId) {
      const observer = createPagingCollectionObserver(
        CollectionNames.AUDITS,
        calendarAuditsObserverConfig(
          context.getters.getAuditsQueryConfig,
          context.state.calendarAuditsDateRange,
          userId,
          roles
        ),
        auditsState => {
          context.commit(mn.UPDATE_CALENDAR_AUDITS_STATE, auditsState);
        }
      );
      context.commit(mn.UPDATE_CALENDAR_AUDITS_OBSERVER, observer);
    } else {
      console.warn("Expect valid user id");
      context.commit(mn.UPDATE_CALENDAR_AUDITS_OBSERVER, null);
    }
  },
  async clearCalendarAudits(context: Context) {
    context.commit(mn.UPDATE_CALENDAR_AUDITS_OBSERVER, null);
    context.commit(mn.UPDATE_CALENDAR_AUDITS_STATE, INITIAL_STATE);
  },

  async updateMeasuresQueryConfig(
    context: Context,
    payload: Partial<MeasuresQueryConfig>
  ) {
    context.commit(mn.UPDATE_MEASURES_QUERY_CONFIG, payload);
    if (context.state.measures.isLive) {
      context.dispatch(n.clearMeasures);
      context.dispatch(n.fetchMeasures);
    }
  },
  async fetchMeasures(context: Context) {
    const roles = extractCurrentUserRoles(context.rootGetters);
    const userId = extractCurrentUserRef(context.rootGetters)?.id;
    if (userId) {
      const observer = createPagingCollectionObserver(
        CollectionNames.MEASURE_PROCESSES,
        measuresObserverConfig(
          context.getters.getMeasuresQueryConfig,
          userId,
          roles
        ),
        measuresState => {
          context.commit(mn.UPDATE_MEASURES_STATE, measuresState);
        }
      );
      context.commit(mn.UPDATE_MEASURES_OBSERVER, observer);
    } else {
      console.warn("Expect valid user id");
      context.commit(mn.UPDATE_MEASURES_OBSERVER, null);
    }
  },

  async fetchMoreMeasures(context: Context) {
    context.commit(
      mn.UPDATE_MEASURES_LIMIT,
      context.state.measuresLimit + DASHBOARD_PAGE_SIZE
    );
  },
  async clearMeasures(context: Context) {
    context.commit(mn.UPDATE_MEASURES_OBSERVER, null);
    context.commit(mn.UPDATE_MEASURES_LIMIT, DASHBOARD_PAGE_SIZE);
    context.commit(mn.UPDATE_MEASURES_STATE, INITIAL_STATE);
  },
};

const n = mapValues(actions, (_, key) => key);

export { n as actionNames, actions };
