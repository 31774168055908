



















































































































































import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";

@Component({})
export default class AWorkflowCrumb extends Vue {
  @Prop({
    default: false,
    type: Boolean,
  })
  readonly current!: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly past!: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly selected!: boolean;

  //missingDefault
  @Prop({
    required: true,
    type: String,
  })
  readonly text!: string;

  get translatedText() {
    return this.$t(this.text);
  }

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly compact!: boolean;
}
