

















































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

import VJsoneditor from "v-jsoneditor";
import { cloneDeep, Dictionary, isEqual } from "lodash";

import { api } from "@/store/modules/auditClasses";
import { api as appApi } from "@/store/modules/app";
import { api as configApi } from "@/store/modules/configuration";

import { api as schemasApi } from "@/store/modules/schemas";

import { TodoAny } from "@auditcloud/shared/lib/utils/type-guards";
import { AuditClassesListResult } from "../../store/modules/auditClasses/types";
import {
  AuditClass,
  MappedAuditClasses,
} from "@auditcloud/shared/lib/types/AuditClass";
import { ROUTE_NAMES } from "../../routenames";
import { CategoryLevelOptions } from "@auditcloud/shared/lib/types/AuditItemCategory";
import { ReportSchemaE } from "@auditcloud/shared/lib/constants";
import { WorkflowActionIds } from "@auditcloud/shared/lib/types/WorkflowActions";
import AuditClassConfigSchema from "@auditcloud/shared/lib/schemas/types/AuditClassConfig.schema.json";
import {
  AuditAccessControlConfig,
  AuditClassConfig,
  MeasureAccessControlConfig,
} from "@auditcloud/shared/lib/schemas";
import AMeasurePolicyEditor from "../controls/AMeasurePolicyEditor.vue";
import { MeasureType } from "@auditcloud/shared/lib/types/ItemTypes";
import { FindingTypeConfig } from "@auditcloud/shared/lib/types/Configuration/Configuration";
import { FindingTypeConfigMap } from "@auditcloud/shared/lib/types/Configuration/defaults";

const pattern = /^[a-z][A-Za-z0-9-]+$/;
@Component({
  components: { VJsoneditor, AMeasurePolicyEditor },
})
export default class AAuditClassEditor extends Vue {
  editedData: null | AuditClassConfig = null;
  loading: boolean = false;
  valid: boolean = true;
  createMode: boolean = false;
  newAuditClassId: string = "";

  rules = [
    (val: string) => {
      return (
        pattern.test(val) ||
        "Enter a valid id (min Lenght: 2, starts with a small letter followed by A-Z a-z 0-9 and -)"
      );
    },
  ];
  onValidationErrors(errors: any[]) {
    console.log("onValidationErrors", errors);
    this.valid = errors.length === 0;
  }
  get options() {
    return {
      onValidationError: this.onValidationErrors,
      templates: [
        /*
        {
          text: "AutoId",
          title: "Insert a AutoId",
          className: "jsoneditor-type-object",
          field: "id",
          value: { "generate-autoId": true }
        },
        {
          text: "List Entry",
          title: "Insert a Entry",
          value: {
            id: "",
            name: "",
            disabled: false
          }
        },
        {
          text: "Tree Entry",
          title: "Insert a Tree",
          value: {
            id: "",
            name: "",
            disabled: false,
            children: []
          }
        } */
      ],
      schema: AuditClassConfigSchema,
      schemaRefs: this.getVfjsCommonSchemas,
      mode: "form",
      modes: ["tree", "form", "code", "text"],
    };
  }

  @Prop({ type: String, required: true })
  segmentName!: string;

  @Prop({ type: String, default: null })
  auditClassId!: string | null;

  @Getter(api.getters.list, { namespace: api.namespace })
  auditClassList!: AuditClassesListResult[];

  @Getter(schemasApi.getters.getVfjsCommonSchemas, {
    namespace: schemasApi.namespace,
  })
  getVfjsCommonSchemas!: TodoAny;

  @Getter(api.getters.mappedAuditClasses, { namespace: api.namespace })
  mappedAuditClasses!: MappedAuditClasses;

  @Watch("auditClassId", {
    immediate: true,
  })
  onAuditClassIdChange(val: string | null, oldVal: string | null) {
    console.log("onAuditClassIdChange", val, oldVal);
    this.loading = false;
    this.createMode = false;
    this.valid = true;
    this.newAuditClassId = "";
    this.editedData = this.storedData;
  }

  get storedData() {
    if (
      this.auditClassId !== null &&
      this.auditClassId in this.mappedAuditClasses
    ) {
      return cloneDeep(this.mappedAuditClasses[this.auditClassId]);
    } else {
      return null;
    }
  }
  get isDirty() {
    return !isEqual(this.storedData, this.editedData);
  }
  get isIdValid() {
    return this.createMode === false || pattern.test(this.newAuditClassId);
  }

  @Action(api.actions.updateAuditClass, { namespace: api.namespace })
  updateAuditClass!: (payload: {
    auditClassId: string;
    auditClassData: Dictionary<any>;
  }) => Promise<any>;

  @Action(appApi.actions.setStatus, { namespace: appApi.namespace })
  setStatus!: (payload: string) => Promise<any>;

  async importJsonConf() {
    console.log(
      "importJsonConf",
      this.segmentName,
      this.editedData,
      this.valid,
      this.isDirty
    );
    if (
      this.valid &&
      this.isDirty &&
      this.editedData !== null &&
      (this.auditClassId !== null || pattern.test(this.newAuditClassId.trim()))
    ) {
      this.loading = true;
      await this.setStatus("loading");
      try {
        console.log("Start Save");
        const payloadBuilder = (
          auditClassId: string,
          auditClassData: Dictionary<any>
        ) => ({ auditClassId, auditClassData });
        const payload = payloadBuilder(
          this.createMode === false && this.auditClassId !== null
            ? this.auditClassId
            : this.newAuditClassId.trim(),
          this.editedData
        );

        console.log("Importing", payload);

        await this.updateAuditClass(payload);
        if (this.createMode) {
          this.onAuditClassSelected(this.newAuditClassId);
        }

        await this.setStatus("success");
      } catch (error) {
        console.log("Json Parse failed:", error);
        await this.setStatus("error");
      }
      this.loading = false;
    }
  }
  onError(err: TodoAny) {
    console.error("AConfigEditor:onError", err);
  }
  onInput(data: null | AuditClass) {
    this.editedData = data;
  }

  onAuditClassSelected(auditClassId: string) {
    this.$router.push({
      name: ROUTE_NAMES.CONFIGURATION_AUDITCLASS,
      params: {
        segmentName: this.segmentName,
        auditClassId,
      },
    });
  }
  setCreateMode() {
    this.createMode = true;
    this.editedData = {
      name: "Name der Auditklasse",
      accessAuditMetadataRead: cloneDeep(
        AuditClassConfigSchema.properties.accessAuditMetadataRead
          .default as AuditAccessControlConfig
      ),
      accessAuditPrivateRead: cloneDeep(
        AuditClassConfigSchema.properties.accessAuditPrivateRead
          .default as AuditAccessControlConfig
      ),
      accessMeasureRead: cloneDeep(
        AuditClassConfigSchema.properties.accessMeasureRead
          .default as MeasureAccessControlConfig
      ),
      actionListExportConfig: {
        contentListSheetName: "Actionplan",
        reportSheetName: null,
        templateName: {
          en: "app/templates/actionplan-template-v05.xlsx",
          de: "app/templates/actionplan-template-v05-en.xlsx",
        },
      },
      measureWorkflow: "measure-workflow-basic",
      categorySetId: "default",
      allowFreeFindings: true,
      actionplanView: "list",
      dimensionSource: null,
      useUndimensiondFindingsInGenericDimension: false,
      actionsPerState: {
        actionplan: [],
        execution: [
          WorkflowActionIds.CHECKLIST,
          WorkflowActionIds.SHOW_INTERMEDIATE_RESULT,
        ],
        planning: [],
        preparation: [],
        reporting: [
          WorkflowActionIds.REPORT,
          WorkflowActionIds.PREPARE_REPORT,
          WorkflowActionIds.ACTIONPLAN,
        ],
        wrapup: [
          WorkflowActionIds.PREPARE_REPORT,
          WorkflowActionIds.SHOW_INTERMEDIATE_RESULT,
        ],
        completed: [WorkflowActionIds.REPORT, WorkflowActionIds.ACTIONPLAN],
      },
      useCategoryLevel: CategoryLevelOptions.Root,
      reportSchema: ReportSchemaE.Compliance,
      reportPages: [
        {
          name: "report-cover-page",
          isIncluded: true,
        },
        {
          name: "report-intro-page",
          isIncluded: true,
        },
        {
          name: "radar-chart-page",
          isIncluded: true,
        },
        {
          name: "findings-table-page",
          isIncluded: true,
        },
      ],
      scoreCalcRulePerYear: {},
      preselectTagsInPreparation: true,
      restrictPreparationToCategories: false,
      displayAuditProgress: "proportion",
      offset: {
        review: 14,
        final: 90,
      },
      mailNotificationTriggers: [],
      auditTransitionActions: [],
      vdaConfig: AuditClassConfigSchema.properties.vdaConfig.default,
      preventRefUserAssignees:
        AuditClassConfigSchema.properties.preventRefUserAssignees.default,
    };
  }

  @Getter(configApi.getters.getMeasureTypes, {
    namespace: configApi.namespace,
  })
  measureTypes!: MeasureType[];

  @Getter(configApi.getters.getFindingTypesMappedByAuditClass, {
    namespace: configApi.namespace,
  })
  findingTypeConfigMap!: FindingTypeConfigMap;

  get findingTypeConfig(): FindingTypeConfig | null {
    const auditClass = this.auditClassId;
    if (auditClass) {
      return this.findingTypeConfigMap[auditClass] ?? null;
    } else {
      return null;
    }
  }
}
