import { isString } from "lodash";
import firebase from "firebase/compat/app";
import { typeIsObjectWithProperty } from "@auditcloud/shared/lib/utils/type-guards";

export function typeIsFirebaseError(
  val: unknown
): val is firebase.FirebaseError {
  return (
    typeIsObjectWithProperty(val, "code") &&
    isString(val.code) &&
    typeIsObjectWithProperty(val, "message") &&
    isString(val.message) &&
    typeIsObjectWithProperty(val, "name") &&
    isString(val.name)
  );
}
