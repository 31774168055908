













































































































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { AuditItemWithId } from "@auditcloud/shared/lib/utils/audit/types";
import AChipsDialog from "@/components/dialogs/AChipsDialog.vue";
import { Action, Getter } from "vuex-class";
import { api as auditApi } from "../../store/modules/audit";
import FormsControlMixin from "@/components/mixins/FormsControlMixin.vue";
import { difference, flatten, intersection, isEmpty, uniq } from "lodash";
@Component({
  mixins: [FormsControlMixin],
  components: {
    AChipsDialog,
  },
})
export default class AAuditItemTagsDialog extends Mixins(FormsControlMixin) {
  created() {
    this.mixinInitFormData({ add: [], remove: [] });
  }

  @Prop({
    type: Array,
    required: true,
  })
  auditItemIds!: Array<AuditItemWithId["id"]>;

  comboboxValue: string | null = "";
  searchInput: string | null = null;
  get trimmedSearchInput(): string {
    return this.searchInput?.trim() ?? "";
  }

  get showAppendItem() {
    const includesSearchItem = !!this.comboboxItems.find(
      item => item === this.trimmedSearchInput
    );
    const hasSomeMatches = this.comboboxItems
      .map(item => item.toLowerCase())
      .some(item => item.includes(this.trimmedSearchInput.toLowerCase()));
    return !!this.trimmedSearchInput && !includesSearchItem && hasSomeMatches;
  }

  get formControlEditValues(): { add: string[]; remove: string[] } {
    return this.editValues as { add: string[]; remove: string[] };
  }

  add(tag: string) {
    const trimmedTag = tag.trim();
    if (
      this.formControlEditValues.add
        .map(val => val.toLowerCase())
        .includes(trimmedTag.toLowerCase()) ||
      this.usedEverywhere
        .map(val => val.toLowerCase())
        .includes(trimmedTag.toLowerCase())
    ) {
      alert(
        this.$t("components.dialogs.audit_item_tag_dialog.tag_already_exists", {
          tag: trimmedTag,
        })
      );
      return;
    } else if (trimmedTag.length) {
      this.formControlEditValues.add.push(trimmedTag);
    }
    this.$nextTick(() => {
      this.searchInput = "";
      this.comboboxValue = null;
    });
  }

  remove(tag: string) {
    if (this.formControlEditValues.add.includes(tag)) {
      this.formControlEditValues.add = this.formControlEditValues.add.filter(
        v => v !== tag
      );
    } else {
      this.formControlEditValues.remove.push(tag);
    }
  }

  save() {
    this.$emit("save", this.formControlEditValues);
  }

  close() {
    if (this.isDirty) {
      if (window.confirm(this.$ft("common.confirms.unsaved_changes"))) {
        this.$emit("close");
      }
    } else {
      this.$emit("close");
    }
  }
  @Getter(auditApi.getters.getMappedTags, {
    namespace: auditApi.namespace,
  })
  mappedTags!: Map<AuditItemWithId["id"], string[]>;

  @Prop({
    type: Boolean,
    required: true,
  })
  isOpen!: boolean;

  isSaving: boolean = false;

  get quesitonTagsGrouped(): string[][] {
    return this.auditItemIds.map(
      id => this.mappedTags.get(id) ?? ["MISSING_ID_EXCEPTION"]
    );
  }

  get allTagsInAudit() {
    return uniq(flatten(Array.from(this.mappedTags.values())));
  }

  get comboboxItems() {
    return this.allTagsInAudit.filter(
      tag =>
        ![...this.usedEverywhere, ...this.formControlEditValues.add].includes(
          tag
        ) || this.formControlEditValues.remove.includes(tag)
    );
  }

  get allQuestionTags(): string[] {
    return uniq(flatten(this.quesitonTagsGrouped));
  }

  get usedEverywhere(): string[] {
    return this.allQuestionTags.filter(tag =>
      this.quesitonTagsGrouped.every(groupedTags => groupedTags.includes(tag))
    );
  }

  get editedUsedEverywhere(): string[] {
    const editedUsedEverywhere = [...this.usedEverywhere];
    this.formControlEditValues.remove.forEach(removedTag => {
      const idx = editedUsedEverywhere.findIndex(tag => tag === removedTag);
      if (idx > -1) {
        editedUsedEverywhere.splice(idx, 1);
      }
    });
    this.formControlEditValues.add.forEach(addedTag =>
      editedUsedEverywhere.push(addedTag)
    );
    return editedUsedEverywhere;
  }

  get usedPartly(): string[] {
    return this.allQuestionTags.filter(
      tag =>
        [...this.usedEverywhere, ...this.formControlEditValues.add].indexOf(
          tag
        ) < 0
    );
  }

  get editedUsedPartly(): string[] {
    const editedUsedPartly = [...this.usedPartly];
    this.formControlEditValues.remove.forEach(removedTag => {
      const idx = editedUsedPartly.findIndex(tag => tag === removedTag);
      if (idx > -1) {
        editedUsedPartly.splice(idx, 1);
      }
    });
    return editedUsedPartly;
  }

  @Action(auditApi.actions.updateTags, {
    namespace: auditApi.namespace,
  })
  updateTags!: (payload: {
    auditItemIds: Array<AuditItemWithId["id"]>;
    tagsToAdd: string[];
    tagsToRemove: string[];
  }) => Promise<void>;

  get addedAndRemovedTags() {
    return intersection(
      this.formControlEditValues.add,
      this.formControlEditValues.remove
    );
  }

  get tagsToAdd() {
    return this.formControlEditValues.add;
  }

  get tagsToRemove() {
    return difference(
      this.formControlEditValues.remove,
      this.addedAndRemovedTags
    );
  }

  async editTags() {
    if (this.trimmedSearchInput !== "") {
      if (
        window.confirm(
          `The text field contains value: "${this.trimmedSearchInput}".\nWant to add this tag also?`
        )
      ) {
        this.add(this.trimmedSearchInput);
      }
    }

    this.isSaving = true;
    const auditItemIds = this.auditItemIds;

    if (this.tagsToAdd.length > 0 || this.tagsToRemove.length > 0) {
      await this.updateTags({
        auditItemIds,
        tagsToAdd: this.tagsToAdd,
        tagsToRemove: this.tagsToRemove,
      });
    }

    this.isSaving = false;
    this.$emit("close");
  }
}
