import { mapValues, sortBy } from "lodash";
import { RootState, RootGetters } from "@/store/types";
import { AuditsQueryConfig, MeasuresQueryConfig, State } from "./types";
import { Getters } from ".";
import { getterNs } from "@/utils/VuexHelper";

type Getter<R> = (
  state: State,
  getters: Getters,
  rootState: RootState,
  rootGetters: RootGetters
) => R;

import { api as configApi, LimitCountType, StatusType } from "../configuration";

import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";
import {
  calcAllAuditRoles,
  calcAuditPermissions,
} from "@auditcloud/shared/lib/utils/aclHelpers";
import { AuditPermissionInfo } from "../audits/types";
import { WatchedDocument } from "@/utils/firestore";
import { AuditMetadataClient } from "@/types/Audit";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import { extractCurrentUserRef, extractCurrentUserRoles } from "../user/utils";
import { AuditMetadataDoc } from "@auditcloud/shared/lib/schemas";

const GET_LIMIT_COUNTS = getterNs(configApi, configApi.getters.getLimitCounts);
const GET_VISIBLE_STATUS = getterNs(
  configApi,
  configApi.getters.getVisibleStatus
);

type PermissionsMapper = (doc: WatchedDocument<unknown>) => AuditPermissionInfo;
function withPermissions(
  user: null | IUserRef,
  roles: string[]
): PermissionsMapper {
  return (doc: WatchedDocument<unknown>) => {
    const auditDoc = doc as WatchedDocument<AuditMetadataClient>;
    const auditRoles = calcAllAuditRoles(
      auditDoc.data,
      user?.id ?? null,
      roles
    );
    const permissions = calcAuditPermissions(
      auditDoc.data,
      user?.id ?? null,
      roles
    );
    return {
      auditDoc,
      auditRoles,
      permissions,
    };
  };
}

const getAuditsQueryConfig: Getter<AuditsQueryConfig> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const limitCounts = rootGetters[GET_LIMIT_COUNTS] as LimitCountType;
  const getVisibleStatus = rootGetters[GET_VISIBLE_STATUS] as StatusType;

  return {
    roleFilter: "member",
    statusFilter: getVisibleStatus.auditStatus,
    limit: limitCounts.auditLimit,
    ...state.auditsQueryConfigChanges,
  };
};

const getIsLoadingAudits: Getter<boolean> = state => {
  return state.audits.isLoading;
};

const getAuditsWithPermissions: Getter<AuditPermissionInfo[]> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const getVisibleStatus = rootGetters[GET_VISIBLE_STATUS] as StatusType;
  const statusFilter = getVisibleStatus.auditStatus;

  const user = extractCurrentUserRef(rootGetters);
  const roles = extractCurrentUserRoles(rootGetters);

  // additional sort/filter to work around firestore query limitations:
  const result = (
    state.audits.documents as WatchedDocument<AuditMetadataDoc>[]
  ).filter(({ data }) => statusFilter?.includes(data.workflow?.status) ?? true);
  const sortedResult = sortBy(result, "auditing_date_start");
  return sortedResult
    .slice(0, state.auditsLimit)
    .map(withPermissions(user, roles));
};

const getHasMoreAudits: Getter<boolean> = state => {
  return state.audits.documents.length > state.auditsLimit;
};

const getIsLoadingCalendarAudits: Getter<boolean> = state => {
  return state.calendarAudits.isLoading;
};

const getCalendarAuditsWithPermissions: Getter<AuditPermissionInfo[]> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const getVisibleStatus = rootGetters[GET_VISIBLE_STATUS] as StatusType;
  const statusFilter = getVisibleStatus.auditStatus;
  const user = extractCurrentUserRef(rootGetters);
  const roles = extractCurrentUserRoles(rootGetters);
  return (state.calendarAudits.documents as WatchedDocument<AuditMetadataDoc>[])
    .filter(({ data }) => statusFilter?.includes(data.workflow?.status) ?? true)
    .map(withPermissions(user, roles));
};

const getMeasuresQueryConfig: Getter<MeasuresQueryConfig> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const limitCounts = rootGetters[GET_LIMIT_COUNTS] as LimitCountType;
  const getVisibleStatus = rootGetters[GET_VISIBLE_STATUS] as StatusType;
  return {
    roleFilter: "assignee",
    statusFilter: getVisibleStatus.measureStatus,
    showCompletedMeasures: true,
    limit: limitCounts.measureLimit,
    ...state.measuresQueryConfigChanges,
  };
};

const getIsLoadingMeasures: Getter<boolean> = state => {
  return state.measures.isLoading;
};

const getMeasures: Getter<MeasureProcessDocument[]> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const getVisibleStatus = rootGetters[GET_VISIBLE_STATUS] as StatusType;
  const statusFilter = getVisibleStatus.measureStatus;

  // additional sort/filter to work around firestore query limitations:
  const result = (
    state.measures.documents as WatchedDocument<MeasureProcessDocument>[]
  )
    .filter(
      ({ data }) =>
        data.workflow?.statusId == null ||
        (statusFilter?.includes(data.workflow?.statusId) ?? true)
    )
    .map(doc => doc.data);

  const sortedResult = sortBy(result, "dueDate");
  return sortedResult.slice(0, state.measuresLimit);
};

const getHasMoreMeasures: Getter<boolean> = state => {
  return state.measures.documents.length > state.measuresLimit;
};

const getters = {
  getAuditsQueryConfig,
  getIsLoadingAudits,
  getAuditsWithPermissions,
  getHasMoreAudits,
  getIsLoadingCalendarAudits,
  getCalendarAuditsWithPermissions,
  getMeasuresQueryConfig,
  getIsLoadingMeasures,
  getMeasures,
  getHasMoreMeasures,
};

const n = mapValues(getters, (_, key) => key);

export { n as getterNames, getters };
