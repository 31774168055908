




























import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { State, Action, Getter, Mutation } from "vuex-class";
import {
  TodoMap,
  TodoArray,
  TodoAny,
  TodoActionSignature,
  TodoMutationSignature,
} from "@auditcloud/shared/lib/utils/type-guards";

import { mapGetters } from "vuex";
import { api } from "@/store/modules/standards";
import { AuditStandard } from "../types/Standard";

@Component({})
export default class Standard extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  standardId!: string;

  @Getter(api.getters.getStandard, { namespace: api.namespace })
  getStandard!: (standardId: string) => AuditStandard | null;

  get currentStandard() {
    console.log(this.standardId);
    return this.getStandard(this.standardId);
  }
}
