










































































// AAuditItemListManipulationControl
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Action, Getter, namespace } from "vuex-class";
import ASquareIconBtn from "@/components/controls/ASquareIconBtn.vue";

import { api } from "@/store/modules/audit";
import {
  api as userApi,
  Getters as UserGetters,
  Actions as UserActions,
} from "@/store/modules/user";
import { TranslateableText } from "@auditcloud/shared/lib/schemas";
import { typeIsMultilingualText } from "@auditcloud/shared/lib/types/common";

const auditModul = namespace(api.namespace);

@Component({
  components: { ASquareIconBtn },
  name: "AContentLanguageSwitch",
  model: {
    prop: "view",
    event: "change",
  },
})
export default class AContentLanguageSwitch extends Vue {
  @Prop({ default: null, type: [Object, String] })
  content!: null | TranslateableText;

  @Prop({
    default: "",
    type: String,
  })
  buttonClasses!: string;

  @auditModul.Getter(api.getters.getManipulatorStatus)
  manipulatorStatus!: {
    filters: number;
    sortings: number;
    grouping: boolean;
  };

  @auditModul.Getter(api.getters.getContentLanguages)
  contentLanguages!: string[];

  @Getter(userApi.getters.getContentLanguage, {
    namespace: userApi.namespace,
  })
  getContentLanguage!: UserGetters["getContentLanguage"];

  @Action(userApi.actions.storeContentLanguage, {
    namespace: userApi.namespace,
  })
  storeContentLanguage!: UserActions["storeContentLanguage"];

  // TODO: Wir brauchen einen Getter der aktiven Sprachen in der AuditItem Liste
  get currentContentLanguages(): any[] {
    const languageOptions = typeIsMultilingualText(this.content)
      ? Object.keys(this.content)
      : this.contentLanguages;

    let currentLanguage = this.getContentLanguage;

    if (!languageOptions.includes(currentLanguage)) {
      currentLanguage = "de";
    }

    return languageOptions.map(lang => {
      return {
        active: lang === currentLanguage,
        text: this.$t(`common.languages.${lang}`),
        lang,
      };
    });
  }

  get currentContentLanguage() {
    if (this.currentContentLanguages.length > 1) {
      return this.getContentLanguage ?? this.ContentTranslation.language;
    }
    return this.ContentTranslation.language;
  }

  setContentLanguage(lang: string) {
    // this.ContentTranslation.setLanguage(lang);
    this.storeContentLanguage(lang);
  }

  @Watch("getContentLanguage", { immediate: true })
  onContentLanguageChange(newVal) {
    if (this.currentContentLanguages.length > 1) {
      this.ContentTranslation.setLanguage(newVal);
    }
  }

  mounted() {
    console.log(
      "AContentLanguageSwitch",
      this.ContentTranslation.language,
      this.contentLanguages
    );
  }
}
