import { MutationTree } from "vuex";
import { Result } from "neverthrow";

import {
  ApiFilterItem,
  UserManagementState,
  ApiOrderByItem,
  ApiError,
  ApiListResponse,
  ApiFindResponse,
} from "./types";

const PREPARE_RELOAD = "PREPARE_RELOAD";
const PREPARE_LOAD_MORE = "PREPARE_FETCH_MORE";
const SET_LIST_RESPONSE = "SET_LIST_RESPONSE";
const APPEND_LIST_RESPONSE = "APPEND_LIST_RESPONSE";
const SET_LOADING_STATE = "SET_LOADING_STATE";
const SET_FIND_RESPONSE = "SET_FIND_RESPONSE";
const UPDATE_WHERE = "UPDATE_FILTER";
const UPDATE_ORDER_BY = "UPDATE_ORDER_BY";
const n = {
  PREPARE_RELOAD,
  PREPARE_LOAD_MORE,
  SET_LIST_RESPONSE,
  APPEND_LIST_RESPONSE,
  SET_LOADING_STATE,
  SET_FIND_RESPONSE,
  UPDATE_WHERE,
  UPDATE_ORDER_BY,
};

const mutations: MutationTree<UserManagementState> = {
  [n.PREPARE_RELOAD](state) {
    state.loading = true;
    state.params.pagination.from = null;
    state.users = [];
  },
  [n.PREPARE_LOAD_MORE](state) {
    state.loading = true;
    state.params.pagination.from = state.nextFrom;
  },
  [n.SET_LIST_RESPONSE](state, payload: Result<ApiListResponse, ApiError>) {
    state.loading = false;
    if (payload.isErr()) {
      state.users = [];
      state.nextFrom = null;
      state.error = payload.error;
    } else {
      state.users = payload.value.users;
      state.nextFrom = payload.value.nextFrom;
      state.error = null;
    }
  },
  [n.APPEND_LIST_RESPONSE](state, payload: Result<ApiListResponse, ApiError>) {
    state.loading = false;
    if (payload.isErr()) {
      state.error = payload.error;
    } else {
      state.users.push(...payload.value.users);
      state.nextFrom = payload.value.nextFrom;
      state.error = null;
    }
  },
  [n.SET_FIND_RESPONSE](state, payload: Result<ApiFindResponse, ApiError>) {
    state.loading = false;
    if (payload.isErr()) {
      state.user = null;
      state.error = payload.error;
    } else {
      state.user = payload.value;
      state.error = null;
    }
  },
  [n.UPDATE_WHERE](state, payload: { where: ApiFilterItem[] }) {
    state.params.where = payload.where;
  },
  [n.UPDATE_ORDER_BY](state, payload: { orderBy: ApiOrderByItem[] }) {
    state.params.orderBy = payload.orderBy;
  },
  [n.SET_LOADING_STATE](state, payload: boolean) {
    state.loading = payload;
  },
};

export { n as mutationNames, mutations };
