





































































import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import {
  VCalendar,
  VCard,
  VCardText,
  VSheet,
  VToolbar,
  VToolbarTitle,
  VBtn,
  VIcon,
} from "vuetify/lib";
import SnippetWorkflow from "@/components/snippets/SnippetWorkflow.vue";
import { AuditPermissionInfo } from "@/store/modules/audits/types";
import { ISO_DATE_FORMAT } from "@auditcloud/shared/lib/utils/formatting/dates";
import moment from "moment";

export interface EventInterface {
  name: string;
  start: string | Date;
  end: string | Date;
  color: string;
  details?: string;
  id: string;
  leadauditor: string | null;
  dates: string[];
  status: string;
  auditEntry: AuditPermissionInfo;
}

export interface VuetifyCalendarRange {
  start: { date: string; weekday: number };
  end: { date: string; weekday: number };
}

export interface CalendarRange {
  start: string;
  end: string;
}

@Component({
  components: {
    VCalendar,
    VCard,
    VSheet,
    VToolbar,
    VToolbarTitle,
    VBtn,
    VIcon,
    SnippetWorkflow,
  },
})
export default class ACalendar extends Vue {
  focus: string = "";
  start: any = null;
  end: any = null;

  @Prop({
    type: Array,
    default: () => [],
  })
  events!: EventInterface[];

  //missingDefault
  @Prop({
    type: String,
  })
  now!: string | undefined;

  get title() {
    const { start, end } = this;
    if (!start || !end) {
      return "";
    }

    const startMonth = this.monthFormatter(start);
    const startYear = start.year;

    return `${startMonth} ${startYear}`;
  }

  get monthFormatter() {
    return (this.$refs.calendar as any).getFormatter({
      timeZone: "UTC",
      month: "long",
    });
  }

  get weekdays() {
    return [1, 2, 3, 4, 5, 6, 0];
  }
  weekdaysBefore(weekday: number) {
    return this.weekdays.indexOf(weekday);
  }
  weekdaysAfter(weekday: number) {
    return 6 - this.weekdaysBefore(weekday);
  }

  mounted() {
    (this.$refs.calendar as any).checkChange();
  }

  setToday() {
    this.focus = this.now || new Date().toISOString().substr(0, 10);
  }
  prev() {
    (this.$refs.calendar as any).prev();
  }
  next() {
    (this.$refs.calendar as any).next();
  }
  updateRange(event: VuetifyCalendarRange) {
    const { start, end } = event;
    this.start = start;
    this.end = end;

    // start/end are for the first and last day of the current month.
    // We want to get the entire visible range, including adjacent months.
    const visibleStartDate = moment(start.date)
      .subtract(this.weekdaysBefore(start.weekday), "days")
      .format(ISO_DATE_FORMAT);
    const visibleEndDate = moment(end.date)
      .add(this.weekdaysAfter(end.weekday), "days")
      .format(ISO_DATE_FORMAT);
    this.$emit("updateRange", { start: visibleStartDate, end: visibleEndDate });
  }
  getEventColor(event: EventInterface) {
    return event.color;
  }
  showEvent({
    nativeEvent,
    event,
  }: {
    nativeEvent: MouseEvent;
    event: EventInterface;
  }) {
    console.log("SHOWEVENT", event);
    this.$emit("selected", event);
    nativeEvent.stopPropagation();
  }
}
