
































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import {
  StatedGetter,
  typeIsStatedGetterOf,
} from "@/store/modules/reporting/types";
import StatGetter from "@/components/layouts/BaseLayouts/StatGetter.vue";

import {
  ConfigurableGetter,
  getterPath,
  typeIsConfigurableGetter,
} from "@auditcloud/shared/lib/types/common";

import { TableData, typeIsTableData } from "@/store/modules/auditResult/types";
import { isFunction, isMap } from "lodash";

@Component({
  components: { StatGetter },
})
export default class AAuditResultTable extends Vue {
  @Prop({
    type: Object,
    required: true,
    validator: typeIsConfigurableGetter,
  })
  dataGetter!: ConfigurableGetter;

  get getterResult(): unknown {
    return this.$store.getters[getterPath(this.dataGetter)];
  }

  get tableData(): StatedGetter<TableData> {
    const getterResult = this.getterResult;
    const id = this.dataGetter.id;
    let result: unknown | null = getterResult;

    console.log("StatedGetter<TableData>", getterResult, id);

    if (typeof id === "string") {
      if (isFunction(getterResult)) {
        result = getterResult(id);
      } else if (isMap(getterResult)) {
        result = getterResult.get(id);
      } else if (getterResult instanceof Object && id in getterResult) {
        result = getterResult[id];
      }
    }

    if (typeIsStatedGetterOf(result, typeIsTableData)) {
      return result;
    } else if (typeIsTableData(result) || result === null) {
      return new StatedGetter(result);
    } else {
      console.error(
        "DataGetter for StatedGetter<TableData> doesn't return valid StatedGetter<TableData>"
      );
      return new StatedGetter<TableData>(null, {
        statusCode: 1,
        message: "Loading data failed",
      });
    }
  }
}
