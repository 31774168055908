import { RootState } from "../../types";
import { MeasureWorkflowsState } from "./types";
import { mutationNames as mn } from "./mutations";
import measureWorkflowConfigs from "@auditcloud/shared/lib/workflow/configs/Measure";
import { MeasureWorkflow } from "@auditcloud/shared/lib/workflow/modules/Measure";
import { keyBy, mapValues } from "lodash";
import { auditsEndpointUrl } from "@/utils/HttpApi";
import axios from "axios";
import { getterNs } from "@/utils/VuexHelper";
import { api as auditApi } from "../audit";
import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";
import { isFeatureEnabled } from "@/plugins/FeatureFlags";
import { AuditClassClient } from "@auditcloud/shared/lib/types/AuditClass";
import { nullable } from "@auditcloud/shared/lib/types/common";
import { Dictionary } from "lodash";
import { ActionTypeContext } from "@/utils/VuexHelper";
import {
  ApiV0MeasuresCreateRequest,
  ApiV0MeasuresCreateResponse,
  MeasuresCreateData,
} from "@auditcloud/shared/lib/schemas";
import {
  calcMeasureDueDate,
  calcMeasureDueDateForMeasureType,
} from "@auditcloud/shared/lib/utils/measure/dueDate";
import { AuditMetadataClient } from "@/types/Audit";
import { MeasureTypeId } from "@auditcloud/shared/lib/constants";

type Context = ActionTypeContext<
  MeasureWorkflowsState,
  RootState,
  Dictionary<unknown>
>;

const actions = {
  async loadMeasureWorkflows(context: Context) {
    const workflows = Object.entries(measureWorkflowConfigs).map(
      ([id, config]) => {
        return new MeasureWorkflow(id, config);
      }
    );

    console.log("MeasureWorkflows", workflows);

    const mappedWorkflows = keyBy(workflows, "id");

    context.commit(mn.SET_WORKFLOWS, mappedWorkflows);
  },
  async executeCreateTransition(
    context: Context,
    payload: {
      auditId: string;
      auditItemId: string;
      findingId: string;
      content: Omit<MeasuresCreateData, "dueDate" | "assignedTo">;
    }
  ) {
    console.log("executeCreateTransition", payload);

    const url = auditsEndpointUrl(
      payload.auditId,
      "findings",
      payload.findingId,
      "measure-processes"
    );

    const responsible = context.rootGetters[
      getterNs(auditApi, auditApi.getters.getMeasureResponsible)
    ] as IUserRef | null;

    const auditClass = context.rootGetters[
      getterNs(auditApi, auditApi.getters.getAuditClass)
    ] as nullable<AuditClassClient>;

    const auditMetadata = context.rootGetters[
      getterNs(auditApi, auditApi.getters.getAuditMetadata)
    ] as nullable<AuditMetadataClient>;

    const dueDate: string | null = isFeatureEnabled("featureMeasureDueDate")
      ? calcMeasureDueDateForMeasureType(
          auditMetadata,
          auditClass,
          payload.content.measureTypeId
        )
      : null;

    const requestData: ApiV0MeasuresCreateRequest = {
      ...payload.content,
      dueDate,
      assignedTo: isFeatureEnabled("featureMeasureAssignToResponsible")
        ? responsible?.id ?? null
        : null,
    };

    const response = await axios.post<ApiV0MeasuresCreateResponse>(
      url,
      requestData,
      {
        responseType: "json",
      }
    );
    return response;
  },
};

const n = mapValues(actions, (_, key) => key);
export { n as actionNames, actions };
