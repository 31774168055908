import {
  AuditProgram,
  AuditProgramCardData,
  AUDIT_WORKFLOW_META_STATUS,
  State,
  OrganizationUnit,
  OrganizationUnitCardData,
} from "./types";
import {
  AuditProgramConfig,
  OrganizationUnitConfig,
} from "@auditcloud/shared/lib/schemas";
import { tt2str, idable } from "@auditcloud/shared/lib/types/common";
import { api as confApi } from "../configuration";
import { api as auditsApi, Getters as AuditsGetters } from "../audits";
import { getterNs } from "@/utils/VuexHelper";
import { Dictionary, fromPairs, isNumber, toPairs } from "lodash";
import i18n, { readFrontendLanguage } from "@/plugins/i18n";
import { AuditMetaStatusChartBuilder } from "./classes/AuditMetaStatusChartBuilder";

import { mapValues } from "lodash";
import { RootState, RootGetters } from "@/store/types";
import { Getters } from ".";
import naturalCompare from "natural-compare";

type Getter<R> = (
  state: State,
  getters: Getters,
  rootState: RootState,
  rootGetters: RootGetters
) => R;

function translator(key: string) {
  return i18n.t(key).toString();
}

const getAuditPrograms: Getter<AuditProgram[]> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const lang = readFrontendLanguage();
  const auditProgramMap = rootGetters[
    getterNs(confApi, confApi.getters.getMappedAuditPrograms)
  ] as Dictionary<AuditProgramConfig>;

  const auditPrograms = toPairs(auditProgramMap).map(([id, auditProgram]) => {
    return {
      id,
      name: tt2str(auditProgram.name, lang),
      responsible: auditProgram.responsible ?? null,
      organizationUnitId: auditProgram.organizationUnitId ?? null,
    };
  });

  auditPrograms.sort((l, r) => {
    return naturalCompare(l.name, r.name);
  });

  return auditPrograms;
};

const getProgramCardData: Getter<AuditProgramCardData[]> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const lang = readFrontendLanguage();

  const audits = rootGetters[
    getterNs(auditsApi, auditsApi.getters.loadedAudits)
  ] as AuditsGetters["loadedAudits"];

  const activeOrganizationUnitId = rootGetters[
    getterNs(auditsApi, auditsApi.getters.getActiveOrganizationUnitId)
  ] as AuditsGetters["getActiveOrganizationUnitId"];

  const auditPrograms = getters.getAuditPrograms;

  const cardData = auditPrograms.map(auditProgram => {
    return new AuditProgramCardData(
      auditProgram,
      new AuditMetaStatusChartBuilder(AUDIT_WORKFLOW_META_STATUS, translator)
    );
  });
  const auditProgramIdIdxMap = fromPairs(
    cardData.map((cd, idx): [string, number] => {
      return [cd.info.id, idx];
    })
  );

  audits.forEach(audit => {
    const auditProgramId = audit.auditProgramId;
    const cardDataIdx = auditProgramIdIdxMap[auditProgramId];
    if (isNumber(cardDataIdx)) {
      cardData[cardDataIdx].updateCount(
        audit.planning_year,
        audit.workflow.status
      );
    } else {
      console.warn(
        "Found unsupported auditProgramId",
        auditProgramId,
        auditPrograms
      );
    }
  });
  return cardData.filter(
    auditProgram =>
      auditProgram.info.organizationUnitId === activeOrganizationUnitId
  );
};

const getOrganizationUnits: Getter<OrganizationUnit[]> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const lang = readFrontendLanguage();
  const organisationUnitMap = rootGetters[
    getterNs(confApi, confApi.getters.getMappedOrganizationUnits)
  ] as Dictionary<OrganizationUnitConfig>;

  const organizationUnits = toPairs(organisationUnitMap).map(
    ([id, organizationUnit]) => {
      return {
        id,
        name: tt2str(organizationUnit.name, lang),
        responsible: organizationUnit.responsible ?? null,
        imageUrl: organizationUnit.imageUrl ?? null,
      };
    }
  );

  organizationUnits.sort((l, r) => {
    return naturalCompare(l.name, r.name);
  });

  return organizationUnits;
};

const getOrganizationUnitCardData: Getter<OrganizationUnitCardData[]> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const activeOrganizationUnitId = rootGetters[
    getterNs(auditsApi, auditsApi.getters.getActiveOrganizationUnitId)
  ] as AuditsGetters["getActiveOrganizationUnitId"];

  if (activeOrganizationUnitId === null) {
    const organizationUnits = getters.getOrganizationUnits;
    const auditPrograms = getters.getAuditPrograms;

    const cardData = organizationUnits.map(organizationUnit => {
      const auditProgramCount = auditPrograms.filter(
        auditProgram => auditProgram.organizationUnitId === organizationUnit.id
      ).length;
      return {
        ...organizationUnit,
        auditProgramCount,
      };
    });
    return cardData;
  } else {
    return [];
  }
};

const getters = {
  getAuditPrograms,
  getProgramCardData,
  getOrganizationUnits,
  getOrganizationUnitCardData,
};

const n = mapValues(getters, (_, key) => key);

export { n as getterNames, getters };
