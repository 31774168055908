

























/* tslint:disable:no-console */

import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import AAuditsWidget from "@/components/widgets/AAuditsWidget.vue";
import AMeasuresWidget from "@/components/widgets/AMeasuresWidget.vue";

import { Action } from "vuex-class";
import { api as appApi } from "@/store/modules/app";
import { TodoArray } from "@auditcloud/shared/lib/utils/type-guards";

@Component({
  components: {
    AAuditsWidget,
    AMeasuresWidget,
  },
})
export default class Dashboard extends Vue {
  @Prop({
    type: String,
    default: null,
  })
  dashboardId!: string;

  @Action(appApi.actions.setMenuPanel, { namespace: appApi.namespace })
  setMenuPanel!: (payload: string) => Promise<boolean>;

  @Action(appApi.actions.setMenuTabs, { namespace: appApi.namespace })
  setMenuTabs!: (
    payload: Array<{ text: string; to: string }>
  ) => Promise<boolean>;

  beforeDestroy() {
    this.setMenuTabs([]);
  }

  mounted() {
    this.setMenuTabs(this.menutabs);
    this.setMenuPanel("dashboard");
  }

  get mobileDisplay(): string {
    console.log("route", this.dashboardId);
    if (this.dashboardId !== null) {
      return this.dashboardId;
    } else {
      return "audits";
    }
  }

  get menutabs(): TodoArray {
    if (this.$vuetify.breakpoint.smAndDown) {
      return [
        {
          text: this.$t("views.dashboard.audits_text"),
          to: "/dashboard/audits",
        },
        {
          text: this.$t("views.dashboard.measures_text"),
          to: "/dashboard/measures",
        },
      ];
    } else {
      return [];
    }
  }

  @Watch("menutabs")
  onMenutabsChanged(newVal: TodoArray) {
    console.log("menutabs", newVal);
    this.setMenuTabs(newVal);
  }
}
