


























































import { Component, Prop, Mixins } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  TodoAny,
  TodoActionSignature,
} from "@auditcloud/shared/lib/utils/type-guards";

import _ from "lodash";
import { api } from "@/store/modules/audit";
import VueFormJsonSchema from "vue-form-json-schema";
import { api as schemasApi, FormSchemaDynamic } from "@/store/modules/schemas";
import {
  dialogRoute,
  DIALOG_NAMES,
  ROUTE_NAMES,
  stripDialog,
} from "../../routenames";
import FormsControlMixin from "@/components/mixins/FormsControlMixin.vue";
import { AuditPermissions } from "@auditcloud/shared/lib/utils/aclHelpers";
import DialogLayout from "../layouts/BaseLayouts/DialogLayout.vue";

@Component({
  components: {
    VueFormJsonSchema,
    DialogLayout,
  },
  mixins: [FormsControlMixin],
})
export default class AuditReportingDialog extends Mixins(FormsControlMixin) {
  @Prop({
    type: String,
    required: true,
  })
  readonly auditId!: string;

  loading: boolean = false;
  formSchemaId: string = "reporting-form";
  vfjsOptions: any = {};
  formValid: boolean = false;

  @Getter(api.getters.getAuditFormData, { namespace: api.namespace })
  getAuditFormData!: TodoAny;

  @Getter(api.getters.getAuditFormSchema, { namespace: api.namespace })
  getAuditFormSchema!: (schemaId: string) => FormSchemaDynamic;

  @Getter(api.getters.getAuditPermissions, { namespace: api.namespace })
  permissions!: AuditPermissions;

  @Getter(schemasApi.getters.getVfjsOptions, {
    namespace: schemasApi.namespace,
  })
  getVfjsOptions!: TodoAny;

  @Getter(schemasApi.getters.getVfjsCommonSchemas, {
    namespace: schemasApi.namespace,
  })
  getVfjsCommonSchemas!: TodoAny;

  get writePermission() {
    return this.permissions.write;
  }
  get formSchema() {
    const res = this.getAuditFormSchema(this.formSchemaId);
    console.log("AuditMedataControl:uiSchema", res);
    return res;
  }
  get uiSchema() {
    return this.formSchema.ui(this.loading || !this.writePermission, false);
  }
  get schema() {
    return this.formSchema.data;
  }

  get dialog(): boolean {
    return this.xDialog;
  }

  set dialog(val: boolean) {
    const auditId = this.auditId;
    const currentRouteName = this.$route.name ?? "error";
    const route = {
      name: stripDialog(currentRouteName),
      params: { auditId },
    };

    this.$router.push(route);
  }
  xDialog = true;

  @Action(api.actions.setAuditFormData, { namespace: api.namespace })
  setAuditFormData!: TodoActionSignature;

  change(e) {
    console.log('"change" event', e);
    this.mixinFormData = e;
  }
  onValidated(isValid) {
    console.log('"validated" event', isValid);
    this.formValid = isValid;
  }
  onChangeState(e) {
    console.log('"change-state" event', e);
  }
  showError(err) {
    console.error("AuditReportingDialog", err);
  }
  close() {
    this.dialog = false;
    document.documentElement.style.overflow = "visible";
  }

  cancelEdit() {
    this.mixinInitFormData(this.getAuditFormData(this.formSchemaId));
    this.dialog = false;
  }

  save(e) {
    if (this.formValid) {
      console.log("save changes", e);
      this.loading = true;

      this.setAuditFormData({
        formSchemaId: this.formSchemaId,
        data: this.mixinFormData,
      })
        .then(res => {
          console.log("setAuditFormData", res);
          this.cancelEdit();
        })
        .catch(err => {
          console.error("setAuditFormData", err);
        })
        .finally(() => {
          this.loading = false;
        });
      document.documentElement.style.overflow = "visible";
    } else {
      this.vfjsOptions = {
        ...this.getVfjsOptions,
        showValidationErrors: true,
      };

      console.log("Form invalid");
    }
  }
  mounted() {
    this.vfjsOptions = this.getVfjsOptions;
    this.mixinInitFormData(this.getAuditFormData(this.formSchemaId));
  }

  openAuditMetadata() {
    const currentRouteName = stripDialog(
      this.$router.currentRoute.name ?? "error"
    );
    this.$router.push({
      name: dialogRoute(currentRouteName, DIALOG_NAMES.AUDIT_INFO_DIALOG),
      params: {
        auditId: this.auditId,
      },
    });
  }
}
