


































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import SignaturePad from "signature_pad";

import { Attachment } from "../types";

@Component({
  components: {},
  name: "ASketchDialog",
})
export default class ASketchDialog extends Vue {
  newComment = "";
  signaturePad: SignaturePad | null = null;

  @Prop({
    type: String,
    required: true,
  })
  icon!: string;

  close() {
    this.$emit("attachments", []);
  }

  resizeCanvas() {
    const canvas = this.theCanvas;
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d")?.scale(ratio, ratio);

    const pad = this.signaturePad;
    if (pad) {
      const points = pad.toData();

      pad.clear();
      this.$nextTick(() => {
        pad.fromData(points);
      });
    }
  }

  mounted() {
    const canvas = this.theCanvas;
    this.signaturePad = new SignaturePad(canvas, {});
    const handleResize = () => {
      this.resizeCanvas();
    };

    window.addEventListener("resize", handleResize);
    this.$nextTick(handleResize);
  }

  get theCanvas() {
    return this.$refs.theCanvas;
  }

  reset() {
    this.signaturePad?.clear();
  }

  sketchDone() {
    this.theCanvas.toBlob(blob => {
      if (blob) {
        const attachment: Attachment = {
          icon: this.icon,
          name: `sketch_${new Date().toISOString().replace(/:/g, "-")}.png`,
          data: blob,
          comment: this.newComment,
        };
        this.$emit("attachments", [attachment]);
      } else {
        this.close();
      }
    });
  }

  $refs!: {
    theCanvas: HTMLCanvasElement;
  };
}
