
























































































































































import { Component, Watch, Mixins } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  TodoAny,
  TodoActionSignature,
} from "@auditcloud/shared/lib/utils/type-guards";

import SnippetChips from "@/components/snippets/Chips.vue";

import FormattingMixin from "@/components/mixins/FormattingMixin.vue";

import PaginationControl from "@auditcloud/components/controls/PaginationControl.vue";
import ListConfigurationMixin from "@/components/mixins/ListConfigurationMixin.vue";

import SnippetStandard from "@/components/snippets/SnippetStandard.vue";
import DownloadButton from "@/components/controls/DownloadButton.vue";

import { v4 as uuidv4 } from "uuid";

import { api } from "@/store/modules/templates";
import { api as appApi } from "@/store/modules/app";
import { ROUTE_NAMES } from "@/routenames";
import {
  TemplatePermissions,
  calcAllTemplateRoles,
  calcTemplatePermissions,
} from "@auditcloud/shared/lib/utils/aclHelpers";
import { supportsRegexLookAheadLookBehind } from "@/utils/FeatureDetection";
import { SystemRoles } from "@auditcloud/shared/lib/constants/roles";

@Component({
  components: {
    SnippetChips,
    PaginationControl,
    SnippetStandard,
    DownloadButton,
  },
})
export default class TemplateMatrix extends Mixins(
  FormattingMixin,
  ListConfigurationMixin
) {
  get hasPermission() {
    return (
      this.$user.roles().includes(SystemRoles.QUALITYMANAGER) ||
      this.$user.roles().includes(SystemRoles.ADMINISTRATOR) ||
      this.$user.roles().includes(SystemRoles.SYSTEM_ADMINISTRATOR)
    );
  }
  get currentBreadCrumbs() {
    return [
      {
        text: this.$t("views.audit.home"),
        to: { name: ROUTE_NAMES.HOME },
        activeclass: "",
      },
      {
        text: this.$t("sitemap.questionpool"),
        to: { name: ROUTE_NAMES.TEMPLATES },

        activeclass: "",
      },
    ];
  }

  drawerRightVisible: boolean = false;
  today: any = new Date().toISOString().slice(0, 10);
  isUpdate: boolean = false;
  date: any = null;
  dialog: boolean = false;

  get headers() {
    return [
      {
        text: this.$i18n.t("views.template_matrix.title").toString(),
        align: "left",
        sortable: true,
        value: "name",
      },
      {
        text: this.$i18n.t("views.template_matrix.standard").toString(),
        value: "standard",
        sortable: false,
      },
      {
        text: this.$i18n.t("views.template_matrix.audit_item_count").toString(),
        value: "auditItemCount",
        sortable: true,
      },

      {
        text: this.$i18n.t("views.template_matrix.type").toString(),
        value: "auditClass",
        sortable: true,
      },
      {
        text: this.$i18n.t("views.template_matrix.art").toString(),
        value: "audited_by",
        sortable: false,
      },
      {
        text: this.$i18n.t("views.template_matrix.scope").toString(),
        value: "scope",
        sortable: false,
      },
      {
        text: this.$i18n.t("views.template_matrix.created").toString(),
        value: "created_date",
        sortable: false,
      },
      {
        text: this.$i18n.t("views.template_matrix.actions").toString(),
        value: "name",
        sortable: false,
      },
    ];
  }
  editedItem: any = {
    id: uuidv4(),
    name: "Fragenliste",
    standard: "ISO9001",
    type: "Prozessaudit",
    audited_by: "Intern",
    scope: "",
    created_date: "2018-08-08",
    audit_items: {},
  };
  defaultItem: any = {
    id: uuidv4(),
    name: "Default",
    standard: "ISO9001",
    type: "",
    audited_by: "Intern",
    scope: "",
    auditor: "",
    planning_date: "",
    auditing_date: "",
    audit_items: {},
  };

  mounted() {
    this.setMenuPanel("breadcrumb");
    this.updateBreadCrumbs();
  }

  @Getter(api.getters.getFlatTemplateMatrix, { namespace: api.namespace })
  getFlatTemplateMatrix!: {
    data: { [k: string]: any };
    id: string;
    permissions: TemplatePermissions;
  }[];

  get audittemplates() {
    console.log("AuditTemplate Object", this.getFlatTemplateMatrix);
    return this.getFlatTemplateMatrix.map(at => {
      if (at && at.data) {
        return {
          id: at.data.id,
          name: at.data.name,
          standardRefs: at.data.standardRefs,
          auditItemCount: at.data.auditItemCount,
          auditClass: at.data.audit_class,
          auditedBy: at.data.audited_by ?? "",
          scope: at.data.scope ?? "",
          createdDate: at.data.created_date ?? null,
          permissions: at.permissions,
        };
      } else {
        return null;
      }
    });
  }

  // Overwrites from ListConfigurationMixin
  get listConfigConfigKey() {
    return "templatematrix";
  }

  get listConfigTotalItemCount() {
    return this.audittemplates.length;
  }

  get importAvailable() {
    return supportsRegexLookAheadLookBehind();
  }

  @Action(appApi.actions.setBreadCrumb, { namespace: appApi.namespace })
  setBreadCrumb!: TodoActionSignature;

  @Action(appApi.actions.setMenuPanel, { namespace: appApi.namespace })
  setMenuPanel!: TodoActionSignature;

  @Action(api.actions.deleteTemplate, { namespace: api.namespace })
  deleteTemplate!: TodoActionSignature;

  @Action(api.actions.exportTemplate, { namespace: api.namespace })
  exportTemplate!: (templateId: string) => Promise<Blob>;

  @Getter(appApi.getters.getCurrentLanguage, { namespace: appApi.namespace })
  currentLanguage!: TodoAny;

  @Watch("currentLanguage", { immediate: true, deep: true })
  onLaguageChange() {
    this.updateBreadCrumbs();
  }

  updateBreadCrumbs() {
    this.setBreadCrumb(this.currentBreadCrumbs);
  }

  openTemplate(templateId, event) {
    console.log("openTemplate", templateId, event);
    this.$router
      .push({ name: ROUTE_NAMES.TEMPLATE, params: { templateId } })
      .then(r => console.log("openTemplate", r))
      .catch(err => console.error("openTemplate", err));
  }

  openImportAuditItemsDialog() {
    this.$router.push({
      name: ROUTE_NAMES.TEMPLATE_IMPORT,
    });
  }

  prepareExport(templateId: string) {
    console.log("prepareExport Template", templateId);
    return () => {
      console.log("Download Template", templateId);
      return this.exportTemplate(templateId);
    };
  }

  editItem(templateId, event) {
    //this.openTemplate(template_id, event);

    console.log("editTemplateMetadata", templateId, event);
    this.$router.push({
      name: ROUTE_NAMES.TEMPLATE_METADATA_OVERVIEW,
      params: { templateId },
    });
  }

  openTemplateCreateDialog() {
    console.log("openTemplateCreateDialog");
    this.$router.push({
      name: ROUTE_NAMES.TEMPLATE_CREATE,
    });
  }

  deleteItem(templateId) {
    if (
      confirm(
        String(this.$t("views.template_matrix.confirm_template_deletion"))
      )
    ) {
      this.deleteTemplate(templateId).catch(err => {
        console.error("deleteTemplate:failed", templateId, err);
      });
    }
  }

  get hasCreatePermission() {
    const templateRoles = calcAllTemplateRoles(
      null,
      this.$user.id(),
      this.$user.roles()
    );
    const permissions = calcTemplatePermissions(templateRoles);
    return permissions.create;
  }

  get canImportAuditItems() {
    // For now, the import is only allowed for "sys" users:
    return (
      this.$hasFeature("featureEnhancedTemplateImport") &&
      this.$user.roles().includes(SystemRoles.SYSTEM_ADMINISTRATOR)
    );
  }
}
