




























import {
  NotedProof,
  SelfAssessment,
  StoredAttachmentEntry,
} from "@auditcloud/shared/lib/schemas";
import { typeIsArrayOf } from "@auditcloud/shared/lib/utils/type-guards";
import { idable } from "@auditcloud/shared/lib/types/common";
import { Component, Prop, Vue } from "vue-property-decorator";
import AAttachmentList from "@/components/snippets/AAttachmentList.vue";
import { api } from "@/store/modules/audit";
import { namespace } from "vuex-class";
import { Dictionary, isString, pick } from "lodash";
import ANotedProofList from "@/components/snippets/ANotedProofList.vue";

const auditModule = namespace(api.namespace);

@Component({
  components: {
    AAttachmentList,
    ANotedProofList,
  },
})
export default class ASelfAssessmentResultExpansion extends Vue {
  openPanel: number = 0;

  @Prop({
    type: Object,
    default: null,
  })
  selfAssessmentResult!: idable<SelfAssessment>;

  @auditModule.Getter(api.getters.getResultAttachmentsMap)
  resultAttachmentsMap!: Dictionary<StoredAttachmentEntry>;

  get attachments() {
    const attachmentIds = this.selfAssessmentResult.attachmentIds;
    return pick(this.resultAttachmentsMap, attachmentIds);
  }

  get notedProofs(): NotedProof[] {
    return this.selfAssessmentResult.notedProofs ?? [];
  }
}
