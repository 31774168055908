import Vue from "vue";
import firebase from "firebase/compat/app";

import { UserPlugin } from "@/plugins/user";
import { ContentTranslationPlugin } from "@/plugins/ContentTranslation";
import { FeatureFlagPlugin } from "@/plugins/FeatureFlags";
import "./registerCommonComponents";

import App from "./App.vue";
import router from "./router";
import i18n from "@/plugins/i18n";
import vuetify from "@/plugins/vuetify";
import store from "./store/index";

function registerAppComponents() {
  const TextHighlight = () => import("vue-text-highlight");
  Vue.component("text-highlight", TextHighlight);

  const AFindingLayout = () =>
    import("./components/layouts/BaseLayouts/FindingLayout.vue");
  Vue.component("AFindingLayout", AFindingLayout);

  const AAppFooter = () => import("./components/app/AAppFooter.vue");
  Vue.component("AAppFooter", AAppFooter);

  const AAuditFooter = () => import("./components/app/AAuditFooter.vue");
  Vue.component("AAuditFooter", AAuditFooter);

  const AuditActivityStream = () =>
    import("./components/widgets/sidebar/AuditActivityStream.vue");
  Vue.component("AuditActivityStream", AuditActivityStream);

  const AAuditTransition = () =>
    import("./components/widgets/sidebar/AAuditTransition.vue");
  Vue.component("AAuditTransition", AAuditTransition);

  const AAuditItemSortAction = () =>
    import("./components/widgets/sidebar/AAuditItemSortAction.vue");
  Vue.component("AAuditItemSortAction", AAuditItemSortAction);

  const AAuditItemFilterAction = () =>
    import("./components/widgets/sidebar/AAuditItemFilterAction.vue");
  Vue.component("AAuditItemFilterAction", AAuditItemFilterAction);

  const AAttachmentSidebar = () =>
    import("./components/widgets/sidebar/AAttachmentSidebar.vue");
  Vue.component("AAttachmentSidebar", AAttachmentSidebar);

  const AAuditAdvancedSettings = () =>
    import("./components/widgets/sidebar/AAuditAdvancedSettings.vue");
  Vue.component("AAuditAdvancedSettings", AAuditAdvancedSettings);

  const AWorkflowHistorySidebar = () =>
    import("./components/widgets/sidebar/AWorkflowHistorySidebar.vue");
  Vue.component("AWorkflowHistorySidebar", AWorkflowHistorySidebar);

  const AAuditDataSidebar = () =>
    import("./components/widgets/sidebar/AAuditDataSidebar.vue");
  Vue.component("AAuditDataSidebar", AAuditDataSidebar);

  const ASelfAssessmentSidebar = () =>
    import("./components/widgets/sidebar/ASelfAssessmentSidebar.vue");
  Vue.component("ASelfAssessmentSidebar", ASelfAssessmentSidebar);

  const AExternalMeasureProcessingSidebar = () =>
    import(
      "./components/widgets/sidebar/AExternalMeasureProcessingSidebar.vue"
    );
  Vue.component(
    "AExternalMeasureProcessingSidebar",
    AExternalMeasureProcessingSidebar
  );

  const AMeasureProcessStepContainer = () =>
    import(
      "./components/controls/MeasureProcessSteps/AMeasureProcessStepContainer.vue"
    );
  Vue.component("AMeasureProcessStepContainer", AMeasureProcessStepContainer);

  const AMeasureProcessStepEmpty = () =>
    import(
      "./components/controls/MeasureProcessSteps/AMeasureProcessStepEmpty.vue"
    );
  Vue.component("AMeasureProcessStepEmpty", AMeasureProcessStepEmpty);

  const AMeasureProcessStepQuestion = () =>
    import(
      "./components/controls/MeasureProcessSteps/AMeasureProcessStepQuestion.vue"
    );
  Vue.component("AMeasureProcessStepQuestion", AMeasureProcessStepQuestion);

  const AMeasureProcessStepFinding = () =>
    import(
      "./components/controls/MeasureProcessSteps/AMeasureProcessStepFinding.vue"
    );
  Vue.component("AMeasureProcessStepFinding", AMeasureProcessStepFinding);

  const AMeasureProcessStepDirectMeasure = () =>
    import(
      "./components/controls/MeasureProcessSteps/AMeasureProcessStepDirectMeasure.vue"
    );
  Vue.component(
    "AMeasureProcessStepDirectMeasure",
    AMeasureProcessStepDirectMeasure
  );

  const AMeasureProcessStepCauseAnalysis = () =>
    import(
      "./components/controls/MeasureProcessSteps/AMeasureProcessStepCauseAnalysis.vue"
    );
  Vue.component(
    "AMeasureProcessStepCauseAnalysis",
    AMeasureProcessStepCauseAnalysis
  );

  const AMeasureProcessStepCorrectiveMeasure = () =>
    import(
      "./components/controls/MeasureProcessSteps/AMeasureProcessStepCorrectiveMeasure.vue"
    );
  Vue.component(
    "AMeasureProcessStepCorrectiveMeasure",
    AMeasureProcessStepCorrectiveMeasure
  );

  const AMeasureProcessStepEfficacyCheck = () =>
    import(
      "./components/controls/MeasureProcessSteps/AMeasureProcessStepEfficacyCheck.vue"
    );
  Vue.component(
    "AMeasureProcessStepEfficacyCheck",
    AMeasureProcessStepEfficacyCheck
  );

  const ATemplateItemFilterAction = () =>
    import("./components/widgets/sidebar/ATemplateItemFilterAction.vue");
  Vue.component("ATemplateItemFilterAction", ATemplateItemFilterAction);
}

export default async function initAuditCloud(data: {
  firebaseApp: firebase.app.App;
  invalidBackend: boolean;
  systemDocExists: boolean;
}): Promise<Vue> {
  Vue.use(UserPlugin);
  Vue.use(FeatureFlagPlugin);
  Vue.use(ContentTranslationPlugin);
  registerAppComponents();

  const app = new Vue({
    router,
    i18n,
    store,
    vuetify,
    render: h => h(App),
  }).$mount("#app");

  return app;
}
