






















































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { api } from "@/store/modules/users";
import { api as confApi } from "@/store/modules/configuration";

import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";
import { VForm } from "../types";
import {
  validate as validateEmail,
  sanitize as sanitizeEmail,
} from "@auditcloud/shared/lib/utils/sanitizer/email";
import {
  validate as validateDisplayName,
  sanitize as sanitizeDisplayName,
} from "@auditcloud/shared/lib/utils/sanitizer/displayName";
import { TranslateableText } from "@auditcloud/shared/lib/schemas";

@Component({})
export default class AUserAutocompleteCreateDialog extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  value!: boolean;
  loading = false;

  newUserDisplayName: string = "";
  newUserEmail: string = "";

  @Getter(confApi.getters.getUserAutocompleteHint, {
    namespace: confApi.namespace,
  })
  userAutocompleteHint!: TranslateableText | null;

  @Action(api.actions.createUserInactive, { namespace: api.namespace })
  createUserInactive!: (payload: {
    data: { email: string; displayName: string };
  }) => Promise<{ created: boolean; ref: IUserRef }>;

  get nameRules() {
    return [
      v => !!v || this.$t("common.rules.name_required").toString(),
      v =>
        validateDisplayName(sanitizeDisplayName(v)) ||
        this.$t("common.rules.name_required").toString(),
    ];
  }
  get emailRules() {
    return [
      v => !!v || this.$t("common.rules.email_required").toString(),
      v =>
        validateEmail(sanitizeEmail(v)) ||
        this.$t("common.rules.email_invalid").toString(),
    ];
  }

  mounted() {
    setTimeout(() => (this.$refs.nameInput as HTMLInputElement).focus(), 100);
  }

  addNewUser() {
    console.log("addNewUser", this.newUserDisplayName, this.newUserEmail);
    if (this.$refs && this.$refs.form) {
      const form = this.$refs.form as VForm;
      if (form.validate()) {
        const displayName = sanitizeDisplayName(this.newUserDisplayName);
        const email = sanitizeEmail(this.newUserEmail);
        const payload = {
          data: {
            displayName,
            email,
          },
        };
        this.loading = true;

        this.createUserInactive(payload)
          .then(res => {
            if (
              res.created ||
              confirm(
                this.$t(
                  "common.confirms.user_with_email_address_exist_use_existing",
                  {
                    displayName: res.ref.displayName,
                  }
                ).toString()
              )
            ) {
              this.$emit("save", res.ref);
              this.newUserDisplayName = "";
              this.newUserEmail = "";
              this.$emit("input", false);
            }
          })
          .catch(err => {
            console.error("Failed to create User", err);
            alert("Failed to add User");
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        console.log("From invalid", form, form.validate());
      }
    } else {
      console.log("addUser Failed", this.$refs && this.$refs.form);
    }
  }
}
