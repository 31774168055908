import { Module } from "vuex";
import { UserState } from "./types";
import { RootState } from "../../types";

import { getters, getterNames } from "./getters";
import { actions, actionNames } from "./actions";
import { mutations, mutationNames } from "./mutations";
import {
  MutationsType,
  GettersType,
  ActionsType,
  getterNs,
} from "@/utils/VuexHelper";
import {
  REV_KEY,
  toUpdateData,
  VersionedDocument,
} from "@auditcloud/shared/lib/types/VersionedDocument";

import { errorIfFalse, createError } from "@/utils/Errors";

const state = new UserState();

const namespaced: boolean = true;
const modul: Module<UserState, RootState> = {
  namespaced,
  getters,
  actions,
  mutations,
  state,
};

export const api = {
  namespace: "user",
  mutations: mutationNames,
  getters: getterNames,
  actions: actionNames,
};
export type Mutations = MutationsType<typeof mutations>;
export type Getters = GettersType<typeof getters>;
export type Actions = ActionsType<typeof actions>;

export default modul;

export function vd(rootGetters: any) {
  return rootGetters[
    getterNs(api, api.getters.getVersionedDocument)
  ] as VersionedDocument<firebase.default.firestore.FieldValue> | null;
}

export function vdu(rootGetters: any) {
  const versionedDocument = vd(rootGetters);
  if (versionedDocument !== null) {
    return versionedDocument.headerUpdate();
  } else {
    throw createError("login/no user");
  }
}

export function rdu(rootGetters: any) {
  return toUpdateData(vdu(rootGetters));
}

export function vdc(rootGetters: any) {
  const versionedDocument = vd(rootGetters);
  if (versionedDocument !== null) {
    return versionedDocument.headerCreate();
  } else {
    throw createError("login/no user");
  }
}

export function rdc(rootGetters: any) {
  return {
    [REV_KEY]: vdc(rootGetters),
  };
}

export function vdd(rootGetters: any) {
  const versionedDocument = vd(rootGetters);
  if (versionedDocument !== null) {
    return versionedDocument.headerDelete();
  } else {
    throw createError("login/no user");
  }
}

export function rdd(rootGetters: any) {
  return toUpdateData(vdd(rootGetters));
}
