
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { api, filterUsersByName } from "@/store/modules/users";

import { IUserRef, typeIsIUserRef } from "@auditcloud/shared/lib/types/UserRef";
import { getterNs, actionNs } from "@/utils/VuexHelper";
import { debounce, Dictionary } from "lodash";
import {
  I18nKey,
  I18nInline,
  ConfigurableGetter,
  ConfigurableAction,
  actionPath,
  getterPath,
} from "@auditcloud/shared/lib/types/common";
import {
  TranslateableText,
  AutocompleteUser,
} from "@auditcloud/shared/lib/schemas";

export interface ItemType {
  id: string;
  disabled: boolean;
  data: IUserRef;
}

@Component({})
export default class AUserAutocompleteMixin extends Vue {
  itemsTransform: (IUserRef) => IUserRef | ItemType = v => v;
  xItems: (IUserRef | ItemType)[] = [];
  loading: boolean = false;
  valid: boolean = false;
  search = "";

  @Prop({
    type: Boolean,
    default: false,
  })
  allowUserCreation!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  clearable!: boolean;

  @Prop({
    type: [String, Object],
    default: "",
  })
  label!: TranslateableText | I18nKey | I18nInline;

  @Prop({
    type: [String, Object],
    default: null,
  })
  placeholder!: TranslateableText | I18nKey | I18nInline | null;

  @Prop({
    type: Object,
    default: null,
  })
  itemsGetter!: null | ConfigurableGetter; /// Getter welcher Basis Items bereitstellt

  @Prop({
    type: Object,
    default: null,
  })
  queryAction!: null | ConfigurableAction; /// Action um nach Usern zu suchen

  @Getter(api.getters.getLoadedUsers, {
    namespace: api.namespace,
  })
  readonly loadedUsers!: Dictionary<AutocompleteUser>;

  resolveUser(userId: string): AutocompleteUser {
    return (
      this.loadedUsers[userId] ?? {
        n: this.$t("components.controls.user_autocomplete.unknown_user_id", {
          userId,
        }).toString(),
        e: "-",
        r: [],
      }
    );
  }

  get defaultedPlaceholder() {
    if (this.placeholder === null) {
      return this.$t(
        "components.controls.user_autocomplete.search_place_holder"
      );
    } else {
      return this.$ft(this.placeholder);
    }
  }

  get baseUsers(): IUserRef[] {
    if (this.itemsGetter) {
      const path = getterPath(this.itemsGetter);
      const getterResult: any = this.$store.getters[path];

      if (Array.isArray(getterResult) && getterResult.every(typeIsIUserRef)) {
        return getterResult;
      } else {
        console.error(
          "ItemsGetter for AUserAutocomplete doesn't return valid userRefs"
        );
        return [];
      }
    } else {
      return [];
    }
  }

  @Watch("baseUsers", { immediate: true })
  onBaseUsersChanged(newVal: IUserRef[]) {
    this.xItems = newVal.map(this.itemsTransform);
  }

  selectNewUser(user: IUserRef): void {}

  get debouncedQueryUser() {
    const queryAction = this.queryAction;
    const itemsTransform = this.itemsTransform;
    if (queryAction) {
      return debounce((input: string) => {
        console.log("Debounce", input);
        this.loading = true;
        const path = actionPath(queryAction);
        this.$store
          .dispatch(path, input, {
            root: true,
          })
          .then((res: IUserRef[]) => {
            this.xItems = res.map(itemsTransform);
          })
          .catch(err => {
            console.error("queryUser failed", err);
          })
          .finally(() => {
            this.loading = false;
          });
      }, 250);
    } else {
      const users = this.baseUsers;
      return debounce((input: string) => {
        console.warn("No queryAction defined", input);
        this.xItems = filterUsersByName(users, input).map(itemsTransform);
      }, 250);
    }
  }

  queryUsers(input: string) {
    this.debouncedQueryUser(input);
  }
}
