import { QueryData } from "./types/QueryData";

/**
 * Create a query filter to match documents against an ACL.
 *
 * To fetch all accessible documents, specify the user ID and system roles.
 * To fetch only documents where a user is "member", specify just the user ID.
 *
 * @param options.userId ID of the requesting user
 * @param options.roles (some of the) system roles of the requesting user
 * @param options.accessType the suffix X of the entity `accessListX` to use
 */
export function createAccessFilter(options: {
  userId: string | null;
  roles?: string[];
  accessType?: "MetadataRead" | "PrivateRead" | "Read";
}): QueryData {
  const { accessType = "MetadataRead", roles = [], userId } = options;
  const accessList = `accessList${accessType}`;
  const matchers = [
    ...roles.map(role => `systemRole:${role}`),
    `user:${userId}`,
  ];
  if (matchers.length > 10) {
    console.warn("Access filter has too many entries: ", matchers);
  }
  return new QueryData(accessList, "array-contains-any", matchers);
}
