import { mapValues } from "lodash";
import { RootState, RootGetters } from "@/store/types";
import { State } from "./types";
import { Finding } from "@auditcloud/shared/lib/schemas";
import { Getters } from ".";
import { Attachment } from "@/components/types";

type Getter<R> = (
  state: State,
  getters: Getters,
  rootState: RootState,
  rootGetters: RootGetters
) => R;

const getFinding: Getter<Finding | null> = state => {
  return state.findingId === null ? null : { customData: {}, ...state.finding };
};

const getNewAttachments: Getter<Attachment[]> = state => {
  return state.attachments;
};

const getEvaluationForSelfAssessmentWithId: Getter<string | null> = state => {
  return state.evaluationForSelfAssessmentWithId;
};

const getters = {
  getFinding,
  getNewAttachments,
  getEvaluationForSelfAssessmentWithId,
};

const n = mapValues(getters, (_, key) => key);

export { n as getterNames, getters };
