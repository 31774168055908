import VueI18n from "vue-i18n";
import Vue from "vue";
import { TranslationMap } from "@/store/modules/i18n/types";
import { Dictionary } from "lodash";
import { enrichWithSharedTranslations } from "@auditcloud/components/src/plugins/i18n";
import de from "../locales/de.json";
import en from "../locales/en.json";

Vue.use(VueI18n);

export function loadDefaultMessages() {
  const messages: Dictionary<TranslationMap> = {
    de: enrichWithSharedTranslations(de, "de"),
    en: enrichWithSharedTranslations(en, "en"),
  };
  // TODO: Automatisches Laden wieder ermöglichen.
  // Funktionen verursachen Probleme bei den Unittests
  /*
  const locales = require.context(
    "../locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );

  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });*/
  return messages;
}

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: loadDefaultMessages(),
  silentTranslationWarn: true,
});

export const languages = (key: string, ...params: any) => i18n.t(key, params);
export function readFrontendLanguage(): string {
  return i18n.locale || "en";
}

export default i18n;
