import { GetterTree } from "vuex";
import { RootState } from "../../types";
import {
  I18nTranslationsState,
  TranslationMap,
  TranslationPatchMap,
} from "./types";
import { toPairs, merge, isString } from "lodash";
import traverse from "traverse";

const getTranslationPatchMap = "getTranslationPatchMap";

const n = {
  getTranslationPatchMap,
};

const emptyTM = (): TranslationMap => ({});
const emptyTPM = (): TranslationPatchMap => ({});

const getters: GetterTree<I18nTranslationsState, RootState> = {
  [n.getTranslationPatchMap](state, getters): TranslationPatchMap {
    return merge(
      emptyTPM(),
      ...toPairs(state.translations).map(([locale, values]) => {
        return traverse(values).reduce(function (acc, node) {
          if (isString(node)) {
            const path = this.path.join(".");
            if (acc[path]) {
              acc[path][locale] = node;
            } else {
              acc[path] = { [locale]: node };
            }
          }
          return acc;
        }, emptyTM());
      })
    );
  },
};

export { n as getterNames, getters };
