import Vue from "vue";
import { MutationTree } from "vuex";

import { UserGroupsState } from "./types";
import { Dictionary } from "lodash";

const n: Dictionary<string> = {};

const mutations: MutationTree<UserGroupsState> = {};

export { n as mutationNames, mutations };
