

























































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter } from "vuex-class";
import { cloneDeep, isArray, isString, pick } from "lodash";

import { VTextField, VIcon, VBtn } from "vuetify/lib";
import draggable from "vuedraggable";
import { isatty } from "tty";

@Component({
  components: { VTextField, VIcon, VBtn, draggable },
})
export default class SortableInputControl extends Vue {
  xValue: string[] = [];
  fieldInput: string = "";
  duplicateError: boolean = false;
  emptyInputError: boolean = false;
  errormsg: string | null = null;
  editMode: boolean = false;
  index: number = -1;

  @Prop({
    default: () => [],
    type: Array,
  })
  readonly value!: string[];

  @Prop({
    type: String,
    default: "",
  })
  readonly placeholder!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly disabled!: boolean;

  @Prop({
    default: "",
    type: String,
  })
  readonly label!: string;

  @Prop({
    default: (word: string, idx: number) => word,
    type: Function,
  })
  readonly formatter!: (word: string, idx: number) => string;

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly error!: boolean;

  @Prop({
    default: "",
    type: String,
  })
  readonly errorMessages!: string;

  @Watch("value", {
    deep: true,
    immediate: true,
  })
  onValueChanged(newVal: string[]) {
    this.xValue = newVal;
  }

  get valueList() {
    return this.xValue.map((word, idx) => {
      return {
        word,
        active: this.index === idx,
      };
    });
  }

  get filterAttrs() {
    const attrs = cloneDeep(
      pick(this.$props, ["error", "errorMessages", "errorCount"])
    );
    if (this.emptyInputError || this.duplicateError) {
      const errmsg = attrs.errorMessages;
      if (isString(errmsg) && errmsg.length > 0) {
        attrs.errorMessages = [errmsg, this.errormsg];
        attrs.errorCount = 2;
      } else if (isArray(errmsg)) {
        attrs.errorMessages.push(this.errormsg);
        attrs.errorCount = attrs.errorMessages.length;
      } else {
        attrs.errorMessages = this.errormsg;
        attrs.errorCount = 1;
      }

      console.log("ERRORMSG: ", typeof errmsg, errmsg);
      attrs.error = true;
    }
    attrs.error;
    return attrs;
  }

  addWord() {
    this.errormsg = null;
    const text = this.fieldInput.trim();
    if (text.length > 0) {
      if (this.editMode) {
        if (
          !this.xValue.includes(text) ||
          this.xValue.findIndex(item => item === text) === this.index
        ) {
          this.$set(this.xValue, this.index, text);
        } else {
          this.duplicateError = true;
          this.errormsg = this.$t(
            "components.controls.wordlist.error_dpl"
          ).toString();
          return;
        }
      } else {
        if (!this.xValue.includes(text)) {
          this.xValue.push(text);
        } else {
          this.duplicateError = true;
          this.errormsg = this.$t(
            "components.controls.wordlist.error_dpl"
          ).toString();
          return;
        }
      }
      this.fieldInput = "";
      this.$emit("input", this.xValue);
    } else {
      this.emptyInputError = true;
      this.errormsg = this.$t(
        "components.controls.wordlist.error_msg"
      ).toString();
    }
    this.closeEditMode();
  }

  closeEditMode() {
    this.index = -1;
    this.editMode = false;
    this.fieldInput = this.duplicateError ? this.fieldInput : "";
  }

  dragEnd() {
    this.$emit("input", this.xValue);
  }

  removeWord(idx: number) {
    this.closeEditMode();
    this.xValue.splice(idx, 1);
    this.$emit("input", this.xValue);
  }

  startEditMode(idx: number) {
    this.editMode = true;
    this.index = idx;
    this.fieldInput = this.xValue[idx];
  }

  vanishErrorByBlur() {
    this.emptyInputError = false;
    this.errormsg = this.duplicateError ? this.errormsg : "";
  }

  vanishErrorByChange() {
    this.errormsg = "";
    this.duplicateError = false;
    this.emptyInputError = false;
  }
}
