























































import Vue, { PropType } from "vue";
import { Component, Prop } from "vue-property-decorator";
import AUserSnippet from "@/components/snippets/AUserSnippet.vue";
import ADateSnippet from "@/components/snippets/ADateSnippet.vue";
import { idable } from "@auditcloud/shared/lib/types/common";
import { MeasureProcessStepDoc } from "@auditcloud/shared/lib/schemas";

const STEP_STATE = ["open", "doing", "done", "failed"] as const;
type StepState = typeof STEP_STATE[number];

@Component({ components: { AUserSnippet, ADateSnippet } })
export default class AMeasureProcessStepReset extends Vue {
  @Prop({
    default: "open",
    type: String as PropType<StepState>,
    validator: (v: any) => STEP_STATE.includes(v),
  })
  stepState!: StepState;

  @Prop({
    required: true,
    type: Object as PropType<idable<MeasureProcessStepDoc>>,
  })
  step!: idable<MeasureProcessStepDoc>;

  @Prop({
    required: false,
    type: Object as PropType<idable<MeasureProcessStepDoc> | null>,
  })
  nextStep?: idable<MeasureProcessStepDoc> | null;

  @Prop({
    type: Boolean,
  })
  greyOut?: boolean;

  get userId(): string | null {
    return this.stepState === "doing"
      ? this.step.workflowInfo.creatorRef.id
      : this.nextStep?.workflowInfo.creatorRef.id ?? null;
  }

  get date(): string | null {
    return this.stepState === "doing"
      ? this.step.workflowInfo.enteredAt
      : this.nextStep?.workflowInfo.enteredAt ?? null;
  }

  get iconConfig() {
    return {
      icon: "mdi-undo-variant",
      color: this.greyOut ? "grey lighten-2" : "warning",
    };
  }

  get transitionComment() {
    return this.nextStep?.workflowInfo?.comment;
  }
}
