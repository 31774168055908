import { AuditMetadataClient } from "@/types/Audit";
import { unlistenerFunction, WatchedDocument } from "@/utils/firestore";
import { AuditPermissions } from "@auditcloud/shared/lib/utils/aclHelpers";
import { idable } from "@auditcloud/shared/lib/types/common";
import { AuditMetadataDoc } from "@auditcloud/shared/lib/schemas";

export class AuditsState {
  loadedAudits: WatchedDocument<AuditMetadataClient>[] = [];
  loadedAuditsUnlistener: unlistenerFunction | null = null;
  activeObserverFilter: null | LoadAuditsConfig = null;
  loadedAuditsNewQuery: boolean = true;
  selectedYear: number | null = null;
  selectedProgram: string | null = null;
  selectedOrganizationUnit: string | null = null;
}

export interface AuditPermissionInfo {
  auditDoc: WatchedDocument<AuditMetadataClient>;
  permissions: AuditPermissions;
  auditRoles: string[];
}

export type LoadAuditsConfig = {
  year: number | null;
  member: string | null;
};

export interface FlatAuditMatrixData {
  id: string;
  auditRoles: string[];
  permissions: AuditPermissions;
  protected: boolean;
  auditMeta: idable<AuditMetadataDoc>;
  auditname: string;
  title: string;
  standard: string[];
  type: string;
  audited_by: string;
  process: unknown;
  scope: unknown;
  auditor: string;
  coauditors: string[];
  leadauditor: string;
  planning_year: string;
  createdAt: string;
  date_filter: string;
  auditing_date: string;
  planning_date: string;

  auditing_date_display?: string[];
  planning_date_display?: string | null;
  auditProgram: string;
  statusId: string;
  status: string;
  hasPendingWrites: boolean;
  fromCache: boolean;
}

export type State = AuditsState;
