
















































































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Getter, Action, Mutation } from "vuex-class";

import { api as auditApi } from "@/store/modules/audit";
import { api as confApi } from "@/store/modules/configuration";
import { api as measuresApi } from "@/store/modules/measures";
import { DIALOG_NAMES, dialogRoute } from "@/routenames";

import {
  FindingType,
  FindingTypeMap,
} from "@auditcloud/shared/lib/types/ItemTypes";
import ATableItem from "@auditcloud/components/widgets/ATableItem.vue";
import AChip from "@/components/snippets/AChip.vue";
import AStandardChip from "@/components/snippets/AStandardChip.vue";
import AAttachmentList from "@/components/snippets/AAttachmentList.vue";
import ASelfAssessmentResultExpansion from "@/components/widgets/ASelfAssessmentResultExpansion.vue";
import AGeoLocation from "@/components/snippets/AGeoLocation.vue";

import { VBtn, VIcon, VChip } from "vuetify/lib";
import {
  EmbeddedFinding,
  Finding,
  SelfAssessment,
  StoredAttachmentEntry,
  TranslateableText,
} from "@auditcloud/shared/lib/schemas";
import { typeIsArrayOf } from "@auditcloud/shared/lib/utils/type-guards";
import { Dictionary, flatten, pick, uniq, isString, isUndefined } from "lodash";
import { typeIsNotEmpty } from "@auditcloud/shared/lib/utils/filter/typeIsNotEmpty";
import { idable, nullable } from "@auditcloud/shared/lib/types/common";
import { Filter } from "@auditcloud/shared/lib/utils/filter/types";
import * as AuditItemListManipulatorIds from "@auditcloud/shared/lib/utils/filter/AuditItemListManipulatorIds";
import { calcUrgency } from "@auditcloud/shared/lib/types/UrgencyChart";
import { AuditClassClient } from "@auditcloud/shared/lib/types/AuditClass";
import { FindingTypeConfig } from "@auditcloud/shared/lib/types/Configuration/Configuration";

@Component({
  components: {
    ATableItem,
    VBtn,
    VIcon,
    VChip,
    AChip,
    AStandardChip,
    AAttachmentList,
    ASelfAssessmentResultExpansion,
    AGeoLocation,
  },
})
export default class AuditOverviewFinding extends Vue {
  @Prop({
    required: true,
  })
  readonly finding!: idable<EmbeddedFinding>;

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly readonly!: boolean;

  @Prop({
    type: Array,
    default: () => [],
  })
  selfAssessmentResults!: idable<SelfAssessment>[];

  @Prop({
    default: null,
    type: String,
  })
  readonly auditItemId!: string | null;

  @Prop({
    default: false,
    type: Boolean,
  })
  uploadError!: boolean;

  @Prop({
    default: true,
    type: Boolean,
  })
  responsiveIndent!: boolean;

  @Prop({
    required: false,
    default: true,
    type: Boolean,
  })
  readonly isMarkerJsEnabled!: boolean;

  @Getter(auditApi.getters.getAuditDimensionsMap, {
    namespace: auditApi.namespace,
  })
  auditDimensionsMap!: {
    [dimensionId: string]: { id: string; name: TranslateableText } | undefined;
  };

  @Getter(auditApi.getters.getResultAttachmentsMap, {
    namespace: auditApi.namespace,
  })
  resultAttachmentsMap!: Dictionary<StoredAttachmentEntry>;

  @Getter(auditApi.getters.getMaturityEnabled, {
    namespace: auditApi.namespace,
  })
  maturityEnabled!: boolean;

  get findingChipLabelSource(): FindingTypeConfig["findingChipLabelSource"] {
    return this.findingTypeConfig?.findingChipLabelSource;
  }

  get dimensionNames(): string[] {
    const f = this.finding as unknown as Finding;
    const l = flatten(
      f.auditItemRefs?.reduce((p, c) => {
        return [
          ...p,
          ...(c.dimensions?.map(
            d => this.auditDimensionsMap[d]?.name ?? null
          ) ?? []),
        ];
      }, [] as (TranslateableText | null)[])
    );

    // <!-- {{ auditDimensionsMap[dimensionId].name }} dimensionNames -->
    const a = uniq(l.filter(typeIsNotEmpty).map(v => this.$ft(v)));
    return a;
  }

  get selfAssessmentForFinding(): SelfAssessment | undefined {
    return this.selfAssessmentResults.find(
      selfAssessment => this.finding.selfAssessmentId === selfAssessment.id
    );
  }

  get findingType(): FindingType {
    if (typeof this.finding.type === "string") {
      return (
        this.mappedFindingTypes[this.finding.type] ??
        this.globalMappedFindingTypes[this.finding.type] ?? {
          placeholder_text: null,
          hidden: false,
          is_no_deviation: false,
          is_not_applicable: false,
          auditItemState: null,
          value: 0,
          id: "UNKNOWN",
          text: "Invalid Type",
          short: "IT",
          description: "Invalid Type",
          color: "red",
        }
      );
    } else {
      return (
        this.finding.type || {
          placeholder_text: null,
          hidden: false,
          is_no_deviation: false,
          is_not_applicable: false,
          auditItemState: null,
          value: 0,
          id: "UNKNOWN",
          text: "Invalid Type",
          short: "IT",
          description: "Invalid Type",
          color: "red",
        }
      );
    }
  }

  get attachments() {
    const attachmentIds = this.finding.attachmentIds;
    if (typeIsArrayOf(attachmentIds, isString)) {
      return pick(this.resultAttachmentsMap, attachmentIds);
    } else {
      return this.finding.attachments;
    }
  }

  get indent(): 0 | 1 {
    return this.responsiveIndent ? 1 : 0;
  }

  get urgency() {
    return calcUrgency(
      this.finding.risk?.impact ?? null,
      this.finding.risk?.likelihood ?? null
    );
  }

  @Getter(auditApi.getters.getFindingTypeConfig, {
    namespace: auditApi.namespace,
  })
  findingTypeConfig!: null | FindingTypeConfig;

  @Getter(auditApi.getters.getMappedFindingTypes, {
    namespace: auditApi.namespace,
  })
  mappedFindingTypes!: FindingTypeMap;

  @Getter(confApi.getters.getAllMappedFindingTypes, {
    namespace: confApi.namespace,
  })
  globalMappedFindingTypes!: FindingTypeMap;

  @Getter(measuresApi.getters.getMappedFindingId2MeasureIds, {
    namespace: measuresApi.namespace,
  })
  findingMeasuresMap!: { [findingId: string]: string[] };

  @Action(auditApi.actions.deleteFinding, { namespace: auditApi.namespace })
  deleteFinding!: (payload: { ref: { findingId: string } }) => Promise<void>;

  @Mutation(auditApi.mutations.TOGGLE_FILTER_SETTING, {
    namespace: auditApi.namespace,
  })
  toggleFilterSetting!: (payload: Filter) => void;

  get useRiskBasedFindings(): boolean {
    return this.auditClass?.useRiskBasedFindings ?? false;
  }

  get hasLikelihood() {
    return (this.finding.risk?.likelihood ?? null) !== null;
  }
  get hasImpact() {
    return (this.finding.risk?.impact ?? null) !== null;
  }

  @Getter(auditApi.getters.getAuditClass, { namespace: auditApi.namespace })
  auditClass!: nullable<AuditClassClient>;
  remove() {
    const measuresForFinding = this.findingMeasuresMap[this.finding.id];

    const text =
      measuresForFinding && measuresForFinding.length > 0
        ? this.$t(
            "components.widgets.audit_overview_finding.delete_finding_with_measures",
            { measureCount: measuresForFinding.length }
          ).toString()
        : this.$t(
            "components.widgets.audit_overview_finding.delete_finding"
          ).toString();

    if (confirm(text)) {
      console.log("Delete", this.findingType.hidden, this.finding.id);
      this.deleteFinding({ ref: { findingId: this.finding.id } });
    }
  }

  initMove() {
    this.$emit("init-move", this.finding.id);
  }

  edit() {
    const findingId: string = this.finding.id;
    const auditItemId = this.auditItemId;
    const additionalParams: Dictionary<string> = auditItemId
      ? { findingId, auditItemId }
      : { findingId };
    const newRoute = {
      name: dialogRoute(this.$route.name ?? "error", DIALOG_NAMES.FINDING),
      params: {
        ...this.$route.params,
        ...additionalParams,
      },
    };
    console.log("Edit Finding", newRoute);
    this.$router.push(newRoute);
  }

  filterMaturity(maturity: number) {
    if (!isUndefined(maturity)) {
      this.toggleFilterSetting({
        aggregationId: AuditItemListManipulatorIds.FILTER_MATURITY,
        value: maturity,
      });
    }
  }
}
