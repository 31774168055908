import { idable, nullable } from "@auditcloud/shared/lib/types/common";
import { WatchedDocument, unlistenerFunction } from "@/utils/firestore";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import { defaultDocType } from "@auditcloud/shared/lib/types/Audit/types";
import {
  MeasureProcessStepDoc,
  TranslateableText,
} from "@auditcloud/shared/lib/schemas";
import { TransitionConfig } from "@auditcloud/shared/lib/workflow/types/Transition";

export class MeasureState {
  Document: WatchedDocument<MeasureProcessDocument | null> = defaultDocType();
  Unlistener: nullable<unlistenerFunction> = null;
  measureAccessDenied = false;
  currentStepIsDirty = false;
  loadedNewQuery: boolean = true;
  processSteps: idable<MeasureProcessStepDoc>[] = [];
  measureTransitions: idable<TransitionConfig>[] = [];
  apiErrors: TranslateableText[] = [];
  activeTransitionId: null | string = null;
}
export type State = MeasureState;

export const MEASURE_ATTACHMENT_SIDEBAR_CONFIG = {
  component: "AAttachmentSidebar",
  props: {
    attachmentModulName: "measure",
    sidebarTitle:
      "components.widgets.sidebar.audit_attachment_list.measure_attachments",
  },
};
