
import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { State, Action, Getter, Mutation } from "vuex-class";
import {
  TodoMap,
  TodoArray,
  TodoAny,
  TodoActionSignature,
  TodoMutationSignature,
} from "@auditcloud/shared/lib/utils/type-guards";
import { mapValues, isNumber, isInteger, isString } from "lodash";

import { api } from "@/store/modules/configuration";
import { mapGetters } from "vuex";
import { ItemsJsConfiguration } from "@auditcloud/shared/lib/schemas";

@Component({})
export default class ListConfiguarationMixin extends Vue {
  listConfigActivePageSize: number = -1;
  listConfigPage: number = 1;
  cachedListConfigSortBy: any = undefined;
  cachedListConfigDescending: any = undefined;

  get listConfigConfigKey(): string | undefined {
    console.error(
      "[warn] computed property listConfigConfigKey must be overwritten in the parent component"
    );
    return undefined;
  }
  get listConfigTotalItemCount(): number | undefined {
    console.error(
      "[warn] computed property listConfigTotalItemCount must be overwritten in the parent component"
    );
    return undefined;
  }

  created() {
    console.log("created in ListConfigurationMixin", this);
  }

  mounted() {
    console.log("mounted in ListConfigurationMixin", this);
  }

  beforeDestroy() {
    console.log("BeforeDestroy in ListConfigurationMixin", this);
  }

  destroy() {
    console.log("BeforeDestroy in ListConfigurationMixin", this);
  }

  @Getter(api.getters.getConfigurationForList, { namespace: api.namespace })
  getConfigurationForList!: (listId?: string) => {
    itemsjs: null | ItemsJsConfiguration;
    manipulators: null | any;
    pageSizes: Array<null | number>;
  };

  @Watch("cachedListConfigSortBy", { immediate: true, deep: true })
  onCashedSortChange(newVal: string | undefined) {
    /* if (newVal !== undefined) {
      this.$templateSort =
    } */
    console.log("Changed Value of cahseListConfigSortBy", newVal);
  }

  get listConfig() {
    return this.getConfigurationForList(this.listConfigConfigKey);
  }
  get listConfigPageSizes() {
    return this.listConfig.pageSizes;
  }
  get listConfigItemsJs() {
    console.log("ListConfigItemJS", this.listConfig.itemsjs, this.listConfig);

    const aggregations = this.listConfig.itemsjs?.aggregations ?? {};

    return {
      ...(this.listConfig.itemsjs ?? {}),
      aggregations: mapValues(aggregations, aggregation => {
        return {
          ...aggregation,
          size:
            isNumber(aggregation.size) &&
            isFinite(aggregation.size) &&
            aggregation.size > 0
              ? aggregation.size
              : 999999,
          sort:
            isString(aggregation.sort) &&
            ["term", "count", "month", "audit_status"].includes(
              aggregation.sort
            )
              ? aggregation.sort
              : "term",
        };
      }),
    };
  }

  get listConfigSortBy() {
    if (typeof this.cachedListConfigSortBy === "undefined") {
      if (
        this.listConfigItemsJs &&
        this.listConfigItemsJs.sortings &&
        this.listConfigItemsJs.sortings.name_asc
      ) {
        console.log(
          "ListConfigSortBy if",
          this.listConfigItemsJs.sortings.name_asc.field
        );
        return [this.listConfigItemsJs.sortings.name_asc.field];
      } else {
        console.log("ListConfigSortBy im else TEIL");
        return ["name"];
      }
    } else {
      console.log(
        "ListConfigSortBy cash existiert",
        this.cachedListConfigSortBy
      );
      return this.cachedListConfigSortBy;
    }
  }
  set listConfigSortBy(val) {
    this.cachedListConfigSortBy = val;
  }

  get listConfigDescending() {
    if (typeof this.cachedListConfigDescending === "undefined") {
      if (
        this.listConfigItemsJs &&
        this.listConfigItemsJs.sortings &&
        this.listConfigItemsJs.sortings.name_asc
      ) {
        return this.listConfigItemsJs.sortings.name_asc.order === "desc";
      } else {
        return false;
      }
    } else {
      return this.cachedListConfigDescending;
    }
  }
  set listConfigDescending(val) {
    this.cachedListConfigDescending = val;
  }

  get listConfigManipulator() {
    return this.listConfig.manipulators;
  }

  get listOptions() {
    return {
      itemsPerPage: this.listConfigPageSize,
      page: this.listConfigPage,
      //TODO: Dirty hack
      sortDesc: Array.from(this.listConfigDescending),
      sortBy: Array.from(this.listConfigSortBy),
    };
  }
  set listOptions(val) {
    console.log("listOptions", val.sortBy);

    this.listConfigSortBy = val.sortBy;
    console.log("ListConfig Cashing", this.cachedListConfigSortBy);
    this.listConfigDescending = val.sortDesc;
  }

  get listConfigPageSize() {
    const val =
      this.listConfigActivePageSize < 0
        ? this.listConfigPageSizes[0]
        : this.listConfigPageSizes[this.listConfigActivePageSize];
    console.log("listConfigPageSize", val);
    if (val === null) {
      return this.listConfigTotalItemCount;
    } else {
      return val;
    }
  }
  get listConfigPageCount() {
    if (this.listConfigPageSize) {
      console.log("listConfigPageCount", this.listConfigPageSize);
      return Math.max(
        Math.ceil(
          (this.listConfigTotalItemCount || 0) / this.listConfigPageSize
        ),
        1
      );
    } else {
      return 1;
    }
  }

  listConfigOnPaginationChange(value) {
    this.listConfigPage = value.page;
    this.listConfigActivePageSize = this.listConfigPageSizes.findIndex(
      v => v === value.pageSize
    );
    this.$vuetify.goTo("#paginationstart", { duration: 0 });
  }
}
