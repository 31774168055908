




























































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { api as auditResultApi } from "@/store/modules/auditResult";
import StatGetter from "@/components/layouts/BaseLayouts/StatGetter.vue";
import { ReportSchemaE, TEXT_TOTAL } from "@auditcloud/shared/lib/constants";
import {
  AuditScoreResult,
  AuditScoreResultForDimensions,
} from "@/store/modules/auditResult/getters";
import { TranslateableText } from "@auditcloud/shared/lib/schemas";

@Component({
  components: { StatGetter },
})
export default class ACalculationFullfilment extends Vue {
  @Getter(auditResultApi.getters.getAuditScoreResult, {
    namespace: auditResultApi.namespace,
  })
  auditScoreResult!: AuditScoreResult;
  @Getter(auditResultApi.getters.getAuditScoreResultForDimensions, {
    namespace: auditResultApi.namespace,
  })
  auditScoreResultForDimensions!: AuditScoreResultForDimensions;

  get tableHeader(): TranslateableText[] {
    if (this.auditScoreResultForDimensions.length > 0) {
      return [
        ...this.auditScoreResultForDimensions.map(
          entry => entry.dimension.name
        ),
        TEXT_TOTAL,
      ];
    } else {
      return [TEXT_TOTAL];
    }
  }

  get dimensionScores() {
    return this.auditScoreResultForDimensions.map(entry => entry.scoreResult);
  }

  get isDeviationSchema(): boolean {
    return (
      this.auditScoreResult.data?.reportCalcSchema === ReportSchemaE.Deviation
    );
  }
}
