





































































































// utf8=🗸

import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { State, namespace, Mutation, Getter } from "vuex-class";

import ALoadingSpinner from "@auditcloud/components/app/ALoadingSpinner.vue";
import StatusMessage from "@/components/app/StatusMessage.vue";
import MenuPanel from "@/components/app/MenuPanel.vue";
import NavigationPanel from "@/components/app/NavigationPanel.vue";
import NavigationPanelRight from "@/components/app/NavigationPanelRight.vue";
import ANotificationToast from "@/components/app/ANotificationToast.vue";

import AFeatureNotAvailableDialog from "@/components/dialogs/AFeatureNotAvailableDialog.vue";
import AMaintenanceOngoingInfo from "@/components/maintenance/AMaintenanceOngoingInfo.vue";
import AMaintenanceSoftwareUpdateDialog from "@/components/maintenance/AMaintenanceSoftwareUpdateDialog.vue";
import AMaintenanceErrorInfo from "@/components/maintenance/AMaintenanceErrorInfo.vue";

import firebase from "firebase/compat/app";

import { api } from "@/store";

import Signin from "@/views/Signin.vue";
import moment from "moment";
import { RootState } from "./store/types";

import { api as appApi } from "@/store/modules/app";
const appModul = namespace(appApi.namespace);

import { api as confApi } from "@/store/modules/configuration";
const confModule = namespace(confApi.namespace);

import { api as usersApi } from "@/store/modules/users";
const usersModul = namespace(usersApi.namespace);
import { api as userApi, Actions as UserActions } from "@/store/modules/user";
const userModul = namespace(userApi.namespace);
import { ROUTE_NAMES } from "@/routenames";
import { watchDbVersion } from "./utils/watchDbVersion";
import { AppSetupData } from "./types/initApp";
import {
  DocumentNames,
  FieldPartNames,
} from "@auditcloud/shared/lib/types/common";
import { isString } from "lodash";
import { updatePwa } from "./utils/pwa";

@Component({
  components: {
    ALoadingSpinner,
    StatusMessage,
    MenuPanel,
    NavigationPanel,
    NavigationPanelRight,
    ANotificationToast,
    Signin,
    AFeatureNotAvailableDialog,
    AMaintenanceSoftwareUpdateDialog,
    AMaintenanceOngoingInfo,
    AMaintenanceErrorInfo,
  },
})
export default class App extends Vue {
  firebaseApp: null | firebase.app.App = null;
  setupApp(data: AppSetupData) {
    this.firebaseApp = data.firebaseApp;
  }
  dbVersions: { expected: number; actual: number } | null = null;
  systemError: string | null = null;

  updateConfirmed: boolean = false;
  updateDismissed: boolean = false;
  waitingServiceWorker: ServiceWorker | null = null;

  initState: string = "uninitialized";
  unsubscribe: null | firebase.Unsubscribe = null;
  unsubscribeSystemObserver: null | firebase.Unsubscribe = null;

  @State((state: RootState) => state.loadingMessage) loadingMessage!:
    | null
    | string;
  @appModul.Getter(appApi.getters.getCurrentLanguage)
  getCurrentLanguage!: string;

  @appModul.Getter(appApi.getters.getFooterComponent)
  footerComponent!: string;

  @appModul.Getter(appApi.getters.getFeatureDisabledDialog)
  featureDisabledDialog!: boolean;

  @appModul.Mutation(appApi.mutations.SET_FEATURE_DISABLED_DIALOG)
  setFeatureDisabledDialog!: (val: boolean) => void;

  @Mutation(api.mutations.SET_LOADING_MESSAGE) setLoadingMsg!: (
    v: string | null
  ) => void;

  @confModule.Mutation(confApi.mutations.updateConfiguration)
  updateConfiguration!: (payload: { segment: string; data: any }) => void;

  @Getter(confApi.getters.getGoogleTagManagerEnabled, {
    namespace: confApi.namespace,
  })
  googleTagManagerEnabled!: boolean;

  @userModul.Action(userApi.actions.listenToTokenChanges)
  listenToTokenChanges!: UserActions["listenToTokenChanges"];

  @usersModul.Action(usersApi.actions.loadUsersByApi)
  loadAutoCompleteUsers!: () => Promise<void>;

  @Watch("getCurrentLanguage", { deep: true, immediate: true })
  onLocaleChanged(newVal: string) {
    this.$i18n.locale = newVal;
    moment.locale(this.$i18n.locale);
  }

  get dbVersionValid() {
    if (this.dbVersions == null) {
      return null;
    }
    return this.dbVersions.expected === this.dbVersions.actual;
  }

  created() {
    document.addEventListener(
      "swUpdated",
      (event: Event) => {
        if (this.updateConfirmed) {
          return;
        }
        const detail = (event as CustomEvent<ServiceWorkerRegistration>).detail;
        this.waitingServiceWorker = detail.waiting;
      },
      { once: true }
    );
    navigator.serviceWorker.addEventListener(
      "controllerchange",
      () => {
        window.location.reload();
      },
      { once: true }
    );
    this.init();
  }

  @Watch("googleTagManagerEnabled", { immediate: true })
  onGoogleTagManagerEnabledChanged(newVal: boolean) {
    if (
      ((window.GTM_ID?.trim().length ?? 0) !== 0 ||
        this.$gtm?.debugEnabled()) &&
      newVal !== this.$gtm.enabled()
    ) {
      this.$gtm.enable(newVal);
    }
  }

  refreshAppAfterUpdate() {
    this.updateConfirmed = true;
    this.waitingServiceWorker?.postMessage("skipWaiting");
  }

  async refreshAppManually() {
    this.updateConfirmed = true;
    const newWorker = await updatePwa("RELOAD", "App:refreshManually");
    if (!newWorker) {
      window.location.reload();
    }
  }

  mounted() {
    this.$i18n.locale = this.getCurrentLanguage;
    moment.locale(this.$i18n.locale);
  }

  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    if (this.unsubscribeSystemObserver) {
      this.unsubscribeSystemObserver();
    }
  }

  async init() {
    try {
      const asyncState: {
        resolve: null | (() => void);
        reject: null | ((reason?: any) => void);
        lastDate: null | string;
      } = {
        resolve: null,
        reject: null,
        lastDate: null,
      };

      const waitForDoc = new Promise<void>((resolve, reject) => {
        asyncState.resolve = resolve;
        asyncState.reject = reject;
      });

      this.unsubscribeSystemObserver = watchDbVersion(
        async ({ expected, actual }, doc) => {
          this.updateConfiguration({
            segment: DocumentNames.CONFIG_SYSTEM,
            data: doc,
          });
          const lastDate = doc[FieldPartNames.SYSTEM_USER_DUMP_DATE];
          if (isString(lastDate) && asyncState.lastDate !== lastDate) {
            asyncState.lastDate = lastDate;
            this.loadAutoCompleteUsers().catch(err => {
              console.error(err);
            });
          }

          if (expected !== actual) {
            const worker = await updatePwa("FETCH_ONLY", "App:dbVersions");
            if (worker) {
              this.waitingServiceWorker = worker;
            }
          }
          this.dbVersions = { expected, actual };

          if (asyncState.resolve) {
            asyncState.resolve();
          }
        },
        err => {
          this.systemError = err;
          console.error(err);
          if (asyncState.reject) {
            asyncState.reject(err);
          }
        }
      );
      await waitForDoc; // Ensure that updateConfiguration is called
      asyncState.resolve = null;
      asyncState.reject = null;

      this.setLoadingMsg("validate user ...");
      // Check user login
      this.unsubscribe = firebase
        .auth()
        .onAuthStateChanged(this.onAuthStateChanged);
      this.listenToTokenChanges();
    } catch (error) {
      this.systemError = "init firebase failed, please reload the page";
      console.error(this.systemError, error);
    }
  }
  onAuthStateChanged(user: firebase.User | null) {
    if (user) {
      this.$store.commit(
        api.mutations.SET_LOADING_MESSAGE,
        "loading core data ..."
      );

      this.$store
        .dispatch(api.actions.initAppCoreData, user)
        .then(() => {
          this.$store.commit(
            api.mutations.SET_LOADING_MESSAGE,
            "loading core data succeeded"
          );
          this.initState = "initialized";
          if (
            this.$route.name === ROUTE_NAMES.SIGNIN ||
            this.$route.name === ROUTE_NAMES.RESETPASSWORD
          ) {
            this.$router.push({ name: ROUTE_NAMES.HOME });
          }
        })
        .catch((err: any) => {
          this.systemError = "Loading core data failed!";
          this.$store.commit(
            api.mutations.SET_LOADING_MESSAGE,
            this.systemError
          );
          console.error("initAppCoreData:failed", err);
        });
    } else if (
      this.$route.name !== ROUTE_NAMES.SIGNIN &&
      this.$route.name !== ROUTE_NAMES.RESETPASSWORD
    ) {
      this.$router.push({
        name: ROUTE_NAMES.SIGNIN,
        query: {
          redirect: this.$route.fullPath,
        },
      });
      this.initState = "unauthorized";
    } else if (
      user === null &&
      (this.$route.name === ROUTE_NAMES.SIGNIN ||
        this.$route.name === ROUTE_NAMES.RESETPASSWORD)
    ) {
      this.initState = "unauthorized";
    }
  }
  share() {
    if (
      "share" in navigator &&
      typeof (navigator as { share: any }).share === "function"
    ) {
      const url = document.location.href;
      (
        navigator as {
          share: (data: { url: string; text?: string; title?: string }) => void;
        }
      ).share({ url });
    }
  }
  goback() {
    this.$router.go(-1);
  }
}
