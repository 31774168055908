


























/* tslint:disable:no-console */
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";

import {
  api as auditsApi,
  Actions as AuditsActions,
  Mutations as AuditMutations,
} from "@/store/modules/audits";
import { api as appApi } from "@/store/modules/app";
import { api as confApi } from "@/store/modules/configuration";
import { ROUTE_NAMES, dialogRoute, DIALOG_NAMES } from "@/routenames";
import {
  TodoActionSignature,
  TodoAny,
} from "@auditcloud/shared/lib/utils/type-guards";
import {
  AuditProgramConfig,
  OrganizationUnitConfig,
} from "@auditcloud/shared/lib/schemas";
import { Dictionary } from "lodash";

@Component({
  components: {},
})
export default class AAudits extends Vue {
  unlistener: any = null;

  @Prop({
    default: null,
    type: String,
  })
  year!: string | null;

  @Prop({
    default: null,
    type: String,
  })
  auditProgramId!: string | null;

  @Prop({
    default: null,
    type: String,
  })
  organizationUnitId!: string | null;

  @Getter(confApi.getters.getMappedAuditPrograms, {
    namespace: confApi.namespace,
  })
  mappedAuditPrograms!: Dictionary<AuditProgramConfig>;

  @Getter(confApi.getters.getMappedOrganizationUnits, {
    namespace: confApi.namespace,
  })
  mappedOrganizationUnits!: Dictionary<OrganizationUnitConfig>;

  @Getter(confApi.getters.getIsAuditCreationAllowed, {
    namespace: confApi.namespace,
  })
  isAuditCreationAllowed!: boolean;

  @Action(auditsApi.actions.loadAudits, { namespace: auditsApi.namespace })
  loadAudits!: AuditsActions["loadAudits"];

  @Action(auditsApi.actions.clearAudits, { namespace: auditsApi.namespace })
  clearAudits!: AuditsActions["clearAudits"];

  @Action(appApi.actions.setMenuPanel, { namespace: appApi.namespace })
  setMenuPanel!: TodoActionSignature;

  @Action(appApi.actions.setBreadCrumb, { namespace: appApi.namespace })
  setBreadCrumb!: TodoActionSignature;

  @Mutation(auditsApi.mutations.SET_SELECTED_PROGRAM, {
    namespace: auditsApi.namespace,
  })
  setProgram!: AuditMutations["SET_SELECTED_PROGRAM"];

  @Mutation(auditsApi.mutations.SET_SELECTED_ORGANIZATION_UNIT, {
    namespace: auditsApi.namespace,
  })
  setOrganizationUnit!: AuditMutations["SET_SELECTED_ORGANIZATION_UNIT"];

  @Getter(appApi.getters.getCurrentLanguage, { namespace: appApi.namespace })
  currentLanguage!: TodoAny;

  @Watch("currentLanguage", { immediate: true, deep: true })
  onLaguageChange() {
    this.updateBreadCrumbsWithYear();
  }

  openCreateDialog() {
    this.$router.push({
      name: dialogRoute(
        this.$route.name ?? ROUTE_NAMES.AUDITMATRIX,
        DIALOG_NAMES.AUDITMATRIX_CREATE
      ),
      params: this.$route.params,
    });
  }

  @Watch("year", { immediate: true })
  onYearChanged(newVal: string | null) {
    const year = parseInt(newVal ?? "", 10);
    this.yearChanged(isNaN(year) ? null : year);
    this.updateBreadCrumbs(
      newVal,
      this.organizationUnitId,
      this.auditProgramId
    );
  }

  @Watch("auditProgramId", { immediate: true })
  onAuditProgramIdChanged(newVal: string | null) {
    this.setProgram(newVal);
    this.updateBreadCrumbs(this.year, this.organizationUnitId, newVal);
  }

  get auditProgramName() {
    const auditProgram = this.mappedAuditPrograms[this.auditProgramId ?? ""];
    if (auditProgram) {
      return this.$ft(auditProgram.name);
    } else {
      return "Unknown";
    }
  }

  @Watch("organizationUnitId", { immediate: true })
  onOrganizationUnitIdChanged(newVal: string | null) {
    this.setOrganizationUnit(newVal);
    this.updateBreadCrumbs(this.year, newVal, this.auditProgramId);
  }

  get organizationUnitName() {
    const organizationUnit =
      this.mappedOrganizationUnits[this.organizationUnitId ?? ""];
    if (organizationUnit) {
      return this.$ft(organizationUnit.name);
    } else {
      return "Unknown";
    }
  }

  updateBreadCrumbsWithYear() {
    this.updateBreadCrumbs(
      this.year,
      this.organizationUnitId,
      this.auditProgramId
    );
  }

  @Getter(confApi.getters.hasAuditdProgramGrid, {
    namespace: confApi.namespace,
  })
  hasAuditProgramGrid!: boolean;

  updateBreadCrumbs(
    year: string | null,
    organizationUnitId: string | null,
    auditProgramId: string | null
  ) {
    organizationUnitId =
      organizationUnitId === "" ? null : this.organizationUnitId;
    const currentBreadCrumbs: any[] = [];
    currentBreadCrumbs.push({
      text: this.$t("views.audit_matrix.home"),
      to: {
        name: ROUTE_NAMES.HOME,
      },
      activeclass: "",
    });

    if (this.hasAuditProgramGrid) {
      currentBreadCrumbs.push({
        text: this.$t("sitemap.audit_programm"),
        to: {
          name: ROUTE_NAMES.AUDIT_PROGRAMS,
        },
        activeclass: "",
      });
    } else {
      currentBreadCrumbs.push({
        text: this.$t("sitemap.audit_programm"),
        to: {
          name: ROUTE_NAMES.AUDITMATRIX,
        },
        activeclass: "",
      });
    }

    if (this.organizationUnitId !== null) {
      currentBreadCrumbs.push({
        text: this.organizationUnitName,
        to: {
          name: ROUTE_NAMES.AUDIT_PROGRAMS,
          params: {
            organizationUnitId: this.organizationUnitId,
          },
        },
        activeclass: "",
      });
    }

    if (this.auditProgramId !== null) {
      const params: {
        auditProgramId: string;
        organizationUnitId?: string;
      } = {
        auditProgramId: this.auditProgramId,
      };
      if (this.organizationUnitId) {
        params.organizationUnitId = this.organizationUnitId;
      }

      currentBreadCrumbs.push({
        text: this.auditProgramName,
        to: {
          name: this.organizationUnitId
            ? ROUTE_NAMES.AUDITMATRIX_UNIT_PROGRAM_FILTERED
            : ROUTE_NAMES.AUDITMATRIX_PROGRAM_FILTERED,
          params,
        },
        activeclass: "",
      });
    }

    if (this.year !== null) {
      const params: {
        year: string;
        auditProgramId?: string;
        organizationUnitId?: string;
      } = {
        year: this.year,
      };
      if (this.auditProgramId) {
        params.auditProgramId = this.auditProgramId;
      }
      if (this.organizationUnitId) {
        params.organizationUnitId = this.organizationUnitId;
      }

      const name = this.auditProgramName
        ? ROUTE_NAMES.AUDITMATRIX_PROGRAM_FILTERED
        : ROUTE_NAMES.AUDITMATRIX_YEAR_FILTERED;

      // the Retranslation from the named route doesn't work
      // cause of that build path by hand
      const path = `/audits${
        this.organizationUnitId ? `/-orgs/${this.organizationUnitId}` : ""
      }${this.auditProgramId ? `/${this.auditProgramId}` : ""}/${this.year}`;

      currentBreadCrumbs.push({
        text: this.year,
        to: path,
        activeclass: "",
      });
    }

    this.setBreadCrumb(currentBreadCrumbs);
    this.setMenuPanel();
  }

  yearChanged(year: number | null) {
    this.loadAudits({
      year: year,
      member: null,
    }).then(u => {
      this.unlistener = u;
    });
  }

  beforeDestroy() {
    this.clearAudits(this.unlistener);
  }
}
