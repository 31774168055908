import firebase from "firebase/compat/app";
import { TodoAny } from "@auditcloud/shared/lib/utils/type-guards";

export class Dispatcher {
  static async invokeAsyncDelete({
    ref,
    doc,
    payload,
    commit,
    mutation,
  }: TodoAny) {
    return new Promise((resolve, reject) => {
      const current_ref = ref;
      const current_doc = doc || null;

      console.log("Delete Dispatcher");

      // TODO: Hier werden die gesamten Subcollections gerade nicht mitgelöscht!!!

      firebase
        .firestore()
        .collection(current_ref)
        .doc(current_doc)
        .delete()
        .then(() => {})
        .catch(error => {
          console.log(error);
          reject(error);
        });

      if (mutation) {
        commit(mutation, { ...payload });
      }
      //dispatch("activities/createActivity", activity, { root: true });

      resolve("success"); // pass values
    });
  }

  static async invokeAsyncSet({
    ref,
    doc,
    child,
    payload,
    commit,
    mutation,
  }: TodoAny) {
    return new Promise((resolve, reject) => {
      const db = firebase.firestore();
      const current_ref = ref;
      const current_doc = doc || null;
      const current_child = child || null;
      const current_data = payload.data;

      console.log("Update DISPATCHER, Doc", current_doc);

      let obj: TodoAny = {};

      if (current_child) {
        obj[current_child] = current_data;
      } else {
        obj = current_data;
      }

      db.collection(current_ref)
        .doc(current_doc)
        .set(obj, { merge: true })
        .then(() => {
          console.log("Firestore-Dispatcher - synchronized");
        })
        .catch(error => {
          console.log("Firestore-Dispatcher - Error");
          console.log(error);
          reject(error);
        });
      if (mutation) {
        commit(mutation, { ...payload });
      }
      resolve("success");
    });
  }

  static async invokeAsyncUpdate({
    ref,
    doc,
    child,
    payload,
    commit,
    mutation,
  }: TodoAny) {
    return new Promise((resolve, reject) => {
      const db = firebase.firestore();
      const current_ref = ref;
      const current_doc = doc || null;
      const current_child = child || null;
      const current_data = payload.data;

      console.log("Update DISPATCHER, Doc", current_doc);

      let obj: TodoAny = {};

      if (current_child) {
        obj[current_child] = current_data;
      } else {
        obj = current_data;
      }

      db.collection(current_ref)
        .doc(current_doc)
        .update(obj)
        .then(() => {
          console.log("Firestore-Dispatcher - synchronized");
        })
        .catch(error => {
          console.log("Firestore-Dispatcher - Error");
          console.log(error);
          reject(error);
        });
      if (mutation) {
        commit(mutation, { ...payload });
      }
      resolve("success");
    });
  }

  static async invokeAsyncCreate({
    ref,
    doc,
    payload,
    commit,
    mutation,
  }: TodoAny) {
    return new Promise((resolve, reject) => {
      const db = firebase.firestore();

      let firebasePushRef = doc
        ? db.collection(ref).doc(doc)
        : db.collection(ref).doc();

      const firebaseUID = firebasePushRef.id;

      firebasePushRef
        .set(payload.data)
        .then(() => {
          console.log("Firestore-Dispatcher sucessful");
        })
        .catch(error => {
          console.log("Firestore-Dispatcher error");
          console.log(error);
          reject(error);
        });

      if (mutation) {
        commit(mutation, { ...payload, item_id: firebaseUID });
      }

      resolve(firebaseUID);
    });
  }

  static async createAsync({ ref, doc, payload, commit, mutation }: TodoAny) {
    return await Dispatcher.invokeAsyncCreate({
      ref,
      doc,
      payload,
      commit,
      mutation,
    });
  }

  static async setAsync({
    ref,
    doc,
    child,
    payload,
    commit,
    mutation,
  }: TodoAny) {
    return await Dispatcher.invokeAsyncSet({
      ref,
      doc,
      child,
      payload,
      commit,
      mutation,
    });
  }

  static async updateAsync({
    ref,
    doc,
    child,
    payload,
    commit,
    mutation,
  }: TodoAny) {
    return await Dispatcher.invokeAsyncUpdate({
      ref,
      doc,
      child,
      payload,
      commit,
      mutation,
    });
  }

  static async deleteAsync({
    ref,
    doc,
    child,
    payload,
    commit,
    mutation,
  }: TodoAny) {
    return await Dispatcher.invokeAsyncDelete({
      ref,
      doc,
      child,
      payload,
      commit,
      mutation,
    });
  }
}
