










import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";

@Component({
  inheritAttrs: false,
})
export default class ANumberInput extends Vue {
  @Prop({
    type: Number,
    default: 0,
  })
  value!: number;

  onInput(value: string) {
    this.$emit("input", parseFloat(value));
  }

  created() {
    console.log("ANumberInput", this.$attrs);
  }
}
