/* tslint:disable:no-console */
// TODO: Altlast?
/*export const Routes = {
  Root: "/",
  SignIn: "/login",
  ResetPassword: "/reset",
  NewPassword: "/new-password",
  Audits: {
    List: "/audits"
  }
};*/
import Vue, { ComponentOptions, AsyncComponent } from "vue";
import VueRouter, { Route, RouteConfig, RawLocation } from "vue-router";
import {
  ROUTE_NAMES,
  DIALOG_NAMES,
  dialogRoute,
  SIGNIN_PATH,
} from "./routenames";

//import Home from "./views/Home.vue";
import ADashboard from "./views/ADashboard.vue";
import TemplateMatrix from "./views/TemplateMatrix.vue";
import Template from "./views/Template.vue";

import AAudits from "./views/AAudits.vue";
import AAuditResultChartTest from "./views/AAuditResultChartTest.vue";
import AAudit from "./views/AAudit.vue";
import AMeasureProcessMatrix from "./views/AMeasureProcessMatrix.vue";
import AMeasureProcessDialog from "./components/dialogs/AMeasureProcessDialog.vue";
import AAuditItemImportDialog from "./components/dialogs/AAuditItemImportDialog.vue";

const AAuditProgramGrid = () =>
  import("./components/widgets/AAuditProgramGrid.vue");
const AAuditsTable = () => import("./components/widgets/AAuditsTable.vue");

/*
const AuditPreparation = () => ({
  // The component to load (should be a Promise)
  component: import("./components/widgets/AuditPreparation.vue"),
  // A component to use while the async component is loading
  loading: Signin,
  // A component to use if the load fails
  error: Signin,
  // Delay before showing the loading component. Default: 200ms.
  delay: 200,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000
}); */
const AAuditPlanning = () => import("./views/AAuditPlanning.vue");
const AuditPreparation = () => import("./views/AAuditPreparation.vue");
const AuditExecution = () => import("./views/AAuditExecution.vue");
const AuditWrapUp = () => import("./views/AAuditWrapup.vue");
const AuditReporting = () => import("./views/AAuditReporting.vue");
const AuditActionlist = () => import("./views/AAuditActionlist.vue");
const AAuditCompleted = () => import("./views/AAuditCompleted.vue");

const AUserManagement = () => import("./views/AUserManagement.vue");
const ASchemaFormEditor = () => import("./views/ASchemaFormEditor.vue");
const AUserManagementWelcome = () =>
  import("./components/usermanagement/Welcome.vue");
const AUserManagementUsers = () =>
  import("./components/usermanagement/AUsers.vue");
const AUserManagementUserDetails = () =>
  import("./components/usermanagement/AUserDetails.vue");
const AUserManagementPersons = () =>
  import("./components/usermanagement/Persons.vue");
const AUserManagementRoles = () =>
  import("./components/usermanagement/Roles.vue");
const AUserManagementGroups = () =>
  import("./components/usermanagement/Groups.vue");
// TODO ACS-1337: I would propose to put that component into the components folder
const AUserCreationView = () => import("./views/AUserCreationView.vue");
const AManagementSummary = () => import("./views/AManagementSummary.vue");

import Standards from "./views/Standards.vue";
import Standard from "./views/Standard.vue";
import Measures from "./views/Measures.vue";

import AuditMeasureDialog from "./components/dialogs/AuditMeasureDialog.vue";
import AuditMetadataDialog from "./components/dialogs/AuditMetadataDialog.vue";
import TemplateItemDialog from "./components/dialogs/TemplateItemDialog.vue";
import ATemplateCreateDialog from "./components/dialogs/ATemplateCreateDialog.vue";
import ATemplateMetadataDialog from "./components/dialogs/ATemplateMetadataDialog.vue";
import AuditCreateDialog from "./components/dialogs/AuditCreateDialog.vue";
import AuditInfoDialog from "@/components/dialogs/AuditInfoDialog.vue";
import AuditReportingDialog from "@/components/dialogs/AuditReportingDialog.vue";
import AuditFindingDialog from "@/components/dialogs/AuditFindingDialog.vue";
import AuditIntermediateResultDialog from "@/components/dialogs/AuditIntermediateResultDialog.vue";
import AuditMeasuresCreateDialog from "@/components/dialogs/AuditMeasuresCreateDialog.vue";

import AConfigEditor from "./components/widgets/AConfigEditor.vue";
import AConfigEditorAudit from "./components/widgets/AConfigEditorAudit.vue";
import AConfigEditorLists from "./components/widgets/AConfigEditorLists.vue";
import AConfigEditorInternals from "./components/widgets/AConfigEditorInternals.vue";

import AAuditClassEditor from "./components/widgets/AAuditClassEditor.vue";
import AFormSchemaEditor from "./components/widgets/AFormSchemaEditor.vue";
import ATranslationTable from "./components/widgets/ATranslationTable.vue";
import AEmailTemplateEditor from "./components/widgets/AEmailTemplateEditor.vue";
import ACustomMetadataEditor from "./components/widgets/ACustomMetadataEditor.vue";

import Signin from "./views/Signin.vue";
import ResetPassword from "./views/ResetPassword.vue";
import Settings from "./views/Settings.vue";
import CameraView from "./components/controls/CameraView.vue";

import Configuration from "./views/Configuration.vue";
import ASanityCheck from "./views/ASanityCheck.vue";
import { isFeatureEnabled } from "./plugins/FeatureFlags";
import { flatten, omit } from "lodash";
import { Dictionary } from "vue-router/types/router";
import { SystemRoles } from "@auditcloud/shared/lib/constants/roles";
import VueGtm from "@gtm-support/vue2-gtm";
import PageNotFound from "./views/PageNotFound.vue";

export interface RouteMetadataAccessControl {
  requiredRoles: string[];
  anonymous: boolean;
  authorized: boolean;
}

const anyAccess: RouteMetadataAccessControl = {
  anonymous: true,
  authorized: true,
  requiredRoles: [],
};
const adminAccess: RouteMetadataAccessControl = {
  anonymous: false,
  authorized: true,
  requiredRoles: [SystemRoles.USER, SystemRoles.ADMINISTRATOR],
};

const sysAdminAccess: RouteMetadataAccessControl = {
  anonymous: false,
  authorized: true,
  requiredRoles: [SystemRoles.USER, SystemRoles.SYSTEM_ADMINISTRATOR],
};

// const unauthorizedOnlyAccess = { anonymous: true, authorized: false };
type Component = ComponentOptions<Vue> | typeof Vue | AsyncComponent;
interface AuditDialogRoute {
  p: string;
  n: string;
  c: Component;
}

const createAuditDialogConfig: AuditDialogRoute = {
  p: "create",
  n: DIALOG_NAMES.AUDITMATRIX_CREATE,
  c: AuditCreateDialog,
};

const editAuditDialogConfig: AuditDialogRoute = {
  p: "edit/:auditId",
  n: DIALOG_NAMES.AUDITMATRIX_EDIT,
  c: AuditMetadataDialog,
};

const auditInfoDialogConfig: AuditDialogRoute = {
  p: "metadata",
  n: DIALOG_NAMES.AUDIT_INFO_DIALOG,
  c: AuditInfoDialog,
};

const auditReportingDialogConfig: AuditDialogRoute = {
  p: "reporting",
  n: DIALOG_NAMES.REPORTING_DIALOG,
  c: AuditReportingDialog,
};

const auditIntermediateResultDialogConfig: AuditDialogRoute = {
  p: "result",
  n: DIALOG_NAMES.INTERMEDIATE_RESULT_DIALOG,
  c: AuditIntermediateResultDialog,
};

const auditMeasuresCreateDialogConfig: AuditDialogRoute = {
  p: "measures",
  n: DIALOG_NAMES.MEASURES_CREATE_DIALOG,
  c: AuditMeasuresCreateDialog,
};

const auditFindingDialogConfig: AuditDialogRoute = {
  p: "finding/:findingId?/-/:auditItemId?",
  n: DIALOG_NAMES.FINDING,
  c: AuditFindingDialog,
};

interface AuditSubRoute {
  p: string;
  n: string;
  c: Component;
  d: AuditDialogRoute[];
}

const auditSubRoutesConfig: AuditSubRoute[] = [
  {
    p: "planning",
    n: ROUTE_NAMES.AUDITPLANNING,
    c: AAuditPlanning,
    d: [auditInfoDialogConfig, auditReportingDialogConfig],
  },
  {
    p: "preparation",
    n: ROUTE_NAMES.AUDITPREPARATION,
    c: AuditPreparation,
    d: [auditInfoDialogConfig, auditReportingDialogConfig],
  },
  {
    p: "execution",
    n: ROUTE_NAMES.AUDITEXECUTION,
    c: AuditExecution,
    d: [
      auditInfoDialogConfig,
      auditFindingDialogConfig,
      auditReportingDialogConfig,
      auditIntermediateResultDialogConfig,
    ],
  },
  {
    p: "wrapup",
    n: ROUTE_NAMES.AUDITWRAPUP,
    c: AuditWrapUp,
    d: [
      auditInfoDialogConfig,
      auditReportingDialogConfig,
      auditFindingDialogConfig,
      auditIntermediateResultDialogConfig,
      auditMeasuresCreateDialogConfig,
      {
        p: "measure/:measureId/:action/:auditItemId?/:findingId?",
        n: DIALOG_NAMES.MEASURE,
        c: AuditMeasureDialog,
      },
    ],
  },
  {
    p: "reporting",
    n: ROUTE_NAMES.AUDITREPORTING,
    c: AuditReporting,
    d: [
      auditInfoDialogConfig,
      auditReportingDialogConfig,
      auditFindingDialogConfig,
      auditMeasuresCreateDialogConfig,
    ],
  },
  {
    p: "actionlist",
    n: ROUTE_NAMES.AUDITACTIONLIST,
    c: AuditActionlist,
    d: [
      auditInfoDialogConfig,
      {
        p: "measure/:measureId",
        n: DIALOG_NAMES.MEASURE,
        c: AuditMeasureDialog,
      },
    ],
  },
  {
    p: "completed",
    n: ROUTE_NAMES.AUDITFINALIZED,
    c: AAuditCompleted,
    d: [
      auditInfoDialogConfig,
      {
        p: "measure/:measureId",
        n: DIALOG_NAMES.MEASURE,
        c: AuditMeasureDialog,
      },
    ],
  },
];

function subrouteConfig2RouteConfig(
  config: AuditSubRoute,
  nestedRouteName: string = "auditDialogs",
  hasWatcherBaseDialogs: boolean = true,
  props: boolean | ((r: Route) => Dictionary<any>) = true
): RouteConfig[] {
  const routes: RouteConfig[] = config.d.map(auditStateDialogRoute => {
    return {
      props: { default: props, [nestedRouteName]: props },
      path:
        config.p === ""
          ? auditStateDialogRoute.p
          : `${config.p}/${auditStateDialogRoute.p}`,
      name: dialogRoute(config.n, auditStateDialogRoute.n),
      components: {
        default: config.c,
        [nestedRouteName]: auditStateDialogRoute.c,
      },
    };
  });
  routes.push({
    props: props,
    path: hasWatcherBaseDialogs ? `${config.p}/:dialogName?` : config.p ?? "",
    name: config.n,
    component: config.c,
  });

  return routes;
}

const auditSubRoutes: RouteConfig[] = flatten(
  auditSubRoutesConfig.map(cfg => subrouteConfig2RouteConfig(cfg))
);

function extractAuditMatrixProps(route: Route) {
  const { year } = route.params;

  if (!/^\d+$/.test(year)) {
    return omit(route.params, ["year"]);
  } else {
    return route.params;
  }
}

function extractAuditProgramProps(route: Route) {
  const organizationUnitId = route.params["organizationUnitId"] ?? null;
  const year = String(new Date().getFullYear());
  return {
    year,
    organizationUnitId,
  };
}

const auditmatrixConfig: AuditSubRoute[] = [
  {
    p: "-orgs/:organizationUnitId/:year(\\d+|all)",
    n: ROUTE_NAMES.AUDITMATRIX_UNIT_YEAR_FILTERED,
    c: AAuditsTable,
    d: [createAuditDialogConfig, editAuditDialogConfig],
  },

  {
    p: "-orgs/:organizationUnitId/:auditProgramId(\\D+)/:year(\\d+|all)",
    n: ROUTE_NAMES.AUDITMATRIX_UNIT_PROGRAM_FILTERED,
    c: AAuditsTable,
    d: [createAuditDialogConfig, editAuditDialogConfig],
  },
  {
    p: ":year(\\d+|all)",
    n: ROUTE_NAMES.AUDITMATRIX_YEAR_FILTERED,
    c: AAuditsTable,
    d: [createAuditDialogConfig, editAuditDialogConfig],
  },

  {
    p: ":auditProgramId(\\D+)/:year(\\d+|all)",
    n: ROUTE_NAMES.AUDITMATRIX_PROGRAM_FILTERED,
    c: AAuditsTable,
    d: [createAuditDialogConfig, editAuditDialogConfig],
  },
  {
    p: "",
    n: ROUTE_NAMES.AUDITMATRIX,
    c: AAuditsTable,
    d: [createAuditDialogConfig, editAuditDialogConfig],
  },
];

const matrixPlainSubRoutes: RouteConfig[] = flatten(
  auditmatrixConfig.map(cfg =>
    subrouteConfig2RouteConfig(cfg, "dialog", false, extractAuditMatrixProps)
  )
);

const auditProgramConfig: AuditSubRoute[] = [
  {
    p: ":organizationUnitId?",
    n: ROUTE_NAMES.AUDIT_PROGRAMS,
    c: AAuditProgramGrid,
    d: [createAuditDialogConfig],
  },
];

const auditProgramPlainSubRoutes: RouteConfig[] = flatten(
  auditProgramConfig.map(cfg =>
    subrouteConfig2RouteConfig(cfg, "dialog", false, extractAuditProgramProps)
  )
);

console.log("MatrixSubroutes", matrixPlainSubRoutes);

const routes: RouteConfig[] = [
  {
    path: "/",
    name: ROUTE_NAMES.HOME,
    component: ADashboard,
    children: [
      {
        path: "create/audit",
        name: ROUTE_NAMES.HOME_CREATE_AUDIT,
        component: AuditCreateDialog,
        props: true,
      },
      {
        path: "view/:auditId",
        name: ROUTE_NAMES.HOME_VIEW_AUDIT,
        component: AuditMetadataDialog,
        props: true,
      },
    ],
  },
  {
    path: "/dashboard/:dashboardId?",
    name: ROUTE_NAMES.DASHBOARDSCOPED,
    component: ADashboard,
    props: true,
    children: [
      {
        path: "create/audit",
        name: ROUTE_NAMES.DASHBOARDSCOPED_CREATE_AUDIT,
        component: AuditCreateDialog,
        props: true,
      },
      {
        path: "view/:auditId",
        name: ROUTE_NAMES.DASHBOARDSCOPED_VIEW_AUDIT,
        component: AuditMetadataDialog,
        props: true,
      },
      {
        props: true,
        path: "edit/measure/:measureId",
        name: ROUTE_NAMES.DASHBOARDSCOPED_EDIT_MEASURE,
        component: AuditMeasureDialog,
      },
    ],
  },
  {
    path: "/audit-result-chart-test",
    component: AAuditResultChartTest,
  },
  {
    path: "/audits",
    // name: ROUTE_NAMES.AUDITMATRIX,
    component: AAudits,
    props: extractAuditMatrixProps,
    children: [...matrixPlainSubRoutes],
  },
  {
    path: "/audit-programs",
    component: AAudits,
    props: extractAuditProgramProps,
    children: [...auditProgramPlainSubRoutes],
  },
  ...(() => {
    if (isFeatureEnabled("featureMeasureProcess")) {
      return [
        {
          path: "/measureprocesses",
          name: ROUTE_NAMES.MEASUREPROCESSMATRIX,
          component: AMeasureProcessMatrix,
          props: true,
          children: [
            {
              props: true,
              path: "measure/:measureProcessId",
              name: ROUTE_NAMES.MEASUREPROCESSDIALOG,
              component: AMeasureProcessDialog,
            },
          ],
        },
      ];
    } else {
      return [];
    }
  })(),
  {
    path: "/audit/:auditId",
    name: ROUTE_NAMES.AUDIT,
    component: AAudit,
    props: true,
    children: auditSubRoutes,
  },
  {
    path: "/camera",
    name: ROUTE_NAMES.CAMERA,
    component: CameraView,
  },
  {
    path: "/templates",
    name: ROUTE_NAMES.TEMPLATES,
    component: TemplateMatrix,
    children: [
      {
        path: "import",
        name: ROUTE_NAMES.TEMPLATE_IMPORT,
        component: AAuditItemImportDialog,
      },
      {
        path: "create",
        name: ROUTE_NAMES.TEMPLATE_CREATE,
        component: ATemplateCreateDialog,
      },
      {
        props: true,
        path: "edit/:templateId",
        name: ROUTE_NAMES.TEMPLATE_METADATA_OVERVIEW,
        component: ATemplateMetadataDialog,
      },
    ],
  },
  {
    props: true,
    path: "/template/:templateId/",
    name: ROUTE_NAMES.TEMPLATE,
    component: Template,
    children: [
      {
        props: true,
        path: "metadata",
        name: ROUTE_NAMES.TEMPLATE_METADATA,
        component: ATemplateMetadataDialog,
      },
      {
        props: true,
        path: "item/:templateItemId?",
        name: ROUTE_NAMES.TEMPLATE_ITEM_DIALOG,
        component: TemplateItemDialog,
      },
    ],
  },
  {
    props: true,
    path: "/template/:templateId/:dialogName",
    name: ROUTE_NAMES.TEMPLATE_DIALOG,
    component: Template,
  },
  {
    path: "/standards",
    name: ROUTE_NAMES.STANDARDS,
    component: Standards,
  },
  {
    path: "/measures",
    name: ROUTE_NAMES.MEASURES,
    component: Measures,
    children: [
      {
        props: true,
        path: "/measure/:measureId",
        name: ROUTE_NAMES.MEASUREDIALOG,
        component: AuditMeasureDialog,
      },
    ],
  },
  {
    props: true,
    path: "/standard/:standardId",
    name: ROUTE_NAMES.STANDARD,
    component: Standard,
  },
  {
    path: SIGNIN_PATH,
    name: ROUTE_NAMES.SIGNIN,
    component: Signin,
    meta: anyAccess,
  },
  /* DISABLE SIGNUP{
    path: "/signup",
    name: ROUTE_NAMES.SIGNUP,
    component: Signup,
    meta: anyAccess
  },*/
  {
    path: "/resetpassword",
    name: ROUTE_NAMES.RESETPASSWORD,
    component: ResetPassword,
    meta: anyAccess,
  },

  {
    path: "/management-summary",
    name: ROUTE_NAMES.MANAGEMENT_SUMMARY,
    component: AManagementSummary,
  },
  {
    path: "/settings",
    name: ROUTE_NAMES.SETTINGS,
    component: Settings,
  },
  {
    path: "/settings/user-management",
    component: AUserManagement,
    meta: adminAccess,
    children: [
      {
        path: "users",
        name: ROUTE_NAMES.USER_MANAGEMENT_USERS,
        component: AUserManagementUsers,
        meta: adminAccess,
      },
      {
        path: "users/create",
        name: ROUTE_NAMES.USER_MANAGEMENT_CREATE_USERS,
        component: AUserCreationView,
        meta: adminAccess,
      },
      {
        props: true,
        path: "users/:userId",
        name: ROUTE_NAMES.USER_MANAGEMENT_USER_DETAILS,
        component: AUserManagementUserDetails,
      },
      {
        path: "persons",
        name: ROUTE_NAMES.USER_MANAGEMENT_PERSONS,
        component: AUserManagementPersons,
        meta: adminAccess,
      },
      {
        path: "roles",
        name: ROUTE_NAMES.USER_MANAGEMENT_ROLES,
        component: AUserManagementRoles,
        meta: adminAccess,
      },
      {
        path: "groups",
        name: ROUTE_NAMES.USER_MANAGEMENT_GROUPS,
        component: AUserManagementGroups,
        meta: adminAccess,
      },
      {
        path: "",
        redirect: "users",
        /*
        Redirect Welcome-View until additional functionality in the
        user-management (e.g. Role/Group-Settings ...) is implemented
        */
        // name: ROUTE_NAMES.USER_MANAGEMENT,
        // component: AUserManagementWelcome,
        // meta: adminAccess,
      },
    ],
  },
  {
    path: "/settings/configuration",
    name: ROUTE_NAMES.CONFIGURATION,
    component: Configuration,
    props: true,
    meta: sysAdminAccess,
    children: [
      {
        props: (route: Route) => {
          return {
            segmentName: "auditClasses",
            auditClassId: route.params.auditClassId,
          };
        },
        path: "auditClasses/:auditClassId?",
        name: ROUTE_NAMES.CONFIGURATION_AUDITCLASS,
        component: AAuditClassEditor,
        meta: sysAdminAccess,
      },
      {
        props: (route: Route) => {
          return {
            segmentName: "metadata",
            metadataSetId: route.params.metadataSetId,
          };
        },
        path: "metadata/:metadataSetId?",
        name: ROUTE_NAMES.CONFIGURATION_METADATA,
        component: ACustomMetadataEditor,
        meta: sysAdminAccess,
      },
      {
        props: (route: Route) => {
          return {
            segmentName: "schema",
            formSchemaId: route.params.formSchemaId,
          };
        },
        path: "schema/:formSchemaId?",
        name: ROUTE_NAMES.CONFIGURATION_FORMSCHEMA,
        component: AFormSchemaEditor,
        meta: sysAdminAccess,
      },

      {
        props: (route: Route) => {
          return {
            segmentName: "email-template",
            mailTemplateId: route.params.mailTemplateId,
          };
        },
        path: "email-template/:mailTemplateId?",
        name: ROUTE_NAMES.CONFIGURATION_EMAIL_TEMPLATE,
        component: AEmailTemplateEditor,
        meta: sysAdminAccess,
      },
      {
        props: true,
        path: "i18n",
        name: ROUTE_NAMES.CONFIGURATION_I18N,
        component: ATranslationTable,
        meta: sysAdminAccess,
      },
      {
        props: true,
        path: "audit",
        name: ROUTE_NAMES.CONFIGURATION_AUDIT,
        component: AConfigEditorAudit,
        meta: sysAdminAccess,
      },
      {
        props: true,
        path: "lists",
        name: ROUTE_NAMES.CONFIGURATION_LISTS,
        component: AConfigEditorLists,
        meta: sysAdminAccess,
      },
      {
        props: true,
        path: "internals",
        name: ROUTE_NAMES.CONFIGURATION_PRIVATES,
        component: AConfigEditorInternals,
        meta: sysAdminAccess,
      },
      {
        props: true,
        path: ":segmentName",
        name: ROUTE_NAMES.CONFIGURATION_SEGMENT,
        component: AConfigEditor,
        meta: sysAdminAccess,
      },
    ],
  },
  {
    path: "/settings/sanity-check",
    name: ROUTE_NAMES.SANITY_CHECK,
    component: ASanityCheck,
    props: true,
    meta: sysAdminAccess,
  },
  {
    path: "/form-edit",
    name: ROUTE_NAMES.SCHEMA_FORM_EDIT,
    component: ASchemaFormEditor,
  },
  { path: "/404", component: PageNotFound },
  { path: "*", redirect: "/404" },
];

// suppress navigation duplicated.
// see https://stackoverflow.com/questions/57837758/navigationduplicated-navigating-to-current-location-search-is-not-allowed
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  // @ts-expect-error: Property 'catch' does not exist on type 'void'. ts(2339)
  // compiles & supresses error
  return originalPush.call(this, location).catch(error => {
    if (error.name !== "NavigationDuplicated") {
      throw error;
    }
  });
};
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_ROUTER_BASE_URL,
  routes,
  linkActiveClass: "is-active",
});

if (window.GTM_ID && window.GTM_ID.trim().length !== 0) {
  console.log("GTM_ID is set.");
  Vue.use(VueGtm, {
    id: window.GTM_ID.trim(), // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    /*queryParams: {
      // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
      gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
      gtm_preview: 'env-4',
      gtm_cookies_win: 'x'
    },*/
    queryParams: undefined, // Add url query string when load gtm.js with GTM ID (s.O.)
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    // nonce: "2726c7f26c", // Will add `nonce` to the script tag
    enabled: false, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: process.env.NODE_ENV !== "production", // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    ignoredViews: [], // Don't trigger events for specified router names (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick,
  });
}

export default router;
