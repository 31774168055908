
































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class DrawerRight extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  drawerRightVisible!: boolean;
}
