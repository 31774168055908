



























import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter } from "vuex-class";

import { VTextField, VIcon, VBtn } from "vuetify/lib";

@Component({
  components: { VTextField, VIcon, VBtn },
})
export default class AWordlist extends Vue {
  xValue: string[] = [];
  fieldInput: string = "";
  errormsg: string | null = null;

  @Prop({
    default: () => [],
    type: Array,
  })
  readonly value!: string[];

  @Prop({
    type: String,
    default: "",
  })
  readonly placeholder!: string;
  @Prop({
    type: Boolean,
    default: false,
  })
  readonly disabled!: boolean;

  @Prop({
    default: "",
    type: String,
  })
  readonly label!: string;

  @Watch("value", {
    deep: true,
    immediate: true,
  })
  onValueChanged(newVal: string[]) {
    this.xValue = newVal;
  }

  addWord() {
    this.errormsg = null;
    const text = this.fieldInput.trim();
    if (text.length > 0) {
      this.xValue.unshift(text);
      this.fieldInput = "";
      this.$emit("input", this.xValue);
    } else {
      this.errormsg = this.$t(
        "components.controls.wordlist.error_msg"
      ).toString();
    }
  }

  removeWord(idx: number) {
    this.xValue.splice(idx, 1);
    this.$emit("input", this.xValue);
  }
}
