import { AuditItemCategorySetMap } from "@auditcloud/shared/lib/types/AuditItemCategory";
import {
  CompositionWizardSettingsNode,
  CompositionWizardSettings,
  AuditProgramConfig,
  OrganizationUnitConfig,
  ConfigurationInternalsDoc,
} from "@auditcloud/shared/lib/schemas";

import {
  AuditItemConfig,
  FindingConfig,
  MeasureConfig,
  AppConfig,
} from "@auditcloud/shared/lib/types/Configuration/defaults";
import {
  MAGIC_MEASURE_STATUS_DIRECT_MEASURE,
  MAGIC_MEASURE_STATUS_EFFECTIVENESS_REVIEW,
  MAGIC_MEASURE_STATUS_CAUSE_ANALYSIS,
  MAGIC_MEASURE_STATUS_IMPLEMENTATION,
  MAGIC_MEASURE_STATUS_NEW,
} from "@auditcloud/shared/lib/workflow/configs/constants";
import { AuditStatusId } from "@auditcloud/shared/lib/constants";

export function appDefaults(): AppConfig {
  return {
    components: {
      debug: true,
      search: false,
      googlelogin: false,
      dashboard: {
        activitystream: true,
        audits: false,
        favorites: false,
        measures: false,
        limitCount: {
          auditLimit: 10,
          measureLimit: 10,
        },

        status: {
          auditStatus: [
            AuditStatusId.Planning,
            AuditStatusId.Preparation,
            AuditStatusId.Execution,
            AuditStatusId.Wrapup,
            AuditStatusId.Reporting,
            AuditStatusId.MeasureActions,
          ],
          measureStatus: [
            MAGIC_MEASURE_STATUS_NEW,
            MAGIC_MEASURE_STATUS_CAUSE_ANALYSIS,
            MAGIC_MEASURE_STATUS_IMPLEMENTATION,
            MAGIC_MEASURE_STATUS_DIRECT_MEASURE,
            MAGIC_MEASURE_STATUS_EFFECTIVENESS_REVIEW,
          ],
        },
      },
      dialog: {
        buttonPosition: "bottom",
      },
    },
    displayAuditProgramGrid: false,
  };
}

export function auditDefaults() {
  const auditPrograms: { [auditProgramId: string]: AuditProgramConfig } = {};
  const organizationUnits: {
    [auditProgramId: string]: OrganizationUnitConfig;
  } = {};
  const auditCreateSettings:
    | CompositionWizardSettingsNode
    | CompositionWizardSettings = {
    name: "Alle Audits",
    title: "Auswahl",
    preset: {
      implementation: "on-site",
      auditProgramId: "",
      auditClassId: "",
      name: "",
      standardRefs: [],
      nameTemplate: "",
      auditing_date: [],
    },
  };

  return {
    data: {
      scopes: [
        "Global",
        "Werk Ansbach",
        "Werk Gladbach",
        "Deutschland",
        "Region Asien",
        "Region USA",
      ],
      types: ["Prozessaudit", "Systemaudit", "Produktaudit"],
      processes: [
        "Produktentwicklung",
        "Vertrieb",
        "Produktion",
        "Service",
        "Marketing",
        "Allgemein",
      ],
      status: [
        { id: 1, description: "Neu" },
        { id: 2, description: "Geplant" },
        { id: 3, description: "Durchgeführt" },
        { id: 4, description: "Nachbereitet" },
        { id: 5, description: "Abgeschlossen" },
        { id: 6, description: "Archiviert" },
      ],
      auditparties: ["Intern", "Extern", "Lieferant"],
      // TODO: Computing!!!
      standards: [
        "ISO9001",
        "ISO14001",
        "ISO27001",
        "EU-DGSVO",
        "ISO50001",
        "VDA-6-3",
        "HACCP",
      ],
      importance: [
        { id: "", description: "Nicht definiert" },
        { id: "0", description: "Notiz" },
        { id: "1", description: "Anmerkung" },
        { id: "3", description: "Wichtige Frage" },
        { id: "9", description: "Sehr wichtige Frage" },
      ],
    },
    dialog: {
      auditname: true,
      standard: true,
      type: true,
      process: true,
      audited_by: true,
      interestedparties: false,
      scope: true,
      leadauditor: true,
      coauditors: true,
      responsible: true,
      auditors: true,
      audit_participants: true,
      planning_year: true,
      planning_date: true,
      auditing_date: true,
    },
    matrix: {
      auditname: true,
      standard: true,
      type: true,
      audited_by: true,
      process: true,
      interestedparties: true,
      scope: true,
      auditor: true,
      audit_participants: true,
      planning_date: true,
      auditing_date: true,
      edit: true,
      headers: [
        {
          text: "Audit-Name",
          align: "left",
          sortable: true,
          value: "name",
        },
        { text: "Standard", value: "standard" },
        { text: "Type", value: "type" },
        { text: "Type2", value: "audited_by" },
        { text: "Scope", value: "scope" },
        { text: "Auditoren", value: "auditor" },
        { text: "Planning Date", value: "planning_date" },
        { text: "Auditing Date", value: "auditing_date" },
        { text: "Actions", value: "name", sortable: false },
      ],
      currentYear: "2020",
    },
    areReportingDatesRequired: false,
    preparation: {
      grouped: true,
      groups: [
        {
          name: "Kategorie",
          field: "question.category",
        },
        {
          name: "Bedeutsamkeit",
          field: "question.importance",
        },
        {
          name: "Auditor",
          field: "question.auditor",
        },
      ],
    },
    auditPrograms,
    organizationUnits,
    auditCreateSettings,
    auditCreationAllowedRoles: [],
  };
}

export function auditItemDefaults(): AuditItemConfig {
  return {
    dialog: {
      category: true,
      subcategory: true,
      chapters: true,
      importance: true,
      auditors: true,
      questioning_date: false,
      processes: false,
      question: true,
    },
    audititemrow_component: {
      importance: true,
      category: true,
      subcategory: true,
      chapters: true,
      auditors: true,
      questioning_date: false,
      processes: false,
      question: true,
    },
    preparation: {
      // TODO: Macht das hier sinn?
      grouped: true,
    },
    typesForAuditClass: {},
  };
}

export function findingDefaults(): FindingConfig {
  return {
    dialog: {
      audititem: true,
      importance: true,
      finding: true,
      reason: true,
      solution: true,
      geolocation: true,
      media: true,
    },
    typesForAuditClass: {},
  };
}

export function internalsDefaults(): ConfigurationInternalsDoc {
  return {
    grafanaProxy: null,
  };
}

export function measureDefaults(): MeasureConfig {
  return {
    data: {
      types: [
        {
          id: "0",
          color: "orange lighten-2",
          text: { de: "Abstellmaßnahme", en: "Corrective action" },
          short: { de: "AM", en: "CA" },
          description: {
            de: "Systematische Abstellmaßnahme",
            en: "Corrective action",
          },
          icon: "assignment_turned_in",
        },
        {
          id: "1",
          color: "red lighten-2",
          text: { de: "Sofortmaßnahme", en: "Immediate action" },
          short: { de: "SO", en: "IA" },
          description: {
            de: "Sofortige Abstellmaßnahme",
            en: "Immediate action",
          },
          icon: "assignment_late",
        },
      ],
    },
    dialog: {},
    matrix_component: {},
    measure_component: {
      workflow: ["Offen", "In Wirksamkeitsprüfung", "Abgeschlossen"],
    },
    workflow: {
      status: [
        { id: "1", description: { de: "Neu", en: "New" }, start: true },
        { id: "2", description: { de: "Offen", en: "Open" } },
        { id: "3", description: { de: "In Bearbeitung", en: "In process" } },
        { id: "4", description: { de: "Abgeschlossen", en: "Completed" } },
        {
          id: "5",
          description: {
            de: "In Wirksamkeitsprüfung",
            en: "In effectiveness review",
          },
        },
        { id: "6", description: { de: "Wirksam", en: "Effective" } },
      ],
      transitions: [
        { id: "1", description: "Created", from: "1", to: "2" },
        { id: "2", description: "Prepared", from: "2", to: "3" },
        { id: "3", description: "Executed", from: "3", to: "4" },
        { id: "4", description: "Ready for Reporting", from: "4", to: "5" },
        { id: "5", description: "Completed", from: "5", to: "6" },
      ],
    },
  };
}

export function categoriesDefault(): AuditItemCategorySetMap {
  return {
    "055a2cb9-22c7-46fa-a707-3eafc790154c": {
      id: "055a2cb9-22c7-46fa-a707-3eafc790154c",
      name: "Standardkategorien",
      categories: [
        {
          id: "f917d25a-e70e-4e8d-8db0-72c0d75a23d4",
          name: "Infrastruktur & Equipment",
          subcategories: [
            {
              id: "eb08d815-fca8-4929-b9bd-b3ab8688bf4e",
              name: "Infrastruktur",
              subcategories: [],
            },
            {
              id: "95110122-acc3-4e3e-a6b3-c6229c5c3098",
              name: "Equipment",
              subcategories: [],
            },
          ],
        },
        {
          id: "08f92e26-adc0-4967-a720-3d06d2a2cd00",
          name: "Prozesse & Abläufe",
          subcategories: [
            {
              id: "a40fe4a8-d95a-42b8-ad41-75a16baf9333",
              name: "Prozesse",
              subcategories: [],
            },
            {
              id: "bc61cf66-dff2-450e-92c4-4f2f9aaffff4",
              name: "Abläufe",
              subcategories: [],
            },
          ],
        },
        {
          id: "cd7c48fe-c161-4a8b-bc9b-d6ba4e771423",
          name: "Wissen & Know How",
          subcategories: [],
        },
        {
          id: "968142f0-c941-4e49-8aa5-aa1261a3efe9",
          name: "Nachweise & Dokumentation",
          subcategories: [],
        },
        {
          id: "efc3b487-d71e-4203-8765-6f84ba8d5705",
          name: "Arbeitsplatz & -umgebung",
          subcategories: [],
        },
      ],
    },
  };
}

export function scopeDefaults() {
  return {
    default: [
      { site: "pp3", region: "AC", division: "AC" },
      { site: "xs1", region: "AC", division: "AC" },
      { site: "gobd", region: "EMEA", division: "KB" },
      { site: "rob", region: "EMEA", division: "IS" },
      { site: "ru3", region: "EMEA", division: "IS" },
      { site: "go1", region: "EMEA", division: "BS" },
      { site: "mun", region: "EMEA", division: "IS" },
      { site: "roh", region: "EMEA", division: "AC" },
      { site: "faz", region: "AC", division: "AC" },
      { site: "zeit", region: "EMEA", division: "BS" },
      { site: "wirt", region: "EMEA", division: "WS" },
      { site: "anon", region: "EMEA", division: "KB" },
      { site: "zton", region: "EMEA", division: "WS" },
      { site: "ssid", region: "EMEA", division: "IS" },
      { site: "gudt", region: "AC", division: "AC" },
      { site: "rohr", region: "EMEA", division: "KB" },
      { site: "mungo", region: "AM", division: "IS" },
      { site: "rusl", region: "AM", division: "IS" },
      { site: "stan", region: "AC", division: "AC" },
      { site: "füss", region: "AC", division: "AC" },
      { site: "rud", region: "AP", division: "KB" },
      { site: "berl", region: "AC", division: "AC" },
      { site: "akkd", region: "AP", division: "IS" },
      { site: "rund", region: "AC", division: "AC" },
      { site: "mast", region: "EMEA", division: "BS" },
    ],
  };
}

export function userDefaults() {
  return {
    data: {
      groups: [
        { id: "user", name: "User" },
        { id: "auditor", name: "Auditor" },
        { id: "admin", name: "Admin" },
        { id: "measure-dispatcher", name: "Maßnahmenverantwortliche" },
      ],
    },
    dialog: {
      avatar: true,
      name: true,
      email: true,
      groups: true,
      googlelogin: false,
    },
    matrix: {
      avatar: false,
      name: true,
      email: true,
      groups: true,
      actions: true,
    },
  };
}
