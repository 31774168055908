import { GetterTree } from "vuex";
import { UserGroupsState } from "./types";
import { RootState } from "@/store/types";
import { TodoArray } from "@auditcloud/shared/lib/utils/type-guards";

const getGroupList = "getGroupList";
const n = {
  getGroupList,
};

const getterTree: GetterTree<UserGroupsState, RootState> = {
  [n.getGroupList](state, getters, rootState, rootGetters): TodoArray {
    return state.Documents.map(v => {
      return {
        id: v.id,
        name: v.data?.displayName ?? "<invalid>",
        active: v.data?.active ?? false,
        roles: v.data?.roles ?? [],
      };
    });
  },
};

export { n as getterNames, getterTree as getters };
