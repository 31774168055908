import { RootState } from "../../types";
import { AuditProgramStatistic } from "./types";
import { Getters } from ".";
import { ActionTypeContext } from "@/utils/VuexHelper";
import { mapValues } from "lodash";

type Context = ActionTypeContext<AuditProgramStatistic, RootState, Getters>;

const actions = {};

const n = mapValues(actions, (_, key) => key);

export { n as actionNames, actions };
