
























































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch, Mixins } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";

import { api } from "@/store/modules/template";
import { api as appApi } from "@/store/modules/app";
import { api as configApi } from "@/store/modules/configuration";
import { AuditItemTypeMap } from "@auditcloud/shared/lib/types/ItemTypes";

import Sortable from "sortablejs";
import NaturalCompare from "natural-compare";

import PaginationControl from "@auditcloud/components/controls/PaginationControl.vue";
import AStandardChip from "@/components/snippets/AStandardChip.vue";
import ASnippetCategoryRef from "@/components/snippets/ASnippetCategoryRef.vue";
import ListConfigurationMixin from "@/components/mixins/ListConfigurationMixin.vue";
import AQuestionContent, {
  QUESTION_CONTENT_TOGGLES,
} from "@/components/controls/AQuestionContent.vue";

import { nullable } from "@auditcloud/shared/lib/types/common";
import {
  AuditTemplate,
  TemplateItemRemovalPayload,
} from "@/store/modules/template/types";
import { ROUTE_NAMES } from "@/routenames";
import { TemplatePermissions } from "@auditcloud/shared/lib/utils/aclHelpers";
import { AuditItemCategory } from "@auditcloud/shared/lib/types/AuditItemCategory";
import { isNumber, toPairs } from "lodash";
import { makeIdable } from "@auditcloud/shared/lib/utils/transform/makeIdable";
import AIconTogglebar, {
  ToggleConfig,
} from "@/components/controls/AIconTogglebar.vue";
import ATemplateListFilter from "@/components/widgets/ATemplateListFilter.vue";
import { ChapterRefType, CategoryLevel } from "@auditcloud/shared/lib/schemas";
import * as IDS from "@auditcloud/shared/lib/utils/filter/AuditItemListManipulatorIds";
import {
  AuditItemIdsFilteredWithSearchResults,
  sortable_chapter,
} from "@auditcloud/shared/lib/utils/filter/AuditItemListManipulator";
import { createPayloadForRemovingTemplateItems } from "@/store/modules/template/utils";
import { Filter } from "@auditcloud/shared/lib/utils/filter/types";
import { AuditItemWithId } from "@auditcloud/shared/lib/utils/audit/types";

const templateModule = namespace(api.namespace);
const configModule = namespace(configApi.namespace);
const numberIndexName = "no";

@Component({
  components: {
    PaginationControl,
    AStandardChip,
    ASnippetCategoryRef,
    AQuestionContent,
    AIconTogglebar,
    ATemplateListFilter,
  },
  mixins: [ListConfigurationMixin],
})
export default class Template extends Mixins(ListConfigurationMixin) {
  currentToggle: string = "";
  toggleItemsWhiteList = ["hint", "labels", "links", "proofs"];

  @Prop({
    type: String,
    required: true,
  })
  templateId!: string;

  @templateModule.Action(api.actions.setCurrentTemplateId)
  setTemplateId!: (templateId: string | null) => Promise<void>;

  @templateModule.Action(api.actions.deleteTemplateItem)
  deleteTemplateItem!: (data: TemplateItemRemovalPayload) => Promise<void>;

  @Action(appApi.actions.setBreadCrumb, { namespace: appApi.namespace })
  setBreadCrumb!: (currentBreadCrumb: any[]) => Promise<boolean>;

  @Action(appApi.actions.setMenuPanel, { namespace: appApi.namespace })
  setMenuPanel!: () => Promise<boolean>;

  @templateModule.Mutation(api.mutations.TOGGLE_FILTER_SETTING)
  toggleFilterSetting!: (payload: Filter) => void;

  @templateModule.Getter(api.getters.getTemplate)
  template!: nullable<AuditTemplate>;

  @templateModule.Getter(api.getters.getTemplatePermissions)
  templatePermissions!: TemplatePermissions;

  @templateModule.Getter(api.getters.getCategoryLevel)
  categoryLevel!: CategoryLevel;

  @templateModule.Getter(api.getters.getMappedAuditItemTypes)
  mappedAuditItemTypes!: AuditItemTypeMap;

  @templateModule.Getter(api.getters.getTemplateItemsNoTypeIsNumber)
  itemsNoTypeIsNumber!: boolean;

  @templateModule.Getter(api.getters.getTemplateItemIdsFilteredWithSearchResult)
  templateItemIdsFilteredWithSearchResult!: AuditItemIdsFilteredWithSearchResults;

  @templateModule.Getter(api.getters.getTemplateItemIdsFiltered)
  filteredAuditItemIds!: Array<AuditItemWithId["id"]>;

  @templateModule.Getter(api.getters.getFullTextSearch)
  filteredText!: string;

  @configModule.Getter(configApi.getters.selfAssessmentFeatureEnabled)
  selfAssessmentFeatureEnabled!: boolean;

  // Overwrites from ListConfigurationMixin
  get listConfigConfigKey() {
    return "templatePreperation";
  }
  get listConfigTotalItemCount() {
    return this.auditItems.length;
  }

  @Watch("templateId", { immediate: true, deep: true })
  onTemplateIdChanged(newVal: string | null, oldVal: string | null) {
    console.log("onTemplateIdChanged", newVal, oldVal);
    if (newVal !== oldVal) {
      this.setTemplateId(null)
        .then(() => {
          this.setTemplateId(newVal);
        })
        .then(() => {
          this.setBreadCrumb(this.currentBreadCrumb);
          this.setMenuPanel();
        });
    }
  }

  get auditItems() {
    if (this.template === null) {
      return [];
    } else {
      return toPairs(this.template.audit_items)
        .map(makeIdable)
        .filter(ai => this.filteredAuditItemIds.includes(ai.id));
    }
  }

  get headers() {
    return [
      // ...(this.itemsNoTypeIsNumber
      //   ? [{ sortable: false, align: "center", width: 36 }]
      //   : []),
      {
        text: this.$i18n.t("views.template.question_no").toString(),
        align: "left",
        sortable: true,
        value: numberIndexName,
      },
      {
        text: this.$i18n.t("views.template.question_category").toString(),
        value: "category",
        sortable: true,
        align: "left",
      },
      {
        text: this.$i18n.t("views.template.question_chapters").toString(),
        value: "chapters",
        sortable: true,
        align: "left",
      },
      {
        text: this.$i18n.t("views.template.question_type").toString(),
        align: "left",
        sortable: true,
        value: "typeShort",
      },
      {
        text: this.$i18n.t("views.template.question_text").toString(),
        align: "left",
        sortable: true,
        value: "text",
        width: "66%",
      },
      /*
        { text: this.$i18n.t("views.template.matrix_standard").toString(), value: "standard" },
        {
          text: this.$i18n.t("views.template.matrix_audit_item_count").toString(),
          value: "auditItemCount"
        },

        { text: this.$i18n.t("views.template.matrix_type").toString(), value: "type" },
        { text: this.$i18n.t("views.template.matrix_art").toString(), value: "audited_by" },
        { text: this.$i18n.t("views.template.matrix_scope").toString(), value: "scope" },
        {
          text: this.$i18n.t("views.template.matrix_created").toString(),
          value: "created_date"
        } */ {
        text: this.$i18n.t("views.template.actions").toString(),
        value: "name",
        sortable: false,
      },
    ];
  }

  get toggles(): ToggleConfig[] {
    return QUESTION_CONTENT_TOGGLES.filter(toggle =>
      this.toggleItemsWhiteList.includes(toggle.id)
    );
  }

  get tableData() {
    console.log("Audititem tableData", this.auditItems);
    return this.auditItems.map((ai, idx) => {
      const auditItemType = this.mappedAuditItemTypes[ai.question.type];
      return {
        id: ai.id,
        labels: ai.labels,
        no: ai.question.no,
        categoryRef:
          ai.question && ai.question.categoryRef ? ai.question.categoryRef : [],
        chapters: ai.question.chapters,
        proofs:
          ai.question && ai.question.requiredProofs
            ? ai.question.requiredProofs
            : [],
        links: ai.question.links,
        typeColor: auditItemType.color,
        typeShort: auditItemType.short,
        typeValue: auditItemType.value,
        typeTitle: auditItemType.text,
        text: this.$ct(ai.question.text),
        selfAssessmentText: ai.question.selfAssessmentText
          ? this.$ct(ai.question.selfAssessmentText)
          : undefined,
        hint: this.$ct(ai.question.hint ?? ""),
        searchResult:
          this.templateItemIdsFilteredWithSearchResult
            .searchResultsByAuditItemIds[ai.id],
        vda_question_scope: ai.question.vda_question_scope,
      };
    });
  }

  get currentBreadCrumb(): any[] {
    const name =
      this.template && typeof this.template.name === "string"
        ? this.template.name
        : this.$t("views.template.create_new_template").toString();

    return [
      {
        text: this.$t("views.audit.home"),
        to: { name: ROUTE_NAMES.DASHBOARDSCOPED },
        activeclass: "",
      },
      // TODO: Aktiven Auditplan auslesen
      {
        text: this.$t("views.template.questionpool").toString(),
        to: { name: ROUTE_NAMES.TEMPLATES },

        activeclass: "",
      },
      {
        text: name,
        to: {
          name: ROUTE_NAMES.TEMPLATE_METADATA,
          params: { templateId: this.templateId },
        },
        activeclass: "",
        actionicon: "info",
      },
    ];
  }

  mounted() {
    console.log("Template:mounted", this.templateId);

    const sortable = new Sortable(
      (this.$refs.sortableTable as Vue).$el.getElementsByTagName("tbody")[0],
      {
        draggable: ".sortableRow",
        handle: ".sortHandle",
        onEnd: this.dragReorder,
        animation: 150,
        ghostClass: "blue-background-class",
      }
    );
  }
  created() {
    console.log("Template:created", this.templateId);
    // this.setFooterComponent("AAuditFooter");
  }

  beforeDestroy() {
    // this.setFooterComponent();
    console.log("BeforeDestroy got called!");
    this.setTemplateId(null);
    this.setBreadCrumb([]);
    this.setMenuPanel();
  }

  dragReorder(e) {
    console.log("Drag Reorder:", e);
  }

  setToggle(toggleId: string | null) {
    if (this.currentToggle === toggleId || toggleId === null) {
      this.currentToggle = "";
    } else {
      this.currentToggle = toggleId;
    }
  }

  editItem(templateItemId: string) {
    this.$router.push({
      name: ROUTE_NAMES.TEMPLATE_ITEM_DIALOG,
      params: {
        ...this.$route.params,
        templateItemId,
      },
    });
  }

  async deleteItem(templateItemId, templateItemNo) {
    if (
      confirm(String(this.$t("views.template.confirm_template_item_deletion")))
    ) {
      const itemRemovalPayload: TemplateItemRemovalPayload = {
        templateItemId,
      };
      if (this.itemsNoTypeIsNumber && isNumber(templateItemNo)) {
        itemRemovalPayload.itemNumberModification =
          createPayloadForRemovingTemplateItems(
            templateItemNo,
            this.auditItems.length
          );
      }
      this.deleteTemplateItem(itemRemovalPayload).catch(err => {
        console.error("deleteTemplateItem:failed", templateItemId, err);
      });
    }
  }

  createAuditItem() {
    this.$router.push({
      name: ROUTE_NAMES.TEMPLATE_ITEM_DIALOG,
      params: {
        ...this.$route.params,
      },
    });
  }

  filterChapter(chapter: ChapterRefType) {
    this.toggleFilterSetting({
      aggregationId: IDS.FILTER_CHAPTER,
      value: sortable_chapter(chapter),
    });
  }

  filterCategory(category: AuditItemCategory) {
    if (category) {
      this.toggleFilterSetting({
        aggregationId: IDS.FILTER_CATEGORY,
        value: category.id,
      });
    }
  }

  filterVdaScope(val: string) {
    this.toggleFilterSetting({
      aggregationId: IDS.FILTER_VDA_QUESTION_SCOPE,
      value: val,
    });
  }

  customSort(items, index, isDesc) {
    const sortBy: string = index[0];
    const sortDesc = isDesc[0];
    if (sortBy === numberIndexName) {
      items.sort((a, b) => NaturalCompare(a[sortBy], b[sortBy]));
    } else {
      items.sort((a, b) => {
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        return 0;
      });
    }
    if (sortDesc) {
      items.reverse();
    }
    return items;
  }
}
