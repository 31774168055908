import _Vue from "vue";

const FEATURES: { [id: string]: () => boolean } = {
  featureMeasureProcess: () => {
    return true; // window.TheProjectId === "audit-cloud-demo1";
  },
  featureMeasureAssignToResponsible: () => {
    return !(
      typeof window.TheProjectId === "string" &&
      ["next-audit-schwartau"].includes(window.TheProjectId)
    );
  },
  featureMeasureDueDate: () => {
    return !(
      typeof window.TheProjectId === "string" &&
      ["next-audit-schwartau"].includes(window.TheProjectId)
    );
  },
  featureEnhancedTemplateImport: () => {
    return true;
  },
  featureCustomActionPlanTemplate: () => {
    return process.env.NODE_ENV !== "production";
  },
  featureAuditAttachmentVersions: () => false,
  featureReportingDashboard: () => {
    return (
      process.env.NODE_ENV !== "production" ||
      (typeof window.TheProjectId === "string" &&
        [
          "audit-cloud-demo1",
          "next-audit-staging",
          "next-audit-dev",
          "next-audit-babor1",
        ].includes(window.TheProjectId))
    );
  },
  featureUseProcessAsScope: () => {
    return (
      typeof window.TheProjectId === "string" &&
      ["next-audit-schwartau"].includes(window.TheProjectId)
    );
  },
  featureAllowToSuppressScoreInReport: () => {
    return (
      process.env.NODE_ENV !== "production" ||
      (typeof window.TheProjectId === "string" &&
        ["next-audit-rehau"].includes(window.TheProjectId))
    );
  },
  featureAuditInfosInReport: () => {
    return (
      typeof window.TheProjectId === "string" &&
      ["next-audit-rehau"].includes(window.TheProjectId)
    );
  },
  featureRadarChartExtremePerformers: () => {
    return (
      typeof window.TheProjectId === "string" &&
      ["next-audit-wzl"].includes(window.TheProjectId)
    );
  },
};

export function isFeatureEnabled(featureName: string) {
  if (featureName in FEATURES) {
    return FEATURES[featureName].call(FEATURES);
  } else {
    console.warn("unknown feature name", featureName);
    return false;
  }
}

export type FeatureFlagPluginType = (featureName: string) => boolean;

export function FeatureFlagPlugin(Vue: typeof _Vue, options?: any): void {
  Vue.prototype.$hasFeature = isFeatureEnabled;
}
