














import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";

import AWorkflowCrumbs from "./WorkflowCrumbs.vue";
import AWorkflowCrumb from "./WorkflowCrumb.vue";

export interface Crumb {
  text: string;
  disabled?: boolean;
}

@Component({
  components: { AWorkflowCrumbs, AWorkflowCrumb },
})
export default class AControlledWorkflowCrumbs extends Vue {
  @Prop({
    default: 0,
  })
  readonly selected!: number;

  @Prop({
    default: 0,
  })
  readonly current!: number;

  @Prop({
    required: true,
  })
  readonly items!: Crumb[];

  @Prop({
    type: Boolean,
  })
  readonly block!: boolean;

  onCrumbClicked(c: number) {
    if (c <= this.current) {
      this.$emit("select-step", c);
    }
  }
}
