import firebase from "firebase/compat/app";

import { mapValues, fromPairs } from "lodash";
import { ActionContext } from "vuex";
import { CurrentFindingState } from "./types";
import { RootState } from "../../types";
import {
  EmptyFindingPayLoad,
  UploadFindingAttachmentPayload,
  uploadFindingAttachment,
  normalizeFinding,
} from "./utils";
import { mutationNames as mn } from "./mutations";
import { getterNs, logXStored } from "@/utils/VuexHelper";
import { api as auditApi } from "../audit";
import { rdu } from "../user";
import { Finding, StoredAttachmentEntry } from "@auditcloud/shared/lib/schemas";
import { Attachment } from "@/components/types";
import { createError } from "@/utils/Errors";
import { v4 as uuidv4 } from "uuid";
import { createFindingPath } from "@auditcloud/shared/lib/utils/firestorePathHelper";
import { NULL_UUID } from "@auditcloud/shared/lib/constants";
import { Result, ok, err } from "neverthrow";
import { extractCurrentUserRef } from "../user/utils";

type Context = ActionContext<CurrentFindingState, RootState>;
type CreateFindingPayload = {
  finding: Finding;
  attachments: Attachment[];
};
type UpdateFindingPayload = {
  findingId: string;
  finding: Finding;
  additionalAttachments: Attachment[];
  attachmentIdsToRemove: string[];
};

export async function storeFindingsPatch(
  { rootGetters }: Context,
  {
    findingId,
    finding,
    additionalAttachments,
    attachmentIdsToRemove,
  }: UpdateFindingPayload
): Promise<
  Result<
    { findingId: string },
    { findingId: string; errorType: "UPLOAD_ERROR" | "OTHER_ERROR" }
  >
> {
  const auditId = rootGetters[
    getterNs(auditApi, auditApi.getters.getAuditId)
  ] as null | string;
  if (auditId === null) {
    throw createError("Invalid auditId");
  }

  const userRef = extractCurrentUserRef(rootGetters);

  if (userRef === null) {
    throw createError("Invalid User");
  }

  // upload attachments
  const attachmentInfosPromises = additionalAttachments.map(attachment => {
    const uploadData: UploadFindingAttachmentPayload = {
      attachment,
      auditId,
      findingId,
      userRef,
    };
    return uploadFindingAttachment(uploadData);
  });

  let attachmentInfos: { id: string; attachment: StoredAttachmentEntry }[] = [];
  let uploadError = false;
  try {
    attachmentInfos = await Promise.all(attachmentInfosPromises);
  } catch (e) {
    uploadError = true;
  }

  const newAttachments = fromPairs(
    attachmentInfos.map(({ id, attachment }) => {
      return [`attachments.${id}`, attachment];
    })
  );

  const findingData: Finding = {
    ...finding,
    attachmentIds: [
      ...finding.attachmentIds.filter(
        id => !attachmentIdsToRemove.includes(id)
      ),
      ...attachmentInfos.map(({ id }) => id),
    ],
  };
  const { ref, doc, child } = createFindingPath(auditId, findingId);

  firebase
    .firestore()
    .collection(ref)
    .doc(doc)
    .update({
      ...newAttachments,
      [child]: normalizeFinding(findingData, rootGetters),
      ...rdu(rootGetters),
    })
    .then(logXStored("Finding"));

  if (uploadError) {
    return err({ findingId, errorType: "UPLOAD_ERROR" });
  } else {
    return ok({ findingId });
  }
}

const actions = {
  async createFinding(
    context: Context,
    { finding, attachments }: CreateFindingPayload
  ) {
    const findingId = uuidv4();

    return await storeFindingsPatch(context, {
      findingId,
      additionalAttachments: attachments,
      attachmentIdsToRemove: [],
      finding,
    });
  },
  async updateFinding(context: Context, payload: UpdateFindingPayload) {
    return await storeFindingsPatch(context, payload);
  },
  async initWithNewFinding({ commit }: Context, payload: EmptyFindingPayLoad) {
    commit(mn.PREPARE_NEW_FINDING, payload);
  },
  async initState(
    { commit, rootGetters, state }: Context,
    { findingId }: { findingId: string | null }
  ) {
    if (findingId === null) {
      commit(mn.PREPARE_NEW_FINDING);
    } else if (findingId !== NULL_UUID) {
      const findingsMap = rootGetters[
        getterNs(auditApi, auditApi.getters.getFindingsMap)
      ] as {
        [k: string]: Finding;
      };
      const finding = findingsMap[findingId];

      if (finding) {
        commit(mn.SET_FINDING, { findingId, finding });
      } else {
        commit(mn.SET_NOT_FOUND);
      }
    } else if (state.findingId !== NULL_UUID) {
      commit(mn.PREPARE_NEW_FINDING);
    }

    return true;
  },
};

const n = mapValues(actions, (_, key) => key);

export { n as actionNames, actions };
