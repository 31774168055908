






































import { ROUTE_NAMES } from "@/routenames";
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
  components: {},
})
export default class PageNotFound extends Vue {
  HOME: string = ROUTE_NAMES.HOME;
  navigateToHome() {
    this.$router.push({ name: this.HOME });
  }
}
