import { mapValues, isEqual, xorWith } from "lodash";
import { State } from "./types";
import { Filter } from "@auditcloud/shared/lib/utils/filter/types";

const mutations = {
  TOGGLE_FILTER(state: State, filter: Filter) {
    state.activeFilter = xorWith(state.activeFilter, [filter], isEqual);
  },
  SET_FILTERS(state: State, filters: Filter[]) {
    state.activeFilter = filters;
  },
  CLEAR_FILTER_FOR_AGGREGATIONS(
    state: State,
    aggregationId: Filter["aggregationId"]
  ) {
    state.activeFilter = state.activeFilter.filter(filter => {
      return filter.aggregationId !== aggregationId;
    });
  },
  CLEAR_ALL_FILTERS(state: State) {
    state.activeFilter = [];
  },
  SET_FULLTEXT_SEARCH(state: State, needle: string) {
    state.fulltextSearch = needle;
  },
};

const n = mapValues(mutations, (_, key) => key);

export { n as mutationNames, mutations };
