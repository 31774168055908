import { Dispatch, Commit } from "vuex";
export interface Callable<R = unknown> {
  (...args: any[]): R;
}

export type GettersType<T extends { [p: string]: Callable }> = {
  [P in keyof T]: ReturnType<T[P]>;
};

export type OmitFirstArg<F> = F extends (x: any, ...args: infer P) => infer R
  ? (...args: P) => R
  : never;

export type ActionsType<T extends { [p: string]: Callable }> = {
  [P in keyof T]: OmitFirstArg<T[P]>;
};

export type MutationsType<T extends { [p: string]: Callable }> = {
  [P in keyof T]: OmitFirstArg<T[P]>;
};

export interface ActionTypeContext<S, R, G> {
  dispatch: Dispatch;
  commit: Commit;
  state: S;
  getters: G;
  rootState: R;
  rootGetters: any;
}

export { getterNs, mutationNs, actionNs } from "@auditcloud/shared/lib/utils";

export function logXStored(X: string): () => void {
  return () => {
    console.log(`${X} stored in the Backend`);
  };
}
