import firebase from "firebase/compat/app";
import { ActionTree } from "vuex";
import { RootState } from "./types";

import { api as activitiesApi } from "./modules/activities";
import { api as appApi } from "./modules/app";
import { api as auditsApi } from "./modules/audits";
import { api as categoriesApi } from "./modules/categories";
import { api as standardsApi } from "./modules/standards";
import { api as templatesApi } from "./modules/templates";
import { api as userApi } from "./modules/user";
import { api as usersApi } from "./modules/users";
import { api as schemasApi } from "./modules/schemas";
import { api as configurationApi } from "./modules/configuration";
import { api as i18nApi } from "./modules/i18n";
import { api as auditApi } from "./modules/audit";
import { api as measuresApi } from "./modules/measures";
import { api as measureApi } from "./modules/measure";
import { api as measureProcessApi } from "./modules/measureProcess";

import { api as autocompletesApi } from "./modules/autocompletes";
import { api as measureWorkflowsApi } from "./modules/measureWorkflows";
import { mutationNs, actionNs } from "@/utils/VuexHelper";
import { api } from "./index";

const initAppCoreData = "initAppCoreData";
const unloadState = "unloadState";

const n = {
  initAppCoreData,
  unloadState,
};

const actions: ActionTree<RootState, RootState> = {
  async [n.initAppCoreData]({ dispatch, commit }, user: firebase.User) {
    await dispatch(actionNs(appApi, appApi.actions.setStatus), {});

    commit(api.mutations.SET_LOADING_MESSAGE, "user signin ...");
    const isNewUser: boolean = await dispatch(
      actionNs(userApi, userApi.actions.autoSignIn),
      user
    );
    console.log("isNewUser", isNewUser);
    if (isNewUser) {
      commit(api.mutations.SET_LOADING_MESSAGE, "loading translations ...");
      await dispatch(actionNs(i18nApi, i18nApi.actions.listenOnTranslations));

      commit(api.mutations.SET_LOADING_MESSAGE, "loading configuration ...");
      await dispatch(
        actionNs(configurationApi, configurationApi.actions.loadData)
      );

      commit(api.mutations.SET_LOADING_MESSAGE, "loading autocompletes ...");
      await dispatch(
        actionNs(
          autocompletesApi,
          autocompletesApi.actions.loadAutoCompletesData
        )
      );
      await dispatch(actionNs(usersApi, usersApi.actions.loadUsersByApi));

      commit(api.mutations.SET_LOADING_MESSAGE, "loading schemas ...");
      await dispatch(actionNs(schemasApi, schemasApi.actions.loadData));

      commit(api.mutations.SET_LOADING_MESSAGE, "loading standards ...");
      await dispatch(
        actionNs(standardsApi, standardsApi.actions.loadStandards)
      );
      commit(api.mutations.SET_LOADING_MESSAGE, "loading templates ...");
      await dispatch(
        actionNs(templatesApi, templatesApi.actions.loadTemplates)
      );

      commit(
        api.mutations.SET_LOADING_MESSAGE,
        "loading measure workflows ..."
      );
      await dispatch(
        actionNs(
          measureWorkflowsApi,
          measureWorkflowsApi.actions.loadMeasureWorkflows
        )
      );

      await dispatch(
        actionNs(
          activitiesApi,
          activitiesApi.actions.startListenOnNewActivities
        )
      );
    }
  },

  [n.unloadState]({ commit }) {
    console.log("UNLOAD STATE");
    commit(mutationNs(auditApi, auditApi.mutations.CLEAR_AUDIT));

    commit(mutationNs(measuresApi, measuresApi.mutations.CLEAR_MEASURES), null);
    commit(mutationNs(measureApi, measureApi.mutations.CLEAR_MEASURE));
    commit(
      mutationNs(
        measureProcessApi,
        measureProcessApi.mutations.CLEAR_MEASURE_PROCESS
      )
    );
    commit(mutationNs(schemasApi, schemasApi.mutations.CLEAR_SCHEMAS));
    commit(
      mutationNs(
        measureWorkflowsApi,
        measureWorkflowsApi.mutations.SET_MEASURE_UNLISTENER
      ),
      null
    );

    commit(mutationNs(activitiesApi, activitiesApi.mutations.resetState));
    commit(mutationNs(appApi, appApi.mutations.resetState));
    commit(mutationNs(auditsApi, auditsApi.mutations.resetState));
    commit(mutationNs(categoriesApi, categoriesApi.mutations.resetState));
    commit(mutationNs(standardsApi, standardsApi.mutations.resetState));
    commit(mutationNs(templatesApi, templatesApi.mutations.resetState));
    commit(mutationNs(usersApi, usersApi.mutations.resetState));
    commit(mutationNs(configurationApi, configurationApi.mutations.resetState));
    commit(
      mutationNs(
        autocompletesApi,
        autocompletesApi.mutations.CLEAR_AUTO_COMPLETES_DATA
      )
    );
  },
};

export { n as actionNames, actions };
