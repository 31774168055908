



















































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Getter } from "vuex-class";

import { api as appApi } from "@/store/modules/app";
import { api as auditApi } from "@/store/modules/audit";

import { VBreadcrumbs, VIcon, VBtn } from "vuetify/lib";
import ADeletedChip from "../snippets/ADeletedChip.vue";
import { getterNs } from "../../utils/VuexHelper";
import { typeIsMultilingualText } from "@auditcloud/shared/lib/types/common";
import { isEqual } from "lodash";
import { Route } from "vue-router";

@Component({
  components: {
    ADeletedChip,
    VBtn,
    VBreadcrumbs,
    VIcon,
  },
})
export default class AMenuBreadCrumb extends Vue {
  changeRoute(to: Route) {
    if (!isEqual(to, this.currentBreadCrumbItem.to)) {
      this.$router.push({ name: to.name ?? "error" });
    }
  }

  set currentBreadCrumbItem(val) {}
  get currentBreadCrumbItem() {
    const [lastItem] = this.breadcrumbItems.slice(-1);
    return lastItem;
  }

  get hasAuditLabel() {
    return !!this.$store.getters[
      getterNs(auditApi, auditApi.getters.getAuditBreadCrumbLabel)
    ];
  }
  get isDeleted() {
    return !!this.$store.getters[
      getterNs(auditApi, auditApi.getters.getIsDeleted)
    ];
  }
  get auditLabel() {
    const label = this.$store.getters[
      getterNs(auditApi, auditApi.getters.getAuditBreadCrumbLabel)
    ] as unknown;
    if (typeIsMultilingualText(label)) {
      return this.$ft(label);
    } else if (typeof label === "string") {
      const res = this.$t(label).toString();

      return res !== label ? res : label;
    } else {
      return " - ";
    }
  }
  @Getter(auditApi.getters.getAuditBreadCrumbLabelIcon, {
    namespace: auditApi.namespace,
  })
  auditLabelIcons!: string | null;

  @Getter(appApi.getters.getBreadCrumb, {
    namespace: appApi.namespace,
  })
  breadcrumbItems!: any[];

  get isProd() {
    return process.env.NODE_ENV === "production";
  }

  onActionIconClicked(item: any) {
    console.log("onActionIconClicked", item);
    const eventId = item.event;
    if (typeof eventId === "string" && eventId.trim().length > 0) {
      this.$emit(eventId);
    } else if (typeof item.to !== "undefined" && item.to !== null) {
      this.$router.push(item.to);
    }
  }
}
