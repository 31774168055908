












































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TodoAny } from "@auditcloud/shared/lib/utils/type-guards";

import { flatten, isArray, pick } from "lodash";
import {
  formatDates,
  parseDateRange,
} from "@auditcloud/shared/lib/utils/formatting/dates";
import moment from "moment";

type DatePickerInputType = Date | string | Array<string | Date> | null;
type DatePickerInternal = null | string | string[];
type DatePickerOutputString = DatePickerInternal;
type DatePickerOutputDate = null | Date | Date[];

@Component({
  name: "AMenuDatepicker",
})
export default class AMenuDatePicker extends Vue {
  @Prop({
    type: String,
    default: "",
  })
  label!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  clearable!: boolean;

  @Prop({
    type: String,
    default: undefined,
  })
  locale!: string;

  @Prop({
    type: Number,
    default: 1, // TODO:  ggf. aus den Usereinstellungen laden - Montag -> typisch deutsch
  })
  firstDayOfWeek!: number;

  @Prop({
    type: Number,
    default: 4, // TODO:  ggf. aus den Usereinstellungen laden - Donnerstag -> typisch deutsch  ISO 8601
  })
  localeFirstDayOfYear!: number;

  @Prop({
    type: Boolean,
    default: false,
  })
  multiple!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  range!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  outlined!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  dense!: boolean;

  @Prop({
    type: String,
    default: "event",
  })
  prependIcon!: string;

  @Prop({
    type: [Date, String, Array],
    default: null,
  })
  value!: DatePickerInputType;

  @Prop({
    type: String,
    default: undefined,
  })
  min!: string;

  @Prop({
    type: String,
    default: undefined,
  })
  max!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Function,
    default: null,
  })
  allowedDates!: TodoAny;

  @Prop({
    type: Boolean,
    default: false,
  })
  returnString!: boolean;

  @Watch("dateMenu")
  onDateMenuChange(menuOpen: boolean) {
    if (!menuOpen) {
      const makeCompareable = (v: DatePickerInternal): string => {
        return JSON.stringify(isArray(v) ? [...v].sort() : v);
      };

      const hasChanges =
        makeCompareable(this.state) !== makeCompareable(this.statePicker);

      if (hasChanges) {
        const applyChanges = confirm(
          this.$ft("components.controls.menu_date_picker.confirm_apply_changes")
        );
        if (applyChanges) {
          this.state = this.statePicker;
          this.triggerEmit();
        } else {
          this.statePicker = this.state;
        }
      }
    }
  }

  showDatePicker() {
    // removes necessity of clicking twice in the input field to open the date picker
    // note: this.$nextTick does not work for some reason
    setTimeout(() => {
      this.dateMenu = true;
    }, 10);
  }

  deleteDate(parent: any, item: any) {
    parent.selectItem(item);
    // prevents the menu from popping up
    setTimeout(() => {
      this.dateMenu = false;
    }, 10);
  }

  dateMenu: boolean = false;
  statePicker: DatePickerInternal = null;
  state: DatePickerInternal = null;

  get localeCalc() {
    if (this.locale) {
      return this.locale;
    } else {
      return this.$i18n.locale;
    }
  }
  get formatedState() {
    const dates = formatDates(this.state ?? "", null);

    if (this.multiple || this.range) {
      return dates;
    } else {
      if (dates.length === 1) {
        return dates[0];
      } else {
        return "";
      }
    }
  }

  get inheritedAttrs() {
    return pick(this.$attrs, ["error", "error-messages", "hide-details"]);
  }

  @Watch("value", { immediate: true })
  onWatchValue(newVal: DatePickerInputType) {
    this.state = this.statePicker = this.normalizeValue(newVal);
    this.$emit("change", newVal);
  }

  triggerEmit() {
    console.log("emit:input", this.statePicker, this.returnString);
    const sendInput = (v: DatePickerOutputString | DatePickerOutputDate) =>
      this.$emit("input", v);

    if (this.returnString) {
      sendInput(this.statePicker);
    } else {
      if (isArray(this.statePicker)) {
        const results = this.statePicker.map(date => new Date(date));
        sendInput(results);
      } else {
        const result =
          this.statePicker === null ? null : new Date(this.statePicker);
        sendInput(result);
      }
    }
    this.state = this.statePicker;
  }

  onTextInput(values: string[]) {
    const formats = this.$t("components.controls.menu_date_picker.formats")
      .toString()
      .split(",");
    const parsed = flatten(values.map(v => parseDateRange(v, formats)));
    // remove duplicates, while keeping order:
    this.statePicker = [...new Set(parsed)];
    this.triggerEmit();
  }

  onDatePickerInput(value) {
    this.statePicker = value;
    if (!this.multiple && !this.range) {
      this.dateMenu = false;
      this.triggerEmit();
    }
  }

  onClear() {
    this.statePicker = this.normalizeValue(null);
    this.triggerEmit();
  }

  onOK() {
    this.dateMenu = false;
    this.triggerEmit();
  }

  onCancel() {
    this.dateMenu = false;
    this.statePicker = this.state;
  }

  normalizeValue(value: DatePickerInputType): DatePickerInternal {
    const toIsoStr = (v: string | Date): string => {
      if (typeof v === "string") {
        return v;
      }
      // Use moment to get the ISO date in the local timezone of the user,
      // so that cutting of the time part does not change the date:
      // - user enters "4.1.2022" in CET
      // - value may be stored in UTC as "2022-01-03T23:00:00Z"
      // - to display, convert to "2022-01-04T00:00:00+0100"
      // - pass date-portion ("2022-01-04") back to v-date-picker
      const isoDateInLocalTimezone = moment(v).format();
      return isoDateInLocalTimezone.substr(0, 10);
    };
    if (this.multiple || this.range) {
      if (value instanceof Array) {
        return value.map(toIsoStr);
      } else if (value instanceof Date) {
        return [value].map(toIsoStr);
      } else if (typeof value === "string") {
        return [value];
      } else {
        return [];
      }
    } else {
      if (value instanceof Date) {
        return toIsoStr(value);
      } else if (typeof value === "string") {
        return value;
      } else if (value instanceof Array && value.length > 0) {
        return toIsoStr(value[0]);
      } else {
        return null;
      }
    }
  }
}
