/**
 * Returns wether the browser supports regex lookahead/lookbehind.
 */
export function supportsRegexLookAheadLookBehind() {
  try {
    return (
      "hibyehihi"
        .replace(new RegExp("(?<=hi)hi", "g"), "hello")
        .replace(new RegExp("hi(?!bye)", "g"), "hey") === "hibyeheyhello"
    );
  } catch (error) {
    return false;
  }
}
