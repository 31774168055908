var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',_vm._b({staticClass:"white",attrs:{"items":_vm.auditItemsWithSearchText,"filter":_vm.filter,"prepend-inner-icon":"mdi-magnify","menu-props":{ overflowY: false }},on:{"change":function($event){return _vm.$emit('change', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(_vm._s(item.question.no))]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("mdi-delete")])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
var parent = ref.parent;
return [_c('div',{staticStyle:{"width":"100%"},style:({ 'max-width': _vm.$attrs['menu-max-width'] })},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-row align-start justify-space-between my-1"},[_c('div',{staticClass:"d-flex a-gap-1 overflow-hidden"},[_c('div',{staticClass:"text-caption secondary--text",staticStyle:{"max-height":"20px","line-height":"20px"}},[_vm._v(" #"+_vm._s(item.question.no)+" ")]),_c('div',{staticClass:"pl-4"},[_c('ASnippetCategoryRef',{staticStyle:{"max-width":"12vw"},attrs:{"filter":false,"category-ref":item.question.categoryRef,"category-set-id":_vm.categorySetId,"category-level":"root","show-categories-in-single-row":"","label":"","custom-classes":[
                  'ma-0 px-2 text-caption max-height-20px flex-shrink-1 pointer' ],"color":"#ECECEB"}})],1),_vm._l((_vm.chapterGroups(item)),function(chapterGroup){return _c('div',{key:chapterGroup.standardName},[_c('div',{staticClass:"d-flex flex-column pl-4"},[(_vm.hasMultipleStandardIds)?_c('span',{staticClass:"secondary--text font-weight-medium",staticStyle:{"font-size":"10px","letter-spacing":"0.12rem"}},[_vm._v(" "+_vm._s(chapterGroup.standardName)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex a-gap-1"},_vm._l((chapterGroup.chapters),function(chapter){return _c('v-chip',{key:chapter.id,staticClass:"px-2 text-caption flex-shrink-0 pointer",class:'lighten-3 accent--text',staticStyle:{"max-height":"20px"},attrs:{"label":"","small":"","title":((chapter.standardName) + "\n" + (chapter.chapterDescription)),"color":'#E2E0EE',"ripple":false}},[_vm._v(" "+_vm._s(chapter.chapterId)+" ")])}),1)])])}),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"1000","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"\n                    pl-4\n                    audit-item-autocomplete\n                    pre\n                    text-subtitle-2\n                    overflow-hidden\n                    flex-shrink-1\n                  ",domProps:{"textContent":_vm._s(parent.$ft(item.question.text))}},'div',attrs,false),on))]}}],null,true)},[_c('span',{staticClass:"pre",domProps:{"textContent":_vm._s(parent.$ft(item.question.text))}})])],2)])])])]}}])},'v-autocomplete',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }