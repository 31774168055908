import {
  colorForIdx,
  TEXT_TOTAL,
  UNKNOWN_DIMENSION,
} from "@auditcloud/shared/lib/constants";
import { fill, isNumber, isString, round, zipWith } from "lodash";
import { TranslateableText } from "@auditcloud/shared/lib/schemas";
import { tt2str } from "@auditcloud/shared/lib/types/common";
import { AuditResultState } from "./types";

import { isFeatureEnabled } from "@/plugins/FeatureFlags";
import {
  AuditResultChartData,
  AuditResultChartDataValue,
  AuditResultChartDataValues,
  CategoryLabel,
  Limit,
  Series,
  ShortLongText,
} from "@auditcloud/shared/lib/types/AuditResultChart";
import Chart from "chart.js";
import { contentLanguage } from "@/plugins/ContentTranslation";
import {
  ScoringConfig,
  CategeoryScoreResultRO,
} from "@auditcloud/shared/lib/auditResult/types";
import { DataTableHeader } from "vuetify";
import { UrgencyChartData } from "@auditcloud/shared/lib/types/UrgencyChart";

export function getAuditResultChartData(
  state: AuditResultState,
  scoringConfig: ScoringConfig
): AuditResultChartData | null {
  const categoryData = state.categoryScoreResult.data;
  const dimensionsData = state.dimensionsResult.data;

  if (categoryData && dimensionsData) {
    const categoryIds: string[] = categoryData.map(cd => cd.categoryRoot.id);

    const limits: AuditResultChartData["limits"] = [];
    if (isFeatureEnabled("featureRadarChartExtremePerformers")) {
      limits.push({
        long: "Top Performer",
        short: "Top",
        color: "green",
        value: 66.7,
      });
      limits.push({
        long: "Low Performer",
        short: "Low",
        color: "red",
        value: 33.3,
      });
    }

    const dataTable = createAuditResultChartDataTable(categoryIds);

    const calcChartValue = (
      categoryData: CategeoryScoreResultRO
    ): AuditResultChartDataValue => {
      if (
        categoryData.isDowngraded &&
        isNumber(categoryData.auditResultChartDataValue)
      ) {
        return 0;
      } else {
        return categoryData.auditResultChartDataValue;
      }
    };

    const MAGIC_TOTAL_ID = "4c4e8a67-84e0-46d3-ae1e-3c753569f323";
    dataTable.setValuesForDimension(
      MAGIC_TOTAL_ID,
      categoryData.map(cd => ({
        categoryId: cd.categoryRoot.id,
        value: calcChartValue(cd), //TODO not-applicable ... ?
      }))
    );

    dimensionsData.forEach(dimensionData => {
      dataTable.setValuesForDimension(
        dimensionData.dimension.id,
        dimensionData.categoryScoreResult.map(cd => ({
          categoryId: cd.categoryRoot.id,
          value: calcChartValue(cd), //TODO not-applicable ... ?
        }))
      );
    });

    const getCategoryLabelFromId = (categoryId: string): CategoryLabel => {
      const category = categoryData.find(
        cd => cd.categoryRoot.id === categoryId
      );
      if (category) {
        return {
          short: category.categoryRoot.short ?? "missing",
          long: category.categoryRoot.name,
        };
      } else {
        return {
          short: "",
          long: "",
        };
      }
    };
    const getDimensionNameFromId = (dimensionId: string): TranslateableText => {
      const dimension = dimensionsData.find(
        dd => dd.dimension.id === dimensionId
      );
      if (dimension) {
        return dimension.dimension.name;
      } else {
        return dimensionId === MAGIC_TOTAL_ID ? TEXT_TOTAL : UNKNOWN_DIMENSION;
      }
    };

    const auditResultChartData = dataTable.generateChartData(
      getCategoryLabelFromId,
      getDimensionNameFromId,
      limits,

      scoringConfig
    );

    return auditResultChartData;
  } else {
    return null;
  }
}

/** Returns an object that is used to generate an AuditResultChartData object */
export function createAuditResultChartDataTable(categoryIds: string[]) {
  const categoryValues = categoryIds.map(categoryId => {
    const values: AuditResultChartDataValue[] = [];
    return {
      categoryId,
      values,
    };
  });

  return {
    dimensionIds: [] as string[],
    categoryValues: categoryValues,
    setValuesForDimension(
      dimensionId: string,
      dimensionValues: {
        categoryId: string;
        value: AuditResultChartDataValue;
      }[]
    ) {
      this.dimensionIds.push(dimensionId);
      this.categoryValues.forEach(({ categoryId, values }) => {
        const valueForCategory = dimensionValues.find(
          dimensionValue => dimensionValue.categoryId === categoryId
        );
        if (valueForCategory) {
          values.push(valueForCategory.value);
        }
      });
    },
    generateChartData(
      getCategoryLabelFromId: (categoryId: string) => CategoryLabel,
      getDimensionNameFromId: (dimensionId: string) => TranslateableText,
      limits: AuditResultChartData["limits"],
      scoringConfig: ScoringConfig
    ): AuditResultChartData {
      const auditResultChartData: AuditResultChartData = {
        categoryLabels: [],
        limits,
        seriesArray: [],
      };

      if (scoringConfig.calculationMethod === "absolute") {
        auditResultChartData.config = {
          maxValue: scoringConfig.max,
          minValue: scoringConfig.min,
        };
      }

      auditResultChartData.categoryLabels = this.categoryValues.map(cv =>
        getCategoryLabelFromId(cv.categoryId)
      );

      auditResultChartData.seriesArray = this.dimensionIds.map((id, idx) => {
        const dimensionName = getDimensionNameFromId(id);
        return {
          short: dimensionName,
          long: dimensionName,
          color: colorForIdx(idx),
          values: this.categoryValues.map(cv => cv.values[idx]),
        };
      });

      return auditResultChartData;
    },
  };
}

/** Returns ChartJS compatible chart data and options for either an RadarChart or a BarChart component. */
export function convertAuditResultChartDataToChartData(
  auditResultChartData: AuditResultChartData,
  locale: string
): {
  chartComponent: string;
  chartData: Chart.ChartData;
  chartOptions: Chart.ChartOptions;
} {
  const useBarChart = auditResultChartData.categoryLabels.length < 3;

  // Prepare ChartData
  const extractLong = (categoryLabel: CategoryLabel) => categoryLabel.long;
  const extractShort = (categoryLabel: CategoryLabel) => categoryLabel.short;

  const labelExtrator = useBarChart ? extractLong : extractShort;

  const labels = auditResultChartData.categoryLabels.map(label =>
    tt2str(labelExtrator(label), locale)
  );

  const transformAuditResultChartDataValue = (
    value: AuditResultChartDataValue
  ): null | number => {
    switch (value) {
      case AuditResultChartDataValues.UNANSWERED:
        return null;
      case AuditResultChartDataValues.NOT_APPLICABLE:
        return null;
      default:
        return round(value, 2);
    }
  };

  /**  Lowers the opacity to 30% by appending an alpha value of '4D' to the hex color string.
   *
   * The hex color needs to be in the format of #000000.
   *
   * If no valid hex color is provided, it returns the string unchanged.
   */
  const lowerHexOpacity = (hexColor: string): string => {
    if (hexColor[0] !== "#" || hexColor.length !== 7) return hexColor;
    return hexColor + "4D";
  };

  const transformSeriesToRadarChartData = (
    series: Series
  ): Chart.ChartDataSets => {
    const data = series.values.map(transformAuditResultChartDataValue);
    return {
      data,
      pointBackgroundColor: series.color,
      backgroundColor: lowerHexOpacity(series.color),
      borderColor: series.color,
      label: tt2str(series.long, locale),
    };
  };

  const transformSeriesToBarChartData = (
    series: Series
  ): Chart.ChartDataSets => {
    const data = series.values.map(transformAuditResultChartDataValue);
    return {
      data,
      maxBarThickness: 50,
      backgroundColor: series.color,
      label: tt2str(series.long, locale),
    };
  };

  const seriesConverter = useBarChart
    ? transformSeriesToBarChartData
    : transformSeriesToRadarChartData;

  const transformLimitToRadarChartDataFactory =
    (fillCount: number) =>
    (limit: Limit): Chart.ChartDataSets => {
      return {
        data: new Array(fillCount).fill(limit.value),
        borderWidth: 1,
        backgroundColor: "transparent",
        pointBackgroundColor: "transparent",
        borderCapStyle: "round",
        borderColor: limit.color,
        pointBorderColor: "transparent",
        borderDash: [5, 5],
        label: tt2str(limit.long, locale),
      };
    };

  const radarChartLimitsConverter = transformLimitToRadarChartDataFactory(
    auditResultChartData.categoryLabels.length
  );
  const limitSeries = useBarChart
    ? [] // solved via plugin (see ACS-1889)
    : auditResultChartData.limits.map(radarChartLimitsConverter);
  const datasets = [
    ...limitSeries,
    ...auditResultChartData.seriesArray.map(seriesConverter),
  ];

  const chartData: Chart.ChartData = {
    labels,
    datasets,
  };

  // Prepare ChartOptions
  const tooltipLabels = auditResultChartData.categoryLabels.map(label =>
    tt2str(extractLong(label), locale)
  );

  /** Custom callback function used by ChartJS to change the displayed default tooltip title. */
  const customTooltipTitleCallback = (
    tooltipItems: Chart.ChartTooltipItem[],
    data: object
  ): string => {
    const N_A = "N/A";

    const categoryIndex = tooltipItems[0]?.index;
    if (typeof categoryIndex === "undefined") {
      return N_A;
    }
    return tooltipLabels[categoryIndex] ?? N_A;
  };

  const limits = auditResultChartData.limits.map(limit => {
    return {
      value: limit.value,
      borderColor: limit.color,
      borderWidth: 1,
      borderDash: [5],
      borderDashOffset: 0,
    };
  });

  const chartScaleMaxValue = auditResultChartData.config?.maxValue ?? 100;
  const chartScaleMinValue = auditResultChartData.config?.minValue ?? 0;

  const barChartSpecificOptions: Chart.ChartOptions = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: chartScaleMaxValue,
            min: chartScaleMinValue,
          },
        },
      ],
    },
    plugins: {
      aLimit: {
        limits: limits,
      },
    },
  };
  const radarChartSpecificOptions: Chart.ChartOptions = {
    scale: {
      ticks: {
        beginAtZero: true,
        max: chartScaleMaxValue,
        min: chartScaleMinValue,
      },
    },
  };
  const chartTypeSpecificOptions = useBarChart
    ? barChartSpecificOptions
    : radarChartSpecificOptions;

  const chartOptions: Chart.ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: "bottom",
      display: true,
    },
    animation: {
      duration: 0,
    },
    tooltips: {
      callbacks: {
        title: customTooltipTitleCallback,
      },
    },
    ...chartTypeSpecificOptions,
  };

  const chartComponent = useBarChart ? "BarChart" : "RadarChart";

  return { chartComponent, chartData, chartOptions };
}

/** Returns ChartJS compatible chart data and options for a BubbleChart component. */
export function convertUrgencyChartDataToChartData(
  urgencyChartData: UrgencyChartData,
  locale: string
): {
  chartData: Chart.ChartData;
  chartOptions: Chart.ChartOptions;
  tableData: { headers: DataTableHeader[]; items: any[] };
} {
  const extractLong = (text: ShortLongText) => text.long;
  const extractShort = (text: ShortLongText) => text.short;

  const chartData: Chart.ChartData = {
    datasets: urgencyChartData.values.map((val, idx) => {
      const color = colorForIdx(idx);
      return {
        label: `${tt2str(extractShort(val.label), locale)} - ${tt2str(
          extractLong(val.label),
          locale
        )}`,
        title: idx + 1,
        data: [
          {
            x: val.likelihood,
            y: val.urgency,
            r: val.impact * 6 + 10, // size factor
          },
        ],
        backgroundColor: color + "80",
        borderColor: color,
      };
    }),
  };

  const chartOptions: Chart.ChartOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    animation: { duration: 0 },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            labelString: tt2str({ de: "Dringlichkeit", en: "Urgency" }, locale),
            display: true,
          },
          ticks: {
            min: 0,
            max: 5,
            callback: function (value) {
              if (Number.isInteger(value) && value > 0 && value <= 4) {
                return value;
              }
            },
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            labelString: tt2str(
              {
                de: "Wahrscheinlichkeit",
                en: "Likelihood",
              },
              locale
            ),
            display: true,
          },
          ticks: {
            min: 0,
            max: 5,
            callback: function (value) {
              if (Number.isInteger(value) && value > 0 && value <= 4) {
                return value;
              }
            },
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        title: function (
          tooltipItems: Chart.ChartTooltipItem[]
        ): string | string[] {
          return tooltipItems.map(tooltipItem => {
            if (tooltipItem.value === undefined) {
              return "";
            }
            return "Urgency: " + tooltipItem.value;
          });
        },
        label: function (
          tooltipItem: Chart.ChartTooltipItem,
          data: Chart.ChartData
        ): string | string[] {
          // restrict label length for each item in the tooltip
          if (!data.datasets || tooltipItem.datasetIndex === undefined) {
            return "";
          }
          const label = data.datasets[tooltipItem.datasetIndex].label || "";
          const maxCharacters = 30;
          if (label && isString(label)) {
            return label.length > maxCharacters
              ? label.slice(0, maxCharacters) + "..."
              : label;
          }
          return label;
        },
      },
    },
  };

  const headers: DataTableHeader[] = [
    {
      text: tt2str({ de: "Nr.", en: "No." }, locale),
      value: "no",
      width: 0, // makes the header use the min-content width
      align: "end",
      sortable: false,
    },
    {
      text: tt2str({ de: "Feststellung", en: "Finding" }, locale),
      value: "finding",
      sortable: false,
    },
    {
      text: tt2str({ de: "Dringlichkeit", en: "Urgency" }, locale),
      value: "urgency",
      width: 0, // makes the header use the min-content width
      align: "end",
      sortable: false,
    },
  ];

  const items = urgencyChartData.values.map((value, idx) => {
    const no = idx + 1;
    const showMore = false;
    const finding = tt2str(value.label.long, locale);
    const urgency = value.urgency;
    return { no, showMore, finding, urgency };
  });

  const tableData = { headers, items };

  return { chartData, chartOptions, tableData };
}

export function simpleTableData(auditResultChartData: AuditResultChartData) {
  const { categoryLabels, seriesArray } = auditResultChartData;

  const abbreviation = categoryLabels.map(labels =>
    tt2str(labels.short, contentLanguage())
  );
  const category = categoryLabels.map(category =>
    tt2str(category.long, contentLanguage())
  );
  const totalSeries = seriesArray.find(series => series.long === TEXT_TOTAL);

  const averageValues = totalSeries
    ? totalSeries.values.map(aV =>
        isNumber(aV) ? aV.toFixed(2).toString() + " %" : "-"
      )
    : fill(Array(categoryLabels.length), "-");

  return zipWith(
    abbreviation,
    category,
    averageValues,
    (abbreviation, category, averageValues) => {
      return { abbreviation, category, averageValues };
    }
  );
}

export function simpleTableDataMaturity(
  auditResultChartData: AuditResultChartData
) {
  const { categoryLabels, seriesArray } = auditResultChartData;

  const abbreviation = categoryLabels.map(labels =>
    tt2str(labels.short, contentLanguage())
  );
  const category = categoryLabels.map(category =>
    tt2str(category.long, contentLanguage())
  );
  const series = seriesArray[0];

  const averageValues = series.values.map(maturityValue =>
    isNumber(maturityValue) ? maturityValue.toString() : "-"
  );

  return zipWith(
    abbreviation,
    category,
    averageValues,
    (abbreviation, category, averageValues) => {
      return { abbreviation, category, averageValues };
    }
  );
}
