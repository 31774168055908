import { AuditDimensionResultState, AuditResultState } from "./types";
import { StateGetterMutationPayload } from "../reporting/types";
import { mapValues } from "lodash";
import {
  AuditResultSteps,
  FlatAuditItemResultData,
} from "@auditcloud/shared/lib/types/AuditResult";
import {
  CategeoryScoreResultRO,
  ScoreResultRO,
} from "@auditcloud/shared/lib/auditResult/types";
import { ConstraintResult } from "@auditcloud/shared/lib/auditResult/utils";

const mutations = {
  CLEAR_RESULT(state: AuditResultState) {
    state.flatData.clear();
    state.scoreResult.clear();
    state.categoryScoreResult.clear();
    state.answerConstraintResults.clear();
  },
  SET_FLAT_RESULT_DATA(
    state: AuditResultState,
    payload: StateGetterMutationPayload<FlatAuditItemResultData[]>
  ) {
    if (payload.type === "data") {
      state.flatData.data = payload.val;
    } else if (payload.type === "error") {
      state.flatData.error = payload.val;
    }
  },
  SET_ANSWER_CONSTRAINT_RESULT_DATA(
    state: AuditResultState,
    payload: StateGetterMutationPayload<ConstraintResult[]>
  ) {
    if (payload.type === "data") {
      state.answerConstraintResults.data = payload.val;
    } else if (payload.type === "error") {
      state.answerConstraintResults.error = payload.val;
    }
  },
  SET_SCORE_RESULT_DATA(
    state: AuditResultState,
    payload: StateGetterMutationPayload<ScoreResultRO>
  ) {
    if (payload.type === "data") {
      state.scoreResult.data = payload.val;
    } else if (payload.type === "error") {
      state.scoreResult.error = payload.val;
    }
  },
  SET_CATEGORY_SCORE_DATA(
    state: AuditResultState,
    payload: StateGetterMutationPayload<CategeoryScoreResultRO[]>
  ) {
    if (payload.type === "data") {
      state.categoryScoreResult.data = payload.val;
    } else if (payload.type === "error") {
      state.categoryScoreResult.error = payload.val;
    }
  },
  SET_RESULT_STEP_DATA(
    state: AuditResultState,
    payload: StateGetterMutationPayload<AuditResultSteps>
  ) {
    if (payload.type === "data") {
      state.auditResultSteps.data = payload.val;
    } else if (payload.type === "error") {
      state.auditResultSteps.error = payload.val;
    }
  },
  SET_DIMENSIONS_RESULT(
    state: AuditResultState,
    payload: StateGetterMutationPayload<AuditDimensionResultState[]>
  ) {
    console.log("SET_DIMENSIONS_RESULT: MUT", payload);
    if (payload.type === "data") {
      state.dimensionsResult.data = payload.val;
      console.log("SET_DIMENSIONS_RESULT: MUT2", state.dimensionsResult);
    } else if (payload.type === "error") {
      state.dimensionsResult.error = payload.val;
    }
  },
};

const n = mapValues(mutations, (_, key) => key);

export { n as mutationNames, mutations };
