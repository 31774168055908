import Vue from "vue";
import { AuditsState, LoadAuditsConfig } from "./types";
import {
  DocumentsUpdateHandlerData,
  MetadataUpdateHandlerData,
  unlistenerFunction,
  WatchedDocument,
} from "@/utils/firestore";
import { AuditMetadataClient, extractAuditProgramId } from "@/types/Audit";
import { Dictionary, isNumber } from "lodash";
import { mapValues } from "lodash";
import { State } from "./types";

const mutations = {
  resetState(state: State) {
    const prev_unlistener = state.loadedAuditsUnlistener;
    if (prev_unlistener) {
      prev_unlistener();
    }
    Object.assign(state, new AuditsState());
  },
  SET_AUDITS_UNLISTENER(
    state: State,
    payload: { unlistener: unlistenerFunction; filter: LoadAuditsConfig }
  ) {
    state.loadedAuditsNewQuery = true;
    state.activeObserverFilter = payload.filter;
    const prev_unlistener = state.loadedAuditsUnlistener;
    Vue.set(state, "loadedAuditsUnlistener", payload.unlistener);
    if (prev_unlistener) {
      prev_unlistener();
    }
  },
  SET_AUDITS(
    state: State,
    { removeDocs, modifiedDocs }: DocumentsUpdateHandlerData
  ) {
    const prepareDoc = (
      doc: WatchedDocument<Dictionary<any>>
    ): WatchedDocument<AuditMetadataClient> => {
      const auditProgramId = extractAuditProgramId(doc.data);
      return {
        ...doc,
        data: {
          ...(doc.data as AuditMetadataClient),
          auditProgramId,
        },
      };
    };
    if (state.loadedAuditsNewQuery === true) {
      state.loadedAudits = modifiedDocs.map(prepareDoc);
    } else {
      const removeDocsIds = new Set(removeDocs);
      const loadedAudits = state.loadedAudits.filter(
        audit => !removeDocsIds.has(audit.id)
      );
      const id2Idx = new Map(
        loadedAudits.map((audit, idx): [string, number] => {
          return [audit.id, idx];
        })
      );

      modifiedDocs.forEach(doc => {
        const preparedDoc = prepareDoc(doc);
        const idx = id2Idx.get(doc.id);

        if (isNumber(idx)) {
          loadedAudits[idx] = preparedDoc;
        } else {
          loadedAudits.push(preparedDoc);
        }
      });

      state.loadedAudits = loadedAudits;
    }

    state.loadedAuditsNewQuery = false;
  },
  SET_AUDITS_METADATA(state: State, updateMetadata: MetadataUpdateHandlerData) {
    if (state.loadedAuditsNewQuery === true) {
      state.loadedAudits = [];
    } /* else {
      updateMetadata.forEach(doc => {
        const idx = state.loadedAudits.findIndex(v => v.id === doc.id);
        if (idx > -1) {
          state.loadedAudits[idx].exists = doc.exists;
          state.loadedAudits[idx].metadata = doc.metadata;
        }
      });
    }*/
  },
  CLEAR_AUDITS(state: State, payload) {
    const prev_unlistener = state.loadedAuditsUnlistener;
    // To prevent that problems with the execution order of the components lifecycle hook will messup the audits watcher
    if ((payload && payload === prev_unlistener) || payload === null) {
      state.loadedAuditsUnlistener = null;
      state.loadedAudits = [];
      state.activeObserverFilter = null;
      if (prev_unlistener) {
        prev_unlistener();
      }
    }
  },
  SET_SELECTED_YEAR(state: State, year?: number | null) {
    state.selectedYear = year ?? null;
  },
  SET_SELECTED_PROGRAM(state: State, program?: string | null) {
    state.selectedProgram = program ?? null;
  },
  SET_SELECTED_ORGANIZATION_UNIT(
    state: State,
    organizationUnitId?: string | null
  ) {
    state.selectedOrganizationUnit = organizationUnitId ?? null;
  },
};

const n = mapValues(mutations, (_, key) => key);

export { n as mutationNames, mutations };
