























































































































































































































































































































/* tslint:disable:no-console */
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

import { api } from "@/store/modules/measureProcess";
import { MeasureProcessMetadata } from "@/store/modules/measureProcess/types";
import AUserChip from "@/components/snippets/AUserChip.vue";
import ADateChip from "@auditcloud/components/snippets/ADateChip.vue";
import AActivityItem from "@/components/controls/AActivityItem.vue";
import AMeasureProsessStepsWidget from "@/components/widgets/AMeasureProsessStepsWidget.vue";

import AWorkflowLog from "@/components/controls/AWorkflowLog.vue";

import { nullable } from "@auditcloud/shared/lib/types/common";
import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";
import { MeasureActivity } from "@/types/measure-activities";
import AMeasureActivityStep from "@/components/controls/MeasureActivitySteps/AMeasureActivityStep.vue";
import AMentionInput from "@/components/controls/AMentionInput.vue";

import { api as userApi, Getters as UserGetters } from "@/store/modules/user";
import { displayName2AvatarText } from "@/utils/user_resolver";
import { Attachment } from "../types";

@Component({
  components: {
    AMeasureProsessStepsWidget,
    AMeasureActivityStep,
    AActivityItem,
    AMentionInput,
    AUserChip,
    ADateChip,
    AWorkflowLog,
  },
})
export default class AMeasureProcessDialog extends Vue {
  loading: boolean = false;
  commentLoading: boolean = false;
  commentError: string | null = null;
  found: boolean = false;
  commentInput: string = "";

  //missingDefault
  @Prop({
    type: String,
  })
  readonly measureProcessId!: string;

  @Getter(api.getters.getMeasureProcessId, {
    namespace: api.namespace,
  })
  storedMeasureProcessId!: string | null;

  @Getter(api.getters.getMeasureProcessMetadata, {
    namespace: api.namespace,
  })
  measureProcessMetadata!: MeasureProcessMetadata | null;

  @Getter(api.getters.getMeasureProcessActivities, {
    namespace: api.namespace,
  })
  measureProcessActivities!: MeasureActivity[];

  @Action(api.actions.setMeasureProcess, { namespace: api.namespace })
  setMeasureProcessId!: (measureProcessId: string) => Promise<boolean>;

  @Action(api.actions.clearMeasureProcess, { namespace: api.namespace })
  clearMeasureProcess!: () => Promise<boolean>;

  @Action(api.actions.sendComment, { namespace: api.namespace })
  sendComment!: (payload: {
    comment: string;
    files: Attachment[];
  }) => Promise<string>;

  @Getter(userApi.getters.getCurrentUserRef, {
    namespace: userApi.namespace,
  })
  user!: UserGetters["getCurrentUserRef"];

  isClosed = false;

  get filters(): Array<{ title: string; type: string | null }> {
    return [
      {
        title: this.$t(
          "components.dialogs.measure_process_dialog.filters_all_entries"
        ).toString(),
        type: null,
      },
      {
        title: this.$t(
          "components.dialogs.measure_process_dialog.filters_comments"
        ).toString(),
        type: "MeasureActivityComment",
      },
      {
        title: this.$t(
          "components.dialogs.measure_process_dialog.filters_verifications"
        ).toString(),
        type: "MeasureActivityStepVerified",
      },
      {
        title: this.$t(
          "components.dialogs.measure_process_dialog.filters_assignments"
        ).toString(),
        type: "MeasureActivityAssign",
      },
      {
        title: this.$t(
          "components.dialogs.measure_process_dialog.filters_attachments"
        ).toString(),
        type: "MeasureActivityAddAttachment",
      },
      {
        title: this.$t(
          "components.dialogs.measure_process_dialog.filters_updates"
        ).toString(),
        type: "MeasureActivityUpdate",
      },
      {
        title: this.$t(
          "components.dialogs.measure_process_dialog.filters_assumption"
        ).toString(),
        type: "MeasureActivityAccept",
      },
      {
        title: this.$t(
          "components.dialogs.measure_process_dialog.filters_rejection"
        ).toString(),
        type: "MeasureActivityReject",
      },
      {
        title: this.$t(
          "components.dialogs.measure_process_dialog.filters_new_tasks"
        ).toString(),
        type: "MeasureActivityAddTask",
      },
    ];
  }
  currentFilter: string | null = null;

  files: File[] = [];

  selectFiles() {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("multiple", "true");
    input.style.visibility = "hidden";
    document.body.appendChild(input);
    input.addEventListener("change", (e: Event) => {
      if ((e.target as any).files instanceof FileList) {
        const files: FileList = (e.target as any).files;
        this.files = [...this.files, ...Array.from(files)];
      }
      document.body.removeChild(input);
    });
    input.click();
  }

  removeFile(i) {
    this.files = [...this.files.slice(0, i), ...this.files.slice(i + 1)];
  }

  get attachments() {
    return this.measureProcessActivities.filter(
      a => a.type === "MeasureActivityAddAttachment"
    );
  }

  get dialog() {
    return !this.isClosed && this.storedMeasureProcessId !== null;
  }

  get responsible(): nullable<IUserRef> {
    if (this.measureProcessMetadata === null) {
      return null;
    } else {
      return this.measureProcessMetadata.responsible;
    }
  }

  get date(): Date {
    return new Date();
  }

  get filteredMeasureProcessActivities(): MeasureActivity[] {
    return this.measureProcessActivities.filter(
      m => m.type === this.currentFilter || !this.currentFilter
    );
  }

  closeDialog() {
    this.isClosed = true;
    setTimeout(() => {
      this.$router.back();
    }, 250);
  }

  created() {
    console.log("MeasureProcessDialog:created");
  }

  mounted() {
    console.log("MeasureProcessDialog:mounted");
  }

  beforeDestroy() {
    this.clearMeasureProcess();
    console.log("MeasureProcessDialog:beforeDestroy");
  }

  @Watch("measureProcessId", { immediate: true, deep: true })
  async onMeasureProcessIdChanged() {
    this.loading = true;
    try {
      await this.clearMeasureProcess();
      this.found = await this.setMeasureProcessId(this.measureProcessId);
    } catch (e) {
      console.error("load measure process failed", e);
    }
    this.loading = false;
  }

  get allowCommentSend(): boolean {
    return this.commentInput.trim() !== "";
  }

  uploadVisible = false;

  addFiles(e) {
    this.files = [...this.files, ...e.dataTransfer.files];
    this.uploadVisible = false;
  }

  get firstLetters() {
    return displayName2AvatarText(this.user?.displayName || "#");
  }

  async createComment() {
    if (this.allowCommentSend) {
      this.commentError = null;
      this.commentLoading = true;

      try {
        // TODO: files as Attachments
        /*
        const fileUploads = await Promise.all(
          this.files.map(f =>
            uploadFile(f, { measureProcessId: this.measureProcessId })
          )
        );
        console.log("File Uploads:", fileUploads);
        */

        await this.sendComment({
          files: [],
          comment: this.commentInput,
        });

        this.commentInput = "";
        this.files = [];
      } catch (e) {
        console.error("send comment failed", e);
        this.commentError = "Speichern fehlgeschlagen";
      }

      this.commentLoading = false;
    }
  }
}
