















































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ADateChip from "@auditcloud/components/snippets/ADateChip.vue";
import AUserChip from "@/components/snippets/AUserChip.vue";
import SnippetMeasureWorkflow from "@/components/snippets/SnippetMeasureWorkflow.vue";

import { ROUTE_NAMES } from "@/routenames";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import { MetaStatus } from "@auditcloud/shared/lib/workflow/types/State";
import { api as measureWorksFlowApi } from "@/store/modules/measureWorkflows";
import { MeasureWorkflow } from "@auditcloud/shared/lib/workflow/modules/Measure";
import { MeasureTypeId } from "@auditcloud/shared/lib/constants/index";
import { Getter } from "vuex-class";
import { Dictionary } from "lodash";

@Component({
  components: {
    AUserChip,
    ADateChip,
    SnippetMeasureWorkflow,
  } /*, directives: { Ripple } */,
})
export default class AMeasureItem extends Vue {
  MeasureTypeId = MeasureTypeId;

  //missingDefault
  @Prop({
    type: Object,
    required: true,
  })
  measure!: MeasureProcessDocument;

  @Getter(measureWorksFlowApi.getters.getMappedMeasureWorkflows, {
    namespace: measureWorksFlowApi.namespace,
  })
  mappedMeasureWorkFlows!: Dictionary<MeasureWorkflow>;

  get dueDate(): Date | string | null {
    return this.measure.dueDate
      ? new Date(this.measure.dueDate)
      : this.measure.dueDate;
  }

  get auditName() {
    return this.measure.auditRef.name;
  }

  get title() {
    const data = [this.measure.id];
    if (this.measure.description.length > 0) {
      data.push(this.measure.description);
    }
    return data.join(" - ");
  }

  get workflowId() {
    return this.measure.workflow.workflowId;
  }

  get statusId() {
    return this.measure.workflow.statusId;
  }

  get assignedTo() {
    return this.measure.userRefs.assignedTo;
  }

  get metaState(): MetaStatus {
    return this.measure.workflow.metaState;
  }

  openMeasure() {
    const measureId = this.measure.id;
    if (measureId) {
      console.log("Ein Audit wird geöffnet:", this.measure);
      this.$router.push({
        name: ROUTE_NAMES.DASHBOARDSCOPED_EDIT_MEASURE,
        params: {
          measureId,
        },
      });
    }
  }
}
