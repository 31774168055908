






















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { namespace } from "vuex-class";
import { api as auditApi } from "@/store/modules/audit";
import { api as auditResultApi } from "@/store/modules/auditResult";
import StatGetter from "@/components/layouts/BaseLayouts/StatGetter.vue";
import AFinalAuditResult from "./AFinalAuditResult.vue";

import {
  MissedConstraintResultData,
  FinalResultForDimensions,
} from "@/store/modules/auditResult/getters";
import { TEXT_TOTAL } from "@auditcloud/shared/lib/constants";
import { AuditProgress } from "@/store/modules/audit/types";
import { StatedGetter } from "@/store/modules/reporting/types";
import { FinalResult } from "@auditcloud/shared/lib/types/AuditResult";
import { TranslateableText } from "@auditcloud/shared/lib/schemas";

const auditModule = namespace(auditApi.namespace);

@Component({
  components: { StatGetter, AFinalAuditResult },
})
export default class AAuditReportOverview extends Vue {
  /** Option for displaying the interim report's UI */
  @Prop({
    type: Boolean,
    default: false,
  })
  showQuestionsAnswered!: boolean;

  @Getter(auditResultApi.getters.getFinalResult, {
    namespace: auditResultApi.namespace,
  })
  final!: StatedGetter<FinalResult>;

  @Getter(auditResultApi.getters.getResultCalculationNote, {
    namespace: auditResultApi.namespace,
  })
  resultCalculationNote!: TranslateableText | null;

  @Getter(auditResultApi.getters.getFinalResultForDimensions, {
    namespace: auditResultApi.namespace,
  })
  resultForDimensions!: FinalResultForDimensions;

  @auditModule.Getter(auditApi.getters.getAuditProgress)
  currentAuditProgress!: AuditProgress;

  get auditProgressText() {
    return `${this.$t("views.audit.proportion_answered_findings", {
      answered_count: this.currentAuditProgress.audititem_answered_count,
      total_count: this.currentAuditProgress.audititem_count,
    })} (${this.$t("views.audit.percentage_answered_findings_short", {
      percentage: this.currentAuditProgress.progress,
    })})`;
  }

  @Getter(auditResultApi.getters.getMissedConstraints, {
    namespace: auditResultApi.namespace,
  })
  missedConstrains!: MissedConstraintResultData[];

  get results() {
    if (!this.final.data) {
      return [];
    }

    if (this.resultForDimensions.length > 0) {
      return [
        {
          title: TEXT_TOTAL,
          ...this.final.data,
        },
        ...this.resultForDimensions.map(res => {
          return {
            ...res.finalResult,
            title: res.dimension.name,
          };
        }),
      ];
    } else {
      return [
        {
          title: null,
          ...this.final.data,
        },
      ];
    }
  }
}
