import { itemsJsCompareFunctions } from "@/utils/itemjs/sort";

export const auditStatusesInOrder = [
  "common.workflow_description.planning", // first (asc)
  "common.workflow_description.preparation",
  "common.workflow_description.execution",
  "common.workflow_description.postprocessing",
  "common.workflow_description.reporting",
  "common.workflow_description.measures",
  "common.workflow_description.finalized",
  "common.workflow_description.canceled", // last
];

export function compareByAuditStatus(a: string, b: string) {
  return itemsJsCompareFunctions.audit_status({ key: a }, { key: b });
}
export function compareBySelfAssessmentStatus(a: string, b: string) {
  return itemsJsCompareFunctions.self_assessment_status({ key: a }, { key: b });
}
