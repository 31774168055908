























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ADateSnippet from "../snippets/ADateSnippet.vue";
import AUserSnippet from "../snippets/AUserSnippet.vue";
import moment from "moment";
import { WorkflowHistoryEntry } from "@auditcloud/shared/lib/schemas";
@Component({
  components: {
    ADateSnippet,
    AUserSnippet,
  },
})
export default class ALog extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  readonly workflow!: WorkflowHistoryEntry;

  get today() {
    return moment().toISOString();
  }
  get in2Weeks() {
    return moment().add(2, "weeks").toISOString();
  }
  get before2Weeks() {
    return moment().subtract(2, "weeks").toISOString();
  }
}
