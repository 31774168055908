































import { api as templateApi } from "@/store/modules/template";
import { isNumber, toInteger } from "lodash";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const templateModule = namespace(templateApi.namespace);
type QuestionNumberType = string | number;

@Component({})
export default class AAuditItemNumberInput extends Vue {
  questionNumber: QuestionNumberType = 0;

  get formValid() {
    return this.numberIsValid;
  }

  set formValid(valid: boolean) {
    this.$emit("validityChange", valid);
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: [String, Number],
    default: "",
  })
  value!: QuestionNumberType;

  @Watch("value", {
    immediate: true,
  })
  onValueChanged(newValue: QuestionNumberType) {
    this.questionNumber = newValue;
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  itemsNoTypeIsNumber!: boolean;

  @Prop({
    type: [Number, String],
    default: 1,
    validator: v => isNumber(v) || typeof v === "string",
  })
  resetTo!: QuestionNumberType;

  @Prop({
    type: Boolean,
    default: true,
  })
  numberIsValid!: boolean;

  @templateModule.Getter(templateApi.getters.getAuditItemsNumbers)
  auditItemsNumbers!: (string | number)[];

  @templateModule.Getter(templateApi.getters.getForceUniqueNumbers)
  forceUniqueNumbers!: boolean;

  get auditItemCount(): number {
    return this.auditItemsNumbers.length;
  }

  get rulesNumberNo() {
    if (this.disabled) {
      return [];
    }
    return [
      v => {
        return (
          (v <= this.auditItemCount && v > 0) ||
          this.$t("common.rules.number_must_be_between", {
            min: 1,
            max: this.auditItemCount,
          }).toString()
        );
      },
    ];
  }

  get rulesTextNo() {
    if (this.disabled || !this.forceUniqueNumbers) {
      return [];
    }
    return [
      v => {
        return (
          !this.auditItemsNumbers.includes(v) ||
          v === this.resetTo ||
          this.$t(
            "components.dialogs.audit_item_dialog_json.error_questionno_exists"
          ).toString()
        );
      },
    ];
  }

  changed(eventData: QuestionNumberType) {
    if (this.itemsNoTypeIsNumber) {
      this.$emit("input", toInteger(eventData));
    } else {
      this.$emit("input", eventData);
    }
  }

  resetNumber() {
    this.changed(this.resetTo);
  }
}
