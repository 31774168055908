





















































import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { State, Action, Getter, Mutation } from "vuex-class";

import VJsoneditor from "v-jsoneditor";
import _, { Dictionary } from "lodash";

import { api, FormSchema, FormDocument } from "@/store/modules/schemas";
import { api as appApi } from "@/store/modules/app";
import { getterNs } from "../../utils/VuexHelper";
import { TodoAny } from "@auditcloud/shared/lib/utils/type-guards";
import { ROUTE_NAMES } from "../../routenames";
import { WatchedDocument } from "../../utils/firestore";

@Component({
  components: { VJsoneditor },
})
export default class AFormSchemaEditor extends Vue {
  editedData: null | FormDocument = null;
  loading: boolean = false;
  valid: boolean = true;
  createMode: boolean = false;
  newFormSchemaId: string = "";
  options = {
    templates: [],
    mode: "form",
    modes: ["tree", "form", "code", "text"], // // v-if="$user.isAdmin()"
  };

  //missingDefault
  @Prop({ type: String, required: true })
  segmentName!: string;

  //missingDefault
  @Prop({ type: String, default: null })
  formSchemaId!: string | null;

  @Getter(api.getters.list, { namespace: api.namespace })
  formularList!: Array<{ id: string; name: string }>;

  @Getter(api.getters.mappedSchemas, { namespace: api.namespace })
  mappedSchemas!: Dictionary<WatchedDocument<FormDocument>>;

  @Watch("formSchemaId", {
    immediate: true,
  })
  onFormSchemaIdChange(val: string | null, oldVal: string | null) {
    console.log("onFormSchemaIdChange", val, oldVal);
    this.createMode = false;
    this.loading = false;
    this.valid = true;
    this.editedData = this.storedData;
  }

  get storedData() {
    if (this.formSchemaId !== null && this.formSchemaId in this.mappedSchemas) {
      return _.cloneDeep(this.mappedSchemas[this.formSchemaId].data);
    } else {
      return null;
    }
  }
  get isDirty() {
    return !_.isEqual(this.storedData, this.editedData);
  }

  @Action(api.actions.updateSchema, { namespace: api.namespace })
  updateSchema!: (payload: {
    formSchemaId: string;
    data: FormDocument;
  }) => Promise<void>;

  @Action(api.actions.createSchema, { namespace: api.namespace })
  createSchema!: (payload: FormDocument) => Promise<string>;

  @Action(appApi.actions.setStatus, { namespace: appApi.namespace })
  setStatus!: (payload: string) => Promise<any>;

  async importJsonConf() {
    console.log(
      "importJsonConf",
      this.segmentName,
      this.editedData,
      this.valid,
      this.isDirty
    );
    if (
      this.valid &&
      this.isDirty &&
      this.editedData !== null &&
      (this.formSchemaId !== null || this.createMode)
    ) {
      this.loading = true;
      await this.setStatus("loading");
      try {
        console.log("Start Save");
        if (this.createMode) {
          const newFormSchemaId = await this.createSchema(this.editedData);
          this.onFormSchemaSelected(newFormSchemaId);
        } else if (this.formSchemaId !== null) {
          const payload = {
            formSchemaId: this.formSchemaId,
            data: this.editedData,
          };
          console.log("Importing", payload);
          await this.updateSchema(payload);
        }

        await this.setStatus("success");
      } catch (error) {
        console.log("Json Parse failed:", error);
        await this.setStatus("error");
      }
      this.loading = false;
    }
  }
  onError(err: TodoAny) {
    console.error("AConfigEditor:onError", err);
    this.valid = false;
  }
  onInput(data: null | FormDocument) {
    this.valid = true;
    this.editedData = data;
  }

  onFormSchemaSelected(formSchemaId: string) {
    console.log("Goto", formSchemaId);
    this.$router.push({
      name: ROUTE_NAMES.CONFIGURATION_FORMSCHEMA,
      params: {
        segmentName: this.segmentName,
        formSchemaId,
      },
    });
  }
  setCreateMode() {
    this.createMode = true;
    this.editedData = {
      formId:
        "metadata-form|audititem-form|finding-form|planning-data|preparation-form|preselect-form|reporting-form",
      groupId: "audit",
      schema: {
        $id: "{groupId}.{formId}.{auditClass}.schema.json",
        properties: {
          audit_class: {
            oneOf: [
              {
                type: "string",
              },
              {
                type: "null",
              },
            ],
          },
          leadauditor: {
            $ref: "{groupId}.{formId}.{auditClass}.schema.json#/definitions/UserRef",
          },
          coauditors: {
            items: {
              $ref: "{groupId}.{formId}.{auditClass}.schema.json#/definitions/UserRef",
            },
            type: "array",
          },
          audited_by: {
            type: "string",
          },
          auditing_date: {
            items: {
              type: "string",
              format: "date",
            },
            type: "array",
          },
          audit_participants: {
            items: {
              $ref: "{groupId}.{formId}.{auditClass}.schema.json#/definitions/UserRef",
            },
            type: "array",
          },
          process: {
            oneOf: [
              {
                items: {
                  type: "string",
                },
                type: "array",
              },
              {
                type: "null",
              },
            ],
          },
          responsible: {
            $ref: "{groupId}.{formId}.{auditClass}.schema.json#/definitions/OptionalUserRef",
          },
          type: {
            type: "string",
          },
          planning_year: {
            type: "number",
          },
          name: {
            oneOf: [{ type: "string", minLength: 1 }, { type: null }],
          },
          implementation: {
            type: "string",
            enum: ["remote", "on-site"],
          },
          standardRefs: {
            type: "array",
            items: {
              $ref: "{groupId}.{formId}.{auditClass}.schema.json#/definitions/AuditStandardRef",
            },
          },
          scope: {
            oneOf: [
              {
                type: "string",
              },
              {
                type: "object",
              },
            ],
          },
        },
        required: [
          "name",
          "standardRefs",
          "scope",
          "implementation",
          "leadauditor",
          "planning_year",
        ],
        type: "object",
        definitions: {
          AuditStandardRef: {
            properties: {
              id: {
                type: "string",
              },
              name: {
                type: "string",
              },
            },
            required: ["id", "name"],
            type: "object",
          },
          UserRef: {
            properties: {
              email: {
                type: "string",
              },
              id: {
                type: "string",
              },
              firebase_id: {
                type: "string",
              },
              displayName: {
                type: "string",
              },
            },
            required: ["id", "displayName"],
            type: "object",
          },
          OptionalUserRef: {
            oneOf: [
              {
                type: "null",
              },
              {
                $ref: "{groupId}.{formId}.{auditClass}.schema.json#/definitions/UserRef",
              },
            ],
          },
        },
        $schema: "http://json-schema.org/draft-07/schema#",
      },
      auditClass: "qm",
      ui: [
        {
          fieldOptions: {
            class: ["container--fluid"],
            attrs: {
              fluid: true,
            },
          },
          component: "v-container",
          children: [
            {
              fieldOptions: {},
              component: "v-row",
              children: [
                {
                  children: [
                    {
                      component: "AAuditNameField",
                      valueModel: true,
                      valueProp: "auditMeta",
                      fieldOptions: {
                        class: ["xs12"],
                        attrs: {
                          readonly: false,
                          label: {
                            "i18n-key": "schema_forms.common.audit_name",
                          },
                          disabled: false,
                        },
                        on: ["input"],
                      },
                      model: "name",
                      errorOptions: {
                        attrs: {
                          "error-messages": {
                            "i18n-key":
                              "schema_forms.common.err_field_required",
                          },
                          error: true,
                        },
                      },
                    },
                    {
                      errorOptions: {
                        attrs: {
                          error: true,
                          "error-messages": {
                            "i18n-key":
                              "schema_forms.common.err_field_required",
                          },
                        },
                      },
                      model: "standardRefs",
                      fieldOptions: {
                        attrs: {
                          items: [
                            {
                              id: "iso9001-2015",
                              name: "ISO 9001:2015",
                            },
                          ],
                          "item-text": "name",
                          "item-value": "id",
                          "return-object": true,
                          label: {
                            "i18n-key": "schema_forms.common.audited_standard",
                          },
                          readonly: false,
                          multiple: true,
                          disabled: false,
                        },
                        class: ["xs12"],
                        on: ["input"],
                      },
                      component: "v-select",
                    },
                    {
                      fieldOptions: {
                        attrs: {
                          readonly: false,
                          disabled: false,
                          items: [
                            {
                              value: "Prozessaudit",
                              text: {
                                "i18n-key": "schema_forms.common.process_audit",
                              },
                            },
                            {
                              text: {
                                "i18n-key": "schema_forms.common.system_audit",
                              },
                              value: "Systemaudit",
                            },
                          ],
                          label: {
                            "i18n-key": "schema_forms.common.audit_type",
                          },
                        },
                        on: ["input"],
                        class: ["xs12"],
                      },
                      model: "type",
                      errorOptions: {
                        attrs: {
                          "error-messages": {
                            "i18n-key":
                              "schema_forms.common.err_field_required",
                          },
                          error: true,
                        },
                      },
                      component: "v-select",
                    },
                    {
                      model: "implementation",
                      component: "v-select",
                      errorOptions: {
                        attrs: {
                          "error-messages": {
                            "i18n-key":
                              "schema_forms.common.err_field_required",
                          },
                          error: true,
                        },
                      },
                      fieldOptions: {
                        class: ["xs12"],
                        attrs: {
                          items: [
                            {
                              text: {
                                "i18n-key":
                                  "schema_forms.common.audit_implementation_on_site",
                              },
                              value: "on-site",
                            },
                            {
                              value: "remote",
                              text: {
                                "i18n-key":
                                  "schema_forms.common.audit_implementation_remote",
                              },
                            },
                            {
                              value: "mixed",
                              text: {
                                "i18n-key":
                                  "schema_forms.common.audit_implementation_mixed",
                              },
                            },
                          ],
                          label: {
                            "i18n-key":
                              "schema_forms.common.audit_implementation",
                          },
                          disabled: false,
                          readonly: false,
                        },
                        on: ["input"],
                      },
                    },
                    {
                      errorOptions: {
                        attrs: {
                          "error-messages": {
                            "i18n-key":
                              "schema_forms.common.err_field_required",
                          },
                          error: true,
                        },
                      },
                      model: "audited_by",
                      fieldOptions: {
                        attrs: {
                          readonly: false,
                          disabled: false,
                          items: ["Intern"],
                          label: {
                            "i18n-key": "schema_forms.common.audit_party",
                          },
                        },
                        class: ["xs12"],
                        on: ["input"],
                      },
                      component: "v-select",
                    },
                  ],
                  fieldOptions: {
                    attrs: {
                      md: "3",
                      cols: "12",
                    },
                    class: ["col-12 col-md-3 pa-3"],
                  },
                  component: "v-col",
                },
                {
                  fieldOptions: {
                    attrs: {
                      cols: "12",
                      md: "3",
                    },
                    class: ["col-12 col-md-3 pa-3"],
                  },
                  component: "v-col",
                  children: [
                    {
                      component: "v-autocomplete",
                      fieldOptions: {
                        on: ["input"],
                        attrs: {
                          label: {
                            "i18n-key": "schema_forms.common.audited_location",
                          },
                          disabled: false,
                          "item-text": "name",
                          "item-value": "id",
                          readonly: false,
                          items: [
                            {
                              id: "de",
                              name: "Germany",
                            },
                            {
                              name: "France",
                              id: "fr",
                            },
                            {
                              name: "Spain",
                              id: "es",
                            },
                            {
                              name: "Netherlands",
                              id: "nl",
                            },
                            {
                              name: "Poland",
                              id: "pl",
                            },
                            {
                              name: "Czechia",
                              id: "cz",
                            },
                          ],
                        },
                        class: ["xs12"],
                      },
                      model: "scope",
                      errorOptions: {
                        attrs: {
                          error: true,
                          "error-messages": {
                            "i18n-key":
                              "schema_forms.common.err_field_required",
                          },
                        },
                      },
                    },
                    {
                      fieldOptions: {
                        attrs: {
                          disabled: false,
                          readonly: false,
                          multiple: true,
                          label: {
                            "i18n-key": "schema_forms.common.audited_process",
                          },
                          items: [
                            "FuE",
                            "IT",
                            "Supply Chain Management",
                            "Qualitätsmanagement",
                            "Marketing",
                            "Vertrieb",
                            "Controlling",
                            "Finanzen",
                            "Personalwesen",
                            "Allgemeine Verwaltung",
                            "Außenbereiche",
                            "ohne",
                          ],
                          deletable: true,
                        },
                        class: ["xs12"],
                        on: ["input"],
                      },
                      displayOptions: {
                        schema: {
                          type: "string",
                          pattern: "Prozessaudit",
                        },
                        model: "type",
                      },
                      errorOptions: {
                        attrs: {
                          error: true,
                          "error-messages": {
                            "i18n-key":
                              "schema_forms.common.err_field_required",
                          },
                        },
                      },
                      model: "process",
                      component: "v-autocomplete",
                    },
                  ],
                },
                {
                  children: [
                    {
                      component: "AUserAutocompleteSingle",
                      fieldOptions: {
                        class: ["xs12"],
                        on: ["input"],
                        attrs: {
                          "items-getter": {
                            namespace: "users",
                            name: "getAuditors",
                          },
                          disabled: false,
                          "deletable-chips": true,
                          readonly: false,
                          label: {
                            "i18n-key": "schema_forms.common.lead_auditor",
                          },
                          chips: true,
                        },
                      },
                      errorOptions: {
                        attrs: {
                          error: true,
                          "error-messages": {
                            "i18n-key":
                              "schema_forms.common.err_field_required",
                          },
                        },
                      },
                      model: "leadauditor",
                    },
                    {
                      fieldOptions: {
                        attrs: {
                          chips: true,
                          readonly: false,
                          disabled: false,
                          "items-getter": {
                            namespace: "users",
                            name: "getAuditors",
                          },
                          "deletable-chips": true,
                          label: {
                            "i18n-key": "schema_forms.common.co_auditors",
                          },
                        },
                        on: ["input"],
                        class: ["xs6"],
                      },
                      component: "AUserAutocompleteMultiple",
                      errorOptions: {
                        attrs: {
                          "error-messages": {
                            "i18n-key":
                              "schema_forms.common.err_field_required",
                          },
                          error: false,
                        },
                      },
                      model: "coauditors",
                    },
                    {
                      errorOptions: {
                        attrs: {
                          "error-messages": {
                            "i18n-key":
                              "schema_forms.common.err_field_required",
                          },
                          error: false,
                        },
                      },
                      component: "AUserAutocompleteSingle",
                      fieldOptions: {
                        attrs: {
                          disabled: false,
                          readonly: false,
                          label: {
                            "i18n-key":
                              "schema_forms.common.responsibile_person",
                          },
                          chips: true,
                          "items-getter": {
                            name: "getLoadedUserRefs",
                            namespace: "users",
                          },
                          "deletable-chips": true,
                          "allow-user-creation": true,
                        },
                        on: ["input"],
                        class: ["xs12"],
                      },
                      model: "responsible",
                    },
                    {
                      fieldOptions: {
                        on: ["input"],
                        class: ["xs12"],
                        attrs: {
                          readonly: false,
                          disabled: false,
                          label: {
                            "i18n-key":
                              "schema_forms.common.audit_participants",
                          },
                          "deletable-chips": true,
                          "items-getter": {
                            namespace: "users",
                            name: "getLoadedUserRefs",
                          },
                          chips: true,
                          "allow-user-creation": true,
                        },
                      },
                      component: "AUserAutocompleteMultiple",
                      errorOptions: {
                        attrs: {
                          error: false,
                          "error-messages": {
                            "i18n-key":
                              "schema_forms.common.err_field_required",
                          },
                        },
                      },
                      model: "audit_participants",
                    },
                  ],
                  fieldOptions: {
                    class: ["col-12 col-md-3 pa-3"],
                    attrs: {
                      md: "3",
                      cols: "12",
                    },
                  },
                  component: "v-col",
                },
                {
                  children: [
                    {
                      fieldOptions: {
                        on: ["input"],
                        attrs: {
                          label: {
                            "i18n-key": "schema_forms.common.planning_period",
                          },
                          items: [2019, 2020, 2021, 2022],
                          readonly: false,
                          disabled: false,
                        },
                        class: ["xs12"],
                      },
                      component: "v-select",
                      model: "planning_year",
                      errorOptions: {
                        attrs: {
                          error: true,
                          "error-messages": {
                            "i18n-key":
                              "schema_forms.common.err_field_required",
                          },
                        },
                      },
                    },
                    {
                      component: "a-menu-date-picker",
                      errorOptions: {
                        attrs: {
                          "error-messages": {
                            "i18n-key":
                              "schema_forms.common.err_field_required",
                          },
                          error: true,
                        },
                      },
                      model: "auditing_date",
                      fieldOptions: {
                        class: ["xs12"],
                        attrs: {
                          readonly: true,
                          "return-string": true,
                          multiple: true,
                          label: {
                            "i18n-key":
                              "schema_forms.common.planned_audit_date",
                          },
                          disabled: true,
                        },
                        on: ["input"],
                      },
                    },
                  ],
                  component: "v-col",
                  fieldOptions: {
                    class: ["col-12 col-md-3 pa-3"],
                    attrs: {
                      md: "3",
                      cols: "12",
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    };
  }
}
