import { mapValues } from "lodash";
import { State } from "./types";
import { EmptyFindingPayLoad, emptyFinding } from "./utils";
import { Finding } from "@auditcloud/shared/lib/schemas";
import { Attachment } from "@/components/types";
import { NULL_UUID } from "@auditcloud/shared/lib/constants";
import { typeIsFinding } from "@auditcloud/shared/lib/schemas/type-guards";

const mutations = {
  PREPARE_NEW_FINDING(state: State, payload: EmptyFindingPayLoad) {
    state.findingId = NULL_UUID;
    state.finding = emptyFinding(payload);
    state.attachments = [];
  },
  SET_NOT_FOUND(state: State) {
    state.findingId = null;
    state.finding = emptyFinding();
    state.attachments = [];
  },

  INIT_FROM_QUICKEDITOR(
    state: State,
    payload: {
      targetValue: string;
      targetProperty: string;
      auditItemId: string;
      dimensions: null | string[];
      attachments: Attachment[];
      evaluationForSelfAssessmentWithId: string | null;
    }
  ) {
    state.findingId = NULL_UUID;

    const finding = emptyFinding(payload);
    finding[payload.targetProperty] = payload.targetValue;

    if (!typeIsFinding(finding)) {
      throw new Error(
        `The configured quickEditorTargetProperty "${payload.targetProperty}" is not a valid Finding property`
      );
    }

    state.finding = finding;
    state.attachments = payload.attachments;
    state.evaluationForSelfAssessmentWithId =
      payload.evaluationForSelfAssessmentWithId;
  },
  SET_FINDING(
    state: State,
    { findingId, finding }: { findingId: string; finding: Finding }
  ) {
    state.attachments = [];
    state.findingId = findingId;
    state.finding = finding;
  },
  APPEND_NEW_ATTACHMENTS(state: State, attachments: Attachment[]) {
    state.attachments.push(...attachments);
  },
  REMOVE_ATTACHMENT(state: State, index: number) {
    state.attachments = state.attachments.filter((_, i) => i !== index);
  },
};

const n = mapValues(mutations, (_, key) => key);

export { n as mutationNames, mutations };
