
















































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";
import { toPairs, fromPairs, size, flatten } from "lodash";
import { v5 as uuidv5, v4 as uuidv4 } from "uuid";

import { parseQuestionsFormXlsx } from "./AAuditItemImportDialog/utils";
import { QuestionRow } from "./AAuditItemImportDialog/types";
import { calcParentRoute } from "@/utils/routerUtils";
import { api as standardsApi } from "@/store/modules/standards";
import { api as templatesApi } from "@/store/modules/templates";
import { api as auditClassApi } from "@/store/modules/auditClasses";
import { api as configApi } from "@/store/modules/configuration";
import { AuditStandard, AuditStandardChapter } from "@/types/Standard";
import {
  AuditClassesListResult,
  AuditClassesListEntry,
} from "@/store/modules/auditClasses/types";

import { AuditItemTypeConfigMap } from "@auditcloud/shared/lib/types/Configuration/defaults";
import {
  AuditItemTypeMap,
  AuditItemType,
} from "@auditcloud/shared/lib/types/ItemTypes";
import {
  AuditItemCategorySetMap,
  AuditItemCategorySet,
  AuditItemCategory,
} from "@auditcloud/shared/lib/types/AuditItemCategory";
import {
  ChapterRefType,
  AuditStandardRef,
  AuditItem,
} from "@auditcloud/shared/lib/schemas";
import {
  tt2str,
  typeIsTranslateableText,
} from "@auditcloud/shared/lib/types/common";

import { AuditTemplate } from "@/store/modules/template/types";

import { typeIsNotEmpty } from "@auditcloud/shared/lib/utils/filter/typeIsNotEmpty";
import { idfy } from "@auditcloud/shared/lib/utils";
import { ft } from "@/plugins/ContentTranslation";

function inputStatus<T>(): {
  loading: boolean;
  messages: string[];
  errorMessages: string[];
  readonly error: boolean;
  readonly errorCount: number;
  val: T | null;
} {
  return {
    loading: false,
    messages: [],

    errorMessages: [],
    get error() {
      return this.errorMessages.length > 0;
    },
    get errorCount() {
      return this.errorMessages.length;
    },
    val: null,
  };
}

function createStandardFrom(chapters: AuditStandardChapter[]): AuditStandard {
  return {
    get id() {
      return idfy(ft(this.name));
    },
    name: "",
    description: "",
    chapters,
  };
}

function resolveCategoryId(
  categoryId: string,
  categories: AuditItemCategory[]
): string[] {
  const res = categories.reduce((p, c) => {
    if (
      c.short === categoryId ||
      c.legacyId === categoryId ||
      (typeIsTranslateableText(c.short) && c.short === categoryId)
    ) {
      return [c.id];
    } else if (p.length === 0) {
      const subCRes = resolveCategoryId(categoryId, c.subcategories);
      if (subCRes.length > 0) {
        return [c.id, ...subCRes];
      } else {
        return [];
      }
    } else {
      return p;
    }
  }, [] as string[]);
  return res;
}

function buildCategoryRefsForm(
  rows: QuestionRow[],
  categorySet: AuditItemCategorySet
): { errors?: string[]; categoryRefsMap: { [categoryId: string]: string[] } } {
  const errors: string[] = [];
  const categoryRefsMap: { [categoryId: string]: string[] } = {};
  rows.forEach(row => {
    const categoryId = row.categoryId;
    if (!categoryRefsMap[categoryId]) {
      const categoryRef = resolveCategoryId(categoryId, categorySet.categories);
      console.log(
        "buildCategoryRefsForm",
        categoryRef,
        categoryId,
        categorySet
      );
      if (categoryRef && categoryRef.length > 0) {
        categoryRefsMap[categoryId] = categoryRef;
      } else {
        errors.push(
          `Category Id ${categoryId} from row ${row.sourceRow} not found in Category Set`
        );
      }
    }
    const subCategoryId = row.subCategoryId;
    if (subCategoryId && !categoryRefsMap[subCategoryId]) {
      const subCategoryRefs = resolveCategoryId(
        subCategoryId,
        categorySet.categories
      ).slice(1);
      if (subCategoryRefs && subCategoryRefs.length > 0) {
        categoryRefsMap[subCategoryId] = subCategoryRefs;
      } else {
        errors.push(
          `Subcategory Id ${subCategoryId} from row ${row.sourceRow} not found in Category Set`
        );
      }
    }
  });

  return { categoryRefsMap, errors: errors.length > 0 ? errors : undefined };
}

@Component({})
export default class AAuditItemImportDialog extends Vue {
  dialog = true;
  defaultAuditItemType: null | string = null;
  templateName = "";
  creating = false;
  allowDuplicateQuestionNumbers: boolean = false;
  newStandard = createStandardFrom([]);

  categoryRefsMap: ReturnType<typeof buildCategoryRefsForm>["categoryRefsMap"] =
    {};

  status = (() => ({
    file: inputStatus<string>(),
    standard: inputStatus<string[]>(),
    auditClass: inputStatus<AuditClassesListEntry>(),
    categorySet: inputStatus<string>(),
  }))();
  /*
  fileparsing = false;
  fileParsingMessage: string[] = [];
  fileErrorMessages: string[] = []; */

  questionRows: QuestionRow[] = [];

  get auditClass(): null | AuditClassesListEntry {
    return this.status.auditClass.val ?? null;
  }

  @Getter(standardsApi.getters.getStandardList, {
    namespace: standardsApi.namespace,
  })
  standardsListInstalled!: AuditStandardRef[];

  @Getter(standardsApi.getters.getChapterListForStandard, {
    namespace: standardsApi.namespace,
  })
  chapterListForStandard!: (standardId) => ChapterRefType[];

  get standardsList(): AuditStandardRef[] {
    return this.standardsListInstalled;
  }

  @Getter(standardsApi.getters.getChapterListForStandard, {
    namespace: standardsApi.namespace,
  })
  chapterListFor!: (id: string) => ChapterRefType[];

  @Getter(auditClassApi.getters.list, {
    namespace: auditClassApi.namespace,
  })
  auditClassList!: AuditClassesListResult[];

  @Getter(configApi.getters.getAuditItemTypesMappedByAuditClass, {
    namespace: configApi.namespace,
  })
  auditItemTypesByAuditClass!: AuditItemTypeConfigMap;

  @Getter(configApi.getters.getCategoryMapping, {
    namespace: configApi.namespace,
  })
  categorySetMap!: AuditItemCategorySetMap;

  get auditItemTypeMap(): AuditItemTypeMap {
    return (
      this.auditItemTypesByAuditClass[this.auditClass?.id ?? ""]?.types ?? {}
    );
  }

  get auditItemTypesCount() {
    return size(this.auditItemTypeMap);
  }

  get auditItemTypeList(): [string, AuditItemType][] {
    return toPairs(this.auditItemTypeMap);
  }

  get categorySet(): AuditItemCategorySet | null {
    return this.categorySetMap[this.categorySetId ?? ""] ?? null;
  }

  closeDialog() {
    const parent = calcParentRoute(this.$route);
    if (parent) {
      this.$router.push(parent);
    } else {
      this.$router.back();
    }
  }

  get categorySetList() {
    return this.auditClass ? [this.auditClass.categorySetId] : [];
  }

  get categorySetId() {
    return this.auditClass?.categorySetId ?? null;
  }

  get standardRef(): AuditStandardRef[] | null {
    const newId = this.newStandard.id;

    const newStandard: AuditStandardRef | null =
      newId && newId.length > 0
        ? { id: newId ?? "", name: this.newStandard.name }
        : null;
    const standardIds = this.status.standard.val;

    if (newStandard === null && standardIds === null) {
      return null;
    } else {
      const newStandardList = newStandard ? [newStandard] : [];
      const standardsList = standardIds
        ? standardIds
            .map(standardId => {
              const standardRef = this.standardsListInstalled.find(
                s => s.id === standardId
              );
              return standardRef ?? null;
            })
            .filter(typeIsNotEmpty)
        : [];

      return [...newStandardList, ...standardsList];
    }
  }

  @Action(templatesApi.actions.importTemplate, {
    namespace: templatesApi.namespace,
  })
  importTemplate!: (payload: {
    id: string;
    data: AuditTemplate;
  }) => Promise<boolean>;

  syncReferencedStandards() {
    const referencedStandards = new Set<string>();
    this.questionRows.forEach(row => {
      row.chapterRefs.forEach(({ standardId }) => {
        referencedStandards.add(standardId);
      });
    });

    this.status.standard.val = [...referencedStandards];
  }

  async parseQuestionsFromExcel(files?: File[] | File) {
    console.log("Init", files);
    this.status.auditClass = inputStatus();
    this.status.standard = inputStatus();
    this.status.file = inputStatus();
    this.questionRows = [];

    if (files instanceof File) {
      try {
        this.status.file.errorMessages = [];
        this.status.file.loading = true;

        console.log("Good", files);
        this.status.file.messages = ["Analysiere die Datei"];
        const { rows } = await parseQuestionsFormXlsx(
          files,
          this.allowDuplicateQuestionNumbers
        );

        this.questionRows = rows;
        this.syncReferencedStandards();

        console.log("EXCEL_ROWS", rows);
        this.status.file.messages = [
          `${this.questionRows.length} Fragen gefunden`,
        ];
        console.log("QUESTIONS", this.questionRows);
      } catch (err) {
        this.status.file.errorMessages.push(`${err}`);
        this.status.file.messages = [];
        console.error(err);
      } finally {
        this.status.file.loading = false;
      }
    }
  }

  async standardSelectionChanged(standardIds?: string[]) {
    //
    console.log("standardSelectionChanged", standardIds, this.status);
    this.status.auditClass = inputStatus();
    this.status.standard = inputStatus();

    if (standardIds) {
      this.status.standard.loading = true;

      // Validate StandardIds
      this.status.standard.messages = ["Analysiere Normen"];

      const errorMessages = flatten(
        await Promise.all(
          standardIds.map(async standardId => {
            const errorsMessages: string[] = [];
            const chapters = this.chapterListFor(standardId);
            if (chapters.length === 0) {
              errorsMessages.push("Keine Normkapitel gefunden ...");
              return errorsMessages;
            }

            this.questionRows.forEach(row => {
              row.chapterIdsForStandard(standardId).forEach(chapterId => {
                const chapterRef = chapters.find(
                  chapter => chapter.chapterId === chapterId
                );
                if (!chapterRef) {
                  errorsMessages.push(
                    `Die Kapitel Id ${chapterId} aus Zeile ${row.sourceRow} wurde in der Norm ${standardId} nicht gefunden.`
                  );
                } else {
                  const chapterDescription = tt2str(
                    row.chapterDescription ?? "",
                    "de"
                  ).trim();
                  const knownChapterDescription = tt2str(
                    chapterRef.chapterDescription ?? "",
                    "de"
                  ).trim();

                  if (
                    chapterDescription.length > 0 &&
                    knownChapterDescription !== chapterDescription
                  ) {
                    const hex = (d: string) => {
                      return d
                        .split("")
                        .map(c => c.charCodeAt(0).toString(16).padStart(2, "0"))
                        .join(" ");
                    };
                    console.warn(
                      `Description mismatch for ${chapterId} from row ${row.sourceRow}`,
                      `\n\n${hex(knownChapterDescription)}\n${hex(
                        chapterDescription
                      )}\n`,
                      chapterRef.chapterDescription,
                      chapterDescription,
                      row
                    );
                    errorsMessages.push(
                      `Die Beschreibung zur ${chapterId} aus Zeile ${row.sourceRow} stimmt nicht mit der aus der Norm  ${standardId} überein. (Import: "${row.chapterDescription}" vs.  Norm: "${chapterRef.chapterDescription}")`
                    );
                  }
                }
              });
            });
            return errorsMessages;
          })
        )
      );

      this.status.standard.errorMessages.push(...errorMessages);

      this.status.standard.loading = false;
      this.status.standard.val = standardIds;
    }
  }

  async auditClassSelectionChanged(auditClass: AuditClassesListEntry | null) {
    console.log("auditClassSelectionChanged", auditClass, this.status);
    this.status.categorySet = inputStatus<string>();
    this.categoryRefsMap = {};
    this.status.auditClass = inputStatus<AuditClassesListEntry>();

    if (auditClass) {
      this.status.auditClass.val = auditClass;
      const categorySet = this.categorySetMap[this.categorySetId ?? ""];
      if (categorySet) {
        const { errors, categoryRefsMap } = buildCategoryRefsForm(
          this.questionRows,
          categorySet
        );
        this.categoryRefsMap = categoryRefsMap;
        if (errors) {
          this.status.categorySet.errorMessages = errors;
        }
      }

      console.log("AuditItemTypes", this.auditItemTypeMap);
    }
  }

  async createTemplate() {
    /* Todo:
     - ggf. neues category Set erstellen
     - warnen wenn die Fragengewichtung nicht gefunden werden kann

     Muss in einem Template folgende Einstellung gespeichert werden?
     - FindingType Set
     - AuditItem Type Set
     - CategorySet
    */
    const defaultAuditItemType = this.defaultAuditItemType;
    if (
      this.status.auditClass.val &&
      this.standardRef &&
      defaultAuditItemType
    ) {
      try {
        this.creating = true;
        const {
          id: auditClassId,
          categorySetId,
          reportSchema,
        } = this.status.auditClass.val;
        const templateId = uuidv5(
          this.templateName,
          "fec90ff4-e885-485c-8538-c690acd20ff1"
        );
        const selectedStandardIds = this.standardRef.map(ref => ref.id);

        const auditItems: [string, AuditItem][] = this.questionRows.map(row => {
          const id = uuidv4();

          const type = (this.auditItemTypeList.find(
            ([id, ait]) =>
              row.auditItemType !== null && ait.short === row.auditItemType
          ) ??
            this.auditItemTypeList.find(
              ([id, ait]) => ait.value === row.weight
            ) ?? [defaultAuditItemType])[0];

          const chapters = [...row.chapterRefs]
            .map(ci => {
              if (selectedStandardIds.includes(ci.standardId)) {
                const chapters = this.chapterListFor(ci.standardId);
                return chapters.find(cr => cr.chapterId === ci.chapterId);
              } else {
                return null;
              }
            })

            .filter(typeIsNotEmpty);
          const customData = row.customData
            ? { customData: row.customData }
            : {};

          const subCategoryRefs = row.subCategoryId
            ? this.categoryRefsMap[row.subCategoryId]
            : [];

          const ai: AuditItem = {
            question: {
              no: row.no,
              text: row.text,
              selfAssessmentText: row.selfAssessmentText,
              categoryRef: [
                ...this.categoryRefsMap[row.categoryId],
                ...subCategoryRefs,
              ],
              chapters,
              description: "",
              hint: row.hint ?? "",
              type,
              links: row.links,
              requiredProofs: row.requiredProofs,
              ...customData,
            },
            labels: row.labels,
          };
          if (row.vda_question_scope) {
            ai.question.vda_question_scope = row.vda_question_scope;
          }
          return [id, ai];
        });

        const template = new AuditTemplate(
          templateId,
          this.templateName,
          auditClassId,
          categorySetId,
          reportSchema,
          ["de", "en"],
          this.standardRef,
          fromPairs(auditItems),
          !this.allowDuplicateQuestionNumbers
        );

        if (!(await this.importTemplate({ id: templateId, data: template }))) {
          console.log("Erstellen des neuen Templates abgebrochen");
          return false;
        }

        console.log(
          "createTemplate",
          template,
          this.auditClass,
          this.categorySet,
          this.auditItemTypeMap,
          this.defaultAuditItemType
        );
      } catch (err) {
        console.error(err);
      } finally {
        this.creating = false;
      }
    }
  }
}
