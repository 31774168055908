
































































































































import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import {
  TodoMap,
  TodoArray,
  TodoAny,
} from "@auditcloud/shared/lib/utils/type-guards";
import itemsjs from "itemsjs";
import { debounce, Dictionary, flatten, fromPairs } from "lodash";

import FormattingMixin from "@/components/mixins/FormattingMixin.vue";
import { customSortItemsJsBucketItems } from "@/utils/itemjs/sort";

@Component({ mixins: [FormattingMixin] })
export default class ItemsJsFacets extends Mixins(FormattingMixin) {
  // TODO: NTH -> Facets nur anzeigen, wenn es mehr als ein Bucket gibt
  @Prop({
    type: Array,
    default: () => [],
  })
  rows!: TodoArray;

  @Prop({
    type: Object,
    default: () => {},
  })
  configuration!: TodoAny;

  @Prop({
    type: Number,
    default: 100,
  })
  perPage!: number;

  @Prop({
    type: Number,
    default: 1,
  })
  page!: number;

  @Prop({
    type: Boolean,
    default: false,
  })
  showEmptyBuckets!: boolean;

  query: unknown = "";
  filters: TodoMap = {};
  itemsJsInstance: TodoMap = {};

  // TODO: Ist das der beste Weg?
  @Watch("rows")
  onWatchRows(newVal) {
    this.itemsJsInstance = itemsjs(this.rows, this.configuration);
  }
  @Watch("$route")
  onWatchRoute() {
    this.query = this.$route.query.query || "";

    if ("filter" in this.$route.query) {
      const queryFilter = this.$route.query.filter;

      this.filters =
        typeof queryFilter === "string" ? JSON.parse(queryFilter) : {};
    } else {
      const filters = {};
      Object.keys(this.configuration.aggregations).map(v => {
        filters[v] = [];
      });
      this.filters = filters;
    }
  }

  // vue lifecycle
  created() {
    const defaultFilter = this.configuration.filter;

    this.itemsJsInstance = itemsjs(this.rows, this.configuration);
    this.query = this.$route.query.query || "";

    if ("filter" in this.$route.query) {
      const queryFilter = this.$route.query.filter;

      this.filters =
        typeof queryFilter === "string" ? JSON.parse(queryFilter) : {};
    } else {
      const filters = {};
      Object.keys(this.configuration.aggregations).map(v => {
        if (defaultFilter && v in defaultFilter) {
          filters[v] = defaultFilter[v];
        } else {
          filters[v] = [];
        }
      });
      this.filters = filters;
    }
  }

  // methods
  getFacetLabel(facet) {
    return this.$ft(facet.title) + " (" + facet.buckets.length + ")";
  }
  unselectBucket(facetName, tagBucket) {
    this.filters[facetName] = this.filters[facetName].filter(
      item => item !== tagBucket
    );
  }
  toggleOption(facetName, bucketKey) {
    console.log("Toggle option:", { facetName, bucketKey });
    if (typeof this.filters[facetName] !== "object") {
      this.$set(this.filters, facetName, []);
    }

    const hasItem = this.filters[facetName].includes(bucketKey);

    if (hasItem) {
      this.$set(
        this.filters,
        facetName,
        this.filters[facetName].filter(i => i !== bucketKey)
      );
    } else {
      this.filters[facetName] = [...this.filters[facetName], bucketKey];
    }
  }

  get filterCount() {
    return (
      (this.query === "" ? 0 : 1) +
      Object.values(this.filters).reduce((prev, item) => prev + item.length, 0)
    );
  }
  getBucketLabel(bucket) {
    return this.$ft(bucket.key) + " (" + bucket.doc_count + ")";
  }
  reset() {
    console.log("resetting");

    const filters = {};
    this.filters = filters;
  }

  // custom sort buckets, because itemsjs can't use custom sorter functions/options to extend functionality
  customSortBuckets(bucket, sortOption): any {
    customSortItemsJsBucketItems(bucket, sortOption);
    return bucket;
  }

  // getter
  // TODO: Das Verhalten muss noch besprochen werden!
  get triggerRouter() {
    return debounce((query: TodoMap) => {
      this.$router.replace({ query, params: this.$route.params });
    }, 500);
  }

  get currentRows() {
    return null;
  }
  get tags() {
    return flatten(
      Object.entries(this.filters).map(([filterKey, filterEntries]) =>
        filterEntries.map(filterEntry => ({
          key: filterKey,
          name: filterEntry,
        }))
      )
    );
  }

  get searchResult() {
    console.log("Searchresult", this.itemsJsInstance);
    let result = this.itemsJsInstance.search({
      per_page: this.rows.length,
      page: 1,
      query: this.query,
      filters: this.filters,
    });

    if (!this.showEmptyBuckets) {
      const resultAggregationPairs = Object.entries(
        result.data.aggregations
      ).filter(([, aggregation]) => {
        const itemsJsAggregation = aggregation as { buckets: unknown[] };
        return itemsJsAggregation.buckets.length > 0;
      });

      result.data.aggregations = fromPairs(resultAggregationPairs);
    }

    this.$emit(
      "itemsjs-result-list-changed",
      result.data.items,
      this.filters,
      this.query
    );
    if (this.query !== "" || Object.keys(this.filters).length > 0) {
      console.log("Filter act", this.query, this.filters);

      const currentFilter = JSON.stringify(this.filters);
      // Avoid NavigationDuplicated error:
      if (
        this.query !== this.$route.query.query ||
        currentFilter !== this.$route.query.filter
      ) {
        const query = { query: this.query, filter: currentFilter };
        this.triggerRouter(query);
      }
    }

    return result;
  }
}
