import { Route, Location } from "vue-router";

export function calcParentRoute(child: Route): Location | null {
  const matchedCount = child.matched.length;
  const currentRoute = child.matched[matchedCount - 1];
  if (currentRoute && typeof currentRoute.parent !== "undefined") {
    const route = currentRoute.parent;
    return {
      name: route.name,
      params: child.params,
      query: child.query,
    };
  } else {
    return null;
  }
}
