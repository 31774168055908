
import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { State, Action, Getter, Mutation } from "vuex-class";
import {
  TodoMap,
  TodoArray,
  TodoAny,
  TodoActionSignature,
  TodoMutationSignature,
} from "@auditcloud/shared/lib/utils/type-guards";
import { formatDates } from "@auditcloud/shared/lib/utils/formatting/dates";

import moment from "moment";

/*
 * Helps to implement uniform date/time formatting
 */
@Component({
  filters: {
    formatTime,
    formatDates,
    objectCount,
    arrayToString,
    trim,
  },
})
export default class FormattingMixin extends Vue {}

const TIME_FORMAT = "HH:mm";

const DATE_PART = /[0-9]{4}-[0-9]{2}-[0-9]{2}/i;
const TIME_PART = /[0-9]{2}[:][0-9]{2}[:][0-9]{2}(.[0-9]+)?/i;
const TZ_PART = /Z|[+-][0-9]{2}[:][0-9]{2}/i;

export const DATE_MATCHER = new RegExp(
  `^${DATE_PART.source}(T${TIME_PART.source}(${TZ_PART.source})?)?$`,
  "i"
);
export const DATE_TIME_MATCHER = new RegExp(
  `^${DATE_PART.source}T${TIME_PART.source}(${TZ_PART.source})?$`,
  "i"
);

export function trim(value, length) {
  if (value.length > length) {
    return value.substring(0, length) + "…";
  } else {
    return value;
  }
}

export function formatTime(isoDate) {
  return isoDate && moment(isoDate).format(TIME_FORMAT);
}

export function objectCount(object) {
  if (!object) {
    return "";
  } else {
    return Object.keys(object).length || 0;
  }
}

export function arrayToString(Value, JoinString = ", ") {
  if (Value instanceof Array) {
    return Value.join(JoinString);
  } else if (!Value) {
    return "";
  } else {
    return Value.toString();
  }
}
