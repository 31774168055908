

















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { Dictionary } from "lodash";
import { AuditMetadataDoc } from "@auditcloud/shared/lib/schemas";

@Component({})
export default class AAuditNameField extends Vue {
  @Prop({
    type: Object,
    default: () => ({}),
  })
  auditMeta!: Partial<AuditMetadataDoc>;

  get context(): Dictionary<unknown> {
    return {
      ...this.auditMeta,
    };
  }
}
