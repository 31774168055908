


































import Vue from "vue";
import axios from "axios";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { TodoAny } from "@auditcloud/shared/lib/utils/type-guards";

import { ROUTE_NAMES } from "../routenames";
import { api as appApi } from "@/store/modules/app";
import { SystemRoles } from "@auditcloud/shared/lib/constants/roles";
import VJsoneditor from "v-jsoneditor";
import { CollectionNames } from "@auditcloud/shared/lib/types/common";
import { systemEndpointUrl } from "@/utils/HttpApi";

const SUPPORTED_COLLECTION_NAMES = [
  CollectionNames.AUDITS,
  CollectionNames.MEASURE_PROCESSES,
  CollectionNames.TEMPLATES,
  CollectionNames.USERS,
  CollectionNames.GROUPS,
] as const;
type SupportedCollection = typeof SUPPORTED_COLLECTION_NAMES[number];

@Component({ components: { VJsoneditor } })
export default class SanityCheck extends Vue {
  response: null | any = null;
  loading: boolean = false;
  collectionNames: SupportedCollection[] = [...SUPPORTED_COLLECTION_NAMES];
  collectionName: SupportedCollection = CollectionNames.AUDITS;

  get options() {
    return {
      onEditable(payload: any) {
        console.log("onEditable", payload);
        return false;
      },
      mode: "code",
      modes: ["tree", "form", "code", "text"],
    };
  }

  get isSysAdmin() {
    return this.$user.roles().includes(SystemRoles.SYSTEM_ADMINISTRATOR);
  }

  get currentBreadCrumb() {
    return [
      {
        text: this.$t("views.audit.home"),
        to: { name: ROUTE_NAMES.DASHBOARDSCOPED },
        activeclass: "",
      },
      {
        text: this.$t("views.settings.breadcrumb"),
        to: { name: ROUTE_NAMES.SETTINGS },
        activeclass: "",
      },
      {
        text: this.$t("views.settings.sanity_check"),
        to: {
          name: ROUTE_NAMES.SANITY_CHECK,
        },
        activeclass: "",
      },
    ];
  }

  async checkSanity() {
    const url = systemEndpointUrl("admin", "validation", this.collectionName);

    try {
      this.loading = true;
      this.response = null;

      const res = await axios({
        url,
        method: "GET",
        responseType: "json",
      });
      console.log(res);
      this.response = res.data;
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  @Watch("currentBreadCrumb", { immediate: true })
  onBreadCrumbChanged() {
    this.updateBreadCrumbs();
    this.setMenuPanel();
  }

  @Action(appApi.actions.setBreadCrumb, { namespace: appApi.namespace })
  setBreadCrumb!: (currentBreadCrumb: any[]) => Promise<boolean>;

  @Action(appApi.actions.setMenuPanel, { namespace: appApi.namespace })
  setMenuPanel!: () => Promise<boolean>;

  @Getter(appApi.getters.getCurrentLanguage, { namespace: appApi.namespace })
  currentLanguage!: TodoAny;

  @Watch("currentLanguage", { immediate: true, deep: true })
  onLaguageChange() {
    this.updateBreadCrumbs();
  }

  updateBreadCrumbs() {
    this.setBreadCrumb(this.currentBreadCrumb);
  }
}
