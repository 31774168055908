import { FormSchemaMap } from "../index";
import preselectForm from "./preselect-form";
import metadataForm from "./metadata-form";
import planningForm from "./planning-data";
import reportingForm from "./reporting-form";
import preparationForm from "./preparation-form";
import findingForm from "./finding-form";

const formSchemaMap: FormSchemaMap = {
  "preselect-form": preselectForm,
  "metadata-form": metadataForm,
  "planning-form": planningForm,
  "reporting-form": reportingForm,
  "preparation-form": preparationForm,
  "finding-form": findingForm,
};

export default formSchemaMap;
