
























































































































































































import { UserSignature, UserRef } from "@auditcloud/shared/lib/schemas";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import SignaturePad from "signature_pad";
import { isUndefined } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { AuditSignatureRoles } from "@auditcloud/shared/lib/types/common";
import { api as usersApi } from "@/store/modules/users";

@Component({
  components: {},
})
export default class ASignatureEditor extends Vue {
  allowPersist: boolean = true;
  userInputIsDirty: boolean = false;
  userSignaturepadIsDirty: boolean = false;
  disableSignaturePersist: boolean = false;
  missingUser: boolean = false;
  missingSignature: boolean = false;
  missingSignatureSmall: boolean = false;
  missingRole: boolean = false;
  signaturePad!: SignaturePad;
  userSignature: UserSignature = {
    id: "",
    role: "",
    userRef: null,
    timestamp: "",
    signature: "",
    isSaved: false,
  };

  isScreenToSmall: boolean = false;
  dialog: boolean = false;
  userRoles: Object[] = [
    {
      text: this.$t("components.dialogs.audit_reporting_dialog.auditor"),
      value: AuditSignatureRoles.AUDITOR,
    },
    {
      text: this.$t("components.dialogs.audit_reporting_dialog.auditor_co"),
      value: AuditSignatureRoles.AUDITOR_CO,
    },
    {
      text: this.$t("components.dialogs.audit_reporting_dialog.audited_person"),
      value: AuditSignatureRoles.AUDITED_PERSON,
    },
    {
      text: this.$t("components.dialogs.audit_reporting_dialog.attendees"),
      value: AuditSignatureRoles.ATTENDEE,
    },
  ];

  $refs!: {
    myCanvasInline: HTMLCanvasElement;
    myCanvasFullScreen: HTMLCanvasElement;
  };
  safeData: string = "";

  @Prop({
    type: Object,
    required: true,
  })
  value!: UserSignature;

  @Prop({
    type: Boolean,
    default: false,
  })
  isDialogOpen!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isEdit!: boolean;

  @Prop({
    type: Array,
    default: () => [],
  })
  requiredRoles!: any;

  @Watch("isDialogOpen", { immediate: true })
  activateDialog() {
    if ((this.isScreenToSmall = window.innerWidth > 1000))
      this.$nextTick(this.initSignaturePad);
    if (this.value) {
      this.userSignature.userRef = this.value.userRef;

      this.userSignature.role = this.value.role;

      this.userSignaturepadIsDirty = this.value.signature !== "";
      if (this.value.userRef) {
        this.userInputIsDirty = true;
      }
    }
    window.addEventListener("resize", this.checkIfResolutionIsHighEnough, {
      passive: true,
    });
    this.checkIfResolutionIsHighEnough();

    return false;
  }
  mounted() {
    console.log("Mounted");
  }
  created() {
    window.addEventListener("resize", this.checkIfResolutionIsHighEnough, {
      passive: true,
    });
  }

  close() {
    this.$emit("close");
  }
  signaturePadIsDirty() {
    this.userSignaturepadIsDirty = true;
    this.missingSignature = false;
    if (this.myCanvas()) {
      this.myCanvas().style.borderColor = "grey";
    }
  }

  onUserChanged(newVal: UserRef) {
    this.missingUser = false;
    this.userInputIsDirty = true;
    this.userSignature.userRef = newVal;
    if (
      this.userSignature.userRef.userSavedSignature &&
      this.userSignature.userRef.userSavedSignature !== ""
    ) {
      this.allowPersist = false;
    }
  }
  onRoleChanged(newVal: string) {
    this.missingRole = false;
    this.userSignature.role = newVal;
  }

  clearSignaturePad() {
    this.signaturePad.clear();
    this.signaturePad.on();
  }

  save() {
    if (this.validation()) {
      if (this.signaturePad) {
        this.userSignature.signature = this.signaturePad.toDataURL();
      }

      if (this.value.id === "") {
        this.userSignature.id = uuidv4();
      } else {
        this.userSignature.id = this.value.id;
      }
      this.userSignature.timestamp =
        new Date().toLocaleDateString() +
        " / " +
        new Date().toLocaleTimeString();
      if (this.isEdit) {
        this.$emit("edit", this.userSignature);
      } else {
        this.$emit("input", this.userSignature);
      }
      this.close();
    }
  }
  saveOnFullScreen() {
    this.userSignature.signature = this.signaturePad.toDataURL();
    this.dialog = false;
    this.missingSignatureSmall = false;
  }

  resizeCanvas() {
    if (!isUndefined(this.myCanvas())) {
      let ratio = Math.max(window.devicePixelRatio || 1, 1);

      this.myCanvas().width = this.myCanvas().offsetWidth * ratio;
      this.myCanvas().height = this.myCanvas().offsetHeight * ratio;
      this.myCanvas().getContext("2d")?.scale(ratio, ratio);

      this.signaturePad.clear();
      this.signaturePad.fromDataURL(this.safeData);
    }
  }

  validation(): boolean {
    if (
      this.userSignature.userRef === null ||
      this.userSignature.userRef.id === ""
    ) {
      this.missingUser = true;
    }
    if (this.userSignature.role === "") {
      this.missingRole = true;
    }
    if (this.isScreenToSmall) {
      if (
        this.userSignature.signature === "" &&
        this.checkRoleInRequiredRoles(this.userSignature.role)
      ) {
        this.missingSignatureSmall = true;
      }
      return !(
        this.checkRoleInRequiredRoles(this.userSignature.role) ||
        this.userSignature.userRef === null ||
        this.userSignature.userRef.id === "" ||
        this.userSignature.role === ""
      );
    } else {
      if (
        this.signaturePad.isEmpty() &&
        this.checkRoleInRequiredRoles(this.userSignature.role)
      ) {
        this.myCanvas().style.borderColor = "rgba(240,129,129,1)";
      }

      return !(
        this.signaturePad.isEmpty() ||
        this.checkRoleInRequiredRoles(this.userSignature.role) ||
        this.userSignature.userRef === null ||
        this.userSignature.userRef.id === "" ||
        this.userSignature.role === ""
      );
    }
  }

  checkRoleInRequiredRoles(newVal: string): boolean {
    for (var idx = 0; idx < this.requiredRoles.length; idx++) {
      if (this.requiredRoles[idx].role === newVal) {
        return true;
      }
    }
    return false;
  }

  get getRole(): String {
    if (this.value) {
      switch (this.value.role) {
        case AuditSignatureRoles.AUDITOR:
          return this.$t(
            "components.dialogs.audit_reporting_dialog.auditor"
          ).toString();
        case AuditSignatureRoles.AUDITOR_CO:
          return this.$t(
            "components.dialogs.audit_reporting_dialog.auditor_co"
          ).toString();
        case AuditSignatureRoles.AUDITED_PERSON:
          return this.$t(
            "components.dialogs.audit_reporting_dialog.audited_person"
          ).toString();
        default:
          return this.$t(
            "components.dialogs.audit_reporting_dialog.attendees"
          ).toString();
      }
    }
    return this.$t(
      "components.dialogs.audit_reporting_dialog.attendees"
    ).toString();
  }

  get localeParticipant() {
    return this.userSignature.userRef;
  }

  get localeRole() {
    return this.userSignature.role;
  }
  set localeParticipant(newVal: UserRef | null) {
    this.userSignature.userRef = newVal;
  }

  get assignedToQueryAction() {
    return {
      name: usersApi.actions.queryUser,
      namespace: usersApi.namespace,
    };
  }

  myCanvas() {
    if (this.isScreenToSmall) {
      return this.$refs.myCanvasFullScreen;
    }
    return this.$refs.myCanvasInline;
  }

  set localeRole(newVal: string) {
    this.userSignature.role = newVal;
  }

  checkIfResolutionIsHighEnough() {
    this.isScreenToSmall = window.innerWidth <= 1000;
    this.$nextTick(this.initSignaturePad);
  }
  timeOutForCanvasRendering() {
    if (this.signaturePad) {
      this.signaturePad.clear();
    }
    this.safeData = "";
    this.dialog = true;
    this.$nextTick(this.initSignaturePad);
  }

  initSignaturePad() {
    if (
      (!this.isScreenToSmall || this.dialog) &&
      !isUndefined(this.myCanvas())
    ) {
      if (this.signaturePad) {
        this.safeData = this.signaturePad.toDataURL();
      }
      this.signaturePad = new SignaturePad(this.myCanvas(), {
        onBegin: this.signaturePadIsDirty,
      });
      this.$nextTick(this.resizeCanvas);
    }
    if (this.value) {
      if (!isUndefined(this.value.signature) && this.userSignaturepadIsDirty) {
        this.signaturePad.fromDataURL(this.value.signature);
        this.userSignature.role = this.value.role;
      }
    }
    this.$nextTick(this.resizeCanvas);
  }
  persistUsersignature() {
    if (this.userSignature.userRef) {
      this.userSignature.userRef.userSavedSignature =
        this.signaturePad.toDataURL();
      this.disableSignaturePersist = true;
    }
  }

  loadUsersignature() {
    if (this.value.userRef?.userSavedSignature) {
      this.signaturePad.fromDataURL(this.value.userRef?.userSavedSignature);
    } else if (this.userSignature.userRef?.userSavedSignature) {
      this.signaturePad.fromDataURL(
        this.userSignature.userRef?.userSavedSignature
      );
    }
  }
}
