

















































































































































import { api } from "@/store/modules/app";
import { formatDates } from "@auditcloud/shared/lib/utils/formatting/dates";
import { Dictionary, isString } from "lodash";
import { Getter } from "vuex-class";
import moment from "moment";
import Vue from "vue";

import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class ADateSnippet extends Vue {
  datePickerMenu = false;

  onSelectionChange(date: string) {
    const editable = this.editable;
    if (editable) {
      this.$emit("input", date);
    }
  }

  setCurrentDate() {
    const date = moment().toISOString().substring(0, 10);
    this.onSelectionChange(date);
  }
  @Prop({
    required: true,
    default: rawProps => moment().format(rawProps.dateFormat),
  })
  readonly date!: Date | string;

  @Prop({
    required: false,
  })
  readonly completedOn!: Date | string;

  @Prop({
    type: [Object, String],
    default: () => {},
  })
  readonly contentClass!: Dictionary<boolean> | string;

  get momentCompletedOn() {
    return moment(this.completedOn);
  }

  get completedOnText() {
    return this.momentCompletedOn.format(this.dateFormat);
  }

  get completedOnTime() {
    return this.momentDate > this.momentCompletedOn;
  }

  get inProgress() {
    return this.isDueDate && !this.completedOn;
  }

  get closeToDueDate() {
    return (
      this.isDueDate &&
      !this.completedOn &&
      this.momentDate.diff(moment(), "days") < 5
    );
  }

  @Prop({ type: String, default: "L" })
  readonly dateFormat!: string;

  @Prop({ type: String, default: "LL" })
  readonly tooltipDateFormat!: string;

  @Prop({ type: Boolean, default: false })
  readonly isDueDate!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly noTooltip!: boolean;

  @Prop({ default: true, type: Boolean })
  readonly readonly!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly chip!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly humanize!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly editable!: boolean;

  @Prop({ default: "500", type: [Number, String] })
  readonly openDelay!: number | string;
  @Prop({ type: String, default: "mdi-clock-alert-outline" })
  completedLateIcon!: string;

  @Prop({ type: String, default: "mdi-check-circle-outline" })
  completedOnTimeIcon!: string;

  @Prop({ type: String, default: "mdi-progress-clock" })
  inProgessIcon!: string;

  @Getter(api.getters.getCurrentLanguage, { namespace: api.namespace })
  getCurrentLanguage!: string;

  get small() {
    return typeof this.$attrs["small"] !== "undefined";
  }

  get dates() {
    const list: string[] = [];
    if (this.date instanceof Array) {
      this.date.forEach((v: any) => {
        if (v instanceof Date) {
          list.push(v.toISOString());
        } else if (typeof v === "string") {
          list.push(v);
        }
      });
    } else if (this.date instanceof Date) {
      list.push(this.date.toISOString());
    } else if (typeof this.date === "string") {
      list.push(this.date);
    }
    list.sort();
    return list;
  }

  get datePickerValue() {
    return isString(this.date)
      ? this.date.substr(0, 10)
      : this.date.toISOString().substr(0, 10);
  }

  get momentDate() {
    const currentDate = moment(this.date);
    currentDate.set({ hour: 23, minute: 59, second: 59 });
    return currentDate;
  }

  get dueDateDifferenceInMs() {
    return this.momentDate.diff(moment(), "ms");
  }

  get dueDateDifference() {
    if (!this.isDueDate) {
      return null;
    }
    if (this.momentDate.isSame(moment(), "day")) {
      return this.$t("components.snippets.date_snippet.today");
    }
    return moment.duration(this.dueDateDifferenceInMs, "ms").humanize(true);
  }

  get delayed() {
    return (
      this.isDueDate && !this.completedOn && this.dueDateDifferenceInMs < 0
    );
  }

  get timeSinceDate() {
    const date = moment(this.dates[0]);
    const diff = date.diff(moment(), "months");
    if (!this.humanize || diff < -3) {
      return date.format(this.dateFormat);
    }
    return moment.duration(date.diff(moment(), "m"), "m").humanize(true);
  }

  get dateText() {
    if (this.dates.length > 0) {
      return formatDates(this.dates, null, this.dateFormat).join(", ");
    } else {
      return this.$t("components.snippets.date_chip.no_date");
    }
  }
  get dateTooltipText() {
    if (this.dates.length > 0) {
      return formatDates(this.dates, null, this.tooltipDateFormat).join(", ");
    } else {
      return this.$t("components.snippets.date_chip.no_date");
    }
  }
}
