



















































































































































import { Dictionary, sortBy, chain, groupBy } from "lodash";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";

import { AuditItem, TranslateableText } from "@auditcloud/shared/lib/schemas";

import { typeIsNotEmpty } from "@auditcloud/shared/lib/utils/filter/typeIsNotEmpty";

import { api } from "@/store/modules/audit";
import { AuditScoreAggregatedTotal } from "@auditcloud/shared/lib/types/AuditScore/types";

import { api as auditResultApi } from "@/store/modules/auditResult";
import { AuditItemCategoryMap } from "@auditcloud/shared/lib/types/AuditItemCategory";
import { idable } from "@auditcloud/shared/lib/types/common";
import { MAGIC_VDA_STAR_AUDIT_ITEM_TYPE_ID } from "@auditcloud/shared/lib/constants";
import {
  AuditReportSummary,
  FlatExportData,
} from "@/store/modules/audit/getters";
import { ScoreCalcSteps } from "@/store/modules/auditResult/getters";

const auditModul = namespace(api.namespace);
const auditResultModul = namespace(auditResultApi.namespace);

class VdaResultItem {
  constructor(
    public readonly value: number,
    public readonly no: string,
    public readonly isApplicable: boolean,
    public readonly star: boolean = true
  ) {}

  get valueDisplay(): string {
    return this.isApplicable ? String(this.value) : "n. e.";
  }
}

class VdaResultList {
  public readonly items: VdaResultItem[];
  constructor(
    public readonly short: string,
    public readonly text: string,
    public readonly listId: string,
    public readonly partOfResult: string[],
    items: VdaResultItem[]
  ) {
    this.items = sortBy(items, "no");
  }
  get compliancePercent() {
    return (this.points * 100) / this.maxPoints;
  }
  get minStarValue() {
    return Math.min(...this.items.filter(v => v.star).map(v => v.value));
  }
  get minValue() {
    return Math.min(...this.items.map(v => v.value));
  }
  get points() {
    return this.items
      .filter(v => v.isApplicable)
      .reduce((p, c) => p + c.value, 0);
  }

  get maxPoints() {
    return this.countApplicable * 10;
  }

  get count() {
    return this.items.length;
  }

  get countApplicable() {
    return this.items.filter(v => v.isApplicable).length;
  }

  get countRatingZero() {
    return this.items.filter(v => v.isApplicable && v.value === 0).length;
  }

  get totalResultParts() {
    return [this.listId, ...this.partOfResult];
  }

  isPartOf(resultId: string): boolean {
    return this.totalResultParts.includes(resultId);
  }
}

function rowsToVdaResultList(
  rows: any[],
  short: string,
  name: string,
  listId: string,
  partOfResult: string[]
): VdaResultList {
  console.log("rowsToVdaResultList", rows, short, name, listId, partOfResult);
  const items = rows
    .map(row => {
      if (
        typeof row.findingWeight === "number" &&
        typeof row.findingIsApplicable === "boolean" &&
        typeof row.auditItemNo === "string" &&
        typeof row.auditItemTypeId === "string"
      ) {
        return new VdaResultItem(
          row.findingWeight,
          row.auditItemNo,
          row.findingIsApplicable,
          row.auditItemTypeId === MAGIC_VDA_STAR_AUDIT_ITEM_TYPE_ID
        );
      } else {
        return null;
      }
    })
    .filter(typeIsNotEmpty);

  return new VdaResultList(short, name, listId, partOfResult, items);
}

export interface AAuditReportCalculationData {
  columnHeader: TranslateableText[];
  rows: {
    description: TranslateableText;
    values: Array<string | number | boolean>;
  }[];
}

function calcResultValue(score: AuditScoreAggregatedTotal): {
  value: string;
  color: string;
} {
  const resultRules = [
    {
      min: 90,
      result: {
        value: "A",
        color: "green",
      },
    },
    {
      max: 90,
      min: 80,
      result: {
        value: "B",
        color: "yellow",
      },
    },
    {
      max: 80,
      result: {
        value: "C",
        color: "red",
      },
    },
  ];
  const compliancePercent = score.compliancePercent * 100;

  const resultRule = resultRules.find(
    ({ min, max }) =>
      (typeof min === "undefined" || min <= compliancePercent) &&
      (typeof max === "undefined" || compliancePercent < max)
  );

  if (resultRule) {
    return resultRule.result;
  } else {
    return {
      color: "#f0f",
      value: "Unbekannt",
    };
  }
}

@Component({
  components: {},
})
export default class ACalculationVda extends Vue {
  vdaCalcChainDialog: boolean = false;
  @auditModul.Getter(api.getters.getAuditItems)
  items!: Array<idable<AuditItem>>;

  @auditResultModul.Getter(auditResultApi.getters.getScoreCalcSteps)
  scoreCalcSteps!: ScoreCalcSteps;

  @auditModul.Getter(api.getters.getAuditReportSummary)
  auditReportSummary!: AuditReportSummary;

  get compliancePercent() {
    return (this.scoreCalcSteps?.compliancePercent ?? 0) * 100;
  }

  get result(): { value: string; color: string } {
    if (this.scoreCalcSteps) {
      return calcResultValue(this.scoreCalcSteps);
    } else {
      return {
        value: "-",
        color: "gray",
      };
    }
  }

  @auditModul.Getter(api.getters.getFlatExportData)
  flatitems!: FlatExportData;
  // Todo: Bessere Quelle für die VDA Berechung suchen dieser Getter soll nur die Abweichungen zurück geben

  @Getter(api.getters.getIsVdaAudit, {
    namespace: api.namespace,
  })
  isVdaAudit!: boolean;

  @auditModul.Getter(api.getters.getAuditCategoryMapping)
  categoryMapping!: AuditItemCategoryMap;

  get vdaResultCalcChain() {
    const flatResultList = this.flatitems(this.items, null);
    console.log("flatResultList", flatResultList);
    const vdaResultCalcChain = chain(flatResultList)
      .groupBy("auditItemCategory")
      .mapValues((rows, auditItemRootCategory) => {
        const cat = this.categoryMapping[auditItemRootCategory];
        if (
          [
            "f74b0be3-52ea-469b-a353-5c844b1c462a" /* P2 */,
            "ccf0c3e7-4926-409a-b16e-54fe1db9096c" /* P5 */,
            "64ad3a97-ec08-4e80-8420-debf2a9ea96c" /* P7 */,
          ].includes(auditItemRootCategory)
        ) {
          return {
            list: [
              [
                rowsToVdaResultList(
                  rows,
                  this.$ct(cat.short || ""),
                  this.$ct(cat.name),

                  "E_" + this.$ct(cat.short || ""),
                  ["E_G", "E_ZC"]
                ),
              ],
            ],
            totalE: "E_" + this.$ct(cat.short ?? ""),
            category: cat,
          };
        } else if (
          [
            "308ae94e-cbce-4ffb-940e-10a69722bc1a" /* P3 */,
            "6ebe1184-875b-44a9-99c6-bfc024a41f7f" /* P4 */,
          ].includes(auditItemRootCategory)
        ) {
          const scoped = groupBy(rows, "auditItemVdaQuestionScope");

          console.log("scoped (P3, P4)", scoped);
          const pd = rowsToVdaResultList(
            scoped["Produkt"] ?? rows,
            "PdP",
            "Product",
            "E_Pd" + this.$ct(cat.short ?? ""),
            ["E_PdP", "E_ZC"]
          );
          const pz = rowsToVdaResultList(
            scoped["Prozess"] ?? rows,
            "Pzp",
            "Process",
            "E_Pz" + this.$ct(cat.short ?? ""),
            ["E_PzP", "E_ZC"]
          );

          const aggrList: VdaResultItem[] = [];
          const pzMap = groupBy(pz.items, "no");

          pz.items.forEach(pzItem => {
            let pdItem = pd.items.find(v => v.no === pzItem.no);
            console.log("ITEMS", pzItem, pdItem);
            if (!pdItem) {
              pdItem = pzItem;
            }

            const applicableCount =
              (pdItem.isApplicable ? 1 : 0) + (pzItem.isApplicable ? 1 : 0);
            const value =
              applicableCount === 0
                ? pdItem.value
                : ((pdItem.isApplicable ? pdItem.value : 0) +
                    (pzItem.isApplicable ? pzItem.value : 0)) /
                  applicableCount;

            // console.log("ITEMS2", applicableCount, value)
            aggrList.push(
              new VdaResultItem(
                value,
                pdItem.no,
                pdItem.isApplicable || pzItem.isApplicable,
                pzItem.star
              )
            );
          });

          const pdMap = groupBy(pd.items, "no");

          const total = new VdaResultList(
            this.$ct(cat.short ?? ""),
            "Process & Product",
            "E_" + this.$ct(cat.short ?? ""),
            ["E_G"],
            aggrList
          );
          return {
            list: [[pd, pz, total]],
            totalE: "E_" + this.$ct(cat.short ?? ""),
            category: cat,
          };
        } else if (
          "f6b3124f-b166-4ae3-8bb7-a30394193081" /* P6 */ ===
          auditItemRootCategory
        ) {
          const process = groupBy(rows, "auditItemVdaProcessName");
          const SubCatNameMapping: Dictionary<string> = {
            "E_P6.1": "E_u1",
            "E_P6.2": "E_u2",
            "E_P6.3": "E_u3",
            "E_P6.4": "E_u4",
            "E_P6.5": "E_u5",
            "E_P6.6": "E_u6",

            "E_6.1": "E_u1",
            "E_6.2": "E_u2",
            "E_6.3": "E_u3",
            "E_6.4": "E_u4",
            "E_6.5": "E_u5",
            "E_6.6": "E_u6",
          };

          const eu7Nos = [
            "6.1.2",
            "6.1.3",
            "6.1.4",
            "6.2.4",
            "6.2.5",
            "6.6.1",
            "6.6.2",
            "6.6.3",
          ];

          return {
            list: chain(process)
              .map((rowsByProcess, processName) => {
                const category = groupBy(
                  rowsByProcess,
                  "auditItemCategoryPath"
                );
                const stepNo = String(
                  (rowsByProcess[0] || {}).auditItemVdaProcessStep || 0
                ).padStart(2, "0");
                const res = chain(category)
                  .map((rowsByCategory, categoryPath) => {
                    const subcat =
                      this.categoryMapping[categoryPath.split("/")[1]];
                    return rowsToVdaResultList(
                      rowsByCategory,
                      this.$ct(subcat.short || "") + " - " + processName,
                      this.$ct(subcat.name),
                      SubCatNameMapping["E_" + this.$ct(subcat.short || "")] +
                        "-" +
                        stepNo,
                      [
                        "E_G",
                        "E_ZC",
                        "E_P6",
                        "E_" + stepNo,
                        SubCatNameMapping["E_" + this.$ct(subcat.short || "")],
                      ]
                    );
                  })
                  .value();
                const eu7Rows = rowsByProcess.filter(v =>
                  eu7Nos.includes(String(v.auditItemNo))
                );
                res.push(
                  rowsToVdaResultList(
                    eu7Rows,
                    "6.7 - " + processName,
                    "6.7 - Transport and handling of parts name",
                    "E_u7-" + stepNo,
                    ["E_u7"]
                  )
                );

                return sortBy(res, "listId");

                /*  [
              [
                rowsToVdaResultList(scoped["Produkt"], cat, "E_PdP"),
                rowsToVdaResultList(scoped["Prozess"], cat, "E_PzP")
              ]
            ],*/
              })
              .value(),

            totalE: "E_" + this.$ct(cat.short || ""),
            category: cat,
          };
        }
      })
      .values()
      .sortBy("totalE")
      .value();

    /*
auditItemCategoryPath
auditItemVdaProcessName
auditItemVdaProcessStep
auditItemVdaQuestionScope*/

    console.log("scoreVDA63 flat audititems", vdaResultCalcChain);
    return vdaResultCalcChain;
  }

  get currentScoreCalculation() {
    const calcChain = this.vdaResultCalcChain;
    const lists: VdaResultList[] = [];
    calcChain.forEach(group => {
      if (group) {
        group.list.forEach(row => {
          lists.push(...row);
        });
      }
    });

    const listE_G = lists.filter(v => v.isPartOf("E_G"));
    const listE_ZC = lists.filter(v => v.isPartOf("E_ZC"));

    const bestCompliance = listE_G.reduce((p, c) => p + c.maxPoints, 0);
    const currentCompliance = listE_G.reduce((p, c) => p + c.points, 0);
    const scoreCompliance = (currentCompliance * 100) / bestCompliance;
    const minAsterisked = Math.min(...listE_G.map(v => v.minStarValue));
    const countNuller = listE_ZC.reduce((p, c) => p + c.countRatingZero, 0);
    console.log("countNuller", countNuller);

    // aggregate EU

    const aggregateResultFromParts = (
      PartPattern: RegExp
    ): Dictionary<number> => {
      const partLists = lists.filter(v =>
        v.totalResultParts.some(n => PartPattern.test(n))
      );

      const res = chain(partLists)
        .groupBy(v => {
          const pattern = v.totalResultParts.find(n => PartPattern.test(n));
          return pattern || "";
        })
        .mapValues((v, key) => {
          // const sumAvg =            v.reduce((p, c) => p + c.compliancePercent, 0) / v.length;

          const s = v.reduce(
            (p, c) => {
              p.count += c.countApplicable;
              p.points += c.points;
              p.maxPoints += c.maxPoints;

              return p;
            },
            { points: 0, maxPoints: 0, count: 0 }
          );
          console.log("s", key, s, v);
          return (s.points * 100) / s.maxPoints;
          // return { sumAvg, key, s, r: (s.points * 100) / s.maxPoints };
        })

        .value();
      console.log(res, PartPattern);
      return res;
    };

    const scoreEu = Math.min(
      ...Object.values(aggregateResultFromParts(/^E_u[1234567]$/)).filter(
        v => !isNaN(v)
      )
    );

    const scoreE = Math.min(
      ...Object.values(aggregateResultFromParts(/^E_\d{1,2}$/)).filter(
        v => !isNaN(v)
      )
    );

    const scoreEp = Math.min(
      ...Object.values(aggregateResultFromParts(/^E_P[234567]$/)).filter(
        v => !isNaN(v)
      )
    );

    console.log("score", scoreEu, scoreE, scoreEp);

    const scoreMap: { [i: number]: { name: string; color: string } } = {
      1: { name: "A", color: "green" },
      2: { name: "B", color: "orange" },
      3: { name: "C", color: "red" },
      0: { name: "n.e.", color: "grey" },
    };

    const mapScore = (score: number) => {
      return scoreMap[score] || "-";
    };

    /**
     * @param input Prozentwert des ergebnisses
     */
    const calcScoreBase = (input: number) => {
      // Base Score
      let score = 3;
      if (input > 90) {
        score = 1;
      } else if (input > 80) {
        score = 2;
      }
      return score;
    };

    /**
     * @param input Geringster Wert aller Sternchenfragen
     *
     */
    const calcScoreDG1 = (input: number) => {
      let score = 1;
      if (input === 0) {
        score = 3;
      } else if (input <= 4) {
        score = 2;
      }
      return score;
    };

    /**
     * @param input Anzahl der Nuller Antworten
     */
    const calcScoreDG2 = (input: number) => {
      let score = 1;
      if (input > 0) {
        score = 2;
      }
      return score;
    };

    /**
     * @param input Minimaler Wert von EU1-EU7
     */
    const calcScoreDG3 = (input: number) => {
      let score = 1;
      if (input < 80) {
        score = 2;
      }
      return score;
    };

    /**
     * @param input Minimaler Wert von E1-E10 Ergebnis der Prozessschritte
     */
    const calcScoreDG4 = (input: number) => {
      // TODO: Wie geht man am Besten mit undefiniertem Eingang um -> Soll score 0 ergeben
      let score = 0;
      if (input < 70) {
        score = 3;
      } else if (input < 80) {
        score = 2;
      } else if (input <= 100) {
        score = 1;
      }
      return score;
    };

    const calcScoreFinal = (input: any) => {
      const scoreArray: Array<number> = [];
      scoreArray.push(calcScoreBase(input.scoreCompliance));

      const asteriskedQuestions = 0;
      scoreArray.push(calcScoreDG1(input.asteriskedQuestions));

      const nullerQuestion = null;
      scoreArray.push(calcScoreDG2(input.nullerQuestion));

      // Downgrade 3 -> Eu1...Eu7
      const eu17Questions = null;
      scoreArray.push(calcScoreDG3(input.eu17Questions));

      // Downgrade 4 -> E1...E10
      const e110Questions = null;
      scoreArray.push(calcScoreDG4(input.e110Questions));

      // Downgrade 4 -> E1...E10
      const ep27Questions = null;
      scoreArray.push(calcScoreDG4(input.e110Questions));

      console.log("scoreVDA63 scoreArray", scoreArray);
    };

    const vdaResult: any[] = [
      { header: "Result without downgrade" },
      {
        scoreValue: calcScoreBase(scoreCompliance),
        title: "Calculated EG",
        subtitle: "C < 80, B < 90",
      },
      { divider: true },
      { header: "Downgrade" },
      {
        scoreValue: calcScoreDG1(minAsterisked),
        title: "Asterisk questions for EG",
        subtitle:
          'minimum 1 question with 4 rating = "B", minimum 1 quesiton with 0 = "C"',
      },
      {
        scoreValue: calcScoreDG2(countNuller),
        title: "No question rated 0",
        subtitle: "otherwise B",
      },
      {
        scoreValue: calcScoreDG3(scoreEu),
        title: "Eu1...Eu7",
        subtitle: '<80% rated "B"',
      },
      {
        scoreValue: calcScoreDG4(scoreE),
        title: "E1...E10",
        subtitle: '<80% rated "B", <70% rated "C"',
      },
      {
        scoreValue: calcScoreDG4(scoreEp),
        title: "Sub ratings Ep2...Ep7",
        subtitle: '<80% rated "B", <70% rated "C"',
      },
      { divider: true },
      { header: "Final result" },
    ];

    let maxScoreVal = 0;
    vdaResult.forEach(v => {
      if (typeof v.scoreValue === "number") {
        maxScoreVal = Math.max(v.scoreValue, maxScoreVal);
        v.score = scoreMap[v.scoreValue].name;
        v.color = scoreMap[v.scoreValue].color;
      }
    });
    vdaResult.push({
      score: scoreMap[maxScoreVal].name,
      color: scoreMap[maxScoreVal].color,
      title: "Final result with downgrades",
      subtitle: "Final overall compliance",
    });
    return vdaResult;
  }
}
