



































































































/* tslint:disable:no-console */
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";

import { api } from "@/store/modules/audit";
import { api as appApi } from "@/store/modules/app";
import { api as confApi } from "@/store/modules/configuration";

import { WorkflowActionPayload } from "@auditcloud/shared/lib/types/WorkflowActions";
import { nullable } from "@auditcloud/shared/lib/types/common";

import AWorkflowBar from "@/components/widgets/AWorkflowBar.vue";
// Dialogs

import { AuditMetadataClient } from "@/types/Audit";
import { ROUTE_NAMES, DIALOG_NAMES } from "@/routenames";
import { TodoAny, TodoArray } from "@auditcloud/shared/lib/utils/type-guards";
import { Route } from "vue-router";
import { AuditWorkflowStepInfo } from "@/store/modules/audit/types";
import { AuditStatusId } from "@auditcloud/shared/lib/constants";
import { Dictionary } from "lodash";
import { formatAuditName } from "@auditcloud/shared/lib/utils/formatting/audits";
import {
  AuditProgramConfig,
  OrganizationUnitConfig,
} from "@auditcloud/shared/lib/schemas";

@Component({
  components: {
    AWorkflowBar,
  },
})
export default class AAudit extends Vue {
  get currentBreadCrumb() {
    const year = this.year;

    console.log("auditmetadata", this.auditMetadata);

    const currentBreadCrumb: TodoArray = [
      {
        text: this.$t("views.audit.home"),
        to: { name: ROUTE_NAMES.DASHBOARDSCOPED },
        activeclass: "",
      },
    ];

    if (this.hasAuditProgramGrid && year && this.auditProgramId) {
      currentBreadCrumb.push({
        text: this.$t("sitemap.audit_programm"),
        to: {
          name: ROUTE_NAMES.AUDIT_PROGRAMS,
        },
        activeclass: "",
      });

      if (this.organizationUnitId) {
        currentBreadCrumb.push({
          text: this.organizationUnitName,
          to: {
            name: ROUTE_NAMES.AUDIT_PROGRAMS,
            params: {
              organizationUnitId: this.organizationUnitId,
            },
          },
          activeclass: "",
        });
      }

      const params: {
        auditProgramId: string;
        organizationUnitId?: string;
        year: string;
      } = {
        auditProgramId: this.auditProgramId,
        year: String(year),
      };
      if (this.organizationUnitId) {
        params.organizationUnitId = this.organizationUnitId;
      }

      currentBreadCrumb.push({
        text: this.auditProgramName,
        to: {
          name: this.organizationUnitId
            ? ROUTE_NAMES.AUDITMATRIX_UNIT_PROGRAM_FILTERED
            : ROUTE_NAMES.AUDITMATRIX_PROGRAM_FILTERED,
          params,
        },
        activeclass: "",
      });

      const name = this.auditProgramName
        ? ROUTE_NAMES.AUDITMATRIX_PROGRAM_FILTERED
        : ROUTE_NAMES.AUDITMATRIX_YEAR_FILTERED;

      // the Retranslation from the named route doesn't work
      // cause of that build path by hand
      const path = `/audits${
        this.organizationUnitId ? `/-orgs/${this.organizationUnitId}` : ""
      }/${this.auditProgramId}/${year ?? "all"}`;

      currentBreadCrumb.push({
        text: `${year ?? "####"}`,
        to: path,
        activeclass: "",
      });
    } else {
      currentBreadCrumb.push({
        text: this.$t("views.audit.plan", { year }),
        to: {
          name: ROUTE_NAMES.AUDITMATRIX_YEAR_FILTERED,
          params: { year: String(year ?? "all") },
        },

        activeclass: "",
      });
    }
    currentBreadCrumb.push({
      text: this.title,
      to: {
        name: this.$route.name ?? "error",
        params: {
          ...this.$route.params,
          dialogName: DIALOG_NAMES.AUDIT_DATA,
        },
        query: this.$route.query,
      },
      activeclass: "",
      actionicon: "info",
    });
    return currentBreadCrumb;
  }

  get year(): number | null {
    return this.auditMetadata?.planning_year ?? null;
  }

  get auditProgramId(): string | null {
    return this.auditMetadata?.auditProgramId ?? null;
  }

  get auditProgramName() {
    const auditProgram = this.mappedAuditPrograms[this.auditProgramId ?? ""];
    if (auditProgram) {
      return this.$ft(auditProgram.name);
    } else {
      return "Unknown";
    }
  }

  get organizationUnitId() {
    const auditProgram = this.mappedAuditPrograms[this.auditProgramId ?? ""];
    if (auditProgram) {
      return auditProgram.organizationUnitId ?? null;
    } else {
      return null;
    }
  }

  get organizationUnitName() {
    const organizationUnit =
      this.mappedOrganizationUnits[this.organizationUnitId ?? ""];
    if (organizationUnit) {
      return this.$ft(organizationUnit.name);
    } else {
      return "Unknown";
    }
  }

  get title() {
    const auditMeta = this.auditMetadata;
    if (!auditMeta) {
      return this.$t("views.audit.new_audit");
    }
    return formatAuditName(auditMeta);
  }

  get workflowStep(): number {
    console.log("workflowStep:changed", this.auditStatus);
    const res = parseInt(this.auditStatus || "", 10);
    if (isNaN(res)) {
      return 0;
    } else {
      return res;
    }
  }

  @Getter(confApi.getters.hasAuditdProgramGrid, {
    namespace: confApi.namespace,
  })
  hasAuditProgramGrid!: boolean;

  selectedStep: number = 0;

  @Prop({
    type: String,
    default: "",
  })
  readonly auditId!: string;

  @Getter(api.getters.hasAuditDocument, {
    namespace: api.namespace,
  })
  hasAuditDocument!: boolean;

  @Getter(api.getters.isExistingDocument, {
    namespace: api.namespace,
  })
  isExistingDocument!: boolean;

  @Getter(api.getters.getAuditLoadingStateView, { namespace: api.namespace })
  view!: "loading" | "loading-items" | "metadata" | "full" | "error";

  @Getter(api.getters.getAuditLoadingError, { namespace: api.namespace })
  auditLoadingError!: TodoAny;

  @Getter(api.getters.getAuditMetadata, {
    namespace: api.namespace,
  })
  auditMetadata!: nullable<AuditMetadataClient>;

  actions: WorkflowActionPayload = {};

  @Getter(api.getters.getWorkflowSteps, {
    namespace: api.namespace,
  })
  workflowStepsAll!: AuditWorkflowStepInfo[];

  get workflowSteps() {
    return this.workflowStepsAll.filter(
      v => v.statusId !== AuditStatusId.Canceled
    );
  }

  @Getter(api.getters.getAuditStatus, {
    namespace: api.namespace,
  })
  auditStatus!: string | null;

  @Getter(appApi.getters.getCurrentLanguage, { namespace: appApi.namespace })
  currentLanguage!: TodoAny;

  @Getter(confApi.getters.getMappedAuditPrograms, {
    namespace: confApi.namespace,
  })
  mappedAuditPrograms!: Dictionary<AuditProgramConfig>;

  @Getter(confApi.getters.getMappedOrganizationUnits, {
    namespace: confApi.namespace,
  })
  mappedOrganizationUnits!: Dictionary<OrganizationUnitConfig>;

  @Action(api.actions.setAudit, { namespace: api.namespace })
  setAudit!: (payload: {
    auditId: string;
    loadSubDocuments: boolean;
  }) => Promise<boolean>;

  @Action(api.actions.clearAudit, { namespace: api.namespace })
  clearAudit!: () => Promise<boolean>;

  @Action(appApi.actions.setBreadCrumb, { namespace: appApi.namespace })
  setBreadCrumb!: (currentBreadCrumb: any[]) => Promise<boolean>;

  @Action(appApi.actions.setMenuPanel, { namespace: appApi.namespace })
  setMenuPanel!: () => Promise<boolean>;

  @Mutation(api.mutations.SET_ACTIVE_LIST, { namespace: api.namespace })
  setActiveList!: (listName: string) => void;

  @Mutation(appApi.mutations.SET_FOOTER_COMPONENT, {
    namespace: appApi.namespace,
  })
  setFooterComponent!: (ComponentName?: string) => void;
  onWorkflowStatusClicked(stepNo: number) {
    const step = this.workflowStepsAll[stepNo];
    if (step) {
      this.$router.push({
        name: step.route,
      });
    }
  }

  @Watch("auditStatus")
  onAuditStatusChanged(newVal: string | null) {
    console.log("onAuditStatusChanged:0", newVal);
    if (
      typeof newVal === "string" &&
      this.$router.currentRoute.name === ROUTE_NAMES.AUDIT
    ) {
      const step = this.workflowStepsAll[this.workflowStep];
      console.log("onAuditStatusChanged:1", step, newVal);

      this.$router.replace({
        name: step.route,
      });
    }
  }

  @Watch("auditId", { immediate: true, deep: true })
  onAuditIdChanged() {
    this.clearAudit().then(() => {
      this.setAudit({ auditId: this.auditId, loadSubDocuments: true });
      this.setMenuPanel();
      const selected = this.workflowStepsAll.findIndex(
        item => item.route === this.$route.name
      );
      if (selected >= 0) {
        this.setActiveList(this.workflowStepsAll[selected].route);
        this.selectedStep = selected;
      }
    });
  }

  @Watch("auditMetadata", { immediate: true, deep: true })
  onAuditMetadataChanged() {
    this.updateBreadCrumbs();
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(newVal: Route, oldVal?: Route) {
    const selected = this.workflowStepsAll.findIndex(
      item => item.route === newVal.name
    );
    console.log("onUrlChange", newVal, oldVal, selected);
    if (selected >= 0) {
      this.setActiveList(this.workflowStepsAll[selected].route);
      this.selectedStep = selected;
    } else {
      const auditStatus = this.auditStatus;
      if (
        ROUTE_NAMES.AUDIT === newVal.name &&
        newVal.params.auditId === oldVal?.params.auditId &&
        auditStatus
      ) {
        this.onAuditStatusChanged(auditStatus);
      }
      this.setActiveList("");
      this.selectedStep = 0;
    }
    this.updateBreadCrumbs();
  }

  @Watch("currentLanguage", { immediate: true, deep: true })
  onLaguageChange() {
    this.updateBreadCrumbs();
  }

  updateBreadCrumbs() {
    this.setBreadCrumb(this.currentBreadCrumb);
  }

  created() {
    this.setFooterComponent("AAuditFooter");
  }

  beforeDestroy() {
    this.setFooterComponent();
    this.clearAudit();
  }
}
