






















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { TodoArray } from "@auditcloud/shared/lib/utils/type-guards";

@Component({})
export default class MoreMenu extends Vue {
  @Prop({
    type: Array,
    required: true,
    default: () => [
      { title: "Click Me", event: "e1", payload: "data" },
      { title: "Click Me Noooow", event: "e2", payload: { a: 1 } },
    ],
  })
  items!: TodoArray;
}
