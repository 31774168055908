var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"secondary"}},[_c('v-btn',{attrs:{"color":"primary","dark":"","icon":""},nativeOn:{"click":function($event){return _vm.close($event)}}},[_c('v-icon',[_vm._v(_vm._s("mdi-close"))])],1),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("schema_forms.common.signature_of"))+" ")])],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('AUserAutocompleteSingle',{attrs:{"placeholder":_vm.$t(
                  'components.controls.user_autocomplete.search_place_holder'
                ),"query-action":_vm.assignedToQueryAction,"error":_vm.missingUser},on:{"input":function($event){return _vm.onUserChanged($event)}},model:{value:(_vm.localeParticipant),callback:function ($$v) {_vm.localeParticipant=$$v},expression:"localeParticipant"}})],1),_c('v-spacer')],1),_c('v-row',{attrs:{"dense":""}},[_c('v-select',{attrs:{"flat":"","label":_vm.$t('components.dialogs.audit_reporting_dialog.attendees_role'),"placeholder":_vm.$t('components.dialogs.audit_reporting_dialog.sign_role'),"items":_vm.userRoles,"error":_vm.missingRole},on:{"change":function($event){return _vm.onRoleChanged($event)}},model:{value:(_vm.localeRole),callback:function ($$v) {_vm.localeRole=$$v},expression:"localeRole"}})],1),_c('v-row',[(_vm.missingSignatureSmall)?_c('v-alert',{attrs:{"dense":"","outlined":"","width":"100%","type":"error"}},[_vm._v(" Unterschrift wird benötigt ")]):_vm._e()],1)],1)],1),(!_vm.isScreenToSmall)?_c('div',[_c('v-col',{staticClass:"px-5"},[_c('canvas',{ref:"myCanvasInline",staticClass:"sign-pad"})]),_c('v-card-actions',{staticClass:"d-flex flex-wrap pb-3"},[_c('v-col',{staticClass:"text-left"},[(
              (!_vm.value.userRef || !_vm.value.userRef.userSavedSignature) &&
              _vm.allowPersist
            )?_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.userInputIsDirty ||
              _vm.userSignaturepadIsDirty === '' ||
              _vm.disableSignaturePersist},on:{"click":function($event){return _vm.persistUsersignature()}}},[_vm._v(" Unterschrift permanent speichern ")]):_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.loadUsersignature()}}},[_vm._v(" Unterschrift Laden ")])],1),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"text":"","color":"blue darken-1"},on:{"click":_vm.clearSignaturePad}},[_vm._v(" "+_vm._s(_vm.$t("components.dialogs.audit_reporting_dialog.reset_sign"))+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("common.buttons.save"))+" ")])],1)],1)],1):_vm._e(),(_vm.isScreenToSmall)?_c('div',[_c('v-dialog',{attrs:{"fullscreen":"","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mb-6 mr-3",attrs:{"text":"","color":"blue darken-1"},on:{"click":function($event){return _vm.timeOutForCanvasRendering()}}},[_vm._v(" "+_vm._s(_vm.$t("components.dialogs.audit_reporting_dialog.dialog_sign"))+" ")]),_c('v-btn',{staticClass:"mb-6 mr-3",attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("common.buttons.save"))+" ")])],1)]}}],null,false,2127863981),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('canvas',{ref:"myCanvasFullScreen",staticClass:"sign-pad-small-screen"}),_c('v-card-actions',[_c('v-col',{staticClass:"no-wrap text-left"},[(
                  (!_vm.value.userRef || !_vm.value.userRef.userSavedSignature) &&
                  _vm.allowPersist
                )?_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.userInputIsDirty ||
                  _vm.userSignaturepadIsDirty === '' ||
                  _vm.disableSignaturePersist},on:{"click":function($event){return _vm.persistUsersignature()}}},[_vm._v(" Unterschrift permanent speichern ")]):_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.loadUsersignature()}}},[_vm._v(" Unterschrift Laden ")]),_c('v-btn',{attrs:{"text":"","color":"blue darken-1"},on:{"click":_vm.clearSignaturePad}},[_vm._v(" "+_vm._s(_vm.$t("components.dialogs.audit_reporting_dialog.reset_sign"))+" ")])],1),_c('v-col',{staticClass:"no-wrap text-right"},[_c('v-btn',{attrs:{"text":"","color":"blue darken-1"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("common.buttons.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.saveOnFullScreen}},[_vm._v(" "+_vm._s(_vm.$t("common.buttons.save"))+" ")])],1)],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }