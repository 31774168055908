import { AuditItem } from "@auditcloud/shared/lib/schemas";
import {
  createTemplateItemNumberFieldPath,
  createTemplateItemPath,
  createTemplateItemsPath,
} from "@auditcloud/shared/lib/utils/firestorePathHelper";
import firebase from "firebase/compat/app";
import { isNumber, pick } from "lodash";
import { Dictionary } from "vue-router/types/router";
import { TemplateItemsNumberModifierPayload } from "./types";

/**
 * Creates the update data required when updating template item numbers.
 * Fetches all items, updates the ones that need an update and returns
 * the data to update in firestore.
 */
export function createUpdateDataForItemsNumberModification(
  modificationData: TemplateItemsNumberModifierPayload,
  templateItems: Dictionary<AuditItem>
) {
  const updateData: firebase.firestore.UpdateData = {};
  const auditItems = templateItems;

  Object.entries(auditItems).forEach(([id, item]) => {
    if (isNumber(item.question.no)) {
      const no = item.question.no;
      if (
        no >= modificationData.startingNumber &&
        no <= modificationData.endNumber
      ) {
        const path = createTemplateItemNumberFieldPath(id);
        updateData[path] = no + modificationData.modifyBy;
      }
    }
  });
  return updateData;
}

/**
 * Returns the question numbers that need to be modified when
 * moving a question from number {oldNumber} to {newNumber}
 */
export function createPayloadForMovingTemplateItems(
  newNumber: number,
  oldNumber: number
): TemplateItemsNumberModifierPayload {
  const payload: TemplateItemsNumberModifierPayload = {
    startingNumber: newNumber < oldNumber ? newNumber : oldNumber + 1,
    endNumber: newNumber < oldNumber ? oldNumber - 1 : newNumber,
    modifyBy: newNumber < oldNumber ? 1 : -1,
  };
  return payload;
}

/**
 * Returns the question numbers that need to be modified when
 * removing a question with the number {templateItemNo}
 */
export function createPayloadForRemovingTemplateItems(
  templateItemNo: number,
  amountOfAuditItems: number
) {
  const payload: TemplateItemsNumberModifierPayload = {
    startingNumber: templateItemNo + 1,
    endNumber: amountOfAuditItems,
    modifyBy: -1,
  };
  return payload;
}
