





























































































import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { pick } from "lodash";
import AUserAutocompleteMixin, {
  ItemType,
} from "@/components/mixins/AUserAutocompleteMixin.vue";
import AUserAutocompleteCreateDialog from "./AUserAutocompleteCreateDialog.vue";
import AUserAutocompleteCreatePersonDialog from "./AUserAutocompleteCreatePersonDialog.vue";

import { IUserRef, typeIsIUserRef } from "@auditcloud/shared/lib/types/UserRef";
import {
  ConfigurableGetter,
  getterPath,
} from "@auditcloud/shared/lib/types/common";
import { typeIsUserRef } from "@auditcloud/shared/lib/schemas/type-guards";

function UserRef2ItemType(disabled: boolean): (user: IUserRef) => ItemType {
  return (user: IUserRef): ItemType => ({
    id: user.id,
    disabled,
    data: user,
  });
}

@Component({
  components: {
    AUserAutocompleteCreateDialog,
    AUserAutocompleteCreatePersonDialog,
  },
})
export default class AUserAutocompleteMultipleWithName extends AUserAutocompleteMixin {
  @Prop({
    type: Object,
    default: null,
  })
  staticUsersGetter!: ConfigurableGetter | null;

  @Prop({
    type: Boolean,
    default: false,
  })
  closeOnContentClick!: boolean;

  get staticUsers(): IUserRef[] {
    if (this.staticUsersGetter === null) {
      return [];
    }

    const path = getterPath(this.staticUsersGetter);
    const getterResult: any = this.$store.getters[path];

    if (Array.isArray(getterResult) && getterResult.every(typeIsIUserRef)) {
      return getterResult;
    } else {
      console.error(
        "StaticUser for AUserAutocomplete doesn't return valid userRefs"
      );
      return [];
    }
  }

  get staticUserIds(): string[] {
    return this.staticUsers.map(user => user.id);
  }

  xSelected: ItemType[] = [];

  get appendIcon(): string | null {
    return this.hasValue && this.clearable ? "clear" : null;
  }

  get hasValue(): boolean {
    return this.xSelected.length > 0;
  }

  hasItem(item: ItemType): boolean {
    return item.data.id ? true : false;
  }

  @Prop({
    type: Array,
    default: () => [],
  })
  value!: IUserRef[];

  @Watch("value", {
    immediate: true,
    deep: true,
  })
  onValueChanged(newVal: IUserRef[], oldVal: IUserRef[]) {
    this.xItems = this.value.map(UserRef2ItemType(false));
    this.xSelected = this.value.map(UserRef2ItemType(false));
  }

  @Watch("search", {
    immediate: true,
  })
  onSearch(newVal: string, oldVal: string) {
    newVal && this.queryUsers(newVal);
  }

  created() {
    this.itemsTransform = UserRef2ItemType(false);
  }

  openAddUserDialog(evt: any) {
    this.userDialogVisible = true;
    (this.$refs.autocomplete as HTMLInputElement).blur();
  }
  openAddNamedPersonDialog(evt: any) {
    this.personDialogVisible = true;
    (this.$refs.autocomplete as HTMLInputElement).blur();
  }
  userDialogVisible = false;
  personDialogVisible = false;

  get selected(): ItemType[] {
    return [
      ...this.staticUsers.map(UserRef2ItemType(false)),
      ...this.xSelected.filter(
        user => !this.staticUserIds.includes(user.id ?? "")
      ),
    ];
  }

  onSelectionChanged(newVal: ItemType[]) {
    this.xSelected = newVal.map(item => {
      return {
        ...item,
        disabled: false,
      };
    });
    console.log("onSelectionChanged", newVal, this.xSelected);
    this.emitSelectionChanged();
  }

  onClearClicked(evt: MouseEvent) {
    this.xSelected = [];
    this.emitSelectionChanged();
  }

  unselectItem(item: ItemType) {
    if (item.id) {
      this.xSelected = this.xSelected.filter(i => i.id !== item.id);
    } else {
      const removeIndex = this.xSelected.findIndex(
        i => i.data.displayName === item.data.displayName
      );
      this.xSelected.splice(removeIndex, removeIndex >= 0 ? 1 : 0);
    }
    this.emitSelectionChanged();
  }

  emitSelectionChanged() {
    this.search = "";
    const cleanupUserRef = (val: any) => {
      return pick(val, ["displayName", "id", "type"]);
    };

    this.$emit(
      "input",
      this.xSelected.map(item => cleanupUserRef(item.data))
    );
  }

  selectNewUser(person: IUserRef | ItemType): void {
    this.search = "";
    if (typeIsUserRef(person)) {
      this.xItems.push(UserRef2ItemType(false)(person));
      this.xSelected.push(
        UserRef2ItemType(this.staticUserIds.includes(person.id))(person)
      );
    } else {
      this.xItems.push(person);
      this.xSelected.push(person);
    }
    this.emitSelectionChanged();
  }
}
