import Vue from "vue";
import { State } from "./types";
import { nullable } from "@auditcloud/shared/lib/types/common";
import { WatchedDocument, unlistenerFunction } from "@/utils/firestore";
import { defaultPageConfig } from "@auditcloud/shared/lib/types/Audit/types";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import { mapValues } from "lodash";

const mutations = {
  SET_MEASURES_UNLISTENER(
    state: State,
    measuresUnlistener: nullable<unlistenerFunction>
  ) {
    state.loadedNewQuery = true;
    state.SelectedMeasureIds = [];
    const prevMeasureUnlistener = state.MeasureUnlistener;
    state.MeasureUnlistener = measuresUnlistener;
    if (prevMeasureUnlistener) {
      prevMeasureUnlistener();
    }
    state.pageConfig = defaultPageConfig();
  },
  SET_MEASURES(
    state: State,
    {
      removeDocs,
      modifiedDocs,
    }: {
      removeDocs: string[];
      modifiedDocs: WatchedDocument<MeasureProcessDocument | null>[];
    }
  ) {
    console.log("SET_MEASURES", removeDocs, modifiedDocs);
    if (state.loadedNewQuery === true) {
      state.MeasureDocuments = modifiedDocs;
    } else {
      removeDocs.forEach(docId => {
        const idx = state.MeasureDocuments.findIndex(v => v.id === docId);
        if (idx > -1) {
          Vue.delete(state.MeasureDocuments, idx);
        }
      });

      modifiedDocs.forEach(doc => {
        const idx = state.MeasureDocuments.findIndex(v => v.id === doc.id);
        if (idx > -1) {
          Vue.set(state.MeasureDocuments, idx, doc);
        } else {
          state.MeasureDocuments.push(doc);
        }
      });
    }
    state.loadedNewQuery = false;
  },
  SET_MEASURES_METADATA(state: State, updateMetadata: WatchedDocument<null>[]) {
    if (state.loadedNewQuery === true) {
      state.MeasureDocuments = [];
    } else {
      updateMetadata.forEach(doc => {
        const idx = state.MeasureDocuments.findIndex(v => v.id === doc.id);
        if (idx) {
          state.MeasureDocuments[idx].exists = doc.exists;
          state.MeasureDocuments[idx].metadata = doc.metadata;
        }
      });
    }
  },
  CLEAR_MEASURES(state: State, payload: null | unlistenerFunction) {
    const prev_unlistener = state.MeasureUnlistener;
    // To prevent that problems with the execution order of the components lifecycle hook will messup the audits watcher
    if ((payload && payload === prev_unlistener) || payload === null) {
      state.MeasureUnlistener = null;
      state.MeasureDocuments = [];
      state.SelectedMeasureIds = [];
      if (prev_unlistener) {
        prev_unlistener();
      }
    }
  },
  SET_SELECTED_MEASURES(state: State, selectedMeasureIds: string[]) {
    state.SelectedMeasureIds = selectedMeasureIds;
  },
};

const n = mapValues(mutations, (_, key) => key);
export { n as mutationNames, mutations };
