






















































































import Vue, { PropType } from "vue";
import { Component, Prop } from "vue-property-decorator";
import {
  TranslateableText,
  MeasureProcessStepDoc,
} from "@auditcloud/shared/lib/schemas";
import { typeIsTranslateableText } from "@auditcloud/shared/lib/types/common";
import { MetaStatus } from "@auditcloud/shared/lib/workflow/types/State";
import { idable } from "@auditcloud/shared/lib/types/common";

const STEP_STATE = ["open", "doing", "done", "failed"] as const;
type StepState = typeof STEP_STATE[number];

@Component({ components: {} })
export default class AMeasureStepLayout extends Vue {
  @Prop({
    required: true,
    type: String,
  })
  stepId!: string;

  @Prop({
    default: "open",
    type: String,
    validator: (v: any) => STEP_STATE.includes(v),
  })
  stepState!: StepState;

  @Prop({
    required: true,
    // type: [Object, String],
    validator: typeIsTranslateableText,
  })
  title!: TranslateableText;

  @Prop({ type: Boolean, default: false })
  isPreview!: boolean;

  @Prop({ type: String, required: true })
  metaStatus!: MetaStatus;

  @Prop({
    type: Object as PropType<idable<MeasureProcessStepDoc> | null>,
  })
  nextStep?: idable<MeasureProcessStepDoc> | null;

  @Prop({ type: Boolean, default: false })
  isResetStep!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  isCanceledStep!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isRevoked!: boolean;

  get isActive() {
    return this.stepState === "doing";
  }

  get hasSubTitle() {
    return !!this.$slots["sub-title"];
  }

  get hasSlotDefault() {
    return !!this.$slots["default"];
  }

  get hasStepFooter() {
    return !!this.$slots["step-footer"];
  }

  get iconConfig() {
    if (this.isResetStep) {
      return { icon: "mdi-cancel", color: "grey lighten-2" };
    }
    if (this.isCanceledStep) {
      return { icon: "mdi-cancel", color: "warning" };
    }

    switch (this.stepState) {
      case "doing":
        return {
          icon: "mdi-chevron-right",
          color: "primary",
        };
      case "failed":
        return {
          icon: "mdi-close",
          color: this.isRevoked ? "grey lighten-2" : "error",
        };
      case "done":
        return {
          icon: "mdi-check",
          color: this.isRevoked ? "grey lighten-2" : "success",
        };
      default:
        return {
          icon: null,
          color: "#828282",
        };
    }
  }
}
