import "firebase/compat/firestore";
import Vue from "vue";
import { MutationTree, ActionTree, GetterTree, Module } from "vuex";
import { Dictionary, values } from "lodash";

import {
  createCollectionObserver,
  DocumentsUpdateHandlerData,
  OrderByData,
  QueryData,
  unlistenerFunction,
  WatchedDocument,
} from "@/utils/firestore";
import { CollectionNames } from "@auditcloud/shared/lib/types/common";
import { actionNs, getterNs } from "@/utils/VuexHelper";

import { api as userApi, Getters as UserGetters } from "../user";
import { api as configApi, LimitCountType } from "../configuration";
import { RootState } from "@/store/types";
import moment from "moment";
import firebase from "firebase/compat/app";

export const api = {
  namespace: "activities",
  getters: {
    getCountOfNewActivities: "getCountOfNewActivities",
    getSortedActivityList: "getSortedActivityList",
    gethasMoreActivities: "gethasMoreActivities",
    getNewestTimestamp: "getNewestTimestamp",
    getOldestTimestamp: "getOldestTimestamp",
  },
  mutations: {
    resetState: "resetState",
    hasNoMoreData: "hasNoMoreData",
    addActivities: "addActivities",
    setActivityUnlistener: "setActivityUnlistener",
  },
  actions: {
    createActivity: "createActivity",
    startListenOnNewActivities: "startListenOnNewActivities",
    markActivitiesAsRead: "markActivitiesAsRead",
    loadMoreActivities: "loadMoreActivities",
  },
};

export interface ActivityDoc {
  timestamp: string;
  actor: string;
  actor_name: string;
  avatar?: string;
  id: string;
  object: string;
  object_url: string;
  verb: string;
}

class ActivityStreamState {
  loadedActivities: Dictionary<WatchedDocument<ActivityDoc>> = {};
  loadedActivitiesNewQuery: boolean = false;
  hasMoreData: boolean = true;
  unlistener: unlistenerFunction | null = null;
}
const state = new ActivityStreamState();

const mutations: MutationTree<ActivityStreamState> = {
  [api.mutations.resetState](state) {
    const prev_unlistener = state.unlistener;
    Object.assign(state, new ActivityStreamState());
    if (prev_unlistener) {
      prev_unlistener();
    }
  },
  [api.mutations.setActivityUnlistener](state, payload) {
    state.loadedActivitiesNewQuery = true;
    const prev_unlistener = state.unlistener;
    if (prev_unlistener) {
      prev_unlistener();
    }
  },
  [api.mutations.hasNoMoreData](state) {
    state.hasMoreData = false;
  },
  [api.mutations.addActivities](
    state,
    { modifiedDocs }: DocumentsUpdateHandlerData<ActivityDoc>
  ) {
    modifiedDocs.forEach(doc => {
      Vue.set(state.loadedActivities, doc.id, doc);
    });

    state.loadedActivitiesNewQuery = false;
  },
};

function getActivityReadTimestamp(rootGetters: Dictionary<unknown>) {
  return rootGetters[
    getterNs(userApi, userApi.getters.getActivityTimeStamp)
  ] as UserGetters["getActivityTimeStamp"];
}

function getLimitsCount(rootGetters: any): LimitCountType {
  return rootGetters[
    getterNs(configApi, configApi.getters.getLimitCounts)
  ] as LimitCountType;
}

const actions: ActionTree<ActivityStreamState, RootState> = {
  async [api.actions.startListenOnNewActivities]({ commit, rootGetters }) {
    const orderBy = new OrderByData("timestamp", "desc");

    const limitCounts = getLimitsCount(rootGetters);
    const limit = limitCounts.activityLimit;

    const unlistener = createCollectionObserver(
      CollectionNames.ACTIVITIES,
      {
        orderBy,
        limit,
      },
      updateData => {
        commit(api.mutations.addActivities, updateData);
      },
      () => {},
      () => {}
    );

    commit(api.mutations.setActivityUnlistener, unlistener);
    return unlistener;
  },
  async [api.actions.markActivitiesAsRead]({ dispatch, getters }) {
    const newestTimestamp = getters[api.getters.getNewestTimestamp] as
      | string
      | null;
    if (newestTimestamp) {
      return dispatch(
        actionNs(userApi, userApi.actions.storeActivitiesReadTime),
        newestTimestamp,
        { root: true }
      );
    } else {
      return null;
    }
  },
  async [api.actions.createActivity](
    { commit, dispatch },
    payload: ActivityDoc
  ) {
    try {
      await dispatch("app/setLoading", true, { root: true });
      const ref = firebase
        .firestore()
        .collection(CollectionNames.ACTIVITIES)
        .doc();
      await ref.set(payload);

      return ref.id;
    } catch (err) {
      console.error("createActivity failed", err);
      return null;
    } finally {
      await dispatch("app/setLoading", false, { root: true });
    }
  },
  async [api.actions.loadMoreActivities]({
    commit,
    rootGetters,
    state,
    getters,
  }) {
    try {
      const limitCounts = getLimitsCount(rootGetters).activityLimit;
      const oldestDate = getters[api.getters.getOldestTimestamp] as string;
      const items = state.loadedActivities;

      const snapshot = await firebase
        .firestore()
        .collection(CollectionNames.ACTIVITIES)
        .orderBy("timestamp", "desc")
        .startAt(oldestDate)
        .limit(limitCounts)
        .get();

      const newDocIds = snapshot.docs.map(doc => doc.id);
      if (snapshot.size < limitCounts) {
        commit(api.mutations.hasNoMoreData);
      } else if (newDocIds.every(id => !!items[id])) {
        console.warn(
          `Kann keine älteren Documente laden da mehr als ${limitCounts} Einträge mit dem exact selben Zeitstempel in der Collection vorhanden sind.\nDeaktiviere das nachladen`
        );
        commit(api.mutations.hasNoMoreData);
      }
      const modifiedDocs: WatchedDocument<ActivityDoc>[] = snapshot.docs.map(
        doc => {
          return {
            id: doc.id,
            data: doc.data() as ActivityDoc,
            exists: doc.exists,
            metadata: {
              ...doc.metadata,
            },
          };
        }
      );

      commit(api.mutations.addActivities, {
        modifiedDocs,
        removeDocs: [],
      });
      return snapshot.size;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
};

const getters: GetterTree<ActivityStreamState, RootState> = {
  [api.getters.getSortedActivityList](state): ActivityDoc[] {
    const activities = values(state.loadedActivities).map(v => v.data);

    return activities.sort((a, b) => {
      return a.timestamp.localeCompare(b.timestamp) * -1; // Reihenfolge umkehren
    });
  },
  [api.getters.getCountOfNewActivities](
    state,
    getters,
    rootState,
    rootGetters
  ): number {
    const now = moment(new Date());
    const readTimeStamp = getActivityReadTimestamp(rootGetters);
    const lastTwoWeeks = moment()
      .subtract(2, "weeks")
      .startOf("day")
      .toISOString();
    const lastReadTimestamp = moment(readTimeStamp ?? lastTwoWeeks);
    const sortedItems = getters[
      api.getters.getSortedActivityList
    ] as ActivityDoc[];

    return sortedItems.reduce((count, activity) => {
      const ts = moment(activity.timestamp);

      return ts.isBetween(lastReadTimestamp, now, undefined, "(]")
        ? count + 1
        : count;
    }, 0);
  },
  [api.getters.gethasMoreActivities](state) {
    return state.hasMoreData;
  },
  [api.getters.getNewestTimestamp](state, getters): string | null {
    const sortedItems = getters[
      api.getters.getSortedActivityList
    ] as ActivityDoc[];
    if (sortedItems.length > 0) {
      return new Date(sortedItems[0].timestamp).toISOString();
    } else {
      return null;
    }
  },

  [api.getters.getOldestTimestamp](state, getters): string {
    const sortedItems = getters[
      api.getters.getSortedActivityList
    ] as ActivityDoc[];
    if (sortedItems.length > 0) {
      return new Date(
        sortedItems[sortedItems.length - 1].timestamp
      ).toISOString();
    } else {
      return new Date().toISOString();
    }
  },
};

const namespaced: boolean = true;
const modul: Module<ActivityStreamState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
export default modul;
