







/* tslint:disable:no-console */
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { pick } from "lodash";
import {
  TodoMap,
  TodoArray,
  TodoAny,
  TodoActionSignature,
  TodoMutationSignature,
} from "@auditcloud/shared/lib/utils/type-guards";

import AMenuBreadCrumb from "@/components/app/MenuBreadCrumb.vue";
import AMenuTabs from "@/components/app/MenuTabs.vue";

import { api as appApi } from "@/store/modules/app";
import { DIALOG_NAMES } from "../../routenames";

@Component({
  components: { AMenuBreadCrumb, AMenuTabs },
})
export default class MenuContent extends Vue {
  get menucomponent(): string {
    if (this.mymenupanel === "dashboard") {
      return "AMenuTabs";
    } else {
      return "AMenuBreadCrumb";
    }
  }
  @Getter(appApi.getters.getMenuPanel, { namespace: appApi.namespace })
  mymenupanel!: string;

  openAttachmentsSidebar() {
    const route = {
      name: this.$route.name ?? "error",
      params: {
        ...this.$route.params,
        dialogName: DIALOG_NAMES.AUDIT_ATTACHMENTS,
      },
    };

    /**
     * {name: "auditExecution", params: {…}}
name: "auditExecution"
params:
auditId: "MPTUQJiYbrCazdTZKLGP"
dialogName: "next"
     */
    console.log("openAttachmentsSidebar", route);
    this.$router.push(route);
  }
}
