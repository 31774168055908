import { MutationTree } from "vuex";
import Vue from "vue";
import {
  CurrentAuditState,
  DocumentType,
  DocumentAuditItemsType,
  initialLoadingState,
  AuditLoadingState,
  AuditListsSettings,
} from "./types";

import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";

import { nullable } from "@auditcloud/shared/lib/types/common";
import { unlistenerFunction } from "@/utils/firestore";

import { triggerUnlistener } from "@/utils/firestore";
import { omit, isEqual, xor, xorWith } from "lodash";

import { ROUTE_NAMES } from "@/routenames";
import {
  FILTER_ANSWERD,
  FILTER_MATURITY,
} from "@auditcloud/shared/lib/utils/filter/AuditItemListManipulatorIds";
import {
  defaultDocType,
  defaultPageConfig,
  SortSetting,
} from "@auditcloud/shared/lib/types/Audit/types";
import { AuditMetadataDoc } from "@auditcloud/shared/lib/schemas";
import { Filter } from "@auditcloud/shared/lib/utils/filter/types";
import { getEmptyListSetting } from "./types";
import { AuditItemWithId } from "@auditcloud/shared/lib/utils/audit/types";

export type SetMeasuresPayload = {
  auditItemId: string;
  findingId: string;
  measureId: string;
  measure: MeasureProcessDocument;
}[];

const SET_UNLISTENER = "SET_UNLISTENER";
const SET_AUDITITEMS_UNLISTENER = "SET_AUDITITEMS_UNLISTENER";
const SET_MEASURE_UNLISTENER = "SET_MEASURE_UNLISTENER";
const SET_CURRENTAUDITMETADATA = "SET_CURRENTAUDITMETADATA";

const SET_AUDITITEMS_DOCUMENT = "SET_AUDITITEMS_DOCUMENT";
const SET_FINDING_DOCUMENTS = "SET_FINDING_DOCUMENTS";
const CLEAR_AUDIT = "CLEAR_AUDIT";
const SET_FULLTEXT_SEARCH = "SET_FULLTEXT_SEARCH";
const PUSH_SORT_SETTING = "PUSH_SORT_SETTING";
const REMOVE_SORT_SETTING = "REMOVE_SORT_SETTING";
const SET_FILTER_SETTING = "SET_FILTER_SETTING";
const TOGGLE_FILTER_SETTING = "TOGGLE_FILTER_SETTING";
const SET_FILTERS = "SET_FILTERS";
const CLEAR_FILTER_FOR_AGGREGATIONS = "CLEAR_FILTER_FOR_AGGREGATIONS";
const SET_CONFLICT_FILTER = "SET_CONFLICT_FILTER";
const SET_UNANSWERED_MATURITIES_FILTER = "SET_UNANSWERED_MATURITIES_FILTER";
const CLEAR_FILTER_SETTINGS = "CLEAR_FILTER_SETTINGS";
const REMOVE_FILTER_SETTING = "REMOVE_FILTER_SETTING";

const SET_AUDIT_STATE_DIRTY = "SET_AUDIT_STATE_DIRTY";
const SET_AUDIT_TRANSITION_DIRTY = "SET_AUDIT_TRANSITION_DIRTY";

const SET_PAGING_CONFIG = "SET_PAGING_CONFIG";
const SET_ACTIVE_LIST = "SET_ACTIVE_LIST";
const SET_ERROR_AUDIT_DATA_PERMISSION_DENIED =
  "SET_ERROR_AUDIT_DATA_PERMISSION_DENIED";
const SET_SELECTED_AUDITITEM_IDS = "SET_SELECTED_AUDITITEM_IDS";
const TOOGLE_SELECTED_AUDITITEM_ID = "TOOGLE_SELECTED_AUDITITEM_ID";
const UPDATE_LOADING_STATE = "UPDATE_LOADING_STATE";
const SET_AUDIT_LOADING_ERROR = "SET_AUDIT_LOADING_ERROR";
const SET_BULK_UPDATING_STATE = "SET_BULK_UPDATING_STATE";

const n = {
  SET_UNLISTENER,
  SET_AUDITITEMS_UNLISTENER,
  SET_MEASURE_UNLISTENER,
  SET_CURRENTAUDITMETADATA,

  SET_AUDITITEMS_DOCUMENT,
  SET_FINDING_DOCUMENTS,
  CLEAR_AUDIT,
  SET_FULLTEXT_SEARCH,
  PUSH_SORT_SETTING,

  REMOVE_SORT_SETTING,
  SET_FILTER_SETTING,
  TOGGLE_FILTER_SETTING,
  SET_FILTERS,
  CLEAR_FILTER_FOR_AGGREGATIONS,

  SET_CONFLICT_FILTER,
  SET_UNANSWERED_MATURITIES_FILTER,
  CLEAR_FILTER_SETTINGS,
  REMOVE_FILTER_SETTING,
  SET_AUDIT_STATE_DIRTY,
  SET_AUDIT_TRANSITION_DIRTY,
  SET_PAGING_CONFIG,
  SET_ACTIVE_LIST,
  SET_ERROR_AUDIT_DATA_PERMISSION_DENIED,
  SET_SELECTED_AUDITITEM_IDS,
  TOOGLE_SELECTED_AUDITITEM_ID,
  UPDATE_LOADING_STATE,
  SET_AUDIT_LOADING_ERROR,
  SET_BULK_UPDATING_STATE,
};

const mutations: MutationTree<CurrentAuditState> = {
  [n.SET_CURRENTAUDITMETADATA](state, unpatchedPayload: DocumentType) {
    const currentDoc = state.Document;
    const patchPayload = (payload: DocumentType) => {
      if (payload.data && !payload.data.auditPreparation) {
        console.error("Expect that the migration is done use fallback");
        const auditPreparation: AuditMetadataDoc["auditPreparation"] = {
          templateIds: [],
          step: "preselection",
          activePreselectionFilters: [],
          manuallyConsideredAuditItemIds: {},
        };
        return {
          ...payload,

          data: {
            ...payload.data,
            auditPreparation,
          },
        };
      } else {
        return payload;
      }
    };
    const payload = patchPayload(unpatchedPayload);

    if (currentDoc && currentDoc.id === payload.id) {
      currentDoc.data = payload.data;
      currentDoc.metadata = payload.metadata;
      currentDoc.exists = payload.exists;
      // Vue.set(state, api.state.Document, payload);
    } else if (state.auditId === payload.id) {
      state.Document = payload;
    } else {
      console.warn(
        "Triggerd SET_CURRENTAUDITMETADATA for unexpected auditId",
        state.auditId,
        payload.id
      );
    }
  },

  [n.SET_AUDITITEMS_DOCUMENT](
    state,
    { doc, auditId }: { doc: DocumentAuditItemsType; auditId: string }
  ) {
    const currentDoc = state.AuditItemsDocument;
    if (currentDoc && currentDoc.id === doc.id && state.auditId === auditId) {
      currentDoc.data = doc.data;
      currentDoc.metadata = doc.metadata;
      currentDoc.exists = doc.exists;
      // Vue.set(state, api.state.Document, payload);
    } else if (state.auditId === auditId) {
      state.AuditItemsDocument = doc;
    } else {
      console.warn(
        "Triggerd SET_AUDITITEMS_DOCUMENT for unexpected auditId",
        state.auditId,
        auditId
      );
    }
  },
  [n.SET_UNLISTENER](
    state,
    {
      auditId,
      auditUnlistener,
    }: {
      auditId: string;
      auditUnlistener: nullable<unlistenerFunction>;
    }
  ) {
    const prevUnlistener = [
      state.Unlistener,
      state.AuditItemsUnlistener,
      state.MeasureUnlistener,
    ];

    state.auditId = auditId;
    state.loadingError = null;
    state.loadingState = initialLoadingState();

    state.Unlistener = auditUnlistener;
    state.MeasureUnlistener = null;
    state.AuditItemsUnlistener = null;
    prevUnlistener.forEach(triggerUnlistener);

    state.pageConfig = defaultPageConfig();
  },
  [n.SET_AUDITITEMS_UNLISTENER](
    state,
    auditItemsUnlistener: nullable<unlistenerFunction>
  ) {
    const prevAuditItemsUnlistener = state.AuditItemsUnlistener;
    state.AuditItemsUnlistener = auditItemsUnlistener;
    state.missingAuditDataReadPermission = false;
    triggerUnlistener(prevAuditItemsUnlistener);
  },
  [n.SET_MEASURE_UNLISTENER](
    state,
    {
      measuresUnlistener,
    }: {
      measuresUnlistener: nullable<unlistenerFunction>;
    }
  ) {
    const prevMeasureUnlistener = state.MeasureUnlistener;

    state.MeasureUnlistener = measuresUnlistener;
    triggerUnlistener(prevMeasureUnlistener);
  },
  [n.CLEAR_AUDIT](state, payload: AuditListsSettings) {
    const prevUnlistener = [
      state.Unlistener,
      state.AuditItemsUnlistener,
      state.MeasureUnlistener,
    ];
    state.auditId = null;
    state.loadingError = null;
    state.loadingState = initialLoadingState();

    state.Unlistener = null;
    state.MeasureUnlistener = null;
    state.AuditItemsUnlistener = null;

    state.Document = defaultDocType();
    state.AuditItemsDocument = defaultDocType();
    state.fulltextSearch = "";
    state.activeList = "";
    state.listSettings = payload;
    state.missingAuditDataReadPermission = false;

    prevUnlistener.forEach(triggerUnlistener);
  },
  [n.SET_FULLTEXT_SEARCH](state, payload: string) {
    state.fulltextSearch = payload;
    state.pageConfig = { ...state.pageConfig, page: 1 };
  },
  [n.PUSH_SORT_SETTING](state, payload: SortSetting) {
    if (state.listSettings[state.activeList]) {
      const sortings = [...state.listSettings[state.activeList].sortings];

      const sorter = sortings.find(v => v.id === payload.id);
      if (sorter) {
        sorter.desc = payload.desc;
      } else {
        sortings.unshift(payload);
      }
      state.listSettings[state.activeList].sortings = sortings;
    } else {
      const listSettings = getEmptyListSetting();
      listSettings.sortings = [payload];
      Vue.set(state.listSettings, state.activeList, listSettings);
    }
  },
  [n.REMOVE_SORT_SETTING](state, payload: string) {
    if (state.listSettings[state.activeList]) {
      const sortings = [...state.listSettings[state.activeList].sortings];

      const sorter_idx = sortings.findIndex(v => v.id === payload);
      if (sorter_idx >= 0) {
        sortings.splice(sorter_idx, 1);
        state.listSettings[state.activeList].sortings = sortings;
      }
    }
  },
  [n.SET_FILTER_SETTING](state, payload: Filter) {
    if (state.listSettings[state.activeList]) {
      const idx = state.listSettings[state.activeList].filters.findIndex(
        filter => isEqual(filter, payload)
      );
      if (idx < 0) {
        state.pageConfig = { ...state.pageConfig, page: 1 };
        const filters = [
          ...state.listSettings[state.activeList].filters,
          payload,
        ];

        state.listSettings[state.activeList].filters = filters;
      }
    } else {
      const listSettings = getEmptyListSetting();
      listSettings.filters = [payload];
      Vue.set(state.listSettings, state.activeList, listSettings);
    }
  },
  [n.TOGGLE_FILTER_SETTING](state, filter: Filter) {
    const activeSetting = state.listSettings[state.activeList];
    if (activeSetting) {
      activeSetting.filters = xorWith(activeSetting.filters, [filter], isEqual);
      state.pageConfig = { ...state.pageConfig, page: 1 };
    } else {
      const listSettings = getEmptyListSetting();
      listSettings.filters = [filter];
      Vue.set(state.listSettings, state.activeList, listSettings);
      state.pageConfig = { ...state.pageConfig, page: 1 };
    }
  },
  [n.SET_FILTERS](state, filters: Filter[]) {
    const activeSetting = state.listSettings[state.activeList];
    if (activeSetting) {
      activeSetting.filters = filters;
    } else {
      const listSettings = getEmptyListSetting();
      listSettings.filters = filters;
      Vue.set(state.listSettings, state.activeList, listSettings);
    }
  },
  [n.CLEAR_FILTER_FOR_AGGREGATIONS](
    state,
    aggregationId: Filter["aggregationId"]
  ) {
    const activeSetting = state.listSettings[state.activeList];
    if (activeSetting) {
      activeSetting.filters = activeSetting.filters.filter(
        activeFilter => activeFilter.aggregationId !== aggregationId
      );
    }
  },
  [n.SET_CONFLICT_FILTER](state) {
    console.log("activateConflictFilter", state.listSettings, state.activeList);
    const conflictFilter: Filter = {
      aggregationId: FILTER_ANSWERD,
      value: "has_conflicts",
    };

    const setFilter = (list: string) => {
      if (state.listSettings[list]) {
        state.listSettings[state.activeList].filters = [conflictFilter];
      } else {
        const listSettings = getEmptyListSetting();
        listSettings.filters = [conflictFilter];
        Vue.set(state.listSettings, state.activeList, listSettings);
      }
    };

    setFilter(ROUTE_NAMES.AUDITEXECUTION);
    setFilter(ROUTE_NAMES.AUDITWRAPUP);
  },
  [n.SET_UNANSWERED_MATURITIES_FILTER](state) {
    console.log(
      "SET_UNANSWERED_MATURITIES_FILTER",
      state.listSettings,
      state.activeList
    );
    const unansweredFilter: Filter = {
      aggregationId: FILTER_MATURITY,
      value: null,
    };

    const setFilter = (list: string) => {
      if (state.listSettings[list]) {
        state.listSettings[state.activeList].filters = [unansweredFilter];
      } else {
        const listSettings = getEmptyListSetting();
        listSettings.filters = [unansweredFilter];
        Vue.set(state.listSettings, state.activeList, listSettings);
      }
    };

    setFilter(ROUTE_NAMES.AUDITEXECUTION);
    setFilter(ROUTE_NAMES.AUDITWRAPUP);
  },
  [n.CLEAR_FILTER_SETTINGS](state) {
    state.pageConfig = { ...state.pageConfig, page: 1 };
    if (state.listSettings[state.activeList]) {
      state.listSettings[state.activeList].filters = [];
    }
  },
  [n.REMOVE_FILTER_SETTING](state, payload: Filter) {
    if (state.listSettings[state.activeList]) {
      const filters = state.listSettings[state.activeList].filters.filter(
        filter => !isEqual(filter, payload)
      );
      console.log("REMOVE_FILTER_SETTING", filters);
      if (
        filters.length < state.listSettings[state.activeList].filters.length
      ) {
        state.pageConfig = { ...state.pageConfig, page: 1 };
        state.listSettings[state.activeList].filters = filters;
      }
    }
  },

  [n.SET_AUDIT_STATE_DIRTY](state, payload: boolean) {
    state.stateDirty = payload;
  },

  [n.SET_AUDIT_TRANSITION_DIRTY](state, payload: boolean) {
    state.transitionDirty = payload;
  },

  [n.SET_PAGING_CONFIG](
    state,
    payload: { page: number; pageSize: number | null }
  ) {
    state.pageConfig = payload;
  },
  [n.SET_ACTIVE_LIST](state, payload: string) {
    state.activeList = payload;
    if (payload === ROUTE_NAMES.AUDITPREPARATION) {
      const listSettings = getEmptyListSetting();
      Vue.set(state.listSettings, state.activeList, listSettings);
    }
  },
  [n.SET_ERROR_AUDIT_DATA_PERMISSION_DENIED](state) {
    state.missingAuditDataReadPermission = true;
  },
  [n.SET_SELECTED_AUDITITEM_IDS](state, payload: Array<AuditItemWithId["id"]>) {
    state.selectedAuditItemIds = payload;
  },
  [n.TOOGLE_SELECTED_AUDITITEM_ID](state, auditItemId: AuditItemWithId["id"]) {
    state.selectedAuditItemIds = xor(state.selectedAuditItemIds, [auditItemId]);
  },

  [n.UPDATE_LOADING_STATE](state, payload: AuditLoadingState) {
    console.log("AuditLoadingStateUpdate", payload);
    if (payload.auditId === state.auditId) {
      state.loadingState = omit(payload, "auditId");
    } else {
      console.warn(
        `Expect to update loadingState for audit "${state.auditId}" but got "${payload.auditId}"`
      );
    }
  },
  [n.SET_AUDIT_LOADING_ERROR](state, error: any) {
    state.loadingError = error;
  },
  [n.SET_BULK_UPDATING_STATE](state, isBulkUpdating) {
    state.auditIsBulkUpdating = isBulkUpdating;
  },
};

export { n as mutationNames, mutations };
