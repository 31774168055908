
























import { Component, Prop, Vue } from "vue-property-decorator";
import { api, Getters, Mutations } from "@/store/modules/asyncHandler";
import { Getter, Mutation } from "vuex-class";
import { cloneDeep } from "lodash";

@Component
export default class ANotificationToast extends Vue {
  interval: number | null = null;

  @Getter(api.getters.getMessages, {
    namespace: api.namespace,
  })
  messages!: Getters["getMessages"];

  @Mutation(api.mutations.REMOVE_MESSAGE, { namespace: api.namespace })
  removeMessage!: Mutations["REMOVE_MESSAGE"];

  @Mutation(api.mutations.CLEANUP_MESSAGES, { namespace: api.namespace })
  cleanupMessages!: Mutations["CLEANUP_MESSAGES"];

  mounted() {
    this.interval = window.setInterval(() => {
      this.cleanupMessages();
    }, 1000);
  }

  beforeDestroy() {
    if (this.interval) {
      window.clearInterval(this.interval);
      this.interval = null;
    }
  }

  get reversedMessages() {
    return this.messages ? cloneDeep(this.messages).reverse() : [];
  }
}
