import { AuditProgram } from "../types";
import { AuditMetaStatusChartBuilder } from "./AuditMetaStatusChartBuilder";

export class AuditProgramCardData {
  readonly currentYearOffset = 1;
  readonly amounts: [number, number, number] = [0, 0, 0];
  public readonly year: number;
  constructor(
    public readonly info: AuditProgram,

    private chartBuilder: AuditMetaStatusChartBuilder
  ) {
    this.year = new Date().getFullYear() - this.currentYearOffset;
  }
  get amountInCurrentYear(): number {
    return this.amounts[this.currentYearOffset];
  }
  get currentYear() {
    return this.year + this.currentYearOffset;
  }
  get chartData(): Chart.ChartData {
    return this.chartBuilder.chartData;
  }
  get yearStatistic(): { year: number; count: number; active: boolean }[] {
    return this.amounts.map((count, offset) => {
      const year = this.year + offset;
      return {
        year,
        count,
        active: offset === this.currentYearOffset,
      };
    });
  }

  updateCount(auditPlanningYear: number, auditWorkflowStatusId: string): void {
    const amountIdx = auditPlanningYear - this.year;
    if (0 <= amountIdx && amountIdx < this.amounts.length) {
      this.amounts[amountIdx] += 1;
    }

    if (auditPlanningYear === this.year + this.currentYearOffset) {
      this.chartBuilder.updateAmount(auditWorkflowStatusId);
    }
  }
}
