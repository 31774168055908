var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expand-transition',[(_vm.transition && _vm.currentMeasure)?_c('v-card',{staticClass:"transition"},[_c('div',{staticClass:"transition__connector"}),_c('v-expand-transition',[_c('div',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$ft(_vm.transition.description))+" "),_c('v-spacer'),_vm._t("right")],2),_c('v-card-text',[(_vm.warnings.length > 0)?_c('v-card',{staticClass:"mb-2",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t( "components.widgets.sidebar.audit_transition.section_title_warning" ))+" ")]),_c('v-card-text',_vm._l((_vm.warnings),function(warning,idx){return _c('AAuditTransitionWarning',{key:idx,attrs:{"warning-text":_vm.$ft(warning)}})}),1)],1):_vm._e(),_vm._l((_vm.components),function(ref,idx){
var component = ref.component;
var config = ref.config;
return _c('div',{key:idx},[_c(component,{tag:"component",attrs:{"measure":_vm.currentMeasure,"component-config":config,"transition":_vm.transition,"transition-data":_vm.transitionData,"transition-ui-state":_vm.uiStateByComponent[config.component],"prevent-ref-user-assignees":_vm.preventRefUserAssignees},on:{"transition-data-updated":function($event){return _vm.updateTransitionData(_vm.transition.id, $event)},"transition-ui-updated":function($event){return _vm.updateTransitionComponentUi(
                  _vm.transition.id,
                  config.component,
                  $event
                )}}}),(_vm.components.length !== idx + 1)?_c('v-divider',{staticClass:"my-8"}):_vm._e()],1)})],2),_c('v-card-actions',{staticClass:"a-measure-transition-form__actions"},[(_vm.currentStepMessages.length !== 0)?_c('div',[_c('v-alert',{staticClass:"mb-0",attrs:{"dense":"","text":"","type":"error"}},[(_vm.currentStepMessages.length === 1)?_c('div',[_vm._v(" "+_vm._s(_vm.$ft(_vm.currentStepMessages[0]))+" ")]):_c('ul',_vm._l((_vm.currentStepMessages),function(message,idx){return _c('li',{key:("message-" + idx)},[_vm._v(" "+_vm._s(_vm.$ft(message))+" ")])}),0)])],1):_vm._e(),_c('v-btn',{attrs:{"color":"accent","elevation":"0","disabled":_vm.isDirty,"loading":_vm.loading || _vm.loadingTransition},on:{"click":_vm.runActiveTransition}},[_vm._v(" "+_vm._s(_vm.$ft(_vm.transition.name))+" ")])],1)],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }