















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  name: "AAuditTypeSelect",
  components: {},
})
export default class AAuditTypeSelect extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  value!: string;

  get items() {
    return [
      {
        text: this.$t("schema_forms.common.process_audit").toString(),
        value: "Prozessaudit",
      },
      {
        text: this.$t("schema_forms.common.potential_analysis").toString(),
        value: "Potentialanalyse",
      },
      {
        text: this.$t("schema_forms.common.checklist_2").toString(),
        value: "Checkliste 2",
      },
      {
        text: this.$t("schema_forms.common.system_audit").toString(),
        value: "Systemaudit",
      },
      {
        text: this.$t("schema_forms.common.product_audit").toString(),
        value: "Produktaudit",
      },
    ];
  }

  onSelectionChanged(newVal: string) {
    this.$emit("input", newVal);
  }
}
