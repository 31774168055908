














































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { cloneDeep, isArray, isString } from "lodash";
import { api as auditClassesApi } from "@/store/modules/auditClasses";
import { AuditSummary, AuditMetadataDoc } from "@auditcloud/shared/lib/schemas";
import {
  AuditClass,
  MappedAuditClasses,
} from "@auditcloud/shared/lib/types/AuditClass";
import { Getter } from "vuex-class";

import {
  fixConflictingSummary,
  prepareForUi,
  trimSummary,
} from "@auditcloud/shared/lib/utils/audit/summary";

@Component({
  model: {
    prop: "value",
    event: "change",
  },
})
export default class AAuditSummary extends Vue {
  @Prop({
    type: [Object, String],
    default: () => null,
  })
  value!: AuditSummary | null;

  @Prop({
    type: Object,
    default: () => ({}),
  })
  auditMeta!: Partial<AuditMetadataDoc>;

  @Watch("$attrs.readonly")
  onReadonlyChanged(newVal: boolean, oldVal: boolean) {
    if (newVal === false && newVal !== oldVal) {
      this.setFocus("textInput");
    }
  }

  @Getter(auditClassesApi.getters.mappedAuditClasses, {
    namespace: auditClassesApi.namespace,
  })
  mappedAuditClasses!: MappedAuditClasses;

  get auditClass(): undefined | AuditClass {
    const auditClassId = this.auditMeta.audit_class ?? "";
    return this.mappedAuditClasses[auditClassId];
  }

  get conflictFreeSummary() {
    return cloneDeep(
      fixConflictingSummary(
        this.value ?? this.auditMeta.summary,
        this.auditClass?.summaryItemConfig
      )
    );
  }

  get normalizedConfig() {
    const conflictFreeSummary = this.conflictFreeSummary;
    return prepareForUi(conflictFreeSummary);
  }

  onChange(model: null | string, value: string) {
    const conflictFreeSummary = this.conflictFreeSummary;
    if (isString(conflictFreeSummary.summary) || model === null) {
      this.emitChange(value);
    } else {
      this.emitChange({
        ...conflictFreeSummary.summary,
        [model]: value,
      });
    }
  }

  emitChange(summary: AuditSummary) {
    this.$emit("change", trimSummary(summary));
  }

  /** Sets the focus to an element.
   * @param elementRef The reference to an element. It is defined as an attribute on an HTML element.
   *
   * Example: ```<v-textarea ref="textarea"></v-textarea>```
   */
  setFocus(elementRef: string) {
    this.$nextTick(() => {
      const element = this.$refs[elementRef] as HTMLElement | HTMLElement[];
      if (isArray(element)) {
        if (element.length > 0) {
          element[0].focus();
        }
      } else {
        element.focus();
      }
    });
  }
}
