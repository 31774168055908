import exceljs from "exceljs";
import {
  cloneDeep,
  isArray,
  isBoolean,
  isDate,
  isNumber,
  isPlainObject,
  isString,
  merge,
  pick,
  set,
  size,
  uniqBy,
  toPairs,
} from "lodash";

import { AuditProof, WebLink } from "@auditcloud/shared/lib/schemas";
import { QuestionRow, typeIsVdaQuestionScope } from "./types";
import { typeIsTranslateableText } from "@auditcloud/shared/lib/types/common";
import {
  ColumnNames,
  ColumnPrefixes,
} from "@auditcloud/shared/lib/utils/excel/types";

// matches a number or text without a space
const questionNoPattern = /^\s*(?:(\d+)|(\S+))\s*$/;

function assertValidSubPattern(val: string) {
  if (!(val.startsWith("(?:") && val.endsWith(")"))) {
    throw Error(
      `Expect that subPatterns are encapsulated in a non capturing group "(?: ... )" `
    );
  }
}

// matches "1"
// matches "1.1"
// matches "10.2"
// matches "1 a"
// matches "1a"
// matches "1 a)"
// matches "1a)"
// matches "CLD 12.3"
// matches "A.13.2"
const CHAPTER_ID_PATTERN =
  "(?:(?:(?:[A-Za-z]+[. ]?)?(?:\\d+))(?:\\.\\d+)*(?: ?[A-Za-z]\\)?)?)";

// matches "iso9001-2015"
// matches "eu-dsgvo"
// matches "edv2021"
const STANDARD_ID_PATTERN = "(?:[A-Za-z][A-Za-z0-9-_]*)";

const STANDARD_COLUMN_PATTERN = ((part: string) => {
  assertValidSubPattern(part);
  return `(?:${ColumnPrefixes.STANDARD}\\((${part})\\))`;
})(STANDARD_ID_PATTERN);

const STANDARD_COLUMN_REGEX = new RegExp(
  allowWhiteSpaceAround(STANDARD_COLUMN_PATTERN)
);

function separatedByColon(part1: string, part2: string) {
  assertValidSubPattern(part1);
  assertValidSubPattern(part2);
  return `(?:${part1}:${part2})`;
}
// matches STANDARD_ID_PATTERN folowed by colon and the CHAPTER_NO_PATTERN
const STANDARD_REF_PATTERN = separatedByColon(
  STANDARD_ID_PATTERN,
  CHAPTER_ID_PATTERN
);

function zeroOrMoreOf(part: string, separatedBy: string) {
  assertValidSubPattern(part);
  return `(?:(?:${part}(?:\\s*[${separatedBy}]\\s*${part})*)?)`;
}

function oneOrMoreOf(part: string, separatedBy: string) {
  assertValidSubPattern(part);
  return `(?:${part}(?:\\s*[${separatedBy}]\\s*${part})*)`;
}

function allowWhiteSpaceAround(part: string) {
  assertValidSubPattern(part);
  return `^\\s*${part}\\s*$`;
}

function noSpaceAround(part: string) {
  assertValidSubPattern(part);
  return `^${part}$`;
}

function splitterRegex(separatedBy: string) {
  return new RegExp(`(?:\\s*[${separatedBy}]\\s*)`);
}

const STANDARD_ID_REGEX = new RegExp(noSpaceAround(STANDARD_ID_PATTERN));

// matches a comma or semicolon separated list of CHAPTER_NO_PATTERN with whitespace between and around
// matches an empty string or only whitespace string
const CHAPTER_ID_LIST_REGEX = new RegExp(
  allowWhiteSpaceAround(zeroOrMoreOf(CHAPTER_ID_PATTERN, ",;"))
);
const CHAPTER_ID_LIST_SPLITTER_REGEX = splitterRegex(",;");

const STANDARD_REF_LIST_REGEX = new RegExp(
  allowWhiteSpaceAround(zeroOrMoreOf(STANDARD_REF_PATTERN, ",;"))
);
const STANDARD_LIST_SPLITTER_REGEX = splitterRegex(",;");

// matches 1
// matches 2.
// matches C2
// matches A1.
// matches 1.1
// matches 2.3.
// matches C2.3
// matches A1.4.
// matches A
const CATEGORY_ID_REGEX = /^\s*([A-Z]|(?:[A-Z]?\d+(?:\.\d+)*))\.?\s*$/;

interface ColumnHandler {
  columnPattern: RegExp;
  validator: (val: exceljs.Cell) => boolean;
  extractor: (
    fieldName: string,
    question: Partial<QuestionRow>,
    val: exceljs.Cell
  ) => Partial<QuestionRow>;
}

interface Builder extends ColumnHandler {
  columnName: string;
}

function typeIsHyperlink(val: any): val is exceljs.CellHyperlinkValue {
  return isPlainObject(val) && isString(val.hyperlink) && isString(val.text);
}

function TranslateableTextExtractor(
  fieldName: string,
  question: Partial<QuestionRow>,
  cell: exceljs.Cell
) {
  const tmp: Partial<QuestionRow> = pick(question, "sourceRow");

  const text = cell.text.trim();

  if (isString(text)) {
    set(tmp, fieldName, text);
  }
  return merge(question, tmp);
}

function notYetImplementedExtractor(
  fieldName: string,
  question: Partial<QuestionRow>,
  cell: exceljs.Cell
) {
  console.warn("not yet implemented ", fieldName);
  return { ...question };
}

export const lazyLinktextSplitter: (
  linkText: string
) => [string, string | undefined] = (() => {
  let REGEX: RegExp | null = null;

  /*
testsData = [
  ["https://a.de", ["https://a.de"]],
  ["http://b.de/dasda", ["http://b.de/dasda"]],
  ["Ccc: https://c.de/cCcc", ["Ccc", "https://c.de/cCcc"]],
  ["           Ddd: http://d.de", ["Ddd", "http://d.de"]],
  ["E:https://e.de/eee", ["E", "https://e.de/eee"]],
  ["          Fff:http://f.de/fff", ["Fff", "http://f.de/fff"]],
  ["Ggg   :https://gg.de/", ["Ggg", "https://gg.de/"]],
  ["           Hh  :http://h.de/hhh", ["Hh", "http://h.de/hhh"]],
  ["IIi   : https://i.de/ii", ["IIi", "https://i.de/ii"]],
  ["           JjJ  : http://j.de/j", ["JjJ", "http://j.de/j"]],
  [": https://k.de/kkkkk", ["", "https://k.de/kkkkk"]],
  ["           : http://l.de/l", ["", "http://l.de/l"]],
  ["     http://m.de    ", ["http://m.de"]]
]
testsData.forEach( ([text, [rf, rs]]) => {
    const [f, s] = text.trim().split(/(?:\s*:\s*(?=https?:\/\/))/);
    console.assert(rf === f && rs === s, `"expect ${text}" -> ["${rf}", "${rs}"] got ["${f}", "${s}"]`);
  }
)
*/

  return (linkText: string): [string, string | undefined] => {
    if (REGEX === null) {
      // compile Regex only if needed so that the iOS Safari can use the rest of the application
      REGEX = new RegExp("(?:s*:s*(?=https?://))"); // Safari on iOS can't handle Lookbehind and Lookahead
    }
    const [f, s] = linkText.split(REGEX);
    return [f, s];
  };
})();

const knownColumns: ColumnHandler[] = [
  {
    columnPattern: /^(no)$/,
    validator: cell => {
      return questionNoPattern.test(cell.text);
    },
    extractor: (fieldName, question, cell) => {
      if (typeof question.no !== "undefined") {
        throw new ExcelParseError(
          `no allready set`,
          cloneDeep(cell.fullAddress)
        );
      }
      const m = questionNoPattern.exec(cell.text);
      const numeric = m?.[1];
      const alphanumeric = m?.[2];
      if (!isString(numeric) && !isString(alphanumeric)) {
        throw new ExcelParseError(
          `no invalid no format`,
          cloneDeep(cell.fullAddress)
        );
      }

      const no = numeric ? parseInt(numeric, 10) : alphanumeric;

      return { ...question, no };
    },
  },
  {
    columnPattern: STANDARD_COLUMN_REGEX,
    validator: cell => {
      const val = cell.value;
      const text = cell.text;
      if (val === null) {
        return true;
      } else {
        return CHAPTER_ID_LIST_REGEX.test(text);
      }
    },
    extractor: (standardId, question, cell) => {
      if (STANDARD_ID_REGEX.test(standardId)) {
        const chapterRefs = cell.text
          .split(CHAPTER_ID_LIST_SPLITTER_REGEX)
          .filter(s => s.length)
          .map(chapterId => {
            return {
              standardId,
              chapterId,
            };
          });

        return {
          ...question,
          chapterRefs: uniqBy(
            [...chapterRefs, ...(question.chapterRefs ?? [])],
            ({ standardId, chapterId }) => `${standardId}:${chapterId}`
          ),
        };
      } else {
        console.error(
          `Found invalid standard chapter in cell`,
          cloneDeep(cell.fullAddress),
          standardId
        );
        throw new ExcelParseError(
          `Found invalid standard chapter in cell`,
          cloneDeep(cell.fullAddress)
        );
      }
    },
  },
  {
    columnPattern: new RegExp(`^(${ColumnNames.CHAPTER_REFS})$`),
    validator: cell => {
      const val = cell.value;
      if (val === null) {
        return true;
      } else if (isString(val)) {
        return STANDARD_REF_LIST_REGEX.test(val);
      } else {
        return false;
      }
    },
    extractor: (fieldName, question, cell) => {
      const chapterRefs = cell.text
        .split(STANDARD_LIST_SPLITTER_REGEX)
        .filter(s => s.length)
        .map(ref => {
          const [standardId, chapterId] = ref.split(":");
          return {
            standardId,
            chapterId,
          };
        });

      return {
        ...question,
        chapterRefs: uniqBy(
          [...chapterRefs, ...(question.chapterRefs ?? [])],
          ({ standardId, chapterId }) => `${standardId}:${chapterId}`
        ),
      };
    },
  },
  {
    columnPattern: new RegExp(`^(${ColumnNames.WEIGHT})$`),
    validator: cell => {
      return (
        (isNumber(cell.value) && isFinite(cell.value)) ||
        (isString(cell.value) && /^\d+$/.test(cell.value))
      );
    },
    extractor: (fieldName, question, cell) => {
      const weight =
        isString(cell.value) && /^\d+$/.test(cell.value)
          ? parseInt(cell.value, 10)
          : cell.value;
      if (weight === null || (isNumber(weight) && isFinite(weight))) {
        return { ...question, weight };
      } else {
        throw new ExcelParseError(
          `Found invalid weight in cell`,
          cloneDeep(cell.fullAddress)
        );
      }
    },
  },
  {
    columnPattern: new RegExp(`^(${ColumnNames.TYPE})$`),
    validator: cell => {
      return isString(cell.value) || cell.value === null;
    },
    extractor: (fieldName, question, cell) => {
      const auditItemType = cell.value;
      if (isString(auditItemType) || auditItemType === null) {
        return { ...question, auditItemType };
      } else {
        throw new ExcelParseError(
          `Found invalid auditItemType in cell`,
          cloneDeep(cell.fullAddress)
        );
      }
    },
  },
  {
    columnPattern: new RegExp(`^(${ColumnNames.SCOPE})$`),
    validator: cell => {
      return typeIsVdaQuestionScope(cell.value) || cell.value === null;
    },
    extractor: (fieldName, question, cell) => {
      const vdaScope = cell.value;
      if (typeIsVdaQuestionScope(vdaScope)) {
        return { ...question, vda_question_scope: vdaScope };
      } else if (vdaScope === null) {
        return question;
      } else {
        throw new ExcelParseError(
          `Found invalid vda question scope in cell`,
          cloneDeep(cell.fullAddress)
        );
      }
    },
  },

  {
    columnPattern: new RegExp(`^(${ColumnNames.CATEGORY_ID})$`),
    validator: cell => {
      return (
        (isNumber(cell.value) && isFinite(cell.value)) ||
        (isString(cell.value) && CATEGORY_ID_REGEX.test(cell.value))
      );
    },
    extractor: (fieldName, question, cell) => {
      const m = CATEGORY_ID_REGEX.exec(cell.text);

      const categoryId = m?.[1] ?? undefined;
      if (categoryId) {
        return { ...question, categoryId };
      } else {
        throw new ExcelParseError(
          `Found invalid categoryId in cell`,
          cloneDeep(cell.fullAddress)
        );
      }
    },
  },
  {
    // id of a subcategory -> this cell can be empty
    columnPattern: new RegExp(`^(${ColumnNames.SUBCATEGORY_ID})$`),
    validator: cell => {
      return (
        cell.value === "" ||
        cell.value === null ||
        (isNumber(cell.value) && isFinite(cell.value)) ||
        (isString(cell.value) && CATEGORY_ID_REGEX.test(cell.value))
      );
    },
    extractor: (fieldName, question, cell) => {
      const m = CATEGORY_ID_REGEX.exec(cell.text);

      const subCategoryId = m?.[1] ?? undefined;
      if (subCategoryId) {
        return { ...question, subCategoryId };
      } else {
        return question;
      }
    },
  },

  {
    columnPattern: new RegExp(
      //eslint-disable-next-line no-useless-escape
      `^(${ColumnNames.CATEGORY_DESCRIPTION}(?:\.[a-z]{2})?)$`
    ),
    validator: cell => {
      return isString(cell.text);
    },
    extractor: (fieldName, question, cell) =>
      TranslateableTextExtractor(
        fieldName.replace("category_description", "categoryDescription"),
        question,
        cell
      ),
  },
  {
    //eslint-disable-next-line no-useless-escape
    columnPattern: new RegExp(`^(${ColumnNames.TEXT}(?:\.[a-z]{2})?)$`),
    validator: cell => {
      return isString(cell.text);
    },
    extractor: TranslateableTextExtractor,
  },
  {
    columnPattern: new RegExp(
      //eslint-disable-next-line no-useless-escape
      `^(${ColumnNames.SELF_ASSESSMENT_TEXT}(?:\.[a-z]{2})?)$`
    ),
    validator: cell => {
      return isString(cell.text);
    },
    extractor: (fieldName, question, cell) =>
      TranslateableTextExtractor(
        fieldName.replace("selfassessment_text", "selfAssessmentText"),
        question,
        cell
      ),
  },
  {
    //eslint-disable-next-line no-useless-escape
    columnPattern: new RegExp(`^(${ColumnNames.HINT}(?:\.[a-z]{2})?)$`),
    validator: cell => {
      return isString(cell.text);
    },
    extractor: TranslateableTextExtractor,
  },
  {
    columnPattern: new RegExp(
      //eslint-disable-next-line no-useless-escape
      `^(${ColumnNames.REQUIRED_PROOFS}(?:\.[a-z]{2})?)$`
    ),
    validator: cell => {
      return isString(cell.text);
    },
    extractor: (fieldName, question, cell) => {
      const proofs: AuditProof[] = [];
      if (isString(cell.value) && cell.value.trim().length > 0) {
        const proofTexts = cell.value.trim().split("\n");

        proofTexts.forEach(proofTextDirty => {
          const proofText = proofTextDirty.trim();
          if (proofText === "") {
            console.warn(
              `Found empty proof entry in cell ${cell.fullAddress.address}`,
              proofTexts,
              proofText
            );
          } else {
            proofs.push({ name: proofText, type: null });
          }
        });
      }
      return {
        ...question,
        requiredProofs: [...(question.requiredProofs ?? []), ...proofs],
      };
    },
  },
  {
    columnPattern: new RegExp(`^(${ColumnNames.LINKS})$`),
    validator: cell => {
      return (
        cell.value === null ||
        typeIsHyperlink(cell.value) ||
        isString(cell.value)
      );
    },
    extractor: (fieldName, question, cell) => {
      const links: WebLink[] = [];
      if (isString(cell.value) && cell.value.trim().length > 0) {
        const linktexts = cell.value.trim().split("\n");

        linktexts.forEach(linktextDirty => {
          const linktext = linktextDirty.trim();
          if (linktext === "") {
            console.warn(
              `Found empty Link entry in cell ${cell.fullAddress.address}`,
              linktexts,
              linktext
            );
          } else {
            const [first, second] = lazyLinktextSplitter(linktext);

            if (second && second.startsWith("http")) {
              links.push({
                url: second,
                label: first.trim() === "" ? undefined : first.trim(),
              });
            } else if (first.trim().startsWith("http")) {
              links.push({
                url: first,
              });
            } else {
              console.warn(
                `Expect Link text but found no http in ${cell.fullAddress.address}`
              );
            }
          }
        });
      } else if (typeIsHyperlink(cell.value)) {
        /* console.assert(
          "https://rauwiki.rehau.org/HSE/Processes/VorfaelleBerichteUndUntersuchungenCOMETIncidentReportingAndInvestigationCOMET" !=
            cell.value.hyperlink,
          "Cell Found Link",
          cell.fullAddress.address,
          cell.type,
          cell.hyperlink,
          cell.isHyperlink,
          cell.value,
          cell
        );*/
        links.push({
          url: cell.value.hyperlink,
          label: cell.value.text,
        });
      }
      return { ...question, links: [...(question.links ?? []), ...links] };
    },
  },
  {
    columnPattern: new RegExp(`^(${ColumnNames.LABELS})$`),
    validator: cell => {
      return cell.value === null || isString(cell.value);
    },
    extractor: (fieldName, question, cell) => {
      const labels: string[] = [];
      if (isString(cell.value)) {
        const dirtylabels = cell.value.trim().split(/\s*,\s*/);
        labels.push(
          ...dirtylabels
            .filter(t => t.length > 0)
            // no duplicate labels
            .filter(t => !question.labels?.includes(t))
        );
      }
      return { ...question, labels: [...(question.labels ?? []), ...labels] };
    },
  },
  {
    columnPattern: new RegExp(
      //eslint-disable-next-line no-useless-escape
      `^(${ColumnNames.SUBCATEGORY_ID}((?:\.[a-zA-Z][a-zA-Z0-9_]*))+)$`
    ),
    validator: cell => {
      return (
        cell.value === null ||
        isString(cell.value) ||
        isNumber(cell.value) ||
        isBoolean(cell.value) ||
        isDate(cell.value) ||
        "richText" in cell.value
      );
    },
    extractor: (fieldName, question, cell) => {
      const tmp: Partial<QuestionRow> = pick(question, "sourceRow");
      const val = cell.value;
      if (isString(val) || isNumber(val) || isBoolean(val)) {
        set(tmp, fieldName, val);
      } else if (isDate(val)) {
        set(tmp, fieldName, val.toISOString());
      } else if (val && "richText" in val) {
        const text = val.richText
          .map(v => v.text)
          .join(" ")
          .trim();
        set(tmp, fieldName, text);
      }

      return merge(question, tmp);
    },
  },
];

export class ExcelParseError extends Error {
  constructor(
    message: string,
    public readonly fullAddress: exceljs.Cell["fullAddress"]
  ) {
    super(message);
  }
  toString(): string {
    return `ExcelParseError in ${this.fullAddress.sheetName}!${this.fullAddress.address}: ${this.message}`;
  }
}

export async function parseQuestionsFormXlsx(
  file: Blob,
  allowDuplicateQuestionNumbers: boolean = false
): Promise<{
  rows: QuestionRow[];
}> {
  const workbook = new exceljs.Workbook();
  await workbook.xlsx.load(await file.arrayBuffer());

  if (workbook.worksheets.length !== 1) {
    throw new Error(
      `Expect only one worksheet found ${workbook.worksheets.length}`
    );
  }

  const sheet = workbook.worksheets[0];
  console.log(
    "workbook.worksheets.length",
    workbook.worksheets.length,
    sheet,
    workbook.worksheets
  );

  console.log("Read Excel File", workbook.worksheets.length, sheet.columnCount);
  if (sheet.columnCount < 1) {
    throw new Error(`Expect at least one column found ${sheet.columnCount}`);
  }

  const handler: { colIdx: number; builder: Builder }[] = [];

  const questions: Partial<QuestionRow>[] = [];
  sheet.eachRow((row, rowIdx) => {
    if (rowIdx === 1) {
      // Read 1. row

      row.eachCell((cell, colIdx) => {
        const value = cell.value;
        if (isString(value)) {
          const f = knownColumns.find(v => v.columnPattern.test(value));

          if (f) {
            const m = f.columnPattern.exec(value);
            if (!m || !isString(m[1])) {
              throw Error("Exec Failed");
            }
            const columnName = m[1];

            handler.push({ builder: { ...f, columnName }, colIdx });
          } else {
            console.warn(
              `Found unsupportet Column ${cell.fullAddress.sheetName}!${cell.fullAddress.address} with Name ${value}`
            );
          }
        } else {
          throw new ExcelParseError(
            `Found unexpected data in HeaderCell`,
            cloneDeep(cell.fullAddress)
          );
        }
      });
    } else {
      let questionRow: Partial<QuestionRow> = { sourceRow: rowIdx };
      handler.forEach(({ colIdx, builder }) => {
        const cell = row.getCell(colIdx);
        // console.log(colIdx, cell.value);
        if (!builder.validator(cell)) {
          console.warn(cell.value);
          throw new ExcelParseError(
            `Found unexpected value in data cell: ${cell}`,
            cloneDeep(cell.fullAddress)
          );
        }
        questionRow = builder.extractor(builder.columnName, questionRow, cell);
      });
      if (size(questionRow) > 0) {
        questions.push(questionRow);
      }
    }
  });
  const notify = (msg: string) => {
    throw new Error(msg);
  };

  const cleanQuestions: QuestionRow[] = questions.map((v, idx) => {
    function setDefault<T>(v: T, msg: string): T {
      return v;
    }
    const sr = v.sourceRow ?? "?";

    // chapterDescription
    const {
      sourceRow = notify(`Missing sourceRow`),
      no = notify(`Missing column no in row ${sr}`),
      weight = setDefault(null, `Use Default for weight`),
      auditItemType = setDefault(null, `Use Default for auditItemType`),
      categoryId = notify(`Missing column category_id in row ${sr}`),
      subCategoryId = null,
      categoryWeight = setDefault(1, `Use Default for category weight`),

      chapterRefs = [],
      chapterDescription = null,
      text = notify(`Missing column text in row ${sr}`),
      selfAssessmentText = null,
      hint = "",
      links = [],
      requiredProofs = [],
      labels = [],
      customData,
      vda_question_scope,
    } = v;

    toPairs({
      text,
      hint,
      selfAssessmentText: selfAssessmentText ?? "",
    }).forEach(([field, value]) => {
      if (!typeIsTranslateableText(value)) {
        notify(
          `expect that "${field}" is an valid translatable string in row ${sr}`
        );
      }
    });

    const res: QuestionRow = {
      sourceRow,
      no,
      weight,
      auditItemType,
      categoryId,
      subCategoryId,
      categoryWeight,

      chapterRefs,
      chapterDescription,
      text,
      selfAssessmentText,
      hint,
      requiredProofs,
      links,
      labels,
      customData,
      chapterIdsForStandard(standardId: string) {
        return chapterRefs
          .filter(chapterRef => chapterRef.standardId === standardId)
          .map(({ chapterId }) => chapterId);
      },
    };
    if (vda_question_scope) {
      res.vda_question_scope = vda_question_scope;
    }
    return res;
  });

  console.log(`extracted ${cleanQuestions.length} questions`);

  let questionNoRows = cleanQuestions.map(v => ({
    no: v.no,
    sourceRow: v.sourceRow,
  }));

  if (!typeOfNoIsNumber(questionNoRows)) {
    questionNoRows = questionNoRows.map(v => ({
      no: String(v.no),
      sourceRow: v.sourceRow,
    }));
  } else {
    const errornousQuestionNumbers: {
      no: number;
      sourceRow: number;
      expectedNo: number;
    }[] = [];

    // sort by number and check if numbers are an ascendingt list 1, 2, 3, ...
    questionNoRows.sort((a, b) => {
      return a.no - b.no;
    });

    questionNoRows.forEach((questionNoRow, index) => {
      const expectedVal = index + 1;
      if (questionNoRow.no !== expectedVal) {
        errornousQuestionNumbers.push({
          no: questionNoRow.no,
          sourceRow: questionNoRow.sourceRow,
          expectedNo: expectedVal,
        });
      }
    });
    if (errornousQuestionNumbers.length > 0) {
      notify(
        `Invalid question numbers: ` +
          errornousQuestionNumbers
            .map(
              v =>
                `Question in line ${v.sourceRow}, expected number: ${v.expectedNo}, got number: ${v.no}`
            )
            .join(",")
      );
    }
  }

  if (!allowDuplicateQuestionNumbers) {
    const uniqQuestionNoRows = uniqBy(questionNoRows, "no");
    if (uniqQuestionNoRows.length !== questionNoRows.length) {
      const nonUniqueQuestionNos = questionNoRows.filter(
        v => !uniqQuestionNoRows.includes(v)
      );
      notify(
        `Values in the field no are not unique: ` +
          nonUniqueQuestionNos
            .map(
              v =>
                `Line ${v.sourceRow}: A question with number ${v.no} does already exist`
            )
            .join(", ")
      );
    }
  }

  const normalizedCleanQuestions = !typeOfNoIsNumber(questionNoRows)
    ? cleanQuestions.map(v => {
        return {
          ...v,
          no: String(v.no),
        };
      })
    : cleanQuestions;

  console.log(normalizedCleanQuestions);

  return {
    rows: normalizedCleanQuestions,
  };
}

function typeOfNoIsNumber(
  val: any
): val is { no: number; sourceRow: number }[] {
  return isArray(val) && val.every(v => v.no && isNumber(v.no));
}
