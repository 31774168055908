




































































import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { State, Action, Getter, Mutation } from "vuex-class";

import VJsoneditor from "v-jsoneditor";
import { cloneDeep, Dictionary, isEqual } from "lodash";

import { api } from "@/store/modules/autocompletes";
import { CustomMetadataType } from "@/store/modules/autocompletes/types";

import { api as schemasApi } from "@/store/modules/schemas";
import { api as appApi } from "@/store/modules/app";
import { TodoAny } from "@auditcloud/shared/lib/utils/type-guards";
import { ROUTE_NAMES } from "../../routenames";
import {
  CustomMetadataList,
  CustomMetadataNested,
} from "@auditcloud/shared/lib/types/CustomMetadata";

function emptyDataSet(type: "nested" | "list" = "list") {
  return {
    type,
    items: [],
  };
}

const SCHEMA_LIST = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "https://schemas.next-audit.de/CustomMetadata_List.schema.json",
  title: "The CustomMetadata List Schema",

  type: "array",
  items: {
    $ref: "https://schemas.next-audit.de/CustomMetadataEntry.schema.json",
  },
  minItems: 1,
};

const SCHEMA_NESTED = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "https://schemas.next-audit.de/CustomMetadata_Nested.schema.json",
  title: "The CustomMetadata Nested Schema",

  type: "array",
  items: {
    $ref: "https://schemas.next-audit.de/CustomMetadataTreeEntry.schema.json",
  },
  minItems: 1,
};
const SCHEMA = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "https://schemas.next-audit.de/CustomMetadata.schema.json",
  title: "The CustomMetadata Schema",
  type: "object",
  oneOf: [
    {
      type: "object",
      required: ["type", "items"],
      properties: {
        type: { const: "list" },
        items: {
          type: "array",
          items: {
            $ref: "https://schemas.next-audit.de/CustomMetadataEntry.schema.json",
          },
          minItems: 1,
        },
      },
      additionalProperties: false,
    },
    {
      type: "object",
      required: ["type", "items", "levelNames"],
      properties: {
        type: { const: "nested" },
        levelNames: {
          oneOf: [
            { type: "null" },
            {
              type: "array",
              items: {
                oneOf: [
                  { type: "string" },
                  {
                    $ref: "https://schemas.next-audit.de/I18nKey.schema.json",
                  },
                  {
                    $ref: "https://schemas.next-audit.de/I18nInline.schema.json",
                  },
                ],
              },
            },
          ],
        },
        items: {
          type: "array",
          items: {
            $ref: "https://schemas.next-audit.de/CustomMetadataTreeEntry.schema.json",
          },
          minItems: 1,
        },
      },
      additionalProperties: false,
    },
  ],
};

@Component({
  components: { VJsoneditor },
})
export default class ACustomMetadataEditor extends Vue {
  editedData: any = emptyDataSet();
  loading: boolean = false;
  valid: boolean = true;
  createMode: boolean = false;
  newMetadataSetId: string = "";
  metadataSetName = "";

  readonly metadataTypeList = [
    { id: "list", name: "List" },
    { id: "nested", name: "Tree" },
  ];

  get metadataSetType(): "list" | "nested" {
    return this.editedData?.type ?? "list";
  }

  set metadataSetType(type: "list" | "nested") {
    if (this.editedData instanceof Object) {
      this.$set(this.editedData, "type", type);
    } else if (Array.isArray(this.editedData)) {
      this.editedData = {
        type,
        data: this.editedData,
      };
    } else {
      this.editedData = emptyDataSet(type);
    }
  }

  get options() {
    console.log("Editor:options");
    return {
      templates: [
        {
          text: "AutoId",
          title: "Insert a AutoId",
          className: "jsoneditor-type-object",
          field: "id",
          value: { "generate-autoId": true },
        },
        {
          text: "List Entry",
          title: "Insert a Entry",
          value: {
            id: "",
            name: "",
            disabled: false,
          },
        },
        {
          text: "Tree Entry",
          title: "Insert a Tree",
          value: {
            id: "",
            name: "",
            disabled: false,
            children: [],
          },
        },
      ],
      schema: SCHEMA,
      schemaRefs: this.getVfjsCommonSchemas,
      mode: "tree",
      modes: ["tree", "form", "code", "text"],
    };
  }

  @Prop({ type: String, required: true })
  segmentName!: string;

  @Prop({ type: String, default: null })
  metadataSetId!: string | null;

  @Getter(api.getters.getAutoCompleteList, { namespace: api.namespace })
  metadataList!: Array<{ id: string; name: string }>;

  @Getter(api.getters.getMappedAutocompletes, { namespace: api.namespace })
  mappedAutocompletes!: Dictionary<CustomMetadataType>;

  @Getter(schemasApi.getters.getVfjsCommonSchemas, {
    namespace: schemasApi.namespace,
  })
  getVfjsCommonSchemas!: TodoAny;

  @Watch("metadataSetId", {
    immediate: true,
  })
  onMetadataSetIdChange(val: string | null, oldVal: string | null) {
    console.log("onMetadataSetIdChange", val, oldVal, this.metadataList);
    this.createMode = false;
    this.loading = false;
    this.valid = true;
    this.editedData = this.storedData;
    this.metadataSetName = this.document?.name ?? "";
  }

  get document() {
    if (
      this.metadataSetId !== null &&
      this.metadataSetId in this.mappedAutocompletes
    ) {
      return this.mappedAutocompletes[this.metadataSetId];
    } else {
      return null;
    }
  }

  get storedData() {
    const document = this.document;
    return document ? cloneDeep(document.data) : null;
  }

  get isDirty() {
    return !isEqual(this.storedData, this.editedData);
  }

  get getterConfig() {
    const data = {
      namespace: "autocompletes",
      name: "getAutocompleteData",
      id: this.metadataSetId,
    };
    return JSON.stringify(data, undefined, 2);
  }

  @Action(api.actions.updateAutoCompleteData, { namespace: api.namespace })
  updateAutoCompleteData!: (payload: {
    id: string;
    name: string;
    data: CustomMetadataList | CustomMetadataNested;
  }) => Promise<void>;

  @Action(api.actions.createAutoCompleteData, { namespace: api.namespace })
  createAutoCompleteData!: (payload: {
    name: string;
    data: CustomMetadataList | CustomMetadataNested;
  }) => Promise<string>;

  @Action(appApi.actions.setStatus, { namespace: appApi.namespace })
  setStatus!: (payload: string) => Promise<any>;

  async storeCustomData() {
    console.log(
      "storeCustomData",
      this.metadataSetId,
      this.editedData,
      this.valid,
      this.isDirty
    );
    if (
      this.valid &&
      this.isDirty &&
      this.editedData !== null &&
      (this.metadataSetId !== null || this.createMode)
    ) {
      this.loading = true;
      await this.setStatus("loading");
      try {
        console.log("Start Save");
        if (this.createMode) {
          const payload = {
            name: this.metadataSetName,
            data: this.editedData,
          };
          const newMetadataSetId = await this.createAutoCompleteData(payload);
          this.onMetadataSetSelected(newMetadataSetId);
        } else if (
          this.metadataSetId !== null &&
          this.document?.data.type === this.metadataSetType
        ) {
          const payload = {
            id: this.metadataSetId,
            name: this.metadataSetName,
            data: this.editedData,
          };
          console.log("Importing", payload);
          await this.updateAutoCompleteData(payload);
        }

        await this.setStatus("success");
      } catch (error) {
        console.log("Json Parse failed:", error);
        await this.setStatus("error");
      }
      this.loading = false;
    }
  }
  onError(err: TodoAny) {
    console.error("ACustomMetadataEditor:onError", err);
    this.valid = false;
  }
  onInput(data: null | CustomMetadataType) {
    this.valid = true;
    this.editedData = data;
  }

  onMetadataSetSelected(metadataSetId: string) {
    console.log("Goto", metadataSetId);
    this.$router.push({
      name: ROUTE_NAMES.CONFIGURATION_METADATA,
      params: {
        segmentName: this.segmentName,
        metadataSetId,
      },
    });
  }
  setCreateMode() {
    this.createMode = true;
    this.options.mode = "tree";
    this.metadataSetName = "";
    this.editedData = emptyDataSet();
  }
}
