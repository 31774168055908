








































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { AuditClass } from "@auditcloud/shared/lib/types/AuditClass";
import {
  TodoAny,
  TodoActionSignature,
} from "@auditcloud/shared/lib/utils/type-guards";

import { api } from "@/store/modules/audit";

import { cloneDeep } from "lodash";

import { api as schemasApi, GetSchemaForType } from "@/store/modules/schemas";
import { api as auditClassesApi } from "@/store/modules/auditClasses";

import VueFormJsonSchema from "vue-form-json-schema";
import { ROUTE_NAMES, stripDialog, isDialogRoute } from "@/routenames";
import { calcParentRoute } from "@/utils/routerUtils";
import { CompositionWizardResultType } from "../types";
import { Dictionary } from "vue-router/types/router";
import DialogLayout from "../layouts/BaseLayouts/DialogLayout.vue";
import { getActivityCreator } from "@/utils/activityCreator";
import { AuditStatusId } from "@auditcloud/shared/lib/constants";
import { AuditMetadataDoc, UserRefLazy } from "@auditcloud/shared/lib/schemas";
import { calcPreselectionFilterBasedOnTheAuditMetadata } from "@/store/modules/audit/utils";
import { calculateAuditAccessList } from "@auditcloud/shared/lib/utils/aclDocumentUsers";

@Component({
  name: "AuditCreateDialog",
  components: {
    VueFormJsonSchema,
    DialogLayout,
  },
})
export default class AuditCreateDialog extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  value!: boolean;
  loading = false;
  createError = false;

  state: string = "preSelection";
  preselection: CompositionWizardResultType = null;

  formSchemaId: string = "metadata-form";
  formValid: boolean = false;
  editedItem: any = {};
  dialog: boolean = true;
  vfjsOptions: any = {};

  @Getter(schemasApi.getters.getVfjsOptions, {
    namespace: schemasApi.namespace,
  })
  getVfjsOptions!: TodoAny;

  @Getter(schemasApi.getters.getSchemaFor, {
    namespace: schemasApi.namespace,
  })
  getSchemaFor!: GetSchemaForType;

  @Getter(auditClassesApi.getters.mappedAuditClasses, {
    namespace: auditClassesApi.namespace,
  })
  auditClasses!: Dictionary<AuditClass>;

  @Getter(schemasApi.getters.getVfjsCommonSchemas, {
    namespace: schemasApi.namespace,
  })
  getVfjsCommonSchemas!: TodoAny;

  get fullscreen() {
    return this.state === "createForm";
  }
  get maxWidth() {
    return this.state === "createForm" ? undefined : "500px";
  }
  get isPreSelection() {
    return this.state === "preSelection";
  }
  get adminview() {
    if (this.$route.query.admin) {
      return true;
    } else {
      return false;
    }
  }

  get activePreselect() {
    return this.preselection ?? {};
  }

  get auditClass() {
    return this.preselection?.auditClassId ?? "";
  }
  get formSchema() {
    console.log("AuditSchema,", this.auditClass);
    const res = this.getSchemaFor("audit", this.formSchemaId, this.auditClass);
    console.log("AuditMedataControl:uiSchema", res);
    return res;
  }
  get uiSchema() {
    return this.formSchema.ui(false, false);
  }
  get schema() {
    return this.formSchema.data;
  }
  get parentRoute() {
    return calcParentRoute(this.$route);
  }

  get auditClassConfig(): AuditClass | null {
    if (!this.auditClass) {
      return null;
    }
    return this.auditClasses[this.auditClass];
  }

  created() {
    console.log("AuditCreateDialog:created", this.dialog);
  }
  mounted() {
    console.log("AuditCreateDialog:mounted", this.dialog);
  }
  beforeDestroy() {
    console.log("AuditCreateDialog:beforeDestroy", this.dialog);
  }

  @Action(api.actions.createAudit, { namespace: api.namespace })
  createAudit!: TodoActionSignature;

  onValidated(value) {
    console.log("onValidated", value);
    this.formValid = value;
  }
  input(value) {
    console.log("input", value);
  }
  change(value) {
    this.editedItem = value;
    console.log("change", value);
  }
  onChangeState(value) {
    console.log("state changed", value);
  }
  showError(err) {
    console.error("AuditMetadataDialog", err);
  }
  navBack() {
    this.$router.back();
  }

  navToParent() {
    if (isDialogRoute(this.$route.name)) {
      this.$router.push({
        name: stripDialog(this.$route.name ?? ""),
        params: this.$route.params,
      });
    } else if (this.parentRoute !== null) {
      this.$router.push(this.parentRoute);
    } else {
      this.navBack();
    }
  }
  closeDialog(redirectAuditId: string | undefined) {
    setTimeout(() => {
      this.dialog = false;
      if (typeof redirectAuditId === "string") {
        this.$router.push({
          name: ROUTE_NAMES.AUDIT,
          params: {
            auditId: redirectAuditId,
          },
        });
      } else {
        this.navToParent();
      }
    }, 1000);
  }
  save() {
    if (this.formValid) {
      this.loading = true;
      this.createError = false;

      const activityCreator = getActivityCreator(
        this.$user,
        this.auditClassConfig,
        {
          ...this.editedItem,
        }
      );

      this.editedItem.responsible = this.editedItem.responsible || null;

      this.createAudit({
        data: { ...this.editedItem },
        activity: activityCreator,
      })
        .then(docRef => {
          console.log("CREATE_AUDIT_SUCCEEDED", docRef);
          this.closeDialog(docRef.id);
        })
        .catch(err => {
          console.error(err);
          this.createError = true;
          this.loading = false;
        });
    } else {
      this.vfjsOptions = {
        ...this.getVfjsOptions,
        showValidationErrors: true,
      };

      console.log("Form invalid");
    }
  }

  setPreSelect() {
    const preselection = this.activePreselect;
    const audit_class = preselection.auditClassId ?? null;
    if (preselection && audit_class) {
      let {
        standards = [],
        standardRefs = [],
        ...presetData
      } = this.activePreselect;
      if (standards.length > 0 && standardRefs.length === 0) {
        standardRefs = standards;
      }

      const nameTemplate =
        this.auditClasses[audit_class].auditNameTemplate ?? "";
      const name = nameTemplate.length === 0 ? "" : null;
      console.log("setPreSelect", presetData, name, nameTemplate);
      const auditClassConfig = this.auditClasses[audit_class];
      const isMultidimensionAuditWithStandards =
        auditClassConfig.dimensionSource === "standard";

      const leadauditor = this.$user.ref() as unknown as UserRefLazy;
      const preSelectedData: AuditMetadataDoc = {
        ...cloneDeep(presetData as AuditMetadataDoc),
        remote: {
          selfAssessment: null,
          externalMeasures: null,
        },
        audit_class,
        nameTemplate,
        name,
        standardRefs,
        leadauditor,
        leadauditorId: leadauditor.id,
        planning_year: new Date().getFullYear(),
        auditPreparation: {
          step: "preselection",
          templateIds: [],
          activePreselectionFilters: [],
          manuallyConsideredAuditItemIds: {},
        },
        selfAssessmentAnsweredAuditItemIds: [],
        selfAssessmentEnabledAuditItemIds: [],
        totalConsideredAuditItemIds: [],
        reportSchema: this.auditClasses[audit_class].reportSchema,
        accessListMetadataRead: [],
        accessListPrivateRead: [],

        workflow: { status: AuditStatusId.Planning },
      };

      // Initialize dependent fields
      preSelectedData.auditPreparation.activePreselectionFilters =
        calcPreselectionFilterBasedOnTheAuditMetadata(
          preSelectedData,
          isMultidimensionAuditWithStandards
        );
      preSelectedData.accessListMetadataRead = calculateAuditAccessList(
        preSelectedData,
        auditClassConfig.accessAuditMetadataRead
      );
      preSelectedData.accessListPrivateRead = calculateAuditAccessList(
        preSelectedData,
        auditClassConfig.accessAuditPrivateRead
      );

      console.log("AuditCreateDialog", preSelectedData);

      this.editedItem = preSelectedData;
      this.vfjsOptions = this.getVfjsOptions;

      this.state = "createForm";
    }
  }
}
