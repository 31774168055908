import { WatchableCollection } from "@/utils/firestore";
import { RootState } from "@/store/types";
import { Result } from "neverthrow";
import { CollectionNames } from "@auditcloud/shared/lib/types/common";
import { SchemaBasedInterfaces, UserDoc } from "@auditcloud/shared/lib/schemas";

type VERSION = "v0";

export type ApiUserParams = {
  where: SchemaBasedInterfaces["api"][VERSION]["types"]["ParamWhere"];
  orderBy: SchemaBasedInterfaces["api"][VERSION]["types"]["ParamOrderBy"];
  pagination: SchemaBasedInterfaces["api"][VERSION]["types"]["ParamPagination"];
};
export type ApiFilterItem = ApiUserParams["where"][0];
export type ApiOrderByItem = ApiUserParams["orderBy"][0];
export type ApiFindResponse =
  SchemaBasedInterfaces["api"][VERSION]["user"]["Find"]["Response"];
export type ApiListResponse =
  SchemaBasedInterfaces["api"][VERSION]["user"]["List"]["Response"];
export type ApiError = SchemaBasedInterfaces["api"][VERSION]["types"]["Error"];
export type ApiUser = ApiFindResponse;

export type ApiUpdateUserRequest =
  SchemaBasedInterfaces["api"][VERSION]["user"]["Update"]["Request"];

export class UserGroupsState {}

export class UserManagementState extends WatchableCollection<
  UserDoc,
  RootState
> {
  loading: boolean = false;
  user: ApiUser | null = null;
  users: ApiUser[] = [];
  nextFrom: string | null = null;
  error: ApiError | null = null;
  params: ApiUserParams = {
    orderBy: [],
    where: [],
    pagination: { limit: 50, from: null },
  };
  constructor() {
    super(CollectionNames.USERS, "USERS");
  }
}

export type UpdateUserPayload = {
  id: string;
} & ApiUpdateUserRequest;

export type UpdateUserResult = Result<
  { created: boolean },
  { code: string; payload: object }
>;
