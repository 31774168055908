import {
  CLOUD_REGION,
  EMULATOR_FUNCTIONS_API_HTTP,
} from "@auditcloud/shared/lib/constants";
import { getApp } from "firebase/app";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";

export async function testCall() {
  console.log("testCall:start");
  const app = getApp();
  const functions = getFunctions(app, CLOUD_REGION);
  connectFunctionsEmulator(
    functions,
    EMULATOR_FUNCTIONS_API_HTTP.host,
    EMULATOR_FUNCTIONS_API_HTTP.port
  );

  const httpFunction = httpsCallable(functions, "testCall", { timeout: 10000 });
  console.log("testCall:function", httpFunction);
  return await httpFunction({ de: "dasd" });
}

window.testCall = testCall;
