export function createTypeError(msg: string, ...context: any) {
  console.error(msg, ...context);
  return new TypeError(msg);
}

export function createError(msg: string, ...context: any) {
  console.error(msg, ...context);
  return new Error(msg);
}

export function errorIfFalse(
  expr: boolean,
  msg: string,
  errType?: ErrorConstructor
) {
  errType = errType || Error;
  if (!expr) {
    throw new errType(msg);
  }
}
