import firebase from "firebase/compat/app";
import {
  pathFromStoragePath,
  pathToStoragePath,
} from "@auditcloud/shared/lib/utils/storage";
import { fileManagementEndpointUrl } from "../HttpApi";
import axios from "axios";
import {
  ApiV0FileManagementDownloadRequest as Request,
  ApiV0FileManagementDownloadResponse as Response,
} from "@auditcloud/shared/lib/schemas";
import { err, ok, Result } from "neverthrow";

/**
 * Converts the specified storage ref to a storage path (prefixed with "storage://")
 */
export function toStoragePath(ref: firebase.storage.Reference) {
  return pathToStoragePath(ref.fullPath);
}

/**
 * Versucht aus dem String eine Download Url zu generieren.
 *
 * @param val String welcher mit https?://|gs://|storage:// startet oder ein relativer pfad
 */
export function parseDownloadRef(val: string): firebase.storage.Reference {
  if (val.match(/^https?:\/\//)) {
    return firebase.storage().refFromURL(val);
  } else if (val.startsWith("gs://")) {
    return firebase.storage().refFromURL(val);
  } else {
    const path = pathFromStoragePath(val);
    return firebase.storage().ref(path);
  }
}

/**
 * Versucht aus dem String eine Download Url zu generieren.
 *
 * @param val String welcher mit https?://|gs://|storage:// startet oder ein relativer pfad
 * @throws Wenn die Url nicht auflösbar ist
 */
export async function parseDownloadUrl(val: string): Promise<string> {
  if (val.match(/^https?:\/\//)) {
    try {
      return await parseDownloadRef(val).getDownloadURL();
    } catch (err) {
      if (
        err &&
        err.name === "FirebaseError" &&
        err.code === "storage/invalid-argument"
      ) {
        return val;
      } else {
        throw err;
      }
    }
  } else {
    return await parseDownloadRef(val).getDownloadURL();
  }
}

async function downloadBlob(httpsUrl: string): Promise<Blob> {
  const response = await fetch(httpsUrl);
  return response.blob();
}

function startBrowserUrlDownload(blobUrl: string, filename: string) {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.href = blobUrl;
  a.download = filename;
  a.click();
  document.body.removeChild(a);
}

export function startBrowserBlobDownload(blob: File): void;
export function startBrowserBlobDownload(blob: Blob, filename: string): void;
export function startBrowserBlobDownload(
  blob: Blob | File,
  filename?: string
): void {
  const downloadFilename =
    (blob instanceof File ? blob.name : filename) ?? "download";
  const blobUrl = URL.createObjectURL(blob);
  startBrowserUrlDownload(blobUrl, downloadFilename);
  URL.revokeObjectURL(blobUrl);
}

export function isStorageUrl(url: string): boolean {
  return url.match(/^(store?age|gs):\/\//) !== null;
}

export function isHttpSUrl(url: string): boolean {
  return url.match(/^https?:\/\//) !== null;
}

export async function triggerDownload(url: string, filename: string) {
  if (isStorageUrl(url)) {
    throw new Error("storage url not allowed");
  }

  const blob = isHttpSUrl(url) ? await downloadBlob(url) : null;
  if (blob) {
    startBrowserBlobDownload(blob, filename);
  } else {
    startBrowserUrlDownload(url, filename);
  }
}

export async function getDownloadLinkForAttachment(
  params: Request
): Promise<Result<Response, string>> {
  const url = fileManagementEndpointUrl("download");
  const res = await axios.get<Response>(url, {
    params,
  });
  if (res.status < 400) {
    return ok(res.data);
  } else {
    return err(res.data as any);
  }
}
