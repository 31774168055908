export interface RootState {
  version: string;
  loadingMessage: null | string;
  [key: string]: any;
}
export interface RootGetters {
  [name: string]: unknown;
}

export enum VuexModulNames {
  AUDIT = "audit",
}

export enum KnownDocumentNames {
  AUDIT_CLASSES = "auditClasses",
}
