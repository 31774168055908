



































































import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import AMeasureActivityComment from "./AMeasureActivityComment.vue";
import AMeasureActivityPrimary from "./AMeasureActivityPrimary.vue";
import AMeasureActivitySecondary from "./AMeasureActivitySecondary.vue";
import {
  MeasureActivity,
  AnyMeasureActivity,
} from "@/types/measure-activities";
import { TranslateableText } from "@auditcloud/shared/lib/schemas";
import { text2safeHtml } from "@auditcloud/shared/lib/utils/security/text2safeHtml";

@Component({
  components: {
    AMeasureActivityComment,
    AMeasureActivityPrimary,
    AMeasureActivitySecondary,
  },
})
export default class AMeasureActivityStep extends Vue {
  //missingDefault
  @Prop({
    type: Object,
    required: true,
  })
  readonly activity!: AnyMeasureActivity;

  /**
   * @param addAts should user mentions be prefixed by an @?
   */
  getTemplate(input: TranslateableText, addAts = false) {
    const text = this.$ft(input); // typeof input === "string" ? input : input[this.$i18n.locale] ?? input.en;
    // Todo: Render Komponente implementieren um ohne v-html auszukommen siehe vue-text-highlight oder SnippetDate
    return text2safeHtml(text)
      .replace(
        /\[([^[\]]+?)\]\((object:\/\/?)\)/g,
        `<a href="${(this.activity as any).object?.url || "#"}">$1</a>`
      )
      .replace(
        /\[([^[\]]+?)\]\((target:\/\/?)\)/g,
        `<a href="${(this.activity as any).target?.url || "#"}">$1</a>`
      )
      .replace(
        /\[([^[\]]+?)\]\(user:\/\/(.+?)\)/g,
        `<a href="/user/$2">${addAts ? "@" : ""}$1</a>`
      );
  }
}
