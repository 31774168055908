

































































import Vue from "vue";
import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  TodoAny,
  TodoActionSignature,
} from "@auditcloud/shared/lib/utils/type-guards";

import { api } from "@/store/modules/audit";
import { api as confApi } from "@/store/modules/configuration";
import { api as standardApi } from "@/store/modules/standards";
import { api as schemasApi, FormSchemaDynamic } from "@/store/modules/schemas";

import VueFormJsonSchema from "vue-form-json-schema";
import FormsControlMixin from "@/components/mixins/FormsControlMixin.vue";

import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import { AuditPermissions } from "@auditcloud/shared/lib/utils/aclHelpers";
import { stripDialog, isDialogRoute } from "@/routenames";
import { calcParentRoute } from "../../utils/routerUtils";
import DialogLayout from "../layouts/BaseLayouts/DialogLayout.vue";
import { ActivityVerbs, getActivityCreator } from "@/utils/activityCreator";
import { formatAuditName } from "@auditcloud/shared/lib/utils/formatting/audits";
import { AuditClassConfig } from "@auditcloud/shared/lib/schemas";
@Component({
  components: {
    VueFormJsonSchema,
    DialogLayout,
  },
  mixins: [FormsControlMixin],
})
export default class AuditMetadataDialog extends Mixins(FormsControlMixin) {
  @Prop({
    type: String,
    required: true,
  })
  auditId!: string;

  loading: boolean = true;
  dialog: boolean = true;
  formSchemaId: string = "metadata-form";
  formValid: any = {};
  vfjsOptions: any = {};
  editedItem: any = null;

  @Getter(api.getters.getAuditMetadata, { namespace: api.namespace })
  getAuditMetadata!: TodoAny;

  @Getter(api.getters.getAuditClass, { namespace: api.namespace })
  getAuditClass!: AuditClassConfig | null;

  @Getter(standardApi.getters.getStandardList, {
    namespace: standardApi.namespace,
  })
  getStandardList!: TodoAny;

  @Getter(confApi.getters.audit, { namespace: confApi.namespace })
  audit!: TodoAny;

  @Getter(schemasApi.getters.getVfjsOptions, {
    namespace: schemasApi.namespace,
  })
  getVfjsOptions!: TodoAny;

  @Getter(schemasApi.getters.getVfjsCommonSchemas, {
    namespace: schemasApi.namespace,
  })
  getVfjsCommonSchemas!: TodoAny;

  @Getter(api.getters.getAuditFormSchema, {
    namespace: api.namespace,
  })
  getAuditFormSchema!: (schemaId: string) => FormSchemaDynamic;

  @Getter(api.getters.getAuditPermissions, { namespace: api.namespace })
  auditPermissions!: AuditPermissions;

  @Getter(api.getters.getAuditRoles, { namespace: api.namespace })
  auditRoles!: string[];

  get readonly() {
    return !this.auditPermissions.writeMetadata;
  }

  get auditName() {
    if (this.getAuditMetadata === null) {
      return "";
    } else {
      return " - " + formatAuditName({ ...this.getAuditMetadata });
    }
  }
  get formSchema() {
    const res = this.getAuditFormSchema(this.formSchemaId);
    console.log("AuditMedataControl:uiSchema", res);
    return res;
  }
  get uiSchema() {
    return this.formSchema.ui(this.readonly, this.readonly);
  }
  get schema() {
    return this.formSchema.data;
  }
  get configuration() {
    return this.audit.dialog || {};
  }
  get isUpdate() {
    return true;
  }
  get selectableScopes() {
    return this.audit.data.scopes;
  }
  get selectablePlanningYears() {
    return [2019, 2020, 2021, 2022];
  }
  get currentMinimumDate() {
    let year = this.mixinFormData.planning_year || "1970";

    let z = new Date("1970-01-01");
    z.setFullYear(year);
    return z.toISOString().substr(0, 10);
  }
  get currentMaximumDate() {
    let year = this.mixinFormData.planning_year || "1970";

    let z = new Date("1970-12-31");
    z.setFullYear(year);
    return z.toISOString().substr(0, 10);
  }
  get selectableAuditTypes() {
    return this.audit.data.types;
  }
  get selectableStandards() {
    console.log("StandardList", this.getStandardList);
    return this.getStandardList;
  }
  get selectableProcesses() {
    return this.audit.data.processes;
  }
  get selectableAuditBys() {
    return this.audit.data.auditparties;
  }
  get parentRoute() {
    return calcParentRoute(this.$route);
  }

  @Action(api.actions.updateAuditMetadata, { namespace: api.namespace })
  updateAuditMetadata!: TodoActionSignature;

  @Action(api.actions.clearAudit, { namespace: api.namespace })
  clearAudit!: TodoActionSignature;

  @Action(api.actions.setAudit, { namespace: api.namespace })
  setAudit!: TodoActionSignature;

  onValidated(value) {
    this.formValid = value;
  }
  input(value) {
    console.log("input", value);
  }
  change(value) {
    this.editValues = value;
    console.log("change", value);
  }
  onChangeState(value) {
    console.log("state changed", value);
  }
  showError(err) {
    console.error("AuditMetadataDialog", err);
  }
  onScopeUpdate(evt) {
    this.editedItem.scope = evt;
  }
  navBack() {
    this.$router.back();
  }

  navToParent() {
    if (isDialogRoute(this.$route.name)) {
      this.$router.push({
        name: stripDialog(this.$route.name ?? ""),
        params: this.$route.params,
      });
    } else if (this.parentRoute !== null) {
      this.$router.push(this.parentRoute);
    } else {
      this.navBack();
    }
  }
  closeDialog() {
    setTimeout(() => {
      this.navToParent();
    }, 250);
  }

  save() {
    if (this.formValid) {
      const activityCreator = getActivityCreator(
        this.$user,
        this.getAuditClass,
        {
          ...this.mixinFormData,
        }
      );

      this.mixinFormData.responsible = this.mixinFormData.responsible || null;

      console.assert(
        typeof this.auditId === "string",
        "expect valid auditId got",
        this.auditId,
        this
      );
      if (typeof this.auditId === "string") {
        this.updateAuditMetadata({
          activity: activityCreator?.(this.auditId, ActivityVerbs.UPDATE),
          id: this.auditId,
          data: this.mixinFormData,
        }).then(() => {
          console.log("UPDATE_AUDIT_SUCCEEDED");
        });
        this.mixinInitFormData(this.mixinFormData);
        this.closeDialog();
      }
    } else {
      this.vfjsOptions = {
        ...this.getVfjsOptions,
        showValidationErrors: true,
      };

      console.log("Form invalid");
    }
  }

  @Watch("auditId", {
    immediate: true,
  })
  onWatchAuditId(newVal, oldVal) {
    this.clearAudit().then(() => {
      this.setAudit({ auditId: newVal, loadSubDocuments: false });
    });
  }

  @Watch("getAuditMetadata", {
    immediate: true,
    deep: true,
  })
  onWatchGetAuditMetadata(newVal, oldVal) {
    console.log("getAuditMetadata:changed", newVal, oldVal);
    this.vfjsOptions = this.getVfjsOptions;

    this.mixinInitFormData(newVal);
    if (
      this.mixinFormData &&
      typeof this.mixinFormData.metadata === "undefined"
    ) {
      Vue.set(this.mixinFormData, "metadata", {});
    }
  }

  created() {
    console.log("AuditMetadataDialog:created", this.dialog, this.auditId);
  }
  mounted() {
    console.log("AuditMetadataDialog:mounted", this.dialog, this.auditId);
  }
  beforeDestroy() {
    console.log("AuditMetadataDialog:beforeDestroy", this.dialog, this.auditId);
    this.clearAudit();
  }
}
