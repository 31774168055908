import { RootState, RootGetters } from "../../types";
import { State } from "./types";
import { api as measureWorkflowsApi } from "../measureWorkflows";
import { api as usersApi } from "../users";
import { api as auditClassesApi } from "../auditClasses";
import { getterNs } from "@/utils/VuexHelper";
import { Dictionary, mapValues, size } from "lodash";
import { MeasureWorkflow } from "@auditcloud/shared/lib/workflow/modules/Measure";

import {
  MeasurePermissions,
  calcMeasurePermissions,
  calcAllMeasureRoles,
  isUserInAccessList,
} from "@auditcloud/shared/lib/utils/aclHelpers";
import {
  StoredAttachmentEntryList,
  StoredAttachmentEntryMap,
  StoredAttachmentEntryWithContext,
} from "@auditcloud/shared/lib/types/Attachments";
import {
  MeasureProcessStepDoc,
  TranslateableText,
} from "@auditcloud/shared/lib/schemas";
import { WorkflowHistoryType } from "@/components/types";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import { ft } from "@/plugins/ContentTranslation";
import { typeIsNotEmpty } from "@auditcloud/shared/lib/utils/filter/typeIsNotEmpty";
import { Getters } from ".";
import { idable, nullable } from "@auditcloud/shared/lib/types/common";
import moment from "moment";
import { UserInfoResolver } from "@/types/User";
import { extractCurrentUserRef, extractCurrentUserRoles } from "../user/utils";
import { EntryAttachmentsVuexApi } from "@/utils/attachFileToEntity";
import { TransitionConfig } from "@auditcloud/shared/lib/workflow/types/Transition";
import {
  AuditClassClient,
  MappedAuditClasses,
} from "@auditcloud/shared/lib/types/AuditClass";

type Getter<R> = (
  state: State,
  getters: Getters,
  rootState: RootState,
  rootGetters: RootGetters
) => R;

const getCurrentMeasureId: Getter<string | null> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  console.log("getCurrentMeasureId", state.Document.id, state.Document.exists);
  if (
    state.Unlistener === null ||
    (state.Document.id.length > 0 && state.Document.exists === false)
  ) {
    return null;
  } else {
    return state.Document.id;
  }
};

const getCurrentMeasure: Getter<MeasureProcessDocument | null> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  if (state.Document.data instanceof Object) {
    return state.Document.data;
  } else {
    return null;
  }
};

const getCurrentStepIsDirty: Getter<boolean> = state =>
  state.currentStepIsDirty;

const getCurrentStepMessages: Getter<TranslateableText[]> = (
  state,
  getters
) => {
  const workflow = getters.getWorkflow;
  if (!workflow) {
    return [];
  }
  return workflow.getStepMessages(state.processSteps);
};

const getCurrentUserRoles: Getter<string[]> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  return extractCurrentUserRoles(rootGetters);
};

const getCurrentMeasureRoles: Getter<string[]> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const currentUserRoles = getters.getCurrentUserRoles;
  const currentUserId = extractCurrentUserRef(rootGetters)?.id ?? null;

  if (state.Document.data instanceof Object) {
    return calcAllMeasureRoles(
      state.Document.data,
      currentUserId,
      currentUserRoles
    );
  } else {
    return [];
  }
};

const getPermissions: Getter<MeasurePermissions> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const currentUserRoles = getters.getCurrentUserRoles;
  const currentUserId = extractCurrentUserRef(rootGetters)?.id ?? null;
  const measureProcess = state.Document.data;
  const workflow = getters.getWorkflow;
  return calcMeasurePermissions(
    measureProcess,
    currentUserId,
    currentUserRoles,
    workflow
  );
};
const getWorkflowId: Getter<string | null> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  if (state.Document.data instanceof Object) {
    return state.Document.data.workflow.workflowId;
  } else {
    return null;
  }
};

const getWorkflow: Getter<MeasureWorkflow | null> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const workflowId = getters[n.getWorkflowId] as string | null;
  const mappedWorkflows = rootGetters[
    getterNs(
      measureWorkflowsApi,
      measureWorkflowsApi.getters.getMappedMeasureWorkflows
    )
  ] as Dictionary<MeasureWorkflow>;
  if (workflowId !== null && mappedWorkflows[workflowId]) {
    return mappedWorkflows[workflowId];
  } else {
    return null;
  }
};
const getStatusId: Getter<string | null> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  if (state.Document.data instanceof Object) {
    return state.Document.data.workflow.statusId;
  } else {
    return null;
  }
};
const getPossibleTransitions: Getter<idable<TransitionConfig>[]> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  console.log("getPossibleTransitions", state.measureTransitions);
  return state.measureTransitions;
};
const getActiveTransition: Getter<idable<TransitionConfig> | null> = (
  state,
  getters
) => {
  const transitionId = state.activeTransitionId;
  const transition = state.measureTransitions.find(
    transition => transitionId === transition.id
  );
  return transition ?? null;
};
const getProcessSteps: Getter<idable<MeasureProcessStepDoc>[]> = state => {
  return state.processSteps;
};
const getEntityAttachments: Getter<
  EntryAttachmentsVuexApi["getEntityAttachments"]
> = state => {
  const attachments = state.Document.data?.attachments ?? {};

  return mapValues(
    attachments,
    (attachment): StoredAttachmentEntryWithContext => {
      return {
        ...attachment,
        context: "measure",
      };
    }
  );
};

const getAttachmentsCount: Getter<number> = (state, getters) => {
  const measureAttachments = getters[
    n.getEntityAttachments
  ] as StoredAttachmentEntryMap;

  return size(measureAttachments);
};

const getAttachmentsByStepId: Getter<Dictionary<StoredAttachmentEntryList>> = (
  state,
  getters
) => {
  const measureAttachments = getters[
    n.getEntityAttachments
  ] as StoredAttachmentEntryMap;

  const result: Dictionary<StoredAttachmentEntryList> = {};
  state.processSteps.forEach(({ id, workflowInfo }) => {
    const momentEnteredAt = moment(workflowInfo.enteredAt);
    const momentLeftAt =
      workflowInfo.leftAt != null ? moment(workflowInfo.leftAt) : undefined;
    result[id] = Object.keys(measureAttachments)
      .map(attachmentId => {
        return { id: attachmentId, ...measureAttachments[attachmentId] };
      })
      .filter(typeIsNotEmpty)
      .filter(({ added }) => {
        return (
          added != null &&
          moment(added).isBetween(momentEnteredAt, momentLeftAt)
        );
      });
  });
  return result;
};

const getIsAuditAccessible: Getter<boolean> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const measureProcess = state.Document.data;
  const currentUserRoles = getters.getCurrentUserRoles;
  const currentUserId = extractCurrentUserRef(rootGetters)?.id ?? null;
  if (measureProcess == null || currentUserId == null) {
    return false;
  }
  return isUserInAccessList(
    measureProcess.auditMetadata.accessListMetadataRead,
    currentUserId,
    currentUserRoles
  );
};

const getEntityAttachmentPermissions: Getter<
  EntryAttachmentsVuexApi["getEntityAttachmentPermissions"]
> = (state, getters, rootState, rootGetters) => {
  const measurePermission = getters[n.getPermissions] as MeasurePermissions;
  console.log("MeasurePersmissions", measurePermission);

  return {
    add: measurePermission.editAttachments,
    delete: measurePermission.editAttachments,
    update: measurePermission.editAttachments,
    download: measurePermission.read,
    deleteOwn: measurePermission.editAttachments,
    updateOwn: measurePermission.editAttachments,
    downloadOwn: measurePermission.read,
  };
};
const getWorkflowHistory: Getter<null | WorkflowHistoryType[]> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const measure = getters[n.getCurrentMeasure] as MeasureProcessDocument | null;

  const history = measure?.workflow.history;
  if (history && history.length > 0) {
    return history.map((log, idx) => {
      const from = ft(
        log.status.form ? log.status.form.name : "common.workflow.created"
      );

      const resolveUser = rootGetters[
        getterNs(usersApi, usersApi.getters.getUser)
      ] as UserInfoResolver;

      const user = resolveUser(log.user) ?? {
        id: log.user,
        displayName: `Unknown ${log.user}`,
      };

      return {
        changedAt: new Date(log.changedAt),
        user,
        status: {
          from,
          to: ft(log.status.to.name),
        },
        comment: log.comment,
        no: idx,
      };
    });
  } else {
    return null;
  }
};

const getIsDeleted: Getter<boolean> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  return state.Document.data?.docVersion?.deleted ?? false;
};

const getAuditClassConfig: Getter<nullable<AuditClassClient>> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const auditClassId = state.Document.data?.auditMetadata.audit_class;

  if (!state.Document.exists || !auditClassId) {
    return null;
  }

  const mappedAuditClasses = rootGetters[
    getterNs(auditClassesApi, auditClassesApi.getters.mappedAuditClasses)
  ] as MappedAuditClasses;
  const auditClass = mappedAuditClasses[auditClassId];

  if (auditClass) {
    return {
      ...auditClass,
      id: auditClassId,
    };
  } else {
    return null;
  }
};

const getPreventRefUserAssignees: Getter<boolean> = (state, getters) => {
  const auditClass = getters.getAuditClassConfig;
  return auditClass?.preventRefUserAssignees ?? false;
};

const getters = {
  getCurrentMeasureId,
  getCurrentMeasure,
  getCurrentMeasureRoles,
  getCurrentStepIsDirty,
  getCurrentStepMessages,
  getCurrentUserRoles,
  getIsAuditAccessible,
  getPermissions,
  getWorkflowId,
  getWorkflow,
  getStatusId,
  getPossibleTransitions,
  getActiveTransition,
  getProcessSteps,
  getEntityAttachments,
  getAttachmentsCount,
  getAttachmentsByStepId,
  getEntityAttachmentPermissions,
  getWorkflowHistory,
  getIsDeleted,
  getAuditClassConfig,
  getPreventRefUserAssignees,
};

const n = mapValues(getters, (_, key) => key);

export { n as getterNames, getters };
