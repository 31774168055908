
























































































import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { isArray, pick } from "lodash";
import AUserAutocompleteMixin, {
  ItemType,
} from "@/components/mixins/AUserAutocompleteMixin.vue";
import AUserAutocompleteCreateDialog from "./AUserAutocompleteCreateDialog.vue";

import { IUserRef, typeIsIUserRef } from "@auditcloud/shared/lib/types/UserRef";
import {
  ConfigurableGetter,
  getterPath,
} from "@auditcloud/shared/lib/types/common";

function UserRef2ItemType(disabled: boolean): (user: IUserRef) => ItemType {
  return (user: IUserRef): ItemType => ({
    id: user.id,
    disabled,
    data: user,
  });
}

type InputType = IUserRef[] | null | IUserRef;
type OutputType = IUserRef[];

@Component({
  components: { AUserAutocompleteCreateDialog },
})
export default class AUserAutocompleteMultiple extends AUserAutocompleteMixin {
  @Prop({
    type: Object,
    default: null,
  })
  staticUsersGetter!: ConfigurableGetter | null;

  @Prop({
    type: Boolean,
    default: false,
  })
  closeOnContentClick!: boolean;

  get staticUsers(): IUserRef[] {
    if (this.staticUsersGetter !== null) {
      const path = getterPath(this.staticUsersGetter);
      const getterResult: any = this.$store.getters[path];

      if (Array.isArray(getterResult) && getterResult.every(typeIsIUserRef)) {
        return getterResult;
      } else {
        console.error(
          "StaticUser for AUserAutocomplete doesn't return valid userRefs"
        );
        return [];
      }
    } else {
      return [];
    }
  }

  get staticUserIds(): string[] {
    return this.staticUsers.map(user => user.id);
  }

  xSelected: ItemType[] = [];

  get appendIcon(): string | null {
    return this.hasValue && this.clearable ? "clear" : null;
  }

  get hasValue(): boolean {
    return this.xSelected.length > 0;
  }

  @Prop({
    type: [Object, Array],
    default: () => [],
  })
  value!: InputType;

  @Prop({
    type: Array,
    default: () => [],
  })
  greyedOutUserIds!: string[];

  @Watch("value", {
    immediate: true,
    deep: true,
  })
  onValueChanged(newVal: InputType, oldVal: InputType) {
    const userList = isArray(newVal) ? newVal : newVal === null ? [] : [newVal];
    this.xItems = userList.map(UserRef2ItemType(false));
    this.xSelected = userList.map(UserRef2ItemType(false));
    if (userList !== newVal) {
      this.emitSelectionChanged();
    }
  }

  @Watch("search", {
    immediate: true,
  })
  onSearch(newVal: string, oldVal: string) {
    newVal && this.queryUsers(newVal);
  }

  created() {
    this.itemsTransform = UserRef2ItemType(false);
  }

  openAddUserDialog(evt: any) {
    this.userDialogVisible = true;
    (this.$refs.autocomplete as HTMLInputElement).blur();
  }
  userDialogVisible = false;

  get selected(): ItemType[] {
    return [
      ...this.staticUsers.map(UserRef2ItemType(false)),
      ...this.xSelected.filter(
        user => !this.staticUserIds.includes(user.id ?? "")
      ),
    ];
  }

  onSelectionChanged(newVal: ItemType[]) {
    this.xSelected = newVal
      .filter(item => item.id && !this.staticUserIds.includes(item.id))
      .map(item => {
        return {
          ...item,
          disabled: false,
        };
      });
    this.emitSelectionChanged();
  }

  onClearClicked(evt: MouseEvent) {
    this.xSelected = [];
    this.emitSelectionChanged();
  }

  unselectItem(id: string) {
    this.xSelected = this.xSelected.filter(item => item.id !== id);
    this.emitSelectionChanged();
  }

  emitSelectionChanged() {
    this.search = "";
    const cleanupUserRef = (val: any) => {
      return pick(val, ["displayName", "id"]);
    };

    this.$emit(
      "input",
      this.xSelected.map(item => cleanupUserRef(item.data))
    );
  }

  selectNewUser(user: IUserRef): void {
    this.search = "";
    this.xItems.push(UserRef2ItemType(false)(user));
    this.xSelected.push(
      UserRef2ItemType(this.staticUserIds.includes(user.id))(user)
    );
    this.emitSelectionChanged();
  }
}
