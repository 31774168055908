



















































import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { State, Action, Getter, Mutation } from "vuex-class";
import {
  TodoMap,
  TodoArray,
  TodoAny,
  TodoActionSignature,
  TodoMutationSignature,
} from "@auditcloud/shared/lib/utils/type-guards";

import { mapGetters, mapActions } from "vuex";
import { api } from "@/store/modules/audits";
import { api as auditApi } from "@/store/modules/audit";
import { api as appApi } from "@/store/modules/app";
import { uploadBlob } from "@/utils/uploader";
import { getDownloadLinkForAttachment } from "@/utils/storage";

declare class ImageCapture {
  constructor(videoTrack: MediaStreamTrack);
  takePhoto(): Promise<Blob>;
  grabFrame(): Promise<ImageBitmap>;
  readonly track: MediaStreamTrack;
}

@Component({})
export default class CameraView extends Vue {
  mediaStream: null | MediaStream = null;
  cameraType: any = { facingMode: "user" };
  message: any = null;
  progressColor: String = "purple";

  @Getter(auditApi.getters.getAuditId, { namespace: auditApi.namespace })
  auditId!: null | string;

  @Getter(appApi.getters.getIsOnline, { namespace: appApi.namespace })
  getIsOnline!: TodoAny;

  get isLoading() {
    // Todo: Reimplement
    return false; //this.loading;
  }

  get videoElement(): HTMLVideoElement {
    return this.$refs.video as HTMLVideoElement;
  }

  mounted() {
    navigator.mediaDevices
      .getUserMedia({ video: this.cameraType })
      .then(mediaStream => {
        this.mediaStream = mediaStream;

        this.videoElement.srcObject = mediaStream;
        this.videoElement.play();
        console.log("Constraints");
        console.log(navigator.mediaDevices.getSupportedConstraints());
      })
      .catch(error => console.error("getUserMedia() error:", error));
  }

  @Action(auditApi.actions.attachFileToFinding, {
    namespace: auditApi.namespace,
  })
  attachFileToFinding!: TodoActionSignature;
  @Action(appApi.actions.setLoading, { namespace: appApi.namespace })
  setLoading!: TodoActionSignature;

  changeCam() {
    if (this.cameraType.facingMode === "user") {
      this.cameraType.facingMode = "environment";
    } else {
      this.cameraType.facingMode = "user";
    }

    console.log("Kamera geändert, wenn möglich");
    console.log(this.cameraType.facingMode);

    // Michel: Wie funktioniert das Mounting Konzept oben?

    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: this.cameraType.facingMode } })
      .then(mediaStream => {
        this.mediaStream = mediaStream;
        this.videoElement.srcObject = mediaStream;
        this.videoElement.play();
      })
      .catch(error => console.error("getUserMedia() error:", error));
  }
  capture() {
    const mediaStream = this.mediaStream;
    if (mediaStream && this.auditId) {
      const mediaStreamTrack = mediaStream.getVideoTracks()[0];
      const auditId = this.auditId;

      const imageCapture = new ImageCapture(mediaStreamTrack);

      this.setLoading(true);

      return imageCapture
        .takePhoto()
        .then(blob => {
          const imageName = `images/picture-${new Date().getTime()}.jpg`;
          console.log("Upload starten:" + imageName);
          return uploadBlob(blob, imageName, {
            target: { type: "audit", auditId: auditId },
          });
        })
        .then(fileUploadResponse => {
          if (fileUploadResponse.isErr()) {
            throw fileUploadResponse.error;
          }
          return getDownloadLinkForAttachment({
            attachmentId: fileUploadResponse.value.data.id,
            target: {
              type: "audit",
              auditId,
            },
          });
        })
        .then(downloadResponse => {
          console.log("FileUploader:1", downloadResponse);
          if (downloadResponse.isOk()) {
            return downloadResponse.value.downloadUrl;
          } else {
            throw downloadResponse.error;
          }
        })
        .then(() => {
          this.setLoading(false);
          this.$router.go(-1);
        })
        .catch(error => {
          console.log("Error Uploading File" + error);
          this.setLoading(false);
        });
    }
  }
}
