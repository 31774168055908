import {
  map,
  flatMap,
  Dictionary,
  values,
  toPairs,
  isString,
  uniq,
  flatten,
  partition,
  uniqBy,
  intersection,
  size,
  union,
  keys,
  pick,
} from "lodash";
import { v4 as uuidv4 } from "uuid";

import {
  AuditItem,
  AuditItemDimensionRef,
  AuditQuestionNote,
  Finding,
  CategoryLevel,
  AuditMetadataDoc,
  AuditResultDoc,
  AuditPreparationState,
  AuditStandardRef,
  AuditClassConfig,
} from "@auditcloud/shared/lib/schemas";
import { lookupAndMakeIdable } from "@auditcloud/shared/lib/utils/transform/lookupAndMakeIdable";
import {
  FindingTypeMap,
  AuditItemTypeMap,
  MeasureType,
  MeasureTypeMap,
  FindingType,
} from "@auditcloud/shared/lib/types/ItemTypes";
import {
  tt2str,
  NullablePartial,
  idable,
  FieldPartNames,
  CollectionNames,
  DocumentNames,
  nullable,
} from "@auditcloud/shared/lib/types/common";
import { typeIsIUserRef } from "@auditcloud/shared/lib/types/UserRef";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import {
  AuditItemCategoryMap,
  CategoryLevelOptions,
  typeIsCategoryLevelTree,
  getCategoryDepthOrDefault,
} from "@auditcloud/shared/lib/types/AuditItemCategory";
import { createError } from "@/utils/Errors";

import { PointsCalculationStrategy } from "@auditcloud/shared/lib/types/AuditScore/types";
import { ct } from "../../../plugins/ContentTranslation";
import { getterNames as gn } from "./getters";
import {
  CurrentAuditState,
  DimensionManager,
  FindingDimensionExtractorFactory,
  PreparationStates,
  UnansweredAuditItemDimensionsFactory,
} from "./types";
import {
  AuditItemWithId,
  QuestionNoteWithId,
} from "@auditcloud/shared/lib/utils/audit/types";
import {
  mappedCount,
  MappedCountResult,
} from "@auditcloud/shared/lib/utils/reduce/mappedCount";
import { typeIsNotEmpty } from "@auditcloud/shared/lib/utils/filter/typeIsNotEmpty";
import {
  AuditItemAnswersLookupFunction,
  selectAvailableFiltersForList,
} from "@auditcloud/shared/lib/utils/filter/FilterUtils";
import { api } from ".";
import {
  MeasureTypeId,
  BLOCKING_AUDIT_ITEM_STATES,
  MAGIC_STANDARD_ID_NULL,
  MAGIC_VDA_STAR_AUDIT_ITEM_TYPE_ID,
} from "@auditcloud/shared/lib/constants";

import { getterNs, mutationNs } from "@/utils/VuexHelper";
import { AuditMetadataClient } from "@/types/Audit";
import { v5 as uuidv5 } from "uuid";
import {
  FlatCategoryData,
  FlatExportDataRow,
  FlatExportVdaData,
} from "@auditcloud/shared/lib/types/AuditReportExport";
import { UserInfoResolver } from "@/types/User";
import {
  createAuditItemsPath,
  createAuditMetadataPath,
  createAuditPreparationMetadataPath,
  createQuestionNotesPath,
  fieldPath,
} from "@auditcloud/shared/lib/utils/firestorePathHelper";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { rdu } from "../user";
import {
  Filter,
  FrontendFilterTree,
} from "@auditcloud/shared/lib/utils/filter/types";
import {
  FILTER_DIMENSION,
  FILTER_LABELS,
} from "@auditcloud/shared/lib/utils/filter/AuditItemListManipulatorIds";
import { buildAuditItemAggegators } from "@auditcloud/shared/lib/utils/filter/AuditItemAggegators";
import { buildFilterIndexForItems } from "@auditcloud/shared/lib/utils/filter/utils";
import { Commit, ActionContext } from "vuex";
import { RootState } from "@/store/types";

export function calculateTotalConsideredAuditItemIds(
  preselectedIds: string[],
  manuallyConsideredAuditItemIds: AuditPreparationState["manuallyConsideredAuditItemIds"]
) {
  const consideredAuditItemIds = [
    ...preselectedIds.filter(
      itemId => manuallyConsideredAuditItemIds[itemId] !== "exclude"
    ),
    ...Object.keys(manuallyConsideredAuditItemIds).filter(
      itemId => manuallyConsideredAuditItemIds[itemId] === "include"
    ),
  ];
  const uniqueConsideredAuditItemIds = [...new Set(consideredAuditItemIds)];
  return uniqueConsideredAuditItemIds;
}

export function buildFindingLookup(
  auditState: CurrentAuditState,
  getters: any
): AuditItemAnswersLookupFunction<idable<Finding>> {
  const findings = auditState.AuditItemsDocument.data?.findings ?? {};

  const auditItemFindingsmapping = getters[
    gn.getAuditItemId2FindingIdsMap
  ] as Dictionary<string[]>;
  return (auditItemId: string) => {
    const findingIds = auditItemFindingsmapping[auditItemId] ?? [];

    return findingIds.map(lookupAndMakeIdable(findings)).filter(typeIsNotEmpty);
  };
}

export function allAuditItemsMapped(rootGetters: any) {
  return rootGetters[getterNs(api, api.getters.getMappedAuditItems)] as {
    [auditItemId: string]: AuditItemWithId;
  };
}

export function allPlainAuditItemsMapped(rootGetters: any) {
  return rootGetters[getterNs(api, api.getters.getPlainAuditItemsMapped)] as {
    [auditItemId: string]: AuditItem;
  };
}

export function getFindingsMapped(auditState: CurrentAuditState) {
  return auditState.AuditItemsDocument.data?.findings ?? {};
}

export function getSelfAssessmentsMapped(auditState: CurrentAuditState) {
  return auditState.AuditItemsDocument.data?.selfAssessments ?? {};
}

export function getQuestionNotesMapped(
  auditState: CurrentAuditState,
  getters: any
): Dictionary<AuditQuestionNote> {
  if (!getters[gn.getIsAuditItemNoteVisible] as boolean) {
    return {};
  }
  return auditState.AuditItemsDocument.data?.questionNotes ?? {};
}

export const isUnDef = (v: any) => {
  return typeof v === "undefined";
};
export const getFallback = (v: any, alt: string | null) => {
  return isUnDef(v) ? alt : v;
};

export function expectType<T>(data: T): T {
  return data;
}

export const auditMeasuresCountByType = (
  measures: MeasureProcessDocument[],
  measure_types: MeasureType[]
) => {
  const measureTypCountMapping = measures
    .map(measure => {
      const type = measure.additionalWorkflowMetadata.type as MeasureTypeId;
      return [MeasureTypeId.Corrective, MeasureTypeId.Direct].includes(type)
        ? type
        : "#";
    })
    .reduce(mappedCount, {} as MappedCountResult);

  return toPairs(measureTypCountMapping)
    .map(([id, count]) => {
      const type = measure_types.find(mt => mt.id === id);
      return {
        count,
        id,
        name: ct(type ? type.text : "Unknown Type"),
      };
    })
    .filter(typeIsNotEmpty)
    .sort((lhs, rhs) => {
      return (
        measure_types.findIndex((val: { id: string }) => val.id === lhs.id) -
        measure_types.findIndex((val: { id: string }) => val.id === rhs.id)
      );
    });
};

export const auditFindingsCountByType = (
  mappedFindings: Dictionary<Finding>,
  finding_types: FindingTypeMap,
  findings_order: string[]
) => {
  const findingTypCountMapping = values(mappedFindings)
    .map(finding => {
      return finding.type;
    })
    .reduce(mappedCount, {} as MappedCountResult);

  return toPairs(findingTypCountMapping)
    .map(([id, count]) => {
      const type = finding_types[id];
      console.assert(type, `Unknown finding type "${id}"`, finding_types);

      return {
        count,
        id,
        value: type ? type.value : 0,
        name: ct(type ? type.text : ""),
        short: ct(type ? type.short : ""),
        is_no_deviation: type.is_no_deviation || false,
        is_not_applicable: type.is_not_applicable || false,
      };
    })
    .filter(typeIsNotEmpty)
    .sort((lhs, rhs) => {
      return (
        findings_order.findIndex((val: string) => val === lhs.id) -
        findings_order.findIndex((val: string) => val === rhs.id)
      );
    });
};

export const auditItemCountByType = (
  audit_items: AuditItem[] | { [key: string]: AuditItem },
  audit_item_types: AuditItemTypeMap
) => {
  const auditItemTypCountMapping = values(audit_items)
    .map(auditItem => {
      return auditItem.question.type;
    })
    .reduce(mappedCount, {} as MappedCountResult);
  return toPairs(auditItemTypCountMapping)
    .map(([id, count]) => {
      const type = audit_item_types[id];
      return {
        count,
        id,
        value: type ? type.value : 0,
        name: ct(type ? type.description : ""),
      };
    })
    .filter(typeIsNotEmpty)
    .sort((lhs, rhs) => {
      return rhs.value - lhs.value;
    });
};

export const normalizeMeasureForFlatExport = (
  measure: MeasureProcessDocument,
  userGetter: UserInfoResolver,
  measureTypeMapping: MeasureTypeMap
) => {
  const measureTypeId =
    measure.additionalWorkflowMetadata.type ?? MeasureTypeId.Corrective;
  const MeasureTypeConfig = measureTypeMapping[measureTypeId];

  const user_name_from_id = (UserData: any) => {
    if (typeIsIUserRef(UserData)) {
      return [UserData.displayName];
    } else if (isString(UserData)) {
      const user = userGetter(UserData);
      if (user) {
        return [user.displayName, "<" + user.email + ">"];
      } else {
        return [UserData];
      }
    } else {
      return ["Unknown User"];
    }
  };

  const createdDate = measure.docVersion.createdAt.toDate();
  return {
    measureId: measure.id,
    measureNote: measure.description,
    measureDesc: measure.measureImplementation,
    measureFullDesc: [measure.description, measure.measureImplementation]
      .filter(typeIsNotEmpty)
      .filter(v => v !== "")
      .join("\r\n\r\n"),
    measureTypeId: MeasureTypeConfig.id,
    measureTypeAbbr: ct(MeasureTypeConfig.short),
    measureTypeName: ct(MeasureTypeConfig.text),
    measureTypeDesc: ct(MeasureTypeConfig.description),
    measureCauseAnalysis: measure.causeAnalysis,
    measureDueDate: measure.dueDate,
    measureDueDateLocale: measure.dueDate
      ? new Date(measure.dueDate).toLocaleDateString()
      : "",
    measureAssignedTo: user_name_from_id(measure.userRefs.assignedTo)[0],
    measureAssignedToAndMail: user_name_from_id(
      measure.userRefs.assignedTo
    ).join(" "),
    measureCreateDate: createdDate.toISOString(),
    measureCreateDateLocale: createdDate.toLocaleDateString(),
    measureAuthor: user_name_from_id(measure.docVersion.createdBy)[0],
    measureAuthorAndMail: user_name_from_id(measure.docVersion.createdBy).join(
      " "
    ),
    measureStatus: measure.workflow.statusId,
    measurePriority: measure.priority,
  };
};

export function extractSignificantCategories<T>(
  categoryRef: T[],
  categoryLevel: CategoryLevel
): T[] {
  console.assert(
    categoryRef.length > 0,
    "extractSignificantCategories expect categoryRef to have at least one item",
    categoryRef
  );
  if (categoryLevel === CategoryLevelOptions.Leaf) {
    return [categoryRef[categoryRef.length - 1]];
  }
  if (typeIsCategoryLevelTree(categoryLevel)) {
    return categoryRef.slice(0, getCategoryDepthOrDefault(categoryLevel));
  }
  return [categoryRef[0]];
}

export function flattenCategoryRefForExport(
  categoryRef: string[],
  categoryLevel: CategoryLevel,
  mappedCategories: AuditItemCategoryMap
): FlatCategoryData {
  const categoryIds = extractSignificantCategories(categoryRef, categoryLevel);

  const listDelimiter = ", ";

  const categories = categoryRef.map(
    categoryRefId => mappedCategories[categoryRefId]
  );
  const significantCategories = categoryIds.map(
    categoryId => mappedCategories[categoryId]
  );
  //mappedCategories[categoryId];

  const categoryNameList = significantCategories
    .map(category => ct(category.name))
    .join(listDelimiter);

  return {
    auditItemCategory: categoryIds.join(listDelimiter) || "id----unknown",
    auditItemCategoryId: categoryIds.join(listDelimiter) || "id----unknown",
    auditItemCategoryName: categoryNameList,
    auditItemCategoryPath: categoryRef.join("/"),
    auditItemCategoryNamePath: categories
      .map(category => {
        return ct(category.name);
      })
      .join(" / "),
  };
}

export interface FlatAuditItemData {
  auditItemId: string;
  auditItemWeight: number;
  auditItemTypeId: string;
  auditItemText: string;
}

export interface FlatFindingData {
  findingId: string;
  findingNote: string;
  findingWeight: number;
  findingTypeId: string;
  findingTypeName: string;
  findingTypeAbbr: string;
  findingTypeDesc: string;
  findingTypeIsDeviation: boolean;
  findingTypeIsApplicable: boolean;
  findingWeightBestPossible: number;
  findingWeightWorstPossible: number;
  findingDimensionId: string;
}

export interface FlatAuditItemFindingScoreData {
  findingsCount: number;
  deviationCount: number;
  isNotApplicable: boolean;
  appreciableFindingValue: number;
  bestScore: number;
  worstScore: number;
  currentScore: number;
}

export interface FlatAuditScoreData
  extends FlatAuditItemData,
    FlatCategoryData,
    FlatAuditItemFindingScoreData {}

export interface FlatAuditItemDataFindingBased
  extends FlatAuditItemData,
    NullablePartial<FlatFindingData>,
    FlatCategoryData {}

export function removeDimensionFormAuditItemsRef(
  auditItemRefs: AuditItemDimensionRef[],
  auditItemId: string,
  dimensionId: string
): AuditItemDimensionRef[] {
  const [relevant, ignored] = partition(
    auditItemRefs,
    auditItemRef => auditItemRef.auditItemId === auditItemId
  );
  const removed = relevant
    .map(auditItemRef => {
      return {
        auditItemId: auditItemRef.auditItemId,
        dimensions:
          auditItemRef.dimensions?.filter(id => id !== dimensionId) ?? null,
      };
    })
    .filter(auditItemRef => auditItemRef.dimensions?.length ?? 1 > 0);

  return [...ignored, ...removed];
}

export function addDimensionFormAuditItemsRef(
  auditItemRefs: AuditItemDimensionRef[],
  possibleDimensions: string[] | null,
  auditItemId: string,
  dimensionId: string
): AuditItemDimensionRef[] {
  const [relevant, ignored] = partition(
    auditItemRefs,
    auditItemRef => auditItemRef.auditItemId === auditItemId
  );

  if (relevant.length > 1) {
    console.warn(
      "Expect only one entry per auditItem",
      auditItemRefs,
      auditItemId
    );
  }

  const mixedDimensions = uniq([
    ...flatten(relevant.map(auditItemRef => auditItemRef.dimensions)),
    dimensionId,
  ]);
  // const dimensions = possibleDimensions === null ? null : uniq([

  return [
    ...ignored,
    {
      auditItemId,
      dimensions: [], // possibleDimensions === null ? possibleDimensions : dimensions
    },
  ];
}

export function flattenAuditItems(
  auditItems: idable<AuditItem>[],
  findings: Dictionary<Finding>,
  auditItemsFindingsMapping: Dictionary<string[]>,

  mappedFindingTypes: FindingTypeMap,
  mappedAuditItemTypes: AuditItemTypeMap,
  mappedCategories: AuditItemCategoryMap,
  categoryLevel: CategoryLevel,
  calcBase: PointsCalculationStrategy
): FlatAuditItemDataFindingBased[] {
  const flattenAuditItemRow = (
    auditItem: idable<AuditItem>
  ): FlatAuditItemDataFindingBased[] => {
    const auditItemId = auditItem.id;
    const auditItemTypeId = auditItem.question.type;
    const auditItemType = mappedAuditItemTypes[auditItemTypeId];

    const flatCategoryData = flattenCategoryRefForExport(
      auditItem.question.categoryRef,
      categoryLevel,
      mappedCategories
    );
    const auditItemFindings = (auditItemsFindingsMapping[auditItemId] ?? [])
      .map(lookupAndMakeIdable(findings))
      .filter(typeIsNotEmpty);

    if (auditItemFindings) {
      const flatRes = map(auditItemFindings, (finding, finding_key) => {
        const findingTypeId = finding.type;

        const finding_type_config = mappedFindingTypes[findingTypeId];
        if (typeof finding_type_config === "undefined") {
          throw createError(
            "invalid finding id",
            findingTypeId,
            mappedFindingTypes,
            finding
          );
        }
        const dimensions = uniq(
          flatten(
            (finding.auditItemRefs ?? [])
              .filter(v => v.auditItemId === auditItemId)
              .map(v => v.dimensions)
          )
        );
        if (dimensions.length === 0) {
          const res: FlatAuditItemDataFindingBased = {
            auditItemId,
            ...flatCategoryData,
            auditItemWeight: auditItemType.value,
            auditItemTypeId,
            auditItemText: ct(auditItem.question.text),
            findingId: finding.id,
            findingNote: ct(finding.text),
            findingWeight: finding_type_config.value,
            findingTypeId,
            findingTypeName: ct(finding_type_config.text),
            findingTypeAbbr: ct(finding_type_config.short),
            findingTypeDesc: ct(finding_type_config.description),
            findingTypeIsDeviation: !finding_type_config.is_no_deviation,
            findingTypeIsApplicable: !finding_type_config.is_not_applicable,
            findingWeightBestPossible: calcBase.best_finding_value,
            findingWeightWorstPossible: calcBase.worst_finding_value,
            findingDimensionId: null,
          };
          return [res];
        } else {
          const dimRes = dimensions.map(findingDimensionId => {
            const res: FlatAuditItemDataFindingBased = {
              auditItemId,
              ...flatCategoryData,
              auditItemWeight: auditItemType.value,
              auditItemTypeId,
              auditItemText: ct(auditItem.question.text),
              findingId: finding.id,
              findingNote: ct(finding.text),
              findingWeight: finding_type_config.value,
              findingTypeId,
              findingTypeName: ct(finding_type_config.text),
              findingTypeAbbr: ct(finding_type_config.short),
              findingTypeDesc: ct(finding_type_config.description),
              findingTypeIsDeviation: !finding_type_config.is_no_deviation,
              findingTypeIsApplicable: !finding_type_config.is_not_applicable,
              findingWeightBestPossible: calcBase.best_finding_value,
              findingWeightWorstPossible: calcBase.worst_finding_value,
              findingDimensionId,
            };
            return res;
          });
          return dimRes;
        }
      });
      return flatten(flatRes);
    } else {
      const res: FlatAuditItemDataFindingBased = {
        auditItemId,
        ...flatCategoryData,
        auditItemWeight: auditItemType.value,
        auditItemTypeId,
        auditItemText: ct(auditItem.question.text),
        findingId: null,
        findingNote: null,
        findingWeight: null,
        findingTypeId: null,
        findingTypeName: null,
        findingTypeAbbr: null,
        findingTypeDesc: null,
        findingTypeIsDeviation: null,
        findingTypeIsApplicable: false,
        findingWeightBestPossible: calcBase.best_finding_value,
        findingWeightWorstPossible: calcBase.worst_finding_value,
        findingDimensionId: null,
      };
      return [res];
    }
  };
  return flatMap(auditItems, flattenAuditItemRow);
}

export const default_flat_export_row = (
  AuditItem: idable<AuditItem>,
  DefaultFindingOrder: number,
  auditItemTypeMap: AuditItemTypeMap,
  categoryLevel: CategoryLevel,
  mappedCategories: AuditItemCategoryMap,
  calcBase: PointsCalculationStrategy
): FlatExportDataRow => {
  const vdaData: Partial<FlatExportVdaData> = {};
  const vda_process = AuditItem.question.vda_process;
  const vda_question_scope = AuditItem.question.vda_question_scope;

  if (
    vda_process instanceof Object &&
    typeof vda_process.name === "string" &&
    typeof vda_process.step === "number"
  ) {
    vdaData.auditItemVdaProcessName = vda_process.name;
    vdaData.auditItemVdaProcessStep = vda_process.step;
  }

  if (typeof vda_question_scope === "string") {
    vdaData.auditItemVdaQuestionScope = vda_question_scope;
  }

  return {
    auditItemNo:
      typeof AuditItem.question.no === "string" ||
      typeof AuditItem.question.no === "number"
        ? AuditItem.question.no
        : "",
    auditItemId: AuditItem.id,
    ...flattenCategoryRefForExport(
      AuditItem.question.categoryRef,
      categoryLevel,
      mappedCategories
    ),
    auditItemWeight: auditItemTypeMap[AuditItem.question.type].value,
    auditItemTypeId: AuditItem.question.type,

    auditItemText: ct(AuditItem.question.text),
    auditItemTextDe: tt2str(AuditItem.question.text, "de"),
    auditItemTextEn: tt2str(AuditItem.question.text, "en"),
    findingDimensionNames: null,
    findingDimensionIds: null,
    findingOrder: DefaultFindingOrder,
    findingIsApplicable: null,
    findingIsDeviation: null,
    findingMaturityValue: null,
    findingId: null,
    findingNote: null,
    findingWeight: null,
    findingTypeId: null,
    findingTypeName: null,
    findingTypeAbbr: null,
    findingTypeDesc: null,
    findingWeightBestPossible: calcBase.best_finding_value,
    findingWeightWorstPossible: calcBase.worst_finding_value,
    measureId: null,
    measureNote: null,
    measureDesc: null,
    measureFullDesc: null,
    measureTypeId: null,
    measureTypeAbbr: null,
    measureTypeName: null,
    measureTypeDesc: null,

    measureCauseAnalysis: null,
    measureDueDate: null,
    measureDueDateLocale: "",
    measureAssignedTo: null,
    measureCreateDate: null,
    measureCreateDateLocale: "",
    measureAuthor: null,
    measureStatus: null,
    measurePriority: null,
    ...vdaData,
  };
};

export function auditItemRefsToIdList(auditItemRefs: Finding["auditItemRefs"]) {
  return uniq(auditItemRefs.map(ref => ref.auditItemId));
}

export function isFindingAssignedToAuditItem(
  auditItemId: string,
  auditDimensions: null | string[],
  auditItemRefs: Finding["auditItemRefs"]
) {
  if (auditDimensions === null || auditDimensions.length === 0) {
    return auditItemRefs.some(ref => ref.auditItemId === auditItemId);
  } else {
    const findingDimensions = flatMap(
      auditItemRefs.filter(ref => ref.auditItemId === auditItemId),
      ref => ref.dimensions ?? []
    );
    return intersection(auditDimensions, findingDimensions).length > 0;
  }
}

const findingDimensionExtractorFactory: FindingDimensionExtractorFactory = (
  rootGetters,
  auditDimensions
) => {
  console.assert(
    auditDimensions === null || auditDimensions.length > 0,
    "Expect AuditDimension length > 0"
  );
  if (auditDimensions === null || auditDimensions.length === 0) {
    return () => null;
  } else {
    const validDimensionIds = new Set(auditDimensions.map(d => d.id));
    const findingsMap = rootGetters[
      getterNs(api, api.getters.getFindingsMap)
    ] as ReturnType<typeof getFindingsMapped>;

    return (auditItemId, findingId) => {
      const finding = findingsMap[findingId];
      if (finding) {
        const dimensionIds = uniq(
          flatten(
            (finding.auditItemRefs ?? [])
              .filter(
                ref =>
                  ref.auditItemId === auditItemId && ref.dimensions !== null
              )
              .map(ref => ref.dimensions ?? [])
          ).filter(dimensionId => validDimensionIds.has(dimensionId))
        );
        return dimensionIds.length === 0 ? null : dimensionIds;
      } else {
        return null;
      }
    };
  }
};

const unansweredAuditItemDimensionsFactory: UnansweredAuditItemDimensionsFactory =
  (rootGetters, auditItemDimensionResolver) => {
    const findings = values(
      rootGetters[getterNs(api, api.getters.getFindingsMap)] as ReturnType<
        typeof getFindingsMapped
      >
    );

    const auditItem2AnsweredDimensionsMap = new Map<string, Set<string>>();
    findings.forEach(finding => {
      const auditItemRefs = finding.auditItemRefs ?? [];
      auditItemRefs.forEach(auditItemRef => {
        const auditItemDimensions = auditItem2AnsweredDimensionsMap.get(
          auditItemRef.auditItemId
        );
        if (auditItemDimensions) {
          const dimensionIds = auditItemRef.dimensions ?? [];
          dimensionIds.forEach(dimensionId => {
            auditItemDimensions.add(dimensionId);
          });
        } else {
          auditItem2AnsweredDimensionsMap.set(
            auditItemRef.auditItemId,
            new Set(auditItemRef.dimensions ?? [])
          );
        }
      });
    });

    return auditItemId => {
      const expectedDimensions = auditItemDimensionResolver(auditItemId);
      if (expectedDimensions === null) {
        return null;
      } else {
        const answeredDimensions =
          auditItem2AnsweredDimensionsMap.get(auditItemId);
        if (answeredDimensions) {
          return expectedDimensions.filter(
            dimensionId => !answeredDimensions.has(dimensionId)
          );
        } else {
          return expectedDimensions;
        }
      }
    };
  };

export const DEFAULT_DIMENSION_MANAGER: DimensionManager = {
  auditDimensionExtractor() {
    return null;
  },
  auditItemDimensionExtractorFactory() {
    return () => null;
  },
  findingDimensionExtractorFactory() {
    return () => null;
  },
  unansweredAuditItemDimensionsFactory() {
    return () => null;
  },
};

export function deriveFilterValueFromStandardId(
  standardId: AuditStandardRef["id"]
): string {
  const STANDARD_BASE_UUID = "57c89c9c-8ce2-4e67-a44b-7b1ed7c92df1";
  return uuidv5(standardId, STANDARD_BASE_UUID);
}

export const STANDARD_DIMENSION_MANAGER: DimensionManager = {
  auditDimensionExtractor(rootGetter) {
    const auditMetadata = rootGetter[
      getterNs(api, api.getters.getAuditMetadata)
    ] as AuditMetadataClient | null;

    const standardRefs = uniqBy(auditMetadata?.standardRefs ?? [], "id"); // Expect standards to by unique
    if (standardRefs.length > 0) {
      return standardRefs.map(ref => {
        return {
          id: deriveFilterValueFromStandardId(ref.id),
          name: ref.name,
        };
      });
    } else {
      return null;
    }
  },
  auditItemDimensionExtractorFactory(rootGetters, auditDimensions) {
    console.assert(
      auditDimensions === null || auditDimensions.length > 0,
      "Expect AuditDimension length > 0"
    );

    if (auditDimensions === null || auditDimensions.length === 0) {
      return () => null;
    } else {
      const auditItemsMap = allPlainAuditItemsMapped(rootGetters);

      const validDimensionIds = new Set(auditDimensions.map(d => d.id));

      return auditItemId => {
        const auditItem = auditItemsMap[auditItemId];
        if (auditItem) {
          const dimensionIds = uniqBy(auditItem.question.chapters, "standardId")
            .map(chapter => deriveFilterValueFromStandardId(chapter.standardId))
            .filter(dimensionId => validDimensionIds.has(dimensionId));

          return dimensionIds.length === 0 ? null : dimensionIds;
        } else {
          return null;
        }
      };
    }
  },
  findingDimensionExtractorFactory,
  unansweredAuditItemDimensionsFactory,
};

const VDA_PROCESS_BASE_UUID = "088537bb-5c20-42e9-9545-e92f37cfaa4a";
export const VDA_PROCESS_DIMENSION_MANAGER: DimensionManager = {
  auditDimensionExtractor(rootGetter) {
    const auditMetadata = rootGetter[
      getterNs(api, api.getters.getAuditMetadata)
    ] as AuditMetadataClient | null;

    const vdaProcesses = auditMetadata?.vda?.processes ?? [];
    if (vdaProcesses.length > 0) {
      return vdaProcesses.map(processName => {
        return {
          id: uuidv5(processName, VDA_PROCESS_BASE_UUID),
          name: processName,
        };
      });
    } else {
      return null;
    }
  },
  auditItemDimensionExtractorFactory(rootGetters, auditDimensions) {
    console.assert(
      auditDimensions === null || auditDimensions.length > 0,
      "Expect AuditDimension length > 0"
    );
    if (auditDimensions === null || auditDimensions.length === 0) {
      return () => null;
    } else {
      const auditItemsMap = allAuditItemsMapped(rootGetters);
      const auditItemsIdWithProcess = new Set(
        toPairs(auditItemsMap)
          .filter(([aiId, ai]) => {
            // Todo:  Besseren kenner für die VDA Prozess Fragen verwenden
            // Vielleicht spezieller AuditItemType
            return /^6\D?/.test(String(ai.question.no));
          })
          .map(([aiId]) => aiId)
      );

      const validDimensionIds = [...new Set(auditDimensions.map(d => d.id))];

      return auditItemId => {
        if (auditItemsIdWithProcess.has(auditItemId)) {
          return validDimensionIds;
        } else {
          return null;
        }
      };
    }
  },
  findingDimensionExtractorFactory,
  unansweredAuditItemDimensionsFactory,
};

export function valueOfAuditItemDimensionRef(ref: AuditItemDimensionRef) {
  if (ref.dimensions) {
    const d = uniq(ref.dimensions);
    d.sort();
    return `${ref.auditItemId}:[${d.join(",")}]`;
  } else {
    return ref.auditItemId;
  }
}

export function isEqualAuditItemDimensionRef(
  lhs: AuditItemDimensionRef,
  rhs: AuditItemDimensionRef
): boolean {
  const ld = lhs.dimensions;
  const rd = rhs.dimensions;
  return (
    lhs.auditItemId === rhs.auditItemId &&
    ((ld === null && rd === null) ||
      size(intersection(ld ?? [], rd ?? [])) ===
        Math.max(size(ld ?? []), size(rd ?? [])))
  );
}
export interface CheckConflictsPayload {
  auditItemId: string;
  auditItemDimensions: null | string[];
  findings: {
    findingId: string;
    findingType: FindingType;
    findingDimensions: null | string[];
  }[];
}
type FindingConflictType =
  | "too_many_answers"
  | "rating_is_in_conflict"
  | "unsupported_dimension"
  | "question_has_no_dimension";

export function checkConflicts({
  auditItemDimensions,
  findings,
}: CheckConflictsPayload): null | FindingConflictType[] {
  const conflicts: FindingConflictType[] = [];
  if (
    auditItemDimensions === null &&
    findings.some(finding => finding.findingDimensions !== null)
  ) {
    conflicts.push("question_has_no_dimension");
  }

  if (
    auditItemDimensions &&
    findings.some(({ findingDimensions }) => {
      if (findingDimensions === null) {
        return true;
      } else {
        return findingDimensions.some(
          findingDimension => !auditItemDimensions.includes(findingDimension)
        );
      }
    })
  ) {
    conflicts.push("unsupported_dimension");
  }

  const findingTypesPerDimension = [
    ...findings
      .reduce((p, c) => {
        const dimensionIds = c.findingDimensions ? c.findingDimensions : [null];
        dimensionIds.forEach(dimensionId => {
          const types = [c.findingType, ...(p.get(dimensionId) ?? [])];
          p.set(dimensionId, types);
        });
        return p;
      }, new Map<string | null, FindingType[]>())
      .values(),
  ];

  if (
    findingTypesPerDimension.some(findingTypes => {
      return (
        findingTypes.length > 1 &&
        findingTypes.some(findingType =>
          BLOCKING_AUDIT_ITEM_STATES.includes(findingType.auditItemState)
        )
      );
    })
  ) {
    conflicts.push("too_many_answers");
  }

  if (
    findingTypesPerDimension.some(findingTypes => {
      if (findingTypes.length > 1) {
        const expectedFindingType = findingTypes[0].is_no_deviation;
        return findingTypes.some(
          findingType => findingType.is_no_deviation !== expectedFindingType
        );
      } else {
        return false;
      }
    })
  ) {
    conflicts.push("rating_is_in_conflict");
  }

  return conflicts.length > 0 ? conflicts : null;
}

export async function updateManuallyConsideredAuditItem(
  auditId: string,
  auditItemIds: Array<AuditItemWithId["id"]>,
  action: "include" | "exclude",
  rootGetters: any,
  context: { db: firebase.firestore.Firestore },
  isInAllowedState: (auditMetadata: AuditMetadataDoc) => boolean,
  addDirectlyToTotalConsidered: boolean = false
) {
  if (addDirectlyToTotalConsidered && action === "exclude") {
    throw new Error(`Exclude not allowed ...`);
  }

  auditItemIds = uniq(auditItemIds);
  const { db } = context;
  const { doc, ref, child } = createAuditPreparationMetadataPath(auditId);

  const manuallyConsideredPath = fieldPath(
    child,
    FieldPartNames.MANUALLY_CONSIDERED_AUDIT_ITEM_IDS
  );
  const auditMetadataDocRef = db.collection(ref).doc(doc);
  const auditResultDocRef = auditMetadataDocRef
    .collection(CollectionNames.PRIVATE)
    .doc(DocumentNames.AUDIT_ITEMS);

  await db.runTransaction(async transaction => {
    const auditMetadata = (
      await transaction.get(auditMetadataDocRef)
    ).data() as AuditMetadataDoc | undefined;
    if (!auditMetadata) {
      throw new Error(`Audit id=${auditId} not found`);
    }
    if (!isInAllowedState) {
      throw new Error(`Manually change considered audit items is not allowed`);
    }

    const auditResult = (await transaction.get(auditResultDocRef)).data() as
      | AuditResultDoc
      | undefined;
    if (!auditResult) {
      throw new Error(`Audit result id=${auditId} not found`);
    }
    const allAuditItemIds = keys(auditResult.auditItems);
    if (
      intersection(allAuditItemIds, auditItemIds).length !== auditItemIds.length
    ) {
      throw new Error(`Found unknown audit item ids`);
    }

    const updateData: firebase.firestore.UpdateData = {};
    const manuallyConsideredAuditItemIds =
      auditMetadata.auditPreparation.manuallyConsideredAuditItemIds;
    const preselectedAuditItemIds = auditMetadata.totalConsideredAuditItemIds;

    const handleIncludeAction = (auditItemId: AuditItemWithId["id"]): void => {
      const isExplicitIncludeRequired =
        !preselectedAuditItemIds.includes(auditItemId);
      if (isExplicitIncludeRequired) {
        updateData[fieldPath(manuallyConsideredPath, auditItemId)] = "include";
      } else if (manuallyConsideredAuditItemIds[auditItemId] === "exclude") {
        updateData[fieldPath(manuallyConsideredPath, auditItemId)] =
          firebase.firestore.FieldValue.delete();
      }
    };
    const handleExcludeAction = (auditItemId: AuditItemWithId["id"]): void => {
      const isExplicitExcludeRequired =
        preselectedAuditItemIds.includes(auditItemId);
      if (isExplicitExcludeRequired) {
        updateData[fieldPath(manuallyConsideredPath, auditItemId)] = "exclude";
      } else if (manuallyConsideredAuditItemIds[auditItemId] === "include") {
        updateData[fieldPath(manuallyConsideredPath, auditItemId)] =
          firebase.firestore.FieldValue.delete();
      }
    };
    auditItemIds.forEach(
      action === "include" ? handleIncludeAction : handleExcludeAction
    );

    if (size(updateData) > 0) {
      if (addDirectlyToTotalConsidered) {
        updateData[FieldPartNames.TOTAL_CONSIDERED_AUDIT_ITEM_IDS] =
          firebase.firestore.FieldValue.arrayUnion(...auditItemIds);
      }
      console.log(
        "updateManuallyConsideredAuditItem",
        addDirectlyToTotalConsidered,
        updateData
      );
      transaction.update(auditMetadataDocRef, {
        ...updateData,
        ...rdu(rootGetters),
      });
    } else {
      console.log("No change required");
    }
  });
}

export async function updateSelfAssessmentEnabledAuditItems(
  auditId: string,
  auditItemIds: Array<AuditItemWithId["id"]>,
  action: "enable" | "disable",
  rootGetters: any,
  context: { db: firebase.firestore.Firestore }
) {
  const { db } = context;
  const { doc, ref } = createAuditMetadataPath(auditId);

  const path = fieldPath(FieldPartNames.SELF_ASSESSMENT_ENABLED_AUDIT_ITEM_IDS);

  const docRef = db.collection(ref).doc(doc);

  return db.runTransaction(async transaction => {
    const doc = await transaction.get(docRef);
    const auditMetadata = doc.data() as AuditMetadataDoc;

    let enabledAuditItemIds = auditMetadata.selfAssessmentEnabledAuditItemIds;

    if (action === "enable") {
      enabledAuditItemIds.push(...auditItemIds);
    }
    if (action === "disable") {
      enabledAuditItemIds = enabledAuditItemIds.filter(
        aID => !auditItemIds.includes(aID)
      );
    }

    const updateData: firebase.firestore.UpdateData = {};
    updateData[path] = [...new Set(enabledAuditItemIds)];

    return transaction.update(docRef, {
      ...updateData,
      ...rdu(rootGetters),
    });
  });
}

/**
 * Either sets the specified tags for the specified auditItemIds
 * or deletes the specified tags for the specified auditItemIds
 */
export async function updateAuditItemsTags(
  auditId: string,
  auditItemIds: Array<AuditItemWithId["id"]>,
  updateConfig:
    | {
        tagsToAdd: string[];
        tagsToRemove: string[];
        action: "update";
      }
    | {
        action: "clear";
      },
  rootGetters: any,
  context: { db: firebase.firestore.Firestore }
) {
  const { db } = context;
  const { doc, ref, child } = createAuditItemsPath(auditId);

  const docRef = db.collection(ref).doc(doc);

  return db.runTransaction(async transaction => {
    const doc = await transaction.get(docRef);
    const auditResultDoc = doc.data() as AuditResultDoc;

    const dbUpdateData: firebase.firestore.UpdateData = {};
    auditItemIds.forEach(auditItemId => {
      if (auditResultDoc.auditItems[auditItemId]) {
        const path = fieldPath(child, auditItemId, FieldPartNames.TAGS);
        if (updateConfig.action === "update") {
          const currentTags = auditResultDoc.auditItems[auditItemId].tags ?? [];
          const newTags = union(currentTags, updateConfig.tagsToAdd).filter(
            v => !updateConfig.tagsToRemove.includes(v)
          );
          dbUpdateData[path] = newTags;
        }
        if (updateConfig.action === "clear") {
          dbUpdateData[path] = [];
        }
      }
    });

    return transaction.update(docRef, {
      ...dbUpdateData,
      ...rdu(rootGetters),
    });
  });
}

/**
 * Creates a new note, deletes an existing note
 * or updates an existing note
 */
export async function updateQuestionNote(
  auditId: string,
  auditItemId: AuditItemWithId["id"],
  action:
    | {
        type: "set";
        text: string;
      }
    | { type: "delete" },
  rootGetters: any,
  context: { db: firebase.firestore.Firestore }
) {
  const { db } = context;
  const { doc, ref } = createQuestionNotesPath(auditId);

  const docRef = db.collection(ref).doc(doc);

  return db.runTransaction(async transaction => {
    const doc = await transaction.get(docRef);
    const auditResultDoc = doc.data() as AuditResultDoc;

    if (!auditResultDoc.questionNotes) {
      auditResultDoc.questionNotes = {};
    }
    if (action.type === "set") {
      const noteIds = noteIdsForAuditItemId(
        auditItemId,
        auditResultDoc.questionNotes
      );

      if (noteIds.length === 0) {
        const newId = uuidv4();
        auditResultDoc.questionNotes[newId] = {
          auditItemIds: [auditItemId],
          text: action.text,
        };
      } else {
        noteIds.forEach(id => {
          if (auditResultDoc.questionNotes) {
            auditResultDoc.questionNotes[id].text = action.text;
          }
        });
      }
    } else if (action.type === "delete") {
      noteIdsForAuditItemId(auditItemId, auditResultDoc.questionNotes).forEach(
        id => {
          if (auditResultDoc.questionNotes) {
            delete auditResultDoc.questionNotes[id];
          }
        }
      );
    }

    return transaction.update(docRef, {
      ...auditResultDoc,
      ...rdu(rootGetters),
    });
  });
}

function noteIdsForAuditItemId(
  auditItemId: AuditItemWithId["id"],
  questionNotes: AuditResultDoc["questionNotes"]
): Array<QuestionNoteWithId["id"]> {
  return Object.entries(questionNotes ?? {})
    .filter(([key, item]) => item.auditItemIds.includes(auditItemId))
    .map(([key]) => key);
}

export function calcPreselectionFilterBasedOnTheAuditMetadata(
  metadata: Partial<AuditMetadataDoc>,
  isMultidimensionAuditWithStandards: boolean
): Filter[] {
  if (isMultidimensionAuditWithStandards) {
    return (metadata.standardRefs ?? []).map(standardRef => {
      const standardId = standardRef.id.trim();
      return {
        aggregationId: FILTER_DIMENSION,
        value:
          standardId === MAGIC_STANDARD_ID_NULL
            ? null
            : deriveFilterValueFromStandardId(standardId),
      };
    });
  } else {
    return [];
  }

  // Todo Preselection Filter basierend auf der Dimension oder ISMS setzen?
}

type TransactionalLoaderFunction<T> = (
  transaction: firebase.firestore.Transaction
) => Promise<T>;

export function buildTransactionalAuditMetadataLoader(
  auditMetadataDocRef: firebase.firestore.DocumentReference
): TransactionalLoaderFunction<AuditMetadataDoc> {
  return async transaction => {
    const auditMetadataDoc = await transaction.get(auditMetadataDocRef);

    const auditMetadata = auditMetadataDoc.data() as
      | undefined
      | AuditMetadataDoc;

    if (!auditMetadata) {
      throw new Error(`${auditMetadataDoc.ref.path} not found`);
    }
    if (!auditMetadata.auditPreparation) {
      console.warn("Audit Metadata in old format ");
    }
    return auditMetadata;
  };
}

export function buildTransactionalAuditResultLoader(
  auditResultDocRef: firebase.firestore.DocumentReference
): TransactionalLoaderFunction<AuditResultDoc> {
  return async transaction => {
    const auditResultDoc = await transaction.get(auditResultDocRef);
    const auditResult = auditResultDoc.data() as undefined | AuditResultDoc;

    if (!auditResult) {
      throw new Error(`${auditResultDoc.ref.path} not found`);
    }

    if (size(auditResult.findings) > 0) {
      throw new Error(`api_errors.audit.template_change_finding_conflict`);
    }
    if (size(auditResult.selfAssessments) > 0) {
      throw new Error(
        `api_errors.audit.template_change_selfassessment_conflict`
      );
    }
    return auditResult;
  };
}

export function buildFilterTreeFromAuditItems(
  getters: any,
  auditItemMap: AuditResultDoc["auditItems"]
): FrontendFilterTree {
  const auditItemAggregators = getters[
    gn.getAuditItemPreselectionAggregators
  ] as ReturnType<typeof buildAuditItemAggegators>;

  const preselectionAggregations = getters[
    gn.getAvailableFiltersForPreselection
  ] as ReturnType<typeof selectAvailableFiltersForList>;

  const filterTree = buildFilterIndexForItems(
    pick(
      auditItemAggregators,
      preselectionAggregations.map(value => value.id)
    ),
    auditItemMap,
    preselectionAggregations
  );

  // Remove lables if only the null bucket is in the index
  const cleanedFilterTree = [...filterTree.entries()].filter(
    ([aggreagationId, aggregation]) => {
      if (aggreagationId === FILTER_LABELS) {
        return aggregation.buckets.size > 1;
      } else {
        return true;
      }
    }
  );

  return new Map(cleanedFilterTree);
}

export function isVdaAuditClass(
  auditClass: AuditClassConfig,
  mappedAuditItemTypes: AuditItemTypeMap
) {
  const isVdaAudit = !!auditClass && !!auditClass.vdaConfig;
  if (isVdaAudit) {
    const auditItemType =
      mappedAuditItemTypes[MAGIC_VDA_STAR_AUDIT_ITEM_TYPE_ID];
    console.assert(
      typeof auditItemType !== "undefined",
      `Expect valid VDA auditItemTypes, Expect ID for Starquestions to be ${MAGIC_VDA_STAR_AUDIT_ITEM_TYPE_ID}`
    );

    if (auditItemType) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function disableEmptyFilters({
  commit,
  rootGetters,
}: ActionContext<unknown, RootState>) {
  const activeFilters = rootGetters[
    getterNs(api, api.getters.getActiveFrontendFilter)
  ] as Filter[];

  const filterTree = rootGetters[
    getterNs(api, api.getters.getFilterTreeForFrontendFiltering)
  ] as FrontendFilterTree;

  // Solution to ACS-2414: Filters may be active even though they have
  // no results. Here, we remove those from the active filters.
  const activeNonEmptyFilters = activeFilters.filter(
    filter =>
      filterTree.get(filter.aggregationId)?.buckets.has(filter.value) ?? false
  );
  commit(mutationNs(api, api.mutations.SET_FILTERS), activeNonEmptyFilters, {
    root: true,
  });
}
