









import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { State } from "@auditcloud/shared/lib/workflow/types/State";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";

@Component({})
export default class AMeasureProcessStepPreviewGeneric extends Vue {
  @Prop({
    type: Object,
  })
  readonly state!: State<MeasureProcessDocument> | null;
}
