



















import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { State, Action, Getter, Mutation } from "vuex-class";
import {
  TodoMap,
  TodoArray,
  TodoAny,
  TodoActionSignature,
  TodoMutationSignature,
} from "@auditcloud/shared/lib/utils/type-guards";

@Component({})
export default class DownloadButton extends Vue {
  @Prop({
    type: String,
    default: null,
  })
  title!: string;
  @Prop({
    type: Function,
    required: true,
  })
  action!: TodoAny;
  @Prop({
    type: String,
    required: true,
  })
  filename!: string;
  @Prop({
    type: String,
    default: "secondary",
  })
  color!: string;

  @Watch("action", {
    deep: true,
    immediate: true,
  })
  onWatchAction() {
    console.log("DownloadButton action Changed");
    this.resetDownload();
  }

  downloadBlob: any = null;
  blobUrl: any = null;
  loading: boolean = false;

  get btnIcon() {
    return this.blobUrl ? "cloud_done" : "cloud_download";
  }

  download() {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = this.blobUrl;
    a.download = this.filename;
    a.click();
    document.body.removeChild(a);
  }
  resetDownload() {
    if (this.blobUrl) {
      window.URL.revokeObjectURL(this.blobUrl);
    }
    this.blobUrl = null;
    this.downloadBlob = null;
  }
  onResult(Blob) {
    const url = window.URL.createObjectURL(Blob);
    this.downloadBlob = Blob;
    this.blobUrl = url;
    this.loading = false;
    console.log("onResult", this.blobUrl);
    this.download();
  }
  onError(Err) {
    console.log("onError", Err);
    this.loading = false;
    this.$emit("download-error", "Download failed");
  }
  runAction() {
    if (!this.loading) {
      if (this.blobUrl) {
        this.download();
      } else {
        this.loading = true;
        this.action().then(this.onResult).catch(this.onError);
      }
    }
  }
}
