import firebase from "firebase/compat/app";
import { ActionTree, ActionContext } from "vuex";
import { RootState } from "../../types";
import { CurrentMeasureProcessState, Signature } from "./types";
import { mutationNames as mn } from "./mutations";
import { getterNs } from "@/utils/VuexHelper";
import { TodoAny } from "@auditcloud/shared/lib/utils/type-guards";
import { api as usersApi } from "@/store/modules/users";

import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";
import { measureProcessApi } from "./MeasureProcessApi";
import { Attachment } from "@/components/types";
import { createError } from "@/utils/Errors";

const setMeasureProcess = "setMeasureProcess";
const clearMeasureProcess = "clearMeasureProcess";
const sendComment = "sendComment";
const startStep = "startStep";
const startDirectMeasure = "startDirectMeasure";
const finalizeDirectMeasure = "finalizeDirectMeasure";
const discardDirectMeasure = "discardDirectMeasure";
const verifyDirectMeasure = "verifyDirectMeasure";

const n = {
  setMeasureProcess,
  clearMeasureProcess,
  sendComment,
  startStep,
  startDirectMeasure,
  finalizeDirectMeasure,
  discardDirectMeasure,
  verifyDirectMeasure,
};

const actions: ActionTree<CurrentMeasureProcessState, RootState> = {
  async [n.setMeasureProcess](context, measureProcessId: string) {
    context.commit(mn.SET_MEASURE_PROCESS, measureProcessId);
    const measureProcessEntry = await measureProcessApi.loadMeasuresProcess(
      measureProcessId
    );
    context.commit(mn.SET_MEASURE_METADATA, measureProcessEntry);
    const steps = await measureProcessApi.loadSteps(measureProcessId);
    context.commit(mn.SET_MEASURE_PROCESS_STEPS, steps);
    const activities = await measureProcessApi.loadActivities(measureProcessId);
    context.commit(mn.SET_LAST_ACTIVITIES, activities);
  },
  [n.clearMeasureProcess](context) {
    context.commit(mn.CLEAR_MEASURE_PROCESS);
  },
  [n.sendComment](
    context,
    payload: {
      comment: string;
      files: Attachment[];
    }
  ) {
    console.log("sendComment", payload);
    throw createError("Not yet implemented");
    /*
    return new Promise<void>((resolve, reject) => {
      const user = context.rootGetters[
        getterNs(usersApi, usersApi.getters.user)
      ] as IUserRef | null;
      if (user) {
        window.setTimeout(() => {
          context.commit(
            mn.APPEND_ACTIVITIES,
            [
              {
                "@context": "https://www.w3.org/ns/activitystreams",
                summary: user.displayName + " created a comment",
                published: new Date().toISOString(),
                type: "AddComment",
                actor: {
                  type: "Person",
                  id: user.id,
                  name: user.displayName
                },
                object: {
                  type: "Note",
                  name: "A Simple Note",
                  content: payload.comment
                },
                target: {
                  type: "Link",
                  name: "Ursachenanalyse",
                  url:
                    "http://example.com/measureprocess/{processId}/step/{stepNo}"
                }
              }
            ]
              .map(activityStream2MeasureActivity)
              .filter(v => v !== null)
          );
          resolve();
        }, 500);
      } else {
        reject(new Error("invalid current user"));
      }
    });*/
  },
  async [n.startStep](context, stepId: string) {
    const measureProcessId = context.state.id;
    if (measureProcessId) {
      measureProcessApi.startStep(stepId);
      const steps = await measureProcessApi.loadSteps(measureProcessId);
      context.commit(mn.SET_MEASURE_PROCESS_STEPS, steps);
    }
  },
  async [n.startDirectMeasure](
    context,
    payload: {
      stepId: string;
      dueDate: string;
      responsible: IUserRef;
      description: string;
    }
  ) {
    const measureProcessId = context.state.id;
    if (measureProcessId) {
      measureProcessApi.startStartDirectMeasure(
        payload.stepId,
        payload.dueDate,
        payload.responsible,
        payload.description
      );
      const steps = await measureProcessApi.loadSteps(measureProcessId);
      context.commit(mn.SET_MEASURE_PROCESS_STEPS, steps);
    }
  },
  async [n.finalizeDirectMeasure](
    context,
    payload: {
      stepId: string;
    }
  ) {
    const measureProcessId = context.state.id;
    if (measureProcessId) {
      measureProcessApi.finalizeStartDirectMeasure(payload.stepId);
      const steps = await measureProcessApi.loadSteps(measureProcessId);
      context.commit(mn.SET_MEASURE_PROCESS_STEPS, steps);
    }
  },
  async [n.discardDirectMeasure](context, stepId: string) {
    const measureProcessId = context.state.id;
    if (measureProcessId) {
      measureProcessApi.startStep(stepId);
      const steps = await measureProcessApi.loadSteps(measureProcessId);
      context.commit(mn.SET_MEASURE_PROCESS_STEPS, steps);
    }
  },
  async [n.verifyDirectMeasure](
    context,
    payload: {
      stepId: string;
      signature: Signature;
      comment: string;
    }
  ) {
    const measureProcessId = context.state.id;
    if (measureProcessId) {
      measureProcessApi.verifyStartDirectMeasure(
        payload.stepId,
        payload.signature,
        payload.comment
      );
      const steps = await measureProcessApi.loadSteps(measureProcessId);
      context.commit(mn.SET_MEASURE_PROCESS_STEPS, steps);
    }
  },
};

export { n as actionNames, actions };
