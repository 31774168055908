





























































































































































































import { api } from "@/store/modules/users";
import { Getter } from "vuex-class";
import AUserSnippetBase from "@/components/snippets/AUserSnippetBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";
import { UserInfo, UserInfoResolver } from "@/types/User";
import { TranslateableText } from "@auditcloud/shared/lib/schemas";
import AUserAutocompleteMixin from "@/components/mixins/AUserAutocompleteMixin.vue";
import AUserAutocompleteCreateDialog from "@/components/controls/AUserAutocompleteCreateDialog.vue";
import { pick } from "lodash";
@Component({
  components: {
    AUserSnippetBase,
    AUserAutocompleteCreateDialog,
  },
})
export default class AUserSnippet extends AUserAutocompleteMixin {
  userSelectMenu: boolean = false;
  userDialogVisible: boolean = false;
  /** helper value, to reset autocomplete UI after canceling the selection */
  uiResetKey: boolean = false;

  onSelectionChange(user: IUserRef | null) {
    const cleanupUserRef = (val: any) => {
      return pick(val, ["displayName", "id"]);
    };
    const preparedUser = user ? cleanupUserRef(user) : null;
    const editable = this.editable;
    if (editable) {
      if (
        this.confirmationMessage &&
        !window.confirm(this.confirmationMessage)
      ) {
        // allow repeated selection of this user to be detected:
        this.uiResetKey = !this.uiResetKey;
        return;
      }
      this.$emit("input", preparedUser);
    }
  }

  selectNewUser(user: IUserRef | null): void {
    this.search = "";
    this.onSelectionChange(user);
  }
  @Watch("userSelectMenu")
  onUserSelectMenuChange(newVal: boolean, oldVal: boolean) {
    if (newVal) {
      this.setFocus("user-autocomplete");
    }
  }

  @Watch("search", {
    immediate: true,
  })
  onSearch(newVal: string, oldVal: string) {
    newVal && this.queryUsers(newVal);
  }

  setFocus(elementRef: string) {
    setTimeout(() => {
      const el = this.$refs[elementRef] as any;
      el.focus();
      el.activateMenu();
    }, 100);
  }
  @Prop({ type: [String, Object] })
  readonly userInput!: IUserRef | string | null;

  @Prop({ type: Array })
  readonly users!: IUserRef[];

  @Prop({ type: [String, Object] })
  readonly contentClass!: string | object;

  @Prop({ type: [Number, String], default: 1000 })
  readonly openDelay!: number | string;

  @Prop({ type: Boolean, default: false })
  readonly noUserCard!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly allowNotAssigned!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly trailingComma!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly chip!: boolean;

  @Prop({ type: String })
  readonly prependIcon!: string;

  @Prop({ type: String })
  readonly prependIconColor!: string;

  @Prop({ type: String })
  readonly appendIcon!: string;

  @Prop({ type: Boolean, default: false })
  readonly editable!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly dark!: boolean;

  @Prop({ type: [String], default: null })
  readonly confirmationMessage!: string | null;

  @Prop({ type: Object })
  readonly noUserPlaceholder!: TranslateableText;

  @Prop({ type: Boolean, default: false })
  readonly small!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly inline!: boolean;

  @Prop({ type: String, default: "" })
  readonly snippetClass!: string;

  @Getter(api.getters.getUser, { namespace: api.namespace })
  getUser!: UserInfoResolver;

  get inputIsRefUser(): boolean {
    return (
      this.userInput instanceof Object &&
      typeof this.userInput.id === "string" &&
      typeof this.userInput.displayName === "string"
    );
  }

  get userId() {
    if (this.userInput instanceof Object) {
      return this.userInput.id;
    } else {
      return this.userInput;
    }
  }

  get userDisplayName() {
    return this.userInfo?.displayName;
  }

  get userEmail() {
    return this.userInfo?.email;
  }

  get userInfo(): UserInfo | null {
    if (!this.userId) {
      return null;
    }
    return this.getUser(this.userId);
  }

  provideUserEmail(user: IUserRef) {
    return this.getUser(user.id)?.email ?? null;
  }
}
