




























import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import {
  isString,
  isFunction,
  isMap,
  isPlainObject,
  omit,
  take,
  isArray,
} from "lodash";
import { typeIsArrayOf } from "@auditcloud/shared/lib/utils/type-guards";
import {
  ConfigurableGetter,
  getterPath,
  I18nInline,
  I18nKey,
} from "@auditcloud/shared/lib/types/common";
import {
  CustomMetadataTreeEntry,
  CustomMetadataNested,
  typeIsCustomMetadataNested,
} from "@auditcloud/shared/lib/types/CustomMetadata";
import { typeIsNotEmpty } from "@auditcloud/shared/lib/utils/filter/typeIsNotEmpty";
import { CustomMetadataEntry } from "@auditcloud/shared/lib/schemas";
import { typeIsCustomMetadataEntry } from "@auditcloud/shared/lib/schemas/type-guards";

@Component({
  name: "AVuexSelectNested",
})
export default class AVuexSelectNested extends Vue {
  xValue: (string | null)[] = [null];

  @Prop({
    validator: (val: any): val is CustomMetadataEntry[] | null =>
      val === null || typeIsArrayOf(val, typeIsCustomMetadataEntry),
    default: null,
  })
  value!: CustomMetadataEntry[] | null;

  get top() {
    return this.xValue[this.xValue.length - 1];
  }

  get beginAttrs() {
    return omit(this.defaultedAttrs, [
      "error",
      "error-messages",
      "error-count",
    ]);
  }

  get defaultedAttrs() {
    return {
      ...this.$attrs,
      "return-object": false,
      multiple: false,
    };
  }

  get xValueBegin() {
    return take(this.xValue, this.xValue.length - 1);
  }

  @Prop({
    type: String,
    required: true,
  })
  label!: string;

  @Prop({
    type: Boolean,
    default: true,
  })
  selectLeaf!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Object,
    required: true,
  })
  itemsGetter!: ConfigurableGetter;

  @Watch("value", {
    immediate: true,
    deep: true,
  })
  onValueChanged(newValue: CustomMetadataEntry[] | null) {
    let isOpen = true;
    const enrichedVal: string[] = [];

    if (newValue !== null) {
      newValue.forEach(({ id }, idx) => {
        const items = this.items(idx);

        const entry = items.find(c => c.id === id);

        if (entry) {
          enrichedVal.push(id);
          isOpen = isOpen && entry.children.length > 0;
        } else {
          enrichedVal.splice(idx);
        }
      });

      const placeholder = isOpen ? [null] : [];
      this.xValue = [...enrichedVal, ...placeholder];
    }
  }

  changed(idx: number, id: string) {
    const newVal = [...this.xValue].filter(isString);
    newVal.splice(idx);
    newVal.push(id);

    const entries = this.enrichedValue(newVal);
    this.onValueChanged(entries);
    if ((this.selectLeaf && !this.xValue.includes(null)) || !this.selectLeaf) {
      this.$emit(
        "input",
        entries.map(v => omit(v, "children"))
      );
    } else {
      this.$emit("input", null);
    }
  }

  enrichedValue(selection: string[]) {
    let items = this.baseItems;
    return selection
      .map((id, idx) => {
        const entry = items.find(i => i.id === id);
        if (entry) {
          items = entry.children;
          return entry;
        } else {
          items = [];
          return null;
        }
      })
      .filter(typeIsNotEmpty);
  }

  get getterResult() {
    const path = getterPath(this.itemsGetter);
    const getterResult: any = this.$store.getters[path];

    return getterResult;
  }

  get getterData(): CustomMetadataNested | null {
    const getterResult = this.getterResult;
    const id = this.itemsGetter.id;
    if (typeIsCustomMetadataNested(getterResult)) {
      return getterResult;
    } else if (typeof id === "string") {
      let result: CustomMetadataNested | null = null;
      if (isFunction(getterResult)) {
        result = getterResult(id);
      } else if (isMap(getterResult)) {
        result = getterResult.get(id);
      } else if (isPlainObject(getterResult)) {
        result = getterResult[id];
      }
      console.assert(
        typeIsCustomMetadataNested(result),
        `expect getter Result to be an array`
      );
      return typeIsCustomMetadataNested(result) ? result : null;
    } else {
      console.error("invalid items getter", this.itemsGetter);
      return null;
    }
  }

  get baseItems(): CustomMetadataTreeEntry[] {
    return this.getterData?.items ?? [];
  }

  get levelNames(): null | Array<string | I18nInline | I18nKey> {
    return this.getterData?.levelNames ?? null;
  }

  levelName(idx: number) {
    const levelNames = this.levelNames;
    if (isArray(levelNames) && levelNames.length > idx) {
      return this.$ft(levelNames[idx]);
    } else {
      return `${this.label} ${idx}`;
    }
  }

  items(idx: number): CustomMetadataTreeEntry[] {
    // getterData
    const id = this.xValue[idx - 1];

    let items = this.baseItems;

    this.xValue.forEach((entryId, cidx) => {
      if (cidx < idx) {
        const c = items.find(v => v.id === entryId);
        if (c) {
          items = c.children;
        } else {
          items = [];
        }
      }
    });

    return items;
  }
}
