





































































































































































































































import Component from "vue-class-component";
import { Prop, Watch, Mixins } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

import { api } from "@/store/modules/template";
import { api as standardsApi } from "@/store/modules/standards";
import { api as configApi } from "@/store/modules/configuration";

import FormsControlMixin from "@/components/mixins/FormsControlMixin.vue";
import AAuditItemTypeSelect from "@/components/controls/AAuditItemTypeSelect.vue";
import ACategorySelect from "@/components/controls/ACategorySelect.vue";
import AAuditItemNumberInput from "@/components/controls/AAuditItemNumberInput.vue";
import ATranslateableTextInput from "@/components/controls/ATranslateableTextInput.vue";
import AStandardChip from "@/components/snippets/AStandardChip.vue";
import AWeblink from "@/components/snippets/AWeblink.vue";
import AWebLinkInput from "@/components/controls/AWebLinkInput.vue";
import ARequirementInput from "@/components/controls/ARequirementInput.vue";
import AInlineLabelInput from "@/components/controls/AInlineLabelInput.vue";

import {
  AuditItem,
  ChapterRefType,
  AuditProof,
  WebLink,
} from "@auditcloud/shared/lib/schemas";
import { idable } from "@auditcloud/shared/lib/types/common";
import { TemplateItemStoragePayload } from "@/store/modules/template/types";
import { isNumber } from "lodash";
import { createPayloadForMovingTemplateItems } from "@/store/modules/template/utils";
import DialogLayout from "../layouts/BaseLayouts/DialogLayout.vue";
import { Result } from "neverthrow";

@Component({
  components: {
    AAuditItemTypeSelect,
    ACategorySelect,
    AAuditItemNumberInput,
    ATranslateableTextInput,
    AStandardChip,
    AWeblink,
    AWebLinkInput,
    ARequirementInput,
    AInlineLabelInput,
    DialogLayout,
  },
  mixins: [FormsControlMixin],
})
export default class TemplateItemDialog extends Mixins(FormsControlMixin) {
  selectedProofItems = [];
  selectedChapterItems = [];
  selectedLinkItems = [];
  dialog: boolean = true;
  loading: boolean = true;
  questionNumberValid: boolean = true;
  creatingNewItem: boolean = true;
  error: string = "";

  @Prop({ type: String, default: null })
  templateItemId!: string | null;

  @Getter(api.getters.getCurrentTemplateItem, {
    namespace: api.namespace,
  })
  currentTemplateItem!: null | idable<AuditItem>;

  @Getter(api.getters.getStandardIds, {
    namespace: api.namespace,
  })
  templateStandardIds!: string[];

  @Getter(standardsApi.getters.getChapterListForStandard, {
    namespace: standardsApi.namespace,
  })
  chapterListForStandard!: (standardId: string) => ChapterRefType[];

  @Watch("currentTemplateItem", { immediate: true, deep: true })
  onCurrentTemplateItemChanged(newVal: null | idable<AuditItem>) {
    if (newVal === null) {
      this.mixinInitFormData(this.emptyAuditItem());
      this.creatingNewItem = true;
    } else {
      this.mixinInitFormData(newVal);
      this.creatingNewItem = false;
    }
  }

  @Getter(api.getters.getAuditClassId, {
    namespace: api.namespace,
  })
  auditClassId!: null | string;

  @Getter(api.getters.getCategorySetId, {
    namespace: api.namespace,
  })
  categorySetId!: null | string;

  @Getter(api.getters.getTemplateItemsNoTypeIsNumber, {
    namespace: api.namespace,
  })
  itemsNoTypeIsNumber!: boolean;

  @Getter(api.getters.getAuditItemsNumbers, { namespace: api.namespace })
  auditItemsNumbers!: (string | number)[];

  @Getter(configApi.getters.selfAssessmentFeatureEnabled, {
    namespace: configApi.namespace,
  })
  selfAssessmentFeatureEnabled!: boolean;

  @Getter(api.getters.getVdaScopeSelectable, {
    namespace: api.namespace,
  })
  vdaScopeSelectable!: boolean;

  @Action(api.actions.setCurrentItemTemplateId, { namespace: api.namespace })
  setCurrentItemTemplateId!: (templateItemId: string | null) => Promise<void>;

  @Action(api.actions.storeTemplateItem, { namespace: api.namespace })
  storeTemplateItem!: (
    data: TemplateItemStoragePayload
  ) => Promise<Result<string, string>>;

  get auditItemCount() {
    return this.auditItemsNumbers.length;
  }

  get valid() {
    return (
      this.mixinFormData.question.type.length === 36 &&
      this.mixinFormData.question.categoryRef.length >= 1 &&
      this.questionNumberValid
    );
  }

  get itemsChapter(): ChapterRefType[] {
    const chapters: ChapterRefType[] = [];
    this.templateStandardIds.forEach(standardId => {
      chapters.push(...this.chapterListForStandard(standardId));
    });

    return chapters;
  }

  get currentSavedNumber() {
    return this.currentTemplateItem?.question.no;
  }

  get requiredProofs(): AuditProof[] {
    return this.mixinFormData.question.requiredProofs ?? [];
  }
  set requiredProofs(items: AuditProof[]) {
    this.$set(this.mixinFormData.question, "requiredProofs", items);
  }

  get labels(): string[] {
    return this.mixinFormData.labels ?? [];
  }
  set labels(items: string[]) {
    this.$set(this.mixinFormData, "labels", items);
  }

  get itemsLinks() {
    return this.mixinFormData.question.links ?? [];
  }

  get questionNumberInputDisabled() {
    return this.loading || (this.creatingNewItem && this.itemsNoTypeIsNumber);
  }

  set itemsLinks(links: WebLink[]) {
    this.$set(this.mixinFormData.question, "links", links);
  }

  get vdaQuestionScopes() {
    if (this.vdaScopeSelectable) {
      return ["Prozess", "Produkt"];
    } else {
      return null;
    }
  }

  get vdaQuestionScope(): AuditItem["question"]["vda_question_scope"] {
    return this.mixinFormData?.question.vda_question_scope;
  }

  set vdaQuestionScope(val: AuditItem["question"]["vda_question_scope"]) {
    if (val === "Prozess" || val === "Produkt") {
      this.$set(this.mixinFormData.question, "vda_question_scope", val);
    } else {
      this.$delete(this.mixinFormData.question, "vda_question_scope");
    }
  }

  created() {
    console.log("AuditItemDialog:created", this.templateItemId);
  }

  mounted() {
    console.log("AuditItemDialog:mounted", this.templateItemId);
  }

  beforeDestroy() {
    console.log("AuditItemDialog:beforeDestroy", this.templateItemId);
  }

  @Watch("templateItemId", { immediate: true })
  async onTemplateItemIdChanged(newVal: string | null, oldVal: string | null) {
    console.log("onTemplateItemIdChanged", newVal, oldVal);
    if (newVal !== oldVal) {
      this.loading = true;
      try {
        await this.setCurrentItemTemplateId(newVal);
      } catch (e) {
        console.error("load template item failed", e);
      }
      this.loading = false;
    }
  }

  validityChange(valid: boolean) {
    this.questionNumberValid = valid;
  }

  emptyAuditItem(): idable<AuditItem> {
    const requiredProofs: AuditProof[] = [];
    const labels: string[] = [];

    return {
      id: "",
      labels,
      question: {
        no: this.itemsNoTypeIsNumber ? this.auditItemCount + 1 : "",
        text: "",
        categoryRef: [],
        chapters: [],
        description: "",
        requiredProofs,
        hint: "",
        type: "",
        links: [],
      },
    };
  }

  async save() {
    this.loading = true;

    const oldNumber = this.currentSavedNumber;
    const newNumber = this.mixinFormData.question.no;

    const itemToStorePayload: TemplateItemStoragePayload = {
      item: this.mixinFormData,
    };
    if (
      this.itemsNoTypeIsNumber &&
      isNumber(oldNumber) &&
      isNumber(newNumber) &&
      newNumber !== oldNumber
    ) {
      itemToStorePayload.itemNumberModification =
        createPayloadForMovingTemplateItems(newNumber, oldNumber);
    }

    this.storeTemplateItem(itemToStorePayload)
      .then(templateItemIdResult => {
        if (templateItemIdResult.isOk()) {
          console.log(
            "storeTemplateItem:done",
            templateItemIdResult.value,
            this.mixinFormData
          );
          this.mixinInitFormData(this.mixinFormData);
          this.closeDialog();
        } else {
          this.error = templateItemIdResult.error;
        }
      })
      .catch(err => {
        console.log("storeTemplateItem:failed", err);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  closeDialog() {
    setTimeout(() => {
      this.$router.back();
    }, 250);
  }

  removeChapter(item2delete: ChapterRefType) {
    this.mixinFormData.question.chapters =
      this.mixinFormData.question.chapters.filter(chapterRef => {
        return !(
          chapterRef.standardId === item2delete.standardId &&
          chapterRef.chapterId === item2delete.chapterId
        );
      });
  }
}
