


























import { Component, Prop, Vue } from "vue-property-decorator";

import AUserSnippet from "@/components/snippets/AUserSnippet.vue";
import ADateSnippet from "@/components/snippets/ADateSnippet.vue";
import AAuditTransitionWarning from "@/components/widgets/AAuditTransitionWarning.vue";
import {
  MeasureProcessStep,
  TranslateableText,
  UserRef,
} from "@auditcloud/shared/lib/schemas";
import { Workflow } from "@auditcloud/shared/lib/workflow/types/Workflow";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import { Transition } from "@auditcloud/shared/lib/workflow/types/Transition";

@Component({
  name: "AMeasureProcessStepTransitionInfo",
  components: {
    AAuditTransitionWarning,
    AUserSnippet,
    ADateSnippet,
  },
})
export default class AMeasureProcessStepTransitionInfo extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  measureWorkflow!: Workflow<MeasureProcessDocument>;

  @Prop({
    type: Object,
    required: true,
  })
  nextStep!: MeasureProcessStep;

  @Prop({
    type: Boolean,
    default: false,
  })
  showIcon!: boolean;

  get closedBy(): UserRef {
    return this.nextStep.workflowInfo.creatorRef;
  }

  get closedAt(): string {
    return this.nextStep.workflowInfo.enteredAt;
  }

  get transitionConfig(): Transition<MeasureProcessDocument> | null {
    const transitionId = this.nextStep.workflowInfo.transitionId;
    if (transitionId !== null) {
      const transitionConfig =
        this.measureWorkflow.transitions.get(transitionId);
      return transitionConfig ?? null;
    }
    return null;
  }

  get isTransitionBackward(): boolean {
    return this.transitionConfig?.back ?? false;
  }

  get transitionNameDone(): TranslateableText | null {
    return this.transitionConfig?.nameDone ?? null;
  }
}
