






















import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import AFeatureNotAvailable from "@/components/widgets/AFeatureNotAvailable.vue";

@Component({ components: { AFeatureNotAvailable } })
export default class AFeatureNotAvailableDialog extends Vue {
  @Prop({
    default: false,
    type: Boolean,
  })
  readonly value!: boolean;

  set dialog(val: boolean) {
    this.$emit("input", val);
  }

  get dialog(): boolean {
    return this.value;
  }
}
