


















import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import _ from "lodash";

@Component({
  name: "AOptionalDatepicker",
})
export default class AOptionalDatepicker extends Vue {
  xValue: string | boolean = false;
  @Prop({
    type: [String, Boolean],
    default: false,
  })
  value!: string | boolean;

  @Prop({
    type: String,
    default: "Datum auswählen",
  })
  enableLabel!: string;

  @Prop({
    type: String,
    default: "Datum",
  })
  label!: string;

  get checked() {
    return this.xValue !== false;
  }

  get dateValue() {
    return typeof this.xValue === "string" ? this.xValue : null;
  }

  @Watch("value", { deep: true, immediate: true })
  onValueChanged(newVal: string | boolean) {
    console.log("onValueChanged", newVal);
    this.xValue = newVal;
  }

  onChange(value: boolean) {
    console.log("onChange", value);
    this.xValue = value;
    this.$emit("input", value);
  }

  dateChanged(value: string | null) {
    if (typeof value === "string") {
      this.xValue = value;
      this.$emit("input", value);
    }
  }

  created() {
    console.log("AOptionalDatepicker", this.$attrs);
  }
}
