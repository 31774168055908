import {
  MOCK_MEASURE_PROCESSES,
  MOCK_MEASURE_ACTIVITIES,
  MOCK_MEASURE_STEPS,
  MeasureProcessEntry,
} from "./mockData";
import Vue from "vue";
import {
  MeasureProcessStepQuestion,
  MeasureProcessStepFinding,
  MeasureProcessStepDirectMeasure,
  StepState,
  MeasureProcessStep,
  Signature,
  SignatureType,
} from "./types";
import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";
import _ from "lodash";
import {
  MeasureActivity,
  MeasureActivityComment,
  MeasureActivityAssign,
  MeasureActivityAddAttachment,
  MeasureActivityUpdate,
  MeasureActivityAccept,
  MeasureActivityReject,
  MeasureActivityAddTask,
} from "@/types/measure-activities";
import { TodoArray } from "@auditcloud/shared/lib/utils/type-guards";
import { v4 as uuidv4 } from "uuid";

function startasync<T>(fn: (...args: any[]) => T, ...args: any[]) {
  return new Promise<T>((resolve, reject) => {
    const timeout = 500 + Math.random() * 1500;
    console.log(`Start Async in ${timeout}ms: `, fn, args);
    window.setTimeout(() => {
      try {
        console.log(`Running Async after ${timeout}ms: `, fn, args);
        resolve(fn(...args));
      } catch (err) {
        console.error(`Running Async failed:`, fn, args, err);
        reject(err);
      }
    }, timeout);
  });
}

function parseUserRef(data: any): IUserRef {
  if (
    data instanceof Object &&
    data.type === "Person" &&
    typeof data.name === "string" &&
    typeof data.id === "string"
  ) {
    return {
      id: data.id,
      displayName: data.name,
    };
  } else {
    console.error("activity stream invalid Person", data);

    throw new Error("parseActivityStream/InvalidPerson");
  }
}

function parsePublished(data: any): Date {
  const published = new Date(data.published);
  if (isNaN(published.valueOf())) {
    console.error("activity stream invalid published date", data, published);

    throw new Error("parseActivityStream/InvalidPublishDate");
  } else {
    return published;
  }
}

const activityStreamTypeMap: {
  [typeName: string]: (data: any) => MeasureActivity;
} = {
  AddComment: (data: any) => {
    const published = parsePublished(data);

    const content =
      data.object && typeof data.object.content === "string"
        ? data.object.content
        : null;

    if (content === null) {
      console.error("activity stream invalid content", data, data.object);

      throw new Error("parseActivityStream/InvalidObjectContent");
    }

    const actor = parseUserRef(data.actor);
    return new MeasureActivityComment(
      published,
      data.summaryMap || data.summary,
      actor,
      content
    );
  },
  Assign: (data: any) => {
    const published = parsePublished(data);
    const actor = parseUserRef(data.actor);
    const object = parseUserRef(data.object);

    const target =
      data.target && typeof data.target.name === "string"
        ? data.target.name
        : null;

    if (target === null) {
      console.error("activity stream invalid target", data, data.object);

      throw new Error("parseActivityStream/InvalidTargetName");
    }

    return new MeasureActivityAssign(
      published,
      data.summaryMap || data.summary,
      actor,
      object,
      target
    );
  },
  AddAttachment: (data: any) => {
    const published = parsePublished(data);
    const actor = parseUserRef(data.actor);

    return new MeasureActivityAddAttachment(
      published,
      data.summaryMap || data.summary,
      actor,
      data.object,
      data.target
    );
  },
  Update: (data: any) => {
    const published = parsePublished(data);
    const actor = parseUserRef(data.actor);

    return new MeasureActivityUpdate(
      published,
      data.summaryMap || data.summary,
      actor,
      data.object
    );
  },
  Accept: (data: any) => {
    const published = parsePublished(data);
    const actor = parseUserRef(data.actor);

    return new MeasureActivityAccept(
      published,
      data.summaryMap || data.summary,
      actor,
      data.object
    );
  },
  Reject: (data: any) => {
    const published = parsePublished(data);
    const actor = parseUserRef(data.actor);

    return new MeasureActivityReject(
      published,
      data.summaryMap || data.summary,
      actor,
      data.object
    );
  },
  AddTask: (data: any) => {
    const published = parsePublished(data);
    const actor = parseUserRef(data.actor);

    return new MeasureActivityAddTask(
      published,
      data.summaryMap || data.summary,
      actor,
      data.object
    );
  },
};

function activityStream2MeasureActivity(data: any): null | MeasureActivity {
  if (typeof data.type === "string" && activityStreamTypeMap[data.type]) {
    return activityStreamTypeMap[data.type](data);
  } else {
    console.warn("Unsupported type", data.type, "in", data);
    return null;
  }
}

class MeasureProcessMockApi {
  stepCount = 3;
  constructor(
    private processes: Array<MeasureProcessEntry>,
    private activities: TodoArray,
    private steps: MeasureProcessStep[]
  ) {}

  loadMeasuresProcess(measureProcessId: string) {
    return startasync((id: string) => {
      const process = this.processes.find(process => process.id === id);
      if (process) {
        return process;
      } else {
        throw Error("not found/measure process");
      }
    }, measureProcessId);
  }

  loadSteps(measureProcessId: string) {
    return startasync((id: string) => {
      return this.steps.slice(0, this.stepCount);
    }, measureProcessId);
  }

  loadActivities(measureProcessId: string) {
    return startasync((id: string) => {
      return this.activities
        .map(activityStream2MeasureActivity)
        .filter(v => v !== null);
    }, measureProcessId);
  }
  nextStep() {
    this.stepCount++;
  }
  startStartDirectMeasure(
    id: string,
    dueDate: string,
    responsible: IUserRef,
    description: string
  ) {
    const stepIdx = this.steps.findIndex(s => s.id === id);
    const step = this.steps[stepIdx];
    if (step instanceof MeasureProcessStepDirectMeasure) {
      const startedStep = new MeasureProcessStepDirectMeasure(
        StepState.WorkInProgress,
        step.id,
        description,
        new Date(dueDate),
        responsible,
        0,
        0
      );
      Vue.set(this.steps, stepIdx, startedStep);
    }
  }
  finalizeStartDirectMeasure(id: string) {
    const stepIdx = this.steps.findIndex(s => s.id === id);
    const step = this.steps[stepIdx];
    if (step instanceof MeasureProcessStepDirectMeasure) {
      const finalized = new MeasureProcessStepDirectMeasure(
        StepState.Verify,
        step.id,
        step.description,
        step.dueDate,
        step.responsible,
        0,
        0,
        new Date()
      );
      Vue.set(this.steps, stepIdx, finalized);
    }
  }
  verifyStartDirectMeasure(id: string, signature: Signature, comment: string) {
    const stepIdx = this.steps.findIndex(s => s.id === id);
    const step = this.steps[stepIdx];
    if (step instanceof MeasureProcessStepDirectMeasure) {
      const finalized = new MeasureProcessStepDirectMeasure(
        StepState.Done,
        step.id,
        step.description,
        step.dueDate,
        step.responsible,
        0,
        0,
        step.finalizedDate,
        signature
      );
      if (signature.type === SignatureType.Rejected) {
        this.steps.splice(
          stepIdx,
          1,
          finalized,
          new MeasureProcessStepDirectMeasure(
            StepState.New,
            uuidv4(),
            null,
            null,
            null,
            0,
            0
          )
        );
      } else {
        Vue.set(this.steps, stepIdx, finalized);
      }
      this.stepCount++;
    }
  }

  startStep(id: string) {
    this.steps.find(step => {
      if (step instanceof MeasureProcessStepDirectMeasure) {
        step.state = StepState.WorkInProgress;
      }
    });
    this.stepCount++;
  }
}

const measureProcessApi = new MeasureProcessMockApi(
  _.cloneDeep(MOCK_MEASURE_PROCESSES),
  _.cloneDeep(MOCK_MEASURE_ACTIVITIES),
  _.cloneDeep(MOCK_MEASURE_STEPS)
);
export { measureProcessApi };
