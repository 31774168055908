
































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { api as auditResultApi } from "@/store/modules/auditResult";
import StatGetter from "@/components/layouts/BaseLayouts/StatGetter.vue";
import {
  gResultSteps,
  ResultStepsForDimensions,
} from "@/store/modules/auditResult/getters";
import { TEXT_TOTAL } from "@auditcloud/shared/lib/constants";
import { TranslateableText } from "@auditcloud/shared/lib/schemas";
import { AuditResultStepEntry } from "@auditcloud/shared/lib/types/AuditResult";

@Component({
  components: { StatGetter },
})
export default class ACalculationCompliance extends Vue {
  activeDimension: null | string = null;

  @Getter(auditResultApi.getters.getResultSteps, {
    namespace: auditResultApi.namespace,
  })
  auditResultSteps!: gResultSteps;

  @Getter(auditResultApi.getters.getResultStepsForDimensions, {
    namespace: auditResultApi.namespace,
  })
  resultStepsForDimensions!: ResultStepsForDimensions;

  get dimensionsList(): null | Array<{
    id: null | string;
    name: TranslateableText;
  }> {
    if (this.resultStepsForDimensions.length > 0) {
      return [
        {
          id: null,
          name: TEXT_TOTAL,
        },
        ...this.resultStepsForDimensions.map(entry => entry.dimension),
      ];
    } else {
      return null;
    }
  }

  get steps(): AuditResultStepEntry[] {
    const dimensionSteps = this.resultStepsForDimensions.find(
      entry => entry.dimension.id === this.activeDimension
    );
    if (dimensionSteps) {
      return dimensionSteps.resultSteps.steps;
    } else if (
      this.resultStepsForDimensions.length > 0 ||
      this.activeDimension === null
    ) {
      return this.auditResultSteps?.data?.steps ?? [];
    } else {
      return [];
    }
  }
}
