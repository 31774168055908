import firebase from "firebase/compat/app";
import { RootState } from "../../types";
import { MeasureState, State } from "./types";
import { mutationNames as mn } from "./mutations";
import { getterNames as gn } from "./getters";
import { updateMeasuresInDb } from "../measures/utils";
import {
  createCollectionObserver,
  createDocumentObserver,
  DocumentsUpdateHandlerData,
  DocumentUpdateHandlerData,
  WatchedDocument,
  CollectionObserverConfig,
  QueryData,
} from "@/utils/firestore";

import { createError } from "@/utils/Errors";
import { ActionTypeContext } from "@/utils/VuexHelper";
import { rdu } from "../user";
import { CollectionNames } from "@auditcloud/shared/lib/types/common";
import {
  MeasureProcessDocument,
  MeasureProcessDocumentSource,
} from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import { Operation } from "fast-json-patch";
import { MeasureWorkflow } from "@auditcloud/shared/lib/workflow/modules/Measure";
import { mapValues, keys } from "lodash";
import {
  attachFilesToEntity,
  EntryAttachmentsVuexApi,
} from "@/utils/attachFileToEntity";
import {
  createMeasureMediaPath,
  fieldPath,
} from "@auditcloud/shared/lib/utils/firestorePathHelper";
import { getPossibleTransitions, handleMeasureTransitionError } from "./utils";
import * as measuresService from "@/utils/services/measuresService";
import {
  MeasureProcessStepDoc,
  MeasureTransitionData,
  ApiV0FileManagementDownloadRequest as DownloadRequest,
  MeasureTransitionDataRelaxed,
} from "@auditcloud/shared/lib/schemas";

import { Getters } from ".";
import {
  REV_KEY,
  VERSIONED_DOCUMENT_FIELDNAME,
} from "@auditcloud/shared/lib/types/VersionedDocument";
import { extractCurrentUserRef, extractCurrentUserRoles } from "../user/utils";
import { getDownloadLinkForAttachment } from "@/utils/storage";
import { typeIsFirebaseError } from "@/utils/firestore/utils";

type Context = ActionTypeContext<State, RootState, Getters>;

function measureStepsObserverFilterConfig(): CollectionObserverConfig["filter"] {
  const filter = [
    new QueryData(
      fieldPath(REV_KEY, VERSIONED_DOCUMENT_FIELDNAME.DELETED),
      "==",
      false
    ),
  ];
  return filter;
}

function updateMeasureData(
  { commit, getters, rootGetters },
  updateData: DocumentUpdateHandlerData
) {
  if (updateData.data !== null) {
    const measure = new MeasureProcessDocument(
      updateData.id,
      updateData.data as MeasureProcessDocumentSource
    );
    const doc: WatchedDocument<MeasureProcessDocument> = {
      ...updateData,
      data: measure,
    };
    commit(mn.SET_MEASURE_METADATA, doc);

    const measureWorkflow = getters[gn.getWorkflow] as null | MeasureWorkflow;

    const user = extractCurrentUserRef(rootGetters);

    if (measureWorkflow && user) {
      const userId = user.id;
      const roles = extractCurrentUserRoles(rootGetters);

      const possibleTransitions = getPossibleTransitions(
        measureWorkflow,
        userId,
        roles,
        doc.data
      );
      commit(mn.SET_MEASURE_TRANSITIONS, possibleTransitions);
    }
  } else {
    commit(mn.SET_MEASURE_METADATA, updateData);
  }
}

function updateMeasureStepsData(
  context,
  updateHandlerData: DocumentsUpdateHandlerData<firebase.firestore.DocumentData>
) {
  console.log("updateMeasureStepsData", updateHandlerData);
  const state: MeasureState = context.state;
  const { removeDocs, modifiedDocs } =
    updateHandlerData as DocumentsUpdateHandlerData<MeasureProcessStepDoc>;

  const steps = state.processSteps.filter(
    step => !removeDocs.includes(step.id)
  );

  modifiedDocs.forEach(({ id, data }) => {
    const i = steps.findIndex(step => step.id === id);
    if (i === -1) {
      steps.push({ id, ...data });
    } else {
      steps[i] = { ...steps[i], ...data };
    }
  });

  context.commit(mn.SET_MEASURE_PROCESS_STEPS, steps);
}

const actions = {
  async setMeasure(
    { commit, getters, rootGetters, state }: Context,
    { measureId }: { measureId?: string }
  ) {
    if (typeof measureId === "string") {
      commit(mn.SET_MEASURE_ID, measureId);
      commit(mn.SET_MEASURE_TRANSITIONS, null);
      commit(mn.SET_MEASURE_PROCESS_STEPS, []);
      commit(mn.SET_MEASURE_ACCESS_DENIED, false);

      const measureUnlistener = createDocumentObserver(
        CollectionNames.MEASURE_PROCESSES,
        measureId,
        updateData =>
          updateMeasureData({ commit, getters, rootGetters }, updateData),
        err => {
          if (typeIsFirebaseError(err)) {
            if (err.code === "permission-denied") {
              console.warn(
                `measure observer (${measureId}): permission denied`
              );
              commit(mn.SET_MEASURE_ACCESS_DENIED, true);
            } else {
              console.error(
                `measure observer (${measureId}): ${err.code} ${err.message}`,
                err
              );
            }
          } else {
            console.error(`measure observer (${measureId}):`, err);
          }
        },
        s => {
          console.log(`measure observer (${measureId}):`, s);
        }
      );

      const stepsUnlistener = createCollectionObserver(
        `/${CollectionNames.MEASURE_PROCESSES}/${measureId}/${CollectionNames.MEASURE_STEPS}`,
        {
          orderBy: {
            fieldPath: fieldPath(
              REV_KEY,
              VERSIONED_DOCUMENT_FIELDNAME.CREATED_AT
            ),
            direction: "asc",
          },
          filter: measureStepsObserverFilterConfig(),
        },
        ({ removeDocs, modifiedDocs }) =>
          updateMeasureStepsData(
            { commit, getters, rootGetters, state },
            { removeDocs, modifiedDocs }
          ),
        () => {},
        err => {
          console.error(`measure steps observer (${measureId}):`, err);
        },
        s => {
          console.log(`measure steps observer (${measureId}):`, s);
        }
      );

      commit(mn.SET_MEASURE_UNLISTENER, () => {
        measureUnlistener();
        stepsUnlistener();
      });
    }
  },
  async activateTransition(
    context: Context,
    transitionId: State["activeTransitionId"]
  ) {
    context.commit(mn.SET_ACTIVE_TRANSITION_ID, transitionId);
  },

  async updateMeasure(context: Context, payload: { patch: Operation[] }) {
    const measureId = context.state.Document.id;
    if (measureId) {
      context.commit(mn.SET_API_ERRORS, []);
      return updateMeasuresInDb(payload.patch, [measureId]);
    } else {
      throw new Error(`exprect measureId`);
    }
  },
  async patchMeasureStep(
    { state }: Context,
    { measureStepId, patch }: { patch: Operation[]; measureStepId: string }
  ) {
    const measureId = state.Document.id;
    if (measureId.trim().length === 0) {
      throw new Error(`expect valid measure id`);
    }

    const response = await measuresService.patchStepContext(
      { measureId, measureStepId },
      patch
    );
    return response;
  },
  async runTransition(
    context: Context,
    payload: {
      transactionData: MeasureTransitionData;
      measureId: string;
      transitionId: string;
    }
  ) {
    context.commit(mn.SET_API_ERRORS, []);
    if (
      !(typeof payload.measureId === "string" && payload.measureId.length > 3)
    ) {
      throw createError("invalid measureId", payload);
    }

    try {
      const response = await measuresService.runTransition(
        payload.transitionId,
        payload.measureId,
        payload.transactionData
      );
      return response;
    } catch (err) {
      handleMeasureTransitionError(err, context, mn);
    }
  },
  async runBulkTransition(
    context: Context,
    payload: {
      transitionData: MeasureTransitionDataRelaxed;
      measureIds: string[];
      transitionId: string;
    }
  ) {
    context.commit(mn.SET_API_ERRORS, []);
    if (!payload.measureIds.every(v => v.length > 3)) {
      throw createError("invalid measureId in", payload.measureIds);
    }

    try {
      const response = await measuresService.runBulkTransition(
        payload.transitionId,
        payload.measureIds,
        payload.transitionData
      );
      return response;
    } catch (err) {
      handleMeasureTransitionError(err, context, mn);
    }
  },
  async attachFilesToEntry(
    { getters }: Context,
    ...[payload]: Parameters<EntryAttachmentsVuexApi["attachFilesToEntry"]>
  ): ReturnType<EntryAttachmentsVuexApi["attachFilesToEntry"]> {
    const measureId = getters.getCurrentMeasureId;
    if (measureId === null) {
      throw createError("Invalid measureId");
    }

    const attachmentMap = await attachFilesToEntity(payload, {
      target: { type: "measure", measureId },
    });
    return keys(attachmentMap);
  },
  async updateAttachmentComment(
    { getters, rootGetters }: Context,
    ...[{ comment, context, id: attachmentId }]: Parameters<
      EntryAttachmentsVuexApi["updateAttachmentComment"]
    >
  ): ReturnType<EntryAttachmentsVuexApi["updateAttachmentComment"]> {
    const expectedContext = "measure";
    if (context !== expectedContext) {
      throw createError(
        `Unexpected context - Error: 88cf96f7-db01-4a93-bd1d-5040a02b9ac2`
      );
    }
    const measureId = getters.getCurrentMeasureId;
    if (!measureId || !attachmentId) {
      throw createError(
        "measure or attachmentId not defined",
        measureId,
        attachmentId
      );
    }

    const db = firebase.firestore();
    const { ref, doc, child } = createMeasureMediaPath(measureId, attachmentId);

    const docRef = db.collection(ref).doc(doc);
    await db.runTransaction(async transaction => {
      transaction.update(docRef, {
        [`${child}.comment`]: comment,
        ...rdu(rootGetters),
      });
    });
  },
  async downloadFileFromEntry(
    { getters }: Context,
    ...[{ id: attachmentId, context }]: Parameters<
      EntryAttachmentsVuexApi["downloadFileFromEntry"]
    >
  ): ReturnType<EntryAttachmentsVuexApi["downloadFileFromEntry"]> {
    const expectedContext = "measure";
    if (context !== expectedContext) {
      throw createError(
        `Unexpected context - Error: fb42f154-8616-4429-a5a1-85da4993cdfa`
      );
    }
    const measureId = getters.getCurrentMeasureId;
    if (!measureId || !attachmentId) {
      throw createError(
        "measure or attachmentId not defined",
        measureId,
        attachmentId
      );
    }
    const params: DownloadRequest = {
      attachmentId,
      target: {
        type: expectedContext,
        measureId,
      },
    };

    const attachmentLink = await getDownloadLinkForAttachment(params);
    if (attachmentLink.isOk()) {
      return attachmentLink.value.downloadUrl;
    } else {
      throw createError(attachmentLink.error);
    }
  },
  async deleteFileFromEntry(
    { getters, rootGetters }: Context,
    ...[{ context, id: attachmentId }]: Parameters<
      EntryAttachmentsVuexApi["deleteFileFromEntry"]
    >
  ): ReturnType<EntryAttachmentsVuexApi["deleteFileFromEntry"]> {
    const expectedContext = "measure";
    if (context !== expectedContext) {
      throw createError(
        `Unexpected context - Error: 21a8eb3c-3681-47df-a04e-afd14c2156f2`
      );
    }
    const measureId = getters.getCurrentMeasureId;
    if (!measureId || !attachmentId) {
      throw createError(
        "measure or attachmentId not defined",
        measureId,
        attachmentId
      );
    }

    const db = firebase.firestore();
    const FieldValue = firebase.firestore.FieldValue;

    const { ref, doc, child } = createMeasureMediaPath(measureId, attachmentId);

    const docRef = db.collection(ref).doc(doc);
    await db.runTransaction(async transaction => {
      transaction.update(docRef, {
        ...rdu(rootGetters),
        [child]: FieldValue.delete(),
      });
    });
  },
};

const n = mapValues(actions, (_, key) => key);

export { n as actionNames, actions };
