import { ActionTree } from "vuex";
import { UserGroupsState } from "./types";
import { RootState } from "@/store/types";
import { Dictionary } from "lodash";

const n: Dictionary<string> = {};

const actions: ActionTree<UserGroupsState, RootState> = {};

export { n as actionNames, actions };
