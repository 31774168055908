























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { TranslateableText } from "@auditcloud/shared/lib/schemas";
import { AuditResult } from "@auditcloud/shared/lib/types/AuditResult";

@Component({})
export default class AFinalAuditResult extends Vue {
  @Prop({
    type: [Object, String],
    default: null,
  })
  readonly title!: TranslateableText | null;

  @Prop({
    type: Object,
    required: true,
  })
  readonly value!: {
    result: AuditResult;
    percent: number;
  };

  @Prop({
    type: [Object, String],
    default: null,
  })
  resultNote!: TranslateableText | null;

  get color() {
    if (this.value) {
      return this.value.result.color;
    } else {
      return "gray";
    }
  }
  get short() {
    if (this.value) {
      return this.value.result.short;
    } else {
      return "-";
    }
  }
  get label() {
    if (this.value) {
      return this.value.result.label
        ? `${this.$ft(this.value.result.label)} - `
        : "";
    } else {
      return "";
    }
  }
  get description() {
    if (this.value) {
      return this.value.result.description;
    } else {
      return "-";
    }
  }
  get percent() {
    if (this.value) {
      return `${(this.value.percent * 100).toFixed(1)}%`;
    } else {
      return "-";
    }
  }
}
