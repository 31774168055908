var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$tc( "components.widgets.sidebar.audit_transition.create_policy_required_measures", _vm.requiredMeasureType.requiredCount, { count: _vm.requiredMeasureType.requiredCount, measureTypeName: _vm.$ft(_vm.requiredMeasureType.text), } ))+" ")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('AMenuDatePicker',{attrs:{"return-string":true,"disabled":_vm.loading,"label":_vm.$t('components.dialogs.audit_measure_dialog.date_filter_label')},model:{value:(_vm.dueDateForMeasures),callback:function ($$v) {_vm.dueDateForMeasures=$$v},expression:"dueDateForMeasures"}})],1)],1),_c('v-row',[_c('v-col',[_c('AUserAutocompleteSingle',{attrs:{"return-string":true,"disabled":_vm.loading,"query-action":{ name: 'queryUser', namespace: 'users' },"label":_vm.$t(
                "components.widgets.sidebar.audit_transition.create_policy_required_measures_assignee"
              )},model:{value:(_vm.assigneeForMeasures),callback:function ($$v) {_vm.assigneeForMeasures=$$v},expression:"assigneeForMeasures"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"rows":3,"disabled":_vm.loading,"counter":_vm.measureMaxCharLimit,"counter-value":function (v) { return v.trim().length; },"label":_vm.$t(
                "components.widgets.sidebar.audit_transition.create_policy_required_measures_text"
              ),"placeholder":_vm.enableMeasureImplementationInQuickEditor
                ? _vm.$t(
                    'components.dialogs.audit_measure_dialog.measure_placeholder_short'
                  )
                : _vm.$t(
                    'components.dialogs.audit_measure_dialog.measure_placeholder'
                  )},model:{value:(_vm.defaultTextForMeasures),callback:function ($$v) {_vm.defaultTextForMeasures=$$v},expression:"defaultTextForMeasures"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('AAsyncBtn',{attrs:{"operation-id":"createMeasures","disabled":!_vm.measureCreationFieldsValid,"handler":_vm.createRequiredMeasuresHandler,"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("components.widgets.sidebar.audit_transition.create"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }