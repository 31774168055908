import ui from "./ui.json";
import data from "./data.json";

import { SchemaMap, FormSchema } from "../../index";
import dgq from "./dgq";
import hse from "./hse";
import qlkgJoined from "./qlkg-joined";
const dialogSchema: FormSchema = {
  ui,
  data,
};

const schemaMap: SchemaMap = {
  default: dialogSchema,
  dgq,
  hse,
  "qlkg-joined": qlkgJoined,
};
export default schemaMap;
