






























































































































































import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { VAvatar, VChip, VIcon, VBtn } from "vuetify/lib";
import { TodoMap } from "@auditcloud/shared/lib/utils/type-guards";

@Component({
  components: { VAvatar, VChip, VIcon, VBtn },
})
export default class AActivityItem extends Vue {
  @Prop({
    default: () => {},
    type: Object,
    required: true,
  })
  readonly item!: TodoMap;
}
