import {
  MeasureProcessMetadata,
  MeasureProcessStep,
  MeasureProcessStepQuestion,
  MeasureProcessStepFinding,
  MeasureProcessStepDirectMeasure,
  StepState,
  MeasureProcessStepCauseAnalysis,
  SignatureType,
  MeasureProcessStepCorrectiveMeasure,
  CorrectiveMeasureTask,
  Signature,
  MeasureProcessStepEfficacyCheck,
} from "./types";
import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";
import { TodoArray } from "@auditcloud/shared/lib/utils/type-guards";
import { ValuedItemType } from "@auditcloud/shared/lib/types/ItemTypes";
import { AuditMetadataClient } from "@/types/Audit";
import { AuditItem } from "@auditcloud/shared/lib/schemas";
import { idable } from "@auditcloud/shared/lib/types/common";

function createUserRef(id: string, displayName: string): IUserRef {
  return {
    id,
    displayName,
  };
}

export interface MeasureProcessEntry {
  id: string;
  audit?: AuditMetadataClient;
  finding: {
    text: string;
    type: ValuedItemType;
    hasDirectMeasure: boolean;
  };
  reason?: string;
  measure?: string;
  dueDate: Date;
  asignee?: IUserRef;
  status: string;
}

enum DemoTypeNames {
  LEVEL1 = "27f9cf8b-1fd7-49fd-9f4e-4273c9f0489d",
  LEVEL2 = "38035b0c-4ab3-4332-b5b9-f32ffebb849e",
  LEVEL3 = "564329f1-948f-4f3b-a5fd-bc7056c809ad",
}

const demoTypes: { [id: string]: ValuedItemType } = {
  [DemoTypeNames.LEVEL1]: {
    id: DemoTypeNames.LEVEL1,
    text: "Empfehlung",
    description: "Empfehlung",
    short: "9",
    color: "#FFF176",
    value: 9,
  },
  [DemoTypeNames.LEVEL2]: {
    id: DemoTypeNames.LEVEL2,
    text: "Nebenabweichung",
    description: "Nebenabweichung",
    short: "3",
    color: "#FFB74D",
    value: 3,
  },
  [DemoTypeNames.LEVEL3]: {
    id: DemoTypeNames.LEVEL3,
    text: "Hauptabweichung",
    description: "Hauptabweichung",
    short: "",
    color: "#E57373",
    value: 1,
  },
};

export const MOCK_MEASURE_PROCESSES: Array<MeasureProcessEntry> = [
  {
    id: "MA-24",
    finding: {
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
      type: demoTypes[DemoTypeNames.LEVEL1],
      hasDirectMeasure: true,
    },
    dueDate: new Date(),
    asignee: createUserRef("1234", "Frowin Zaun"),
    status: "Neu",
  },
  {
    id: "MA-26",
    finding: {
      text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
      type: demoTypes[DemoTypeNames.LEVEL3],
      hasDirectMeasure: false,
    },
    reason: "Consectetur adipiscing elit, sed do eiusmod tempor",
    measure: "Sed do eiusmod tempor incididunt",
    dueDate: new Date(),
    asignee: createUserRef("1234", "Frowin Zaun"),
    status: "Neu",
  },
  {
    id: "MA-29",
    finding: {
      text: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est",
      type: demoTypes[DemoTypeNames.LEVEL1],
      hasDirectMeasure: true,
    },
    measure: "Sed do eiusmod tempor incididunt",
    dueDate: new Date(),
    asignee: createUserRef("1234", "Frowin Zaun"),
    status: "In Bearbeitung",
  },
  {
    id: "MA-30",
    finding: {
      text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
      type: demoTypes[DemoTypeNames.LEVEL2],
      hasDirectMeasure: false,
    },
    reason: "Consectetur adipiscing elit, sed do eiusmod tempor",
    measure: "Sed do eiusmod tempor incididunt",
    dueDate: new Date(),
    asignee: createUserRef("1234", "Frowin Zaun"),
    status: "Neu",
  },
  {
    id: "MA-31",
    finding: {
      text: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
      type: demoTypes[DemoTypeNames.LEVEL3],
      hasDirectMeasure: false,
    },
    reason: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit",
    dueDate: new Date(),
    asignee: createUserRef("1234", "Frowin Zaun"),
    status: "Neu",
  },
];

export const MOCK_MEASURE_ACTIVITIES: TodoArray = [
  // Chat Nachricht erstellt
  {
    summaryMap: {
      en: "[Sally](user://1345) created a comment",
      de: "[Sally](user://1345) hat einen Kommentar angelegt",
    },
    published: "2014-12-12T12:12:12Z",
    type: "AddComment",
    actor: {
      type: "Person",
      id: "12343",
      name: "Sally",
    },
    object: {
      type: "Note",
      name: "A Simple Note",
      content: "Dies und dass",
    },
    target: {
      type: "Link",
      name: "Ursachenanalyse",
      url: "http://example.com/measureprocess/{processId}/step/{stepNo}",
    },
  },
  {
    summaryMap: {
      en: "[Stefan](user://13456) created a comment",
      de: "[Stefan](user://13456) hat einen Kommentar angelegt",
    },
    published: "2014-12-12T12:17:12Z",
    type: "AddComment",
    actor: {
      type: "Person",
      id: "24132",
      name: "Stefan",
    },
    object: {
      type: "Note",
      name: "A Simple Note",
      content:
        "Hallo [Sally Bridge](user://12343), bitte schau dir einmal die Sofortmaßnahme an. Thx, S",
    },
    target: {
      type: "Link",
      name: "Ursachenanalyse",
      url: "http://example.com/measureprocess/{processId}/step/{stepNo}",
    },
  },

  // Anhang hinzufügen
  {
    summaryMap: {
      en: "[Sally](user://1345) added an [attachment](object://)",
      de: "[Sally](user://1345) hat einen [Anhang](object://) hinzugefügt",
    },
    published: "2014-12-12T12:12:12Z",
    type: "AddAttachment",
    actor: {
      type: "Person",
      id: "",
      name: "Sally",
    },
    object: {
      type: "Document",
      name: "Bericht",
      url: "gs://attachments/measureprocess/{processId}/step/{stepNo}/report.pdf",
    },
    target: {
      type: "Link",
      name: "Ursachenanalyse",
      url: "http://example.com/measureprocess/{processId}/step/{stepNo}",
    },
  },

  // Schritt zuweisen
  {
    summaryMap: {
      en: '[Sally](user://1345) assigned ["Sofortmaßnahme"](target://) to [Peter](user://1345)',
      de: "[Sally](user://1345) hat die [Sofortmaßnahme](target://) [Peter](user://1345) zugewiesen",
    },
    published: "2014-12-12T12:12:12Z",
    type: "Assign",
    actor: {
      type: "Person",
      id: "",
      name: "Sally",
    },
    object: {
      type: "Person",
      id: "",
      name: "Peter",
    },
    target: {
      type: "Link",
      name: "Sofortmaßnahme",
      url: "http://example.com/measureprocess/{processId}/step/{stepNo}",
    },
  },

  // Schritt bearbeitet
  {
    summaryMap: {
      en: '[Sally](user://1345) edited ["Ursachenanalyse"](object://)',
      de: "[Sally](user://1345) hat die [Ursachenanalyse](object://) bearbeitet",
    },
    published: "2014-12-12T12:12:12Z",
    type: "Update",
    actor: {
      type: "Person",
      id: "",
      name: "Sally",
    },
    object: {
      type: "Link",
      name: "Ursachenanalyse",
      url: "http://example.com/measureprocess/{processId}/step/{stepNo}",
    },
  },

  // Schritt akzeptieren
  {
    summaryMap: {
      en: '[Sally](user://1345) verified ["Ursachenanalyse"](object://)',
      de: "[Sally](user://1345) hat die [Ursachenanalyse](object://) verifiziert",
    },
    published: "2014-12-12T12:12:12Z",
    type: "Accept",
    actor: {
      type: "Person",
      id: "",
      name: "Sally",
    },
    object: {
      type: "Link",
      name: "Ursachenanalyse",
      url: "http://example.com/measureprocess/{processId}/step/{stepNo}",
    },
  },

  //Schritt ablehnen
  {
    summaryMap: {
      en: '[Sally](user://1345) rejected ["Ursachenanalyse"](object://)',
      de: "[Sally](user://1345) hat die [Ursachenanalyse](object://) abgelehnt",
    },
    summary: "Sally hat die Ursachenanalyse abgelehnt",
    published: "2014-12-12T12:12:12Z",
    type: "Reject",
    actor: {
      type: "Person",
      id: "",
      name: "Sally",
    },
    object: {
      type: "Link",
      name: "Ursachenanalyse",
      url: "http://example.com/measureprocess/{processId}/step/{stepNo}",
    },
    content: "Kommentar",
  },

  // Unteraufgabe erstellt
  {
    summaryMap: {
      en: "[Sally](user://1345) added a new [task](object://)",
      de: "[Sally](user://1345) hat eine neue [Aufgabe](object://) hinzugefügt",
    },
    summary: "Sally hat eine Aufgabe erstellt",
    published: "2014-12-12T12:12:12Z",
    type: "AddTask",
    actor: {
      type: "Person",
      id: "",
      name: "Sally",
    },
    object: {
      type: "Note",
      id: "{taskId}",
      name: "A Simple Note",
      content: "This is a simple note",
      url: "http://example.com/measureprocess/{processId}/step/{stepNo}/task/{taskId}",
    },
    target: {
      type: "Link",
      name: "Ursachenanalyse",
      url: "http://example.com/measureprocess/{processId}/step/{stepNo}",
    },
  },

  // Unteraufgabe fertigstellen
  {
    summaryMap: {
      en: "[Sally](user://1345) edited a [task](object://)",
      de: "[Sally](user://1345) hat eine [Aufgabe](object://) bearbeitet",
    },
    published: "2014-12-12T12:12:12Z",
    type: "Update",
    actor: {
      type: "Person",
      id: "",
      name: "Sally",
    },
    object: {
      type: "Note",
      id: "{taskId}",
      name: "A Simple Note",
      content: "This is a simple note",
      url: "http://example.com/measureprocess/{processId}/step/{stepNo}/task/{taskId}",
    },
  },
];

export const MOCK_MEASURE_STEPS: MeasureProcessStep[] = [
  new MeasureProcessStepQuestion(
    {
      id: "005e25e1-e01b-458b-be20-bd6e35e5bdae",
      tags: [],
      ignored: false,
      question: {
        categoryRef: ["e74b3328-b2c8-4e01-901e-84aee0bc07eb"],
        chapters: [
          {
            chapterDescription: "Kompetenz",
            chapterId: "7.2",
            standardId: "iso9001-2015",
            standardName: "ISO 9001:2015",
          },
        ],
        hint: "",
        no: 60,
        text: "Wie wird die Wirksamkeit der Maßnahmen bewertet?",
        type: "d7e8470e-3d4c-46e8-9408-4dce75ae8789",
        links: [],
        description: "",
        requiredProofs: [],
      },
    } as idable<AuditItem>,
    "2c00ed51-ae7d-4e9a-b413-b9c99184e6ec"
  ),
  new MeasureProcessStepFinding(
    {
      auditItemRefs: [
        {
          auditItemId: "005e25e1-e01b-458b-be20-bd6e35e5bdae",
          dimensions: null,
        },
      ],
      attachmentIds: [],
      text: "Warum ist das denn jetzt so?",
      type: "9c779e4d-f6d9-4f81-95c3-fac58dac30bb",
      id: "31dad4be-e205-41b6-8518-c171d420df7d",
    },
    "6bc3c739-45e4-42f0-924e-b4d087ceaaa3"
  ),
  new MeasureProcessStepDirectMeasure(
    StepState.New,
    "26f9b852-77de-4acd-9313-debdfebc9b83",
    null,
    null,
    null,
    0,
    0
  ),
  new MeasureProcessStepCauseAnalysis(
    StepState.New,
    "639b5cf9-badf-4aff-96a5-dff7ad3703cb",
    new Date("2020-05-18"),
    { id: "2323", displayName: "Laura Hubner" },
    1,
    12,
    "Eine vollständige Beschreibung der Ursache ...",
    "5w",
    null,
    {
      by: { id: "2343", displayName: "Frowin Zaun" },
      date: new Date(),
      type: SignatureType.Verified,
    }
  ),
  new MeasureProcessStepCorrectiveMeasure(
    StepState.New,
    "dc1576a8-6325-405a-94ef-718373c2e2b5",
    new Date("2020-04-02"),
    { id: "2343", displayName: "Peter Pan" },
    5,
    2,
    "Beschreibung der Abstellmaßnahme",
    [
      new CorrectiveMeasureTask(
        true,
        { id: "23", displayName: "Ines Scholz" },
        "Aufgabenerstellungsrecht für alle Mitarbeiter freischalten"
      ),
      new CorrectiveMeasureTask(
        false,
        { id: "349", displayName: "Stefan Schneider" },
        "Mitarbeiter schulen"
      ),
    ],
    new Date("2020-04-01"),
    new Signature(SignatureType.Rejected, new Date("2020-04-01"), {
      id: "2323",
      displayName: "Laura Hubner",
    })
  ),

  new MeasureProcessStepEfficacyCheck(
    StepState.New,
    "36e2c2f1-bf96-4825-966f-4af106452552",
    new Date("2020-05-10"),
    null,
    0,
    0
  ),
];
