import { AsyncHandlerResponseMessage } from "@auditcloud/shared/lib/async-handling";
import { MultilingualText } from "@auditcloud/shared/lib/schemas";

export interface InternalMessage extends AsyncHandlerResponseMessage {
  id: string;
  createdAt: string;
}

export interface InternalError {
  error: unknown;
  operationId: string;
}

export class AsyncHandlerState {
  operations: {
    [operationId: string]: number | undefined;
  } = {};
  messages: Array<InternalMessage> = [];
}
export type State = AsyncHandlerState;
