













import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  name: "ASelectPlanningYear",
  components: {},
})
export default class ASelectPlanningYear extends Vue {
  @Prop({
    type: Number,
    required: true,
  })
  value!: number;

  get items() {
    const currentYear: number = new Date().getFullYear();
    const years: number[] = [
      currentYear - 1,
      currentYear,
      currentYear + 1,
      currentYear + 2,
    ];
    if (years.includes(this.value)) {
      return years;
    } else {
      return [this.value - 1, this.value, this.value + 1, this.value + 2];
    }
  }

  onSelectionChanged(newVal: number) {
    this.$emit("input", newVal);
  }
}
