


































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

import AAuditTransitionWarning from "@/components/widgets/AAuditTransitionWarning.vue";

import {
  api,
  Getters as MeasureGetters,
  Actions as MeasureActions,
} from "@/store/modules/measure";
import { MeasureTransitionData, UserRef } from "@auditcloud/shared/lib/schemas";
import { cloneDeep, Dictionary, isEqual, isString } from "lodash";
import { flattenUserRolesMap } from "@auditcloud/shared/lib/utils/aclDocumentUsers";
import comment from "../workflow/measure/components/transition/comment";
import duedateAssignee from "../workflow/measure/components/transition/duedateAssignee";
import notifications from "../workflow/measure/components/transition/notifications";
import { typeIsNotEmpty } from "@auditcloud/shared/lib/utils/filter/typeIsNotEmpty";
import { Transition } from "@auditcloud/shared/lib/workflow/types/Transition";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";

@Component({
  components: { AAuditTransitionWarning },
})
export default class AMeasureTransitionForm extends Vue {
  loading = false;
  error = false;
  loadingTransition = false;

  /** Transition data to be sent when running the transition */
  dataByTransition: Dictionary<MeasureTransitionData> = {};

  /** Additional transition state, to persist UI state when switching tabs */
  uiStateByTransitionComponent: Dictionary<Dictionary<object>> = {};

  get uiStateByComponent(): Dictionary<object> {
    if (this.transition) {
      return this.uiStateByTransitionComponent[this.transition.id] ?? {};
    }
    return {};
  }

  updateTransitionData(transitionId: string, eventData: object) {
    const currentState = this.dataByTransition[transitionId] ?? {};
    this.$set(this.dataByTransition, transitionId, {
      ...currentState,
      ...eventData,
    });
  }

  updateTransitionComponentUi(
    transitionId: string,
    component: string,
    eventData: object
  ) {
    const transitionComponentsState =
      this.uiStateByTransitionComponent[transitionId] ?? {};
    const componentState = transitionComponentsState[component] ?? {};
    Vue.set(this.uiStateByTransitionComponent, transitionId, {
      ...transitionComponentsState,
      [component]: {
        ...componentState,
        ...eventData,
      },
    });
  }

  @Getter(api.getters.getActiveTransition, {
    namespace: api.namespace,
  })
  transition!: MeasureGetters["getActiveTransition"];

  @Getter(api.getters.getCurrentStepIsDirty, {
    namespace: api.namespace,
  })
  currentStepIsDirty!: MeasureGetters["getCurrentStepIsDirty"];

  @Getter(api.getters.getProcessSteps, {
    namespace: api.namespace,
  })
  processSteps!: MeasureGetters["getProcessSteps"];

  @Getter(api.getters.getPreventRefUserAssignees, {
    namespace: api.namespace,
  })
  preventRefUserAssignees!: MeasureGetters["getPreventRefUserAssignees"];

  get isDirty() {
    return (
      this.transitionDirty ||
      this.currentStepIsDirty ||
      this.currentStepMessages.length !== 0
    );
  }

  @Getter(api.getters.getCurrentMeasure, {
    namespace: api.namespace,
  })
  currentMeasure!: MeasureGetters["getCurrentMeasure"];

  @Getter(api.getters.getWorkflow, {
    namespace: api.namespace,
  })
  measureWorkflow!: MeasureGetters["getWorkflow"];

  @Action(api.actions.runTransition, { namespace: api.namespace })
  runTransition!: MeasureActions["runTransition"];

  runActiveTransition() {
    console.log(
      "runActiveTransition",
      this.transition,
      this.currentMeasure,
      this.transitionData
    );
    const transactionData = this.transitionData ?? {
      assignedTo: null,
      dueDate: null,
    };
    if (this.transition && this.currentMeasure) {
      this.loading = true;
      this.runTransition({
        transactionData,
        measureId: this.currentMeasure.id,
        transitionId: this.transition?.id,
      })
        .then(res => {
          console.log("runTransition:done", res);
        })
        .catch(err => {
          console.error("runTransition:error", err);
        })
        .finally(() => {
          this.loading = false;
        });
      this.$emit("transition-activated");
    }
  }

  async calculateNextAssignee(
    transition: Transition<MeasureProcessDocument>
  ): Promise<UserRef | null> {
    const currentMeasure = this.currentMeasure;
    const workflow = this.measureWorkflow;
    const statusId = currentMeasure?.workflow.statusId ?? null;
    const status =
      statusId === null ? null : workflow?.states.get(statusId) ?? null;

    const caller = {
      id: this.$user.id(),
      customClaims: {
        roles: this.$user.roles(),
      },
    };

    if (!currentMeasure || !workflow || !status) {
      return null;
    }

    try {
      const assignedToId = transition.nextOwner
        ? await transition.nextOwner.cb(
            cloneDeep(caller),
            currentMeasure.id,
            currentMeasure,
            {},
            transition,
            status
          )
        : currentMeasure.userRefs.assignedTo?.id ?? null;

      console.log("AssignedTo", assignedToId);
      const assignedTo =
        flattenUserRolesMap(currentMeasure.userRefs).find(
          p => p.id === assignedToId
        ) ?? null;

      console.assert(
        (assignedTo === null && assignedToId === null) ||
          (isString(assignedToId) && assignedTo !== null),
        "Expect valid assignedToId",
        assignedTo,
        assignedToId,
        currentMeasure.userRefs
      );
      return assignedTo;
    } catch (err) {
      console.error("failed to load next assignee", err);
      this.error = true;
      return null;
    }
  }

  /** On transition change -> set initial data for each component */
  @Watch("transition", { immediate: true })
  async onTransitionChanged(
    newVal: MeasureGetters["getActiveTransition"],
    oldVal?: MeasureGetters["getActiveTransition"]
  ) {
    if (!newVal) {
      return;
    }

    this.error = false;
    const workflow = this.measureWorkflow;
    const transitionId = newVal.id;
    const transition = transitionId
      ? workflow?.transitions.get(transitionId) ?? null
      : null;

    if (transition == null) {
      console.error("No such transition: ", transitionId);
      this.error = true;
      return;
    }

    this.loadingTransition = true;
    const assignedTo = await this.calculateNextAssignee(transition);
    const dueDate = this.currentMeasure?.dueDate ?? null;
    const initialTransitionData = {
      ...this.components.reduce(
        (formData, component) => ({ ...formData, ...component.initialData() }),
        {}
      ),
      assignedTo,
      dueDate,
    };
    this.$set(this.dataByTransition, transitionId, initialTransitionData);
    this.loadingTransition = false;
  }

  get transitionData(): MeasureTransitionData | null {
    return this.transition && this.dataByTransition[this.transition.id]
      ? this.dataByTransition[this.transition.id]
      : null;
  }

  get currentStepMessages() {
    const workflow = this.measureWorkflow;

    const messages =
      this.error || workflow === null
        ? [
            {
              en: "Workflow not found",
              de: "Workflow konnte nicht geladen werden",
            },
          ]
        : [];
    return [
      ...(workflow?.getStepMessages(this.processSteps) ?? []),
      ...messages,
    ];
  }

  get components() {
    return (this.transition?.components ?? [])
      .map((config: unknown) => {
        if (comment.typeIsConfigType(config)) {
          return comment.factory(config);
        } else if (duedateAssignee.typeIsConfigType(config)) {
          return duedateAssignee.factory(config);
        } else if (notifications.typeIsConfigType(config)) {
          return notifications.factory(config);
        } else {
          console.warn(
            "Expect known transition component configuration, got",
            config
          );
          return null;
        }
      })
      .filter(typeIsNotEmpty);
  }

  get transitionDirty() {
    const formData = this.transitionData;

    return (
      formData === null ||
      !this.components.every(component => {
        return component.isValid(formData);
      })
    );
  }

  get hints() {
    return this.transition?.information.hints ?? [];
  }

  get warnings() {
    return this.transition?.information.warnings ?? [];
  }
}
