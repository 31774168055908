















import Vue from "vue";
import Component from "vue-class-component";
import AAuditResultsChart from "@/components/widgets/AAuditResultsChart.vue";
import { AuditResultChartData } from "@auditcloud/shared/lib/types/AuditResultChart";
import {
  WZL_AUDIT_RESULT,
  WZL_AUDIT_RESULT_2_CAT,
  DIMENSIONS_AUDIT_RESULT,
  DIMENSIONS_AUDIT_RESULT_2_CAT,
  SIMPLE_AUDIT_RESULT,
  SIMPLE_AUDIT_RESULT_2_CAT,
  WZL_DIMENSIONS_AUDIT_RESULT,
  WZL_DIMENSIONS_AUDIT_RESULT_2_CAT,
  WZL_DIMENSIONS_AUDIT_RESULT_1_CAT,
} from "@auditcloud/shared/lib/types/AuditResultChartTestData";

@Component({
  components: { AAuditResultsChart },
})
export default class AAuditResultChartTest extends Vue {
  auditResultChartDummyData: AuditResultChartData[] = [
    SIMPLE_AUDIT_RESULT,
    SIMPLE_AUDIT_RESULT_2_CAT,
    WZL_AUDIT_RESULT,
    WZL_AUDIT_RESULT_2_CAT,
    DIMENSIONS_AUDIT_RESULT,
    DIMENSIONS_AUDIT_RESULT_2_CAT,
    WZL_DIMENSIONS_AUDIT_RESULT,
    WZL_DIMENSIONS_AUDIT_RESULT_2_CAT,
    WZL_DIMENSIONS_AUDIT_RESULT_1_CAT,
  ];
}
