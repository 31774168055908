import { UserPluginType } from "@/plugins/user";
import { v4 as uuidv4 } from "uuid";
import { Dictionary } from "vue-router/types/router";
import { formatAuditName } from "@auditcloud/shared/lib/utils/formatting/audits";
import { AuditClassConfig } from "@auditcloud/shared/lib/schemas";

export enum ActivityVerbs {
  CREATE = "create",
  UPDATE = "update",
}

interface AuditActivity {
  id: string;
  avatar: string;
  actor: string;
  actor_name: string;
  audit_name: string;
  verb: ActivityVerbs;
  object: string;
  object_url: string;
  timestamp: string;
}

/**
 * Creates a function ```activityCreator``` with the passed params ```actor``` and ```metadata```.
 * @param actor The actor of the activity.
 * @param auditMetadata The form's model.
 * @returns An individualized function that is used to create an activity.
 */
export const getActivityCreator = (
  actor: UserPluginType,
  auditClass: AuditClassConfig | null,
  auditMetadata:
    | ({
        name: string | null;
        nameTemplate?: string | null;
      } & Dictionary<unknown>)
    | null
) => {
  if (!auditClass || auditClass.activityCreationDisabled) {
    return null;
  }

  /**
   * Creates an activity object that is used to create an activity for the activity-stream.
   * @param auditId The actor of the activity.
   * @param verb The verb used in the activity.
   * @returns The activity object.
   */
  const activityCreator = (
    auditId: AuditActivity["id"],
    verb: AuditActivity["verb"]
  ): AuditActivity => ({
    id: uuidv4(),
    avatar: actor.avatar(),
    actor: actor.id(),
    actor_name: actor.displayName(),
    audit_name: formatAuditName(auditMetadata),
    verb: verb,
    object: auditId,
    object_url: "/audit/" + auditId,
    timestamp: new Date().toISOString(),
  });

  return activityCreator;
};
