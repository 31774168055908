






import { AuditClassClient } from "@auditcloud/shared/lib/types/AuditClass";
import { idable, nullable } from "@auditcloud/shared/lib/types/common";
import { isString } from "lodash";
import { Component, Vue, Watch } from "vue-property-decorator";

import { api } from "@/store/modules/audit";
import { api as auditResultApi } from "@/store/modules/auditResult";
import { Action, Getter } from "vuex-class";
import { AuditItem } from "@auditcloud/shared/lib/schemas";

@Component
export default class AAuditResultDataWrapper extends Vue {
  loading: boolean = true;

  @Getter(api.getters.getAuditId, {
    namespace: api.namespace,
  })
  currentAuditId!: nullable<string>;

  @Getter(api.getters.getUnAnsweredAuditItems, {
    namespace: api.namespace,
  })
  unanswerdAuditItems!: Array<idable<AuditItem>>;

  @Action(auditResultApi.actions.loadAuditResult, {
    namespace: auditResultApi.namespace,
  })
  loadAuditResult!: (payload: {
    lang: string;
    auditId: string;
  }) => Promise<void>;

  @Watch("unanswerdAuditItems", { deep: true, immediate: true })
  onAuditClassChanged(newVal: AuditClassClient | null) {
    this.loadData();
  }
  loadData() {
    if (isString(this.currentAuditId)) {
      this.loadAuditResult({
        auditId: this.currentAuditId,
        lang: this.$i18n.locale,
      })
        .then(() => {
          console.log("load audit result done");
          this.loading = false;
        })
        .catch(err => {
          console.error("load audit result failed", err);
          this.loading = false;
        });
    }
  }
}
