














import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";

import { VBtn, VIcon } from "vuetify/lib";

@Component({
  components: { VBtn, VIcon },
})
export default class ASquareIconBtn extends Vue {
  @Prop({
    default: "list",
    type: String,
  })
  icon!: string;

  @Prop({
    default: null,
    type: [String, Number],
  })
  badge!: string | number;

  @Prop({
    default: "primary",
    type: String,
  })
  readonly color!: string;

  @Prop({
    default: true,
    type: Boolean,
  })
  readonly selected!: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly forceColor!: boolean;

  get hasBadge(): boolean {
    console.log("hasBadge", this.badge);
    return typeof this.badge === "string" || typeof this.badge === "number";
  }
}
