var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("components.dialogs.audit_reporting_dialog.dialog_sign"))+" "),_c('v-spacer'),(_vm.countRequiredSigns > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("components.dialogs.audit_reporting_dialog.count_signs"))+_vm._s(_vm.localCountSignedRequiredSigns)+"/"+_vm._s(_vm.countRequiredSigns)+" ")]):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.header,"items":_vm.localSignatures,"items-per-page":5,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getRoleText(item.role))+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"no-wrap"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"no-wrap"},[_vm._v(" "+_vm._s(item.timestamp)+" ")])]}},{key:"item.signature",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('img',{attrs:{"src":item.signature,"width":"100%"}})])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"no-wrap text-right"},[_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.signatureRemoved(item)}}},[_c('v-icon',{attrs:{"color":"pink"}},[_vm._v("mdi-delete")])],1),_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.openSignatureEditorDialog(item)}}},[_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("mdi-pencil")])],1)],1)]}}])})],1),_c('v-card-actions',{staticClass:"card-actions-border"},[(_vm.remaningRoles() !== '')?_c('div',[_vm._v(" Benötigte Unterschriften: "+_vm._s(_vm.remaningRoles())+" ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"ml-3",attrs:{"icon":""},on:{"click":function($event){return _vm.openSignatureEditorDialog()}}},[_c('v-icon',[_vm._v(" "+_vm._s("mdi-plus-circle-outline")+" ")])],1)],1)],1),(_vm.signatureEditordialog)?_c('v-dialog',{attrs:{"width":"70%","height":"70%"},model:{value:(_vm.signatureEditordialog),callback:function ($$v) {_vm.signatureEditordialog=$$v},expression:"signatureEditordialog"}},[_c('ASignatureEditor',{attrs:{"value":_vm.currentSignature,"is-dialog-open":true,"is-edit":_vm.isEdit,"required-roles":_vm.requiredRoles},on:{"input":function($event){return _vm.signatureAdded($event)},"edit":function($event){return _vm.signatureEdit($event)},"close":function($event){_vm.signatureEditordialog = false}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }