




































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Action } from "vuex-class";

import VJsoneditor from "v-jsoneditor";
import _, { Dictionary } from "lodash";

import { api } from "@/store/modules/configuration";
import { api as appApi } from "@/store/modules/app";
import { getterNs } from "../../utils/VuexHelper";
import { TodoAny } from "@auditcloud/shared/lib/utils/type-guards";

@Component({
  components: { VJsoneditor },
})
export default class AConfigEditor extends Vue {
  editedData: null | Dictionary<any> = null;
  valid: boolean = true;
  options = {
    templates: [],
    mode: "form",
    modes: ["tree", "form", "code", "text"],
  };
  //missingDefault
  @Prop({ type: String, required: true })
  segmentName!: string;

  @Watch("segmentName", {
    immediate: true,
  })
  onSegmentNameChange(val: string, oldVal: string) {
    this.valid = true;
    this.editedData = this.storedData;
  }

  get storedData() {
    const getterName = getterNs(api, this.segmentName);
    if (this.segmentName in api.getters) {
      return _.cloneDeep(this.$store.getters[getterName]);
    } else {
      return null;
    }
  }
  get isDirty() {
    return !_.isEqual(this.storedData, this.editedData);
  }

  @Action(api.actions.updateConfiguration, { namespace: api.namespace })
  updateConfiguration!: (payload: {
    segment: string;
    data: Dictionary<any>;
  }) => Promise<any>;

  @Action(appApi.actions.setStatus, { namespace: appApi.namespace })
  setStatus!: (payload: string) => Promise<any>;

  importJsonConf() {
    console.log(
      "importJsonConf",
      this.segmentName,
      this.editedData,
      this.valid,
      this.isDirty
    );
    if (this.valid && this.isDirty && this.editedData !== null) {
      this.setStatus("loading");
      try {
        console.log("Start Save");
        const payload = { segment: this.segmentName, data: this.editedData };

        console.log("Importing", payload);

        this.updateConfiguration(payload);
        this.setStatus("success");
      } catch (error) {
        console.log("Json Parse failed:", error);
        this.setStatus("error");
      }
    }
  }
  onError(err: TodoAny) {
    console.error("AConfigEditor:onError", err);
    this.valid = false;
  }
  onInput(data: null | Dictionary<any>) {
    this.valid = true;
    this.editedData = data;
  }
}
