import { mapValues } from "lodash";
import { RootState, RootGetters } from "@/store/types";
import { State } from "./types";
import { Getters } from ".";
import {
  Aggregation,
  Filter,
  FilterTree,
  FrontendFilterTree,
} from "@auditcloud/shared/lib/utils/filter/types";
import { api as auditApi } from "../audit";
import { getterNs } from "@auditcloud/shared/lib/utils";
import {
  buildAggregationsFromActiveFiltersAndIndex,
  calculateFilteredItemIds,
} from "@auditcloud/shared/lib/utils/filter/utils";
import { AuditItemWithId } from "@auditcloud/shared/lib/utils/audit/types";
import { fulltextSearchAuditItems } from "@auditcloud/shared/lib/utils/filter/FilterUtils";
import { ct } from "@/plugins/ContentTranslation";
import {
  AuditItemIdsFilteredWithSearchResults,
  SortManipulator,
  do_sort,
} from "@auditcloud/shared/lib/utils/filter/AuditItemListManipulator";
import { allPlainAuditItemsMapped } from "../audit/utils";
import { lookupAndMakeIdable } from "@auditcloud/shared/lib/utils/transform/lookupAndMakeIdable";
import { typeIsNotEmpty } from "@auditcloud/shared/lib/utils/filter/typeIsNotEmpty";
import { Finding } from "@auditcloud/shared/lib/schemas";
import { idable } from "@auditcloud/shared/lib/types/common";
import * as ListManipulatorIds from "@auditcloud/shared/lib/utils/filter/AuditItemListManipulatorIds";
import { QuestionSearchResultMap } from "@auditcloud/shared/lib/types/Audit/types";
import { readFrontendLanguage } from "@/plugins/i18n";

type Getter<R> = (
  state: State,
  getters: Getters,
  rootState: RootState,
  rootGetters: RootGetters
) => R;

const getAuditItemListFilterAggregations: Getter<Aggregation[]> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const activeFilters = state.activeFilter;

  const filterTree = rootGetters[
    getterNs(auditApi, auditApi.getters.getFilterTreeForNotConsideredAuditItems)
  ] as FrontendFilterTree;

  const notConsideredAuditItemIds = rootGetters[
    getterNs(auditApi, auditApi.getters.getNotConsideredAuditItemIds)
  ] as Array<AuditItemWithId["id"]>;

  const allowedAggregations: string[] = [
    ListManipulatorIds.FILTER_CHAPTER,
    ListManipulatorIds.FILTER_FINDING_HAS_NOTE,
    ListManipulatorIds.FILTER_IMPORTANCE,
    ListManipulatorIds.FILTER_DIMENSION,
    ListManipulatorIds.FILTER_HAS_LINKS,
    ListManipulatorIds.FILTER_HAS_PROOFS,
    ListManipulatorIds.FILTER_LABELS,
    ListManipulatorIds.FILTER_TAGS,
    ListManipulatorIds.FILTER_CATEGORY,
  ];
  const aggregations = buildAggregationsFromActiveFiltersAndIndex(
    filterTree,
    activeFilters,
    notConsideredAuditItemIds,
    readFrontendLanguage()
  ).filter(({ id }) => {
    return allowedAggregations.includes(id);
  });
  return aggregations;
};

const getFullTextSearch: Getter<string> = state => {
  return state.fulltextSearch;
};

const getAuditItemsFilteredByFulltextSearch: Getter<AuditItemIdsFilteredWithSearchResults> =
  (state, getters, rootState, rootGetters) => {
    const notConsideredAuditItemIds = rootGetters[
      getterNs(auditApi, auditApi.getters.getNotConsideredAuditItemIds)
    ] as Array<AuditItemWithId["id"]>;

    const questionNotesMapped = rootGetters[
      getterNs(auditApi, auditApi.getters.getMappedNotes)
    ] as Map<AuditItemWithId["id"], string[]>;

    const mappedAuditItems = allPlainAuditItemsMapped(rootGetters);
    const auditItemLookUp = (auditItemId: AuditItemWithId["id"]) => {
      return mappedAuditItems[auditItemId] ?? null;
    };

    return fulltextSearchAuditItems(
      state.fulltextSearch,
      notConsideredAuditItemIds,
      questionNotesMapped,
      auditItemLookUp,
      ct
    );
  };

const getSearchResultsByAuditItemIds: Getter<QuestionSearchResultMap> = (
  state,
  getters
) => {
  const auditItemIdsFilteredwithSearchResults =
    getters.getAuditItemsFilteredByFulltextSearch;

  return auditItemIdsFilteredwithSearchResults.searchResultsByAuditItemIds;
};

const getActiveFilters: Getter<Filter[]> = state => {
  return state.activeFilter;
};

const getNotConsideredAuditItems: Getter<Array<AuditItemWithId>> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const activeFilters = state.activeFilter;

  const filterTree = rootGetters[
    getterNs(auditApi, auditApi.getters.getFilterTreeForNotConsideredAuditItems)
  ] as FrontendFilterTree;

  const fullTextFilteredAuditItemIds =
    getters.getAuditItemsFilteredByFulltextSearch.auditItemIds;

  const filteredAuditItemIds = calculateFilteredItemIds(
    filterTree,
    activeFilters,
    fullTextFilteredAuditItemIds
  );

  const mappedAuditItems = allPlainAuditItemsMapped(rootGetters);

  const preparedAuditItems = filteredAuditItemIds
    .map(lookupAndMakeIdable(mappedAuditItems))
    .filter(typeIsNotEmpty)
    .map((auditItem): {
      auditItem: AuditItemWithId;
      findings: Array<idable<Finding>>;
    } => {
      return {
        auditItem,
        findings: [],
      };
    });

  const allSortManipulators = rootGetters[
    getterNs(auditApi, auditApi.getters.getAllSortManipulators)
  ] as SortManipulator[];

  const baseOrderSortingSettings = [
    { desc: false, id: ListManipulatorIds.SORT_NO },
  ];

  const sortedAuditItems = do_sort(
    allSortManipulators,
    baseOrderSortingSettings,
    preparedAuditItems
  );

  return sortedAuditItems.map(entry => entry.auditItem);
};

const getters = {
  getAuditItemListFilterAggregations,
  getFullTextSearch,
  getAuditItemsFilteredByFulltextSearch,
  getSearchResultsByAuditItemIds,
  getActiveFilters,
  getNotConsideredAuditItems,
};

const n = mapValues(getters, (_, key) => key);

export { n as getterNames, getters };
