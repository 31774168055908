import { Attachment } from "@/components/types";
import firebase from "firebase/compat/app";
import { v4 as uuidv4 } from "uuid";
import { uploadBlob, UploaderMetadata, uploadFile } from "./uploader";
import { Dictionary } from "lodash";
import { StoredAttachmentEntry } from "@auditcloud/shared/lib/schemas";
import {
  StoredAttachmentEntryWithContext,
  StoredAttachmentEntryMapWithContext,
  EntryAttachmentsPermissions,
} from "@auditcloud/shared/lib/types/Attachments";

export interface UpdateCommentType {
  id: string;
  comment: string;
  context: StoredAttachmentEntryWithContext["context"];
}
export interface AccessAttachmentType {
  id: string;
  context: StoredAttachmentEntryWithContext["context"];
}

export interface EntryAttachmentsVuexApi {
  readonly getEntityAttachments: StoredAttachmentEntryMapWithContext;
  readonly getEntityAttachmentPermissions: EntryAttachmentsPermissions;
  attachFilesToEntry(payload: Attachment[]): Promise<string[]>;
  updateAttachmentComment(payload: UpdateCommentType): Promise<void>;
  downloadFileFromEntry(payload: AccessAttachmentType): Promise<string>;
  deleteFileFromEntry(payload: AccessAttachmentType): Promise<void>;
}

export type Rdu = Dictionary<
  string | number | boolean | firebase.firestore.FieldValue
>;

export async function attachFilesToEntity(
  attachments: Attachment[],
  uploadMetaData: UploaderMetadata
) {
  const attachmentMap: { [key: string]: StoredAttachmentEntry } = {};

  const attachmentGroup = attachments.map(attachment => ({
    mediaId: uuidv4(),
    attachment,
  }));

  const fileUploadProcesses = await Promise.all(
    attachmentGroup.map(({ attachment }) => {
      if (attachment.data instanceof File) {
        return uploadFile(attachment.data, uploadMetaData, attachment.comment);
      } else {
        return uploadBlob(
          attachment.data,
          attachment.name,
          uploadMetaData,
          attachment.comment
        );
      }
    })
  );

  fileUploadProcesses.forEach((upload, idx) => {
    if (upload.isOk()) {
      const resData = upload.value.data;
      attachmentMap[resData.id] = {
        ...resData.attachmentEntry,
      };
    }
  });

  return attachmentMap;
}
