import firebase from "firebase/compat/app";
import Vue from "vue";
import { mapValues } from "lodash";
import { State } from "./types";
import { contentTranslation } from "../../../plugins/ContentTranslation";
import { idable } from "@auditcloud/shared/lib/types/common";
import { UserDoc } from "@auditcloud/shared/lib/schemas";

const mutations = {
  setUser(state: State, payload: null | idable<UserDoc>) {
    state.user = payload;
  },
  SET_FIREBASE_USER(state: State, payload: null | firebase.User) {
    state.firebaseUser = payload;
  },
  SET_FIREBASE_CLAIMS(state: State, payload: any) {
    state.firebaseClaims = payload;
  },

  storeActivitiesReadTime(state: State, date: string) {
    if (state.user) {
      Vue.set(state.user, "activitiesReadTime", date);
    }
  },
  storeSystemLanguage(state, language) {
    if (state.user) {
      if (!state.user.language) {
        Vue.set(state.user, "language.system", language);
      } else {
        Vue.set(state.user.language, "system", language);
      }
      // Trigger Language Object again for reactivity
      if (state.user.language) {
        Vue.set(state.user.language, "system", language);
      }
    }
  },
  storeContentLanguage(state: State, language: string) {
    if (state.user) {
      contentTranslation.setLanguage(language);
      console.log("Contentlanguage", contentTranslation);
      if (!state.user.language) {
        Vue.set(state.user, "language.content", language);
      } else {
        Vue.set(state.user.language, "content", language);
      }
      // Trigger Language Object again for reactivity
      if (state.user.language) {
        Vue.set(state.user.language, "content", language);
      }
    }
  },
  storeCustomData(state: State, customData: UserDoc["customData"]) {
    if (state.user) {
      Vue.set(state.user, "customData", customData);
    }
  },
};

const n = mapValues(mutations, (_, key) => key);

export { n as mutationNames, mutations };
