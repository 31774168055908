









































import Vue from "vue";
import Component from "vue-class-component";

//@ts-ignore
import RadarChart from "@/components/charts/RadarChart";
import { Prop, Watch } from "vue-property-decorator";

import {
  MaturityChartData,
  typeIsMaturityChartData,
} from "@auditcloud/shared/lib/types/MaturityChart";
import { contentLanguage } from "@/plugins/ContentTranslation";
import { simpleTableDataMaturity } from "@/store/modules/auditResult/utils";
import { Getter } from "vuex-class";
import { api } from "@/store/modules/audit";
import { AuditClassClient } from "@auditcloud/shared/lib/types/AuditClass";
import { nullable } from "@auditcloud/shared/lib/types/common";
import { convertMaturityChartDataToChartData } from "@auditcloud/shared/lib/auditResult/utils";
@Component({
  components: {
    RadarChart,
  },
})
export default class AMaturityChart extends Vue {
  @Prop({
    type: Object,
    required: true,
    validator: typeIsMaturityChartData,
  })
  maturityChartData!: MaturityChartData;

  @Getter(api.getters.getAuditClass, { namespace: api.namespace })
  auditClass!: nullable<AuditClassClient>;

  @Watch("maturityChartData", { immediate: true, deep: true })
  OnMaturityChartDataChange(newVal: MaturityChartData) {
    const { chartData, chartOptions } = convertMaturityChartDataToChartData(
      newVal,
      contentLanguage()
    );
    this.chartData = chartData;
    this.chartOptions = chartOptions;
    this.tableData = simpleTableDataMaturity(newVal);
  }

  chartData: Chart.ChartData | null = null;
  chartOptions: Chart.ChartOptions | null = null;
  tableData: any = null;
}
