var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"d-flex flex-column a-gap-2 my-2",attrs:{"flat":"","tile":""}},[_c('div',{staticClass:"d-flex align-center flex-nowrap a-gap-1 mx-2"},[_c('v-card',{staticClass:"grey lighten-4 flex-grow-1",attrs:{"flat":"","rounded":"lg"}},[_c('v-text-field',{staticClass:"transparent",attrs:{"label":_vm.$t('components.controls.items_jsFacets.label'),"solo":"","flat":"","clearable":"","hide-details":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary--text",staticStyle:{"border-radius":"8px"},attrs:{"depressed":"","disabled":_vm.filterCount === 0,"text":_vm.filterCount === 0,"height":"48","min-width":"48"},on:{"click":function($event){return _vm.reset()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter-off")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("components.widgets.search_filter.clear_all_filters"))+" ")])])],1),(_vm.tags.length > 0)?_c('v-card',{staticClass:"d-flex a-gap-1 flex-wrap mx-2",attrs:{"flat":"","tile":""}},_vm._l((_vm.tags),function(tag){return _c('v-chip',{key:tag.name,staticClass:"px-2",attrs:{"close":"","label":"","small":"","color":"primary"},on:{"update:active":function($event){return _vm.unselectBucket(tag.key, tag.name)}}},[_vm._v(" "+_vm._s(_vm._f("trim")(_vm.$ft(tag.name),36))+" ")])}),1):_vm._e(),_c('v-divider'),_c('v-card',{attrs:{"flat":""}},[_c('v-list',{staticClass:"pa-0"},[_vm._l((_vm.searchResult.data.aggregations),function(facet2){return _c('v-list-group',{key:facet2.name,attrs:{"active-class":"grey lighten-4"}},[_c('v-list-item',{staticClass:"pa-0",attrs:{"slot":"activator"},slot:"activator"},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.getFacetLabel(facet2))}})],1)],1),_vm._l((_vm.customSortBuckets(
            facet2.buckets,
            _vm.configuration.aggregations[facet2.name]
          )),function(bucket){return _c('v-list-item',{key:bucket.key,staticStyle:{"min-height":"40px"},on:{"click":function($event){return _vm.toggleOption(facet2.name, bucket.key)}}},[_c('v-list-item-action',{staticClass:"ma-0 mr-3"},[_c('v-checkbox',{attrs:{"input-value":_vm.filters[facet2.name] &&
                _vm.filters[facet2.name].includes(bucket.key)}})],1),_c('v-list-item-content',{staticClass:"py-2",class:{
              'primary--text':
                _vm.filters[facet2.name] &&
                _vm.filters[facet2.name].includes(bucket.key),
            },staticStyle:{"white-space":"nowrap"},attrs:{"title":_vm.getBucketLabel(bucket)}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.getBucketLabel(bucket))}})],1)],1)})],2)}),(Object.keys(_vm.searchResult.data.aggregations).length === 0)?_c('v-list-item',[_vm._v(" "+_vm._s(_vm.$t("components.controls.items_jsFacets.no_filters_avalilable"))+" ")]):_vm._e()],2)],1),_c('v-divider')],1)}
var staticRenderFns = []

export { render, staticRenderFns }