var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"height-100"},[(_vm.isEditingTags)?_c('AAuditItemTagsDialog',{attrs:{"is-open":_vm.isEditingTags,"audit-item-ids":_vm.selectedAuditItemIds},on:{"close":function($event){_vm.isEditingTags = false;
      _vm.selectedAuditItemIds = [];}}}):_vm._e(),(_vm.isEditingNote && _vm.selectedAuditItemIds.length === 1)?_c('AAuditItemNoteDialog',{attrs:{"audit-item-id":_vm.selectedAuditItemIds[0],"is-open":_vm.isEditingNote},on:{"close":function($event){_vm.isEditingNote = false;
      _vm.selectedAuditItemIds = [];}}}):_vm._e(),_c('ATableItem',{staticStyle:{"position":"relative"},attrs:{"highlight-color":_vm.questionType ? _vm.questionType.color : null},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticClass:"font-weight-regular mr-2"},[_vm._v(" #"+_vm._s(_vm.auditItem.question.no)+" ")]),(_vm.questionType && _vm.questionType.short)?_c('AChip',{attrs:{"small":"","color":_vm.questionType.color}},[_vm._v(" "+_vm._s(_vm.questionType.short)+" ")]):_vm._e(),(_vm.categoryCount > 0)?[(_vm.hasEmbeddedCategories)?_c('ASnippetCategory',{attrs:{"filter":false,"category":_vm.auditItem.question.categories,"category-level":_vm.categoryLevel,"show-categories-in-single-row":true},on:{"filter-selected":_vm.filterCategory}}):_c('ASnippetCategoryRef',{attrs:{"filter":!_vm.isDialogOpen,"category-ref":_vm.auditItem.question.categoryRef,"category-set-id":_vm.categorySetId,"category-level":_vm.categoryLevel,"show-categories-in-single-row":true},on:{"filter-selected":_vm.filterCategory}})]:_vm._e(),(typeof _vm.auditItem.question.vda_question_scope === 'string')?_c('v-chip',{attrs:{"small":"","color":"primary"},on:{"click":_vm.filterVdaScope}},[_vm._v(" "+_vm._s(_vm.auditItem.question.vda_question_scope)+" "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-filter-outline")])],1):_vm._e(),(
          _vm.auditItem.question.vda_process &&
          typeof _vm.auditItem.question.vda_process.name === 'string'
        )?_c('v-chip',{attrs:{"small":"","color":"primary"},on:{"click":_vm.filterProcess}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-filter-outline")]),_vm._v(" "+_vm._s(_vm.auditItem.question.vda_process.name)+" ")],1):_vm._e()]},proxy:true},{key:"header-right",fn:function(){return [_vm._t("header-right"),(!_vm.readonly)?_c('div',{staticClass:"d-flex align-center"},[_vm._t("info"),_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":"0","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,1458783693)},[_c('v-list',{staticClass:"pa-0"},_vm._l((_vm.auditItemActions),function(item,index){return _c('v-list-item',{key:index,staticClass:"pa-0",staticStyle:{"min-height":"0"}},[_c('v-btn',{staticClass:"text-body-2 no-uppercase justify-end",attrs:{"color":"white","tile":"","elevation":"0","block":""},on:{"click":function($event){return item.handler([_vm.auditItem.id])}}},[_vm._v(" "+_vm._s(_vm.$ft(item.name))+" ")])],1)}),1)],1)],2):_vm._e()]},proxy:true}],null,true)},[(_vm.dimensions && _vm.dimensions.length > 0)?_c('p',{staticClass:"dimensions d-inline-block"},_vm._l((_vm.dimensions),function(dimension,idx){return _c('v-chip',{key:idx,staticClass:"mr-1 text-truncate",attrs:{"label":"","small":"","outlined":"","icon":dimension.answered,"color":"accent"}},[_vm._v(" "+_vm._s(_vm.$ft(dimension.text))+" "),(dimension.answered)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()],1)}),1):_vm._e(),(_vm.chapters.length > 0)?_c('p',[_c('span',_vm._l((_vm.chapters),function(chapter,idx){return _c('a-standard-chip',{key:idx,staticClass:"mr-1",attrs:{"chapter-ref":chapter,"filter":!_vm.isDialogOpen,"outlined":"","color":"accent","label":""},on:{"click":function($event){return _vm.filterChapter(chapter)}}})}),1)]):_vm._e(),_c('AQuestionContent',{attrs:{"tags":_vm.tags,"labels":_vm.auditItem.labels,"audit-item-proofs":_vm.auditItemProofs,"links":_vm.links,"hint":_vm.auditItem.question.hint,"text":_vm.auditItem.question.text,"show-self-assessment":_vm.selfAssessmentFeatureEnabled,"self-assessment-text":_vm.selfAssessmentFeatureEnabled && _vm.selfAssessmentIncluded
          ? _vm.auditItem.question.selfAssessmentText
          : undefined,"allow-filtering":"","notes":_vm.notesText,"filtered-text":_vm.filteredText,"search-result":_vm.searchResult,"hide-requirement-text":_vm.hideRequirementText,"question-in-selfassessment":_vm.questionInSelfassessment},on:{"filter-change":_vm.filterTag}}),(_vm.moving)?_c('v-expand-transition',[_c('v-overlay',{staticClass:"transition-fast-in-fast-out",staticStyle:{"height":"100%"},attrs:{"absolute":true,"z-index":"3"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{staticStyle:{"padding":"10px"}},[_c('v-btn',{staticStyle:{"padding":"10px"},attrs:{"color":"success"},on:{"click":_vm.moved}},[_vm._v(" "+_vm._s(_vm.$t("components.widgets.audit_overview_question.link_finding"))+" ")])],1),_c('div',{staticStyle:{"padding":"10px"}},[_c('v-btn',{on:{"click":_vm.cancelMove}},[_c('v-icon',[_vm._v("close")])],1)],1)])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }