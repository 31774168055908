import ui from "./ui.json";
import data from "./data.json";

import { SchemaMap, FormSchema } from "../../index";
import supplier from "./supplier";
import qm from "./qm";

const dialogSchema: FormSchema = {
  ui,
  data,
};

const schemaMap: SchemaMap = {
  default: dialogSchema,
  supplier,
  qm,
};
export default schemaMap;
