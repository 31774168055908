import { GetterTree } from "vuex";
import { RootState } from "../../types";
import { AutoCompletionsState } from "./types";
import {
  CustomMetadataList,
  CustomMetadataNested,
} from "@auditcloud/shared/lib/types/CustomMetadata";

const getAutoCompleteList = "getAutoCompleteList";
const getMappedAutocompletes = "getMappedAutocompletes";
const getAutocompleteData = "getAutocompleteData";

const n = {
  getAutoCompleteList,
  getMappedAutocompletes,
  getAutocompleteData,
};

const getters: GetterTree<AutoCompletionsState, RootState> = {
  [n.getAutoCompleteList](state, getters, rootState, rootGetters) {
    return Object.entries(state.autoCompletionData).map(([id, doc]) => {
      return { id, name: doc.name };
    });
  },
  [n.getMappedAutocompletes](state, getters, rootState, rootGetters) {
    return state.autoCompletionData;
  },
  [n.getAutocompleteData](state, getters, rootState, rootGetters) {
    return (id: string): null | CustomMetadataList | CustomMetadataNested => {
      return state.autoCompletionData[id]?.data ?? null;
    };
  },
};

export { n as getterNames, getters };
