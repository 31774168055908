import { Module } from "vuex";
import { AuditResultState } from "./types";
import { RootState } from "../../types";

import { getters, getterNames } from "./getters";
import { actions, actionNames } from "./actions";
import { mutations, mutationNames } from "./mutations";
import { GettersType } from "@/utils/VuexHelper";

const state = new AuditResultState();

const namespaced: boolean = true;
const modul: Module<AuditResultState, RootState> = {
  namespaced,
  getters,
  actions,
  mutations,
  state,
};

export const api = {
  namespace: "auditResult",
  mutations: mutationNames,
  getters: getterNames,
  actions: actionNames,
};

export type Getters = GettersType<typeof getters>;

export default modul;
