var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ forceSingleRow: _vm.showCategoriesInSingleRow }},_vm._l((_vm.categories),function(category,idx){return _c('v-chip',{key:idx,class:[
      {
        'max-width-chip': true,
        'cursor-style': _vm.filter,
        'text-truncate': true,
        'd-inline-block': true,
      },
      _vm.customClasses ],attrs:{"small":"","color":_vm.color,"title":_vm.categoryNames,"label":_vm.label}},[(_vm.filter)?_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-filter-outline")]):_vm._e(),_c('span',{on:{"click":function($event){return _vm.selectFilter(category)}}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v(" "+_vm._s(_vm.$ct(category.name))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$ct(category.short || ""))+" ")])])],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }