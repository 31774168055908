






























































import Vue from "vue";

// canvas-confetti: Removed because it causes CSP warnings.
// Enable temporarily if needed, or use a more security-friendly option.
// More information: https://github.com/catdad/canvas-confetti/issues/139
//
// import confetti from "canvas-confetti";

import { Component, Prop } from "vue-property-decorator";
import AAnniversaryEventDialog from "@/components/dialogs/AAnniversaryEventDialog.vue";
import { Action, Getter } from "vuex-class";
import { api as userApi, Getters as UserGetters } from "@/store/modules/user";
import { api as confApi } from "@/store/modules/configuration";
import moment, { Moment } from "moment";

const ESTABLISHED_YEAR = 2019;
const SHOW_FROM_DATE: string = "2021-10-01";
const SHOW_UNTIL_DATE: string = "2021-10-31";

@Component({ components: { AAnniversaryEventDialog } })
export default class AAnniversaryEventWidget extends Vue {
  isAvailable: boolean = false;
  isOpen: boolean = false;
  isWiggling: boolean = false;

  checkIsAvailableTimeout?: ReturnType<typeof setTimeout>;
  wiggleStartTimeout?: ReturnType<typeof setTimeout>;
  wiggleEndTimeout?: ReturnType<typeof setTimeout>;

  @Prop({ type: Boolean, default: false })
  icon!: boolean;

  @Getter(confApi.getters.getAnniversaryEventShowFrom, {
    namespace: confApi.namespace,
  })
  getAnniversaryEventShowFrom!: string | null;

  @Getter(confApi.getters.getAnniversaryEventShowUntil, {
    namespace: confApi.namespace,
  })
  getAnniversaryEventShowUntil!: string | null;

  @Getter(userApi.getters.getCustomData, {
    namespace: userApi.namespace,
  })
  getCustomData!: UserGetters["getCustomData"];

  @Getter
  mounted() {
    this.checkIsAvailable();
  }

  @Action(userApi.actions.storeCustomData, { namespace: userApi.namespace })
  storeCustomData!: (customData: UserGetters["getCustomData"]) => void;

  get wasSeen(): boolean {
    return this.getCustomData?.["anniversaryEventYearSeen"] === this.year;
  }

  get year(): number {
    return new Date().getFullYear();
  }

  get age(): number {
    return this.year - ESTABLISHED_YEAR;
  }

  checkIsAvailable() {
    const now = new Date().toISOString();
    const showFrom = this.getAnniversaryEventShowFrom ?? SHOW_FROM_DATE;
    const showUntil = this.getAnniversaryEventShowUntil ?? SHOW_UNTIL_DATE;
    this.isAvailable = showFrom <= now && now <= showUntil;
    if (this.isAvailable && this.icon && !this.wasSeen) {
      // wiggle once after startup, then schedule wiggling every day at noon
      this.wiggleStartTimeout = setTimeout(() => {
        this.wiggleIcon();
      }, 2000);
    }

    // check again twice an hour
    if (this.checkIsAvailableTimeout) {
      clearTimeout(this.checkIsAvailableTimeout);
    }
    this.checkIsAvailableTimeout = setTimeout(() => {
      this.checkIsAvailable();
    }, 30 * 60 * 1000);
  }

  handleClose() {
    this.storeCustomData({ anniversaryEventYearSeen: 2021 });
    this.isOpen = false;
  }

  wiggleIcon() {
    // start wiggle animation:
    this.isWiggling = true;

    this.wiggleEndTimeout = setTimeout(() => {
      // end wiggle animation:
      this.isWiggling = false;
      this.clearTimeouts();

      // ...and schedule next wiggle:
      const nextNoon = this.nextNoon();
      const hourMs = 60 * 60 * 1000;
      const deltaMs = Math.max(hourMs, nextNoon.diff(moment()));
      console.log(`AnniversaryEventWidget: next wiggle in ${deltaMs} ms`);
      this.wiggleStartTimeout = setTimeout(() => {
        this.wiggleIcon();
      }, deltaMs);
    }, 2000);
  }

  nextNoon(): Moment {
    const isPm = moment().hour() >= 12;
    const noon = moment();
    if (isPm) {
      noon.add(1, "day");
    }
    noon.set("hours", 12);
    noon.set("minutes", 0);
    noon.set("seconds", 0);
    return noon;
  }

  clearTimeouts() {
    if (this.wiggleStartTimeout) {
      clearTimeout(this.wiggleStartTimeout);
    }
    if (this.wiggleEndTimeout) {
      clearTimeout(this.wiggleEndTimeout);
    }
    if (this.checkIsAvailableTimeout) {
      clearTimeout(this.checkIsAvailableTimeout);
    }
  }

  beforeDestroy() {
    this.clearTimeouts();
  }

  async openDialog() {
    const confettiOptions = {
      disableForReducedMotion: true,
      particleCount: 7,
      zIndex: 99999,
      drift: -1,
    };
    const durationMs = 2 * 1000;
    const showDialogAt = Date.now() + durationMs / 5;
    const finishAt = Date.now() + durationMs;

    const runFrame = () => {
      // see above for note on canvas-confetti:
      // confetti({
      //   ...confettiOptions,
      //   angle: 60,
      //   spread: 55,
      //   origin: {
      //     x: 0 - Math.random() * 0.2,
      //     y: 0.4 + Math.random() * 0.2,
      //   },
      // });
      // confetti({
      //   ...confettiOptions,
      //   angle: 120,
      //   spread: 55,
      //   origin: {
      //     x: 1 + Math.random() * 0.2,
      //     y: 0.4 + Math.random() * 0.2,
      //   },
      // });

      const now = Date.now();
      if (now > showDialogAt && !this.isOpen) {
        this.isOpen = true;
      }
      // keep going until we are out of time
      if (now < finishAt) {
        requestAnimationFrame(runFrame);
      }
    };

    runFrame();
  }
}
