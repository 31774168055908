












import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

import {
  api as measureApi,
  Getters as MeasureGetters,
} from "@/store/modules/measure";

import {
  State,
  StepConfigSimpleMessage,
  StepType,
} from "@auditcloud/shared/lib/workflow/types/State";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";

const measureModule = namespace(measureApi.namespace);

@Component({
  components: {},
})
export default class AMeasureProcessStepPreviewSimpleMessage extends Vue {
  @Prop({
    type: Object,
  })
  readonly state!: State<MeasureProcessDocument> | null;

  /**
   * true if pre-existing data from the measure process should be shown
   * (so that inputs of older measures before the workflow UI are not missing).
   */
  @Prop({
    type: Boolean,
    required: true,
  })
  readonly withCurrentProcessProperties!: boolean;

  @measureModule.Getter(measureApi.getters.getCurrentMeasure, {
    namespace: measureApi.namespace,
  })
  readonly currentMeasure!: MeasureGetters["getCurrentMeasure"];

  get stepConfig(): StepConfigSimpleMessage {
    const stepConfig = this.state?.step;
    if (stepConfig?.type === StepType.SIMPLE_MESSAGE) {
      return stepConfig;
    }
    return { type: StepType.SIMPLE_MESSAGE };
  }

  get description(): string | null {
    const processProperty = this.stepConfig.description?.processProperty;
    if (processProperty && this.withCurrentProcessProperties) {
      return this.currentMeasure?.[processProperty] ?? null;
    }
    return null;
  }
}
