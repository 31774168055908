import firebase from "firebase/compat/app";

import { mapValues } from "lodash";
import { State } from "./types";
import { RootState } from "../../types";
import { ActionTypeContext, actionNs } from "@/utils/VuexHelper";
import { api as auditApi } from "../audit";
import { Getters } from ".";
import { AuditItemWithId } from "@auditcloud/shared/lib/utils/audit/types";
import { updateManuallyConsideredAuditItem } from "../audit/utils";
import { AuditStatusId } from "@auditcloud/shared/lib/constants";

type Context = ActionTypeContext<State, RootState, Getters>;

const actions = {
  async manuallyAddToConsideredAuditItems(
    { rootGetters }: Context,
    auditItemIds: Array<AuditItemWithId["id"]>
  ) {
    console.log("manuallyAddToConsideredAuditItems", auditItemIds);
    const auditId = rootGetters[
      actionNs(auditApi, auditApi.getters.getAuditId)
    ] as string | null;

    if (auditId) {
      const db = firebase.firestore();
      return await updateManuallyConsideredAuditItem(
        auditId,
        auditItemIds,
        "include",
        rootGetters,
        { db },
        auditMetadata => {
          return (
            auditMetadata.workflow.status === AuditStatusId.Execution ||
            auditMetadata.workflow.status === AuditStatusId.Wrapup
          );
        },
        true
      );
    } else {
      throw new Error("no-audit-active");
    }
  },
};

const n = mapValues(actions, (_, key) => key);

export { n as actionNames, actions };
