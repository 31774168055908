















import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import {
  AuditStandardRef,
  TranslateableText,
} from "@auditcloud/shared/lib/schemas";
import {
  I18nKey,
  I18nInline,
  ConfigurableGetter,
  getterPath,
} from "@auditcloud/shared/lib/types/common";

import { api } from "@/store/modules/standards";

import { typeIsNamedRef } from "@auditcloud/shared/lib/utils/type-guards";
import { isMap, isPlainObject, isArray, isFunction } from "lodash";

@Component({
  name: "AStandardSelect",
})
export default class AStandardSelect extends Vue {
  xValue: AuditStandardRef[] = [];
  @Prop({
    type: Array,
    default: () => [],
  })
  value!: AuditStandardRef[];

  @Prop({
    type: [String, Object],
    default() {
      return {
        "i18n-key": "schema_forms.template.standards_label",
      };
    },
  })
  label!: TranslateableText | I18nKey | I18nInline;

  @Prop({
    type: [String, Object],
    default: null,
  })
  placeholder!: TranslateableText | I18nKey | I18nInline | null;

  @Prop({
    type: Object,
    default() {
      return {
        namespace: api.namespace,
        name: api.getters.getStandardList,
      };
    },
    /**
      Im Metadataform
      {
        namespace: "templates",
        name: "getStandardsListForAuditClass",
        id: "qm"
      }
     */
  })
  itemsGetter!: ConfigurableGetter; /// Getter welcher Basis Items bereitstellt

  @Watch("value", {
    immediate: true,
    deep: true,
  })
  onValueChanged(newVal: AuditStandardRef[], oldVal: AuditStandardRef[]) {
    console.log("onValueChanged", newVal, oldVal);
    this.xValue = newVal;
  }

  get defaultedPlaceHolder() {
    if (this.placeholder) {
      return this.$ft(this.placeholder);
    } else {
      return undefined;
    }
  }

  get getterResult() {
    const path = getterPath(this.itemsGetter);
    const getterResult: any = this.$store.getters[path];

    return getterResult;
  }

  get items(): AuditStandardRef[] {
    const getterResult = this.getterResult;
    const id = this.itemsGetter.id;
    let result: any[] | null = null;

    console.log("AuditStandardRef", getterResult, id);

    if (isArray(getterResult)) {
      result = getterResult;
    } else if (typeof id === "string") {
      if (isFunction(getterResult)) {
        result = getterResult(id);
      } else if (isMap(getterResult)) {
        result = getterResult.get(id);
      } else if (isPlainObject(getterResult)) {
        result = getterResult[id];
      }
    }

    if (Array.isArray(result) && result.every(typeIsNamedRef)) {
      return result;
    } else {
      console.error(
        "ItemsGetter for AStandardSelect doesn't return valid AuditStandardRef"
      );
      return [];
    }
  }

  selectionChanged(data: AuditStandardRef[]) {
    this.$emit("input", data);
  }
}
