var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isVdaAudit)?_c('div',[(false)?[_c('h3',{staticClass:"pa-4"},[_vm._v("VDA")]),_c('v-simple-table',[_c('tbody',[_vm._l((_vm.currentScoreCalculation),function(item,index){return [(item.header)?_c('tr',{key:index},[_c('td',{staticClass:"text-left",attrs:{"colspan":"2"}},[_c('div',{staticClass:"subtitle-1 text--primary font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$ft(item.header))+" ")])])]):_vm._e(),(!item.header && !item.divider)?_c('tr',{key:index},[_c('td',{staticClass:"text-left"},[_c('div',{staticClass:"subtitle-1 text--primary"},[_vm._v(" "+_vm._s(_vm.$ft(item.title))+" ")]),(item.subtitle)?_c('div',{staticClass:"subtitle-2 text--secondary"},[_vm._v(" "+_vm._s(_vm.$ft(item.subtitle))+" ")]):_vm._e()]),_c('td',{staticClass:"text-right"},[(_vm.$ft(item.score).length <= 2)?_c('v-avatar',{staticStyle:{"-webkit-box-pack":"center","-ms-flex-pack":"center","justify-content":"center"},attrs:{"size":"32","color":item.color}},[_vm._v(" "+_vm._s(_vm.$ft(item.score))+" ")]):_c('strong',{staticClass:"subtitle-1 text--primary"},[_vm._v(" "+_vm._s(_vm.$ft(item.score))+" ")])],1)]):_vm._e()]})],2)])]:_vm._e(),_c('v-dialog',{attrs:{"fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"pa-4"},[_c('v-btn',_vm._g({attrs:{"color":"red lighten-2","dark":""}},on),[_vm._v(" "+_vm._s(_vm.$t("components.widgets.audit_report_summary.vda_calculation"))+" ")])],1)]}}],null,false,4066298481),model:{value:(_vm.vdaCalcChainDialog),callback:function ($$v) {_vm.vdaCalcChainDialog=$$v},expression:"vdaCalcChainDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.$t("components.widgets.audit_report_summary.vda_calculation"))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.vdaCalcChainDialog = false}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-card-text',[_c('div',{staticStyle:{"overflow-x":"scroll"}},_vm._l((_vm.vdaResultCalcChain),function(group,gidx){return _c('table',{key:gidx},_vm._l((group.list),function(rows,ridx){return _c('tr',{key:ridx},[_c('td',{staticClass:"title"},[_vm._v(_vm._s(_vm.$ft(group.category.short)))]),_vm._l((rows),function(col,cidx){return _c('td',{key:cidx},[_vm._v(" "+_vm._s(col.text)+" "),_c('table',{staticClass:"vdaresult",attrs:{"border":"0","cellspacing":"0","cellpadding":"0"}},[_c('tr',[_vm._l((col.items),function(item,iidx){return _c('td',{key:'n' + iidx,class:{
                        star: item.star,
                        caption: true,
                        no: true,
                        'px-1': true,
                      }},[_vm._v(" "+_vm._s(item.no)+" ")])}),_c('td'),_c('td'),_c('td')],2),_c('tr',[_vm._l((col.items),function(item,iidx){return _c('td',{key:'v' + iidx,class:{
                        star: item.star,
                        points: true,
                        'px-1': true,
                      }},[_vm._v(" "+_vm._s(item.valueDisplay)+" ")])}),_c('td',{staticClass:"space"}),_c('td',[_vm._v(" E "),_c('sub',[_vm._v(_vm._s(col.listId.split("_")[1]))])]),_c('td',[_vm._v(_vm._s(col.compliancePercent.toFixed(1))+"%")])],2)])])})],2)}),0)}),0)])],1)],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }