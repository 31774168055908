












import { AuditStatusId } from "@auditcloud/shared/lib/constants";
import { AuditMetadataDoc } from "@auditcloud/shared/lib/schemas";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AIncludeSelfAssessmentCheckbox extends Vue {
  @Prop({
    type: Object,
    default: () => ({}),
  })
  auditMeta!: Partial<AuditMetadataDoc>;

  get xValue() {
    return this.auditMeta.includeSelfAssessment ?? false;
  }

  set xValue(val: boolean) {
    this.$emit("input", val);
  }

  get readonly() {
    const EDITABLE_STATES: string[] = [
      AuditStatusId.Planning,
      AuditStatusId.Preparation,
      AuditStatusId.Execution,
    ];
    const auditStatusId = this.auditMeta.workflow?.status ?? "";
    return !EDITABLE_STATES.includes(auditStatusId);
  }
}
