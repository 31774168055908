import axios from "axios";
import { err, ok } from "neverthrow";
import {
  ApiV0FileManagementUploadAnnounceRequest as UploadAnnounceRequest,
  ApiV0FileManagementUploadAnnounceResponse as UploadAnnounceResponse,
  ApiV0FileManagementUploadPersistResponse as UploadPersistResponse,
  ApiV0FileManagementUploadPersistRequest as UploadPersistRequest,
} from "@auditcloud/shared/lib/schemas";
import { fileManagementEndpointUrl } from "./HttpApi";
import { isEmpty } from "lodash";

export type UploaderMetadata = Pick<UploadAnnounceRequest, "target">;

export async function uploadBlob(
  blob: Blob,
  filename: string,
  metadata: UploaderMetadata,
  comment: string = ""
) {
  try {
    // fetch upload link from backend
    const uploadAnnounceUrl = fileManagementEndpointUrl("upload", "announce");
    const announcementRequestData: UploadAnnounceRequest = {
      name: filename,
      size: blob.size,
      mimeType: isEmpty(blob.type) ? "application/octet-stream" : blob.type,
      comment: comment,
      ...metadata,
    };
    const uploadAnnounceResponse = await axios.post<UploadAnnounceResponse>(
      uploadAnnounceUrl,
      announcementRequestData
    );

    // upload to storage /w signed url
    const fileUploadUrl = uploadAnnounceResponse.data.uploadUrl;
    const fileUploadHeaders = {
      // super important, otherwise you'll get weird errors because browsers
      // set the header themselves based on what they think the file type is
      "Content-Type": uploadAnnounceResponse.data.attachmentEntry.type,
    };

    await axios.put(fileUploadUrl, blob, {
      headers: fileUploadHeaders,
    });

    // persist data via backend, after successful upload
    const uploadPersistUrl = fileManagementEndpointUrl("upload", "persist");
    const uploadPersistPayload: UploadPersistRequest = {
      ...uploadAnnounceResponse.data,
      target: metadata.target,
    };
    await axios.post<UploadPersistResponse>(
      uploadPersistUrl,
      uploadPersistPayload
    );

    return ok(uploadAnnounceResponse);
  } catch (error) {
    return err(error);
  }
}

export async function uploadFile(
  file: File,
  metadata: UploaderMetadata,
  comment: string = ""
) {
  return uploadBlob(file, file.name, metadata, comment);
}
