









































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  TodoAny,
  TodoActionSignature,
} from "@auditcloud/shared/lib/utils/type-guards";

import { api as activityApi } from "@/store/modules/activities";
import { api as appApi, SetTransitionPanelPayload } from "@/store/modules/app";
import { api as confApi } from "@/store/modules/configuration";

import AProfileWidget from "@/components/widgets/AProfileWidget.vue";
import AAnniversaryEventWidget from "@/components/widgets/AAnniversaryEventWidget.vue";
import AMenuContent from "@/components/app/MenuContent.vue";

@Component({
  components: {
    AMenuContent,
    AProfileWidget,
    AAnniversaryEventWidget,
  },
})
export default class MenuPanel extends Vue {
  @Getter(activityApi.getters.getCountOfNewActivities, {
    namespace: activityApi.namespace,
  })
  newActivities!: number;

  @Getter(confApi.getters.app, { namespace: confApi.namespace })
  app!: TodoAny;

  @Getter(appApi.getters.isLoading, { namespace: appApi.namespace })
  isLoading!: TodoAny;

  get config() {
    console.log("App Config", this.app);
    return this.app || { components: { search: false } };
  }
  get currentActivities() {
    return this.newActivities || "";
  }
  get userActions() {
    if (this.$store.getters["users/isAuthenticated"]) {
      return [{ icon: "exit_to_app", text: "Sign out", route: "/signout" }];
    } else {
      return [{ icon: "lock_open", text: "Sign in", route: "/signin" }];
    }
  }

  @Action(appApi.actions.toggleDrawer, { namespace: appApi.namespace })
  toggleDrawer!: TodoActionSignature;

  @Action(appApi.actions.setTransitionPanel, { namespace: appApi.namespace })
  setTransitionPanel!: (payload: SetTransitionPanelPayload) => Promise<void>;

  openActivitiyStream() {
    this.setTransitionPanel({
      component: "AuditActivityStream",
      title: "Activity Stream",
    });
  }
}
