














import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";

import {
  AuditItemType,
  AuditItemTypeMap,
} from "@auditcloud/shared/lib/types/ItemTypes";

import { api as confApi } from "@/store/modules/configuration";
import { AuditItemTypeConfigMap } from "@auditcloud/shared/lib/types/Configuration/defaults";

/*      const auditItemTypeConfigMap = rootGetters[
        getterNs(confApi, confApi.getters.getAuditItemTypesMappedByAuditClass)
      ] as AuditItemTypeConfigMap;

      const auditItemConfig = auditItemTypeConfigMap[auditClass];
      return auditItemConfig || null;*/
@Component({})
export default class AAuditItemTypeSelect extends Vue {
  xValue: string | null = null;

  @Prop({
    type: String,
    required: true,
  })
  auditClassId!: string | null;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: String,
    default: null,
  })
  value!: string | null;

  @Watch("value", {
    immediate: true,
  })
  onValueChanged(newValue: string | null) {
    this.xValue = newValue;
  }

  changed(eventData: string | null | undefined) {
    this.xValue = eventData || null;
    this.$emit("input", eventData || null);
  }

  @Getter(confApi.getters.getAuditItemTypesMappedByAuditClass, {
    namespace: confApi.namespace,
  })
  auditItemTypesMappedByAuditClass!: AuditItemTypeConfigMap;

  get auditItemTypeConfig() {
    if (this.auditClassId !== null) {
      return this.auditItemTypesMappedByAuditClass[this.auditClassId] || null;
    } else {
      return null;
    }
  }

  get mappedAuditItemTypes(): AuditItemTypeMap {
    if (this.auditItemTypeConfig !== null) {
      return this.auditItemTypeConfig.types;
    } else {
      return {};
    }
  }

  get color() {
    const selectedType = this.mappedAuditItemTypes[this.xValue || ""];
    console.log("Color", selectedType);
    if (selectedType) {
      return selectedType.color;
    } else {
      return "";
    }
  }

  get auditItemTypes() {
    return Object.values(this.mappedAuditItemTypes);
  }

  itemText(item: AuditItemType) {
    console.log("itemText", item);
    return [
      this.$ft(item.short).trim(),
      this.$ft(item.text).trim(),
      `(${item.value})`,
    ]
      .filter(v => v !== "")
      .join(" - ");
  }
}
