

















































import Vue from "vue";
import { escape } from "lodash";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { VAvatar, VIcon, VBtn } from "vuetify/lib";
import { MeasureActivityComment } from "@/types/measure-activities";

import ADateChip from "@auditcloud/components/snippets/ADateChip.vue";
import { displayName2AvatarText } from "@/utils/user_resolver";

@Component({
  components: { VAvatar, VIcon, VBtn, ADateChip },
})
export default class AMeasureActivityComment extends Vue {
  //missingDefault
  @Prop({
    type: Object,
    required: true,
  })
  readonly activity!: MeasureActivityComment;

  get firstLetters() {
    return displayName2AvatarText(this.activity.actor.displayName);
  }
}
