








































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Action } from "vuex-class";
import { TodoActionSignature } from "@auditcloud/shared/lib/utils/type-guards";

import { api } from "@/store/modules/standards";
import VueCsvImport from "vue-csv-import";
import { VForm } from "../types";
import { typeIsTranslateableText } from "@auditcloud/shared/lib/types/common";

function ChaptersToCsv(Chapters) {
  let lines = '"id";"description"';
  for (let Chapter of Chapters) {
    lines +=
      '\n"' +
      Chapter.id.replace("\\", "\\\\").replace('"', '\\"') +
      '";"' +
      Chapter.description.replace("\\", "\\\\").replace('"', '\\"') +
      '"';
    console.log(Chapter);
  }

  return lines;
}

function normalizeChapters(Chapters) {
  return Chapters.map(Chapter => {
    return {
      id: Chapter.id.trim(),
      description: Chapter.description.trim(),
    };
  });
}
function validateChapters(chapters) {
  console.log("Validate Chapters", typeof chapters);
  if (!(chapters instanceof Array)) {
    throw "root must be an array";
  }
  if (chapters.length < 1) {
    throw "at least one chapter required";
  }

  for (let chapter of chapters) {
    if (typeof chapter.id !== "string") {
      throw "id must be a string";
    }
    if (!typeIsTranslateableText(chapter.description)) {
      throw "description must be a string";
    }
  }
}

@Component({
  components: {
    VueCsvImport,
  },
})
export default class StandatdImportDialog extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  dialogStandardImport!: boolean;

  nameRules: any = [
    v => !!v || "Name is required",
    v =>
      (v && 2 <= v.trim().length && v.trim().length <= 32) ||
      "Name length invalid 2-32 Characters",
  ];
  idRules: any = [
    v =>
      (2 <= v.trim().length && v.trim().length <= 32) ||
      v.trim().length === 0 ||
      "Id length invalid 2-32 Characters",
    v =>
      /^([A-Z][A-Z0-9\-_]{1,31})?$/i.test(v.trim()) ||
      "Id must start with a letter followed by digiest or letters",
  ];
  descRules: any = [
    v => !!v || "Description is required",
    v =>
      (v && 5 <= v.trim().length && v.trim().length <= 256) ||
      "Description length invalid 5-256 Characters",
  ];
  chapterRules: any = [
    v => {
      try {
        let chapters = JSON.parse(v);

        validateChapters(chapters);
      } catch (e) {
        if (typeof e === "object") {
          return e.message;
        } else {
          return e;
        }
      }
      return true;
    },
  ];
  editStandard: any = {
    id: "",
    name: "",
    desc: "",
    isJson: true,
    valid: false,
    chapters: null,
  };

  get standard() {
    return this.editStandard;
  }
  get chapters() {
    if (this.editStandard.chapters === null) {
      return "";
    } else if (this.editStandard.isJson) {
      return JSON.stringify(this.editStandard.chapters, undefined, 2);
    } else {
      return ChaptersToCsv(this.editStandard.chapters);
    }
  }
  set chapters(value) {
    this.editStandard.valid = false;

    try {
      this.editStandard.chapters = JSON.parse(value);
      this.editStandard.isJson = true;
      validateChapters(this.editStandard.chapters);
      this.editStandard.chapters = normalizeChapters(
        this.editStandard.chapters
      );
      this.editStandard.valid = true;
    } catch (e) {
      console.log(e);
      this.editStandard.valid = false;
    }
  }

  get chaptersCSV() {
    return this.editStandard.chapters;
  }

  set chaptersCSV(value) {
    this.editStandard.valid = false;

    try {
      this.editStandard.chapters = value;
      validateChapters(this.editStandard.chapters);
      this.editStandard.chapters = normalizeChapters(
        this.editStandard.chapters
      );
      this.editStandard.valid = true;
    } catch (e) {
      console.log(e);
      this.editStandard.valid = false;
    }
  }
  @Action(api.actions.importStandards, { namespace: api.namespace })
  importStandards!: TodoActionSignature;

  close() {
    this.$emit("update:dialogStandardImport", false);
  }
  importStandard() {
    if ((this.$refs.form as VForm).validate()) {
      const payload = {
        data: {
          id:
            this.editStandard.id.trim().length !== 0
              ? this.editStandard.id.trim()
              : null,
          name: this.editStandard.name.trim(),
          description: this.editStandard.desc.trim(),
          chapters: this.editStandard.chapters,
        },
      };

      this.importStandards(payload)
        .then(success => {
          console.log("ImportData: OK", success);
          this.close();
        })
        .catch(reason => {
          console.log("ImportData: Failed", reason);
        });
    } else {
      console.log("ImportData: Invalid");
    }
  }
}
