import { State } from "./types";
import { unlistenerFunction, WatchedDocument } from "@/utils/firestore";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import { defaultDocType } from "@auditcloud/shared/lib/types/Audit/types";
import {
  MeasureProcessStepDoc,
  TranslateableText,
} from "@auditcloud/shared/lib/schemas";
import { idable } from "@auditcloud/shared/lib/types/common";
import { mapValues } from "lodash";

const mutations = {
  SET_MEASURE_ID(state: State, measureId: string) {
    if (state.Document?.id !== measureId) {
      const prevUnlistener = state.Unlistener;
      state.Document = defaultDocType(measureId);
      state.Unlistener = null;
      if (prevUnlistener) {
        prevUnlistener();
      }
    }
  },
  SET_MEASURE_ACCESS_DENIED(state: State, measureAccessDenied: boolean) {
    state.measureAccessDenied = measureAccessDenied;
  },
  SET_ACTIVE_TRANSITION_ID(
    state: State,
    transitionId: State["activeTransitionId"]
  ) {
    state.activeTransitionId = transitionId;
  },
  SET_MEASURE_UNLISTENER(state: State, measuresUnlistener: unlistenerFunction) {
    const prevUnlistener = state.Unlistener;
    state.Unlistener = measuresUnlistener;
    if (prevUnlistener) {
      prevUnlistener();
    }
  },
  SET_MEASURE_METADATA(
    state: State,
    data: WatchedDocument<MeasureProcessDocument | null>
  ) {
    console.log("SET_MEASURE_METADATA", state, data);
    state.Document = data;
  },
  CLEAR_MEASURE(state: State) {
    const prevUnlistener = state.Unlistener;
    state.Unlistener = null;
    state.Document = defaultDocType();

    if (prevUnlistener) {
      prevUnlistener();
    }
  },
  SET_MEASURE_TRANSITIONS(
    state: State,
    transitions: State["measureTransitions"]
  ) {
    state.measureTransitions = transitions;
  },
  SET_MEASURE_PROCESS_STEPS(
    state: State,
    processSteps: idable<MeasureProcessStepDoc>[]
  ) {
    console.log("SET_MEASURE_PROCESS_STEPS", state, processSteps);
    state.processSteps = processSteps;
  },
  SET_CURRENT_STEP_DIRTY(state: State, isDirty: boolean) {
    state.currentStepIsDirty = isDirty;
  },
  SET_API_ERRORS(state: State, apiErrors: TranslateableText[]) {
    state.apiErrors = apiErrors;
  },
};

const n = mapValues(mutations, (_, key) => key);

export { n as mutationNames, mutations };
