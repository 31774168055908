













































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import {
  emptyVdaMatrixData,
  VdaMatrixData,
} from "@auditcloud/shared/lib/components/types";
import { AuditStatusId } from "@auditcloud/shared/lib/constants";
import { AuditMetadataDoc } from "@auditcloud/shared/lib/schemas";

@Component({})
export default class VdaControl extends Vue {
  xValue: VdaMatrixData = emptyVdaMatrixData();
  errmsgProcesses: string = "";
  errmsgProducts: string = "";
  errmsgMatrix: string = "";

  @Prop({
    default: () => ({}),
    type: Object,
  })
  readonly auditMeta!: Partial<AuditMetadataDoc>;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly readonly!: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly error!: boolean;

  @Prop({
    default: "",
    type: String,
  })
  readonly errorMessages!: string;

  @Watch("value", {
    deep: true,
    immediate: true,
  })
  onValueChanged(newVal: VdaMatrixData) {
    this.xValue = newVal;
  }

  @Watch("xValue", {
    deep: true,
    immediate: true,
  })
  onXValueChanged(newVal: VdaMatrixData) {
    this.$emit("input", newVal);
  }

  get value() {
    return this.auditMeta.vda ?? emptyVdaMatrixData();
  }

  get xReadonly() {
    if (this.readonly) {
      return true;
    }
    const auditStatusId = this.auditMeta.workflow?.status ?? "";
    const preparationStep = this.auditMeta.auditPreparation?.step;
    const editable =
      auditStatusId === AuditStatusId.Planning ||
      (auditStatusId === AuditStatusId.Preparation &&
        preparationStep === "preselection");
    return !editable;
  }

  get errProcesses(): boolean {
    if (
      this.error &&
      (this.value.processes.length < 1 || this.value.processes.length > 10)
    ) {
      this.errmsgProcesses = this.$t(
        "schema_forms.common.err_input_control"
      ).toString();
      return true;
    } else {
      this.errmsgProcesses = "";
      return false;
    }
  }

  get errProducts(): boolean {
    if (
      this.error &&
      (this.value.products.length < 1 || this.value.products.length > 10)
    ) {
      this.errmsgProducts = this.$t(
        "schema_forms.common.err_input_control"
      ).toString();
      return true;
    } else {
      this.errmsgProducts = "";
      return false;
    }
  }

  get errMatrix(): boolean {
    if (this.error && this.value.matrix.length < 1) {
      this.errmsgMatrix = this.$t(
        "schema_forms.common.err_selection_matrix"
      ).toString();
      return true;
    } else {
      this.errmsgMatrix = "";
      return false;
    }
  }
}
