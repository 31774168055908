import { RootGetters, RootState } from "@/store/types";
import { GtmIdContainer } from "@gtm-support/vue2-gtm";
import { mapValues } from "lodash";
import Vue from "vue";
import { Getters } from ".";
import { State } from "./types";

type Getter<R> = (
  state: State,
  getters: Getters,
  rootState: RootState,
  rootGetters: RootGetters
) => R;

const gtmIsEnabled: Getter<boolean> = state => {
  return Vue.gtm.enabled() ?? false;
};

const gtmId: Getter<string | string[] | GtmIdContainer[] | null> = state => {
  return Vue.gtm.id ?? null;
};

const getters = {
  gtmIsEnabled,
  gtmId,
};

const n = mapValues(getters, (_, key) => key);

export { n as getterNames, getters };
