
































































import Vue from "vue";
import Component from "vue-class-component";

import BubbleChart from "@/components/charts/BubbleChart";
import { Prop } from "vue-property-decorator";

import { convertUrgencyChartDataToChartData } from "@/store/modules/auditResult/utils";
import { UrgencyChartData } from "@auditcloud/shared/lib/types/UrgencyChart";
import { Chart } from "chart.js";

@Component({
  components: {
    BubbleChart,
  },
})
export default class AUrgencyChart extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  urgencyChartData!: UrgencyChartData;

  get componentData() {
    const newData = this.urgencyChartData;
    return convertUrgencyChartDataToChartData(newData, this.$i18n.locale);
  }

  get chartData() {
    return this.componentData.chartData;
  }
  get chartOptions() {
    return this.componentData.chartOptions;
  }
  get tableData() {
    return this.componentData.tableData;
  }

  readonly bubbleDataLabelsPlugin = {
    id: "a-bubble-data-lables",
    afterDatasetsDraw: function (chart, easing) {
      const ctx = chart.ctx;

      if (!ctx || !chart.data.datasets) {
        return;
      }

      chart.data.datasets.forEach(function (dataset: any, i) {
        const meta = chart.getDatasetMeta(i);
        if (meta.type === "bubble") {
          //exclude scatter
          meta.data.forEach(function (element: any, index) {
            // Draw the text in black, with the specified font
            ctx.fillStyle = "rgb(0, 0, 0, 0.5)";
            const fontSize = 13;
            const fontStyle = "normal";
            const fontFamily = "'Roboto','Arial','sans-serif'";
            ctx.font = Chart.helpers.fontString(
              fontSize,
              fontStyle,
              fontFamily
            );

            // Make sure alignment settings are correct
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";

            const position = element.tooltipPosition();
            ctx.fillText(dataset.title, position.x, position.y);
          });
        } //if
      });
    },
  };
  readonly areaBorderPlugin = {
    id: "a-area-border",
    beforeDraw(chart: any, args, options) {
      const {
        ctx,
        chartArea: { bottom, left, right, top },
      } = chart;
      ctx.save();
      ctx.strokeStyle = "#e0e0e0";
      ctx.lineWidth = "1";
      ctx.strokeRect(left, top, right - left, bottom - top);
      ctx.restore();
    },
  };
}
