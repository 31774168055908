






import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { StateConfig } from "@auditcloud/shared/lib/workflow/types/State";
import { measureWorkflowStatusGetter } from "@/utils/status_resolver";

@Component({})
export default class SnippetMeasureWorkflow extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  statusId!: string;

  @Prop({
    type: String,
    required: true,
  })
  workflowId!: string;

  get status(): StateConfig | null {
    const statusResolver = measureWorkflowStatusGetter(this.workflowId);
    return statusResolver(this.statusId);
  }

  get name(): null | string {
    return this.$ft(this.status?.name ?? "Unknown");
  }
}
