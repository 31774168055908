










































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { State, Action, Getter, Mutation } from "vuex-class";
import {
  TodoAny,
  TodoMutationSignature,
} from "@auditcloud/shared/lib/utils/type-guards";

import { api } from "@/store/modules/app";
import { api as userApi, Actions as UserActions } from "@/store/modules/user";
import { api as confApi } from "@/store/modules/configuration";
import { ROUTE_NAMES } from "../../routenames";
import AAnniversaryEventWidget from "@/components/widgets/AAnniversaryEventWidget.vue";
import AProfileWidget from "@/components/widgets/AProfileWidget.vue";

@Component({ components: { AProfileWidget, AAnniversaryEventWidget } })
export default class NavigationPanel extends Vue {
  auditId: string = "";
  measureId: string = "";
  isProd: any = process.env.NODE_ENV === "production";

  @State(api.state.drawerVisible, { namespace: api.namespace })
  drawerVisible!: TodoAny;

  @Getter(api.getters.sitemap, { namespace: api.namespace })
  sitemap!: TodoAny;

  @Getter(confApi.getters.app, { namespace: confApi.namespace })
  app!: TodoAny;

  get config() {
    return this.app || { components: { debug: false } };
  }
  get currentAvatar() {
    if (this.$user.displayName()) {
      return this.$user.displayName().substring(0, 1) || "-";
    } else {
      return "-";
    }
  }

  @Mutation(api.mutations.toggleDrawer, { namespace: api.namespace })
  toggleDrawer!: TodoMutationSignature;

  @Action(userApi.actions.userSignOut, { namespace: userApi.namespace })
  userSignOut!: UserActions["userSignOut"];

  logout() {
    console.log("Loging Out");
    this.userSignOut();
    this.$router.push({
      name: ROUTE_NAMES.SIGNIN,
    });
  }
  openAudit() {
    if (this.auditId !== "") {
      this.$router.push({
        name: ROUTE_NAMES.AUDIT,
        params: { auditId: this.auditId },
      });
      this.auditId = "";
    }
  }
  openMeasure() {
    if (this.measureId !== "") {
      this.$router.push({
        name: ROUTE_NAMES.MEASUREDIALOG,
        params: { measureId: this.measureId },
      });
      this.measureId = "";
    }
  }
}
