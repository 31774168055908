









































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";

import {
  api as auditResultApi,
  Getters as AuditResultGetters,
} from "@/store/modules/auditResult";
import { api as auditApi } from "@/store/modules/audit";

import AAuditReportSummary from "@/components/widgets/AAuditReportSummary.vue";
import AAuditReportOverview from "@/components/widgets/AAuditReportOverview.vue";
import AAuditReportCalculation from "@/components/widgets/AAuditReportCalculation.vue";
import AAuditResultTable from "@/components/widgets/AAuditResultTable.vue";

import ACardWithHeader from "@/components/widgets/ACardWithHeader.vue";
import AAuditResultsChart from "@/components/widgets/AAuditResultsChart.vue";
import {
  ConfigurableGetter,
  nullable,
} from "@auditcloud/shared/lib/types/common";
import AMaturityChart from "@/components/widgets/AMaturityChart.vue";
import AUrgencyChart from "@/components/widgets/AUrgencyChart.vue";
import AFinalAuditResult from "./AFinalAuditResult.vue";
import { AuditResult } from "@auditcloud/shared/lib/types/AuditResult";
import { AuditClassClient } from "@auditcloud/shared/lib/types/AuditClass";

@Component({
  components: {
    ACardWithHeader,
    AAuditReportSummary,
    AAuditReportOverview,
    AAuditReportCalculation,
    AAuditResultTable,
    AAuditResultsChart,
    AMaturityChart,
    AUrgencyChart,
    AFinalAuditResult,
  },
})
export default class AAuditResultSummary extends Vue {
  get auditStatisticsGetter(): ConfigurableGetter {
    return {
      namespace: auditResultApi.namespace,
      name: auditResultApi.getters.getAuditFindingResult,
    };
  }
  @Prop({
    type: Boolean,
    default: true,
  })
  showReportingSummary!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  showQuestionsAnswered!: boolean;

  tab: number = 0;

  @Getter(auditResultApi.getters.getAuditResultChartData, {
    namespace: auditResultApi.namespace,
  })
  auditResultChartData!: AuditResultGetters["getAuditResultChartData"];

  @Getter(auditResultApi.getters.getUrgencyChartData, {
    namespace: auditResultApi.namespace,
  })
  urgencyChartData!: AuditResultGetters["getUrgencyChartData"];

  @Getter(auditResultApi.getters.getMaturityChartData, {
    namespace: auditResultApi.namespace,
  })
  maturityChartData!: AuditResultGetters["getMaturityChartData"];

  @Getter(auditApi.getters.getIsResultVisible, {
    namespace: auditApi.namespace,
  })
  isResultVisible!: boolean;

  @Getter(auditApi.getters.getAuditClass, {
    namespace: auditApi.namespace,
  })
  auditClass!: nullable<AuditClassClient>;

  get useRiskBasedFindings(): boolean {
    return this.auditClass?.useRiskBasedFindings ?? false;
  }

  @Getter(auditApi.getters.getMaturityEnabled, {
    namespace: auditApi.namespace,
  })
  maturityEnabled!: boolean;

  get maturityResult(): AuditResult {
    const score = this.maturityChartData?.totalScore;
    if (!score) {
      throw new Error("Total score is not defined");
    }
    return {
      short: score >= 4 ? "A" : score >= 3 ? "B" : score >= 2 ? "C" : "D",
      text: "",
      color:
        score >= 4
          ? "green"
          : score >= 3
          ? "green lighten-2"
          : score >= 2
          ? "yellow darken-2"
          : "red",
      description: "",
    };
  }
}
