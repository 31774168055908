









































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import { Action, Getter, Mutation, State } from "vuex-class";
import {
  TodoMap,
  TodoAny,
  TodoMutationSignature,
} from "@auditcloud/shared/lib/utils/type-guards";

// Snippets/Components

import ItemsJsFacets from "@/components/controls/ItemsJsFacets.vue";
import PaginationControl from "@auditcloud/components/controls/PaginationControl.vue";

import AUserSnippet from "@/components/snippets/AUserSnippet.vue";
import ADateSnippet from "@/components/snippets/ADateSnippet.vue";
import AUserChip from "@/components/snippets/AUserChip.vue";
import ADateChip from "@auditcloud/components/snippets/ADateChip.vue";
import AChip from "@/components/snippets/AChip.vue";
import MeasureActionBar from "@/components/widgets/MeasureActionBar.vue";

import AAuditMeasureBulkEditDialog from "@/components/dialogs/AAuditMeasureBulkEditDialog.vue";

import ListConfigurationMixin from "@/components/mixins/ListConfigurationMixin.vue";
import AMeasureProcessTitleSuggestion from "@/components/controls/AMeasureProcessTitleSuggestion.vue";
// Layouts
import DrawerRight from "@/components/layouts/BaseLayouts/DrawerRight.vue";

import {
  api,
  Getters as MeasuresGetters,
  Mutations as MeasuresMutations,
  Actions as MeasuresActions,
} from "@/store/modules/measures";
import { api as userApi, Getters as UserGetters } from "@/store/modules/user";
import { ROUTE_NAMES } from "@/routenames";
import { DataTableHeader } from "vuetify";
import { MAGIC_MEASURE_COMPLETED_STATUS_ID } from "@auditcloud/shared/lib/workflow/configs/constants";
import { unlistenerFunction } from "@/utils/firestore";
import { isGenericMeasureReader } from "@auditcloud/shared/lib/utils/aclHelpers";
import { clone, Dictionary, fromPairs, get, isString, keyBy } from "lodash";
import { api as appApi } from "@/store/modules/app";
type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;

type UserIsType = "member" | "generic-measure-reader" | "assignee" | "creator";

@Component({
  components: {
    DrawerRight,
    ItemsJsFacets,
    PaginationControl,
    AChip,
    ADateChip,
    AUserChip,
    AAuditMeasureBulkEditDialog,
    AUserSnippet,
    ADateSnippet,
    AMeasureProcessTitleSuggestion,
    MeasureActionBar,
  },
  mixins: [ListConfigurationMixin],
})
export default class MeasureListWidget extends Mixins(ListConfigurationMixin) {
  deleting: string[] | null = null;
  bulkEditMode: boolean = false;
  showCompletedMeasuresCheckbox: boolean = false;

  @Prop({
    type: String,
    default: null,
  })
  auditId!: string | null;

  @Prop({
    type: String,
    required: true,
  })
  listConfigKey!: string;

  @Prop({
    type: String,
    required: true,
  })
  dialogRouteName!: string;

  @Prop({
    type: Boolean,
    default: true,
  })
  observeMeasures!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  loading!: boolean;

  AUDIT_LINK_NAME: any = ROUTE_NAMES.AUDIT;
  filterCount: number = 0;
  drawerRightBulkEditVisible: boolean = false;
  currentData: MeasuresGetters["getFlatMeasureMatrix"] = [];
  selectedData: MeasuresGetters["getFlatMeasureMatrix"] = [];

  get selectedDataMap(): Dictionary<
    ArrayElement<MeasuresGetters["getFlatMeasureMatrix"]>
  > {
    return keyBy(this.selectedData, "id");
  }

  get showCompletedMeasures(): boolean {
    return this.showCompletedMeasuresCheckbox || this.isInAuditContext;
  }

  setSelectedData(
    selectedMeasure: ArrayElement<MeasuresGetters["getFlatMeasureMatrix"]>
  ) {
    const filteredSelectedData = this.selectedData.filter(
      m => m.id !== selectedMeasure.id
    );
    const selectMeasure =
      filteredSelectedData.length === this.selectedData.length;

    this.selectedData = selectMeasure
      ? [...this.selectedData, selectedMeasure]
      : filteredSelectedData;
  }

  dialogMeasure: boolean = false;
  today: any = new Date().toISOString().slice(0, 10);
  currentFilter: String = "all";
  unlistener: null | unlistenerFunction = null;

  @State(appApi.state.drawerVisible, {
    namespace: appApi.namespace,
  })
  drawerVisible!: boolean;

  @State(appApi.state.drawerRightVisible, {
    namespace: appApi.namespace,
  })
  drawerRightVisible!: boolean;
  get drawerRight() {
    return this.drawerRightVisible;
  }

  @Mutation(appApi.mutations.toggleDrawerRight, {
    namespace: appApi.namespace,
  })
  toggleDrawerRight!: TodoMutationSignature;
  set drawerRight(value) {
    this.$store.dispatch("app/toggleDrawerRight", value);
  }

  get sortedCurrentDataForTable() {
    return [...this.currentDataForTable].sort((a, b) => {
      return b.id < a.id ? 1 : -1;
    });
  }

  @Getter(api.getters.getFlatMeasureMatrix, { namespace: api.namespace })
  readonly getFlatMeasureMatrix!: MeasuresGetters["getFlatMeasureMatrix"];

  @Getter(api.getters.getAllowedBulkActions, { namespace: api.namespace })
  readonly allowedBulkAktions!: MeasuresGetters["getAllowedBulkActions"];

  @Getter(api.getters.getBulkActionsPossible, { namespace: api.namespace })
  readonly bulkActionsPossible!: MeasuresGetters["getBulkActionsPossible"];

  @Getter(userApi.getters.getCurrentUserRoles, {
    namespace: userApi.namespace,
  })
  readonly currentUserRoles!: UserGetters["getCurrentUserRoles"];

  collapsedMap: Dictionary<boolean> = {};
  toggleCollapsed(id: string) {
    this.$set(this.collapsedMap, id, !this.collapsedMap[id]);
  }
  get allCollapsed() {
    return Object.values(this.collapsedMap).some(val => val);
  }
  toggleCollapseAll() {
    const collapse = clone(!this.allCollapsed);
    this.currentDataForTable.forEach(measure =>
      this.$set(this.collapsedMap, measure.id, collapse)
    );
  }
  setBulkEditMode() {
    this.bulkEditMode = !this.bulkEditMode;
    if (!this.bulkEditMode) {
      this.selectedData = [];
    }
  }

  selectWhereUserIs(whereUserIs: UserIsType) {
    const needsConfirmation =
      whereUserIs === "generic-measure-reader" &&
      this.showCompletedMeasuresCheckbox;
    if (
      !needsConfirmation ||
      confirm(
        this.$t(
          "components.widgets.measures_widget.load_all_confirm"
        ).toString()
      )
    ) {
      this.whereUserIs = whereUserIs;
    }
  }

  get isGenericMeasureReader(): boolean {
    return isGenericMeasureReader(this.currentUserRoles);
  }

  get isInAuditContext(): boolean {
    return isString(this.auditId) && this.auditId.length > 0;
  }

  get whereUserIsSelection(): {
    id: UserIsType;
    text: string;
  } {
    return this.whereUserIsItems.find(v => v.id === this.whereUserIs)!;
  }

  get whereUserIsItems(): Array<{
    id: UserIsType;
    text: string;
  }> {
    const whereItemsForUsers: { id: UserIsType; text: string }[] = [
      {
        id: "assignee",
        text: this.$t(
          "components.widgets.measures_widget.filter_by_role_assignee"
        ).toString(),
      },
      {
        id: "creator",
        text: this.$t(
          "components.widgets.measures_widget.filter_by_role_creator"
        ).toString(),
      },
      {
        id: "member",
        text: this.$t(
          "components.widgets.measures_widget.filter_by_role_member"
        ).toString(),
      },
    ];

    const whereItemsForGenericReaders: { id: UserIsType; text: string }[] = [
      {
        id: "generic-measure-reader",
        text: this.$t(
          "views.measure_process_matrix.header_admin_filter_all"
        ).toString(),
      },
    ];

    return this.isGenericMeasureReader
      ? [...whereItemsForUsers, ...whereItemsForGenericReaders]
      : whereItemsForUsers;
  }
  xWhereUserIs: UserIsType = "assignee";

  get whereUserIs(): UserIsType {
    if (this.isInAuditContext && this.isGenericMeasureReader) {
      return "generic-measure-reader";
    } else {
      return this.xWhereUserIs;
    }
  }
  set whereUserIs(val: UserIsType) {
    if (this.xWhereUserIs !== val) {
      this.xWhereUserIs = val;
      this.triggerLoad();
    } else {
      this.xWhereUserIs = val;
    }
  }

  get bulkActions() {
    return [
      {
        id: "edit",
        name: this.$t("components.widgets.measure_list_widget.edit"),
        icon: "mdi-pencil",
        iconColor: "primary",
        disabled: this.selectedData.length === 0 || !this.allowBulkEdit,
      },
      {
        id: "delete",
        name: this.$t("components.widgets.measure_list_widget.delete"),
        icon: "mdi-delete",
        iconColor: "error",
        disabled: this.selectedData.length === 0 || !this.allowBulkDelete,
      },
    ];
  }

  get hasAuditColumn() {
    return this.auditId === null;
  }
  get auditRefHeader(): DataTableHeader[] {
    return this.hasAuditColumn
      ? [
          {
            text: this.$t(
              "views.measure_process_matrix.header_audit"
            ).toString(),
            value: "audit_name",
          },
        ]
      : [];
  }

  get showExpand(): boolean {
    return !this.isLargeDisplayAndUp && !this.isExtraSmall;
  }

  get isLargeDisplayAndUp(): boolean {
    return this.$vuetify.breakpoint.lgAndUp;
  }

  get isExtraSmall(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.mobile;
  }

  get tableTextWidth() {
    const drawerWidth = this.drawerVisible ? 256 : 0;
    const drawerRightWidth = this.drawerRight ? 330 : 0;
    return `calc(17vw - ${drawerWidth / 3}px - ${drawerRightWidth / 3}px)`;
  }

  get causeHeader(): DataTableHeader[] {
    return [
      {
        text: this.$t("views.measure_process_matrix.header_cause").toString(),
        value: "cause",
        sortable: false,
      },
    ];
  }

  get directMeasureHeader(): DataTableHeader[] {
    return this.currentData.some(m => m.hasDirectMeasure)
      ? [
          {
            text: "RequiredMeasure",
            value: "directMeasure",
            sortable: false,
            width: "24px",
          },
        ]
      : [];
  }

  get headers(): DataTableHeader[] {
    return [
      ...this.directMeasureHeader,
      {
        text: this.$t(
          "components.widgets.measure_list_widget.id_header"
        ).toString(),
        value: "id",
      },
      ...this.auditRefHeader,
      {
        text: this.$t("views.measure_process_matrix.header_finding").toString(),
        value: "finding",
        sortable: false,
      },
      ...this.causeHeader,
      {
        text: this.$t(
          "views.measure_process_matrix.header_corrective_measure"
        ).toString(),
        value: "text",
        sortable: false,
      },
      {
        text: this.$t("views.measure_process_matrix.header_status").toString(),
        value: "status",
      },
      {
        text: this.$t(
          "components.widgets.measure_list_widget.duedate_header"
        ).toString(),
        value: "dueDate",
      },
      {
        text: this.$t(
          "views.measure_process_matrix.header_assigned_to"
        ).toString(),
        value: "assignedTo",
      },
    ];
  }

  get currentMeasures() {
    const aggregations = this.listConfigItemsJs.aggregations;
    return this.getFlatMeasureMatrix.map(flatMeasureDoc => {
      return {
        ...fromPairs(
          Object.keys(aggregations).map(path => [
            path,
            get(flatMeasureDoc, path),
          ])
        ),
        ...flatMeasureDoc,
      };
    });
  }

  // Overwrites from ListConfigurationMixin
  get listConfigConfigKey() {
    return this.listConfigKey;
  }
  get listConfigTotalItemCount() {
    return this.currentData.length;
  }

  get measureCompleted() {
    return MAGIC_MEASURE_COMPLETED_STATUS_ID;
  }

  get allowBulkDelete(): boolean {
    return this.allowedBulkAktions.delete;
  }

  get allowBulkEdit(): boolean {
    return (
      this.allowedBulkAktions.changeState ||
      this.allowedBulkAktions.changeAssignee ||
      this.allowedBulkAktions.changeDueDate
    );
  }

  @Action(api.actions.deleteMeasure, { namespace: api.namespace })
  deleteMeasure!: MeasuresActions["deleteMeasure"];

  @Action(api.actions.deleteMeasures, { namespace: api.namespace })
  deleteMeasures!: MeasuresActions["deleteMeasures"];

  @Action(api.actions.loadMeasures, { namespace: api.namespace })
  loadMeasures!: MeasuresActions["loadMeasures"];

  @Action(api.actions.clearMeasures, { namespace: api.namespace })
  clearMeasures!: MeasuresActions["clearMeasures"];

  @Mutation(api.mutations.SET_SELECTED_MEASURES, { namespace: api.namespace })
  setSelectedMeasures!: MeasuresMutations["SET_SELECTED_MEASURES"];

  @Getter(api.getters.getSelectedMeasureIds, { namespace: api.namespace })
  selectedMeasureIds!: MeasuresGetters["getSelectedMeasureIds"];

  @Watch("auditId")
  onWatchAuditId() {
    this.triggerLoad();
  }

  @Watch("showCompletedMeasures")
  onShowCompletedMeasuresChange() {
    this.triggerLoad(true);
  }

  showCompletedMeasuresCheckboxChange() {
    const newValue = !this.showCompletedMeasuresCheckbox;
    if (
      !newValue ||
      this.whereUserIs !== "generic-measure-reader" ||
      confirm(
        this.$t(
          "components.widgets.measures_widget.load_all_confirm"
        ).toString()
      )
    ) {
      this.showCompletedMeasuresCheckbox = newValue;
    }
  }

  @Watch("selectedData")
  updateSelectedMeasureIds() {
    this.setSelectedMeasures(this.selectedData.map(v => v.id));
  }

  @Watch("currentData", { immediate: true })
  onCurrentDataChange(measures) {
    this.$emit("updated:number-of-measures", measures.length);
  }

  onItemsjsResultListChanged(d: TodoAny, filter: TodoMap, query: string) {
    this.filterCount =
      (query === "" ? 0 : 1) +
      Object.values(filter).reduce((prev, item) => prev + item.length, 0);

    this.currentData = d;
  }

  setFilter(filter: string) {
    this.currentFilter = filter || "all";
  }
  filter(items: any[], query: string) {
    return items.filter(function (item) {
      if (query === "status=offen") {
        if (item.status === "Offen") {
          return item;
        }
      } else if (query === "status=geschlossen") {
        if (item.status === "Abgeschlossen") {
          return item;
        }
      } else if (query === "!assigned_to") {
        if (!item.assignedTo) {
          return item;
        }
      } else {
        return item;
      }
    });
  }

  get selectableItems(): { [k: string]: boolean } {
    const result = {};
    this.currentData.forEach(measure => {
      result[measure.id] = true;
    });
    return result;
  }

  @Watch("selectableItems")
  updateSelectedItems() {
    this.selectedData = this.selectedData.filter(
      selectedMeasure => this.selectableItems[selectedMeasure.id]
    );
  }

  get currentDataForTable() {
    return this.currentData.map(measure => ({
      ...measure,
      selectionDisabled: !this.selectableItems[measure.id],
    }));
  }

  selectAllToggle(props) {
    const disabledSelectionCount = props.items.filter(
      el => !this.selectableItems[el.id]
    ).length;
    if (
      this.selectedData.length !==
      props.items.length - disabledSelectionCount
    ) {
      this.selectedData = [];
      props.items.forEach(item => {
        if (!item.selectionDisabled) {
          this.selectedData.push(item);
        }
      });
    } else {
      this.selectedData = [];
    }
  }

  measureRowClicked(
    item: any,
    event: {
      expand: (value: boolean) => void;
      headers: DataTableHeader[];
      isCollapsed: boolean;
      isMobile: boolean;
      isSelected: boolean;
      item: any;
      select: (value: boolean) => void;
    }
  ): void {
    if (this.bulkEditMode) {
      event.select(!event.isSelected);
    } else {
      this.updateMeasure(event.item.id);
    }
  }

  updateMeasure(measureId) {
    const route = {
      name: this.dialogRouteName,
      params: { measureId },
      query: this.$route.query,
    };

    this.$router.push(route);
  }
  deleteMeasureById(measureId: string) {
    if (
      confirm(
        this.$t("components.widgets.audit_overview_measure.confirm_delete", {
          measureId,
        }).toString()
      )
    ) {
      this.deleting = [measureId];
      this.deleteMeasure({ measureId }).catch(err => {
        console.error(`Delete measure ${measureId} failed`);
        this.deleting = null;
        alert("Delete failed");
      });
    }
  }
  deleteSelectedMeasures() {
    if (
      confirm(
        this.$t(
          "components.widgets.audit_overview_measure.confirm_delete_multiple",
          {
            measureIds: this.selectedMeasureIds.join(","),
          }
        ).toString()
      )
    ) {
      this.deleting = this.selectedMeasureIds;
      this.deleteMeasures(this.selectedMeasureIds)
        .then(() => {
          this.selectedData = [];
        })
        .catch(err => {
          console.error(
            `Delete measures ${this.selectedMeasureIds.join(", ")} failed`
          );
          this.deleting = null;
          alert("Deletion failed");
        });
    }
  }
  triggerLoad(forceLoad?: boolean) {
    const whereUserIs = this.whereUserIs;
    const showCompletedMeasures = this.showCompletedMeasures;
    if (this.observeMeasures || forceLoad) {
      const payload =
        typeof this.auditId === "string"
          ? {
              auditId: this.auditId,
              whereUserIs,
              showCompletedMeasures,
            }
          : { whereUserIs, showCompletedMeasures };

      this.loadMeasures(payload).then(u => {
        this.unlistener = u;
      });
    }
  }

  openBulkEditDialog() {
    this.drawerRightBulkEditVisible = true;
  }
  closeBulkEditDialog() {
    this.drawerRightBulkEditVisible = false;
  }

  created() {
    console.log("MeasureListWidget:created", this.auditId);
    this.triggerLoad();
  }
  mounted() {
    console.log("MeasureListWidget:mounted", this.auditId);
  }
  beforeDestroy() {
    console.log("MeasureListWidget:beforeDestroy", this.auditId);
    if (this.observeMeasures) {
      this.clearMeasures(this.unlistener);
    }
  }
}
