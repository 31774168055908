


























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { GeoLocation } from "@auditcloud/shared/lib/schemas";

@Component({})
export default class AGeoLocation extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  value!: GeoLocation;

  @Prop({
    required: true,
    type: Boolean,
  })
  linkEnabled!: Boolean;

  @Prop({
    required: true,
    type: Boolean,
  })
  closeEnabled!: GeoLocation;

  get description() {
    return this.value.description;
  }

  get position() {
    return this.value.position;
  }

  get lat() {
    return this.position?.latitude;
  }

  get long() {
    return this.position?.longitude;
  }

  get linkFromGeoLocation(): string {
    return this.validPosition && this.linkEnabled
      ? "https://maps.google.com/?q=" + this.lat + "," + this.long
      : "";
  }

  get validPosition() {
    return this.lat !== 0 && this.long !== 0;
  }
}
