type i18nTranslator = (key: string) => string;
export interface AuditMetaStatusChartBuilderConfig {
  statusText: string;
  color: string;
  workflowStatusIds: Set<string>;
}

export class AuditMetaStatusChartBuilder {
  private amounts: number[];
  constructor(
    private readonly config: AuditMetaStatusChartBuilderConfig[],
    private readonly translator: i18nTranslator
  ) {
    this.amounts = new Array(config.length).fill(0);
  }
  updateAmount(auditStatusId: string) {
    const amountIdx = this.config.findIndex(cfg =>
      cfg.workflowStatusIds.has(auditStatusId)
    );
    if (amountIdx >= 0) {
      this.amounts[amountIdx] += 1;
    } else {
      console.warn(`Found unknown audit status id "${auditStatusId}"`);
    }
  }
  get chartData(): Chart.ChartData {
    return {
      labels: this.config.map(item => this.translator(item.statusText)),
      datasets: [
        {
          backgroundColor: this.config.map(item => item.color),
          data: this.amounts,
        },
      ],
    };
  }
}
