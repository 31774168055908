export const api = {
  namespace: "categories",
  mutations: {
    resetState: "resetState",
  },
  getters: {
    loadedCategories: "loadedCategories",
    loadedSubcategories: "loadedSubcategories",
    loadCategories: "loadCategories",
    loadSubcategories: "loadSubcategories",
  },
  state: {
    loadedCategories: "loadedCategories",
    loadedSubcategories: "loadedSubcategories",
  },
};

const defaultState = () => {
  return {
    [api.state.loadedCategories]: {
      default: [
        "0. Allgemeine Themen zum Managementsystem",
        "1. Vertrieb-Aussendienst (Allgemeine Building Blocks - Basis)",
        "2. Vertrieb-Innendienst (Allgemeine Building Blocks - Basis)",
        "3. Mod-Center (Allgemeine Building Blocks - Basis)",
        "4. Planung und MRP (Allgemeine Building Blocks - Basis)",
        "5. Logistik (Allgemeine Building Blocks - Basis)",
        "6. Reklamationsbearbeitung",
        "7. Service (Allgemeine Building Blocks - Basis + Sonder)",
        "8. Große und kleine Gehäuse (IE Baustein - Basis)",
        "9. HMI Basic (IE-Baustein - Basis)",
        "10. Gehäuseklimatisierung (IE Baustein - Basis)",
        "11. RAS Basis (IE-Baustein - Basis)",
        "12. RiLine (IE Baustein - Basis)",
        "13. Produkte & Komponenten (IT-Baustein - Basis)",
      ],
      "vda-6-3": [
        "2 - Projektmanagement",
        "3 - Planung der Produkt- und Prozessentwicklung",
        "4 - Realisierung der Produkt- und Prozessentwicklung",
        "5 - Lieferantenmanagement",
        "6 - Prozessanalyse / Produktion",
        "7 - Kundenbetreuung / Kundenzufriedenheit / Service",
        "8 - Dienstleistungen",
      ],
    },
    [api.state.loadedSubcategories]: {
      default: [
        "Organisation und Qualifiaktion (Prozess)",
        "Anfragebearbeitung (Prozess)",
        "Angebot und Kundenkommunikation (Prozess)",
        "Auftragserfassung und kaufmännische Bearbeitung (Prozess)",
      ],
      xyz: [
        "Organisation und Qualifiaktion (Prozess)",
        "Anfragebearbeitung (Prozess)",
        "Angebot und Kundenkommunikation (Prozess)",
        "Auftragserfassung und kaufmännische Bearbeitung (Prozess)",
      ],
    },
  };
};

const state = defaultState();

const mutations = {
  [api.mutations.resetState](state) {
    Object.assign(state, defaultState());
  },
};

const getters = {
  [api.getters.loadedCategories](state) {
    return state.loadedCategories;
  },
  [api.getters.loadedSubcategories](state) {
    return state.loadedSubcategories;
  },
  [api.getters.loadCategories](state) {
    return objectId => {
      if (state.loadedCategories[objectId]) {
        console.debug("Load XY category");
        return state.loadedCategories[objectId];
      } else {
        console.debug(state.loadedCategories["default"]);
        return state.loadedCategories["default"];
      }
    };
  },
  [api.getters.loadSubcategories](state) {
    return objectId => {
      if (state.loadedSubcategories[objectId]) {
        console.debug("Load XY category");
        return state.loadedSubcategories[objectId];
      } else {
        console.debug(state.loadedSubcategories["default"]);
        return state.loadedSubcategories["default"];
      }
    };
  },
};

export default {
  namespaced: true,
  api,
  state,
  mutations,
  getters,
};
