




















































































































/* tslint:disable:no-console */
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter, Mutation } from "vuex-class";
import { ROUTE_NAMES } from "@/routenames";

import AAuditOverviewFinding from "../components/widgets/AAuditOverviewFinding.vue";
import AUserChip from "../components/snippets/AUserChip.vue";
import ADateChip from "@auditcloud/components/snippets/ADateChip.vue";
import AChip from "../components/snippets/AChip.vue";

import {
  api,
  MEASURE_PROCESSES_MOCK_DATA,
} from "@/store/modules/measureProcess";
import { MeasureProcessEntry } from "@/store/modules/measureProcess/mockData";

@Component({
  components: { AAuditOverviewFinding, AUserChip, ADateChip, AChip },
})
export default class AMeasureProcessMatrix extends Vue {
  //missingDefault
  @Prop({
    type: String,
  })
  readonly measureProcessId!: string;

  @Getter(api.getters.getMeasureProcessId, {
    namespace: api.namespace,
  })
  storedMeasureProcessId!: string | null;

  @Action(api.actions.setMeasureProcess, { namespace: api.namespace })
  setMeasureProcessId!: (measureProcessId: string) => Promise<boolean>;

  @Action(api.actions.clearMeasureProcess, { namespace: api.namespace })
  clearMeasureProcess!: () => Promise<boolean>;

  get measureProcessIds(): string[] {
    const ids = MEASURE_PROCESSES_MOCK_DATA.map(val => val.id);
    console.log("measureProcessIds", ids);
    return ids;
  }

  get headers(): any {
    return [
      {
        text: this.$t("views.measure_process_matrix.header_audit"),
        value: "audit",
      },
      {
        text: this.$t("views.measure_process_matrix.header_finding"),
        value: "finding",
      },
      {
        text: this.$t("views.measure_process_matrix.header_cause"),
        value: "reason",
      },
      {
        text: this.$t("views.measure_process_matrix.header_corrective_measure"),
        value: "measure",
      },
      {
        text: this.$t("views.measure_process_matrix.header_assigned_to"),
        value: "asignee",
      },
      {
        text: this.$t("views.measure_process_matrix.header_status"),
        value: "status",
      },
    ];
  }
  items = MEASURE_PROCESSES_MOCK_DATA;

  @Watch("measureProcessId", { immediate: true, deep: true })
  onAuditIdChanged() {
    this.clearMeasureProcess().then(() => {
      this.setMeasureProcessId(this.measureProcessId);
    });
  }

  onRowClicked(e: MeasureProcessEntry) {
    this.$router.push({
      name: ROUTE_NAMES.MEASUREPROCESSDIALOG,
      params: { measureProcessId: e.id },
    });
  }

  beforeDestroy() {
    this.clearMeasureProcess();
  }
}
