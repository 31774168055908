import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";
import { TranslateableText } from "@auditcloud/shared/lib/schemas";

export interface MeasureActivity {
  readonly type: string;
  readonly published: Date;
  readonly summary: TranslateableText;
}

export class MeasureActivityComment implements MeasureActivity {
  type = "MeasureActivityComment";
  constructor(
    public readonly published: Date,
    public readonly summary: TranslateableText,
    public readonly actor: IUserRef,
    public readonly comment: string
  ) {}
}

export class MeasureActivityStepVerified implements MeasureActivity {
  type = "MeasureActivityStepVerified";
  constructor(
    public readonly published: Date,
    public readonly summary: TranslateableText,
    public readonly actor: IUserRef,
    public readonly object: string,
    public readonly target: IUserRef
  ) {}
}

export class MeasureActivityAssign implements MeasureActivity {
  type = "MeasureActivityAssign";
  constructor(
    public readonly published: Date,
    public readonly summary: TranslateableText,
    public readonly actor: IUserRef,
    public readonly object: IUserRef,
    public readonly target: string
  ) {}
}

export class MeasureActivityAddAttachment implements MeasureActivity {
  type = "MeasureActivityAddAttachment";
  constructor(
    public readonly published: Date,
    public readonly summary: TranslateableText,
    public readonly actor: IUserRef,
    public readonly object: any,
    public readonly target: any
  ) {}
}

export class MeasureActivityUpdate implements MeasureActivity {
  type = "MeasureActivityUpdate";
  constructor(
    public readonly published: Date,
    public readonly summary: TranslateableText,
    public readonly actor: IUserRef,
    public readonly object: any
  ) {}
}

export class MeasureActivityAccept implements MeasureActivity {
  type = "MeasureActivityAccept";
  constructor(
    public readonly published: Date,
    public readonly summary: TranslateableText,
    public readonly actor: IUserRef,
    public readonly object: any
  ) {}
}

export class MeasureActivityReject implements MeasureActivity {
  type = "MeasureActivityReject";
  constructor(
    public readonly published: Date,
    public readonly summary: TranslateableText,
    public readonly actor: IUserRef,
    public readonly object: any,
    public readonly content?: string
  ) {}
}

export class MeasureActivityAddTask implements MeasureActivity {
  type = "MeasureActivityAddTask";
  constructor(
    public readonly published: Date,
    public readonly summary: TranslateableText,
    public readonly actor: IUserRef,
    public readonly object: any
  ) {}
}

export type AnyMeasureActivity =
  | MeasureActivityComment
  | MeasureActivityStepVerified
  | MeasureActivityAssign
  | MeasureActivityAddAttachment
  | MeasureActivityUpdate
  | MeasureActivityAccept
  | MeasureActivityReject
  | MeasureActivityAddTask;
