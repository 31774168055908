import { nullable } from "@auditcloud/shared/lib/types/common";
import { MultilingualText } from "@auditcloud/shared/lib/schemas";
import { unlistenerFunction } from "@/utils/firestore";
import { Dictionary } from "lodash";

export type TranslationMap = Dictionary<TranslationMap | string>;
export type TranslationPatch = { locale: string; values: TranslationMap }[];

export interface TranslationPatchItem {
  patch?: MultilingualText;
  default?: MultilingualText;
}

export type TranslationPatchMap = Dictionary<MultilingualText>;

export class I18nTranslationsState {
  translations: Dictionary<TranslationMap> = {};
  translationUnlistener: nullable<unlistenerFunction> = null;
  loadedNewQuery: boolean = true;
}
