
























import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { State, Action, Getter } from "vuex-class";
import {
  NamedRef,
  AuditItemDimensionRef,
} from "@auditcloud/shared/lib/schemas";
import { uniq, flatten } from "lodash";

@Component({
  components: {},
  name: "ADimensionSelect",
})
export default class ADimensionSelect extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  auditItemId!: string; // activeAuditItemId

  @Prop({
    type: Array,
    default: null,
  })
  possibleDimensionsForAuditItem!: null | NamedRef[];

  @Prop({
    type: Array,
    required: true,
  })
  value!: AuditItemDimensionRef[];

  get activeRefs(): AuditItemDimensionRef[] {
    return this.value.filter(ref => ref.auditItemId === this.auditItemId);
  }

  get validDimensionIds(): string[] {
    return uniq(
      this.possibleDimensionsForAuditItem?.map(dimension => dimension.id) ?? []
    );
  }

  get error() {
    if (this.validDimensionIds.length > 0 && this.dimensions.length === 0) {
      return this.$t(
        "components.controls.dimension_select.error_one_select"
      ).toString();
    } else {
      return false;
    }
  }
  get dimensionsRequired() {
    return this.validDimensionIds.length > 0;
  }

  get referencedDimensionIds(): string[] {
    return uniq(flatten(this.activeRefs.map(ref => ref.dimensions ?? [])));
  }

  get invalidDimensionIds(): string[] {
    return this.referencedDimensionIds.filter(
      id => !this.validDimensionIds.includes(id)
    );
  }

  get items(): NamedRef[] {
    return [
      ...(this.possibleDimensionsForAuditItem ?? []),
      ...this.invalidDimensionIds.map((id, idx) => ({
        id,
        name: `Invalid ${idx}`,
      })),
    ];
  }

  get dimensions(): string[] {
    return this.referencedDimensionIds;
  }

  updateModel(val: AuditItemDimensionRef[]) {
    console.log("updateModel:changed", val);
    this.$emit("input", val);
  }

  set dimensions(val: string[]) {
    console.log("dimensions:changed", val);
    const untouchedRefs = this.value.filter(
      ref => ref.auditItemId !== this.auditItemId
    );
    this.updateModel([
      ...untouchedRefs,
      {
        auditItemId: this.auditItemId,
        dimensions: val.length > 0 ? val : null,
      },
    ]);
  }
}
