import _ from "lodash";
import {
  FlatAuditItemDataFindingBased,
  FlatAuditScoreData,
} from "@/store/modules/audit/utils";
import { PointsCalculationStrategy } from "@auditcloud/shared/lib/types/AuditScore/types";
import {
  AuditScoreTotal,
  AuditScore,
  AuditScoreByCategory,
  AuditScoreAggregated,
  AuditScoreAggregatedByCategory,
  AuditScoreAggregatedTotal,
} from "@auditcloud/shared/lib/types/AuditScore/types";
import { AuditItemCategoryMap } from "@auditcloud/shared/lib/types/AuditItemCategory";
import {
  array_cmp,
  text2sortArray,
} from "@auditcloud/shared/lib/utils/comperators";
import { typeIsNotEmpty } from "@auditcloud/shared/lib/utils/filter/typeIsNotEmpty";
import { Translator } from "@auditcloud/shared/lib/types/common";

function isApplicableRangeFlatAuditItemDataFindingBased(
  flatFindingsPerAuditItem: FlatAuditItemDataFindingBased[]
): boolean {
  return (
    flatFindingsPerAuditItem.length > 0 &&
    flatFindingsPerAuditItem.every(row => {
      return (
        row.auditItemWeight !== 0 &&
        row.findingId !== null &&
        row.findingWeight !== null &&
        row.findingTypeIsApplicable === true
      );
    })
  );
}

export function calcAuditScore(
  audit_findings: FlatAuditItemDataFindingBased[],
  calcBase: PointsCalculationStrategy
): AuditScoreTotal {
  const groupedByAuditId = _.groupBy(audit_findings, "auditItemId");

  const defaultScore = (): AuditScore => {
    return {
      worst: 0,
      best: 0,
      current: 0,
      notApplicable: 0,
    };
  };
  const defaultByCategory = (): {
    [categoryId: string]: AuditScoreByCategory;
  } => {
    return {};
  };

  const by_category = _.reduce(
    groupedByAuditId,
    (sum, findingsPerAuditItem) => {
      const categoryId = findingsPerAuditItem[0].auditItemCategoryId;
      const finding = findingsPerAuditItem[0];

      if (!(categoryId in sum)) {
        sum[categoryId] = { ...defaultScore(), categoryId, name: categoryId };
      }

      //
      if (
        isApplicableRangeFlatAuditItemDataFindingBased(findingsPerAuditItem)
      ) {
        const currentValues = findingsPerAuditItem
          .map(v => {
            console.assert(
              v.auditItemCategoryId === categoryId,
              "Expect category is equal for all",
              v,
              categoryId
            );
            return v.findingWeight;
          })
          .filter(typeIsNotEmpty);
        const appreciable_finding_value =
          calcBase.get_appreciable_finding_value(currentValues);

        const auditItemWeight = finding.auditItemWeight;

        sum[categoryId].worst += auditItemWeight * calcBase.worst_finding_value;
        sum[categoryId].best += auditItemWeight * calcBase.best_finding_value;
        sum[categoryId].current += auditItemWeight * appreciable_finding_value;
      } else {
        const auditItemWeight = finding.auditItemWeight;

        sum[categoryId].worst += auditItemWeight * calcBase.worst_finding_value;
        sum[categoryId].best += auditItemWeight * calcBase.best_finding_value;
        sum[categoryId].notApplicable =
          auditItemWeight * calcBase.best_finding_value;
      }

      return sum;
    },
    defaultByCategory()
  );

  const total_score = _.reduce(
    by_category,
    (sum, v) => {
      const score: AuditScore = {
        best: sum.best + v.best,
        worst: sum.worst + v.worst,
        current: sum.current + v.current,
        notApplicable: sum.notApplicable + v.notApplicable,
      };
      return score;
    },
    defaultScore()
  );

  return {
    inTotal: total_score,
    byCategory: _.map(by_category),
  };
}

// TODO: calcAuditScore vs. aggregateScoreData

export function aggregateScoreData(
  auditScoreData: FlatAuditScoreData[]
): AuditScoreAggregated {
  const bestScore = auditScoreData.reduce(
    (prev, criteria) => prev + criteria.bestScore,
    0
  );

  // AuditItems welche in den Score einfließen ...
  const isCalculable = (criteria: FlatAuditScoreData) => {
    return (
      // sind Beantwortet
      criteria.findingsCount > 0 &&
      // haben eine Gewichtung ungleich 0
      criteria.auditItemWeight !== 0 &&
      // wurden nicht mit NZ beantwortet
      !criteria.isNotApplicable
    );
  };

  const effectivBestScore = auditScoreData.reduce(
    (prev, criteria) =>
      prev + (isCalculable(criteria) ? criteria.bestScore : 0),
    0
  );

  const criteriaNotApplicabelTotalScore = auditScoreData.reduce(
    (prev, criteria) =>
      prev + (criteria.isNotApplicable ? criteria.bestScore : 0),
    0
  );
  const currentScore = auditScoreData.reduce(
    (prev, criteria) =>
      prev + (isCalculable(criteria) ? criteria.currentScore : 0),
    0
  );
  const worstScore = auditScoreData.reduce(
    (prev, criteria) =>
      prev + (isCalculable(criteria) ? criteria.worstScore : 0),
    0
  );

  const isValid =
    auditScoreData.length > 0 &&
    auditScoreData.some(criteria => isCalculable(criteria));

  const divisor = effectivBestScore - worstScore;
  console.assert(
    divisor !== 0,
    "Expect divisor to be not 0. Use fallback 100%",
    auditScoreData
  );
  const compliancePercent =
    divisor !== 0 ? (currentScore - worstScore) / divisor : 1;

  return {
    isValid,
    compliancePercent,
    criteriaTotalCount: auditScoreData.length,
    bestScore,
    effectivBestScore,
    worstScore,
    currentScore,

    criteriaNotApplicabelTotalCount: auditScoreData.reduce(
      (prev, criteria) => prev + (criteria.isNotApplicable ? 1 : 0),
      0
    ),
    criteriaNotApplicabelTotalScore,

    criteriaUnanswerdTotalCount: auditScoreData.reduce(
      (prev: number, criteria) => prev + (criteria.findingsCount === 0 ? 1 : 0),
      0
    ),
    criteriaUnanswerdTotalScore: auditScoreData.reduce(
      (prev: number, criteria) =>
        prev + (criteria.findingsCount === 0 ? criteria.bestScore : 0),
      0
    ),

    deviationsTotalCount: auditScoreData.reduce(
      (prev: number, criteria) => prev + criteria.deviationCount,
      0
    ),
  };
}

/**
 *
 * @deprecated use getter auditResult.getters.getScoreCalcSteps
 * @param auditCategories
 * @param audit_score_data
 * @param translator
 */
export function scoreCalcSteps(
  auditCategories: AuditItemCategoryMap,
  audit_score_data: FlatAuditScoreData[],
  translator: Translator
) {
  const grouped = Object.entries(
    _.groupBy(audit_score_data, "auditItemCategoryId")
  ).map(([auditItemCategoryId, items]) => {
    const category = auditCategories[auditItemCategoryId];

    const categoryName = category
      ? translator(category.name)
      : auditItemCategoryId;

    const auditItemCategoryShort =
      category && category.short ? translator(category.short) : categoryName;

    const by_category: AuditScoreAggregatedByCategory = {
      ...aggregateScoreData(items),
      auditItemCategoryId,
      auditItemCategoryName: categoryName,
      auditItemCategoryShort,
    };
    return by_category;
  });

  const totalScoreSteps = aggregateScoreData(audit_score_data);
  const compliancePercent = totalScoreSteps.compliancePercent;

  const totalResult =
    compliancePercent < 0.8 ? "C" : compliancePercent < 0.9 ? "B" : "A";
  const result: AuditScoreAggregatedTotal = {
    ...totalScoreSteps,
    byCategory: grouped.sort((lhs, rhs) => {
      return array_cmp(
        text2sortArray(lhs.auditItemCategoryName),
        text2sortArray(rhs.auditItemCategoryName)
      );
    }),
    totalResult,
  };
  return result;
}
