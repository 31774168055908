import { typeIsAxiosError } from "@/types/TypeGuards";
import { typeIsRestApiError } from "@auditcloud/shared/lib/async-handling";
import { MultilingualText } from "@auditcloud/shared/lib/schemas";
import { FirestoreError } from "firebase/firestore";

export function stringifyError(err: unknown): MultilingualText {
  if (typeIsRestApiError(err)) {
    return {
      de: `Die Aktion im Backend ist fehlgeschlagen`,
      en: `The action in the backend failed.`,
    };
  } else if (typeIsAxiosError(err)) {
    return {
      de: `Die Anfrage ist fehlgeschlagen`,
      en: `The request failed.`,
    };
  } else if (err instanceof FirestoreError) {
    return {
      de: `Eine Datenbankaktion ist fehlgeschlagen.`,
      en: `A database action failed`,
    };
  } else {
    return {
      de: `Es ist ein unbekannter Fehler aufgetreten.`,
      en: `A unknown error accured.`,
    };
  }
}
