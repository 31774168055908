import {
  WebLink,
  TranslateableText,
  AuditProof,
  AuditQuestion,
} from "@auditcloud/shared/lib/schemas";

export interface QuestionRow {
  sourceRow: number;
  no: number | string;
  auditItemType: string | null;
  weight: number | null;
  categoryId: string;
  subCategoryId: string | null;
  categoryWeight: number | null;
  chapterIdsForStandard(standardId: string): string[];
  chapterRefs: { standardId: string; chapterId: string }[];
  chapterDescription: TranslateableText | null;
  text: TranslateableText;
  selfAssessmentText: TranslateableText | null;
  hint: TranslateableText | null;
  links: WebLink[];
  requiredProofs: AuditProof[];
  labels: string[];
  vda_question_scope?: AuditQuestion["vda_question_scope"];
  customData?: {
    [k: string]: unknown | undefined;
  };
}

export function typeIsVdaQuestionScope(
  val: any
): val is QuestionRow["vda_question_scope"] {
  return val === "Prozess" || val === "Produkt";
}
