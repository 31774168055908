







































































import Component from "vue-class-component";
import { Prop, Watch, Mixins } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { TodoAny } from "@auditcloud/shared/lib/utils/type-guards";

import { api } from "@/store/modules/template";
import { api as schemasApi, GetSchemaForType } from "@/store/modules/schemas";

import { nullable } from "@auditcloud/shared/lib/types/common";
import { AuditTemplate } from "@/store/modules/template/types";

import VueFormJsonSchema from "vue-form-json-schema";
import FormsControlMixin from "@/components/mixins/FormsControlMixin.vue";
import { omit, cloneDeep } from "lodash";
import { calcParentRoute } from "../../utils/routerUtils";
import DialogLayout from "../layouts/BaseLayouts/DialogLayout.vue";

@Component({
  components: {
    VueFormJsonSchema,
    DialogLayout,
  },
  mixins: [FormsControlMixin],
})
export default class ATemplateMetadataDialog extends Mixins(FormsControlMixin) {
  loading: boolean = false;
  formValid = false;
  editable = false;
  readonly = false;

  formSchemaId: string = "metadata-form";

  @Prop({
    type: String,
    required: true,
  })
  templateId!: string;

  @Getter(schemasApi.getters.getVfjsOptions, {
    namespace: schemasApi.namespace,
  })
  getVfjsOptions!: TodoAny;

  @Getter(schemasApi.getters.getSchemaFor, {
    namespace: schemasApi.namespace,
  })
  getSchemaFor!: GetSchemaForType;

  @Getter(schemasApi.getters.getVfjsCommonSchemas, {
    namespace: schemasApi.namespace,
  })
  getVfjsCommonSchemas!: TodoAny;

  @Getter(api.getters.getTemplate, {
    namespace: api.namespace,
  })
  template!: nullable<AuditTemplate>;

  @Getter(api.getters.getCurrentTemplateId, {
    namespace: api.namespace,
  })
  activeTemplateId!: nullable<string>;

  @Action(api.actions.setCurrentTemplateId, { namespace: api.namespace })
  setTemplateId!: (templateId: string | null) => Promise<void>;

  @Action(api.actions.updateTemplateMetadata, { namespace: api.namespace })
  updateTemplateMetadata!: (data: any) => Promise<void>;

  @Watch("templateId", { immediate: true, deep: true })
  onTemplateIdChanged(newVal: string | null, oldVal: string | null) {
    console.log("onTemplateIdChanged", newVal, oldVal);
    if (newVal !== oldVal) {
      this.setTemplateId(null).then(() => {
        this.setTemplateId(newVal);
      });
    }
  }

  @Watch("template", { immediate: true, deep: true })
  onTemplateChanged(newVal) {
    if (this.template !== null) {
      const formData = omit(this.template, "audit_items");
      this.mixinInitFormData(formData);
    } else {
      this.mixinInitFormData({});
    }
  }

  get formSchema() {
    const auditClass = this.template ? this.template.audit_class : undefined;
    const res = this.getSchemaFor("template", this.formSchemaId, auditClass);
    return res;
  }
  get uiSchema() {
    return this.formSchema.ui(
      this.loading || !this.editable || this.readonly,
      this.readonly
    );
  }
  get schema() {
    return this.formSchema.data;
  }

  showError(e: any) {
    console.log("Error!", e);
  }
  change(value) {
    console.log('"change" event', value);

    this.mixinFormData = value;
  }
  onValidated(isValid) {
    console.log('"validated" event', isValid);
    this.formValid = isValid;
  }
  onChangeState(e) {
    console.log('"change-state" event', e);
  }

  onSave() {
    if (this.formValid) {
      this.loading = true;
      this.updateTemplateMetadata(cloneDeep(this.mixinFormData))
        .then(() => {
          this.closeDialog();
        })
        .catch(err => {
          console.error("Save Template failed", err);
          this.loading = false;
        });
    }
  }

  closeDialog() {
    const parent = calcParentRoute(this.$route);
    if (parent) {
      this.$router.push(parent);
    } else {
      this.$router.back();
    }
  }

  editControl() {
    this.editable = true;
    const formData = omit(this.template, "audit_items");
    this.mixinInitFormData(formData);
  }

  cancelEdit() {
    this.editable = false;
    const formData = omit(this.template, "audit_items");
    this.mixinInitFormData(formData);
  }
}
