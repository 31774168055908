import { extractString } from "./TypeGuards";
import {
  typeIsArrayOf,
  typeIsNamedRef,
  typeIsVdaMatrixEntry,
} from "@auditcloud/shared/lib/utils/type-guards";
import { idable } from "@auditcloud/shared/lib/types/common";

import { typeIsIUserRef } from "@auditcloud/shared/lib/types/UserRef";
import {
  SelectedItem,
  VdaMatrixData,
} from "@auditcloud/shared/lib/components/types";
import { createError } from "@/utils/Errors";
import { pick, isString, isObject } from "lodash";
import {
  AuditImplementationE,
  AuditStatusId,
  ReportSchemaE,
  DEFAULT_AUDIT_PROGRAM_ID,
} from "@auditcloud/shared/lib/constants";

import { AuditMetadataDoc } from "@auditcloud/shared/lib/schemas";

export type AuditMetadataClient = idable<AuditMetadataDoc>;

export function extractAuditProgramId(data: any) {
  if (data && typeof data.auditProgramId === "string") {
    return data.auditProgramId;
  } else {
    return DEFAULT_AUDIT_PROGRAM_ID;
  }
}

export function checkValidVda(vda_object: VdaMatrixData): boolean {
  return (
    vda_object.processes.length > 1 &&
    vda_object.products.length > 1 &&
    vda_object.matrix.length > 1 &&
    vda_object.processes.length <= 10 &&
    vda_object.products.length <= 10
  );
}

export function prepareVdaMatrixData(auditData: any): VdaMatrixData {
  let processes: string[] = [];
  let products: string[] = [];
  let matrix: SelectedItem[] = [];
  if (isObject(auditData?.vda)) {
    if (typeIsArrayOf(auditData.vda.processes, isString)) {
      processes = auditData.vda.processes;
    }
    if (typeIsArrayOf(auditData.vda.products, isString)) {
      products = auditData.vda.products;
    }
    if (typeIsArrayOf(auditData.vda.matrix, typeIsVdaMatrixEntry)) {
      matrix = auditData.vda.matrix;
    }
  }

  return {
    processes: processes,
    products: products,
    matrix: matrix,
  };
}

export function enrichAuditClient(id: string, data: any): AuditMetadataClient {
  let standardIds: string[] = [];
  const standards: any = data.standardRefs || data.standards || data.standard;
  if (!typeIsArrayOf(standards, typeIsNamedRef)) {
    console.error("Invalid Data standardRefs");
    throw createError("invalid value/standardRefs");
  }

  const auditProgramId = extractAuditProgramId(data);
  const response: AuditMetadataClient = {
    ...data,
    id,
    attachments: data.attachments ?? {},
    auditProgramId,

    audit_participants: (data.audit_participants || []).map(
      (audit_participant: any) => {
        if (typeIsIUserRef(audit_participant)) {
          return audit_participant;
        } else {
          console.error(
            "Invalid Data audit_participant",
            data.coauditors,
            audit_participant
          );
          throw createError("invalid value/audit_participant");
        }
      }
    ),
    audited_by: extractString(data, "audited_by"),
    auditing_date: (data.auditing_date || []).map(
      (date: string) =>
        // isoString2Date(date)
        date
    ), //extractString(data, "auditing_year"),
    /*,
    auditing_year: (() => {
      if (typeof data.auditing_year === "number") {
        return data.auditing_year;
      } else {
        console.error("Invalid Data auditing_year", data);
        throw createError("invalid value/auditing_year");
      }
    })()*/ coauditors: (data.coauditors || []).map((coauditor: any) => {
      if (typeIsIUserRef(coauditor)) {
        return coauditor;
      } else {
        console.error(
          "Invalid Data coauditor",
          data.coauditors,
          coauditor,
          data
        );
        throw createError("invalid value/coauditor");
      }
    }),
    companyMetadata: data.companyMetadata || null,
    audit_class: typeof data.audit_class === "string" ? data.audit_class : null,
    leadauditor: (() => {
      if (typeIsIUserRef(data.leadauditor) || data.leadauditor === null) {
        return data.leadauditor;
      } else {
        console.error("Invalid Data leadauditor", data.leadauditor);
        throw createError("invalid value/leadauditor");
      }
    })(),
    planning_year: parseInt(data.planning_year || "1970", 10),
    protected: data.protected || false,
    reportSchema: extractString(data, "reportSchema", ReportSchemaE.Compliance),
    responsible: (() => {
      if (typeIsIUserRef(data.responsible) || data.responsible === null) {
        return data.responsible;
      } else {
        console.error("Invalid Data responsible", data.responsible);
        throw createError("invalid value/responsible");
      }
    })(),
    standards: standards.map(val => {
      return pick(val, ["id", "name"]);
    }),
    standardRefs: standards.map(val => {
      return pick(val, ["id", "name"]);
    }),
    summary: data.summary,
    type: extractString(data, "type"),
    workflow: {
      status:
        data.workflow && data.workflow.status
          ? data.workflow.status
          : AuditStatusId.Planning,
      log:
        data.workflow && Array.isArray(data.workflow.log)
          ? data.workflow.log
          : [],
    },
    scope: data.scope || "",
    metadata: data.metadata || null,
    additionalReportData: data.additionalReportData || null,
    implementation: Object.values(AuditImplementationE).includes(
      data.implementation
    )
      ? data.implementation
      : AuditImplementationE.OnSite,
  };
  console.log("Enriched Audit", response);
  return response;
}
