



















































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter } from "vuex-class";

import { VCheckbox } from "vuetify/lib";
import {
  remove,
  intersectionWith,
  differenceWith,
  isEqual,
  cloneDeep,
  pick,
} from "lodash";
import { forEach } from "traverse";

interface SelectedItem {
  c: string;
  r: string;
}

@Component({
  components: { VCheckbox },
})
export default class SelectableMatrixControl extends Vue {
  selectedItems: SelectedItem[] = [];

  @Prop({
    default: () => [],
    type: Array,
  })
  readonly value!: SelectedItem[];

  @Prop({
    default: () => [],
    type: Array,
    /* required: true */
  })
  readonly rows!: string[];

  @Prop({
    default: () => [],
    type: Array,
    /* required: true */
  })
  readonly columns!: string[];

  @Prop({
    default: "",
    type: String,
  })
  readonly label!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly disabled!: boolean;

  @Prop({
    default: (word: string, idx: number) => word,
    type: Function,
  })
  readonly rowFormatter!: (word: string, idx: number) => string;

  @Prop({
    default: (word: string, idx: number) => word,
    type: Function,
  })
  readonly columnFormatter!: (word: string, idx: number) => string;

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly error!: boolean;

  @Prop({
    default: "",
    type: String,
  })
  readonly errorMessages!: string;

  @Watch("value", {
    deep: true,
    immediate: true,
  })
  onValueChanged(newVal: SelectedItem[]) {
    this.selectedItems = newVal;
  }

  @Watch("rows", {
    deep: true,
    immediate: true,
  })
  onRowChanged(newVal: string[]) {
    this.cleanUpSelectedItems();
  }

  @Watch("columns", {
    deep: true,
    immediate: true,
  })
  onColumnChanged(newVal: string[]) {
    this.cleanUpSelectedItems();
  }

  @Watch("selectedItems", {
    deep: true,
    immediate: true,
  })
  onSelectedItemChanged(newVal: SelectedItem[]) {
    this.$emit("input", newVal);
  }

  get areAllSelected(): boolean {
    return this.selectedItems.length === this.rows.length * this.columns.length;
  }

  get allItems(): SelectedItem[] {
    const allItems: SelectedItem[] = [];
    this.rows.forEach(r =>
      this.columns.forEach(c => allItems.push({ c: c, r: r }))
    );
    return allItems;
  }

  get filterAttrs() {
    const attrs = cloneDeep(
      pick(this.$props, ["error", "errorMessages", "errorCount"])
    );
    attrs.error;
    return attrs;
  }

  comparator(a, b) {
    const res = isEqual(a, b);
    /* console.log("Comparator", res, a === b, a, b); */
    return res;
  }

  cleanUpSelectedItems() {
    const rows = this.rows;
    const cols = this.columns;

    this.selectedItems = this.selectedItems.filter(item => {
      const r = item.r;
      const c = item.c;
      return rows.includes(r) && cols.includes(c);
    });
  }

  chooseAll() {
    this.selectedItems = this.allItems;
  }
}
