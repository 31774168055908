import Vue from "vue";
import { mapValues } from "lodash";
import { State } from "./types";
import { MailTemplateDoc } from "@auditcloud/shared/lib/schemas";
import { unlistenerFunction, WatchedDocument } from "@/utils/firestore";

const mutations = {
  WATCH(state: State, unlistener: unlistenerFunction) {
    const prevUnlistener = state.Unlistener;
    state.loadedNewQuery = true;
    state.Unlistener = unlistener;

    if (prevUnlistener) {
      prevUnlistener();
    }
  },
  SET_CURRENT_TEMPLATE_ID(state: State, emailTemplateId: string) {
    state.currentTemplateId = emailTemplateId;
  },
  SET_DOCUMENTS(
    state: State,
    {
      removeDocs,
      modifiedDocs,
    }: {
      removeDocs: string[];
      modifiedDocs: WatchedDocument<MailTemplateDoc | null>[];
    }
  ) {
    if (state.loadedNewQuery === true) {
      state.Documents = modifiedDocs;
    } else {
      removeDocs.forEach(docId => {
        const idx = state.Documents.findIndex(v => v.id === docId);
        if (idx > -1) {
          Vue.delete(state.Documents, idx);
        }
      });

      modifiedDocs.forEach(doc => {
        const idx = state.Documents.findIndex(v => v.id === doc.id);
        if (idx > -1) {
          Vue.set(state.Documents, idx, doc);
        } else {
          state.Documents.push(doc);
        }
      });
    }
    state.loadedNewQuery = false;
  },
  UNLISTEN(state: State) {
    const prevUnlistener = state.Unlistener;
    state.Unlistener = null;
    state.loadedNewQuery = true;

    if (prevUnlistener) {
      prevUnlistener();
    }
  },
};

const n = mapValues(mutations, (_, key) => key);

export { n as mutationNames, mutations };
