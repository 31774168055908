















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { AuditImplementationE } from "@auditcloud/shared/lib/constants";

@Component({
  name: "ASelectImplementation",
  components: {},
})
export default class ASelectImplementation extends Vue {
  @Prop({
    type: String,
  })
  value!: string | undefined;

  get items() {
    return [
      {
        text: this.$t(
          "schema_forms.common.audit_implementation_on_site"
        ).toString(),
        value: AuditImplementationE.OnSite,
      },
      {
        text: this.$t(
          "schema_forms.common.audit_implementation_remote"
        ).toString(),
        value: AuditImplementationE.Remote,
      },
      {
        text: this.$t(
          "schema_forms.common.audit_implementation_mixed"
        ).toString(),
        value: AuditImplementationE.Mixed,
      },
    ];
  }

  onSelectionChanged(newVal: string) {
    console.log("ImplementationSelect: ", newVal);
    this.$emit("input", newVal);
  }
}
