



























































import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { VIcon } from "vuetify/lib";
import { AnyMeasureActivity } from "@/types/measure-activities";

@Component({
  components: { VIcon },
})
export default class AMeasureActivityPrimary extends Vue {
  //missingDefault
  @Prop({
    type: Object,
    required: true,
  })
  readonly activity!: AnyMeasureActivity;

  @Prop({
    type: String,
    required: true,
  })
  readonly color!: string;

  @Prop({
    type: String,
    required: true,
  })
  readonly icon!: string;
}
