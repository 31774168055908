import { RootState, RootGetters } from "../../types";
import { State } from "./types";
import {
  keyBy,
  Dictionary,
  groupBy,
  mapValues,
  intersectionBy,
  last,
  values,
} from "lodash";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import { typeIsNotEmpty } from "@auditcloud/shared/lib/utils/filter/typeIsNotEmpty";
import {
  measureWorkflowStatusResolver,
  measureWorkflowResolver,
} from "@/utils/status_resolver";
import { api as usersApi } from "../users";
import { api as measureWorkflowsApi } from "../measureWorkflows";
import { getterNs } from "@/utils/VuexHelper";
import { UserInfoResolver, UserRefToItemsJsNormalizer } from "@/types/User";
import {
  calcMeasurePermissions,
  MeasurePermissions,
  isUserInAccessList,
} from "@auditcloud/shared/lib/utils/aclHelpers";
import { ft } from "@/plugins/ContentTranslation";
import { MAGIC_MEASURE_COMPLETED_STATUS_ID } from "@auditcloud/shared/lib/workflow/configs/constants";
import { WatchedDocument } from "@/utils/firestore";
import { getDateRange } from "@/utils/Datefilter";
import moment from "moment";
import {
  EmbeddedFinding,
  TranslateableText,
} from "@auditcloud/shared/lib/schemas";
import { getPossibleTransitions } from "../measure/utils";
import { MeasureWorkflow } from "@auditcloud/shared/lib/workflow/modules/Measure";
import { Getters } from ".";
import { MeasureTypeId } from "@auditcloud/shared/lib/constants";
import { MetaStatus } from "@auditcloud/shared/lib/workflow/types/State";
import { extractCurrentUserRef, extractCurrentUserRoles } from "../user/utils";
import { idable } from "@auditcloud/shared/lib/types/common";
import { TransitionConfig } from "@auditcloud/shared/lib/workflow/types/Transition";
import { resolveMetadataForItemJs } from "@/utils/itemjs/filter";
import { defaultWorkflowSteps } from "../audit/types";
import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";

type Getter<R> = (
  state: State,
  getters: Getters,
  rootState: RootState,
  rootGetters: RootGetters
) => R;

const getMeasureIds: Getter<string[]> = state => {
  return state.MeasureDocuments.map(doc => doc.id);
};
const getMappedMeasures: Getter<Dictionary<MeasureProcessDocument>> = state => {
  return keyBy(
    state.MeasureDocuments.map(watched => {
      if (watched.data !== null) {
        return watched.data;
      } else {
        return null;
      }
    }).filter(typeIsNotEmpty),
    "id"
  );
};

const getMeasureAmount: Getter<number> = state => {
  return state.MeasureDocuments.filter(watched => watched.data !== null).length;
};
const getMappedFindingId2MeasureIds: Getter<{
  [findingId: string]: string[];
}> = (state, getters) => {
  const mappedMeasures = getters.getMappedMeasures;
  const mappedByFinding = mapValues(
    groupBy(mappedMeasures, "findingId"),
    measures => {
      return measures.map(v => v.id);
    }
  );

  console.log("getMappedFindingId2MeasureIds", mappedByFinding);

  return mappedByFinding;
};
export interface FlatMeasureMatrixItem {
  id: string;
  text: string;
  suggestionText: string;
  autor: string;
  audit_name: string;
  audit_id: string;
  auditMetadata: Dictionary<unknown>;
  hasAuditMetadataAccess: boolean;
  findingObject: EmbeddedFinding | null;
  finding: TranslateableText | null;
  cause: string;
  assignedTo: string;
  assignedToRef: IUserRef | null;
  status: string;
  status_id: string | null;
  metaState: MetaStatus;
  workflow_id: string;
  dueDate: string;
  date_filter: string[];
  delay: string | null;
  completedOn: string | null;
  createdDate: string;
  audititem_id: string;
  deviation_id: string;
  hasDirectMeasure: boolean;
  permissions: MeasurePermissions;
}
const getFlatMeasureMatrix: Getter<Array<FlatMeasureMatrixItem>> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const measureIds = getters.getMeasureIds;
  const mappedMeasures = getters.getMappedMeasures;
  const currentUserRoles = extractCurrentUserRoles(rootGetters);
  const currentUserId = extractCurrentUserRef(rootGetters)?.id ?? null;

  const normalizeUserRefToItemsJs = rootGetters[
    getterNs(usersApi, usersApi.getters.getUserRefToItemJsNormalizer)
  ] as UserRefToItemsJsNormalizer;

  const resolveUser = rootGetters[
    getterNs(usersApi, usersApi.getters.getUser)
  ] as UserInfoResolver;

  const EMPTY_TEXT = "(leer)";

  return measureIds
    .map(measureId => {
      const measure = mappedMeasures[measureId];
      if (measure) {
        const workflow = measureWorkflowResolver(measure.workflow.workflowId);
        const permissions = calcMeasurePermissions(
          measure,
          currentUserId,
          currentUserRoles,
          workflow
        );

        const autor =
          normalizeUserRefToItemsJs(
            resolveUser(measure.docVersion.createdBy)
          ) ?? EMPTY_TEXT;
        const resolveStatus = measureWorkflowStatusResolver(
          measure.workflow.workflowId
        );
        const extractDateFilter = (data: TranslateableText[]): string[] => {
          return data.map(d => ft(d));
        };

        const isCompleted =
          MAGIC_MEASURE_COMPLETED_STATUS_ID === measure.workflow.statusId;
        const lastWorkflowStatus = last(measure.workflow.history);

        const dueDate = measure.dueDate;
        let delay: string | null = null;
        let completedOn: string | null = null;
        const getDelay = (dueDate, date) => {
          const days = moment(date).diff(moment(dueDate), "days");
          if (days <= 0) {
            return null;
          }
          if (days < 14) {
            return `${days}d`;
          } else if (days < 365) {
            const weeks = moment(date).diff(moment(dueDate), "weeks");
            return `${weeks}w`;
          } else {
            const years = moment(date).diff(moment(dueDate), "years");
            return `${years}y`;
          }
        };
        if (!dueDate) {
          return null;
        }

        if (isCompleted) {
          completedOn = lastWorkflowStatus?.changedAt ?? null;
          delay = getDelay(dueDate, completedOn);
        } else {
          delay = getDelay(dueDate, Date.now());
        }

        const hasAuditMetadataAccess =
          !!currentUserId &&
          isUserInAccessList(
            measure.auditMetadata.accessListMetadataRead,
            currentUserId,
            currentUserRoles
          );

        const assignedTo =
          measure.userRefs.assignedTo === null
            ? EMPTY_TEXT
            : measure.userRefs.assignedTo.displayName;
        const assignedToRef = measure.userRefs.assignedTo;
        const item: FlatMeasureMatrixItem = {
          id: measure.id,
          text: measure.description || "",
          suggestionText: measure.measureSuggestion || "",
          autor,
          audit_name: measure.auditRef.name,
          audit_id: measure.auditRef.id,
          auditMetadata: {
            ...measure.auditMetadata,
            ...resolveMetadataForItemJs(
              measure.auditMetadata,
              defaultWorkflowSteps(),
              EMPTY_TEXT,
              normalizeUserRefToItemsJs
            ),
          },
          hasAuditMetadataAccess,
          findingObject: measure.finding,
          finding: measure.finding?.text ?? null,
          cause: measure.causeAnalysis,
          assignedTo,
          assignedToRef,
          status:
            measure.workflow.statusId !== null
              ? ft(resolveStatus(measure.workflow.statusId) ?? "Invalid State")
              : "Invalid State",
          status_id: measure.workflow.statusId,
          metaState: measure.workflow.metaState,
          workflow_id: measure.workflow.workflowId,
          dueDate: measure.dueDate || "",
          date_filter:
            dueDate && measure.workflow.metaState !== "complete"
              ? extractDateFilter(getDateRange(dueDate))
              : [],
          delay,
          completedOn,
          createdDate:
            measure.docVersion.createdAt.toDate().toISOString().substr(0, 10) ||
            "",
          audititem_id: measure.auditItemId || "",
          deviation_id: measure.findingId || "",
          hasDirectMeasure:
            measure.additionalWorkflowMetadata?.type === MeasureTypeId.Direct,
          permissions,
        };
        return item;
      } else {
        return null;
      }
    })
    .filter(typeIsNotEmpty);
};
const getCompletedMeasuresCount: Getter<number> = state => {
  const countIfDone = (
    accumulator: number,
    currentValue: WatchedDocument<MeasureProcessDocument | null>
  ) => {
    // Todo: Use better metric to find completed states
    if (
      currentValue.data?.workflow.statusId === MAGIC_MEASURE_COMPLETED_STATUS_ID
    ) {
      return accumulator + 1;
    } else {
      return accumulator;
    }
  };
  return state.MeasureDocuments.reduce(countIfDone, 0);
};
const getOpenMeasureCount: Getter<number> = state => {
  const countIfDone = (
    accumulator: number,
    currentValue: WatchedDocument<MeasureProcessDocument | null>
  ) => {
    // Todo: Use better metric to find completed states
    if (
      currentValue.data?.workflow.statusId !== MAGIC_MEASURE_COMPLETED_STATUS_ID
    ) {
      return accumulator + 1;
    } else {
      return accumulator;
    }
  };
  return state.MeasureDocuments.reduce(countIfDone, 0);
};
const getSelectedMeasureIds: Getter<string[]> = state => {
  return state.SelectedMeasureIds;
};
/** Gets all transitionts that are allowed for EACH of the currently selected measures. */
const getTransitionsForSelectedMeasures: Getter<idable<TransitionConfig>[]> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  let possibleTransitions: idable<TransitionConfig>[][] = [];
  const selectedMeasureIds = getters.getSelectedMeasureIds;
  const mappedMeasures = getters.getMappedMeasures;

  const mappedWorkflows = rootGetters[
    getterNs(
      measureWorkflowsApi,
      measureWorkflowsApi.getters.getMappedMeasureWorkflows
    )
  ] as Dictionary<MeasureWorkflow>;

  const user = extractCurrentUserRef(rootGetters);

  if (user) {
    const userId = user.id;
    const roles = extractCurrentUserRoles(rootGetters);

    // get possible transitions for every measure
    selectedMeasureIds
      .map(measureId => mappedMeasures[measureId])
      // skip measures that have been deleted (while selection is updating)
      .filter(measureDoc => measureDoc != null)
      .forEach(measureDoc => {
        const measureWorkflowId = measureDoc.workflow.workflowId;

        const currPossibleTransitions = getPossibleTransitions(
          mappedWorkflows[measureWorkflowId],
          userId,
          roles,
          measureDoc
        );
        possibleTransitions.push(currPossibleTransitions);
      });
  }
  return intersectionBy(
    ...possibleTransitions,
    (v: idable<TransitionConfig>) => v.id
  );
};

/** Checks if transitions are allowed for any measure */
const getTransitionsAllowedForSomeMeasures: Getter<boolean> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const mappedMeasures = getters.getMappedMeasures;

  const mappedWorkflows = rootGetters[
    getterNs(
      measureWorkflowsApi,
      measureWorkflowsApi.getters.getMappedMeasureWorkflows
    )
  ] as Dictionary<MeasureWorkflow>;

  const user = extractCurrentUserRef(rootGetters);

  if (user) {
    const userId = user.id;
    const roles = extractCurrentUserRoles(rootGetters);
    return values(mappedMeasures).some(measureDoc => {
      const measureWorkflowId = measureDoc.workflow.workflowId;

      const currPossibleTransitions = getPossibleTransitions(
        mappedWorkflows[measureWorkflowId],
        userId,
        roles,
        measureDoc
      );
      return currPossibleTransitions.length > 0;
    });
  }
  return false;
};
const getAllowedBulkActions: Getter<{
  changeState: boolean;
  changeAssignee: boolean;
  changeDueDate: boolean;
  delete: boolean;
}> = (state, getters) => {
  const selectedMeasureIds = new Set(state.SelectedMeasureIds);
  const selectedMeasures = getters.getFlatMeasureMatrix.filter(item =>
    selectedMeasureIds.has(item.id)
  );

  const hasItems = selectedMeasureIds.size > 0;
  const hasPermissionToDeleteSelected = selectedMeasures.every(
    item => item.permissions.delete
  );
  const permissionToChangeAssignee = selectedMeasures.every(
    item => item.permissions.changeAssignee
  );
  const permissionToChangeDueDate = selectedMeasures.every(
    item => item.permissions.changeDueDate
  );
  const hasAllowedTransitions =
    getters.getTransitionsForSelectedMeasures.length > 0;

  return {
    changeAssignee: hasItems && permissionToChangeAssignee,
    changeDueDate: hasItems && permissionToChangeDueDate,
    changeState: hasItems && hasAllowedTransitions,
    delete: hasItems && hasPermissionToDeleteSelected,
  };
};

const getBulkActionsPossible: Getter<Boolean> = (state, getters) => {
  const flatMeasureMatrix = getters.getFlatMeasureMatrix;

  const hasPermissionToDeleteSelected = flatMeasureMatrix.some(
    item => item.permissions.delete
  );
  const permissionToChangeAssignee = flatMeasureMatrix.some(
    item => item.permissions.changeAssignee
  );
  const permissionToChangeDueDate = flatMeasureMatrix.some(
    item => item.permissions.changeDueDate
  );
  const hasAllowedTransitions =
    getters.getTransitionsForSelectedMeasures.length > 0;

  return (
    permissionToChangeAssignee ||
    permissionToChangeDueDate ||
    hasPermissionToDeleteSelected ||
    hasAllowedTransitions
  );
};

const getters = {
  getMeasureIds,
  getMappedMeasures,
  getMeasureAmount,
  getMappedFindingId2MeasureIds,
  getFlatMeasureMatrix,
  getCompletedMeasuresCount,
  getOpenMeasureCount,
  getSelectedMeasureIds,
  getTransitionsForSelectedMeasures,
  getTransitionsAllowedForSomeMeasures,
  getAllowedBulkActions,
  getBulkActionsPossible,
};
const n = mapValues(getters, (_, key) => key);

export { n as getterNames, getters };
