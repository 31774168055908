





















































































































































































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import AUserChip from "@/components/snippets/AUserChip.vue";
import ADateChip from "@auditcloud/components/snippets/ADateChip.vue";
import AAttachmentsSelectDialog from "@/components/dialogs/AAttachmentsSelectDialog.vue";

import { typeIsMapOf } from "@auditcloud/shared/lib/utils/type-guards";

import { EntryAttachmentsVuexApi } from "../../../utils/attachFileToEntity";

import prettyBytes from "pretty-bytes";
import { triggerDownload } from "@/utils/storage";
import {
  typeIsEntryAttachmentsPermissions,
  StoredAttachmentEntryListWithContext,
  typeIsStoredAttachmentEntryWithContext,
} from "@auditcloud/shared/lib/types/Attachments";
import { getterNs, actionNs } from "@/utils/VuexHelper";
import { toPairs } from "lodash";

import { Attachment } from "@/components/types";

@Component({
  components: {
    AUserChip,
    ADateChip,
    AAttachmentsSelectDialog,
  },
  name: "AAttachmentSidebar",
})
export default class AAttachmentSidebar extends Vue {
  search: string = "";
  isUploading = false;
  deleting: null | string = null;
  downloading: null | string = null;
  uploadIsOpen = false;
  uploadDragVisible = false;
  uploadCardDragVisible = false;
  files: File[] = [];
  uploadDestination = null;

  editingComment: string = "";
  editMode: boolean = false;

  error: boolean = false;
  errors: string[] = [];

  @Prop({ type: String, required: true })
  attachmentModulName!: string;

  @Prop({ type: String, required: true })
  sidebarTitle!: string;

  @Prop({ type: Boolean, default: false })
  readonly!: boolean;

  get api() {
    return {
      namespace: this.attachmentModulName,
      getters: {
        getEntityAttachments: "getEntityAttachments",
        getEntityAttachmentPermissions: "getEntityAttachmentPermissions",
      },
      actions: {
        attachFilesToEntry: "attachFilesToEntry",
        updateAttachmentComment: "updateAttachmentComment",
        deleteFileFromEntry: "deleteFileFromEntry",
        downloadFileFromEntry: "downloadFileFromEntry",
      },
    };
  }

  get getEntityAttachments(): EntryAttachmentsVuexApi["getEntityAttachments"] {
    const api = this.api;
    const attachmentsMap = this.$store.getters[
      getterNs(api, api.getters.getEntityAttachments)
    ] as unknown;

    if (typeIsMapOf(attachmentsMap, typeIsStoredAttachmentEntryWithContext)) {
      return attachmentsMap;
    } else {
      if (process.env.NODE_ENV !== "production") {
        console.warn(
          `AAttachmentSidebar for ${api.namespace}, getter ${getterNs(
            api,
            api.getters.getEntityAttachments
          )} must return StoredAttachmentEntryMap got`,
          attachmentsMap
        );
      }
      return {};
    }
  }

  get getEntityAttachmentPermissions(): EntryAttachmentsVuexApi["getEntityAttachmentPermissions"] {
    const api = this.api;
    const permissions = this.$store.getters[
      getterNs(api, api.getters.getEntityAttachmentPermissions)
    ] as unknown;
    console.log("EntryAttachmentsPermissions", permissions);
    if (typeIsEntryAttachmentsPermissions(permissions)) {
      return permissions;
    } else {
      if (process.env.NODE_ENV !== "production") {
        console.warn(
          `AAttachmentSidebar for ${api.namespace}, getter ${getterNs(
            api,
            api.getters.getEntityAttachmentPermissions
          )} must return EntryAttachmentsPermissions`
        );
      }
      return {
        download: false,
        update: false,
        delete: false,
        deleteOwn: false,
        updateOwn: false,
        downloadOwn: false,
        add: false,
      };
    }
  }

  async attachFilesToEntry(
    ...[payload]: Parameters<EntryAttachmentsVuexApi["attachFilesToEntry"]>
  ) {
    const api = this.api;
    const actionName = actionNs(api, api.actions.attachFilesToEntry);
    return await (this.$store.dispatch(actionName, payload, {
      root: true,
    }) as ReturnType<EntryAttachmentsVuexApi["attachFilesToEntry"]>);
  }

  async updateAttachmentComment(
    ...[payload]: Parameters<EntryAttachmentsVuexApi["updateAttachmentComment"]>
  ) {
    console.log("CALL: updateAttachmentComment", payload);
    const api = this.api;
    const actionName = actionNs(api, api.actions.updateAttachmentComment);
    return await (this.$store.dispatch(actionName, payload, {
      root: true,
    }) as ReturnType<EntryAttachmentsVuexApi["updateAttachmentComment"]>);
  }

  async downloadFileFromEntry(
    ...[payload]: Parameters<EntryAttachmentsVuexApi["downloadFileFromEntry"]>
  ) {
    const api = this.api;
    const actionName = actionNs(api, api.actions.downloadFileFromEntry);
    return await (this.$store.dispatch(actionName, payload, {
      root: true,
    }) as ReturnType<EntryAttachmentsVuexApi["downloadFileFromEntry"]>);
  }

  async deleteFileFromEntry(
    ...[payload]: Parameters<EntryAttachmentsVuexApi["deleteFileFromEntry"]>
  ) {
    const api = this.api;
    const actionName = actionNs(api, api.actions.deleteFileFromEntry);
    return await (this.$store.dispatch(actionName, payload, {
      root: true,
    }) as ReturnType<EntryAttachmentsVuexApi["deleteFileFromEntry"]>);
  }

  get attachmentsList(): StoredAttachmentEntryListWithContext {
    return toPairs(this.getEntityAttachments)
      .map(([id, val]) => {
        return { ...val, id };
      })
      .sort((lhs, rhs) =>
        lhs.name.toLowerCase().localeCompare(rhs.name.toLowerCase())
      );
  }

  get filteredItems(): StoredAttachmentEntryListWithContext {
    return this.attachmentsList.filter(a =>
      a.name.toLowerCase().includes(this.search.toLowerCase())
    );
  }

  get writePermission(): boolean {
    return this.getEntityAttachmentPermissions.add;
  }

  get deletePermission(): boolean {
    return this.getEntityAttachmentPermissions.delete;
  }

  get editPermission(): boolean {
    return this.getEntityAttachmentPermissions.update;
  }

  uploadSelected(attachments: Attachment[]) {
    this.isUploading = true;

    this.attachFilesToEntry(attachments)
      .then(attachmentIds => {
        console.log("uploadSelected done", attachmentIds);
        this.uploadIsOpen = false;
      })
      .catch(err => {
        this.errors = [String(err)];
        this.error = true;
        console.error("uploadSelected failed", err);
      })
      .finally(() => {
        this.isUploading = false;
        this.files = [];
      });
  }

  saveEditedComment(item: StoredAttachmentEntryListWithContext[number]) {
    console.log("saveEditedComment - ITEM: ", item);
    this.errors = [];
    this.error = false;

    this.updateAttachmentComment({
      id: item.id,
      context: item.context,
      comment: this.editingComment,
    })
      .catch(err => {
        this.errors = [String(err)];
        this.error = true;
        console.error("updateAttachmentComment failed", err);
      })
      .finally(() => {
        this.cancelEditMode();
      });
  }

  downloadAttachment(item: StoredAttachmentEntryListWithContext[number]) {
    this.errors = [];
    this.error = false;

    this.downloading = item.id;

    this.downloadFileFromEntry({
      id: item.id,
      context: item.context,
    })
      .then(downloadUrl => {
        triggerDownload(downloadUrl, item.name);
      })
      .catch(err => {
        this.errors = [String(err)];
        this.error = true;
        console.error("downloadFileFromEntry failed", err);
      })
      .finally(() => {
        this.downloading = null;
      });
  }

  deleteAttachment(item: StoredAttachmentEntryListWithContext[number]) {
    this.errors = [];
    this.error = false;

    if (
      confirm(
        this.$t(
          "components.widgets.sidebar.audit_attachment_list.delete_confirm"
        ).toString()
      )
    ) {
      this.deleting = item.id;

      this.deleteFileFromEntry({
        id: item.id,
        context: item.context,
      })
        .catch(err => {
          this.errors = [String(err)];
          this.error = true;
          console.error("deleteFileFromEntry failed", err);
        })
        .finally(() => {
          this.deleting = null;
        });
    }
  }

  prettyBytes(input: number) {
    return prettyBytes(input);
  }

  resetSearch() {
    this.search = "";
  }

  editComment(item: StoredAttachmentEntryListWithContext[number]) {
    this.editMode = true;
    this.editingComment = item.comment ?? "";
  }

  cancelEditMode() {
    this.editMode = false;
    this.editingComment = "";
  }

  addCardFiles(e: DragEvent) {
    if (e.dataTransfer) {
      this.files = Array.from(e.dataTransfer.files);

      this.uploadIsOpen = true;

      this.uploadDestination = null;
    }
    this.uploadCardDragVisible = false;
  }
}
