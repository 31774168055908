

































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { VChip, VIcon } from "vuetify/lib";
import AAttachmentDialog from "@/components/dialogs/AAttachmentDialog.vue";
import { Attachment, validateAttachment } from "../types";

@Component({
  components: { VChip, VIcon, AAttachmentDialog },
})
export default class AAttachmentUploadList extends Vue {
  url: string = "";
  xActiveAttachment: number = -1;

  get icon(): string {
    const attachment = this.attachments[this.activeAttachment];
    if (attachment) {
      return attachment.icon;
    } else {
      return "";
    }
  }

  get name(): string {
    const attachment = this.attachments[this.activeAttachment];
    if (attachment) {
      return attachment.name;
    } else {
      return "";
    }
  }

  get activeAttachment(): number {
    return this.xActiveAttachment;
  }

  set activeAttachment(val: number) {
    const attachment = this.attachments[val];
    if (this.url !== "") {
      window.URL.revokeObjectURL(this.url);
    }

    if (attachment && attachment.data) {
      this.url = window.URL.createObjectURL(attachment.data);
    } else {
      this.url = "";
    }

    this.xActiveAttachment = val;
  }

  get dialog() {
    return (
      this.attachments.length >= 0 &&
      0 <= this.activeAttachment &&
      this.activeAttachment < this.attachments.length
    );
  }
  set dialog(value: boolean) {
    if (!value) {
      this.activeAttachment = -1;
    }
  }

  get isImage(): boolean {
    const attachment = this.attachments[this.activeAttachment];
    if (attachment && attachment.data) {
      return attachment.data.type.startsWith("image/");
    } else {
      return false;
    }
  }

  @Prop({
    required: true,
    type: Array,
    validator: (val: any) =>
      val instanceof Array && val.every(validateAttachment),
  })
  readonly attachments!: Attachment[];

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly deletable!: boolean;

  removeAttachment(idx: number) {
    this.$emit("remove", idx);
  }
}
