
















































































import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import FormsControlMixin from "@/components/mixins/FormsControlMixin.vue";

@Component({
  mixins: [FormsControlMixin],
})
export default class AChipsDialog extends Mixins(FormsControlMixin) {
  @Prop({ type: Array, required: true })
  value!: string[];

  @Prop({ type: String, required: true })
  title!: string;

  @Prop({ type: String, default: "" })
  textFieldLabel!: string;

  @Prop({ type: String, default: "" })
  textFieldPlaceholder!: string;

  @Prop({ type: String, default: "" })
  noChipsMessage!: string;

  @Prop({ type: Boolean, default: false })
  loading!: boolean;

  created() {
    this.mixinInitFormData(this.value);
  }

  textFieldValue: string = "";

  get formControlEditValues(): string[] {
    return this.editValues as string[];
  }

  add(tag: string) {
    if (
      this.formControlEditValues
        .map(val => val.toLowerCase())
        .includes(tag.toLowerCase())
    ) {
      alert(
        this.$t("components.dialogs.audit_item_tag_dialog.tag_already_exists", {
          tag,
        })
      );
      return;
    }
    this.editValues.push(tag);
    this.textFieldValue = "";
  }

  remove(index: number) {
    this.formControlEditValues.splice(index, 1);
  }

  save() {
    this.$emit("save", this.editValues);
  }

  close() {
    if (this.isDirty) {
      if (window.confirm(this.$ft("common.confirms.unsaved_changes"))) {
        this.$emit("close");
      }
    } else {
      this.$emit("close");
    }
  }
}
