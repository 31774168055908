













































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  TodoAny,
  TodoActionSignature,
} from "@auditcloud/shared/lib/utils/type-guards";
import { api } from "@/store/modules/standards";
import { api as userApi, Getters as UserGetters } from "@/store/modules/user";
import { api as appApi } from "@/store/modules/app";

// Dialogs
import StandardImportDialog from "@/components/dialogs/StandardImportDialog.vue";

// Controls
import MoreMenu from "@/components/snippets/MoreMenu.vue";
import { ROUTE_NAMES } from "@/routenames";
import { SystemRoles } from "@auditcloud/shared/lib/constants/roles";

@Component({
  components: {
    MoreMenu,
    StandardImportDialog,
  },
})
export default class Standards extends Vue {
  dialogImport: boolean = false;

  @Getter(api.getters.loadedStandards, { namespace: api.namespace })
  loadedStandards!: TodoAny;

  @Action(appApi.actions.setBreadCrumb, { namespace: appApi.namespace })
  setBreadCrumb!: TodoActionSignature;

  @Action(appApi.actions.setMenuPanel, { namespace: appApi.namespace })
  setMenuPanel!: TodoActionSignature;

  @Getter(appApi.getters.getCurrentLanguage, { namespace: appApi.namespace })
  currentLanguage!: TodoAny;

  @Getter(userApi.getters.getCurrentUserRoles, { namespace: userApi.namespace })
  currentUserRoles!: UserGetters["getCurrentUserRoles"];

  @Watch("currentLanguage", { immediate: true, deep: true })
  onLaguageChange() {
    this.updateBreadCrumbs();
  }

  get hasImportPermission() {
    return this.currentUserRoles.includes(SystemRoles.SYSTEM_ADMINISTRATOR);
  }

  get moreMenu() {
    return [
      {
        title: this.$t("views.standards.import_btn"),
        event: "item-import",
      },
    ];
  }

  created() {
    this.updateBreadCrumbs();
  }

  updateBreadCrumbs() {
    const currentBreadCrumbs = [
      {
        text: this.$t("views.audit.home"),
        to: {
          name: ROUTE_NAMES.HOME,
        },
        activeclass: "",
      },
      {
        text: this.$t(
          "components.dialogs.template_metadata_dialog.template_metadata_dialog_standards_label"
        ),
        to: {
          name: ROUTE_NAMES.STANDARDS,
        },
        activeclass: "",
      },
    ];
    this.setMenuPanel("breadcrumb");
    this.setBreadCrumb(currentBreadCrumbs);
  }
}
