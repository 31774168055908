


















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

import {
  api as measureApi,
  Getters as MeasureGetters,
} from "@/store/modules/measure";

import {
  State,
  StepConfigImplementationMessage,
  StepType,
} from "@auditcloud/shared/lib/workflow/types/State";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import AMeasureProcessTitleSuggestion from "@/components/controls/AMeasureProcessTitleSuggestion.vue";

const measureModule = namespace(measureApi.namespace);

@Component({
  components: { AMeasureProcessTitleSuggestion },
})
export default class AMeasureProcessStepPreviewImplementationMessage extends Vue {
  @Prop({
    type: Object,
  })
  readonly state!: State<MeasureProcessDocument> | null;

  /**
   * true if pre-existing data from the measure process should be shown
   * (so that inputs of older measures before the workflow UI are not missing).
   */
  @Prop({
    type: Boolean,
    required: true,
  })
  readonly withCurrentProcessProperties!: boolean;

  @measureModule.Getter(measureApi.getters.getCurrentMeasure, {
    namespace: measureApi.namespace,
  })
  readonly currentMeasure!: MeasureGetters["getCurrentMeasure"];

  get measureSuggestion() {
    return this.currentMeasure?.measureSuggestion || null;
  }

  get stepConfig(): StepConfigImplementationMessage {
    const stepConfig = this.state?.step;
    if (stepConfig?.type === StepType.IMPLEMENTATION_MESSAGE) {
      return stepConfig;
    }
    return { type: StepType.IMPLEMENTATION_MESSAGE };
  }

  get summary(): string | null {
    const processProperty = this.stepConfig.summary?.processProperty;
    if (processProperty) {
      return this.currentMeasure?.[processProperty] ?? null;
    }
    return null;
  }

  get description(): string | null {
    const processProperty = this.stepConfig.description?.processProperty;
    if (processProperty && this.withCurrentProcessProperties) {
      return this.currentMeasure?.[processProperty] ?? null;
    }
    return null;
  }
}
