import _ from "lodash";
import { Module } from "vuex";
import { UserGroupsState } from "./types";
import { RootState } from "../../types";

import { getters, getterNames } from "./getters";
import { actions, actionNames } from "./actions";
import { mutations, mutationNames } from "./mutations";

const state = new UserGroupsState();

const namespaced: boolean = true;
const modul: Module<UserGroupsState, RootState> = {
  namespaced,
  getters,
  actions: { ...actions, ...state.actions() },
  mutations: { ...mutations, ...state.mutations() },
  state,
};

export const api = {
  namespace: "userGroups",
  getters: getterNames,
  actions: { ...actionNames, ...state.actionNames() },
  mutations: { ...mutationNames, ...state.mutationNames() },
};
export default modul;
