var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('div',{staticClass:"d-flex justify-center align-center",style:(_vm.detached
      ? {
          width: '100vw',
          position: 'fixed',
          bottom: '100px',
          left: ("" + (_vm.drawerVisible ? '128px' : '0')),
          transition: 'left .2s cubic-bezier(.4,0,.2,1)',
          'pointer-events': 'none',
        }
      : { transition: 'none!important' })},[_c('div',{class:_vm.detached ? ['px-4 py-2 elevation-4 rounded'] : [],staticStyle:{"background":"white"},style:({ 'pointer-events': 'auto' })},[_c('div',{staticClass:"d-flex align-center pl-0"},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"min-height":"36px","gap":"0.5rem"}},[[_vm._t("default")]],2)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }