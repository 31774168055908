
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { MeasureProcessStepDoc } from "@auditcloud/shared/lib/schemas";
import { idable } from "@auditcloud/shared/lib/types/common";

@Component({
  components: {},
})
export default class AMeasureProcessStepControlEmpty extends Vue {
  @Prop({
    type: Object,
  })
  readonly doc!: idable<MeasureProcessStepDoc>;
}
