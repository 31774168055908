























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Attachment, AttachmentAddHandler } from "../types";

@Component({})
export default class AAttachmentButton extends Vue {
  @Prop({
    type: Boolean,
    default: true,
  })
  small!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Object,
    required: true,
  })
  attachmentHandler!: AttachmentAddHandler;
  dialog: null | typeof Vue = null;

  selectionDone(attachments: Attachment[]) {
    this.dialog = null;
    this.$emit("attachments", attachments);
  }

  handleClick() {
    const handler = this.attachmentHandler;
    if (handler.type === "async") {
      handler
        .handler()
        .then(attachments => {
          if (attachments) {
            this.$emit("attachments", attachments);
          }
        })
        .catch(err => console.error(err));
    } else if (handler.type === "ui") {
      this.dialog = handler.handler;
    }
  }
}
