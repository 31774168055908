
import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { State, Action, Getter, Mutation } from "vuex-class";
import { TodoActionSignature } from "@auditcloud/shared/lib/utils/type-guards";

import { api as appApi } from "@/store/modules/app";
import { api as auditApi } from "@/store/modules/audit";
import { uploadFile } from "@/utils/uploader";
import { getDownloadLinkForAttachment } from "@/utils/storage";

@Component({})
export default class FileUploaderMixin extends Vue {
  uploadAvatarHandle: {
    url: string | ArrayBuffer | null;
    file: File;
  } | null = null;

  @Getter(auditApi.getters.getAuditId, { namespace: auditApi.namespace })
  getAuditId!: null | string;

  @Action(auditApi.actions.attachFileToFinding, {
    namespace: auditApi.namespace,
  })
  attachFileToFinding!: TodoActionSignature;

  @Action(appApi.actions.setLoading, { namespace: appApi.namespace })
  setLoading!: TodoActionSignature;
  onFilePickedCached(e) {
    // set Loading:
    this.setLoading(true);

    const files: File[] = e.target.files;
    if (files[0] !== undefined) {
      const fileName = Date.now().toString() + "-" + files[0].name;
      if (fileName.lastIndexOf(".") <= 0) {
        return;
      }
      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      fr.addEventListener("load", () => {
        const imageFile = files[0]; // this is an image file that can be sent to server...

        console.log(imageFile);
        console.log(fr.result);

        this.uploadAvatarHandle = { url: fr.result, file: imageFile };
      });
    }

    this.setLoading(false);
  }

  onFilePicked(e) {
    // set Loading:
    const auditId = this.getAuditId;
    if (auditId === null) {
      console.error("Expect auditId !== null");
      return null;
    }

    this.setLoading(true);

    const files = e.target.files;

    if (files[0] !== undefined) {
      const imageFile = files[0]; // this is an image file that can be sent to server...
      console.log("FileUploader:0", imageFile);
      uploadFile(imageFile, {
        target: {
          type: "audit",
          auditId,
        },
      })
        .then(fileUploadResponse => {
          if (fileUploadResponse.isErr()) {
            throw fileUploadResponse.error;
          }
          return getDownloadLinkForAttachment({
            target: {
              type: "audit",
              auditId,
            },
            attachmentId: fileUploadResponse.value.data.id,
          });
        })
        .then(downloadResponse => {
          console.log("FileUploader:1", downloadResponse);
          if (downloadResponse.isOk()) {
            return downloadResponse.value.downloadUrl;
          } else {
            throw downloadResponse.error;
          }
        })
        .then(() => {
          this.setLoading(false);
        })
        .catch(error => {
          console.error("FileUploader:3", error);
          this.setLoading(false);
        });
    }
  }
}
