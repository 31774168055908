var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"content-class":"user-card overflow-hidden","disabled":_vm.isLoading || !_vm.userInput || _vm.noUserCard,"top":"","open-delay":_vm.openDelay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onOuterMenu = ref.on;
var attrsOuterMenu = ref.attrs;
return [_c('v-menu',{attrs:{"disabled":_vm.disabled || _vm.isLoading || !_vm.editable,"close-on-content-click":false,"content-class":"a-autocomplete-border"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
var attrsMenu = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:({
              'a-user-default-editable': _vm.editable && !_vm.chip,
            },
            _vm.snippetClass),style:({
            height: _vm.chip ? 'unset' : _vm.editable ? '32px' : '24px',
            cursor: !_vm.editable ? 'default !important' : 'pointer !important',
            display: _vm.inline ? 'inline-block' : 'block',
          })},'div',Object.assign({}, attrsMenu, attrsOuterMenu, _vm.$attrs),false),Object.assign({}, onMenu, onOuterMenu)),[(_vm.userDialogVisible)?_c('AUserAutocompleteCreateDialog',{on:{"save":_vm.selectNewUser},model:{value:(_vm.userDialogVisible),callback:function ($$v) {_vm.userDialogVisible=$$v},expression:"userDialogVisible"}}):_vm._e(),_c('AUserSnippetBase',_vm._b({class:_vm.contentClass,attrs:{"small":_vm.small,"dark":_vm.dark,"user-name":_vm.userDisplayName,"is-loading":_vm.isLoading,"prepend-icon":_vm.prependIcon,"prepend-icon-color":_vm.prependIconColor,"append-icon":_vm.appendIcon
                ? _vm.appendIcon
                : _vm.editable && _vm.chip
                ? 'mdi-chevron-down'
                : '',"no-user-card":_vm.noUserCard,"chip":_vm.chip,"editable":_vm.editable,"no-user-placeholder":_vm.noUserPlaceholder,"trailing-comma":_vm.trailingComma}},'AUserSnippetBase',_vm.$attrs,false))],1)]}}],null,true),model:{value:(_vm.userSelectMenu),callback:function ($$v) {_vm.userSelectMenu=$$v},expression:"userSelectMenu"}},[_c('v-card',[_c('v-autocomplete',{key:_vm.uiResetKey,ref:"user-autocomplete",staticClass:"pa-0 ma-0",staticStyle:{"border-radius":"0 !important"},attrs:{"value":_vm.userInfo,"items":_vm.xItems,"item-value":"id","item-text":"displayName","placeholder":_vm.defaultedPlaceholder,"return-object":"","solo":"","hide-no-data":_vm.itemsGetter === null && !_vm.allowUserCreation,"search-input":_vm.search,"cache-items":true,"hide-details":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":function($event){_vm.userSelectMenu = false;
            _vm.onSelectionChange($event);},"!keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }$event.stopPropagation();},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"])){ return null; }$event.stopPropagation();}]},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [(_vm.allowNotAssigned && _vm.userInput)?_c('v-list-item',{on:{"click":function($event){_vm.userSelectMenu = false;
                _vm.onSelectionChange(null);}}},[_c('AUserSnippetBase',{attrs:{"small":_vm.small,"user-name":null,"prepend-icon":"mdi-account-off","no-user-placeholder":{
                  de: 'Nicht zugewiesen',
                  en: 'Not assigned',
                }}})],1):_vm._e()]},proxy:true},{key:"append-item",fn:function(){return [(_vm.allowUserCreation)?_c('v-list-item',{on:{"click":function($event){_vm.userSelectMenu = false;
                _vm.userDialogVisible = true;}}},[_c('AUserSnippetBase',{attrs:{"small":_vm.small,"user-name":null,"prepend-icon":"mdi-account-circle-outline","no-user-placeholder":_vm.$t(
                    'components.controls.user_autocomplete.create_external_user'
                  )}})],1):_vm._e()]},proxy:true},{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('AUserSnippetBase',{attrs:{"small":_vm.small,"user-name":item.displayName,"user-email":_vm.provideUserEmail(item)}})]}},(_vm.allowUserCreation)?{key:"no-data",fn:function(){return [_c('span')]},proxy:true}:null],null,true)},[_c('v-icon',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v("mdi-account-circle")])],1)],1)],1)]}}])},[_c('div',{staticClass:"default-user-card"},[_c('v-icon',{attrs:{"size":"80px","color":"secondary"}},[_vm._v("mdi-account-box")]),_c('div',{staticClass:"user-info"},[_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.userDisplayName)+" ")]),(_vm.userEmail)?_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.userEmail))]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }