













































// AAuditItemListManipulationControl
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter, Mutation, Action } from "vuex-class";
import AContentLanguageSwitch from "@/components/controls/AContentLanguageSwitch.vue";
import ASquareIconBtn from "@/components/controls/ASquareIconBtn.vue";

import { api as templateApi } from "@/store/modules/template";
import { debounce } from "lodash";
import { DIALOG_NAMES, ROUTE_NAMES } from "@/routenames";
import { Aggregation, Filter } from "@auditcloud/shared/lib/utils/filter/types";

import { extractQueries } from "@auditcloud/shared/lib/utils/filter/FilterUtils";

@Component({
  components: { ASquareIconBtn, AContentLanguageSwitch },
})
export default class ATemplateListFilter extends Vue {
  @Getter(templateApi.getters.getFullTextSearch, {
    namespace: templateApi.namespace,
  })
  xGetFulltextSearch!: string;

  @Mutation(templateApi.mutations.SET_FULLTEXT_SEARCH, {
    namespace: templateApi.namespace,
  })
  setFulltextSearch!: (text: string) => void;

  @Getter(templateApi.getters.getManipulatorStatus, {
    namespace: templateApi.namespace,
  })
  manipulatorStatus!: {
    filters: number;
  };

  @Getter(templateApi.getters.getTemplateItemListFilterAggregations, {
    namespace: templateApi.namespace,
  })
  aggregations!: Aggregation[];

  get filterCount(): null | number {
    return this.manipulatorStatus.filters > 0
      ? this.manipulatorStatus.filters
      : null;
  }

  mounted() {
    this.setFulltextSearch("");
  }

  @Mutation(templateApi.mutations.SET_FILTER_SETTING, {
    namespace: templateApi.namespace,
  })
  setFilter!: (v: Filter) => void;

  @Mutation(templateApi.mutations.CLEAR_FILTER_SETTINGS, {
    namespace: templateApi.namespace,
  })
  clearFilters!: () => void;

  setFulltextSearchDebounced = debounce((val: string | null) => {
    if (val === null) {
      val = "";
    }
    const { filters, fullText } = extractQueries(val, this.aggregations);
    this.setFulltextSearch(fullText);
    if (filters.length) {
      this.clearFilters();
      filters.forEach(f => this.setFilter(f));
    }
  }, 250);

  showFilter(evt: any) {
    const route = {
      ...this.$route,
      name: ROUTE_NAMES.TEMPLATE_DIALOG,
      params: {
        dialogName: DIALOG_NAMES.FILTER,
      },
    };
    this.$router.push(route);
  }
}
