import { MeasureWorkflow } from "@auditcloud/shared/lib/workflow/modules/Measure";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import { ActionContext } from "vuex";
import { MeasureState } from "./types";
import { RootState } from "@/store/types";
import { Dictionary } from "lodash";

export function getPossibleTransitions(
  measureWorkFlow: MeasureWorkflow,
  callerId: string,
  roles: string[],
  data: MeasureProcessDocument
) {
  const possibleTransitions = measureWorkFlow.possibleTransitions(
    {
      id: callerId,
      customClaims: {
        roles,
      },
    },
    data
  );

  return possibleTransitions;
}

export function handleMeasureTransitionError(
  err,
  context: ActionContext<MeasureState, RootState>,
  mn: Dictionary<string>
) {
  if (err.response) {
    const response = err.response;
    if (response.status === 400) {
      const data = response.data;
      if (
        data.code === "workflow-error/failed-preconditions" &&
        Array.isArray(data.payload.messages)
      ) {
        context.commit(mn.SET_API_ERRORS, data.payload.messages);
      }
    }
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error("Response other than 2xx");
    console.error(err.response);
    console.error(err.response.status);
    console.error(err.response.headers);
  } else if (err.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.error("Request");
    console.error(err.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error("Error", err.message);
  }
  console.log(err.config);
  console.error("measure/transition-failed", err);

  throw new Error("measure/transition-failed");
}
