import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import VuexPersist from "vuex-persist";

import modules from "./modules";
import { actions, actionNames } from "./actions";
import { RootState } from "./types";

Vue.use(Vuex);

const BadMutations: string[] = [];

const vuexLocalStorage = new VuexPersist<RootState>({
  key: "vuex-audit-cloud", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  /*reducer: state => { 
    const newstate: any = {};
    Object.entries(state).forEach(([k, v]) => {
      if (["configurationApp", "processes"].includes(k)) {
        return;
      }
      newstate[k] = v;
    }); 
    return newstate;
  },*/
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  filter: mutation => BadMutations.includes(mutation.type),
  supportCircular: true,
  strictMode: process.env.NODE_ENV !== "production",
});

export const api = {
  namespace: null,
  getters: {},
  mutations: {
    RESTORE_MUTATION: "RESTORE_MUTATION",
    SET_LOADING_MESSAGE: "SET_LOADING_MESSAGE",
  },
  actions: actionNames,
  state: {
    loadingMessage: "loadingMessage",
    version: "version",
  },
};

class State implements RootState {
  loadingMessage = null;
  version = "0.1.0";
  constructor() {
    console.log("State", this);
  }
}

const store: StoreOptions<RootState> = {
  // plugins: [vuexLocalStorage.plugin],
  actions,
  state: new State(),
  mutations: {
    [api.mutations.RESTORE_MUTATION]: vuexLocalStorage.RESTORE_MUTATION, // this mutation **MUST** be named "RESTORE_MUTATION"
    [api.mutations.SET_LOADING_MESSAGE](state, payload: string | null) {
      state.loadingMessage = payload;
    },
  },
  modules,
  strict: process.env.NODE_ENV !== "production",
};

const vuexStore = new Vuex.Store<RootState>(store);
window.vuexStore = vuexStore;
export default vuexStore;
