import { MailTemplateDoc } from "@auditcloud/shared/lib/schemas";

export function formMailLineEndings(textWithCrLf: string): string {
  return textWithCrLf.replace(/\r\n/g, "\n");
}

export function toMailLineEndings(textWithLf: string): string {
  return textWithLf.replace(/\n/g, "\r\n");
}

export function cleanupMailTemplate(
  dirtyWithLf: MailTemplateDoc
): MailTemplateDoc {
  return {
    type: dirtyWithLf.type,
    subject: dirtyWithLf.subject.trim(),
    html: dirtyWithLf.html.trim(),
    text: toMailLineEndings(dirtyWithLf.text).trim(),
  };
}
