import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { Bar, mixins } from "vue-chartjs";
import { buildDefaultChartData } from "./utils";
import { ChartData, ChartOptions } from "chart.js";
import { cloneDeep, debounce } from "lodash";
const { reactiveProp } = mixins;

@Component({
  extends: Bar,
  mixins: [reactiveProp],
})
export default class BarChart extends Vue<Bar> {
  constructor(...props: any[]) {
    super(...props);
  }
  @Prop({
    type: Object,
    default: buildDefaultChartData,
  })
  chartData!: Chart.ChartData;

  @Prop({
    type: Object,
    default: () => {
      return {};
    },
  })
  options!: Chart.ChartOptions;

  @Prop({
    type: Array,
    default: [],
  })
  plugins!: any[];

  @Watch("options")
  onWatchChartOptions() {
    this.renderChart(this.chartData, this.options);
  }
  @Watch("chartData")
  onWatchChartData() {
    this.renderChart(this.chartData, this.options);
  }

  mounted() {
    // Overwriting base render method with actual data.
    this.rerender();
    this.plugins.forEach(plugin => this.addPlugin(plugin));
  }

  latestChartData: ChartData | null = null;
  latestOptions: ChartOptions | null = null;
  refreshChart = debounce((vm: BarChart) => {
    if (vm.latestChartData !== null && vm.latestOptions !== null) {
      this.renderChart(
        // Clone is required cause Chart JS changes Structure "_meta" is written in the datasets
        cloneDeep(vm.latestChartData),
        cloneDeep(vm.latestOptions)
      );
    }
  }, 100);

  rerender() {
    this.latestChartData = this.chartData;
    this.latestOptions = this.options;
    if (this.latestChartData !== null && this.latestOptions !== null) {
      this.refreshChart(this);
    }
  }
}
