






















































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import { VBtn, VIcon, VSelect, VRow, VCol } from "vuetify/lib";

import AWidget from "@/components/widgets/AWidget.vue";
import ASquareIconButton from "@/components/controls/ASquareIconBtn.vue";
import AListCard from "@/components/widgets/AListCard.vue";
import AMeasureItem from "@/components/widgets/AMeasureItem.vue";

import { Action, Getter } from "vuex-class";
import { ROUTE_NAMES } from "@/routenames";

import {
  api as dashboardApi,
  Getters as dashboardGetters,
  Actions as dashboardActions,
} from "@/store/modules/dashboard";

@Component({
  components: {
    AWidget,
    ASquareIconButton,
    AListCard,
    AMeasureItem,
    VBtn,
    VRow,
    VCol,
    VIcon,
    VSelect,
  },
})
export default class AMeasuresWidget extends Vue {
  measureUserFilter: "creator" | "assignee" | "member" = "assignee";

  get availableMeasureRoleFilters(): any[] {
    return [
      {
        text: this.$t(
          "components.widgets.measures_widget.filter_by_role_assignee"
        ).toString(),
        value: "assignee",
      },
      {
        text: this.$t(
          "components.widgets.measures_widget.filter_by_role_creator"
        ).toString(),
        value: "creator",
      },
      {
        text: this.$t(
          "components.widgets.measures_widget.filter_by_role_member"
        ).toString(),
        value: "member",
      },
    ];
  }

  @Getter(dashboardApi.getters.getMeasuresQueryConfig, {
    namespace: dashboardApi.namespace,
  })
  measuresQueryConfig!: dashboardGetters["getMeasuresQueryConfig"];

  @Getter(dashboardApi.getters.getMeasures, {
    namespace: dashboardApi.namespace,
  })
  measures!: dashboardGetters["getMeasures"];

  @Getter(dashboardApi.getters.getIsLoadingMeasures, {
    namespace: dashboardApi.namespace,
  })
  isLoadingMeasures!: dashboardGetters["getIsLoadingMeasures"];

  @Getter(dashboardApi.getters.getHasMoreMeasures, {
    namespace: dashboardApi.namespace,
  })
  hasMoreMeasures!: dashboardGetters["getHasMoreMeasures"];

  @Action(dashboardApi.actions.updateMeasuresQueryConfig, {
    namespace: dashboardApi.namespace,
  })
  updateMeasuresQueryConfig!: dashboardActions["updateMeasuresQueryConfig"];

  @Action(dashboardApi.actions.fetchMeasures, {
    namespace: dashboardApi.namespace,
  })
  fetchMeasures!: dashboardActions["fetchMeasures"];

  @Action(dashboardApi.actions.fetchMoreMeasures, {
    namespace: dashboardApi.namespace,
  })
  fetchMoreMeasures!: dashboardActions["fetchMoreMeasures"];

  @Action(dashboardApi.actions.clearMeasures, {
    namespace: dashboardApi.namespace,
  })
  clearMeasures!: dashboardActions["clearMeasures"];

  openMeasures() {
    this.$router.push({
      name: ROUTE_NAMES.MEASURES,
    });
  }

  created() {
    console.log("AMeasureWidget:create");
    this.fetchMeasures();
  }

  beforeDestroy() {
    console.log("AMeasureWidget:beforeDestroy");
    this.clearMeasures();
  }
}
