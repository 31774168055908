import { isArray, mapValues, pick } from "lodash";
import { RootState, RootGetters } from "@/store/types";
import { State } from "./types";
import { Getters } from ".";
import { UserDoc, UserRef } from "@auditcloud/shared/lib/schemas";
import { VersionedDocument } from "@auditcloud/shared/lib/types/VersionedDocument";
import firebase from "firebase/compat/app";
import { contentTranslation } from "@/plugins/ContentTranslation";
import { idable } from "@auditcloud/shared/lib/types/common";

type Getter<R> = (
  state: State,
  getters: Getters,
  rootState: RootState,
  rootGetters: RootGetters
) => R;

const getCurrentUserRef: Getter<null | UserRef> = state => {
  console.log("getCurrentUserRef", state);
  if (state.user) {
    return pick(state.user, ["id", "displayName"]);
  } else {
    return null;
  }
};

const isAuthenticated: Getter<boolean> = state => {
  return state.user !== null;
};
const getCurrentUser: Getter<null | idable<UserDoc>> = state => {
  return state.user;
};

const getCurrentUserRoles: Getter<string[]> = state => {
  return isArray(state.firebaseClaims?.roles) ? state.firebaseClaims.roles : [];
};
const getVersionedDocument: Getter<VersionedDocument<firebase.firestore.FieldValue> | null> =
  state => {
    if (state.firebaseUser) {
      return new VersionedDocument(
        state.firebaseUser.uid,
        firebase.firestore.FieldValue.serverTimestamp,
        firebase.firestore.FieldValue.increment
      );
    } else {
      return null;
    }
  };

const getCustomData: Getter<UserDoc["customData"]> = state => {
  return state.user?.customData ?? {};
};
const getActivityTimeStamp: Getter<string | null> = state => {
  return state.user?.activitiesReadTime ?? null;
};
const getUserLanguage: Getter<UserDoc["language"] | null> = state => {
  return state.user?.language ?? null;
};
const getContentLanguage: Getter<string> = () => {
  return contentTranslation.language;
};

const getters = {
  getCurrentUserRef,
  isAuthenticated,
  getCurrentUser,
  getCurrentUserRoles,
  getVersionedDocument,
  getActivityTimeStamp,
  getUserLanguage,
  getContentLanguage,
  getCustomData,
};

const n = mapValues(getters, (_, key) => key);

export { n as getterNames, getters };
