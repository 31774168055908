


















































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import AActionBar from "@/components/widgets/AActionBar.vue";

import { Dictionary } from "lodash";
import { TranslateableText } from "@auditcloud/shared/lib/schemas";

@Component({
  components: { AActionBar },
})
export default class MeasureActionBar extends Vue {
  @Prop({ type: Array, required: true })
  readonly selection!: string[];

  @Prop({
    type: [Object, String],
    default: () => "views.audit_preparation.preselect_deselect_all",
  })
  readonly cancelTooltip!: TranslateableText;

  @Prop({ type: Array, required: true })
  readonly actions!: {
    id: string;
    name: TranslateableText;
    icon?: string;
    iconColor?: string;
    disabled?: boolean;
    buttonClass?: Dictionary<string> | string;
    tooltip?: string;
    tooltipContentClass?: Dictionary<string> | string;
  };

  @Prop({ type: Boolean, default: false })
  readonly loading!: boolean;

  deselectAll() {
    this.$emit("deselect-all");
  }
}
