import { MutationTree } from "vuex";
import {
  CurrentMeasureProcessState,
  MeasureProcessMetadata,
  MeasureProcessStep,
} from "./types";

import { defaultDocType } from "@auditcloud/shared/lib/types/Audit/types";
import { TodoArray } from "@auditcloud/shared/lib/utils/type-guards";
import { nullable } from "@auditcloud/shared/lib/types/common";
import { MeasureProcessEntry } from "./mockData";

const SET_MEASURE_PROCESS = "SET_MEASURE_PROCESS";
const CLEAR_MEASURE_PROCESS = "CLEAR_MEASURE_PROCESS";
const SET_MEASURE_METADATA = "SET_MEASURE_METADATA";
const SET_LAST_ACTIVITIES = "SET_LAST_ACTIVITIES";
const APPEND_ACTIVITIES = "APPEND_ACTIVITIES";
const SET_MEASURE_PROCESS_STEPS = "SET_MEASURE_PROCESS_STEPS";

const n = {
  SET_MEASURE_PROCESS,
  CLEAR_MEASURE_PROCESS,
  SET_MEASURE_METADATA,
  SET_LAST_ACTIVITIES,
  APPEND_ACTIVITIES,
  SET_MEASURE_PROCESS_STEPS,
};

const mutations: MutationTree<CurrentMeasureProcessState> = {
  [n.SET_MEASURE_PROCESS](state, measureProcessId: string) {
    state.metadata = defaultDocType();
    state.activities = [];
    state.steps = [];
    state.id = measureProcessId;
  },
  [n.CLEAR_MEASURE_PROCESS](state) {
    state.metadata = defaultDocType();
    state.activities = [];
    state.steps = [];
    state.id = null;
  },
  [n.SET_MEASURE_METADATA](
    state,
    measureProcessMetadata: nullable<MeasureProcessMetadata>
  ) {
    state.metadata.data = measureProcessMetadata;
  },
  [n.SET_LAST_ACTIVITIES](state, activities: TodoArray) {
    state.activities = activities;
  },
  [n.APPEND_ACTIVITIES](state, activities: TodoArray) {
    // published
    const mergedActivities = [...activities, ...state.activities];
    mergedActivities.sort((lhs: any, rhs: any) => {
      if (lhs.published < rhs.published) return -1;
      if (lhs.published > rhs.published) return 1;
      return 0;
    });
    state.activities = mergedActivities;
  },
  [n.SET_MEASURE_PROCESS_STEPS](state, steps: MeasureProcessStep[]) {
    state.steps = steps;
  },
};

export { n as mutationNames, mutations };
