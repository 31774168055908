








import { TranslateableText } from "@auditcloud/shared/lib/schemas";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { v4 as uuidv4 } from "uuid";
import { typeIsSomething } from "@auditcloud/shared/lib/utils/type-guards";

type PropType = Promise<unknown> | null;
@Component({
  components: {},
})
export default class AAxiosAlert extends Vue {
  @Prop({
    type: Promise,
  })
  readonly promise!: PropType;
  error: TranslateableText | null = null;
  promiseId: string = uuidv4();

  @Watch("promise", { immediate: true, deep: true })
  onPromiseChanged(newVal: PropType, oldVal?: PropType) {
    this.error = null;
    this.promiseId = uuidv4();

    if (newVal) {
      console.log("AttachHandler", newVal);
      newVal.catch(this.createErrorHandler(this.promiseId));
    }
  }

  createErrorHandler(promiseId: string): (error: unknown) => void {
    return error => {
      if (this.promiseId === promiseId) {
        if (typeIsSomething(error)) {
          console.error("Axios failed", error);
          if (error.response) {
            console.error("Axios response", error.response);
            this.error = error.response.data.code;
          } else {
            this.error = String(error);
          }
        } else {
          this.error = "Unknown error";
        }
      }
    };
  }
}
