



















































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";

import { cloneDeep, isEqual } from "lodash";

import { api as appApi } from "@/store/modules/app";
import {
  api as mailTemplatesApi,
  Getters as mtg,
  Mutations as mtm,
  Actions as mta,
} from "@/store/modules/mailTemplates";

import { ROUTE_NAMES } from "../../routenames";
import { MailTemplateDoc } from "@auditcloud/shared/lib/schemas";

const pattern = /^[a-z][A-Za-z0-9_-]+$/;
@Component({
  components: {},
})
export default class AEmailTemplateEditor extends Vue {
  @Getter(mailTemplatesApi.getters.getTemplateIds, {
    namespace: mailTemplatesApi.namespace,
  })
  readonly templateIds!: mtg["getTemplateIds"];

  @Getter(mailTemplatesApi.getters.getCurrentTemplateId, {
    namespace: mailTemplatesApi.namespace,
  })
  readonly getCurrentTemplateId!: mtg["getCurrentTemplateId"];

  @Getter(mailTemplatesApi.getters.getCurrentTemplate, {
    namespace: mailTemplatesApi.namespace,
  })
  readonly currentTemplate!: mtg["getCurrentTemplate"];

  @Mutation(mailTemplatesApi.mutations.SET_CURRENT_TEMPLATE_ID, {
    namespace: mailTemplatesApi.namespace,
  })
  setCurrentTemplateId!: mtm["SET_CURRENT_TEMPLATE_ID"];

  @Action(mailTemplatesApi.actions.startWatch, {
    namespace: mailTemplatesApi.namespace,
  })
  startWatch!: mta["startWatch"];

  @Action(mailTemplatesApi.actions.create, {
    namespace: mailTemplatesApi.namespace,
  })
  create!: mta["create"];

  @Action(mailTemplatesApi.actions.update, {
    namespace: mailTemplatesApi.namespace,
  })
  update!: mta["update"];

  @Mutation(mailTemplatesApi.mutations.UNLISTEN, {
    namespace: mailTemplatesApi.namespace,
  })
  stopWatch!: mtm["UNLISTEN"];

  editedData: null | MailTemplateDoc = null;
  loading: boolean = false;
  valid: boolean = true;
  createMode: boolean = false;
  newMailTemplateId: string = "";

  rules = [
    (val: string) => {
      return (
        pattern.test(val) ||
        "Enter a valid id (min Lenght: 2, starts with a small letter followed by A-Z, a-z, 0-9, '-' and '_')"
      );
    },
  ];
  @Prop({ type: String, required: true })
  segmentName!: string;

  @Prop({ type: String, default: null })
  mailTemplateId!: string | null;

  @Watch("mailTemplateId", {
    immediate: true,
  })
  onMailTemplateIdChange(val: string | null, oldVal: string | null) {
    this.loading = false;
    this.createMode = false;
    this.valid = true;
    this.newMailTemplateId = "";
    this.editedData = null;
    this.setCurrentTemplateId(val ?? "");
  }

  @Watch("storedData", {
    immediate: true,
  })
  onStoredDataChange(
    val: MailTemplateDoc | null,
    oldVal?: MailTemplateDoc | null
  ) {
    if (this.editedData === null) {
      this.editedData = cloneDeep(val);
    }
  }

  onMailTemplateIdSelected(mailTemplateId: string) {
    this.$router.push({
      name: ROUTE_NAMES.CONFIGURATION_EMAIL_TEMPLATE,
      params: {
        segmentName: this.segmentName,
        mailTemplateId,
      },
    });
  }

  get storedData() {
    if (this.mailTemplateId !== null) {
      return cloneDeep(this.currentTemplate);
    } else {
      return null;
    }
  }
  get isDirty() {
    return !isEqual(this.storedData, this.editedData);
  }
  get isIdValid() {
    return this.createMode === false || pattern.test(this.newMailTemplateId);
  }

  @Action(appApi.actions.setStatus, { namespace: appApi.namespace })
  setStatus!: (payload: string) => Promise<any>;

  async store() {
    if (
      this.valid &&
      this.isDirty &&
      this.editedData !== null &&
      (this.mailTemplateId !== null ||
        pattern.test(this.newMailTemplateId.trim()))
    ) {
      this.loading = true;
      await this.setStatus("loading");
      try {
        if (this.createMode && this.newMailTemplateId) {
          await this.create({
            id: this.newMailTemplateId,
            data: this.editedData,
          });
          this.editedData = null;
          this.onMailTemplateIdSelected(this.newMailTemplateId);
        } else {
          await this.update(this.editedData);
          this.editedData = this.storedData;
        }

        await this.setStatus("success");
      } catch (error) {
        console.error(error);
        await this.setStatus("error");
      }
      this.loading = false;
    }
  }

  setCreateMode() {
    this.createMode = true;
    this.editedData = {
      subject: "",
      html: "",
      text: "",
      type: "handlebars",
    };
  }

  mounted() {
    this.startWatch();
  }
  beforeDestroy() {
    this.stopWatch();
  }
}
