import Vue from "vue";
import { MutationTree } from "vuex";
import { I18nTranslationsState, TranslationPatch } from "./types";
import { nullable } from "@auditcloud/shared/lib/types/common";
import { unlistenerFunction } from "@/utils/firestore";

const SET_TRANSLATIONS_UNLISTENER = "SET_TRANSLATIONS_UNLISTENER";
const SET_TRANSLATION_PATCH = "SET_TRANSLATION_PATCH";
const n = {
  SET_TRANSLATIONS_UNLISTENER,
  SET_TRANSLATION_PATCH,
};

const mutations: MutationTree<I18nTranslationsState> = {
  [n.SET_TRANSLATIONS_UNLISTENER](
    state,
    translationUnlistener: nullable<unlistenerFunction>
  ) {
    state.loadedNewQuery = true;
    const prevMeasureUnlistener = state.translationUnlistener;
    state.translationUnlistener = translationUnlistener;
    if (prevMeasureUnlistener) {
      prevMeasureUnlistener();
    }
  },
  [n.SET_TRANSLATION_PATCH](
    state,
    { removed, modified }: { removed: string[]; modified: TranslationPatch }
  ) {
    removed.forEach(docId => {
      Vue.delete(state.translations, docId);
    });
    modified.forEach(patch => {
      Vue.set(state.translations, patch.locale, patch.values);
    });
  },
};

export { n as mutationNames, mutations };
