
















































import Vue from "vue";
import Component from "vue-class-component";

import RadarChart from "@/components/charts/RadarChart";
import BarChart from "@/components/charts/BarChart";
import { Prop } from "vue-property-decorator";
import {
  convertAuditResultChartDataToChartData,
  simpleTableData,
} from "@/store/modules/auditResult/utils";
import {
  AuditResultChartData,
  typeIsAuditResultChartData,
} from "@auditcloud/shared/lib/types/AuditResultChart";

/** Displays the Audit Result Chart.
 * Shows a radar chart when more than 2 categories are defined.
 * Shows a bar chart otherwise.
 */
@Component({
  components: {
    RadarChart,
    BarChart,
  },
})
export default class AAuditResultsChart extends Vue {
  @Prop({
    type: Object,
    required: true,
    validator: typeIsAuditResultChartData,
  })
  auditResultChartData!: AuditResultChartData;

  get componentData() {
    return convertAuditResultChartDataToChartData(
      this.auditResultChartData,
      this.$i18n.locale
    );
  }

  get chartComponent() {
    return this.componentData.chartComponent;
  }
  get chartData() {
    return this.componentData.chartData;
  }
  get chartOptions() {
    return this.componentData.chartOptions;
  }

  get tableData() {
    return simpleTableData(this.auditResultChartData);
  }
  readonly limitPlugin = {
    id: "aLimit",
    beforeDraw(chart: any, args, options) {
      const ctx = chart.ctx;
      const yMax = chart.scales["y-axis-0"].max;
      const { left, right, top, bottom } = chart.chartArea;
      const chartHeight = bottom - top;

      options.limits.forEach(limit => {
        const value = limit.value;
        const p1 = {
          x: left,
          y: bottom - (chartHeight * value) / yMax,
        };
        const p2 = {
          x: right,
          y: p1.y,
        };

        ctx.save();

        ctx.lineWidth = limit.borderWidth;
        ctx.strokeStyle = limit.borderColor;
        ctx.setLineDash(limit.borderDash);
        ctx.lineDashOffset = limit.borderDashOffset;

        // Draw
        ctx.beginPath();
        ctx.moveTo(p1.x, p1.y);
        ctx.lineTo(p2.x, p2.y);
        ctx.stroke();

        ctx.restore();
      });
    },
  };
}
