

















































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AMeasureProcessTitleSuggestion extends Vue {
  @Prop({
    type: String,
    default: "",
  })
  suggestion!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  hidden!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  clickable!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  hintOnly!: boolean; // only show a little hint that a suggestion exists
}
