import { FindingType } from "@auditcloud/shared/lib/types/ItemTypes";
import { typeIsTranslateableText } from "@auditcloud/shared/lib/types/common";
import { getIconForMimeType } from "@auditcloud/shared/lib/utils/FileManagement";
import {
  TranslateableText,
  CompositionWizardSettings,
  CompositionWizardSettingsNode,
  UserRef,
} from "@auditcloud/shared/lib/schemas";
import Vue from "vue";

import { Dictionary, isDate, isInteger, isPlainObject, isString } from "lodash";
import { typeIsArrayOf } from "@auditcloud/shared/lib/utils/type-guards";
import { IUserRef, typeIsIUserRef } from "@auditcloud/shared/lib/types/UserRef";
import ASketchDialog from "./dialogs/ASketchDialog.vue";

export type VForm = Vue & {
  validate: () => boolean;
  reset: () => void;
  resetValidation: () => void;
};

export function validateAttachment(val: any): boolean {
  return (
    val instanceof Object &&
    typeof val.icon === "string" &&
    typeof val.name === "string" &&
    (val.data instanceof Blob || val.data instanceof File)
  );
}

/**
 * List of roles and users being in this role
 */
export type UsersByRole<T extends string = string> = {
  roleId: T; // unique id to identify the role
  roleName: string; // human-readable name of the role
  users: UserRef[]; // users in that role
  preselect: boolean; // wether or not to preselect users in that role
}[];

/**
 * Todo:LocalAttachment vs. StoredAttachment
 *
 * Attachment wurde lokal erfasst bzw. Attachment liegt auf dem Server
 */

export interface Attachment {
  icon: string;
  name: string;
  data: Blob | File;
  comment: string;
}

export type AttachmentHandler = () => Promise<Attachment[] | null>;

export function validateAttachmentAddHandler(val: any): boolean {
  return (
    val instanceof Object &&
    typeof val.icon === "string" &&
    typeof val.handler === "function"
  );
}

type IVue = typeof Vue;

interface AttachmentAddHandlerBase {
  readonly icon: string;
  readonly title: TranslateableText;
}

interface AttachmentAddHandlerAsync extends AttachmentAddHandlerBase {
  readonly type: "async";
  readonly handler: AttachmentHandler;
}
interface AttachmentAddHandlerUi extends AttachmentAddHandlerBase {
  readonly type: "ui";
  /** A Vue component that emits an `attachments` event with payload of type `Attachment[]`. */
  readonly handler: IVue;
}

export type AttachmentAddHandler =
  | AttachmentAddHandlerAsync
  | AttachmentAddHandlerUi;

export interface QuickActionConfig {
  actions: FindingType[];
  directActions: string[];
  disableSegmentedSelection: boolean;
}

function isCompositionWizardSettings(val: any): boolean {
  return (
    isPlainObject(val) &&
    typeIsTranslateableText(val.name) &&
    isPlainObject(val.preset)
  );
}

export function typeIsCompositionWizardSettings(
  val: any
): val is CompositionWizardSettings {
  return isCompositionWizardSettings(val);
}

export function typeIsCompositionWizardSettingsNode(
  val: any
): val is CompositionWizardSettingsNode {
  return (
    isCompositionWizardSettings(val) &&
    typeIsTranslateableText(val.title) &&
    typeIsArrayOf(val.children, (v): v is
      | CompositionWizardSettings
      | CompositionWizardSettingsNode => {
      return (
        typeIsCompositionWizardSettingsNode(v) ||
        typeIsCompositionWizardSettings(v)
      );
    })
  );
}
export type CompositionWizardResultType = null | Dictionary<any>;

export interface WorkflowHistoryType {
  changedAt: Date;
  user: IUserRef;
  status: {
    from: String;
    to: String;
  };
  comment: String;
  no: Number;
}

export function typeIsWorkflowHistory(val: any): val is WorkflowHistoryType {
  return (
    isPlainObject(val) &&
    isDate(val.changedAt) &&
    typeIsIUserRef(val.user) &&
    isPlainObject(val.status) &&
    isString(val.status.from) &&
    isString(val.status.to) &&
    isString(val.comment) &&
    isInteger(val.no)
  );
}

function buildAttachmentHandler(acceptedTypes: string[]): AttachmentHandler {
  return () => {
    return new Promise<null | Attachment[]>((resolve, reject) => {
      var input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", acceptedTypes.join(","));
      input.setAttribute("multiple", "multiple");
      // input.setAttribute("capture", "environment");
      input.style.visibility = "hidden";
      document.body.appendChild(input);
      const changeEventHandler = (changeEvent: Event) => {
        console.log("FileChange", changeEvent);

        const files = (changeEvent.target as any)?.files;
        if (files instanceof FileList) {
          const attachments = [...files].map((file): Attachment => {
            return {
              data: file,
              icon: getIconForMimeType(file.type),
              name: file.name,
              comment: "",
            };
          });

          resolve(attachments);
        } else {
          resolve(null);
        }
        document.body.removeChild(input);
      };

      input.addEventListener("change", changeEventHandler, false);
      input.click();
    });
  };
}

const SPREADSHEET_TYPES = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // MS-Office 2003+
  "application/vnd.ms-excel", // MS-Office < 2003
  "application/vnd.oasis.opendocument.spreadsheet", // OpenOffice / LibreOffic / MS-Office 2003+
];

const TEXT_PROCESSING_TYPES = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // MS-Office 2003+
  "application/msword", // MS-Office < 2003
  "application/vnd.oasis.opendocument.text", // OpenOffice / LibreOffic / MS-Office 2003+
];

const PRESENTATION_TYPES = [
  "application/vnd.openxmlformats-officedocument.presentationml.presentation", // MS-Office 2003+
  "application/vnd.ms-powerpoint", // MS-Office < 2003
  "application/vnd.oasis.opendocument.presentation", // OpenOffice / LibreOffic / MS-Office 2003+
];

const DOCUMENT_MIME_TYPES = [
  ...SPREADSHEET_TYPES,
  ...TEXT_PROCESSING_TYPES,
  ...PRESENTATION_TYPES,
  "application/pdf",
];

export const attachmentHandlers: AttachmentAddHandler[] = [
  {
    type: "async",
    handler: buildAttachmentHandler(["image/*"]),
    icon: "mdi-file-image",
    title: {
      en: "Image",
      de: "Foto/Bild",
    },
  },
  {
    type: "async",
    handler: buildAttachmentHandler([
      ...DOCUMENT_MIME_TYPES,
      "image/*",
      "audio/*",
      "video/*",
    ]),
    icon: "mdi-paperclip",
    title: {
      en: "Document",
      de: "Dokument",
    },
  },
  {
    type: "async",
    handler: buildAttachmentHandler(["audio/*"]),
    icon: "mdi-microphone",
    title: {
      en: "Audio",
      de: "Audio",
    },
  },
  {
    type: "ui",
    handler: ASketchDialog,
    icon: "mdi-draw",
    title: {
      en: "Sketch",
      de: "Skizze",
    },
  },
];
