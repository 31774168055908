
































































































import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";

import AAuditNameDisplay from "@/components/snippets/AAuditNameDisplay.vue";
import AUserChip from "@/components/snippets/AUserChip.vue";
import ADateChip from "@auditcloud/components/snippets/ADateChip.vue";
import { VChip } from "vuetify/lib";

import SnippetWorkflow from "@/components/snippets/SnippetWorkflow.vue";
import ASnippetSelfAssessmentState from "@/components/snippets/ASnippetSelfAssessmentState.vue";
import { AuditMetadataClient } from "@/types/Audit";
import { AuditStatusId } from "@auditcloud/shared/lib/constants";
import {
  extractSelfAssessmentStatus,
  SelfAssessmentStatusId,
} from "@auditcloud/shared/lib/utils/audit/auditStatus";

@Component({
  components: {
    AAuditNameDisplay,
    AUserChip,
    ADateChip,
    VChip,
    SnippetWorkflow,
    ASnippetSelfAssessmentState,
  },
})
export default class AAuditItem extends Vue {
  @Prop({
    type: Object,

    required: true,
  })
  audit!: AuditMetadataClient;

  @Prop({
    type: Boolean,
    default: false,
  })
  showSelfAssessmentStatus!: boolean;

  get status() {
    return this.audit &&
      this.audit.workflow &&
      typeof this.audit.workflow.status === "string"
      ? this.audit.workflow.status
      : AuditStatusId.Planning;
  }

  get selfAssessmentStatus(): SelfAssessmentStatusId {
    return this.audit
      ? extractSelfAssessmentStatus(this.audit)
      : SelfAssessmentStatusId.NONE;
  }

  get selfAssessmentStatusVisible() {
    return (
      this.showSelfAssessmentStatus &&
      this.selfAssessmentStatus !== SelfAssessmentStatusId.NONE
    );
  }

  get dates() {
    if (!this.audit || !this.audit.auditing_date) {
      return "";
    }
    return this.audit.auditing_date.map((d: any) => {
      if (d instanceof Date) {
        return d;
      } else {
        return new Date(d);
      }
    });
  }
}
