




































import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";

@Component({})
export default class AWorkflowCrumbs extends Vue {
  @Prop({ default: false, type: Boolean })
  readonly block!: boolean;
}
