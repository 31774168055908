



















































































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import {
  startBrowserBlobDownload,
  triggerDownload,
  getDownloadLinkForAttachment,
  isStorageUrl,
} from "@/utils/storage";
import * as markerjs2 from "markerjs2";
import { Action, Getter } from "vuex-class";
import { api as auditApi } from "@/store/modules/audit";
import { api as measureApi } from "@/store/modules/measure";
import {
  Annotation,
  Finding,
  SelfAssessment,
} from "@auditcloud/shared/lib/schemas";
import { Attachment2AnnotationsMap } from "@/store/modules/audit/getters";
import { idable } from "@auditcloud/shared/lib/types/common";
import { v4 as uuidv4 } from "uuid";
import html2canvas from "html2canvas";
import { ApiV0FileManagementDownloadRequest } from "@auditcloud/shared/lib/schemas";
import { TodoAny } from "@auditcloud/shared/lib/utils/type-guards";
import { AuditPermissions } from "@auditcloud/shared/lib/utils/aclHelpers";
import { StoredAttachmentEntryMapWithContext } from "@auditcloud/shared/lib/types/Attachments";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import { Dictionary } from "lodash";

@Component({})
export default class AAttachmentDialog extends Vue {
  imageBlobWithMarkers: null | Blob = null;
  showMenu: boolean = false;
  annotation: Annotation = {
    markerAreaState: null,
    attachmentId: "",
    timestamp: "",
    userRef: {
      id: this.$user.id(),
      displayName: this.$user.displayName(),
    },
  };
  attachmentsMap: { [storageUrl: string]: string } = {};
  error: string = "";
  screenToSmallToEdit: boolean = false;
  imageToSmallToEdit: boolean = false;
  displayMode: "toSmall" | "editable" = "toSmall";
  loading: boolean = true;
  downloading: boolean = false;
  markerArea!: markerjs2.MarkerArea;
  markerState!: markerjs2.MarkerAreaState;
  markingDisabled: boolean = true;
  selectedAnnotation!: Annotation;

  @Prop({
    required: true,
    type: String,
  })
  readonly name!: string;

  @Prop({
    required: true,
    type: String,
  })
  readonly icon!: string;

  @Prop({
    required: true,
    type: String,
  })
  readonly url!: string;

  @Prop({
    type: String,
    default: "",
  })
  readonly attachmentId!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly externalSource!: boolean;

  @Prop({
    required: false,
    default: true,
    type: Boolean,
  })
  readonly isMarkerJsEnabled!: boolean;

  get isMarkingAllowed(): boolean {
    return this.isMarkerJsEnabled && this.markingDisabled;
  }

  get isBlobPreview() {
    return this.url.startsWith("blob:");
  }

  @Watch("url", { deep: true, immediate: true })
  onAttachmentUrlChanged(newVal: string, oldVal: string) {
    this.loading = false;

    if (!newVal) {
      return;
    }

    if (newVal.startsWith("blob:")) {
      this.$set(this.attachmentsMap, newVal, newVal);
    } else if (isStorageUrl(newVal) && !this.attachmentsMap[newVal]) {
      this.loading = true;
      getDownloadLinkForAttachment(this.decideTypeOfDownloadRequest())
        .then(response => {
          if (response.isOk()) {
            this.$set(this.attachmentsMap, newVal, response.value.downloadUrl);
          } else {
            throw response.error;
          }
        })
        .catch(err => {
          this.error = err;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  // Cannot use getter because of Marker.js
  numberOfMarkers() {
    return this.markerState?.markers?.length ?? 0;
  }

  @Getter(auditApi.getters.getAuditId, { namespace: auditApi.namespace })
  getAuditId!: TodoAny;

  @Getter(measureApi.getters.getCurrentMeasure, {
    namespace: measureApi.namespace,
  })
  measureDoc!: MeasureProcessDocument;

  @Getter(auditApi.getters.getEntityAttachmentsPrivate, {
    namespace: auditApi.namespace,
  })
  auditAttachmentsPrivate!: StoredAttachmentEntryMapWithContext;

  @Getter(auditApi.getters.getFindingsMap, {
    namespace: auditApi.namespace,
  })
  findingsMap!: { [k: string]: Finding };

  @Getter(auditApi.getters.getSelfAssessments, {
    namespace: auditApi.namespace,
  })
  selfAssessments!: Dictionary<SelfAssessment>;

  @Getter(auditApi.getters.getAuditPermissions, {
    namespace: auditApi.namespace,
  })
  auditPermissions!: AuditPermissions;

  get hasEditPermission() {
    return this.auditPermissions.write;
  }

  private decideTypeOfDownloadRequest(): ApiV0FileManagementDownloadRequest {
    const measureDoc = this.measureDoc;
    const attachmentId = this.attachmentId;
    const auditId = this.getAuditId ?? measureDoc.auditRef.id;

    const isInMeasureDoc =
      !!this.measureDoc && measureDoc.attachments[attachmentId];
    const isInMeasureFinding =
      !!this.measureDoc && !!measureDoc.finding?.attachments[attachmentId];

    if (isInMeasureDoc) {
      return {
        target: { type: "measure", measureId: measureDoc.id },
        attachmentId,
      };
    } else if (isInMeasureFinding) {
      return {
        target: { type: "measure-finding", measureId: measureDoc.id },
        attachmentId,
      };
    } else {
      const finding = Object.entries(this.findingsMap).find(([_, finding]) =>
        finding.attachmentIds.includes(attachmentId)
      );

      const selfAssessment = Object.entries(this.selfAssessments).find(
        ([_, selfAssessment]) =>
          selfAssessment.attachmentIds.includes(attachmentId)
      );

      if (finding || selfAssessment) {
        return {
          target: { type: "finding", auditId },
          attachmentId,
        };
      } else {
        return { attachmentId, target: { type: "audit", auditId } };
      }
    }
  }

  mounted() {
    window.addEventListener("resize", this.checkForDisplayMode, {
      passive: true,
    });
  }
  beforeDestroy() {
    window.removeEventListener("resize", this.checkForDisplayMode);
  }

  checkForDisplayMode() {
    this.screenToSmallToEdit = false;
    this.imageToSmallToEdit = false;
    if (window.screen.availWidth < 400 || window.screen.availHeight < 101) {
      this.displayMode = "toSmall";
      this.screenToSmallToEdit = true;
    } else if (
      this.$refs.imgRef &&
      this.$refs.imgRef.naturalWidth >= 400 &&
      this.$refs.imgRef.naturalHeight >= 101
    ) {
      this.displayMode = "editable";
    } else {
      this.displayMode = "toSmall";
      this.imageToSmallToEdit = true;
    }
  }

  onImgLoaded(evt: Event) {
    this.checkForDisplayMode();
    markerjs2.Activator.addKey("MJS2-P153-S274-0172");
    this.showMarkerArea(false);
  }

  get previewUrl() {
    return this.attachmentsMap[this.url] || null;
  }

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly isImage!: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly value!: boolean;

  @Action(auditApi.actions.storeAnnotation, { namespace: auditApi.namespace })
  storeAnnotation!: (payload: {
    annotation: Annotation;
    annotationId?: string;
  }) => Promise<string>;

  @Action(auditApi.actions.deleteAnnotation, {
    namespace: auditApi.namespace,
  })
  deleteAnnotation!: (payload: {
    annotation: Annotation;
    annotationId?: string;
  }) => Promise<void>;

  @Getter(auditApi.getters.getAttachment2AnnotationsMap, {
    namespace: auditApi.namespace,
  })
  attachment2AnnotationsMap!: Attachment2AnnotationsMap;

  get storedAnnotations(): idable<Annotation>[] {
    return this.attachment2AnnotationsMap[this.attachmentId] ?? [];
  }

  set dialog(val: boolean) {
    this.$emit("input", val);
  }

  get dialog(): boolean {
    return this.value;
  }

  get dialogFullscreen() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  $refs!: {
    imgRef: HTMLImageElement;
  };

  showMarkerArea(editable: boolean) {
    this.markingDisabled = false;
    this.markerArea = new markerjs2.MarkerArea(this.$refs.imgRef);

    if (this.selectedAnnotation) {
      this.markerState = this.selectedAnnotation
        .markerAreaState as markerjs2.MarkerAreaState;
    }
    this.markerArea.renderImageType = "image/png";
    this.markerArea.renderMarkersOnly = true;
    this.markerArea.availableMarkerTypes = [
      markerjs2.FrameMarker,
      markerjs2.ArrowMarker,
      markerjs2.TextMarker,
    ];
    this.markerArea.targetRoot =
      this.$refs.imgRef.parentElement ?? document.documentElement;

    const uiStyle = this.markerArea.uiStyleSettings;

    if (editable) {
      const barColor = "attachment-dialog-marker-bar-color";
      const btnColor = "attachment-dialog-marker-bar-button-color";
      const btnHoverColor = "attachment-dialog-marker-bar-button-hover-color";
      uiStyle.toolbarStyleColorsClassName = barColor;
      uiStyle.toolbarButtonStyleColorsClassName = btnHoverColor;
      uiStyle.toolbarActiveButtonStyleColorsClassName = btnColor;
      uiStyle.toolbarOverflowBlockStyleColorsClassName = btnColor;

      uiStyle.toolboxButtonRowStyleColorsClassName = barColor;
      uiStyle.toolboxButtonStyleColorsClassName = btnHoverColor;
      uiStyle.toolboxStyleColorsClassName = barColor;
      uiStyle.toolboxPanelRowStyleColorsClassName = barColor;
      uiStyle.toolboxActiveButtonStyleColorsClassName = btnColor;

      this.markingDisabled = false;
    } else {
      const markerInvisible = "attachment-dialog-marker-invisible";
      uiStyle.toolbarStyleColorsClassName = markerInvisible;
      uiStyle.toolboxStyleColorsClassName = markerInvisible;

      this.markingDisabled = true;
    }
    this.markerArea.addRenderEventListener((dataUrl, state) => {
      this.imageBlobWithMarkers = null;
      if (!state) {
        return;
      }
      this.markerState = state;
      const today = new Date().toISOString();
      this.annotation.timestamp = today;
      this.annotation.markerAreaState = state;
      this.annotation.attachmentId = this.attachmentId;

      this.storeAnnotation({
        annotation: this.annotation,
        annotationId: this.storedAnnotations[0]?.id ?? uuidv4(),
      });
      this.checkForDeletedMarkers(this.annotation);
      if (this.storedAnnotations[0]) {
        this.storedAnnotations[0].markerAreaState =
          this.annotation.markerAreaState;
      }

      this.markingDisabled = true;
      this.showMarkerArea(false);
      const markerSelector = ".__markerjs2_";
      document.querySelector(markerSelector)!.remove();
    });

    this.markerArea.addCloseEventListener(() => {
      this.markingDisabled = true;
    });

    this.markerArea.show();

    if (this.storedAnnotations[0]) {
      this.markerState = this.storedAnnotations[0]
        .markerAreaState as markerjs2.MarkerAreaState;
    } else {
      this.markerState = this.annotation
        .markerAreaState as markerjs2.MarkerAreaState;
    }
    if (this.markerState) {
      this.markerArea.restoreState(this.markerState);
    }
  }

  checkForDeletedMarkers(annotation: Annotation) {
    const markerAreaState =
      annotation.markerAreaState as markerjs2.MarkerAreaState;
    if (markerAreaState.markers.length === 0) {
      this.deleteAnnotation({
        annotation,
        annotationId: this.storedAnnotations[0].id,
      });
    }
  }

  downloadMediaPlain() {
    if (this.previewUrl) {
      triggerDownload(this.previewUrl, this.name);
    }
  }
  downloadMediaWithMarkers() {
    const storedBlob = this.imageBlobWithMarkers;
    if (storedBlob === null) {
      const imgUrl = this.previewUrl;
      const markerSelector =
        ".__markerjs2_ > div > div:nth-child(2) > div > div > svg";
      const svgSrc = document.querySelector(markerSelector);
      if (imgUrl && svgSrc) {
        this.downloading = true;
        const svg = svgSrc.cloneNode(true) as SVGSVGElement;

        const div = document.createElement("div");
        document.body.appendChild(div);

        const img = document.createElement("img");
        img.src = imgUrl;
        img.addEventListener("load", () => {
          // Wait for the image to be loaded then derive the SVG size
          svg.setAttribute("width", `${img.width}`);
          svg.setAttribute("height", `${img.height}`);
          div.appendChild(svg);

          // TO DO Gegebenenfalls ersetzen durch die rasterize Funktion von Marker.js
          // https://markerjs.com/reference/classes/renderer.html#rasterize
          html2canvas(div, {
            useCORS: true,
            width: img.width,
            height: img.height,
          })
            .then(canvas => {
              canvas.toBlob(blob => {
                this.imageBlobWithMarkers = blob;
                if (blob) {
                  startBrowserBlobDownload(blob, this.name);
                }

                div.remove();
                this.downloading = false;
              });
            })
            .catch(err => {
              console.error("html2canvas failed", err);
              this.downloading = false;
            });
        });

        img.addEventListener("error", evt => {
          console.error("Load Image failed ...", evt);
          this.downloading = false;
        });

        div.appendChild(img);
        div.className = "attachment-dialog-download-container";
      } else {
        console.error("Expect URL and SVG");
      }
    } else {
      startBrowserBlobDownload(storedBlob, this.name);
    }
  }
}
