





























































/* tslint:disable:no-console */

import Vue from "vue";
import Component from "vue-class-component";

import AControlledWorkflowCrumbs from "../controls/WorkflowCrumbs/ControlledWorkflowCrumbs.vue";
import { Prop } from "vue-property-decorator";

@Component({
  components: { AControlledWorkflowCrumbs },
})
export default class AWorkflowBar extends Vue {
  @Prop({
    default: [],
    type: Array,
  })
  readonly items!: any[];

  @Prop({
    default: 0,
    type: Number,
  })
  readonly selected!: number;

  @Prop({
    default: 0,
    type: Number,
  })
  readonly current!: number;

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly hasActions!: boolean;
}
