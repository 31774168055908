import firebase from "firebase/compat/app";
import { LoadAuditsConfig, State } from "./types";
import { RootState } from "@/store/types";
import { mutationNames as mn } from "./mutations";
import { api as activityApi } from "../activities";
import { api as confApi } from "../configuration";

import {
  createCollectionObserver,
  QueryData,
  unlistenerFunction,
} from "@/utils/firestore";
import { fieldPath } from "@auditcloud/shared/lib/utils/firestorePathHelper";
import {
  VERSIONED_DOCUMENT_FIELDNAME,
  REV_KEY,
} from "@auditcloud/shared/lib/types/VersionedDocument";
import { CollectionNames } from "@auditcloud/shared/lib/types/common";
import {
  isNumber,
  isString,
  mapValues,
  Dictionary,
  filter as filterFunc,
  omit,
  omitBy,
  pickBy,
  keys,
} from "lodash";
import { rdd } from "../user";
import {
  actionNs,
  logXStored,
  ActionTypeContext,
  getterNs,
} from "@/utils/VuexHelper";

import { createError } from "@/utils/Errors";
import { v4 as uuidv4 } from "uuid";
import { extractCurrentUserRef, extractCurrentUserRoles } from "../user/utils";
import { Getters } from ".";
import { auditsEndpointUrl } from "@/utils/HttpApi";
import { AuditProgramConfig } from "@auditcloud/shared/lib/schemas";
import axios, { AxiosError } from "axios";
import {
  ApiV0AuditsBatchChangeAuditProgramRequest,
  ApiV0AuditsBatchChangeAuditProgramResponse,
} from "@auditcloud/shared/lib/schemas/interfaces";
import { getterNames as gn } from "./getters";
import { AuditPermissionInfo } from "../dashboard/types";
import { formatAuditName } from "@auditcloud/shared/lib/utils/formatting/audits";
import { createAccessFilter } from "@/utils/firestore/queryFilters";

type Context = ActionTypeContext<State, RootState, Getters>;

function isRestartObserverRequired(
  activeFilter: null | LoadAuditsConfig,
  nextFilter: LoadAuditsConfig
) {
  return (
    activeFilter === null ||
    activeFilter.member !== nextFilter.member ||
    (isNumber(activeFilter.year) && activeFilter.year !== nextFilter.year)
  );
}

const actions = {
  async loadAudits(
    { commit, dispatch, getters, state, rootGetters }: Context,
    payload: LoadAuditsConfig
  ) {
    console.assert(payload, "loadAudits require a payload");

    commit(mn.SET_SELECTED_YEAR, payload.year);
    if (!isRestartObserverRequired(state.activeObserverFilter, payload)) {
      return state.loadedAuditsUnlistener;
    }

    dispatch("app/setLoading", false, { root: true });
    const filter = [
      new QueryData(
        fieldPath(REV_KEY, VERSIONED_DOCUMENT_FIELDNAME.DELETED),
        "==",
        false
      ),
    ];

    if (isNumber(payload.year)) {
      filter.push(new QueryData("planning_year", ">=", payload.year - 1));
      filter.push(new QueryData("planning_year", "<=", payload.year + 1));
    }

    const user = extractCurrentUserRef(rootGetters);
    const roles = extractCurrentUserRoles(rootGetters);
    filter.push(
      createAccessFilter({
        roles: payload.member ? [] : roles,
        userId: payload.member ?? user?.id ?? null,
      })
    );

    const unlistener = createCollectionObserver(
      CollectionNames.AUDITS,
      {
        filter,
      },
      updateData => {
        console.time(mn.SET_AUDITS);
        commit(mn.SET_AUDITS, updateData);
        console.timeEnd(mn.SET_AUDITS);
      },
      updateMetadata => {
        console.time(mn.SET_AUDITS_METADATA);
        commit(mn.SET_AUDITS_METADATA, updateMetadata);
        console.timeEnd(mn.SET_AUDITS_METADATA);
      },
      () => {}
    );
    commit(mn.SET_AUDITS_UNLISTENER, { unlistener, filter: payload });
    return unlistener;
  },
  async clearAudits({ commit }: Context, payload: unlistenerFunction | null) {
    // TODO: Die Reihenfolge der Ausführung im Componenten Lifecycle ist nicht nutzbar (OLD Create, New Create, OLD beforeDestroy, New beforeDestroy)
    commit(mn.CLEAR_AUDITS, payload);
  },
  async deleteAudit(
    { state, rootGetters, dispatch, getters }: Context,
    { auditId }: { auditId: string }
  ): Promise<void> {
    try {
      const url = auditsEndpointUrl(auditId);
      const res = await axios.delete(url);
      console.log("AXIOS:done", res);
    } catch (error) {
      const err = error as AxiosError;
      console.error("AXIOS:failed", err);
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Response other than 2xx");
        console.error(
          "deleteAudit: unexpected response status: ",
          err.response.status,
          err.response.headers,
          err.response.data
        );
      } else if (err.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error("Request");
        console.error(err.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error", err.message);
      }

      throw err;
    }
  },
  async changeAuditProgram(
    { state, rootGetters, dispatch }: Context,
    { auditIds, auditProgramId }: ApiV0AuditsBatchChangeAuditProgramRequest
  ) {
    const knownAuditProgramIds = keys(
      rootGetters[
        getterNs(confApi, confApi.getters.getMappedAuditPrograms)
      ] as Dictionary<AuditProgramConfig>
    );

    if (
      auditIds.length === 0 ||
      !knownAuditProgramIds.includes(auditProgramId)
    ) {
      return;
    }

    const url = auditsEndpointUrl("batch", "change-audit-program");
    try {
      const res = await axios.post<ApiV0AuditsBatchChangeAuditProgramResponse>(
        url,
        {
          auditIds,
          auditProgramId,
        }
      );
      console.log("AXIOS:done", res);
    } catch (error) {
      const err = error as AxiosError;
      console.error("AXIOS:failed", err);
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Response other than 2xx");
        console.error(
          "changeAuditProgram: unexpected response status: ",
          err.response.status,
          err.response.headers,
          err.response.data
        );
      } else if (err.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error("Request");
        console.error(err.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error", err.message);
      }

      throw err;
    }
  },
};

const n = mapValues(actions, (_, key) => key);

export { n as actionNames, actions };
