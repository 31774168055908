import { MutationTree } from "vuex";
import { CurrentTemplateState } from "./types";
import { Filter } from "@auditcloud/shared/lib/utils/filter/types";
import { isEqual, xorWith } from "lodash";

const SET_TEMPLATE_ID = "SET_TEMPLATE_ID";
const SET_TEMPLATE_ITEM_ID = "SET_TEMPLATE_ITEM_ID";
const SET_FULLTEXT_SEARCH = "SET_FULLTEXT_SEARCH";
const SET_FILTER_SETTING = "SET_FILTER_SETTING";
const TOGGLE_FILTER_SETTING = "TOGGLE_FILTER_SETTING";
const SET_FILTERS = "SET_FILTERS";
const CLEAR_FILTER_FOR_AGGREGATIONS = "CLEAR_FILTER_FOR_AGGREGATIONS";

const CLEAR_FILTER_SETTINGS = "CLEAR_FILTER_SETTINGS";
const REMOVE_FILTER_SETTING = "REMOVE_FILTER_SETTING";
// const SET_LOADING_STATE = "SET_LOADING_STATE";

const n = {
  SET_TEMPLATE_ID,
  SET_TEMPLATE_ITEM_ID,
  SET_FULLTEXT_SEARCH,
  SET_FILTER_SETTING,
  TOGGLE_FILTER_SETTING,
  SET_FILTERS,
  CLEAR_FILTER_FOR_AGGREGATIONS,
  CLEAR_FILTER_SETTINGS,
  REMOVE_FILTER_SETTING,
};

const mutations: MutationTree<CurrentTemplateState> = {
  [n.SET_TEMPLATE_ID](state, templateId: string | null) {
    console.assert(
      typeof templateId === "string" || templateId === null,
      "Invalid Call, expect string or null"
    );
    state.currentTemplateId = templateId;
  },
  [n.SET_TEMPLATE_ITEM_ID](state, templateItemId: string | null) {
    console.assert(
      typeof templateItemId === "string" || templateItemId === null,
      "Invalid Call, expect string or null"
    );
    state.currentTemplateItemId = templateItemId;
  },
  [n.SET_FULLTEXT_SEARCH](state, payload: string) {
    state.fulltextSearch = payload;
  },
  [n.SET_FILTER_SETTING](state, payload: Filter) {
    const idx = state.activeFilter.findIndex(filter =>
      isEqual(filter, payload)
    );
    if (idx < 0) {
      const filters = [...state.activeFilter, payload];

      state.activeFilter = filters;
    }
  },
  [n.TOGGLE_FILTER_SETTING](state, filter: Filter) {
    state.activeFilter = xorWith(state.activeFilter, [filter], isEqual);
  },
  [n.SET_FILTERS](state, filters: Filter[]) {
    state.activeFilter = filters;
  },
  [n.CLEAR_FILTER_FOR_AGGREGATIONS](
    state,
    aggregationId: Filter["aggregationId"]
  ) {
    state.activeFilter = state.activeFilter.filter(
      activeFilter => activeFilter.aggregationId !== aggregationId
    );
  },
  [n.CLEAR_FILTER_SETTINGS](state) {
    state.activeFilter = [];
  },
  [n.REMOVE_FILTER_SETTING](state, payload: Filter) {
    const filters = state.activeFilter.filter(
      filter => !isEqual(filter, payload)
    );
    console.log("REMOVE_FILTER_SETTING", filters);
    if (filters.length < state.activeFilter.length) {
      state.activeFilter = filters;
    }
  },
};

export { n as mutationNames, mutations };
