var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',_vm._b({attrs:{"top":"","open-delay":_vm.openDelay,"disabled":_vm.noTooltip || _vm.datePickerMenu},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
var attrsTooltip = ref.attrs;
return [_c('v-menu',{attrs:{"disabled":_vm.disabled || !!_vm.completedOn || !_vm.editable,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
var attrsMenu = ref.attrs;
return [(_vm.chip)?_c('v-chip',_vm._g(_vm._b({staticClass:"a-date-chip elevation-0",class:Object.assign({}, {delayed: _vm.delayed,
            pointer: _vm.editable,
            'close-to-due-date': !_vm.delayed && _vm.closeToDueDate,
            'font-weight-bold': _vm.delayed || _vm.closeToDueDate},
            _vm.contentClass),attrs:{"ripple":_vm.editable,"color":_vm.delayed ? '#fae3e3' : _vm.closeToDueDate ? '#fff1db' : undefined,"text-color":_vm.delayed ? '#e57373' : _vm.closeToDueDate ? '#ffb74d' : undefined}},'v-chip',Object.assign({}, attrsMenu, attrsTooltip, _vm.$attrs),false),Object.assign({}, onMenu, onTooltip)),[(_vm.isLoading)?_c('v-progress-circular',{staticStyle:{"margin-left":"-6px","margin-right":"10px"},attrs:{"indeterminate":"","size":"20","width":"2"}}):(_vm.completedOn)?_c('span',[(_vm.completedOnTime)?_c('v-icon',{attrs:{"left":"","small":_vm.small}},[_vm._v(" "+_vm._s(_vm.completedOnTimeIcon)+" ")]):_c('v-icon',{attrs:{"left":"","small":_vm.small}},[_vm._v(" "+_vm._s(_vm.completedLateIcon)+" ")])],1):(_vm.delayed)?_c('span',[_c('v-icon',{attrs:{"left":"","small":_vm.small}},[_vm._v(" "+_vm._s(_vm.inProgessIcon)+" ")])],1):(_vm.inProgress)?_c('span',[_c('v-icon',{attrs:{"left":"","small":_vm.small}},[_vm._v(" "+_vm._s(_vm.inProgessIcon)+" ")])],1):_vm._e(),_vm._v(" "+_vm._s(_vm.dateText)+" "),(!_vm.completedOn && _vm.editable)?_c('v-icon',{attrs:{"right":"","small":_vm.$attrs.small !== 'undefined'}},[_vm._v(" mdi-chevron-down ")]):_vm._e()],1):_c('span',_vm._g(_vm._b({class:_vm.contentClass,staticStyle:{"cursor":"default"}},'span',Object.assign({}, attrsTooltip, attrsTooltip),false),Object.assign({}, onMenu, onTooltip)),[_vm._v(" "+_vm._s(_vm.timeSinceDate)+" ")])]}}],null,true),model:{value:(_vm.datePickerMenu),callback:function ($$v) {_vm.datePickerMenu=$$v},expression:"datePickerMenu"}},[_c('v-card',[_c('div',[_c('v-date-picker',{staticClass:"border-none",attrs:{"no-title":"","value":_vm.datePickerValue},on:{"input":function($event){_vm.onSelectionChange($event);
              _vm.datePickerMenu = false;}}})],1),_c('div',{staticClass:"mx-4 pb-2"},[_c('v-btn',{attrs:{"block":"","depressed":""},on:{"click":_vm.setCurrentDate}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-calendar-today")]),_vm._v(" "+_vm._s(_vm.$t("common.buttons.today"))+" ")],1)],1)])],1)]}}])},'v-tooltip',_vm.$attrs,false),[(_vm.isDueDate)?_c('div',[(_vm.completedOn)?[(!_vm.completedOnTime)?_c('div',[_c('v-icon',{attrs:{"left":"","color":"white","small":""}},[_vm._v(" "+_vm._s(_vm.completedLateIcon)+" ")]),_vm._v(" "+_vm._s(_vm.$t("components.snippets.date_snippet.completed_late"))+" "+_vm._s(_vm.completedOnText)+" ")],1):_c('div',[_c('v-icon',{attrs:{"left":"","color":"white","small":""}},[_vm._v(" "+_vm._s(_vm.completedOnTimeIcon)+" ")]),_vm._v(" "+_vm._s(_vm.$t("components.snippets.date_snippet.completed_on_time"))+" "+_vm._s(_vm.completedOnText)+" ")],1)]:[(_vm.delayed)?_c('v-icon',{attrs:{"left":"","color":"white","small":""}},[_vm._v(" "+_vm._s(_vm.completedLateIcon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("components.snippets.date_snippet.due"))+" "+_vm._s(_vm.dueDateDifference)+" ")]],2):_vm._e(),(!_vm.delayed && !_vm.completedOn && !_vm.inProgress)?_c('div',[(_vm.isDueDate)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("components.snippets.date_snippet.due_on"))+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.dateTooltipText)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }