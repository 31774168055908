import { Dispatcher } from "@/store/helper/firestore";

export const api = {
  namespace: "favorites",
  getters: {
    loadedFavorites: "loadedFavorites",
    filteredFavorites: "filteredFavorites",
  },
  mutations: {
    resetState: "resetState",
    setLoadedFavorites: "setLoadedFavorites",
    createFavorite: "createFavorite",
    deleteFavorite: "deleteFavorite",
  },
  actions: {
    loadFavorites: "loadFavorites",
    createFavorite: "createFavorite",
    deleteFavorite: "deleteFavorite",
  },
  state: { loadedFavorites: "loadedFavorites" },
};

const defaultState = () => {
  return {
    [api.state.loadedFavorites]: [],
  };
};

const state = defaultState();

const mutations = {
  [api.mutations.resetState](state) {
    Object.assign(state, defaultState());
  },
  [api.mutations.setLoadedFavorites](state, payload) {
    state.loadedFavorites = payload;
  },
  [api.mutations.createFavorite](state, payload) {
    state.loadedFavorites.push(payload);
  },
};

const actions = {
  [api.actions.loadFavorites]({ commit }, payload) {
    console.log("Load Favorites", commit, payload);

    // Dispatcher Load Favorites
  },
  [api.actions.createFavorite]({ commit }, payload) {
    console.log("Create Favorite", commit, payload);
    // Dispatcher Create Favorite
  },
  // eslint-disable-next-line
  [api.actions.deleteFavorite]({ commit }, payload) {
    const favorite_id = payload.favorite_id;
    // const user_id = payload.user_id;
    //const Favorite = payload.Favorite;

    const ref = "favorites";
    const child = favorite_id;
    const mutation = "readFavorite";

    Dispatcher.deleteAsync({ ref, child, payload, commit, mutation });
  },
};

const getters = {
  [api.getters.loadedFavorites](state) {
    // TODO: apply standard filter
    return state.loadedFavorites;
  },
  [api.getters.filteredFavorites](state) {
    return objectId => {
      return state.loadedFavorites.filter(a => a.object === objectId);
    };
  },
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
