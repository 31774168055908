// Todo: see extractUserIdFromRef -> functions\src\modules\trigger\firestore\onAuditWrite.ts

const extract_id = user => {
  if (typeof user === "string") {
    return user;
  } else if (user instanceof Object && typeof user.id === "string") {
    return user.id;
  } else if (user instanceof Object && typeof user.firebase_id === "string") {
    return user.firebase_id;
  } else {
    return null;
  }
};

const user_resolver = userGetter => {
  return UserData => {
    if (!(UserData instanceof Array)) {
      UserData = [UserData];
    }
    return UserData.map(data => {
      if (
        data instanceof Object &&
        typeof data.displayName === "string" &&
        typeof data.id === "string"
      ) {
        return data.displayName;
      } else if (data) {
        const user = userGetter(extract_id(data));
        if (user) {
          return user.displayName;
        } else {
          return `User: ${data}`;
        }
      } else {
        return null;
      }
    }).filter(val => val !== null);
  };
};
export default user_resolver;

export function displayName2AvatarText(displayName) {
  return displayName
    .split(" ")
    .slice(0, 2)
    .map(s => s.substr(0, 1))
    .join("");
}
