import _Vue from "vue";
import {
  tt2str,
  I18nKey,
  I18nInline,
  typeIsI18nInline,
  typeIsI18nKey,
} from "@auditcloud/shared/lib/types/common";
import { TranslateableText } from "@auditcloud/shared/lib/schemas";
import i18n, { readFrontendLanguage } from "./i18n";
import { isString } from "lodash";

export interface ContentTranslationPluginType {
  setLanguage(locale: string): void;
  language: string;
  // resolve: TextResolver;
}

class ContentTranslation implements ContentTranslationPluginType {
  storeVM: _Vue | null = null;
  constructor() {
    this.storeVM = new _Vue({
      data: () => {
        return {
          contentLanguage: "de",
        };
      },
    });
  }

  get state() {
    return this.storeVM ? this.storeVM.$data : null;
  }

  get language(): string {
    return this.state !== null ? this.state.contentLanguage : "de";
  }
  setLanguage(locale: string) {
    if (this.state !== null) {
      this.state.contentLanguage = locale;
    }
  }
}

export const contentTranslation = new ContentTranslation();

function t(text: TranslateableText | I18nKey | I18nInline, locale: string) {
  if (typeIsI18nKey(text)) {
    return i18n.t(text["i18n-key"], locale).toString();
  } else if (typeIsI18nInline(text)) {
    return tt2str(text["i18n-inline"], locale);
  } else if (isString(text)) {
    return i18n.t(text, locale).toString();
  } else {
    return tt2str(text, locale);
  }
}

/**
 * Liefert die Übersetzung zu basierent auf der gesetzten Content-Sprache
 *
 */
export function ct(text: TranslateableText | I18nKey | I18nInline) {
  const locale = contentTranslation.language;
  return t(text, locale);
}

export function contentLanguage(): string {
  return contentTranslation.language;
}

/**
 * Liefert die Übersetzung zu basierent auf der gesetzten UI-Sprache
 *
 */
export function ft(text: TranslateableText | I18nKey | I18nInline) {
  const locale = readFrontendLanguage();
  return t(text, locale);
}

export function ContentTranslationPlugin(
  Vue: typeof _Vue,
  options?: any
): void {
  Vue.prototype.$ft = ft;
  Vue.prototype.$ct = ct;
  Vue.prototype.ContentTranslation = contentTranslation;
}
