















































































































































































import {
  AuditProof,
  WebLink,
  TranslateableText,
} from "@auditcloud/shared/lib/schemas";
import { typeIsTranslateableText } from "@auditcloud/shared/lib/types/common";
import Vue from "vue";
import { api } from "@/store/modules/audit";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { typeIsArrayOf } from "@auditcloud/shared/lib/utils/type-guards";
import { typeIsWebLink } from "@auditcloud/shared/lib/types/Weblink";
import { cloneDeep, isString } from "lodash";
import { typeIsAuditProof } from "@auditcloud/shared/lib/types/AuditProof";

import AWeblink from "@/components/snippets/AWeblink.vue";
import AAuditQuestionAdditionalContentBox from "@/components/controls/AAuditQuestionAdditionalContentBox.vue";

import AIconTogglebar, {
  ToggleConfig,
} from "@/components/controls/AIconTogglebar.vue";
import { QuestionSearchResult } from "@auditcloud/shared/lib/types/Audit/types";
import { Filter } from "@auditcloud/shared/lib/utils/filter/types";
import { Getter } from "vuex-class";

export const QUESTION_CONTENT_TOGGLES: ToggleConfig[] = [
  {
    id: "hint",
    title: "components.controls.question_content.toggle_title_hint",
    iconColor: "#cccccc",
    icon: "mdi-information",
    iconOutlined: "mdi-information-outline",
  },
  {
    id: "labels",
    title: "components.controls.question_content.toggle_title_label",
    iconColor: "#cccccc",
    icon: "mdi-bookmark-multiple",
    iconOutlined: "mdi-bookmark-multiple-outline",
  },
  {
    id: "links",
    title: "components.controls.question_content.toggle_title_link",
    iconColor: "#cccccc",
    icon: "mdi-link-variant",
  },
  {
    id: "proofs",
    title: "components.controls.question_content.toggle_title_proof",
    iconColor: "#cccccc",
    icon: "mdi-exclamation",
  },
  {
    id: "notes",
    title: "components.controls.question_content.toggle_title_note",
    iconColor: "#cccccc",
    icon: "mdi-message-text",
    iconOutlined: "mdi-message-text-outline",
  },
];

@Component({
  components: {
    AWeblink,
    AIconTogglebar,
    AAuditQuestionAdditionalContentBox,
  },
})
export default class AQuestionContent extends Vue {
  currentToggle: string = "";
  openPanel: number = 0;

  get toggles(): ToggleConfig[] {
    const toggleElements: { id: string; activeSearchResult?: boolean }[] = [];
    if (this.hasHint) {
      toggleElements.push({
        id: "hint",
        activeSearchResult: this.searchResult && this.searchResult.hitInHint,
      });
    }
    if (this.labels.length > 0) {
      toggleElements.push({
        id: "labels",
      });
    }

    if (this.links.length > 0) {
      toggleElements.push({
        id: "links",
      });
    }

    if (this.notes.length > 0) {
      toggleElements.push({
        id: "notes",
        activeSearchResult: this.searchResult && this.searchResult.hitInNotes,
      });
    }

    if (this.auditItemProofs && this.auditItemProofs.length > 0) {
      toggleElements.push({
        id: "proofs",
      });
    }

    const toggles = cloneDeep(QUESTION_CONTENT_TOGGLES).filter(toggle => {
      const element = toggleElements.find(
        toggleElement => toggleElement.id === toggle.id
      );
      if (element) {
        if (element.activeSearchResult) {
          toggle.iconColor = "warning";
        } else {
          toggle.iconColor = toggle.iconColor ?? "";
        }
        return true;
      }
      return false;
    });

    return toggles;
  }

  @Prop({
    type: Array,
    default: () => [],
    validator: (v: unknown) => typeIsArrayOf(v, isString),
  })
  readonly tags!: string[];

  @Prop({
    type: Array,
    default: () => [],
    validator: (v: unknown) => typeIsArrayOf(v, isString),
  })
  readonly labels!: string[];

  @Prop({
    type: Array,
    default: () => [],
    validator: (v: unknown) => typeIsArrayOf(v, typeIsAuditProof),
  })
  readonly auditItemProofs!: AuditProof[];

  @Prop({
    type: Array,
    default: () => [],
    validator: (v: unknown) => typeIsArrayOf(v, typeIsWebLink),
  })
  readonly links!: WebLink[];

  @Prop({
    type: [Object, String],
    default: null,
    validator: (v: unknown) => typeIsTranslateableText(v),
  })
  readonly hint!: TranslateableText;

  @Prop({
    type: [Object, String],
    required: true,
    validator: (v: unknown) => typeIsTranslateableText(v),
  })
  readonly text!: TranslateableText;

  @Prop({
    type: [Object, String],
    validator: (v: unknown) => typeIsTranslateableText(v),
  })
  readonly selfAssessmentText!: TranslateableText;

  @Prop({
    type: String,
    default: () => "",
    validator: isString,
  })
  readonly toggle!: string;

  @Prop({
    type: String,
    default: () => "",
    validator: isString,
  })
  readonly filteredText!: string;

  @Prop({
    type: Object,
    default: (): QuestionSearchResult => ({}),
  })
  readonly searchResult!: QuestionSearchResult;

  @Prop({
    type: Array,
    default: () => [],
    validator: val => typeIsArrayOf(val, isString),
  })
  readonly notes!: string[];

  @Prop({
    type: Array,
    default: () => [],
  })
  readonly activeFilters!: Array<Filter["value"]>;

  @Prop({
    type: Boolean,
    default: false,
  })
  questionInSelfassessment!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  showSelfAssessment!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  allowFiltering!: boolean;

  /** Use prop when there is no current audit (the getter is not available) */
  @Prop({
    type: Boolean,
    default: false,
  })
  hideRequirementText!: boolean;

  @Getter(api.getters.hideRequirementText, {
    namespace: api.namespace,
  })
  getHideRequirementText!: boolean;

  @Watch("toggle", { immediate: true, deep: true })
  watchToggleChanged(newVal: string) {
    this.setToggle(newVal);
  }

  get hasHint(): boolean {
    const hint = this.$ct(this.hint ?? "").trim();
    return hint !== "";
  }

  setToggle(toggle: string) {
    if (toggle === this.currentToggle) {
      this.currentToggle = "";
    } else {
      this.currentToggle = toggle;
    }
  }

  filterTag(tag: string) {
    if (this.allowFiltering) {
      this.$emit("filter-change", tag);
    }
  }
}
