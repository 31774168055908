import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import { UsersByRole } from "../types";
import i18n from "@/plugins/i18n";
import { uniqBy, isArray } from "lodash";
import { UserRef } from "@auditcloud/shared/lib/schemas";
import { MeasureRoles } from "@auditcloud/shared/lib/constants/roles";

/**
 * Get users by groups that participated in the specified measure
 *
 * @param measure The measure to get the users from
 */
export function getUsersByRoleFromMeasure(
  measure: MeasureProcessDocument,
  preselectionRoles: MeasureRoles[]
): UsersByRole<MeasureRoles> {
  const storedUserIds = new Set<string>();

  const generateUniqUsersList = (
    usersInput: null | UserRef | UserRef[] = null
  ): UserRef[] => {
    const users = usersInput
      ? isArray(usersInput)
        ? usersInput
        : [usersInput]
      : [];

    const cleanUsers = users.filter(user => !storedUserIds.has(user.id));
    cleanUsers.forEach(user => {
      storedUserIds.add(user.id);
    });
    return uniqBy(cleanUsers, user => user.id);
  };

  const createUsersByRole = (
    roleId: MeasureRoles,
    roleNameId: string,
    users: UserRef[]
  ) => {
    return {
      roleId,
      roleName: i18n.t(roleNameId).toString(),
      users,
      preselect: preselectionRoles.includes(roleId),
    };
  };

  const usersByRole: UsersByRole<MeasureRoles> = [
    createUsersByRole(
      MeasureRoles.AUDITOR,
      "components.dialogs.measure_process_dialog.lead_auditor",
      generateUniqUsersList(measure.userRefs.leadAuditor)
    ),
    createUsersByRole(
      MeasureRoles.CO_AUDITOR,
      "components.dialogs.measure_process_dialog.co_auditors",
      generateUniqUsersList(measure.userRefs.coAuditors)
    ),
    createUsersByRole(
      MeasureRoles.RESPONSIBLE,
      "components.dialogs.measure_process_dialog.responsibility",
      generateUniqUsersList(measure.userRefs.responsible)
    ),
    createUsersByRole(
      MeasureRoles.MEASURE_PARTICIPANT,
      "components.dialogs.measure_process_dialog.participant",
      generateUniqUsersList(measure.userRefs.participants)
    ),
  ];
  return usersByRole;
}
