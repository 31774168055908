






































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Action } from "vuex-class";
import { api } from "@/store/modules/users";
import { v5 as uuidv5 } from "uuid";
import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";
import { VForm } from "../types";
import {
  validate as validateDisplayName,
  sanitize as sanitizeDisplayName,
} from "@auditcloud/shared/lib/utils/sanitizer/displayName";

const USERREF_NAME_UUID = "fbad2fb0-cf1a-442a-a065-8f3be5582c0d";
function deriveIdFromName(displayName: string): string {
  return uuidv5(displayName, USERREF_NAME_UUID);
}

@Component({})
export default class AUserAutocompleteCreatePersonDialog extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  value!: boolean;
  loading = false;

  newUserDisplayName: string = "";

  @Action(api.actions.createUserInactive, { namespace: api.namespace })
  createUserInactive!: (payload: {
    data: { email: string; displayName: string };
  }) => Promise<{ created: boolean; ref: IUserRef }>;

  get nameRules() {
    return [
      v => !!v || this.$t("common.rules.name_required").toString(),
      v =>
        validateDisplayName(sanitizeDisplayName(v)) ||
        this.$t("common.rules.name_required").toString(),
    ];
  }

  mounted() {
    setTimeout(() => (this.$refs.nameInput as HTMLInputElement).focus(), 100);
  }

  addNewUser() {
    console.log("addNewPerson", this.newUserDisplayName);
    if (!this.$refs || !this.$refs.form) {
      console.error("addUser Failed", this.$refs && this.$refs.form);
      return;
    }

    const form = this.$refs.form as VForm;
    if (!form.validate()) {
      console.error("From invalid", form, form.validate());
      return;
    }

    const displayName = sanitizeDisplayName(this.newUserDisplayName);
    const id = deriveIdFromName(displayName);

    this.loading = true;
    this.$emit("save", {
      data: { type: "name-only", displayName },
      disabled: false,
      id,
    });
    this.newUserDisplayName = "";
    this.$emit("input", false);
    this.loading = false;
  }
}
