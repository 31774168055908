



















import {
  TransitionConfig,
  TransitionContext,
} from "@auditcloud/shared/lib/workflow/types/Transition";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

import {
  api,
  Getters as MeasureGetters,
  Actions as MeasureActions,
} from "@/store/modules/measure";
import { idable } from "@auditcloud/shared/lib/types/common";
import { isEqual } from "lodash";

@Component({
  components: {},
})
export default class AMeasureTransitionSelectionTabs extends Vue {
  readonly TRANSITION_CONTEXT: TransitionContext = "edit";
  loading = false;
  tab = 0;

  @Getter(api.getters.getPossibleTransitions, {
    namespace: api.namespace,
  })
  transitions!: MeasureGetters["getPossibleTransitions"];

  @Action(api.actions.activateTransition, { namespace: api.namespace })
  activateTransition!: MeasureActions["activateTransition"];

  get transitionsForContext() {
    return (
      this.transitions?.filter(t =>
        t.showInContexts.includes(this.TRANSITION_CONTEXT)
      ) ?? null
    );
  }

  @Watch("transitionsForContext", { immediate: true, deep: true })
  onTransitionsChange(
    newVal: idable<TransitionConfig>[],
    oldVal: idable<TransitionConfig>[]
  ) {
    if (!isEqual(newVal, oldVal)) {
      // Preselect the last transition (usually the "next" step)
      this.tab = this.transitionsForContext.length - 1;
    }
  }

  @Watch("tab", { immediate: true, deep: true })
  onActiveTabChange(newVal: number) {
    const transitionId = this.transitionsForContext?.[newVal]?.id ?? null;
    this.loading = true;
    this.activateTransition(transitionId).finally(() => {
      this.loading = false;
    });
  }
}
