










































/* tslint:disable:no-console */

import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { VChip } from "vuetify/lib";
const textColorCache = {};

@Component({
  components: { VChip },
})
export default class AChip extends Vue {
  textColor: string = "white";
  @Prop({
    type: String,
    required: true,
  })
  readonly color!: string;

  @Prop({ type: Boolean, default: false })
  readonly circle!: boolean;

  @Prop({ type: String, default: "20px" })
  readonly size!: string;

  @Watch("color", {
    immediate: true,
  })
  updateColorText() {
    this.$nextTick(() => this.calculateTextColor());
  }

  calculateTextColor() {
    if (this.color in textColorCache) {
      this.textColor = textColorCache[this.color];
      return;
    }
    let element = (this.$refs.coloredChip as Vue).$el;
    let style = window.getComputedStyle(element, "");
    let bgColor = style
      .getPropertyValue("background-color")
      .match(
        /rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/
      );
    if (bgColor) {
      const brightness = Math.round(
        (parseInt(bgColor[1]) * 299 +
          parseInt(bgColor[2]) * 587 +
          parseInt(bgColor[3]) * 114) /
          1000
      );
      if (brightness < 125) {
        this.textColor = "white";
      } else {
        this.textColor = "black";
      }
      textColorCache[this.color] = this.textColor;
    }
  }
}
