




































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Action } from "vuex-class";

import VJsoneditor from "v-jsoneditor";
import { cloneDeep, isEqual, Dictionary } from "lodash";
import ConfigurationListsDocSchema from "@auditcloud/shared/lib/schemas/documents/ConfigurationListsDoc.schema.json";
import { ConfigurationListsDoc } from "@auditcloud/shared/lib/schemas";
import ajv from "@auditcloud/shared/lib/schemas/validator";

import { api } from "@/store/modules/configuration";
import { api as appApi } from "@/store/modules/app";
import { getterNs } from "../../utils/VuexHelper";
import { TodoAny } from "@auditcloud/shared/lib/utils/type-guards";
@Component({
  components: { VJsoneditor },
})
export default class AConfigEditorLists extends Vue {
  editedData: null | ConfigurationListsDoc = null;

  valid: boolean = true;
  options = {
    ajv,
    onValidationError: this.onValidationError,
    schema: ConfigurationListsDocSchema,
    templates: [],
    mode: "form",
    modes: ["tree", "form", "code", "text"],
  };

  segmentName = "lists";

  get storedData(): ConfigurationListsDoc | null {
    const getterName = getterNs(api, this.segmentName);
    if (this.segmentName in api.getters) {
      return cloneDeep(this.$store.getters[getterName]);
    } else {
      return null;
    }
  }
  @Watch("storedData", {
    immediate: true,
  })
  onSegmentNameChange(
    val: ConfigurationListsDoc | null,
    oldVal?: ConfigurationListsDoc | null
  ) {
    this.valid = true;
    this.editedData = cloneDeep(val);
  }

  get isDirty() {
    return !isEqual(this.storedData, this.editedData);
  }

  @Action(api.actions.updateConfiguration, { namespace: api.namespace })
  updateConfiguration!: (payload: {
    segment: string;
    data: Dictionary<any>;
  }) => Promise<any>;

  @Action(appApi.actions.setStatus, { namespace: appApi.namespace })
  setStatus!: (payload: string) => Promise<any>;

  importJsonConf() {
    console.log(
      "importJsonConf",
      this.segmentName,
      this.editedData,
      this.valid,
      this.isDirty
    );
    if (this.valid && this.isDirty && this.editedData !== null) {
      this.setStatus("loading");
      try {
        console.log("Start Save");
        const payload = { segment: this.segmentName, data: this.editedData };

        console.log("Importing", payload);

        this.updateConfiguration(payload);
        this.setStatus("success");
      } catch (error) {
        console.log("Json Parse failed:", error);
        this.setStatus("error");
      }
    }
  }
  onError(err: TodoAny) {
    console.error("AConfigEditorLists:onError", err);
    this.valid = false;
  }
  onValidationError(errors: any[]) {
    this.valid = errors.length === 0;
    errors.forEach(error => {
      console.error("AConfigEditorLists:ValidationError", error);
    });
  }
  onInput(data: null | ConfigurationListsDoc) {
    this.valid = true;
    this.editedData = data;
  }
}
