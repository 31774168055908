


















import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { State, Action, Getter, Mutation } from "vuex-class";
import {
  TodoMap,
  TodoArray,
  TodoAny,
  TodoActionSignature,
  TodoMutationSignature,
} from "@auditcloud/shared/lib/utils/type-guards";

import { mapActions, mapGetters } from "vuex";

// import { api } from "@/store/modules/configuration";
import { api as appApi } from "@/store/modules/app";

@Component({})
export default class StatusMessage extends Vue {
  mode: string = "";
  timeout: number = 5000;
  snackbar: boolean = false;
  currentMessage: TodoMap | false = {};

  @Getter(appApi.getters.getMessageline, { namespace: appApi.namespace })
  getMessageline!: TodoAny;

  get messageline() {
    return this.getMessageline;
  }
  get currentColor() {
    const msg = this.currentMessage;

    if (msg && msg.type === "error") {
      return "red";
    } else if (msg && msg.type === "success") {
      return "green";
    } else {
      return "secondary";
      // return "grey";
    }
  }

  @Action(appApi.actions.shiftStatus, { namespace: appApi.namespace })
  shiftStatus!: TodoActionSignature;

  shiftMessage() {
    const newMessage = this.getMessageline[0];
    this.currentMessage = newMessage;
    this.snackbar = true;
    this.shiftStatus();
  }

  @Watch("currentMessage")
  watchOnCurrentStatus() {
    if (this.currentMessage !== false) {
      this.snackbar = true;
    }
  }
  @Watch("snackbar")
  async watchOnSnackbar(visible) {
    if (visible || !this.messageline.length) return;
    await this.$nextTick();
    setTimeout(() => {
      this.shiftMessage();
    }, 500);
  }
  @Watch("messageline")
  watchOnMessageline() {
    if (!this.snackbar) {
      this.shiftMessage();
    }
  }
}
