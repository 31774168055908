





























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { WebLink } from "@auditcloud/shared/lib/schemas";

@Component({})
export default class AWeblink extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  link!: WebLink;

  get url() {
    return this.link.url;
  }

  get label() {
    if (typeof this.link.label === "string") {
      return this.link.label;
    } else {
      return "…";
    }
  }
}
