







































































































































































































































































































































































































































import Component from "vue-class-component";
import { Prop, Watch, Mixins } from "vue-property-decorator";
import { Action, Getter, Mutation, namespace } from "vuex-class";
import {
  size,
  omit,
  Dictionary,
  pick,
  isString,
  uniqBy,
  isUndefined,
  isNull,
} from "lodash";

import FormsControlMixin from "@/components/mixins/FormsControlMixin.vue";

import { Attachment, QuickActionConfig } from "../types";
import { api as auditApi } from "@/store/modules/audit";
import {
  api as findingApi,
  Actions as FindingActions,
  Getters as FindingGetters,
  Mutations as FindingMutations,
} from "@/store/modules/finding";

import { stripDialog } from "@/routenames";

import AAuditOverviewQuestion from "@/components/widgets/AAuditOverviewQuestion.vue";
import ARiskWidget, {
  RiskWidgetOutput,
} from "@/components/widgets/ARiskWidget.vue";
import SimplePagination from "@/components/controls/SimplePagination.vue";
import AAttachmentUploadList from "@/components/snippets/AAttachmentUploadList.vue";
import AAttachmentList from "@/components/snippets/AAttachmentList.vue";
import AGeoLocationInput from "@/components/controls/AGeoLocationInput.vue";
import ALinkFindingToRequirementWidget from "@/components/widgets/ALinkFindingToRequirementWidget.vue";
import ADimensionSelect from "@/components/controls/ADimensionSelect.vue";
import AAttachmentButton from "@/components/controls/AAttachmentButton.vue";
import ASelfAssessmentResultExpansion from "@/components/widgets/ASelfAssessmentResultExpansion.vue";
import DialogLayout from "../layouts/BaseLayouts/DialogLayout.vue";

import { FindingType } from "@auditcloud/shared/lib/types/ItemTypes";
import {
  Finding,
  AuditItem,
  StoredAttachmentEntry,
  GeoLocation,
  NamedRef,
  SelfAssessment,
} from "@auditcloud/shared/lib/schemas";
import { DimensionMap } from "@/store/modules/audit/types";
import { typeIsNotEmpty } from "@auditcloud/shared/lib/utils/filter/typeIsNotEmpty";
import { attachmentHandlers } from "@/components/types";
import { AuditStatusId, NULL_UUID } from "@auditcloud/shared/lib/constants";
import { storeFindingsPatch } from "@/store/modules/finding/actions";
import { AuditItemDimensionsMap } from "@auditcloud/shared/lib/types/Audit/types";
import { nullable } from "@auditcloud/shared/lib/types/common";
import { AuditClassClient } from "@auditcloud/shared/lib/types/AuditClass";
import {
  HIGH_RISK_ID,
  LOW_RISK_ID,
  MEDIUM_RISK_ID,
  RISK_TYPE_ID,
} from "@auditcloud/shared/lib/types/UrgencyChart";
import { AuditItemWithId } from "@auditcloud/shared/lib/utils/audit/types";
import ASnippetCategoryRef from "@/components/snippets/ASnippetCategoryRef.vue";

@Component({
  components: {
    AAuditOverviewQuestion,
    AAttachmentUploadList,
    SimplePagination,
    AAttachmentList,
    AGeoLocationInput,
    ADimensionSelect,
    AAttachmentButton,
    DialogLayout,
    ARiskWidget,
    ASelfAssessmentResultExpansion,
    ASnippetCategoryRef,
    ALinkFindingToRequirementWidget,
  },
  mixins: [FormsControlMixin],
})
export default class AuditFindingDialog extends Mixins(FormsControlMixin) {
  mounted() {
    if (this.useRiskBasedFindings && this.findingId === NULL_UUID) {
      this.mixinFormData.risk = {
        impact: 0.1,
        likelihood: 0.1,
        type: "SIMPLE",
        text: "",
      };
      this.mixinFormData.type = LOW_RISK_ID;
    }
  }
  updateRisk(payload: RiskWidgetOutput) {
    const { findingTypeId, risk } = payload;
    this.mixinFormData.risk = risk;
    this.mixinFormData.type = findingTypeId;
  }

  get useRiskBasedFindings(): boolean {
    return this.auditClass?.useRiskBasedFindings ?? false;
  }

  openTopics: string | null = "";
  readonly attachmentHandlers = attachmentHandlers;

  loading: boolean = false;
  valid: boolean = false;

  uploadError: boolean = false;

  attachmentsToRemove: string[] = [];
  possibleMaturityValues: number[] = [0, 1, 2, 3, 4, 5];
  created() {
    if (this.auditItemId) {
      this.localAuditItem = this.auditItemsMap[this.auditItemId];
    }
  }

  @Getter(auditApi.getters.getUnlinkedFindingsAllowed, {
    namespace: auditApi.namespace,
  })
  unlinkedFindingsAllowed!: boolean;

  @Getter(auditApi.getters.getUnlinkedFindingsEnabled, {
    namespace: auditApi.namespace,
  })
  unlinkedFindingsEnabled!: boolean;

  localAuditItem: AuditItemWithId | null = null;

  linkAuditItem(auditItem: AuditItemWithId | null) {
    this.localAuditItem = auditItem;
    this.mixinFormData.auditItemRefs =
      auditItem === null
        ? []
        : [
            {
              auditItemId: auditItem.id,
              dimensions: null,
            },
          ];
  }

  get linkAuditItemError() {
    return (
      this.localAuditItem === null &&
      (!this.unlinkedFindingsEnabled || !this.unlinkedFindingsAllowed)
    );
  }

  get linkAuditItemErrorMessages() {
    return this.localAuditItem !== null
      ? null
      : !this.unlinkedFindingsEnabled
      ? this.$t(
          "components.dialogs.audit_finding_dialog.no_unlinked_findings_enabled"
        )
      : !this.unlinkedFindingsAllowed
      ? this.$t(
          "components.dialogs.audit_finding_dialog.no_unlinked_findings_allowed"
        )
      : null;
  }

  chapterGroups(auditItem: AuditItem) {
    const chapters = auditItem.question.chapters;
    const uniqChaptersById = uniqBy(chapters, "standardId");

    return uniqChaptersById.map(chapter => {
      return {
        standardName: chapter.standardName,
        categoryRef: auditItem.question.categoryRef,
        chapters: chapters.filter(c => c.standardId === chapter.standardId),
      };
    });
  }

  @Prop({
    type: String,
    required: true,
  })
  readonly auditId!: string;

  @Prop({
    type: String,
    default: null,
    validator: val => isString(val) || isNull(val),
  })
  readonly auditItemId!: string | null;

  @Prop({
    type: String,
    default: null,
  })
  readonly findingId!: string | null;

  @Action(auditApi.actions.moveFinding, { namespace: auditApi.namespace })
  moveFinding!: (payload: {
    findingId: string;
    formAuditItemId: string | null;
    toAuditItemId: string | null;
  }) => Promise<boolean>;

  @Action(findingApi.actions.initState, { namespace: findingApi.namespace })
  initState!: FindingActions["initState"];

  @Action(findingApi.actions.createFinding, { namespace: findingApi.namespace })
  createFinding!: FindingActions["createFinding"];

  @Action(findingApi.actions.updateFinding, { namespace: findingApi.namespace })
  updateFinding!: FindingActions["updateFinding"];

  @Getter(findingApi.getters.getFinding, { namespace: findingApi.namespace })
  readonly finding!: FindingGetters["getFinding"];

  @Mutation(findingApi.mutations.APPEND_NEW_ATTACHMENTS, {
    namespace: findingApi.namespace,
  })
  appendNewAttachments!: FindingMutations["APPEND_NEW_ATTACHMENTS"];

  @Mutation(findingApi.mutations.REMOVE_ATTACHMENT, {
    namespace: findingApi.namespace,
  })
  removeAttachment!: FindingMutations["REMOVE_ATTACHMENT"];

  @Getter(findingApi.getters.getNewAttachments, {
    namespace: findingApi.namespace,
  })
  readonly attachments!: FindingGetters["getNewAttachments"];

  @Getter(findingApi.getters.getEvaluationForSelfAssessmentWithId, {
    namespace: findingApi.namespace,
  })
  readonly evaluationForSelfAssessmentWithId!: FindingGetters["getEvaluationForSelfAssessmentWithId"];

  @Getter(auditApi.getters.getResultAttachmentsMap, {
    namespace: auditApi.namespace,
  })
  resultAttachmentsMap!: Dictionary<StoredAttachmentEntry>;

  get attachmentsForCurrentFinding() {
    const attachmentIds = this.finding?.attachmentIds ?? [];
    return pick(this.resultAttachmentsMap, attachmentIds);
  }

  @Getter(auditApi.getters.getFindingsMap, { namespace: auditApi.namespace })
  findingsMap!: Dictionary<Finding>;

  @Getter(auditApi.getters.getMappedAuditItems, {
    namespace: auditApi.namespace,
  })
  auditItemsMap!: Dictionary<AuditItemWithId>;

  @Getter(auditApi.getters.getAuditDimensionsMap, {
    namespace: auditApi.namespace,
  })
  auditDimensions!: DimensionMap;

  @Getter(auditApi.getters.getAuditItemDimensionsMap, {
    namespace: auditApi.namespace,
  })
  auditItemDimensionsMap!: AuditItemDimensionsMap;

  @Getter(auditApi.getters.getAuditClass, {
    namespace: auditApi.namespace,
  })
  auditClass!: nullable<AuditClassClient>;

  @Getter(auditApi.getters.getSelfAssessments, {
    namespace: auditApi.namespace,
  })
  selfAssessments!: Dictionary<SelfAssessment>;

  @Getter(auditApi.getters.getMaturityEnabled, {
    namespace: auditApi.namespace,
  })
  maturityEnabled!: boolean;

  get auditItems() {
    return Object.values(this.auditItemsMap);
  }

  get possibleDimensionIds() {
    if (this.auditItemId) {
      return this.auditItemDimensionsMap[this.auditItemId] ?? [];
    } else {
      return [];
    }
  }

  get possibleDimensions(): NamedRef[] {
    const res = this.possibleDimensionIds
      .map(id => {
        const dimension = this.auditDimensions[id];
        return dimension ? { ...dimension, id } : null;
      })
      .filter(typeIsNotEmpty);
    return res;
  }

  @Getter(auditApi.getters.getUnassignedFindingIds, {
    namespace: auditApi.namespace,
  })
  unassignedFindingIds!: string[];

  @Getter(auditApi.getters.getFindingQuickActionConfig, {
    namespace: auditApi.namespace,
  })
  quickActionConfig!: QuickActionConfig;

  @Getter(auditApi.getters.getFindingIds, { namespace: auditApi.namespace })
  findingIds!: string[];

  get activeAuditItem(): null | AuditItem {
    if (!isUndefined(this.localAuditItem)) {
      return this.localAuditItem;
    }
    const auditItemId = this.auditItemId;
    if (auditItemId) {
      return this.auditItemsMap[auditItemId] ?? null;
    } else {
      return null;
    }
  }

  get isUnlinkedFinding() {
    return (
      (this.auditItemId === null && this.findingId === null) ||
      (this.findingId !== null &&
        this.unassignedFindingIds.includes(this.findingId))
    );
  }

  get currentFindingType(): FindingType | null {
    const findingTypeId = this.findingTypeId;
    const findingType = this.quickActionConfig.actions.find(
      v => v.id === findingTypeId
    );
    if (findingType) {
      return findingType as FindingType;
    } else {
      return null;
    }
  }

  get qabItems() {
    if (this.isUnlinkedFinding) {
      return this.quickActionConfig.actions.filter(
        (v: any) => v.is_not_applicable === false
      );
    } else {
      return this.quickActionConfig.actions;
    }
  }
  get qabDirectEvt() {
    return this.quickActionConfig.directActions;
  }

  get riskItems() {
    return [
      ...this.quickActionConfig.actions.filter(
        findingType => !this.isRiskType(findingType.id)
      ),
      {
        id: RISK_TYPE_ID,
        text: { de: "Risiko", en: "Risk" },
      },
    ];
  }

  isRiskType(findingTypeId: string) {
    return [LOW_RISK_ID, MEDIUM_RISK_ID, HIGH_RISK_ID].includes(findingTypeId);
  }

  get findingTypeIsRisk() {
    if (this.findingTypeId === null) {
      return false;
    }
    return this.isRiskType(this.findingTypeId);
  }

  get findingTypeIdForSelect() {
    if (this.findingTypeId === null) {
      return null;
    }
    if (this.useRiskBasedFindings) {
      return this.isRiskType(this.findingTypeId)
        ? RISK_TYPE_ID
        : this.findingTypeId;
    }
    return this.findingTypeId;
  }
  set findingTypeIdForSelect(typeId: string | null) {
    if (typeId === RISK_TYPE_ID) {
      this.findingTypeId = LOW_RISK_ID;
    } else {
      this.findingTypeId = typeId;
    }
  }

  get findingTypeId(): string | null {
    return this.mixinFormData.type || null;
  }

  set findingTypeId(typeId: string | null) {
    const hasRisk =
      !!this.mixinFormData.risk &&
      this.mixinFormData.risk.impact !== null &&
      this.mixinFormData.risk.likelihood !== null;
    const shouldInitialiseRisk = !hasRisk && typeId === LOW_RISK_ID;

    if (shouldInitialiseRisk) {
      this.mixinFormData.risk = {
        impact: 0.1,
        likelihood: 0.1,
        type: "SIMPLE",
        text: "",
      };
    }

    if (typeId) {
      this.mixinFormData.type = typeId;
    } else {
      this.mixinFormData.type = null;
    }
  }

  get findingTypeError() {
    return !this.findingTypeId;
  }

  get storedAttachments() {
    if (this.mixinFormData !== null) {
      const data = omit(
        { ...this.attachmentsForCurrentFinding },
        this.attachmentsToRemove
      );

      return data;
    } else {
      return {};
    }
  }

  get hasData() {
    return this.mixinFormData instanceof Object && size(this.mixinFormData) > 0;
  }

  get isMaturityEditingAllowed() {
    return (
      this.maturityEnabled &&
      this.currentFindingType &&
      !this.currentFindingType.is_not_applicable
    );
  }

  get activeColor() {
    const item = this.quickActionConfig.actions.find(
      i => i.id === this.findingTypeId
    );
    if (item) {
      return item.color;
    } else {
      return "";
    }
  }
  get findingLocations() {
    return this.mixinFormData.locations ?? [];
  }

  set findingLocations(locations: GeoLocation[]) {
    this.$set(this.mixinFormData, "locations", locations);
  }
  get asIsDescription() {
    return this.mixinFormData.draft ?? [];
  }

  set asIsDescription(val: string) {
    this.$set(this.mixinFormData, "draft", val);
  }

  get selfAssessmentForEvaluation(): SelfAssessment | null {
    if (this.evaluationForSelfAssessmentWithId) {
      return this.selfAssessments[this.evaluationForSelfAssessmentWithId];
    }
    if (this.finding?.selfAssessmentId) {
      return this.selfAssessments[this.finding.selfAssessmentId];
    }
    return null;
  }

  get maturity() {
    return this.mixinFormData.maturity;
  }

  set maturity(maturity: number) {
    this.$set(this.mixinFormData, "maturity", maturity);
  }

  close() {
    console.log("closeDialog", this.$route);
    const auditId = this.auditId;
    const currentRouteName = this.$route.name ?? "error";
    const route = {
      name: stripDialog(currentRouteName),
      params: { auditId },
    };

    this.$router.push(route);
  }

  cancelEdit() {
    this.close();
  }

  @Watch("findingId", { immediate: true })
  onFindingIdChanged(findingId: string | null) {
    this.mixinInitFormData({});
    this.initState({
      findingId,
    });
  }

  @Watch("finding", { immediate: true, deep: true })
  onFindingChanged(finding: FindingGetters["getFinding"]) {
    this.mixinInitFormData(finding ?? {});
  }

  onSave() {
    if (this.currentFindingType !== null) {
      this.mixinInitFormData(this.mixinFormData);

      const typeId = this.currentFindingType.id;
      const finding = {
        ...this.mixinFormData,
        text: this.mixinFormData.text.trim(),
        type: typeId,
      };

      if (!this.isRiskType(typeId) && "risk" in finding) {
        delete finding.risk;
      }

      if (this.isMaturityEditingAllowed) {
        finding.maturity = this.maturity;
      }

      if (this.findingId === null || this.findingId === NULL_UUID) {
        finding.selfAssessmentId = this.evaluationForSelfAssessmentWithId;

        this.loading = true;
        const res = this.createFinding({
          finding,
          attachments: this.attachments,
        });
        this.handleSaveResult(res, "Store new finding");
      } else {
        this.loading = true;
        const res = this.updateFinding({
          findingId: this.findingId,
          finding,
          attachmentIdsToRemove: this.attachmentsToRemove,
          additionalAttachments: this.attachments,
        });
        this.handleSaveResult(res, "update finding");
      }
    }
  }

  handleSaveResult(res: ReturnType<typeof storeFindingsPatch>, msg: string) {
    res.then(result => {
      if (result.isOk()) {
        console.log(msg, result.value);
        this.uploadError = false;
        this.close();
      } else {
        if (result.error.errorType === "UPLOAD_ERROR") {
          this.removeAllAttachments();
          this.uploadError = true;
        }
      }
      this.loading = false;
    });
  }

  removeStoredAttachment(attachmentId: string) {
    this.attachmentsToRemove.push(attachmentId);
  }

  addAttachment(attachments: Attachment[]) {
    this.attachments.push(...attachments);
  }

  removeAllAttachments() {
    this.attachments.forEach((_, idx) => {
      this.removeAttachment(idx);
    });
  }
}
