import Vue from "vue";
import { MutationTree } from "vuex";
import { CurrentTemplatesState } from "./types";
import {
  unlistenerFunction,
  DocumentsUpdateHandlerData,
  MetadataUpdateHandlerData,
} from "@/utils/firestore";

const SET_TEMPLATES_UNLISTENER = "SET_TEMPLATES_UNLISTENER";
const SET_TEMPLATES = "SET_TEMPLATES";
const SET_TEMPLATES_METADATA = "SET_TEMPLATES_METADATA";
const CLEAR_TEMPLATES = "CLEAR_TEMPLATES";
const resetState = "resetState";

// const SET_LOADING_STATE = "SET_LOADING_STATE";

const n = {
  SET_TEMPLATES_UNLISTENER,
  SET_TEMPLATES,
  SET_TEMPLATES_METADATA,
  CLEAR_TEMPLATES,
  resetState,
};

const mutations: MutationTree<CurrentTemplatesState> = {
  [n.SET_TEMPLATES_UNLISTENER](state, unlistener: unlistenerFunction | null) {
    state.loadedNewQuery = true;
    const prevDocumentsUnlistener = state.DocumentsUnlistener;
    state.DocumentsUnlistener = unlistener;
    if (prevDocumentsUnlistener) {
      prevDocumentsUnlistener();
    }
  },
  [n.SET_TEMPLATES](
    state,
    { removeDocs, modifiedDocs }: DocumentsUpdateHandlerData
  ) {
    console.log(n.SET_TEMPLATES, removeDocs, modifiedDocs);
    if (state.loadedNewQuery === true) {
      state.Documents = modifiedDocs;
    } else {
      removeDocs.forEach(docId => {
        const idx = state.Documents.findIndex(v => v.id === docId);
        if (idx > -1) {
          Vue.delete(state.Documents, idx);
        }
      });

      modifiedDocs.forEach(doc => {
        const idx = state.Documents.findIndex(v => v.id === doc.id);
        if (idx > -1) {
          Vue.set(state.Documents, idx, doc);
        } else {
          state.Documents.push(doc);
        }
      });
    }
    state.loadedNewQuery = false;
  },
  [n.SET_TEMPLATES_METADATA](state, updateMetadata: MetadataUpdateHandlerData) {
    console.log(n.SET_TEMPLATES_METADATA, updateMetadata);
    if (state.loadedNewQuery === true) {
      state.Documents = [];
    } else {
      updateMetadata.forEach(doc => {
        const idx = state.Documents.findIndex(v => v.id === doc.id);
        if (idx > -1) {
          state.Documents[idx].exists = doc.exists;
          state.Documents[idx].metadata = doc.metadata;
        }
      });
    }
  },
  [n.CLEAR_TEMPLATES](state) {
    const prevDocumentUnlistener = state.DocumentsUnlistener;
    state.Documents = [];

    if (prevDocumentUnlistener) {
      prevDocumentUnlistener();
    }
  },
  [n.resetState](state) {
    const prevDocumentUnlistener = state.DocumentsUnlistener;
    state.Documents = [];
    state.loadedNewQuery = true;

    if (prevDocumentUnlistener) {
      prevDocumentUnlistener();
    }
  },
};

export { n as mutationNames, mutations };
