import { size, Dictionary } from "lodash";
import { AuditStandardRef, AuditItem } from "@auditcloud/shared/lib/schemas";
import { Filter } from "@auditcloud/shared/lib/utils/filter/types";

export class AuditTemplate {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly audit_class: string,
    public readonly categorySetId: string,
    public readonly reportSchema: string,
    public readonly templateLanguages: string[],
    public readonly standardRefs: AuditStandardRef[],
    public readonly audit_items: Dictionary<AuditItem>,
    public readonly forceUniqueQuestionNumbers: boolean
  ) {}

  get auditItemCount() {
    return size(this.audit_items);
  }
}

export class CurrentTemplateState {
  currentTemplateId: string | null = null;
  currentTemplateItemId: string | null = null;
  fulltextSearch: string = "";
  activeFilter: Filter[] = [];
}
export interface TemplateItemsNumberModifierPayload {
  startingNumber: number;
  endNumber: number;
  modifyBy: number;
}

export interface TemplateItemStoragePayload {
  item: AuditItem;
  itemNumberModification?: TemplateItemsNumberModifierPayload;
}
export interface TemplateItemRemovalPayload {
  templateItemId: string;
  itemNumberModification?: TemplateItemsNumberModifierPayload;
}

export const forceUniqueQuestionNumbersDefaultValue = true;
