var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.circle)?_c('span',[_c('v-sheet',{ref:"coloredChip",style:({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      backgroundColor: _vm.color,
      color: _vm.textColor,
      width: _vm.size,
      minWidth: _vm.size,
      height: _vm.size,
      minHeight: _vm.size,
      display: 'inline-block',
      borderRadius: '999px',
    })},[_vm._t("default")],2)],1):_c('v-chip',_vm._b({ref:"coloredChip",staticClass:"colored-chip",attrs:{"color":_vm.color,"text-color":_vm.textColor}},'v-chip',_vm.$attrs,false),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }