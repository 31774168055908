

















import { api } from "@/store/modules/template";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({})
export default class AInlineLabelInput extends Vue {
  search: string = "";
  lazyValue: string[] = [];

  @Prop({
    default: () => [],
    type: Array,
  })
  readonly value!: string[];

  @Watch("value", { immediate: true, deep: true })
  watchValueChanged(newVal: string[]) {
    this.lazyValue = newVal;
  }

  @Prop({
    default: () => "",
    type: String,
  })
  readonly label!: string;
  @Prop({
    default: () => "",
    type: String,
  })
  readonly hint!: string;

  @Getter(api.getters.getUsedLabels, {
    namespace: api.namespace,
  })
  usedLabels!: string[];

  onValueChanged(newVal: string[]) {
    const labels = Array.from(new Set(newVal));
    this.$emit("input", labels);
  }
}
