import { RootGetters } from "@/store/types";
import { getterNs } from "@/utils/VuexHelper";

import { api as userApi, Getters as UserGetters } from "./index";

export function extractCurrentUserRef(rootGetters: RootGetters) {
  return rootGetters[
    getterNs(userApi, userApi.getters.getCurrentUserRef)
  ] as UserGetters["getCurrentUserRef"];
}
export function extractCurrentUserRoles(rootGetters: RootGetters) {
  return rootGetters[
    getterNs(userApi, userApi.getters.getCurrentUserRoles)
  ] as UserGetters["getCurrentUserRoles"];
}
