import { nullable } from "@auditcloud/shared/lib/types/common";

export interface ErrorResponse {
  statusCode: number;
  message: string;
}

export enum GetterState {
  IDLE = "IDLE",
  LOADING = "LOADING",
  ERROR = "ERROR",
  FINISHED = "FINISHED",
}

export interface IStatedGetter {
  readonly status: GetterState;
  error: null | ErrorResponse;
  data: null | any;
}

export type StateGetterMutationPayload<T> =
  | { type: "data"; val: T }
  | { type: "error"; val: ErrorResponse };
export class StatedGetter<T> implements IStatedGetter {
  private xStatus = GetterState.IDLE;
  private xError: nullable<ErrorResponse> = null;
  private xData: nullable<T> = null;
  clear() {
    this.xStatus = GetterState.IDLE;
    this.xData = null;
    this.xError = null;
  }

  set data(v: nullable<T>) {
    this.xData = v;
    this.xError = null;

    if (v === null) {
      this.xStatus = GetterState.LOADING;
    } else {
      this.xStatus = GetterState.FINISHED;
    }
  }

  set error(v: nullable<ErrorResponse>) {
    this.xError = v;
    this.xData = null;
    this.xStatus = GetterState.ERROR;
  }

  get data() {
    return this.xData;
  }

  get error() {
    return this.xError;
  }

  get status() {
    return this.xStatus;
  }

  constructor(data?: nullable<T>, error?: nullable<ErrorResponse>) {
    if (typeof data !== "undefined") {
      this.data = data;
    }
    if (typeof error !== "undefined") {
      this.error = error;
    }
  }
}

export function typeIsStatedGetterOf<T>(
  val: any,
  tc: (v: any) => v is T
): val is StatedGetter<T> {
  return val instanceof StatedGetter && (val.data === null || tc(val.data));
}
