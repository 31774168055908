var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.chip)?_c('v-chip',_vm._b({class:_vm.editable ? 'pointer' : '',style:({
    padding: '0 8px 0 6px',
  }),attrs:{"small":_vm.small}},'v-chip',_vm.$attrs,false),[(_vm.isLoading)?_c('v-progress-circular',{staticStyle:{"margin-left":"0px","margin-right":"10px"},attrs:{"indeterminate":"","size":"20","width":"2"}}):(!_vm.userName)?_c('v-icon',_vm._b({staticStyle:{"margin-left":"0px","margin-right":"8px"},attrs:{"left":"","size":"20","color":_vm.prependIconColor}},'v-icon',_vm.$attrs,false),[_vm._v(" mdi-account-circle-outline ")]):(typeof _vm.prependIcon === 'undefined')?_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center","margin-right":"8px","border-radius":"20px","font-style":"initial"},style:({
      'font-weight': _vm.small ? 'thin' : 'normal',
      width: _vm.small ? '15px' : '20px',
      height: _vm.small ? '15px' : '20px',
      minWidth: _vm.small ? '15px' : '20px',
      minHeight: _vm.small ? '15px' : '20px',
      'background-color': _vm.$attrs['color']
        ? _vm.$attrs['color']
        : _vm.dark
        ? '#FFFFFF33'
        : '#ccc',
      color: _vm.$attrs['text-color']
        ? _vm.$attrs['text-color']
        : _vm.dark
        ? 'white'
        : 'inherit',
    })},[_vm._v(" "+_vm._s(_vm.userPreviewCharacter)+" ")]):(_vm.prependIcon)?_c('v-icon',_vm._b({attrs:{"left":"","color":_vm.prependIconColor}},'v-icon',_vm.$attrs,false),[_vm._v(" "+_vm._s(_vm.prependIcon)+" ")]):_vm._e(),_c('span',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm.userName ? _vm.userName : _vm.noUserPlaceholderText)+" ")]),(_vm.appendIcon)?_c('v-icon',{staticClass:"flex-shrink-0",attrs:{"right":""}},[_vm._v(" "+_vm._s(_vm.appendIcon)+" ")]):_vm._e()],1):_c('div',_vm._b({staticClass:"d-inline-block"},'div',_vm.$attrs,false),[_c('div',{staticClass:"d-flex align-center"},[(typeof _vm.prependIcon === 'undefined')?_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center","margin-right":"8px","font-style":"initial","font-weight":"bold"},style:({
        'font-weight': _vm.small ? 'thin' : 'normal',
        'background-color': _vm.$attrs['color']
          ? _vm.$attrs['color']
          : _vm.dark
          ? '#FFFFFF33'
          : '#ccc',
        color: _vm.$attrs['text-color'] ? _vm.$attrs['text-color'] : 'white',
        'border-radius': (_vm.iconRadiusPx + "px"),
        width: (_vm.iconRadiusPx + "px"),
        height: (_vm.iconRadiusPx + "px"),
      })},[_vm._v(" "+_vm._s(_vm.userPreviewCharacter)+" ")]):_vm._e(),(_vm.prependIcon)?_c('v-icon',_vm._b({attrs:{"left":"","color":_vm.$attrs['text-color']}},'v-icon',_vm.$attrs,false),[_vm._v(" "+_vm._s(_vm.prependIcon)+" ")]):_vm._e(),_c('span',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(("" + (_vm.userName ? _vm.userName : _vm.noUserPlaceholderText) + (_vm.trailingComma ? "," : "")))+" ")]),(_vm.userEmail)?_c('span',{staticClass:"user-email"},[_vm._v(" "+_vm._s(_vm.userEmail)+" ")]):_vm._e()]),(_vm.appendIcon)?_c('v-icon',{staticClass:"flex-shrink-0",attrs:{"right":""}},[_vm._v(" "+_vm._s(_vm.appendIcon)+" ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }