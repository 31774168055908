












/* tslint:disable:no-console */

import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { VChip } from "vuetify/lib";
import { ChapterRefType } from "@auditcloud/shared/lib/schemas";

@Component
export default class AStandardChip extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  readonly chapterRef!: ChapterRefType;

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly filter!: boolean;

  get standardName() {
    return this.chapterRef.standardName;
  }
  get chapterId() {
    return this.chapterRef.chapterId;
  }

  get chapterDescription() {
    return this.$ct(this.chapterRef.chapterDescription);
  }
}
