import { mapValues, pick } from "lodash";
import { RootState, RootGetters } from "@/store/types";
import { State } from "./types";
import { MailTemplateDoc } from "@auditcloud/shared/lib/schemas";
import { Getters } from ".";
import { WatchedDocument } from "@/utils/firestore";
import { formMailLineEndings } from "./utils";

type Getter<R> = (
  state: State,
  getters: Getters,
  rootState: RootState,
  rootGetters: RootGetters
) => R;

const getTemplates: Getter<Array<WatchedDocument<null | MailTemplateDoc>>> =
  state => {
    return state.Documents;
  };

const getTemplateIds: Getter<string[]> = state => {
  return state.Documents.map(doc => doc.id);
};
const getCurrentTemplateId: Getter<string> = state => {
  return state.currentTemplateId;
};
const getCurrentTemplate: Getter<MailTemplateDoc | null> = state => {
  const template = state.Documents.find(v => v.id === state.currentTemplateId);
  if (template?.data) {
    const data = template.data;
    const { text } = data;

    return pick({ ...data, text: formMailLineEndings(text) }, [
      "html",
      "subject",
      "text",
      "type",
    ]);
  } else {
    return null;
  }
};

const getters = {
  getTemplates,
  getTemplateIds,
  getCurrentTemplateId,
  getCurrentTemplate,
};

const n = mapValues(getters, (_, key) => key);

export { n as getterNames, getters };
