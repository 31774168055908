
















































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter, Mutation } from "vuex-class";
import { stripDialog, ROUTE_NAMES } from "@/routenames";
import { formatDates } from "@auditcloud/shared/lib/utils/formatting/dates";

import AUserChip from "@/components/snippets/AUserChip.vue";
import ADateChip from "@auditcloud/components/snippets/ADateChip.vue";
import ADateSnippet from "../snippets/ADateSnippet.vue";
import ADeletedChip from "../snippets/ADeletedChip.vue";
import AUserSnippet from "../snippets/AUserSnippet.vue";

import AAuditOverviewQuestion from "@/components/widgets/AAuditOverviewQuestion.vue";
import AAuditOverviewFinding from "@/components/widgets/AAuditOverviewFinding.vue";
import AContentLanguageSwitch from "@/components/controls/AContentLanguageSwitch.vue";

import {
  api,
  Getters as MeasureGetters,
  Actions as MeasureActions,
  Mutations as MeasureMutations,
} from "@/store/modules/measure";
import { api as confApi } from "@/store/modules/configuration";
import { api as auditClassesApi } from "@/store/modules/auditClasses";

import { api as usersApi } from "@/store/modules/users";
import { api as appApi, SetTransitionPanelPayload } from "@/store/modules/app";
import { MeasureProcessDocument } from "@auditcloud/shared/lib/workflow/modules/Measure/MeasureProcessDocument";
import {
  MetaStatus,
  State as WorkflowState,
  StateConfig,
  StepType,
} from "@auditcloud/shared/lib/workflow/types/State";

import { omit, isEqual, last, clone, Dictionary, intersection } from "lodash";
import { MeasureType } from "@auditcloud/shared/lib/types/ItemTypes";

import { measureWorkflowStatusResolver } from "../../utils/status_resolver";
import { typeIsNotEmpty } from "@auditcloud/shared/lib/utils/filter/typeIsNotEmpty";
import { MeasureConfig } from "@auditcloud/shared/lib/types/Configuration/defaults";
import AAttachmentSidebar from "@/components/widgets/sidebar/AAttachmentSidebar.vue";
import AWorkflowHistorySidebar from "@/components/widgets/sidebar/AWorkflowHistorySidebar.vue";
import AMeasureParticipatingUsers from "@/components/widgets/AMeasureParticipatingUsers.vue";
import AMeasureProcessStepTransitionInfo from "@/components/controls/MeasureProcessSteps/AMeasureProcessStepTransitionInfo.vue";
import AMeasureProcessStepMetaStatusInfo from "@/components/controls/MeasureProcessSteps/AMeasureProcessStepMetaStatusInfo.vue";
import AMeasureProcessStepReset from "@/components/controls/MeasureProcessSteps/AMeasureProcessStepReset.vue";
import AMeasureProcessFreeProcessProperties from "@/components/controls/AMeasureProcessFreeProcessProperties.vue";
import AMeasureTransitionForm from "@/components/controls/AMeasureTransitionForm.vue";
import AMeasureTransitionSelectionTabs from "@/components/controls/AMeasureTransitionSelectionTabs.vue";

import AMeasureProcessTitleSuggestion from "@/components/controls/AMeasureProcessTitleSuggestion.vue";

import { MeasureTypeId } from "@auditcloud/shared/lib/constants";
import {
  AuditClassConfig,
  EmbeddedAuditItem,
  EmbeddedFinding,
  MeasureProcessStep,
  MeasureProcessStepDoc,
  MeasureTransitionData,
} from "@auditcloud/shared/lib/schemas";
import { idable } from "@auditcloud/shared/lib/types/common";
import { SystemRoles } from "@auditcloud/shared/lib/constants/roles";
import {
  calcAllMeasureRoles,
  calcMeasurePermissions,
  calcMeasureStepPermissions,
} from "@auditcloud/shared/lib/utils/aclHelpers";
import {
  TransitionConfig,
  TransitionContext,
} from "@auditcloud/shared/lib/workflow/types/Transition";
import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";
import { Operation } from "fast-json-patch";
import {
  deriveMeasureUserIdsFromRefs,
  MeasureUserRefs,
} from "@auditcloud/shared/lib/utils/aclDocumentUsers";

type PreviewStep = {
  state: idable<WorkflowState<MeasureProcessDocument>>;
  wasShownBefore: boolean;
};

@Component({
  components: {
    ADateChip,
    AUserChip,
    ADateSnippet,
    ADeletedChip,
    AUserSnippet,
    AAuditOverviewQuestion,
    AAuditOverviewFinding,
    AAttachmentSidebar,
    AWorkflowHistorySidebar,
    AMeasureProcessTitleSuggestion,
    AMeasureParticipatingUsers,
    AMeasureProcessStepTransitionInfo,
    AMeasureProcessStepMetaStatusInfo,
    AMeasureProcessFreeProcessProperties,
    AMeasureProcessStepReset,
    AMeasureTransitionForm,
    AMeasureTransitionSelectionTabs,
    AContentLanguageSwitch,
  },
})
export default class AuditMeasureDialog extends Vue {
  readonly STEP_TYPE_2_COMPONENT_MAP: { [key in StepType]: string } = {
    EMPTY: "AMeasureProcessStepControlEmpty",
    FORCE_TRANSITION: "AMeasureProcessStepControlForceTransition",
    SIMPLE_MESSAGE: "AMeasureProcessStepControlSimpleMessage",
    IMPLEMENTATION_MESSAGE: "AMeasureProcessStepControlImplementationMessage",
  };

  readonly STEP_TYPE_2_PREVIEW_COMPONENT_MAP: { [key in StepType]: string } = {
    EMPTY: "AMeasureProcessStepPreviewGeneric",
    FORCE_TRANSITION: "AMeasureProcessStepPreviewGeneric",
    SIMPLE_MESSAGE: "AMeasureProcessStepPreviewSimpleMessage",
    IMPLEMENTATION_MESSAGE: "AMeasureProcessStepPreviewImplementationMessage",
  };

  readonly TRANSITION_RESET_CONTEXT: TransitionContext = "reset";

  loading: boolean = true;
  userLoading: boolean = true;
  dueDateLoading: boolean = true;

  get settingsLoading(): boolean {
    return this.userLoading || this.dueDateLoading;
  }

  transitionLoading: boolean = false;
  found: boolean = false;
  autoScrollActive: boolean = true;

  error: boolean = false;
  errors: string[] = [];

  valid: boolean = false;
  transitionData: any = undefined;
  transitionDirty = true;

  attachmentsDialog: boolean = false;
  workflowHistoryDialog: boolean = false;
  resetTransitionConfig: idable<TransitionConfig> | null = null;

  // iOS/macOS: help Safari remember the  background scroll state (ACS-1820)
  windowScrollPosition: { top: number; left: number } | null = null;

  isLatestResetStep(resetStep: idable<MeasureProcessStep>) {
    const latestResetStep = [...this.processSteps]
      .reverse()
      .find(step => step.workflowInfo.resetStatus === "reset");
    return latestResetStep?.id === resetStep.id;
  }

  isActiveStep(stepId) {
    return this.stepStateById[stepId] === "doing";
  }

  get completedDate() {
    return this.isCompleted
      ? (this.currentStep?.docVersion as any)?.createdAt.toDate()
      : null;
  }

  get lastStepInCurrentMetaState() {
    const currentStateId = this.currentStateId;
    if (!currentStateId) return null;

    const workflow = this.measureWorkflow;
    if (!workflow) return null;

    const currentState = workflow.states.get(currentStateId);
    if (!currentState) return null;

    let metaState = this.currentMeasureMetaState;
    if (!metaState) return null;

    if (metaState === "new") {
      metaState = "wip";
    }

    const indexOfLastStepInMetaState =
      this.remainingPreviewStates.findIndex(
        state => state.metaState !== metaState
      ) ?? 0;

    return [currentState, ...this.remainingPreviewStates][
      indexOfLastStepInMetaState
    ];
  }

  get confirmationMessage(): string | null {
    if (!this.currentMeasure) {
      return null;
    }

    const isCurrentUserAssigned =
      this.$user.id() === this.currentMeasure.userRefs.assignedTo?.id;
    if (isCurrentUserAssigned) {
      const userRefsAfterChange: MeasureUserRefs = {
        ...this.currentMeasure.userRefs,
        assignedTo: null,
      };
      const measureProcessAfterChange: MeasureProcessDocument = {
        ...this.currentMeasure,
        userRefs: userRefsAfterChange,
        users: deriveMeasureUserIdsFromRefs(userRefsAfterChange),
      };

      const permissionsAfterChange = calcMeasurePermissions(
        measureProcessAfterChange,
        this.$user.id(),
        this.currentUserRoles,
        this.measureWorkflow
      );

      // as long as the user can see the measure and change the assignee,
      // they can always just re-assign themselves and do whatever they can now.
      const hasSameAccessAfterChange =
        permissionsAfterChange.read && permissionsAfterChange.changeAssignee;

      return hasSameAccessAfterChange
        ? null
        : this.$t(
            "components.dialogs.audit_measure_dialog.confirm_update_assignee"
          ).toString();
    }
    return null;
  }

  updateAssignee(assignee: IUserRef | null) {
    this.userLoading = true;
    this.patchMeasure({
      op: "replace",
      value: assignee,
      path: "/userRefs/assignedTo",
    }).finally(() => {
      this.userLoading = false;
    });
  }
  updateDueDate(dueDate: string) {
    this.dueDateLoading = true;
    this.patchMeasure({
      op: "replace",
      value: dueDate,
      path: "/dueDate",
    }).finally(() => {
      this.dueDateLoading = false;
    });
  }

  async patchMeasure(op: Operation) {
    try {
      await this.updateMeasure({
        patch: [op],
      });
      console.log("patch done");
    } catch (err) {
      this.handleApiError(err);
    }
  }

  get isDueDateEditable() {
    return this.permissions.changeDueDate && !this.loading;
  }
  get isAssigneeEditable() {
    return this.permissions.changeAssignee && !this.loading;
  }

  get editingDisabledStep() {
    return this.loading || !this.permissions.write || this.isCompleted;
  }

  get stepPermissions(): Dictionary<Record<"read" | "write", boolean>> {
    const result = {};
    if (!this.measureWorkflow) {
      return result;
    }

    const measureRoles = calcAllMeasureRoles(
      this.currentMeasure,
      this.$user.id(),
      this.currentUserRoles
    );
    this.processSteps.forEach(step => {
      const stepPermissions = calcMeasureStepPermissions(
        step,
        this.measureWorkflow,
        this.permissions,
        measureRoles
      );
      result[step.id] = stepPermissions;
    });
    return result;
  }

  transitionDataChange($event) {
    this.transitionData = $event;
  }

  get currentStateId() {
    return this.currentMeasure?.workflow.statusId;
  }

  get currentStep(): idable<MeasureProcessStep> | null {
    const current = this.processSteps.find(
      step => step.workflowInfo.leftAt == null
    );
    return current ?? last(this.processSteps) ?? null;
  }

  get measureType(): MeasureType {
    const measureType = this.measureTypes.find(
      mt => mt.id === this.measureTypeId
    );

    if (measureType) {
      return measureType;
    } else {
      return {
        id: "__UNKNOWN__MEASURE_TYPE",
        icon: "error",
        text: "Unknown",
        short: "U",
        description: "Unknown",
        color: "red",
      };
    }
  }

  get breadcrumbs() {
    const auditPart = {
      name: this.currentMeasure?.auditRef.name ?? "",
      to: this.isAuditAccessible
        ? {
            name: ROUTE_NAMES.AUDIT,
            params: { auditId: this.currentMeasure?.auditRef.id ?? "" },
          }
        : null,
      activeclass: "",
    };

    const measurePart = {
      name: this.measureId,
      type: this.measureAccessDenied
        ? this.$t("components.dialogs.audit_measure_dialog.measure_label")
        : this.$ft(this.measureType.text),
      to: this.measureAccessDenied
        ? null
        : {
            name: ROUTE_NAMES.MEASUREDIALOG,
            params: { measureId: this.measureId },
          },
      activeclass: "",
    };

    if (this.currentMeasure && this.$vuetify.breakpoint.mdAndUp) {
      return [auditPart, measurePart];
    } else {
      return [measurePart];
    }
  }

  get assignedToQueryAction() {
    return this.preventRefUserAssignees
      ? {
          name: usersApi.actions.queryLoginUser,
          namespace: usersApi.namespace,
        }
      : {
          name: usersApi.actions.queryUser,
          namespace: usersApi.namespace,
        };
  }

  @Prop({
    required: true,
    type: String,
  })
  measureId!: string;

  @Getter(api.getters.getIsAuditAccessible, {
    namespace: api.namespace,
  })
  isAuditAccessible!: MeasureGetters["getIsAuditAccessible"];

  @Getter(api.getters.getCurrentMeasure, {
    namespace: api.namespace,
  })
  currentMeasure!: MeasureGetters["getCurrentMeasure"];

  @Getter(api.getters.getProcessSteps, {
    namespace: api.namespace,
  })
  processSteps!: MeasureGetters["getProcessSteps"];

  @Getter(api.getters.getPermissions, {
    namespace: api.namespace,
  })
  permissions!: MeasureGetters["getPermissions"];

  @Getter(api.getters.getAttachmentsCount, {
    namespace: api.namespace,
  })
  attachmentsCount!: MeasureGetters["getAttachmentsCount"];

  @Getter(api.getters.getCurrentStepIsDirty, {
    namespace: api.namespace,
  })
  currentStepIsDirty!: MeasureGetters["getCurrentStepIsDirty"];

  @Getter(api.getters.getCurrentUserRoles, {
    namespace: api.namespace,
  })
  currentUserRoles!: MeasureGetters["getCurrentUserRoles"];

  @Getter(api.getters.getCurrentMeasureRoles, {
    namespace: api.namespace,
  })
  currentMeasureRoles!: MeasureGetters["getCurrentMeasureRoles"];

  @Getter(api.getters.getAttachmentsByStepId, {
    namespace: api.namespace,
  })
  attachmentsByStepId!: MeasureGetters["getAttachmentsByStepId"];

  @Getter(api.getters.getPossibleTransitions, {
    namespace: api.namespace,
  })
  transitions!: MeasureGetters["getPossibleTransitions"];

  @Getter(api.getters.getWorkflow, {
    namespace: api.namespace,
  })
  measureWorkflow!: MeasureGetters["getWorkflow"];

  @Getter(api.getters.getIsDeleted, {
    namespace: api.namespace,
  })
  isDeleted!: MeasureGetters["getIsDeleted"];

  @Getter(confApi.getters.measure, {
    namespace: confApi.namespace,
  })
  currentConfig!: MeasureConfig;

  @Getter(api.getters.getAuditClassConfig, {
    namespace: api.namespace,
  })
  auditClassConfig!: MeasureGetters["getAuditClassConfig"];

  @Getter(api.getters.getPreventRefUserAssignees, {
    namespace: api.namespace,
  })
  preventRefUserAssignees!: MeasureGetters["getPreventRefUserAssignees"];

  @Action(api.actions.setMeasure, { namespace: api.namespace })
  setCurrentMeasure!: MeasureActions["setMeasure"];

  @Mutation(api.mutations.CLEAR_MEASURE, { namespace: api.namespace })
  clearCurrentMeasure!: MeasureMutations["CLEAR_MEASURE"];

  @Action(api.actions.runTransition, { namespace: api.namespace })
  runTransition!: MeasureActions["runTransition"];

  @State("apiErrors", { namespace: api.namespace })
  apiErrors!: string[];

  @State("measureAccessDenied", { namespace: api.namespace })
  measureAccessDenied!: boolean;

  @Action(api.actions.updateMeasure, { namespace: api.namespace })
  updateMeasure!: MeasureActions["updateMeasure"];

  get workflowId() {
    return this.currentMeasure?.workflow.workflowId ?? "";
  }

  get remainingPreviewSteps(): PreviewStep[] {
    const firstStateId = this.processSteps?.length
      ? this.processSteps?.[0].workflowInfo.state.id
      : null;
    const currentStateId = this.currentStateId;
    if (firstStateId == null || currentStateId == null) {
      return [];
    }

    const preview = this.measureWorkflow?.previews.find(
      ({ steps }) => steps[0].stateId === firstStateId
    );
    if (!preview) {
      console.warn("no preview steps for first state=", firstStateId);
      return [];
    }

    const currentStepIndex = preview.steps.findIndex(
      previewStep => previewStep.stateId === currentStateId
    );
    if (currentStepIndex === -1) {
      console.warn("no preview steps from current state=", currentStateId);
      return [];
    }

    const completedStateIds: Set<string> = new Set();
    this.processSteps.forEach(step => {
      completedStateIds.add(step.workflowInfo.state.id);
    });
    return preview.steps
      .slice(currentStepIndex + 1)
      .map((previewStep): null | PreviewStep => {
        const stateId = previewStep.stateId;
        const state = this.measureWorkflow?.states.get(stateId);
        if (state) {
          const wasShownBefore = completedStateIds.has(stateId);
          return { state: { ...state, id: stateId }, wasShownBefore };
        } else {
          console.warn("Bad preview step configuration: ", { stateId, state });
          return null;
        }
      })
      .filter(typeIsNotEmpty);
  }

  get remainingPreviewStates() {
    return this.remainingPreviewSteps.map(step => step.state);
  }

  get statusResolver() {
    return measureWorkflowStatusResolver(this.workflowId);
  }

  get currentStateConfig(): StateConfig | null {
    const statusId = this.currentMeasure?.workflow.statusId;
    if (statusId == null) {
      return null;
    }
    return this.measureWorkflow?.states.get(statusId) ?? null;
  }

  get currentStatusLabel() {
    return this.$ft(this.currentStateConfig?.name ?? "Invalid Status");
  }

  get currentMeasureMetaState(): StateConfig["metaState"] | null {
    return this.currentStateConfig?.metaState ?? null;
  }

  get isCompleted() {
    return this.currentMeasureMetaState === "complete";
  }

  get statusId() {
    const statusId = this.currentMeasure?.workflow.statusId ?? "";
    return statusId;
  }

  set statusId(id: string) {
    if (this.currentMeasure && this.currentMeasure.workflow) {
      this.currentMeasure.workflow.statusId = id;
    }
  }

  get hideRequirementText(): boolean {
    const hideRequirementsFromNonAuditors =
      this.auditClassConfig?.hideRequirementsFromNonAuditors ?? true;
    return (
      hideRequirementsFromNonAuditors &&
      intersection(this.currentUserRoles, [
        SystemRoles.AUDITOR,
        SystemRoles.GLOBAL_READER,
        SystemRoles.ADMINISTRATOR,
        SystemRoles.QUALITYMANAGER,
        SystemRoles.SYSTEM_ADMINISTRATOR,
      ]).length === 0
    );
  }

  get embeddedAuditItem(): EmbeddedAuditItem | null {
    if (this.currentMeasure?.auditItem) {
      const ai = this.currentMeasure.auditItem;
      return {
        ...ai,
        question: {
          ...ai.question,
          hint: ai.question.hint || "",
        },
      };
    } else {
      return null;
    }
  }

  get embeddedFinding(): EmbeddedFinding | null {
    if (this.currentMeasure?.finding) {
      const f = this.currentMeasure.finding;
      return { ...f, attachments: f.attachments || {} };
    } else {
      return null;
    }
  }

  get measureTypeId(): string {
    const id =
      this.currentMeasure?.additionalWorkflowMetadata.type ??
      MeasureTypeId.Corrective;
    console.log("measureTypeId:get", id);
    return id;
  }

  get measureTypes(): MeasureType[] {
    return Object.values(this.currentConfig.data.types);
  }

  @Action(appApi.actions.setTransitionPanel, { namespace: appApi.namespace })
  setTransitionPanel!: (payload: SetTransitionPanelPayload) => Promise<void>;

  get stepStateById(): Dictionary<"doing" | "done" | "failed"> {
    const result = {};
    this.processSteps.forEach((step, index) => {
      const nextStep = this.processSteps[index + 1];
      if (nextStep) {
        const transitionId = nextStep.workflowInfo.transitionId;
        const transitionConfig = transitionId
          ? this.measureWorkflow?.transitions.get(transitionId)
          : null;
        result[step.id] = transitionConfig?.back ? "failed" : "done";
      } else {
        result[step.id] = "doing";
      }
    });
    return result;
  }

  get stepMetaStateById(): Dictionary<MetaStatus> {
    const result = {};
    this.processSteps.forEach((step, index) => {
      result[step.id] = this.measureWorkflow?.states.get(
        step.workflowInfo.state.id
      )?.metaState;
    });
    return result;
  }

  mounted() {
    this.windowScrollPosition = { top: window.scrollY, left: window.scrollX };
    document.documentElement.style.overflow = "hidden";

    console.log(this.$route);
  }

  updated() {
    document.documentElement.style.overflow = "hidden";
  }

  beforeDestroy() {
    setTimeout(() => {
      if (this.windowScrollPosition !== null) {
        window.scrollTo(this.windowScrollPosition);
      }
    });
    this.autoScrollActive = false;
    document.documentElement.style.overflow = "visible";
    this.clearCurrentMeasure();
  }

  @Watch("currentStep")
  handleStepUpdate(
    step: idable<MeasureProcessStep> | null,
    previousStep: idable<MeasureProcessStep> | null
  ) {
    if (step === null || step.id === previousStep?.id) {
      return;
    }
    // wait for a moment to avoid jumps, as `currentStep` may still be updating
    setTimeout(() => {
      if (!this.autoScrollActive) {
        return;
      }
      if (this.currentMeasure?.workflow.metaState === "complete") {
        this.scrollTo(last(this.processSteps) ?? null);
      } else {
        this.scrollTo(this.currentStep);
      }
    }, 200);
  }

  scrollTo(step: idable<MeasureProcessStep> | null) {
    if (step == null) {
      return;
    }
    const elem = document.getElementById(step.id);
    if (elem) {
      elem.scrollIntoView(true);
    }
  }

  @Watch("measureId", { immediate: true, deep: false })
  async onMeasureIdChanged(measureId: string, measureIdOld: string) {
    if (measureId !== measureIdOld) {
      this.loading = true;

      this.setCurrentMeasure({ measureId });
    }
  }

  @Watch("currentMeasure", { immediate: true, deep: true })
  onCurrentMeasureChanged(
    newVal: null | MeasureProcessDocument,
    oldVal: null | MeasureProcessDocument
  ) {
    if (!isEqual(newVal, oldVal)) {
      if (newVal != null) {
        this.loading = false;
        this.userLoading = false;
        this.dueDateLoading = false;
      }
      this.transitionLoading = false;
    }
  }

  closeDialog() {
    console.log("closeDialog", this.$route);
    const route = this.$route;

    const stripedRouteName = stripDialog(route.name ?? "");
    const params = omit(route.params, [
      "action",
      "findingId",
      "auditItemId",
      "measureId",
    ]);

    if (route.name === stripedRouteName) {
      const matchCount = route.matched.length;
      const parentRoute = route.matched[matchCount - 1].parent;
      if (parentRoute) {
        const newLocation = {
          name: parentRoute.name,
          params,
          query: route.query,
        };
        console.log(newLocation, parentRoute);
        this.$router.push(newLocation);
      } else {
        this.$router.back();
      }
    } else {
      const newLocation = {
        name: stripedRouteName,
        params,
        query: route.query,
      };
      this.$router.push(newLocation);
    }
  }

  handleApiError(reason: any) {
    if (this.apiErrors.length > 0) {
      this.errors = this.apiErrors;
    } else {
      this.errors = [String(reason)];
    }

    console.log("ERROR", this.apiErrors);

    this.error = true;
    this.loading = false;
  }

  resetDialog: boolean = false;

  get allowResetTransitions(): boolean {
    return this.allowedResetTransitions.length > 0;
  }

  /** All reset transitions (needed to mark the resetted steps) */
  get allResetTransitionIds(): string[] {
    if (!this.measureWorkflow?.transitions) {
      return [];
    }

    return Array.from(this.measureWorkflow.transitions)
      .filter(([key, value]) =>
        value.showInContexts.includes(this.TRANSITION_RESET_CONTEXT)
      )
      .map(([id]) => id);
  }

  /** All canceling transitions (needed to mark the canceled steps) */
  get allCancelingTransitionIds(): string[] {
    if (!this.measureWorkflow?.transitions) {
      return [];
    }

    return Array.from(this.measureWorkflow.transitions)
      .filter(([key, value]) => value.cancelsStep)
      .map(([id]) => id);
  }

  /** Reset transitions that are allowed to performed by the user */
  get allowedResetTransitions(): idable<TransitionConfig>[] {
    return (
      this.transitions?.filter(
        transition =>
          transition.showInContexts.includes(this.TRANSITION_RESET_CONTEXT) &&
          this.processSteps.find(v => v.workflowInfo.state.id === transition.to)
      ) ?? []
    );
  }

  /** Canceling transitions that are allowed to performed by the user */
  get allowedCancelingTransitions(): idable<TransitionConfig>[] {
    return (
      this.transitions?.filter(
        transition =>
          transition.cancelsStep &&
          this.processSteps.find(v => v.workflowInfo.state.id === transition.to)
      ) ?? []
    );
  }

  transitionComment: string = "";

  isResetStep(nextStep: idable<MeasureProcessStepDoc> | undefined): boolean {
    const transitionId = nextStep?.workflowInfo?.transitionId;
    return transitionId
      ? !!this.allResetTransitionIds.find(v => v === transitionId)
      : false;
  }

  isCanceledStep(nextStep: idable<MeasureProcessStepDoc> | undefined): boolean {
    const transitionId = nextStep?.workflowInfo?.transitionId;
    return transitionId
      ? !!this.allCancelingTransitionIds.find(v => v === transitionId)
      : false;
  }

  resetCurrentMeasureProcess(
    transition: idable<TransitionConfig> | null
  ): void {
    const measureId = this.measureId;

    if (!transition || !transition.isReset) {
      console.error("no reset transition");
      return;
    }
    const transactionData: MeasureTransitionData = {
      assignedTo: null,
      dueDate: null,
      transitionComment: this.transitionComment,
    };

    // find most recent assignee for reset state
    this.processSteps.forEach(step => {
      if (step.workflowInfo.state.id === transition.to) {
        transactionData.assignedTo = step.workflowInfo.assigneeRef ?? null;
      }
    });

    this.runTransition({
      measureId,
      transactionData,
      transitionId: transition.id,
    });

    this.transitionComment = "";
    this.resetDialog = false;
  }

  getStateNameById(id: string): string {
    const state = this.measureWorkflow?.states.get(id);
    return state ? this.$ft(state.name) : "Invalid State";
  }

  formatDates(value: string | string[]) {
    return formatDates(value, null);
  }
}
