





























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ADeletedChip extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  isDeleted!: boolean;
}
