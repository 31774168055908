




































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ASignatureEditor from "@/components/widgets/ASignatureEditor.vue";
import {
  RequiredSignatures,
  UserSignature,
} from "@auditcloud/shared/lib/schemas";
import { api } from "@/store/modules/audit";
import { namespace } from "vuex-class";
import { AuditSignatureRoles } from "@auditcloud/shared/lib/types/common";

const auditModule = namespace(api.namespace);
@Component({
  components: {
    ASignatureEditor,
  },
})
export default class ARequiredSignatures extends Vue {
  header: Object[] = [
    {
      text: this.$t("components.dialogs.audit_reporting_dialog.sign_role"),
      value: "role",
      sortable: false,
      width: "10%",
    },
    {
      text: this.$t("components.dialogs.audit_reporting_dialog.name"),
      value: "userRef.displayName",
      sortable: false,
      width: "25%",
    },
    {
      text: this.$t("components.dialogs.audit_reporting_dialog.time"),
      value: "timestamp",
      sortable: false,
      width: "15%",
    },
    {
      text: this.$t("components.dialogs.audit_reporting_dialog.dialog_sign"),
      value: "signature",
      sortable: false,
      width: "40%",
    },
    {
      text: this.$t("components.dialogs.audit_reporting_dialog.actions"),
      value: "actions",
      sortable: false,
      width: "20%",
      align: "end",
    },
  ];
  signatureEditordialog: boolean = false;
  userRoles: Object[] = [
    {
      text: this.$t("components.dialogs.audit_reporting_dialog.auditor"),
      value: AuditSignatureRoles.AUDITOR,
    },
    {
      text: this.$t("components.dialogs.audit_reporting_dialog.auditor_co"),
      value: AuditSignatureRoles.AUDITOR_CO,
    },
    {
      text: this.$t("components.dialogs.audit_reporting_dialog.audited_person"),
      value: AuditSignatureRoles.AUDITED_PERSON,
    },
    {
      text: this.$t("components.dialogs.audit_reporting_dialog.attendees"),
      value: AuditSignatureRoles.ATTENDEE,
    },
  ];
  signatures: UserSignature[] = [];
  currentSignature: UserSignature = {
    id: "",
    role: "",
    userRef: null,
    timestamp: "",
    signature: "",
    isSaved: false,
  };

  edit: Boolean = false;
  countRequiredSigns = 0;
  countSignedRequiredSigns = 0;
  requiredSignaturesRoles: Array<any> = [];
  copyOfRequiredSignaturesRoles: Array<any> = [];

  @Prop({
    type: Object,
    default: () => {},
  })
  value!: RequiredSignatures;

  @Prop({
    type: Array,
    default: () => [],
  })
  requiredRoles!: any;

  // To Do ACS-1526
  // Participants, should be a Property, which gets the current audit_participants dynamically from the ui.json.
  // Unfortunately this Task is not available jet, without passing all ui.json Properties as one big Object.
  /*
  @Prop({
    type: Array,
    default: () => [null, null]
  })
  participants!: (UserRef | null)[];
  */

  mounted() {
    for (var idx = 0; idx < this.requiredRoles.length; idx++) {
      this.countRequiredSigns += this.requiredRoles[idx].count;
    }
    this.requiredSignaturesRoles = this.requiredRoles;
    if (this.value.signatures) {
      this.signatures = this.value.signatures;
    }
    if (!this.checkRequiredRoles()) {
      this.$emit("input", undefined);
    }
  }

  signatureAdded(newVal: UserSignature) {
    this.signatures.push(newVal);
    Vue.set(this.signatures[this.signatures.length - 1], "isSaved", true);
    if (!this.requiredRoles) {
      this.$emit("input", {
        signatures: this.signatures,
      });
    } else {
      if (this.checkRequiredRoles()) {
        this.$emit("input", {
          signatures: this.signatures,
        });
        return;
      }
      this.$emit("input", undefined);
    }
  }

  signatureRemoved(newVal: UserSignature) {
    for (var idx = 0; idx < this.signatures.length; idx++) {
      if (this.signatures[idx] === newVal) {
        this.signatures.splice(idx, 1);
        if (this.checkRequiredRoles()) {
          this.$emit("input", {
            signatures: this.signatures,
          });
          return;
        }
        break;
      }
    }
    this.$emit("input", undefined);
  }

  signatureEdit(newVal: UserSignature) {
    for (var idx = 0; idx < this.signatures.length; idx++) {
      if (this.signatures[idx].id === newVal.id) {
        this.$set(this.signatures, idx, newVal);
        if (this.checkRequiredRoles()) {
          this.$emit("input", {
            signatures: this.signatures,
          });
          return;
        }
        break;
      }
    }

    this.$emit("input", undefined);
  }

  openSignatureEditorDialog(
    newVal: UserSignature = {
      id: "",
      role: "",
      userRef: null,
      timestamp: "",
      signature: "",
      isSaved: false,
    }
  ) {
    this.edit = newVal.id !== "";
    this.currentSignature = newVal;
    this.signatureEditordialog = true;
  }

  checkRequiredRoles(): boolean {
    if (this.requiredSignaturesRoles.length === 0) {
      return true;
    }

    this.countSignedRequiredSigns = 0;
    for (var idx3 = 0; idx3 < this.requiredSignaturesRoles.length; idx3++) {
      this.requiredSignaturesRoles[idx3] = this.requiredRoles[idx3];
    }

    for (var idx = 0; idx < this.signatures.length; idx++) {
      for (var idx2 = 0; idx2 < this.requiredSignaturesRoles.length; idx2++) {
        if (
          this.signatures[idx].role ===
            this.requiredSignaturesRoles[idx2].role &&
          this.countSignedRequiredSigns < this.countRequiredSigns &&
          this.requiredSignaturesRoles[idx2].count > 0
        ) {
          this.countSignedRequiredSigns++;
          this.requiredSignaturesRoles[idx2].count--;
        }
      }
    }
    this.remaningRoles();
    if (this.countSignedRequiredSigns === this.countRequiredSigns) {
      return true;
    }
    return false;
  }

  getRoleText(newVal: string) {
    switch (newVal) {
      case AuditSignatureRoles.AUDITOR:
        return this.$t(
          "components.dialogs.audit_reporting_dialog.auditor"
        ).toString();
      case AuditSignatureRoles.AUDITOR_CO:
        return this.$t(
          "components.dialogs.audit_reporting_dialog.auditor_co"
        ).toString();
      case AuditSignatureRoles.AUDITED_PERSON:
        return this.$t(
          "components.dialogs.audit_reporting_dialog.audited_person"
        ).toString();
      default:
        return this.$t(
          "components.dialogs.audit_reporting_dialog.attendees"
        ).toString();
    }
  }
  get isEdit() {
    return this.edit;
  }
  get localSignatures() {
    return this.signatures;
  }
  get localCountSignedRequiredSigns() {
    return this.countSignedRequiredSigns;
  }

  remaningRoles() {
    var erg = "";
    for (var idx = 0; idx < this.requiredSignaturesRoles.length; idx++) {
      if (
        this.requiredSignaturesRoles[idx].count > 0 &&
        idx !== this.requiredSignaturesRoles.length - 1
      ) {
        erg +=
          this.getRoleText(this.requiredSignaturesRoles[idx].role) +
          ": " +
          this.requiredRoles[idx].count +
          ", ";
      } else {
        erg +=
          this.getRoleText(this.requiredSignaturesRoles[idx].role) +
          ": " +
          this.requiredRoles[idx].count;
      }
    }
    return erg;
  }
  $refs!: {
    myCanvas: HTMLCanvasElement;
  };
}
