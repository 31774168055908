import { Dictionary, keyBy } from "lodash";
import { KNOWN_SCHEMAS } from "@auditcloud/shared/lib/schemas/validator";

const requireSchemas = require.context(".", true, /^\.\/\S+\.schema\.json$/);
const allSchemas = [
  ...KNOWN_SCHEMAS,
  ...requireSchemas.keys().map(fileName => {
    return requireSchemas(fileName);
  }),
];
export const schemas: Dictionary<any> = keyBy(allSchemas, "$id");
