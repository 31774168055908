import { ActionsType, GettersType } from "@/utils/VuexHelper";
import { Module } from "vuex";
import { RootState } from "../../types";
import { actionNames, actions } from "./actions";
import { getterNames, getters } from "./getters";
import { GtmState } from "./types";

const state = new GtmState();

const namespaced: boolean = true;
const modul: Module<GtmState, RootState> = {
  namespaced,
  actions,
  getters,
  state,
};

export const api = {
  namespace: "gtm",
  getters: getterNames,
  actions: actionNames,
};
export type Getters = GettersType<typeof getters>;
export type Actions = ActionsType<typeof actions>;

export default modul;
