






import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { AuditMetadataClient } from "@/types/Audit";
import { formatAuditName } from "@auditcloud/shared/lib/utils/formatting/audits";

@Component({})
export default class AAuditNameDisplay extends Vue {
  @Prop()
  audit!: AuditMetadataClient;

  get calculatedName(): string {
    return formatAuditName(this.audit);
  }
}
