





































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { VChip, VIcon } from "vuetify/lib";
import AAttachmentDialog from "@/components/dialogs/AAttachmentDialog.vue";
import { StoredAttachmentEntryMap } from "@auditcloud/shared/lib/types/Attachments";

@Component({
  components: { VChip, VIcon, AAttachmentDialog },
})
export default class AAttachmentList extends Vue {
  xActiveAttachment: string = "";

  get activeAttachment(): string {
    return this.xActiveAttachment;
  }
  set activeAttachment(val: string) {
    this.xActiveAttachment = val;
  }

  get url(): string {
    return this.attachments[this.activeAttachment].url ?? "";
  }

  get name(): string {
    return this.attachments[this.activeAttachment].name ?? "";
  }

  get icon(): string {
    return this.attachments[this.activeAttachment].icon ?? "";
  }

  get dialog() {
    return (
      Object.keys(this.attachments).length >= 0 &&
      this.attachments[this.activeAttachment] instanceof Object
    );
  }
  set dialog(value: boolean) {
    if (!value) {
      this.activeAttachment = "";
    }
  }

  get isImage(): boolean {
    return this.attachments[this.activeAttachment].type.startsWith("image/");
  }

  get isExternalSource(): boolean {
    return this.attachments[this.activeAttachment].source === "portal";
  }

  @Prop({
    required: true,
    type: Object,
  })
  readonly attachments!: StoredAttachmentEntryMap;

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly deletable!: boolean;

  @Prop({
    required: false,
    default: true,
    type: Boolean,
  })
  readonly isMarkerJsEnabled!: boolean;

  removeAttachment(attachmentId: string) {
    this.$emit("remove", attachmentId);
  }
}
