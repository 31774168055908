

































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { api } from "@/store/modules/app";
import { State } from "vuex-class";

@Component({
  components: {},
})
export default class AActionBar extends Vue {
  @State(api.state.drawerVisible, { namespace: api.namespace })
  drawerVisible!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly show!: boolean;
  @Prop({ type: Boolean, default: false })
  readonly detached!: boolean;
}
