import axios, { AxiosResponse } from "axios";
import {
  measureEndpointUrl,
  measuresEndpointUrl,
  measureStepEndpointUrl,
  auditsEndpointUrl,
} from "../HttpApi";
import {
  ApiV0MeasuresRemoveRequest,
  ApiV0MeasuresTransitionRequest,
  MeasureTransitionData,
  ApiV0MeasureTransitionRequest,
  ApiV0MeasureTransitionResponse,
  ApiV0MeasuresTransitionResponse,
  ApiV0MeasureStepContextPatchRequest,
  ApiV0MeasureStepContextPatchResponse,
  MeasureTransitionDataRelaxed,
  ApiV0CreateMeasureProcessesForAuditRequest,
  ApiV0CreateMeasureProcessesForAuditResponse,
} from "@auditcloud/shared/lib/schemas";
import { Operation } from "fast-json-patch";
import { typeIsApiV0CreateMeasureProcessesForAuditResponse } from "@auditcloud/shared/lib/schemas/type-guards";
import {
  RestApiError,
  typeIsRestApiError,
} from "@auditcloud/shared/lib/async-handling";
import {
  TypeGuard,
  TypeGuardWithErrors,
} from "@auditcloud/shared/lib/types/common";
import {
  assertTypeIsAxiosError,
  assertTypeIsAxiosResponseOf,
} from "@/types/TypeGuards";

/**
 * Run a transition for one measure
 */
export async function runTransition(
  transitionId: string,
  measureId: string,
  transitionData: MeasureTransitionData
) {
  const url = measureEndpointUrl(measureId, "transition", transitionId, "run");

  const payload: ApiV0MeasureTransitionRequest = transitionData;
  const res = (await axios.post(url, payload))
    .data as ApiV0MeasureTransitionResponse;
  return res;
}

/**
 * Run a transition for multiple measure ids
 *
 * @param transitionId The transition to run
 * @param measureIds The measures to transition
 * @param transitionData The data to feed the transition with
 */
export async function runBulkTransition(
  transitionId: string,
  measureIds: string[],
  transitionData: MeasureTransitionDataRelaxed
) {
  const url = measuresEndpointUrl("transitions", transitionId, "run");

  const payload: ApiV0MeasuresTransitionRequest = {
    measureIds,
    payload: transitionData,
  };
  const res = (await axios.post(url, payload))
    .data as ApiV0MeasuresTransitionResponse;
  return res;
}

/**
 * Delete the measure with the specified id
 *
 * @param measureId Id of the measure to be deleted
 */
export async function deleteMeasure(measureId: string) {
  const url = measureEndpointUrl(measureId);

  const res = await axios.delete(url, {
    responseType: "json",
  });
  return res.data;
}

/**
 * Delete the measures with the specified ids
 *
 * @param measureIds Id of the measures to be deleted
 */
export async function deleteMeasures(measureIds: string[]) {
  const url = measuresEndpointUrl("remove");
  const payload: ApiV0MeasuresRemoveRequest = { measures: measureIds };

  const res = await axios.post(url, payload, {
    responseType: "json",
  });
  return res.data;
}

export async function patchStepContext(
  { measureId, measureStepId }: { measureId: string; measureStepId: string },
  stepContextPatch: Operation[]
): Promise<ApiV0MeasureStepContextPatchResponse> {
  const url = measureStepEndpointUrl(measureId, measureStepId);
  const patch =
    stepContextPatch as unknown as ApiV0MeasureStepContextPatchRequest;

  const res = await axios.patch(
    url,
    { patch },
    {
      responseType: "json",
    }
  );
  return res.data;
}

async function postRq<RQ, RS>(
  url: string,
  payload: RQ,
  tg: TypeGuard<RS> | TypeGuardWithErrors<RS>
): Promise<AxiosResponse<RS | RestApiError>> {
  const response = await axios
    .post<unknown>(url, payload)
    .then(res => {
      assertTypeIsAxiosResponseOf(res, tg);
      return res;
    })
    .catch(error => {
      assertTypeIsAxiosError(error);
      const res = error.response;
      if (!res) {
        throw error;
      }
      assertTypeIsAxiosResponseOf(res, typeIsRestApiError);
      return res;
    });

  return response;
}

export async function createMeasures(
  auditId: string,
  payload: ApiV0CreateMeasureProcessesForAuditRequest
): Promise<ApiV0CreateMeasureProcessesForAuditResponse> {
  const url = auditsEndpointUrl(auditId, "measure-processes");
  const { data, status } = await postRq(
    url,
    payload,
    typeIsApiV0CreateMeasureProcessesForAuditResponse
  );

  if (typeIsRestApiError(data)) {
    throw new Error(JSON.stringify(data));
  } else {
    return data;
  }
}
