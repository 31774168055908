





























































































































































































import AQuestionContent from "@/components/controls/AQuestionContent.vue";
import AAuditItemNoteDialog from "@/components/dialogs/AAuditItemNoteDialog.vue";
import AAuditItemTagsDialog from "@/components/dialogs/AAuditItemTagsDialog.vue";
import AChip from "@/components/snippets/AChip.vue";
import ASnippetCategory from "@/components/snippets/ASnippetCategory.vue";
import ASnippetCategoryRef from "@/components/snippets/ASnippetCategoryRef.vue";
import AStandardChip from "@/components/snippets/AStandardChip.vue";
import AWeblink from "@/components/snippets/AWeblink.vue";
import { api } from "@/store/modules/audit";
import { AnswerStatus, AuditItemProperties } from "@/store/modules/audit/types";
import { AuditItemWithId } from "@auditcloud/shared/lib/utils/audit/types";
import { api as configApi } from "@/store/modules/configuration";
import ATableItem from "@auditcloud/components/widgets/ATableItem.vue";
import {
  AuditProof,
  CategoryLevel,
  ChapterRefType,
  EmbeddedAuditItem,
  TranslateableText,
  WebLink,
} from "@auditcloud/shared/lib/schemas";
import { QuestionSearchResult } from "@auditcloud/shared/lib/types/Audit/types";
import { AuditItemCategory } from "@auditcloud/shared/lib/types/AuditItemCategory";
import {
  AuditItemTypeMap,
  ValuedItemType,
} from "@auditcloud/shared/lib/types/ItemTypes";
import { sortable_chapter } from "@auditcloud/shared/lib/utils/filter/AuditItemListManipulator";
import * as IDS from "@auditcloud/shared/lib/utils/filter/AuditItemListManipulatorIds";
import { Filter } from "@auditcloud/shared/lib/utils/filter/types";
import { typeIsDictionary } from "@auditcloud/shared/lib/utils/type-guards";
import { isArray, isString } from "lodash";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { VBtn, VChip, VIcon } from "vuetify/lib";
import { Action, Getter, Mutation } from "vuex-class";

@Component({
  components: {
    ATableItem,
    VBtn,
    VIcon,
    VChip,
    AChip,
    AStandardChip,
    AWeblink,
    ASnippetCategoryRef,
    ASnippetCategory,
    AQuestionContent,
    AAuditItemNoteDialog,
    AAuditItemTagsDialog,
  },
})
export default class AAuditOverviewQuestion extends Vue {
  currentToggle: string = "";
  isEditingTags: boolean = false;
  isEditingNote: boolean = false;

  openEditTagDialog(auditItemIds: Array<AuditItemWithId["id"]>) {
    this.isEditingTags = true;
    this.selectedAuditItemIds = auditItemIds;
  }
  openEditNoteDialog(auditItemIds: Array<AuditItemWithId["id"]>) {
    this.isEditingNote = true;
    this.selectedAuditItemIds = auditItemIds;
  }

  @Action(api.actions.manuallyAddAuditItemsToSelfAssessment, {
    namespace: api.namespace,
  })
  manuallyAddAuditItemsToSelfAssessment!: (
    ids: Array<AuditItemWithId["id"]>
  ) => Promise<void>;

  @Action(api.actions.manuallyRemoveAuditItemsFromSelfAssessment, {
    namespace: api.namespace,
  })
  manuallyRemoveAuditItemsFromSelfAssessment!: (
    ids: Array<AuditItemWithId["id"]>
  ) => Promise<void>;

  @Getter(api.getters.getAuditItemProperties, { namespace: api.namespace })
  auditItemPropertiesMap!: Map<AuditItemWithId["id"], AuditItemProperties>;

  @Getter(api.getters.getIsAuditItemNoteVisible, { namespace: api.namespace })
  isAuditItemNoteVisible!: boolean;

  get auditItemProperties() {
    return this.auditItemPropertiesMap.get(this.auditItemId);
  }

  get isConsidered() {
    return this.auditItemProperties?.isConsidered ?? false;
  }

  get isSelfAssessmentEnabled() {
    return this.auditItemProperties?.isSelfAssessmentEnabled ?? false;
  }

  selectedAuditItemIds: Array<AuditItemWithId["id"]> = [];

  get auditItemActions(): {
    name: TranslateableText;
    handler: (ids: Array<AuditItemWithId["id"]>) => void | Promise<void>;
  }[] {
    const actions = [
      {
        name: this.$ft("views.audit_preparation.edit_tags"),
        handler: this.openEditTagDialog,
      },
    ];
    if (this.isAuditItemNoteVisible) {
      actions.push({
        name: this.$ft("views.audit_preparation.edit_notes"),
        handler: this.openEditNoteDialog,
      });
    }
    if (this.selfAssessmentIncluded) {
      actions.push({
        name: this.isSelfAssessmentEnabled
          ? this.$ft("views.audit_preparation.exclude_from_self_assessment")
          : this.$ft("views.audit_preparation.include_in_self_assessment"),
        handler: this.isSelfAssessmentEnabled
          ? this.manuallyRemoveAuditItemsFromSelfAssessment
          : this.manuallyAddAuditItemsToSelfAssessment,
      });
    }
    return actions;
  }

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly readonly!: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly hideRequirementText!: boolean;

  //missingDefault
  @Prop({
    required: true,
    type: Object,
  })
  readonly auditItem!: AuditItemWithId | EmbeddedAuditItem;

  @Prop({
    required: true,
    type: String,
  })
  readonly auditItemId!: string;

  @Prop({
    required: false,
    type: Boolean,
  })
  hover!: boolean;

  @Prop({
    required: false,
    type: Boolean,
  })
  moving!: boolean;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  })
  isDialogOpen!: boolean;

  @Prop({
    required: false,
    type: Array,
  })
  dimensions!: AnswerStatus[];

  @Prop({
    default: (): QuestionSearchResult => ({}),
    type: Object,
  })
  readonly searchResult!: QuestionSearchResult;

  get hasEmbeddedCategories() {
    const categories = this.auditItem.question?.categories;
    return typeIsDictionary(categories) && isArray(categories.categoryPath);
  }

  get categoryCount() {
    const question = this.auditItem.question;
    if (isArray(question.categoryRef)) {
      return question.categoryRef.length;
    }
    const categories = this.auditItem.question?.categories;
    if (typeIsDictionary(categories) && isArray(categories.categoryPath)) {
      return categories.categoryPath.length;
    }
    return 0;
  }

  get auditItemProofs(): AuditProof[] {
    return this.auditItem.question.requiredProofs ?? [];
  }

  get hasHint(): boolean {
    const hint = this.$ct(this.auditItem.question.hint ?? "").trim();
    return hint !== "";
  }

  get questionType(): ValuedItemType | null {
    const questionType = this.auditItem.question.type;
    if (isString(questionType)) {
      return this.mappedAuditItemTypes[questionType] ?? null;
    } else {
      return questionType ?? null;
    }
  }

  get tags(): string[] {
    return this.auditItem.tags || [];
  }

  get links(): WebLink[] {
    return this.auditItem.question.links || [];
  }

  get notesText(): string[] {
    return this.mappedNotes.get(this.auditItemId) ?? [];
  }

  @Mutation(api.mutations.SET_FILTER_SETTING, { namespace: api.namespace })
  pushFilterSetting!: (payload: Filter) => void;

  @Mutation(api.mutations.TOGGLE_FILTER_SETTING, { namespace: api.namespace })
  toggleFilterSetting!: (payload: Filter) => void;

  @Getter(api.getters.getFullTextSearch, { namespace: api.namespace })
  filteredText!: string;

  @Getter(api.getters.getCategoryLevel, { namespace: api.namespace })
  categoryLevel!: CategoryLevel;

  @Getter(api.getters.getAuditCategorySetId, { namespace: api.namespace })
  categorySetId!: string;

  @Getter(api.getters.getMappedAuditItemTypes, { namespace: api.namespace })
  mappedAuditItemTypes!: AuditItemTypeMap;

  @Getter(api.getters.getSelfAssessmentIncluded, { namespace: api.namespace })
  selfAssessmentIncluded!: boolean;

  @Getter(configApi.getters.selfAssessmentFeatureEnabled, {
    namespace: configApi.namespace,
  })
  selfAssessmentFeatureEnabled!: boolean;

  get questionInSelfassessment(): boolean {
    return (
      this.selfAssessmentFeatureEnabled &&
      this.selfAssessmentIncluded &&
      this.auditItemProperties?.isSelfAssessmentEnabled === true
    );
  }

  @Getter(api.getters.getMappedNotes, {
    namespace: api.namespace,
  })
  mappedNotes!: Map<AuditItemWithId["id"], string[]>;

  setToggle(toggle: string) {
    if (toggle === this.currentToggle) {
      this.currentToggle = "";
    } else {
      this.currentToggle = toggle;
    }
  }

  filterTag(tag: string) {
    this.toggleFilterSetting({
      aggregationId: IDS.FILTER_TAGS,
      value: tag,
    });
  }

  filterChapter(chapter: ChapterRefType) {
    if (!this.isDialogOpen) {
      this.toggleFilterSetting({
        aggregationId: IDS.FILTER_CHAPTER,
        value: sortable_chapter(chapter),
      });
    }
  }

  filterCategory(category: AuditItemCategory) {
    if (!this.isDialogOpen) {
      if (category) {
        this.toggleFilterSetting({
          aggregationId: IDS.FILTER_CATEGORY,
          value: category.id,
        });
      }
    }
  }

  filterVdaScope() {
    this.toggleFilterSetting({
      aggregationId: IDS.FILTER_VDA_QUESTION_SCOPE,
      value: this.auditItem.question.vda_question_scope ?? null,
    });
  }
  filterProcess() {
    this.toggleFilterSetting({
      aggregationId: IDS.FILTER_VDA_PROCESSES,
      value: this.auditItem.question.vda_process?.step ?? null,
    });
  }

  get chapters() {
    return this.auditItem.question.chapters;
  }

  cancelMove() {
    this.$emit("cancelmove");
  }

  moved() {
    console.log("moved now", this.auditItemId);
    this.$emit("move", this.auditItemId);
  }
}
