

























































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter, Mutation } from "vuex-class";

import { api } from "@/store/modules/templates";
import { api as auditClassesApi } from "@/store/modules/auditClasses";
import { api as standardsApi } from "@/store/modules/standards";
import { AuditClassesListResult } from "@/store/modules/auditClasses/types";

import { pick, omit } from "lodash";
import { ROUTE_NAMES } from "../../routenames";
import { AuditStandardRef } from "@auditcloud/shared/lib/schemas";

function emptyTemplate() {
  return {
    name: "",
    audit_items: {},
    audit_class: "",
    standardRefs: [],
  };
}

@Component({
  components: {},
})
export default class ATemplateCreateDialog extends Vue {
  dialog: boolean = true;
  loading: boolean = false;
  xData = emptyTemplate();
  nameRules = [
    (v: any) => !!v || this.$t("common.rules.name_required").toString(),
    (v: any) =>
      (v && 2 <= v.trim().length && v.trim().length <= 32) ||
      this.$t(
        "components.dialogs.template_create_dialog.name_rule_length"
      ).toString(),
  ];

  @Prop({ type: String, default: null })
  templateItemId!: string | null;

  @Getter(auditClassesApi.getters.list, {
    namespace: auditClassesApi.namespace,
  })
  auditClasses!: AuditClassesListResult;

  @Getter(standardsApi.getters.getStandardList, {
    namespace: standardsApi.namespace,
  })
  standards!: AuditStandardRef[];

  @Action(api.actions.createTemplate, { namespace: api.namespace })
  createTemplate!: (templateData: any) => Promise<string>;

  get valid() {
    return (
      typeof this.auditClasses.find(ac => this.xData.audit_class === ac.id) !==
      "undefined"
    );
  }

  created() {
    console.log("ATemplateCreateDialog:created", this.xData);
  }

  mounted() {
    console.log("ATemplateCreateDialog:mounted", this.xData);
  }

  beforeDestroy() {
    console.log("ATemplateCreateDialog:beforeDestroy", this.xData);
  }

  prepareCreateTemplate() {
    console.log("createTemplate", this.xData);
    const auditClass = this.auditClasses.find(
      ac => this.xData.audit_class === ac.id
    );
    if (auditClass) {
      this.loading = true;
      const auditClassTemplateData = pick(auditClass, [
        "categorySetId",
        "reportSchema",
      ]);
      const templateData = omit(
        {
          ...auditClassTemplateData,
          auditClassId: auditClass.id,
          ...this.xData,
        },
        "id"
      );

      console.log("createTemplate", templateData);
      this.createTemplate(templateData)
        .then(templateId => {
          this.$router.push({
            name: ROUTE_NAMES.TEMPLATE,
            params: { templateId },
          });
        })
        .catch(err => {
          console.error("createTemplate failed", err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  closeDialog() {
    this.dialog = false;
    setTimeout(() => {
      this.$router.back();
    }, 250);
  }
}
