import { Module } from "vuex";
import { CurrentMeasureProcessState } from "./types";
import { RootState } from "../../types";
export { MOCK_MEASURE_PROCESSES as MEASURE_PROCESSES_MOCK_DATA } from "./mockData";

import { getters, getterNames } from "./getters";
import { actions, actionNames } from "./actions";
import { mutations, mutationNames } from "./mutations";

const state = (() => new CurrentMeasureProcessState())();

const namespaced: boolean = true;
const modul: Module<CurrentMeasureProcessState, RootState> = {
  namespaced,
  getters,
  actions,
  mutations,
  state,
};

export const api = {
  namespace: "measureProcess",
  mutations: mutationNames,
  getters: getterNames,
  actions: actionNames,
};
export default modul;
