import firebase from "firebase/compat/app";
import { ActionTree } from "vuex";
import { RootState } from "../../types";
import { CurrentTemplatesState } from "./types";
import { mutationNames as mn } from "./mutations";
import { getterNames as gn } from "./getters";
import { Dictionary, isString } from "lodash";
import { api as auditApi } from "../audit";
import { api as confApi } from "@/store/modules/configuration";
import { api as auditClassesApi } from "@/store/modules/auditClasses";
import {
  QueryData,
  OrderByData,
  createCollectionObserver,
  DocumentsUpdateHandlerData,
  MetadataUpdateHandlerData,
} from "@/utils/firestore";
import { actionNs, getterNs, logXStored } from "@/utils/VuexHelper";
import { AuditTemplate } from "../template/types";
import { AuditItemCategorySetMap } from "@auditcloud/shared/lib/types/AuditItemCategory";
import { rdc, rdd, rdu } from "../user";
import { createError } from "@/utils/Errors";
import {
  VERSIONED_DOCUMENT_FIELDNAME,
  REV_KEY,
} from "@auditcloud/shared/lib/types/VersionedDocument";
import { CollectionNames } from "@auditcloud/shared/lib/types/common";
import { fieldPath } from "@auditcloud/shared/lib/utils/firestorePathHelper";
import { AuditItemTypeMap } from "@auditcloud/shared/lib/types/ItemTypes";
import exceljs from "exceljs";
import { store as storeCategorySet } from "@auditcloud/shared/lib/utils/excel/categorySet";
import { store as storeAuditItems } from "@auditcloud/shared/lib/utils/excel/templateItems";
import { cleanWorksheetName } from "@auditcloud/shared/lib/utils/excel/utils";
import { AuditItemTypeConfigMap } from "@auditcloud/shared/lib/types/Configuration/defaults";
import { MappedAuditClasses } from "@auditcloud/shared/lib/types/AuditClass";
import { isVdaAuditClass } from "../audit/utils";

const loadTemplates = "loadTemplates";
const setQuestionsForCurrentAudit = "setQuestionsForCurrentAudit";
const createTemplate = "createTemplate";
const importTemplate = "importTemplate";
const exportTemplate = "exportTemplate";
const deleteTemplate = "deleteTemplate";

const n = {
  loadTemplates,
  setQuestionsForCurrentAudit,
  createTemplate,
  importTemplate,
  exportTemplate,
  deleteTemplate,
};

const actions: ActionTree<CurrentTemplatesState, RootState> = {
  async [n.loadTemplates](context) {
    const filter: QueryData[] = [
      new QueryData(
        fieldPath(REV_KEY, VERSIONED_DOCUMENT_FIELDNAME.DELETED),
        "==",
        false
      ),
    ];

    const orderBy = new OrderByData("name");

    const unlistener = createCollectionObserver(
      CollectionNames.TEMPLATES,
      { filter, orderBy },
      (updateData: DocumentsUpdateHandlerData) => {
        context.commit(mn.SET_TEMPLATES, updateData);
      },
      (updateData: MetadataUpdateHandlerData) => {
        context.commit(mn.SET_TEMPLATES_METADATA, updateData);
      },
      () => {}
    );
    context.commit(mn.SET_TEMPLATES_UNLISTENER, unlistener);
    return unlistener;
  },
  [n.setQuestionsForCurrentAudit](
    { dispatch, state, getters, rootGetters },
    payload
  ) {
    const templates = getters[
      gn.getMappedTemplates
    ] as Dictionary<AuditTemplate>;
    if (
      payload &&
      typeof payload.templateId === "string" &&
      templates &&
      templates[payload.templateId] &&
      templates[payload.templateId].audit_items
    ) {
      const template = templates[payload.templateId];

      const categorySetMap = rootGetters[
        getterNs(confApi, confApi.getters.getCategoryMapping)
      ] as AuditItemCategorySetMap;

      const categorySet = categorySetMap[template.categorySetId];
      const categories = categorySet ? categorySet.categories : [];

      if (categories.length > 0) {
        dispatch(
          actionNs(auditApi, auditApi.actions.useTemplate),
          {
            templateId: payload.templateId,
            ...template,
            categories,
          },
          {
            root: true,
          }
        );
      }
    }
  },
  async [n.createTemplate]({ rootGetters }, payload: any) {
    const collectionRef = firebase
      .firestore()
      .collection(CollectionNames.TEMPLATES);
    const docRef = await collectionRef.add({ ...payload, ...rdc(rootGetters) });

    return docRef.id;
  },
  async [n.importTemplate](
    { rootGetters },
    { id, data }: { id: string; data: AuditTemplate }
  ) {
    const doc = await firebase
      .firestore()
      .collection(CollectionNames.TEMPLATES)
      .doc(id)
      .get();

    if (
      !doc.exists ||
      (doc.exists &&
        confirm(
          "Ein Template mit dem angegebenen Namen existiert schon.\nSoll das bestehende Template überschrieben werden?"
        ))
    ) {
      await firebase.firestore().runTransaction(async transaction => {
        if (doc.exists) {
          transaction.update(doc.ref, {
            ...data,
            ...rdu(rootGetters),
          });
        } else {
          transaction.set(doc.ref, {
            ...data,
            ...rdc(rootGetters),
          });
        }
      });

      return true;
    } else {
      return false;
    }
  },
  async [n.exportTemplate]({ getters, rootGetters }, templateId: string) {
    const mappedTemplates = getters[
      gn.getMappedTemplates
    ] as Dictionary<AuditTemplate>;
    const template = mappedTemplates[templateId];
    if (!template) {
      throw new Error(`Template "${templateId}" not found`);
    }

    const categorySetId = template.categorySetId;
    const categorySetMap = rootGetters[
      getterNs(confApi, confApi.getters.getCategoryMapping)
    ] as AuditItemCategorySetMap;

    const categorySet = categorySetMap[categorySetId];
    if (!categorySet) {
      throw new Error(`CategorySet "${categorySetId}" not found`);
    }

    const selfAssessmentFeatureEnabled = rootGetters[
      getterNs(confApi, confApi.getters.selfAssessmentFeatureEnabled)
    ] as boolean;

    const auditClassId = template.audit_class;

    const mappedAuditItemTypes = rootGetters[
      getterNs(confApi, confApi.getters.getAuditItemTypesMappedByAuditClass)
    ] as AuditItemTypeConfigMap;
    const auditItemTypes = mappedAuditItemTypes[auditClassId];
    if (!auditItemTypes) {
      throw new Error(
        `Audit item types for audit class ${auditClassId} not found`
      );
    }

    const mappedAuditClasses = rootGetters[
      getterNs(auditClassesApi, auditClassesApi.getters.mappedAuditClasses)
    ] as MappedAuditClasses;

    const auditClass = mappedAuditClasses[auditClassId];
    const mappedAuditItemTypesForAuditClass =
      mappedAuditItemTypes[auditClassId].types;

    const classIsVdaAuditClass = isVdaAuditClass(
      auditClass,
      mappedAuditItemTypesForAuditClass
    );

    const standardRefs = template.standardRefs;
    const languages = template.templateLanguages;
    const auditItems = template.audit_items;

    const workbook = new exceljs.Workbook();

    const name = cleanWorksheetName(template.name);
    const auditItemsSheet = workbook.addWorksheet(
      cleanWorksheetName(template.name)
    );
    storeAuditItems(
      auditItemsSheet,
      auditItems,
      auditItemTypes.types,
      categorySet,
      standardRefs,
      languages,
      classIsVdaAuditClass ? "type" : "weight",
      selfAssessmentFeatureEnabled
    );

    const data = await workbook.xlsx.writeBuffer();
    return new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  },
  async [n.deleteTemplate]({ rootGetters }, templateId: any) {
    if (isString(templateId)) {
      const docRef = firebase
        .firestore()
        .collection(CollectionNames.TEMPLATES)
        .doc(templateId);
      const updateData = rdd(rootGetters);

      console.log(n.deleteTemplate, updateData, docRef);

      await docRef.update(updateData);
      logXStored(`Template delete: ${templateId}`)();
    } else {
      throw createError("expect valid templateId", templateId);
    }
  },
};

export { n as actionNames, actions };
