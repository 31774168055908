var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.xValue.length > 0 || !_vm.disabled)?_c('v-input',_vm._b({},'v-input',_vm.filterAttrs,false),[_c('v-container',{staticClass:"py-0 mt-5 mb-3"},[(_vm.xValue.length > 0)?_c('v-row',{class:{
          'label__sortable-control': true,
          'disabled-label__sortable-control': _vm.disabled,
        }},[_vm._v(" "+_vm._s(_vm.$ft(_vm.label))+" ")]):_vm._e(),_c('v-row',[_c('v-list',{staticClass:"pb-0",staticStyle:{"width":"100%"},attrs:{"subheader":""}},[_c('draggable',{attrs:{"options":{ disabled: _vm.disabled || _vm.editMode },"handle":".handle"},on:{"end":_vm.dragEnd},model:{value:(_vm.xValue),callback:function ($$v) {_vm.xValue=$$v},expression:"xValue"}},_vm._l((_vm.valueList),function(v,idx){return _c('v-list-item',{key:idx,staticClass:"list-item__sortable-control",attrs:{"disabled":_vm.disabled,"draggable":""}},[_c('v-list-item-content',{class:{
                  'py-0': true,
                  'disabled-items__sortable-control': _vm.disabled,
                }},[_c('v-chip',{class:{
                    'chip__sortable-control': true,
                    handle: true,
                    'disabled-items__sortable-control': !v.active && _vm.editMode,
                  },attrs:{"small":"","label":""},on:{"click":function($event){return _vm.startEditMode(idx)}}},[(!_vm.disabled)?_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cursor-move")])],1):_vm._e(),_vm._v(" "+_vm._s(_vm.formatter(v.word, idx + 1))+" "),_c('v-spacer'),(!_vm.disabled)?_c('v-avatar',{staticClass:"chip-close",attrs:{"right":"","justify":"end"},on:{"click":function($event){$event.stopPropagation();return _vm.removeWord(idx)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$delete")])],1):_vm._e()],1)],1)],1)}),1)],1)],1),(!_vm.disabled)?_c('v-row',{staticClass:"mt-0"},[_c('v-text-field',_vm._b({attrs:{"placeholder":_vm.xValue.length > 0 ? _vm.$ft(_vm.placeholder) : _vm.$ft(_vm.label),"hide-details":"","disabled":_vm.disabled,"clearable":!_vm.editMode},on:{"blur":_vm.vanishErrorByBlur,"change":_vm.vanishErrorByChange,"input":_vm.vanishErrorByChange,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addWord($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.addWord($event)}]},scopedSlots:_vm._u([(_vm.editMode)?{key:"append",fn:function(){return [_c('v-icon',{on:{"click":_vm.addWord}},[_vm._v("mdi-check-circle")]),_c('v-icon',{on:{"click":_vm.closeEditMode}},[_vm._v("mdi-close-circle")])]},proxy:true}:null],null,true),model:{value:(_vm.fieldInput),callback:function ($$v) {_vm.fieldInput=$$v},expression:"fieldInput"}},'v-text-field',_vm.filterAttrs,false))],1):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }