

































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter } from "vuex-class";
import { pick } from "lodash";

import { AuditProof } from "@auditcloud/shared/lib/schemas";

@Component({})
export default class ARequirementInput extends Vue {
  search: string = "";

  @Prop({
    default: () => [],
    type: Array,
  })
  readonly value!: AuditProof[];

  get filterAttrs() {
    return pick(this.$attrs, [
      "error",
      "error-messages",
      "error-count",
      "hint",
      "label",
    ]);
  }

  onValueChanged(newVal: (AuditProof | string)[]) {
    const proofs: AuditProof[] = newVal.map(v => {
      if (typeof v === "string") {
        const proof: AuditProof = {
          name: v,
          type: null,
          description: "",
        };
        return proof;
      } else {
        return v;
      }
    });
    this.$emit("input", proofs);
  }

  mounted() {
    console.log("ARequierementInput", this.value);
  }
}
