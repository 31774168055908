








































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { api as auditApi } from "@/store/modules/audit";
import { api as configApi } from "@/store/modules/configuration";
import {
  api as asyncHandlerApi,
  Getters as AsyncHandlerGetters,
} from "@/store/modules/asyncHandler";

import { MeasureType } from "@auditcloud/shared/lib/types/ItemTypes";
import { MeasurePolicyViolation } from "@auditcloud/shared/lib/utils/audit/measurePolicies";
import { stripDialog } from "@/routenames";
import AMeasureCreateForm from "./AMeasureCreateForm.vue";

@Component({
  components: {
    AMeasureCreateForm,
  },
})
export default class AuditMeasuresCreateDialog extends Vue {
  dialog: boolean = true;

  @Getter(asyncHandlerApi.getters.getIsOperationRunning, {
    namespace: asyncHandlerApi.namespace,
  })
  isOperationRunning!: AsyncHandlerGetters["getIsOperationRunning"];

  get loading() {
    return this.isOperationRunning("createMeasures");
  }

  get strictMeasurePolicyViolations() {
    return this.measurePolicyViolations.filter(
      v => v.violationLevel === "strict"
    );
  }

  get requiredMeasureTypes(): Array<MeasureType & { requiredCount: number }> {
    const measureTypeIds: string[] = [];

    this.strictMeasurePolicyViolations.forEach(({ measureTypeId }) => {
      if (!measureTypeIds.includes(measureTypeId)) {
        measureTypeIds.push(measureTypeId);
      }
    });

    // filter measureTypes to preserve the order of the measure types
    return this.measureTypes
      .filter(({ id }) => measureTypeIds.includes(id))
      .map(m => {
        return {
          ...m,
          requiredCount: this.strictMeasurePolicyViolations
            .filter(v => v.measureTypeId === m.id)
            .reduce((acc, curr) => {
              return (acc += curr.findingCount);
            }, 0),
        };
      });
  }

  @Watch("requiredMeasureTypes", { deep: true })
  requiredMeasureTypeChanged(
    newVal: AuditMeasuresCreateDialog["requiredMeasureTypes"]
  ) {
    if (newVal.length < 1) {
      this.close();
    }
  }

  @Getter(configApi.getters.getMeasureTypes, {
    namespace: configApi.namespace,
  })
  measureTypes!: MeasureType[];

  @Getter(auditApi.getters.getMeasurePolicyViolations, {
    namespace: auditApi.namespace,
  })
  measurePolicyViolations!: MeasurePolicyViolation[];

  close() {
    const route = this.$route;
    this.$router.push({
      ...route,
      name: stripDialog(route.name ?? "error"),
    });

    this.dialog = false;
  }
}
