




















































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import AAsyncBtn, {
  AsyncHandlerFunction,
} from "@/components/controls/AAsyncBtn.vue";
import { api as auditApi } from "@/store/modules/audit";
import {
  api as asyncHandlerApi,
  Getters as AsyncHandlerGetters,
} from "@/store/modules/asyncHandler";
import { UserRef } from "@auditcloud/shared/lib/schemas";
import { MeasureType } from "@auditcloud/shared/lib/types/ItemTypes";
import { AuditClassClient } from "@auditcloud/shared/lib/types/AuditClass";
import { nullable } from "@auditcloud/shared/lib/types/common";
import { AuditMetadataClient } from "@/types/Audit";
import { AsyncHandlerFunctionResponse } from "@/store/modules/asyncHandler";
import { calcMeasureDueDateForMeasureType } from "@auditcloud/shared/lib/utils/measure/dueDate";
import { MEASURE_MAX_CHAR_LIMIT } from "@auditcloud/shared/lib/workflow/configs/constants";

@Component({
  components: {
    AAsyncBtn,
  },
})
export default class AMeasureCreateForm extends Vue {
  dueDateForMeasures: string | null = null;
  assigneeForMeasures: UserRef | null = null;
  defaultTextForMeasures: string = "";

  @Getter(asyncHandlerApi.getters.getIsOperationRunning, {
    namespace: asyncHandlerApi.namespace,
  })
  isOperationRunning!: AsyncHandlerGetters["getIsOperationRunning"];

  get loading() {
    return this.isOperationRunning("createMeasures");
  }

  @Prop({
    type: Object,
    required: true,
  })
  requiredMeasureType!: MeasureType & { requiredCount: number };

  @Watch("requiredMeasureType", { deep: true, immediate: true })
  requiredMeasureTypeChanged(
    newVal: AMeasureCreateForm["requiredMeasureType"],
    oldVal?: AMeasureCreateForm["requiredMeasureType"]
  ) {
    if (newVal.id !== oldVal?.id) {
      this.dueDateForMeasures = calcMeasureDueDateForMeasureType(
        this.auditMetadata,
        this.auditClass,
        newVal.id
      );
      this.assigneeForMeasures = this.auditMetadata?.responsible ?? null;
      this.defaultTextForMeasures = "";
    }
  }

  get measureMaxCharLimit() {
    return MEASURE_MAX_CHAR_LIMIT;
  }

  get measureTitleLengthValid() {
    return (
      this.defaultTextForMeasures.trim().length <= this.measureMaxCharLimit
    );
  }

  get enableMeasureImplementationInQuickEditor() {
    return this.auditClass?.enableMeasureImplementationInQuickEditor;
  }

  get measureCreationFieldsValid() {
    return (
      !!this.dueDateForMeasures &&
      !!this.assigneeForMeasures &&
      this.measureTitleLengthValid
    );
  }

  get createRequiredMeasuresHandler(): AsyncHandlerFunction {
    const dueDate = this.dueDateForMeasures;
    const assignedToUser = this.assigneeForMeasures;

    if (!dueDate || !assignedToUser) {
      return () => Promise.resolve(null);
    } else {
      return () => {
        this;
        return this.createRequiredMeasuresForPolicices({
          dueDate,
          assignedTo: assignedToUser.id,
          defaultText: this.defaultTextForMeasures.trim(),
          measureTypeId: this.requiredMeasureType.id,
        });
      };
    }
  }

  @Getter(auditApi.getters.getAuditClass, {
    namespace: auditApi.namespace,
  })
  auditClass!: nullable<AuditClassClient>;

  @Getter(auditApi.getters.getAuditMetadata, {
    namespace: auditApi.namespace,
  })
  auditMetadata!: nullable<AuditMetadataClient>;

  @Action(auditApi.actions.createRequiredMeasuresForPolicies, {
    namespace: auditApi.namespace,
  })
  createRequiredMeasuresForPolicices!: (payload: {
    dueDate: string;
    assignedTo: string;
    defaultText: string;
    measureTypeId: string;
  }) => Promise<AsyncHandlerFunctionResponse>;
}
