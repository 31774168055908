















































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import {
  calcUrgency,
  deriveRiskFindingTypeId,
} from "@auditcloud/shared/lib/types/UrgencyChart";
import { api } from "@/store/modules/audit";
import { FindingTypeMap } from "@auditcloud/shared/lib/types/ItemTypes";
import { SimpleFindingRisk } from "@auditcloud/shared/lib/schemas";
import { debounce } from "lodash";

export interface RiskWidgetOutput {
  findingTypeId: string;
  risk: SimpleFindingRisk;
}

@Component({})
export default class ARiskWidget extends Vue {
  // local properties used by the button groups
  text: string | undefined = "";
  likelihood: number | undefined = 0.1;
  impact: number | undefined = 0.1;
  findingTypeId: string = "";

  @Prop({
    type: Object,
    required: true,
  })
  readonly risk!: SimpleFindingRisk;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly loading!: boolean;

  @Getter(api.getters.getMappedFindingTypes, { namespace: api.namespace })
  findingTypes!: FindingTypeMap;

  @Watch("risk", { deep: true, immediate: true })
  onRiskChange() {
    // using the @input event rather than @change in the slider, so that the
    // finding type (and so the color) is updated while dragging.
    // Using debounce to save some computation when using the slider
    const change = debounce(() => {
      this.text = this.risk.text;
      this.likelihood = this.risk.likelihood ?? undefined;
      this.impact = this.risk.impact ?? undefined;
      this.findingTypeId = this.derivedFindingTypeId;
    }, 250);
    change();
  }

  get derivedFindingTypeId() {
    const urgency =
      this.impact !== undefined && this.likelihood !== undefined
        ? calcUrgency(this.impact, this.likelihood)
        : null;
    return deriveRiskFindingTypeId(urgency);
  }

  get output(): RiskWidgetOutput {
    const risk: SimpleFindingRisk = {
      ...this.risk,
      text: this.text,
      impact: this.impact ?? null,
      likelihood: this.likelihood ?? null,
    };
    const findingTypeId = this.derivedFindingTypeId;
    return {
      findingTypeId,
      risk,
    };
  }

  get urgency() {
    return calcUrgency(this.impact ?? null, this.likelihood ?? null);
  }

  updateRiskValues() {
    const output = this.output;

    const findingType = this.findingTypes[output.findingTypeId];

    if (!findingType) {
      console.error(
        `Finding type with the derived id ${output.findingTypeId} does not exist.`
      );
      return;
    }

    this.$emit("change", output);
  }

  updateText() {
    this.$emit("change", this.output);
  }
}
