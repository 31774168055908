import { GetterTree } from "vuex";
import {
  CurrentMeasureProcessState,
  MeasureProcessMetadata,
  MeasureProcessStep,
  MeasureProcessStepEmpty,
  MeasureProcessStepQuestion,
  MeasureProcessStepFinding,
  MeasureProcessStepDirectMeasure,
  MeasureProcessStepCauseAnalysis,
  MeasureProcessStepCorrectiveMeasure,
  CorrectiveMeasureTask,
  Signature,
  MeasureProcessStepEfficacyCheck,
  NamedRef,
  StepState,
  SignatureType,
  TITLE_QUESTION,
  TITLE_FINDING,
  TITLE_CAUSE_ANALYSIS,
  TITLE_DIRECTMEASURE,
  TITLE_MEASURE,
  TITLE_EFFICACY_CHECK,
} from "./types";
import { RootState } from "../../types";
import { api } from "./index";
import { nullable } from "@auditcloud/shared/lib/types/common";
import { TodoArray } from "@auditcloud/shared/lib/utils/type-guards";
import { MeasureActivity } from "@/types/measure-activities";
import { AuditItem } from "@auditcloud/shared/lib/schemas";
import { isEqual } from "lodash";

const getMeasureProcessId = "getMeasureProcessId";
const getMeasureProcessMetadata = "getMeasureProcessMetadata";
const getMeasureProcessActivities = "getMeasureProcessActivities";
const getMeasureProcessSteps = "getMeasureProcessSteps";
const getAuditList = "getAuditList";

const n = {
  getMeasureProcessId,
  getMeasureProcessMetadata,
  getMeasureProcessActivities,
  getMeasureProcessSteps,
  getAuditList,
};

const getterTree: GetterTree<CurrentMeasureProcessState, RootState> = {
  [n.getMeasureProcessId](
    state,
    getters,
    rootState,
    rootGetters
  ): string | null {
    return state.id;
  },
  [n.getMeasureProcessMetadata](
    state,
    getters,
    rootState,
    rootGetters
  ): nullable<MeasureProcessMetadata> {
    return state.metadata.data;
  },
  [n.getMeasureProcessActivities](
    state,
    getters,
    rootState,
    rootGetters
  ): MeasureActivity[] {
    return state.activities;
  },
  [n.getMeasureProcessSteps](state) {
    // TODO: Berechnen der offenen Workflow Status muss noch verbessert werden.
    const lastStep =
      state.steps.length > 0 ? state.steps[state.steps.length - 1] : undefined;
    console.log("getMeasureProcessSteps", lastStep, state.steps);

    if (lastStep) {
      const ToDoSteps = [
        new MeasureProcessStepEmpty(TITLE_QUESTION),
        new MeasureProcessStepEmpty(TITLE_FINDING),
        new MeasureProcessStepEmpty(TITLE_DIRECTMEASURE),
        new MeasureProcessStepEmpty(TITLE_CAUSE_ANALYSIS),
        new MeasureProcessStepEmpty(TITLE_MEASURE),
        new MeasureProcessStepEmpty(TITLE_EFFICACY_CHECK),
      ];

      const idx = ToDoSteps.findIndex(step =>
        isEqual(step.title, lastStep.title)
      );
      console.log("End Steps, idx", idx);
      if (idx > -1 && idx + 1 < ToDoSteps.length) {
        return [...state.steps, ...ToDoSteps.slice(idx + 1)];
      }
    }
    return state.steps;
  },
  /**
   * Gibt eine Liste der zukünfig geplanten Audits zurück
   */
  [n.getAuditList](state, getters, rootState, rootGetters): NamedRef[] {
    return [
      {
        id: "09f41650-f76b-42fb-9d86-9516101d06c4",
        name: "Audit 01",
      },
      {
        id: "c5486388-21a1-4bf9-a350-6edc993e1cd7",
        name: "Audit 02",
      },
      {
        id: "d9c3c915-0174-4a2c-b2b8-8a06e0406d04",
        name: "Audit 03",
      },
      {
        id: "8ce2c0c3-a506-4cb8-a22a-d41772b1a81b",
        name: "Audit 04",
      },
    ];
  },
};

export { n as getterNames, getterTree as getters };
