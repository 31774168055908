































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter } from "vuex-class";
import { pick } from "lodash";

@Component({})
export default class SimplePagination extends Vue {
  page: string = "";
  isNext: boolean = true;
  isPrev: boolean = true;

  @Prop({
    type: Number,
    required: true,
  })
  value!: number;

  @Prop({
    type: Number,
    required: true,
  })
  min!: number;
  @Prop({
    type: Number,
    required: true,
  })
  max!: number;

  @Watch("value", { immediate: true })
  onValueChanged(newVal: number) {
    this.page = String(newVal);
    this.isNext = !(+this.page === this.max);
    this.isPrev = !(+this.page === this.min);
  }

  isNumberOnly(val: string): boolean {
    return /^\d+$/.test(val);
  }

  onEnter() {
    //check if number
    if (!this.isNumberOnly(this.page)) {
      this.page = String(this.value);
      return;
    }
    const val = parseInt(this.page, 10);

    //check if number is bigger than array.length
    if (val > this.max) {
      this.page = String(this.value);
      return;
    }

    this.$emit("input", val);
  }

  onNext() {
    const val = this.value + 1;
    this.page = String(val);
    this.$emit("input", val);
  }

  onPrev() {
    const val = this.value - 1;
    this.page = String(val);
    this.$emit("input", val);
  }
}
