import { mapValues } from "lodash";
import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import { isoTimestampNow } from "@auditcloud/shared/lib/utils/dateUtils";
import { State, InternalError } from "./types";
import { stringifyError } from "./utils";
import moment from "moment";

const mutations = {
  START_OPERATION(state: State, operationId: string) {
    const operationOperationCount = state.operations[operationId] ?? 0;
    Vue.set(state.operations, operationId, operationOperationCount + 1);
  },
  STOP_OPERATION(state: State, operationId: string) {
    const operationOperationCount = state.operations[operationId] ?? 1;
    Vue.set(state.operations, operationId, operationOperationCount - 1);
  },
  ADD_ERROR(state: State, error: InternalError) {
    const message = stringifyError(error.error);
    console.error(
      `AASyncBtn failed ${error.operationId} failed: `,
      error.error
    );

    state.messages.push({
      message: message,
      type: "error",
      payload: error,
      createdAt: isoTimestampNow(),
      id: uuidv4(),
    });
  },
  ADD_MSG(
    state: State,
    msg: Omit<State["messages"][number], "id" | "createdAt">
  ) {
    state.messages.push({
      ...msg,
      id: uuidv4(),
      createdAt: isoTimestampNow(),
    });
  },
  CLEANUP_MESSAGES(state: State) {
    const now = moment(isoTimestampNow());
    state.messages = state.messages.filter(message => {
      const createdAt = moment(message.createdAt);
      const duration = now.diff(createdAt, "s");
      return duration < 5;
    });
  },
  REMOVE_MESSAGE(state: State, id: string) {
    state.messages = state.messages.filter(v => v.id !== id);
  },
};

const n = mapValues(mutations, (_, key) => key);

export { n as mutationNames, mutations };
