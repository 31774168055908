import firebase from "firebase/compat/app";
import { MutationTree, ActionTree, GetterTree, Module } from "vuex";
import { RootState } from "@/store/types";

import { Dispatcher } from "@/store/helper/firestore";
import Vue from "vue";

import { api as appApi } from "@/store/modules/app";
import { api as auditApi } from "@/store/modules/audit";
import { api as auditClassesApi } from "@/store/modules/auditClasses";
import { TodoAny, TodoMap } from "@auditcloud/shared/lib/utils/type-guards";
import {
  appDefaults,
  auditDefaults,
  auditItemDefaults,
  findingDefaults,
  measureDefaults,
  categoriesDefault,
  scopeDefaults,
  userDefaults,
  internalsDefaults,
} from "./defaults";
import {
  FindingConfig,
  MeasureConfig,
  AuditItemConfig,
  FindingTypeConfigMap,
  AuditItemTypeConfigMap,
  AppConfig,
} from "@auditcloud/shared/lib/types/Configuration/defaults";
import { AuditItemCategorySetMap } from "@auditcloud/shared/lib/types/AuditItemCategory";
import { defaultgetConfigurationForList } from "@auditcloud/shared/lib/types/Configuration/defaults";
import {
  createFindingConfig,
  createMeasureConfig,
  createAuditItemConfig,
  createCategoriesConfig,
  createListConfig,
} from "@auditcloud/shared/lib/types/Configuration/Configuration";
import {
  MeasureType,
  MeasureTypeMap,
  FindingTypeMap,
} from "@auditcloud/shared/lib/types/ItemTypes";
import { actionNs, getterNs } from "@/utils/VuexHelper";
import {
  systemDefaults,
  createSystemConfig,
  DocumentNames,
  CollectionNames,
} from "@auditcloud/shared/lib/types/common";
import {
  AuditProgramConfig,
  OrganizationUnitConfig,
  NamedRef,
  TranslateableText,
} from "@auditcloud/shared/lib/schemas";

import keyBy from "lodash/keyBy";
import {
  mergeWith,
  isArray,
  omit,
  toPairs,
  mapValues,
  Dictionary,
  isNumber,
  map,
  intersection,
} from "lodash";
import { typeIsDictionary } from "@auditcloud/shared/lib/utils/type-guards";
import { ft } from "@/plugins/ContentTranslation";
import naturalCompare from "natural-compare";
import { extractCurrentUserRoles } from "../user/utils";
import { SystemRoles } from "@auditcloud/shared/lib/constants/roles";

const system = DocumentNames.CONFIG_SYSTEM;

export const api = {
  namespace: "configuration",
  actions: {
    loadData: "loadData",
    importData: "importData",
    updateConfiguration: "updateConfiguration",
  },
  mutations: {
    resetState: "resetState",
    setState: "setState",
    updateConfiguration: "updateConfiguration",
  },
  getters: {
    app: "app",
    scopes: "scopes",
    template: "template",
    audit: "audit",
    audititem: "audititem",
    finding: "finding",
    measure: "measure",
    user: "user",
    categories: "categories",
    system,
    lists: "lists",
    internals: "internals",

    getConfigurationForList: "getConfigurationForList",
    customerLogo: "customerLogo",

    getMeasureTypes: "getMeasureTypes",
    getMeasureTypesMapping: "getMeasureTypesMapping",

    getFindingActions: "getFindingActions",
    getLimitCounts: "getLimitCounts",
    getVisibleStatus: "getVisibleStatus",
    getAuditItemActions: "getAuditItemActions",
    getTemplateItemActions: "getTemplateItemActions",

    getMeasureActions: "getMeasureActions",

    getCategoryMapping: "getCategoryMapping",
    auditingYears: "auditingYears",
    getFindingTypesMappedByAuditClass: "getFindingTypesMappedByAuditClass",
    getAllMappedFindingTypes: "getAllMappedFindingTypes",
    getAuditItemTypesMappedByAuditClass: "getAuditItemTypesMappedByAuditClass",
    getMappedAuditPrograms: "getMappedAuditPrograms",
    getMappedOrganizationUnits: "getMappedOrganizationUnits",
    getAuditProgramList: "getAuditProgramList",
    getMappedAuditProgramTypes: "getMappedAuditProgramTypes",
    getAuditCompositionWizardSettings: "getAuditCompositionWizardSettings",
    getManagementSummaryAllowedRoles: "getManagementSummaryAllowedRoles",
    hasAuditdProgramGrid: "hasAuditdProgramGrid",
    areReportingDatesRequired: "areReportingDatesRequired",
    configurableReportFeatureEnabled: "configurableReportFeatureEnabled",
    configurableChecklistFeatureEnabled: "configurableChecklistFeatureEnabled",
    getIsAuditCreationAllowed: "getIsAuditCreationAllowed",
    customerPortalFeatureEnabled: "customerPortalFeatureEnabled",
    selfAssessmentFeatureEnabled: "selfAssessmentFeatureEnabled",
    allowedSelfAssessmentSharingTypes: "allowedSelfAssessmentSharingTypes",
    getIsSelfAssessmentStatusVisibleOnDashboard:
      "getIsSelfAssessmentStatusVisibleOnDashboard",
    externalMeasureFeatureEnabled: "externalMeasureFeatureEnabled",
    getDialogButtonPosition: "getDialogButtonPosition",
    getAnniversaryEventShowFrom: "getAnniversaryEventShowFrom",
    getAnniversaryEventShowUntil: "getAnniversaryEventShowUntil",

    getGoogleTagManagerEnabled: "getGoogleTagManagerEnabled",
    getUserAutocompleteHint: "getUserAutocompleteHint",
  },
  state: {
    app: "app",
    scopes: "scopes",
    template: "template",
    audit: "audit",
    audititem: "audititem",
    finding: "finding",
    measure: "measure",
    user: "user",
    workflow: "workflow",
    categories: "categories",
    lists: "lists",
    internals: "internals",
    system,
  },
};

export interface LimitCountType {
  auditLimit: number;
  measureLimit: number;
  activityLimit: number;
}
export interface StatusType {
  auditStatus: string[];
  measureStatus: string[];
}
export class ConfigurationState {
  app: AppConfig = appDefaults();
  audit = auditDefaults();
  audititem: AuditItemConfig = auditItemDefaults();
  scopes: TodoAny = scopeDefaults();
  finding: FindingConfig = findingDefaults();
  categories: AuditItemCategorySetMap /*{ [id: string]: AuditItemCategories }*/ =
    categoriesDefault();
  lists = defaultgetConfigurationForList();
  internals = internalsDefaults();
  measure: MeasureConfig = measureDefaults();
  user: TodoAny = userDefaults();
  system = systemDefaults();
}

const state = new ConfigurationState();

const mutations: MutationTree<ConfigurationState> = {
  [api.mutations.resetState](state) {
    const config = omit(new ConfigurationState(), DocumentNames.CONFIG_SYSTEM);
    toPairs(config).forEach(([key, val]) => {
      Vue.set(state, key, val);
    });
  },
  [api.mutations.setState](state, payload) {
    state.app = mergeWith(appDefaults(), payload.app, (objValue, srcValue) => {
      if (isArray(objValue) && srcValue) {
        return srcValue;
      }
    });
    state.audit = {
      ...auditDefaults(),
      ...payload.audit,
    };
    state.audititem = createAuditItemConfig(
      payload.audititem ?? auditItemDefaults()
    );
    state.lists = createListConfig(
      payload.lists ?? defaultgetConfigurationForList()
    );
    state.internals = { ...internalsDefaults(), ...payload.internals };
    state.scopes = payload.scopes;
    state.finding = createFindingConfig(payload.finding ?? findingDefaults());
    state.categories = createCategoriesConfig(payload.categories);
    state.measure = createMeasureConfig(payload.measure ?? measureDefaults());
    state.user = payload.user;
  },
  [api.mutations.updateConfiguration](
    state,
    payload: { segment: string; data: TodoAny }
  ) {
    if (payload.segment === "finding") {
      state.finding = createFindingConfig(payload.data);
    } else if (payload.segment === "measure") {
      state.measure = createMeasureConfig(payload.data);
    } else if (payload.segment === "audititem") {
      state.audititem = createAuditItemConfig(payload.data);
    } else if (payload.segment === "categories") {
      state.categories = createCategoriesConfig(payload.data);
    } else if (payload.segment === "lists") {
      state.lists = createListConfig(
        payload.data ?? defaultgetConfigurationForList()
      );
    } else if (payload.segment === DocumentNames.CONFIG_SYSTEM) {
      state.system = createSystemConfig(payload.data);
    } else if (payload.segment === DocumentNames.CONFIG_INTERNALS) {
      Vue.set(state, payload.segment, payload.data);
    } else {
      const segments: TodoMap = {
        app: state.app,
        audit: state.audit,
        scopes: state.scopes,
        measure: state.measure,
        user: state.user,
      };
      if (payload.segment in segments && segments[payload.segment]) {
        Object.assign(segments[payload.segment], payload.data);
      } else {
        Vue.set(state, payload.segment, payload.data);
      }
    }
  },
};

// TODO: Not realy save...
const actions: ActionTree<ConfigurationState, RootState> = {
  async [api.actions.loadData]({ rootGetters, commit, dispatch }) {
    const configurations: TodoMap = {};
    dispatch("app/setLoading", true, { root: true });

    const isSysAdmin = extractCurrentUserRoles(rootGetters).includes(
      SystemRoles.SYSTEM_ADMINISTRATOR
    );
    if (isSysAdmin) {
      const internals = await firebase
        .firestore()
        .collection(CollectionNames.CONFIGURATION_PRIVATE)
        .doc(DocumentNames.CONFIG_INTERNALS)
        .get();
      if (internals.exists) {
        configurations[internals.id] = internals.data();
      }
    }

    const result = await new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(CollectionNames.CONFIGURATION)
        .get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            if (doc.id === auditClassesApi.namespace) {
              dispatch(
                actionNs(auditClassesApi, auditClassesApi.actions.init),
                doc.data(),
                { root: true }
              );
            } else {
              configurations[doc.id] = doc.data();
            }
          });
          commit(api.mutations.setState, configurations);
          dispatch("app/setLoading", false, { root: true });
          resolve(true);
        })
        .catch(error => {
          console.error(error);
          dispatch("app/setLoading", false, { root: true });
          reject(false);
        });
    });

    return result;
  },
  [api.actions.importData]({ commit }, payload) {
    // TODO: Datensätze aus Configuration Component importieren -> Hier verliert man schon schnell den Überblick!
    // const ref = "configuration";
    // const mutation = "resetState";

    let daten = new ConfigurationState();

    payload = { data: new ConfigurationState() };

    // Disabled - Ist viel zu gefährlich!
    //Dispatcher.importAsync({ ref, child, payload, commit, mutation });
  },
  [api.actions.updateConfiguration]({ commit }, payload) {
    // TODO: Datensätze aus Configuration Component importieren -> Hier verliert man schon schnell den Überblick!

    // the internals config lives in a separate collection (rules forbid listing)
    const doc = payload.segment;
    const ref =
      doc === DocumentNames.CONFIG_INTERNALS
        ? CollectionNames.CONFIGURATION_PRIVATE
        : CollectionNames.CONFIGURATION;
    const mutation = api.mutations.updateConfiguration;
    const child = "";

    Dispatcher.createAsync({ ref, doc, child, payload, commit, mutation });
  },
};

const getters: GetterTree<ConfigurationState, RootState> = {
  [api.getters.app](state) {
    return state.app;
  },
  [api.getters.audit](state) {
    return state.audit;
  },
  [api.getters.audititem](state) {
    return state.audititem;
  },
  [api.getters.finding](state): FindingConfig {
    return state.finding;
  },
  [api.getters.measure](state) {
    return state.measure;
  },
  [api.getters.scopes](state) {
    return state.scopes;
  },
  [api.getters.user](state) {
    return state.user;
  },
  [api.getters.categories](state): AuditItemCategorySetMap {
    return state.categories;
  },
  [api.getters.system](state) {
    return state.system;
  },
  [api.getters.lists](state) {
    // TODO: Fix Typo in Firestore
    const lists = { ...state.lists };
    if (!lists.auditPreparation && lists.auditPreperation) {
      lists.auditPreparation = lists.auditPreperation;
    }
    return lists;
  },
  [api.getters.internals](state) {
    return { ...state.internals };
  },
  [api.getters.getConfigurationForList](state, getters) {
    return (key: string) => {
      if (key in getters.lists && getters.lists[key]) {
        return getters.lists[key];
      } else {
        return {
          pageSizes: [25, 50, 100, null],
          itemsjs: null,
          manipulators: null,
        };
      }
    };
  },
  [api.getters.customerLogo](state) {
    return (
      state.app.customerLogo ||
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVR42mNgAAIAAAUAAen63NgAAAAASUVORK5CYII="
    );
  },
  [api.getters.getMeasureTypes](state): MeasureType[] {
    return state.measure.data.types;
  },
  [api.getters.getMeasureTypesMapping](state): MeasureTypeMap {
    return keyBy(state.measure.data.types, "id");
  },

  [api.getters.getFindingActions](state, getters, rootState, rootGetters) {
    const isOnline = rootGetters[getterNs(appApi, appApi.getters.getIsOnline)];
    const actions: Array<{
      id: string;
      color: string;
      icon: string;
      event: string | { action: string };
    }> = [];

    actions.push({
      id: "1",
      color: "blue darken-2",
      icon: "edit",
      event: "itemupdate",
    });
    if (isOnline) {
      actions.push({
        id: "2",
        color: "indigo",
        icon: "camera",
        event: "itemcamera",
      });
      actions.push({
        id: "3",
        color: "indigo",
        icon: "attach_file",
        event: "itemattach",
      });
    }

    actions.push({
      id: "4",
      color: "red",
      icon: "delete",
      event: {
        action: actionNs(auditApi, auditApi.actions.deleteFinding),
      },
    });
    return actions;
  },
  [api.getters.getLimitCounts](state, getters): LimitCountType {
    const appConfig = getters[api.getters.app];
    const DEFAULT_LIMITS: LimitCountType = {
      auditLimit: 10,
      measureLimit: 10,
      activityLimit: 20,
    };

    const limitCounts = appConfig.components?.dashboard?.limitCount as unknown;
    console.log("GET_LIMIT_COUNTS", limitCounts, appConfig);
    if (typeIsDictionary(limitCounts)) {
      return {
        auditLimit: isNumber(limitCounts.auditLimit)
          ? limitCounts.auditLimit
          : DEFAULT_LIMITS.auditLimit,
        measureLimit: isNumber(limitCounts.measureLimit)
          ? limitCounts.measureLimit
          : DEFAULT_LIMITS.measureLimit,
        activityLimit: isNumber(limitCounts.activityLimit)
          ? limitCounts.activityLimit
          : DEFAULT_LIMITS.activityLimit,
      };
    } else {
      return DEFAULT_LIMITS;
    }
  },
  [api.getters.getManagementSummaryAllowedRoles](state, getters): string[] {
    const appConfig = getters[api.getters.app] as AppConfig;
    return appConfig.components.managementSummary?.allowedRoles ?? [];
  },
  [api.getters.getVisibleStatus](state, getters): StatusType {
    const appConfig = getters[api.getters.app];
    const status = (appConfig.components?.dashboard?.status ??
      null) as StatusType | null;
    if (status) {
      return status;
    } else {
      const initialValue = appDefaults();
      return initialValue.components.dashboard.status;
    }
  },
  [api.getters.getAuditItemActions]() {
    return [
      {
        id: "1",
        color: "blue darken-2",
        icon: "edit",
        event: "itemupdate",
      },
      {
        id: "2",
        color: "red",
        icon: "delete",
        event: "itemdelete",
      },
    ];
  },
  [api.getters.getTemplateItemActions](state, getters) {
    return getters[api.getters.getAuditItemActions];
  },

  [api.getters.getMeasureActions]() {
    return [
      {
        id: "1",
        color: "blue darken-2",
        icon: "edit",
        event: "itemupdate",
      },
      {
        id: "2",
        color: "red",
        icon: "delete",
        event: "itemdelete",
      },
    ];
  },
  [api.getters.getCategoryMapping](state): AuditItemCategorySetMap {
    return state.categories;
  },
  [api.getters.auditingYears](state): number[] {
    const currentYear = new Date().getFullYear();
    console.assert(
      typeof state.app.startYear === "number" && state.app.startYear >= 2000,
      "invalid startYear in /configuration/app.startYear fallback to ",
      currentYear
    );
    const startYear: number =
      typeof state.app.startYear === "number" && state.app.startYear >= 2000
        ? state.app.startYear
        : currentYear;

    const years: Array<number> = [];
    for (
      let year = Math.min(currentYear, startYear);
      year <= Math.max(currentYear, startYear) + 2;
      year++
    ) {
      years.push(year);
    }

    return years;
  },
  [api.getters.getFindingTypesMappedByAuditClass](state): FindingTypeConfigMap {
    return state.finding.typesForAuditClass;
  },
  [api.getters.getAllMappedFindingTypes](state): FindingTypeMap {
    const findingTypes = Object.values(state.finding.typesForAuditClass).map(
      findingConfig => findingConfig.types
    );
    const mappedFindingTypes: FindingTypeMap = {};

    return findingTypes.reduce((p, c) => {
      return { ...p, ...c };
    }, mappedFindingTypes);
  },
  [api.getters.getAuditItemTypesMappedByAuditClass](
    state
  ): AuditItemTypeConfigMap {
    return state.audititem.typesForAuditClass;
  },
  [api.getters.getMappedAuditPrograms](state): Dictionary<AuditProgramConfig> {
    return state.audit.auditPrograms;
  },
  [api.getters.getMappedOrganizationUnits](
    state
  ): Dictionary<OrganizationUnitConfig> {
    return state.audit.organizationUnits ?? {};
  },
  [api.getters.getAuditProgramList](state): Array<NamedRef> {
    const natualNamedRefSorter = (
      lhs: { id: string; name: string },
      rhs: { id: string; name: string }
    ) => {
      return naturalCompare(lhs.name, rhs.name);
    };
    const auditProgramList = map(
      state.audit.auditPrograms,
      (auditProgram, id) => {
        return {
          id,
          organizationUnitId: auditProgram.organizationUnitId ?? null,
          name: ft(auditProgram.name),
        };
      }
    ).sort(natualNamedRefSorter);
    const organizationUnitNames = mapValues(
      state.audit.organizationUnits,
      organizationUnit => {
        return ft(organizationUnit.name).trim();
      }
    );

    return auditProgramList
      .map(({ name, id, organizationUnitId }) => {
        const organizationUnitName =
          organizationUnitNames[organizationUnitId ?? ""] ?? null;
        return {
          id,
          name: organizationUnitName
            ? `${organizationUnitName} / ${name}`
            : ` ${name}`,
        };
      })
      .sort(natualNamedRefSorter);
  },
  [api.getters.getMappedAuditProgramTypes](state) {
    return mapValues(state.audit.auditPrograms, v => v.name);
  },
  [api.getters.getIsAuditCreationAllowed](
    state,
    getters,
    rootState,
    rootGetters
  ) {
    const roles = extractCurrentUserRoles(rootGetters);
    return (
      roles.includes(SystemRoles.SYSTEM_ADMINISTRATOR) ||
      intersection(state.audit.auditCreationAllowedRoles, roles).length > 0
    );
  },
  [api.getters.getAuditCompositionWizardSettings](state) {
    return state.audit.auditCreateSettings;
  },
  [api.getters.hasAuditdProgramGrid](state) {
    return state.app.displayAuditProgramGrid === true;
  },
  [api.getters.areReportingDatesRequired](state) {
    return state.audit?.areReportingDatesRequired ?? false;
  },
  [api.getters.configurableChecklistFeatureEnabled](state) {
    return state.app.features?.configurableChecklist ?? false;
  },
  [api.getters.configurableReportFeatureEnabled](state) {
    return state.app.features?.configurableReport ?? false;
  },
  [api.getters.customerPortalFeatureEnabled](state, getters) {
    return state.app.customerPortalConfig?.enabled ?? false;
  },
  [api.getters.selfAssessmentFeatureEnabled](state, getters) {
    const customerPortalEnabled = getters[
      api.getters.customerPortalFeatureEnabled
    ] as boolean;

    return (
      customerPortalEnabled &&
      state.app.customerPortalConfig &&
      state.app.customerPortalConfig.allowedSharingTypesForSelfAssessment
        .length > 0
    );
  },
  [api.getters.allowedSelfAssessmentSharingTypes](state, getters) {
    const customerPortalEnabled = getters[
      api.getters.customerPortalFeatureEnabled
    ] as boolean;

    return customerPortalEnabled
      ? state.app.customerPortalConfig?.allowedSharingTypesForSelfAssessment
      : [];
  },
  [api.getters.getIsSelfAssessmentStatusVisibleOnDashboard](state) {
    return state.app.components.dashboard?.selfAssessmentStatus ?? false;
  },
  [api.getters.externalMeasureFeatureEnabled](state, getters) {
    const customerPortalEnabled = getters[
      api.getters.customerPortalFeatureEnabled
    ] as boolean;

    return (
      customerPortalEnabled &&
      state.app.customerPortalConfig &&
      state.app.customerPortalConfig?.allowedSharingTypesForExternalMeasures
        .length > 0
    );
  },
  [api.getters.getAnniversaryEventShowFrom](state) {
    return state.app.features?.anniversaryEventShowFrom ?? null;
  },
  [api.getters.getAnniversaryEventShowUntil](state) {
    return state.app.features?.anniversaryEventShowUntil ?? null;
  },
  [api.getters.getDialogButtonPosition](state): string {
    if (
      state.app.components.dialog.buttonPosition === "top" ||
      state.app.components.dialog.buttonPosition === "both"
    ) {
      return state.app.components.dialog.buttonPosition;
    }
    return "bottom";
  },
  [api.getters.getGoogleTagManagerEnabled](state) {
    return state.app.features?.googleTagManager ?? false;
  },
  [api.getters.getUserAutocompleteHint](state): TranslateableText | null {
    return state.app.components?.userAutoComplete?.hintMessage ?? null;
  },
};

const namespaced: boolean = true;
const modul: Module<ConfigurationState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};

export default modul;
