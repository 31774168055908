import { RootState, RootGetters } from "@/store/types";
import { getterNs } from "@/utils/VuexHelper";
import { AuditPermissionInfo, State, FlatAuditMatrixData } from "./types";
import { api as auditApi } from "../audit";
import { api as usersApi } from "../users";
import { api as confApi } from "../configuration";
import { WatchedDocument } from "@/utils/firestore";
import { AuditMetadataClient } from "@/types/Audit";
import {
  calcAllAuditRoles,
  calcAuditPermissions,
} from "@auditcloud/shared/lib/utils/aclHelpers";
import { AuditWorkflowStepInfo } from "../audit/types";
import { mapValues, Dictionary, keys, pickBy } from "lodash";
import { formatAuditName } from "@auditcloud/shared/lib/utils/formatting/audits";
import { extractCurrentUserRef, extractCurrentUserRoles } from "../user/utils";
import { Getters } from ".";
import { idable } from "@auditcloud/shared/lib/types/common";
import {
  AuditMetadataDoc,
  AuditProgramConfig,
} from "@auditcloud/shared/lib/schemas";
import { ReportSchemaE } from "@auditcloud/shared/lib/constants";
import { resolveMetadataForItemJs } from "@/utils/itemjs/filter";
import { UserRefToItemsJsNormalizer } from "@/types/User";

type Getter<R> = (
  state: State,
  getters: Getters,
  rootState: RootState,
  rootGetters: RootGetters
) => R;

const EMPTY_TEXT = "(leer)";

const loadedAudits: Getter<idable<AuditMetadataDoc>[]> = state => {
  return state.loadedAudits.map(doc => ({ ...doc.data, id: doc.id }));
};
const getPermissionEnrichedLoadedAudits: Getter<AuditPermissionInfo[]> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const userId = extractCurrentUserRef(rootGetters)?.id ?? null;
  const roles = extractCurrentUserRoles(rootGetters);
  const loadedAudits =
    state.loadedAudits as WatchedDocument<AuditMetadataClient>[];

  return loadedAudits.map(doc => {
    const audit = doc.data;
    const auditRoles = calcAllAuditRoles(audit, userId, roles);
    const permissions = calcAuditPermissions(audit, userId, roles);

    return {
      auditDoc: doc,
      auditRoles,
      permissions,
    };
  });
};
const getDefaultCalcSchemaName: Getter<ReportSchemaE> = (
  state,
  getters,
  rootState
) => {
  return rootState.configuration.audit.reportSchema || "compliance";
};
const getFlatAuditMatrix: Getter<FlatAuditMatrixData[]> = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const workflowSteps = rootGetters[
    getterNs(auditApi, auditApi.getters.getWorkflowSteps)
  ] as AuditWorkflowStepInfo[];

  const normalizeUserRefToItemsJs = rootGetters[
    getterNs(usersApi, usersApi.getters.getUserRefToItemJsNormalizer)
  ] as UserRefToItemsJsNormalizer;

  const auditProgramMap = rootGetters[
    getterNs(confApi, confApi.getters.getMappedAuditPrograms)
  ] as Dictionary<AuditProgramConfig>;

  const activeProgramIds = state.selectedProgram
    ? new Set([state.selectedProgram])
    : new Set(
        keys(
          pickBy(auditProgramMap, auditProgram => {
            return (
              auditProgram.organizationUnitId === state.selectedOrganizationUnit
            );
          })
        )
      );

  const auditMatrixFilterBuilder: () =>
    | null
    | ((v: AuditPermissionInfo) => boolean) = () => {
    if (activeProgramIds.size > 0 && state.selectedYear !== null) {
      return v => {
        const auditData = v.auditDoc.data;
        return (
          auditData.planning_year === state.selectedYear &&
          activeProgramIds.has(auditData.auditProgramId)
        );
      };
    } else if (state.selectedYear !== null) {
      return v => {
        const auditData = v.auditDoc.data;
        return auditData.planning_year === state.selectedYear;
      };
    } else if (activeProgramIds.size > 0) {
      return v => {
        const auditData = v.auditDoc.data;
        return activeProgramIds.has(auditData.auditProgramId);
      };
    } else {
      return null;
    }
  };

  const auditMatrixFilter = auditMatrixFilterBuilder();

  const filteredAudits = auditMatrixFilter
    ? getters.getPermissionEnrichedLoadedAudits.filter(auditMatrixFilter)
    : getters.getPermissionEnrichedLoadedAudits;

  return filteredAudits.map(
    ({ auditDoc, permissions, auditRoles }): FlatAuditMatrixData => {
      const id = auditDoc.id;
      const audit = auditDoc.data;

      const auditname = formatAuditName(audit);

      return {
        id,
        auditRoles,
        permissions,
        protected: audit.protected || false,
        auditMeta: audit,
        auditname,
        title: auditname,
        auditing_date_display: audit.auditing_date,
        planning_date_display: audit.planning_date,
        auditProgram: audit.auditProgramId,
        hasPendingWrites: auditDoc.metadata.hasPendingWrites,
        fromCache: auditDoc.metadata.fromCache,
        ...resolveMetadataForItemJs(
          audit,
          workflowSteps,
          EMPTY_TEXT,
          normalizeUserRefToItemsJs
        ),
      };
    }
  );
};

const getActiveOrganizationUnitId: Getter<string | null> = state => {
  return state.selectedOrganizationUnit;
};

const getters = {
  loadedAudits,
  getPermissionEnrichedLoadedAudits,
  getDefaultCalcSchemaName,
  getFlatAuditMatrix,
  getActiveOrganizationUnitId,
};

const n = mapValues(getters, (_, key) => key);

export { n as getterNames, getters };
