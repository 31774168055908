import { MutationTree, ActionTree, GetterTree, Module } from "vuex";
import { RootState } from "@/store/types";
import firebase from "firebase/compat/app";

import audit from "./audit";
import template from "./template";
import { schemas } from "./common";
import i18n, { readFrontendLanguage } from "@/plugins/i18n";

import {
  nullable,
  typeIsMultilingualText,
  tt2str,
  CollectionNames,
} from "@auditcloud/shared/lib/types/common";
import { MultilingualText } from "@auditcloud/shared/lib/schemas";
import { Dictionary } from "lodash";
import {
  WatchedDocument,
  unlistenerFunction,
  createCollectionObserver,
  DocumentsUpdateHandlerData,
  MetadataUpdateHandlerData,
} from "@/utils/firestore";
import Vue from "vue";
import _ from "lodash";
import traverse from "traverse";

export interface FormSchema {
  ui: any;
  data: any;
}

export interface FormSchemaDynamic {
  ui: (disabled: boolean, readonly: boolean) => any;
  data: any;
}

export type SchemaMap = {
  // Formularname z.B. metadata, preperation, planning
  default: FormSchema; // ist immer gesetzt
  [auditClass: string]: FormSchema; // AuditProgramm spezifische DialogAnpassungen
};

export type FormSchemaMap = {
  [formId: string]: SchemaMap;
};

export type GroupFormSchemaMap = {
  [groupId: string]: FormSchemaMap;
  // Formular für z.B. audit, finding, ...
};

const groups: GroupFormSchemaMap = {
  audit,
  template,
};

interface FormDocumentHeader {
  groupId: string;
  formId: string;
  auditClass: string;
}

export interface FormDocument extends FormDocumentHeader {
  schema: any;
  ui: any;
}
export class SchemaState {
  Documents: WatchedDocument<FormDocument>[] = [];
  DocumentsUnlistener: nullable<unlistenerFunction> = null;
  loadedNewQuery: boolean = true;

  dialogSchemas: GroupFormSchemaMap = {};
}
export type GetSchemaForType = (
  groupId: string,
  formId: string,
  auditClass?: string
) => FormSchemaDynamic;

const I18N_FIELD_NAMES = ["i18n-key", "i18n-inline"];
export function updateUiSchema(
  uiSchema: any,
  disabled: boolean,
  readonly: boolean,
  i18nTranslator: (key: string) => string,
  multilingualTranslator: (mt: MultilingualText) => string
): any {
  const res = traverse(uiSchema).map(function (node) {
    // handle disable
    if (
      this.key === "disabled" &&
      this.path.join(".").endsWith(".fieldOptions.attrs.disabled") &&
      typeof node === "boolean"
    ) {
      return disabled;
    } else if (
      this.key === "readonly" &&
      this.path.join(".").endsWith(".fieldOptions.attrs.readonly") &&
      typeof node === "boolean"
    ) {
      return readonly;
    } else {
      if (node instanceof Object) {
        const keys = Object.keys(node);
        if (keys.length === 1 && I18N_FIELD_NAMES.includes(keys[0])) {
          if (typeof node["i18n-key"] === "string") {
            const text = i18nTranslator(node["i18n-key"]);
            this.update(text, true);
            return text;
          } else if (typeIsMultilingualText(node["i18n-inline"])) {
            const text = multilingualTranslator(node["i18n-inline"]);
            this.update(text, true);
            return text;
          } else {
            console.warn("Invalid i18n form data", node);
            return node;
          }
        } else {
          return node;
        }
      } else {
        return node;
      }
    }
  });

  return res;
}

export const api = {
  namespace: "schemas",
  actions: {
    loadData: "loadData",
    updateSchema: "updateSchema",
    createSchema: "createSchema",
  },
  mutations: {
    SET_SCHEMAS_UNLISTENER: "SET_SCHEMAS_UNLISTENER",
    SET_SCHEMAS: "SET_SCHEMAS",
    SET_SCHEMAS_METADATA: "SET_SCHEMAS_METADATA",
    CLEAR_SCHEMAS: "CLEAR_SCHEMAS",
  },
  getters: {
    mappedSchemas: "mappedSchemas",
    list: "list",
    getSchemaFor: "getSchemaFor",
    getVfjsOptions: "getVfjsOptions",
    getVfjsCommonSchemas: "getVfjsCommonSchemas",
  },
  state: {
    dialogSchemas: "dialogSchemas",
  },
};

const state = new SchemaState();

const mutations: MutationTree<SchemaState> = {
  [api.mutations.SET_SCHEMAS_UNLISTENER](
    state,
    unlistener: unlistenerFunction | null
  ) {
    state.loadedNewQuery = true;
    const prevDocumentsUnlistener = state.DocumentsUnlistener;
    state.DocumentsUnlistener = unlistener;
    if (prevDocumentsUnlistener) {
      prevDocumentsUnlistener();
    }
  },
  [api.mutations.SET_SCHEMAS](
    state,
    { removeDocs, modifiedDocs }: DocumentsUpdateHandlerData<FormDocument>
  ) {
    console.log(api.mutations.SET_SCHEMAS, removeDocs, modifiedDocs);
    if (state.loadedNewQuery === true) {
      state.Documents = modifiedDocs;
    } else {
      removeDocs.forEach(docId => {
        const idx = state.Documents.findIndex(v => v.id === docId);
        if (idx > -1) {
          Vue.delete(state.Documents, idx);
        }
      });

      modifiedDocs.forEach(doc => {
        const idx = state.Documents.findIndex(v => v.id === doc.id);
        if (idx > -1) {
          Vue.set(state.Documents, idx, doc);
        } else {
          state.Documents.push(doc);
        }
      });
    }
    state.loadedNewQuery = false;
  },
  [api.mutations.SET_SCHEMAS_METADATA](
    state,
    updateMetadata: MetadataUpdateHandlerData
  ) {
    console.log(api.mutations.SET_SCHEMAS_METADATA, updateMetadata);
    if (state.loadedNewQuery === true) {
      state.Documents = [];
    } else {
      updateMetadata.forEach(doc => {
        const idx = state.Documents.findIndex(v => v.id === doc.id);
        if (idx > -1) {
          state.Documents[idx].exists = doc.exists;
          state.Documents[idx].metadata = doc.metadata;
        }
      });
    }
  },
  [api.mutations.CLEAR_SCHEMAS](state) {
    const prevDocumentUnlistener = state.DocumentsUnlistener;
    state.Documents = [];

    if (prevDocumentUnlistener) {
      prevDocumentUnlistener();
    }
  },
};

const actions: ActionTree<SchemaState, RootState> = {
  [api.actions.loadData]({ commit, dispatch }) {
    const unlistener = createCollectionObserver(
      CollectionNames.SCHEMAS,
      {},
      (updateData: DocumentsUpdateHandlerData) => {
        commit(api.mutations.SET_SCHEMAS, updateData);
      },
      (updateData: MetadataUpdateHandlerData) => {
        commit(api.mutations.SET_SCHEMAS_METADATA, updateData);
      },
      () => {}
    );
    commit(api.mutations.SET_SCHEMAS_UNLISTENER, unlistener);
    return unlistener;
  },
  async [api.actions.updateSchema](
    { commit },
    payload: {
      formSchemaId: string;
      data: FormDocument;
    }
  ) {
    const db = firebase.firestore();
    const docRef = db
      .collection(CollectionNames.SCHEMAS)
      .doc(payload.formSchemaId);

    await docRef.set(payload.data);
  },
  async [api.actions.createSchema]({ commit }, payload: FormDocument) {
    const formSchemaId = `${payload.groupId}-${payload.formId}-${payload.auditClass}`;

    const db = firebase.firestore();
    const docRef = db.collection(CollectionNames.SCHEMAS).doc(formSchemaId);

    await docRef.set(payload);
    return formSchemaId;
  },
};

const getters: GetterTree<SchemaState, RootState> = {
  [api.getters.mappedSchemas](
    state
  ): Dictionary<WatchedDocument<FormDocument>> {
    return _.keyBy(state.Documents, "id");
  },
  [api.getters.list](state): Array<{ id: string; name: string }> {
    return state.Documents.map(doc => {
      return {
        id: doc.id,
        name: `${doc.data.groupId}, ${doc.data.formId}, ${doc.data.auditClass}`,
      };
    });
  },
  [api.getters.getSchemaFor](state): GetSchemaForType {
    // audit
    return (
      groupId: string,
      formId: string,
      auditClass?: string
    ): FormSchemaDynamic => {
      const result = (() => {
        const schema =
          typeof auditClass === "string"
            ? state.Documents.find(
                doc =>
                  doc.data.groupId === groupId &&
                  doc.data.formId === formId &&
                  doc.data.auditClass === auditClass
              )
            : undefined;

        if (schema) {
          return {
            ui: schema.data.ui,
            data: schema.data.schema,
          };
        } else {
          if (groupId in groups && formId in groups[groupId]) {
            if (auditClass && auditClass in groups[groupId][formId]) {
              return groups[groupId][formId][auditClass];
            } else {
              return groups[groupId][formId].default;
            }
          } else {
            console.error(
              "No FormSchema found for",
              groupId,
              formId,
              auditClass
            );
            return null;
          }
        }
      })();

      if (result !== null) {
        return {
          ui: (disabled: boolean, readonly: boolean = false) => {
            const locale = readFrontendLanguage();
            console.log("getSchemaFor:ui", locale);
            const i18nKeyResolver = (key: string) => {
              return i18n.t(key).toString();
            };
            const multilingualTranslator = (mt: MultilingualText) => {
              return tt2str(mt, locale);
            };

            return updateUiSchema(
              result.ui,
              disabled,
              readonly,
              i18nKeyResolver,
              multilingualTranslator
            );
          },
          data: result.data,
        };
      } else {
        return {
          ui: () => [],
          data: {},
        };
      }
    };
  },
  [api.getters.getVfjsOptions](state): Dictionary<any> {
    return {
      showValidationErrors: true,
      validate: true,
      validateOnLoad: true,
      // ajv: {}
    };
  },
  [api.getters.getVfjsCommonSchemas](): Dictionary<any> {
    console.log("getVfjsCommonSchemas", schemas);
    return schemas;
  },
};

const namespaced: boolean = true;
const modul: Module<SchemaState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};

export default modul;
